import { FC } from "react";
import { Box } from "@mui/material";
import { useUserContext } from "../../user/user.context";
import { UserAvatarWithQuery } from "../../user/components/user-avatar.component";
import { CommentTextEditorWithUsersQuery } from "./comment-text-editor.component";
import { SerializedEditorState } from "lexical";
import { IComment } from "@netcero/netcero-core-api-client";

interface ICreateNewCommentProps {
  organizationId: string;
  comment?: IComment;
  onSave: (content: SerializedEditorState) => Promise<void>;
  disabled?: boolean;
}

export const CreateCommentEditorComponent: FC<ICreateNewCommentProps> = ({
  organizationId,
  comment,
  onSave,
  disabled,
}) => {
  const { user } = useUserContext();

  return (
    <Box display="flex" gap={1}>
      {/* Avatar */}
      <Box mt={1.25} px={0.5}>
        <UserAvatarWithQuery
          organizationId={organizationId}
          userId={comment?.createdByUserId ?? user?.userProfile.id}
          avatarDiameter={30}
        />
      </Box>
      {/* Comment Input */}
      <Box flex={1}>
        <CommentTextEditorWithUsersQuery
          organizationId={organizationId}
          onSave={onSave}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};
