import { useSearchParams } from "react-router-dom";
import { VIEWED_COMMENT_QUERY_PARAM } from "@netcero/netcero-common";
import { useCallback } from "react";

export const useViewedCommentWithReset = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const viewedCommentId = searchParams.get(VIEWED_COMMENT_QUERY_PARAM);

  const resetViewedCommentAndExecuteAction = useCallback(
    <T extends CallableFunction>(action?: T) =>
      ((...args: unknown[]) => {
        if (searchParams.has(VIEWED_COMMENT_QUERY_PARAM)) {
          // Execute once js thread is free again (avoid collisions with other setSearchParams calls)
          // This has already caused issues --> therefore this mitigation is necessary
          // We may want to consider a different approach in the future (different router library or v7 upgrade)
          setTimeout(
            () =>
              setSearchParams(
                (params) => {
                  params.delete(VIEWED_COMMENT_QUERY_PARAM);
                  return params;
                },
                { replace: true },
              ),
            10,
          );
        }
        return action?.(...args);
      }) as unknown as T,
    [searchParams, setSearchParams],
  );

  return {
    viewedCommentId,
    isViewedCommentSet: viewedCommentId !== null,
    resetViewedCommentAndExecuteAction,
  };
};
