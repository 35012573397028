import { FC, PropsWithChildren } from "react";
import { FeatureFlag } from "../constants/feature-flags.constants";
import { useIsFeatureFlagEnabled } from "../hooks/is-feature-flag-enabled.hook";

type RequireFeatureFlagProps = {
  // The feature flag to check
  featureFlag: FeatureFlag;
  // When true, renders children when the flag is NOT enabled
  negate?: boolean;
};

export const RequireFeatureFlag: FC<PropsWithChildren<RequireFeatureFlagProps>> = ({
  featureFlag,
  negate = false,
  children,
}) => {
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(featureFlag);

  // Return null if the flag is not enabled and we are not negating
  // Return null if the flag is enabled and we are negating
  if (negate === isFeatureFlagEnabled) {
    return null;
  }

  return children;
};
