import { FC, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import {
  IHydratedInputParameterRecordingStructureGroupDisclosureRequirement,
  IHydratedInputParameterRecordingStructureGroupDisclosureRequirementSection,
} from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import {
  ITableOfContentsEntry,
  TableOfContents,
} from "../../common/components/table-of-contents.component";
import {
  IEitherESRSOrVSMEHydratedRecordingStructureGroup,
  InputParameterRecordingEsrsStructuresUtilities,
} from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import { useDeoEsrsInputContext } from "./deo-esrs-input.context";

interface IDeoEsrsTableOfContentsProps {
  esrsStructure: IEitherESRSOrVSMEHydratedRecordingStructureGroup;
  activeDisclosureRequirementId: string | null;
  generateDisclosureRequirementUrl: (
    section: IHydratedInputParameterRecordingStructureGroupDisclosureRequirementSection,
    disclosureRequirement: IHydratedInputParameterRecordingStructureGroupDisclosureRequirement,
  ) => string;
  onChangeDisclosureRequirement: (
    section: IHydratedInputParameterRecordingStructureGroupDisclosureRequirementSection,
    disclosureRequirement: IHydratedInputParameterRecordingStructureGroupDisclosureRequirement,
  ) => void;
}

type TocEntryLookupEntry = {
  section: IHydratedInputParameterRecordingStructureGroupDisclosureRequirementSection;
  disclosureRequirement: IHydratedInputParameterRecordingStructureGroupDisclosureRequirement;
};

export const DeoEsrsTableOfContents: FC<IDeoEsrsTableOfContentsProps> = ({
  esrsStructure,
  activeDisclosureRequirementId,
  generateDisclosureRequirementUrl,
  onChangeDisclosureRequirement,
}) => {
  const translateContent = useTranslateContent();
  const { conditionalDisplayInputParametersLookup } = useDeoEsrsInputContext();

  const tocEntries: ITableOfContentsEntry[] = useMemo(() => {
    return esrsStructure.sections.map((section) => ({
      id: section.id,
      title: translateContent(section.name),
      children:
        InputParameterRecordingEsrsStructuresUtilities.removeConditionallyHiddenDisclosureRequirements(
          section.disclosureRequirements,
          conditionalDisplayInputParametersLookup,
        ).map((disclosureRequirement) => ({
          id: disclosureRequirement.id,
          title: disclosureRequirement.disclosureRequirementInputParameter
            ? translateContent(
                disclosureRequirement.disclosureRequirementInputParameter.inputParameter.name,
              )
            : "",
        })),
    }));
  }, [esrsStructure, translateContent]);

  const tocEntryLookup = useMemo(() => {
    return new Map(
      esrsStructure.sections.map((section) => [
        section.id,
        new Map([
          // Section itself
          [
            "",
            {
              section: section,
              disclosureRequirement: section.disclosureRequirements[0],
            },
          ] as [string, TocEntryLookupEntry],
          // Map DRs
          ...section.disclosureRequirements.map(
            (disclosureRequirement) =>
              [
                disclosureRequirement.id,
                {
                  section,
                  disclosureRequirement,
                },
              ] as [string, TocEntryLookupEntry],
          ),
        ]),
      ]),
    );
  }, [esrsStructure.sections]);
  const getTocEntryLink = useCallback(
    (
      _: ITableOfContentsEntry,
      [sectionTocEntry, disclosureRequirementTocEntry]: ITableOfContentsEntry[],
    ) => {
      const lookedUpEntry = tocEntryLookup
        .get(sectionTocEntry.id)
        ?.get(disclosureRequirementTocEntry?.id ?? "");
      if (!lookedUpEntry) {
        return "";
      }

      return generateDisclosureRequirementUrl(
        lookedUpEntry.section,
        lookedUpEntry.disclosureRequirement,
      );
    },
    [tocEntryLookup, generateDisclosureRequirementUrl],
  );

  const handleSelectEntry = useCallback(
    (
      _: ITableOfContentsEntry,
      [sectionTocEntry, disclosureRequirementTocEntry]: ITableOfContentsEntry[],
    ) => {
      const lookedUpEntry = tocEntryLookup
        .get(sectionTocEntry.id)
        ?.get(disclosureRequirementTocEntry?.id ?? "");
      if (!lookedUpEntry) {
        return;
      }

      onChangeDisclosureRequirement(lookedUpEntry.section, lookedUpEntry.disclosureRequirement);
    },
    [onChangeDisclosureRequirement, tocEntryLookup],
  );

  return (
    <Box>
      <TableOfContents
        entries={tocEntries}
        activeEntryId={activeDisclosureRequirementId}
        getLinkForEntry={getTocEntryLink}
        onSelectEntry={handleSelectEntry}
      />
    </Box>
  );
};
