import { useCommentsAndAuditLogState } from "./comments-and-audit-log-state.hook";
import { useCallback, useMemo, useState } from "react";

export interface IUseCommentsAndAuditLogStateWithDataResult<T> {
  // Original Props
  showCommentsAndAuditLogSideSection: boolean;
  // Modified Props
  toggleCommentsAndAuditLogSideSection: (newData?: T | null) => void;
  openCommentsAndAuditLogSideSection: (newData?: T | null) => void;
  closeCommentsAndAuditLogSideSection: (newData?: T | null) => void;
  // New Props
  data: T | null;
  updateCommentsAndAuditLogSideSectionData: (newData: T | null) => void;
}

export const useCommentsAndAuditLogStateWithData = <
  T,
>(): IUseCommentsAndAuditLogStateWithDataResult<T> => {
  const {
    showCommentsAndAuditLogSideSection,
    toggleCommentsAndAuditLogSideSection: toggleCommentsAndAuditLogSideSectionInternal,
    openCommentsAndAuditLogSideSection,
    closeCommentsAndAuditLogSideSection: closeCommentsAndAuditLogSideSectionInternal,
  } = useCommentsAndAuditLogState();
  const [data, setData] = useState<T | null>(null);

  const withUpdateData = useCallback(
    (action: (newData?: T | null) => void) => (newData?: T | null) => {
      if (newData !== undefined) {
        setData(newData);
      }
      action(newData);
    },
    [],
  );

  return useMemo(
    () => ({
      data,
      updateCommentsAndAuditLogSideSectionData: setData,
      showCommentsAndAuditLogSideSection,
      toggleCommentsAndAuditLogSideSection: withUpdateData(
        toggleCommentsAndAuditLogSideSectionInternal,
      ),
      openCommentsAndAuditLogSideSection: withUpdateData(openCommentsAndAuditLogSideSection),
      closeCommentsAndAuditLogSideSection: withUpdateData(
        closeCommentsAndAuditLogSideSectionInternal,
      ),
    }),
    [
      data,
      showCommentsAndAuditLogSideSection,
      withUpdateData,
      toggleCommentsAndAuditLogSideSectionInternal,
      openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSectionInternal,
    ],
  );
};
