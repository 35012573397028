import { Add } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import {
  IDMACategoryWithEffectsAndChildren,
  IDMAFinancialEffect,
  IDMAMaterialImpact,
  IESRSTopic,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../common/constants/tabler-icon.constants";
import { DMACategoryComponent } from "./dma-category.component";
import { useRenderESRSTopicName } from "./hooks/render-esrs-topic-name.hook";
import { ESRSTopicContextProvider } from "./esrs-topic.context";
import { useDoesUserHaveRole } from "../authentication/hooks/does-user-have-role.hook";
import { ROLE_ACCESS } from "@netcero/netcero-common";

interface IESRSTopicComponentProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  esrsTopic: IESRSTopic;
  showMaterialImpacts: boolean;
  showFinancialEffects: boolean;
  showEmpty: boolean;
  onlyViewCategory?: IDMACategoryWithEffectsAndChildren | null;
  onCreateMaterialImpact: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => void;
  onCreateFinancialEffect: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => void;
  onEditMaterialImpact: (materialImpact: IDMAMaterialImpact) => void;
  onEditFinancialEffect: (financialEffect: IDMAFinancialEffect) => void;
  onOptOutOfRecordingESRSTopic: (esrsTopic: IESRSTopic) => void;
  onStartDataRecordingForESRSTopic: (esrsTopic: IESRSTopic) => void;
  onEditOptOutReasonOfESRSTopic: (esrsTopic: IESRSTopic) => void;
  onCreateDMACategory: (esrsTopic: IESRSTopic, parentId: string | null) => void;
  onEditDMACategory: (esrsTopic: IESRSTopic, category: IDMACategoryWithEffectsAndChildren) => void;
  onAddChildDMACategory: (
    esrsTopic: IESRSTopic,
    parentCategory: IDMACategoryWithEffectsAndChildren,
  ) => void;
  onOptOutOfDMACategory: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => void;
  onEditOptOutOfDMACategory: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => void;
  onOptBackIntoDMACategory: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => void;
  onOpenFeedbackDialog: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => void;
}

export const ESRSTopicComponent: FC<IESRSTopicComponentProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  esrsTopic,
  showFinancialEffects,
  showMaterialImpacts,
  showEmpty = true,
  onlyViewCategory,
  onCreateMaterialImpact,
  onCreateFinancialEffect,
  onEditMaterialImpact,
  onEditFinancialEffect,
  onOptOutOfRecordingESRSTopic,
  onStartDataRecordingForESRSTopic,
  onEditOptOutReasonOfESRSTopic,
  onCreateDMACategory,
  onEditDMACategory,
  onAddChildDMACategory,
  onOptOutOfDMACategory,
  onEditOptOutOfDMACategory,
  onOptBackIntoDMACategory,
  onOpenFeedbackDialog,
}) => {
  const { t } = useTranslation("dma_esrs_topic_component");
  const [open, setOpen] = useState<boolean>(false);
  const renderTopicName = useRenderESRSTopicName();

  const canUpdateEsrsTopic = useDoesUserHaveRole(ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_ESRS_TOPICS);

  const esrsTopicContextValue = useMemo(
    () => ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId: esrsTopic.id,
    }),
    [organizationId, recordingPeriodId, dataEntryObjectId, esrsTopic.id],
  );

  const content = useMemo(() => {
    const recordedESRSTopic = esrsTopic.recordedESRSTopic;

    if (
      // nothing was recorded yet
      !recordedESRSTopic ||
      // there has been a previous recording, but all categories were deleted by the user
      (recordedESRSTopic &&
        !recordedESRSTopic.optOut &&
        recordedESRSTopic.dmaCategories.length === 0)
    ) {
      return (
        <ESRSTopicNotYetRecordedComponent
          esrsTopic={esrsTopic}
          onOptOutOfRecordingESRSTopic={onOptOutOfRecordingESRSTopic}
          onStartDataRecordingForESRSTopic={onStartDataRecordingForESRSTopic}
          showActions={canUpdateEsrsTopic}
        />
      );
    }

    if (recordedESRSTopic.optOut) {
      return (
        <ESRSTopicOptedOutComponent
          esrsTopic={esrsTopic}
          onStartDataRecordingForESRSTopic={onStartDataRecordingForESRSTopic}
          onEditOptOutReason={onEditOptOutReasonOfESRSTopic}
          showActions={canUpdateEsrsTopic}
        />
      );
    }

    return (
      // This component should never be shown, but it is here for completeness
      <ESRSTopicContextProvider value={esrsTopicContextValue}>
        <Box display="flex" flexDirection="column" gap={6}>
          {(onlyViewCategory ? [onlyViewCategory] : recordedESRSTopic.dmaCategories).map(
            (child) => {
              return (
                <DMACategoryComponent
                  key={child.id}
                  category={child}
                  showEmpty={showEmpty}
                  showMaterialImpacts={showMaterialImpacts}
                  showFinancialEffects={showFinancialEffects}
                  onCreateMaterialImpact={(dmaCategory) =>
                    onCreateMaterialImpact(esrsTopic, dmaCategory)
                  }
                  onCreateFinancialEffect={(dmaCategory) =>
                    onCreateFinancialEffect(esrsTopic, dmaCategory)
                  }
                  onEditMaterialImpact={(materialImpact) => onEditMaterialImpact(materialImpact)}
                  onEditFinancialEffect={(financialEffect) =>
                    onEditFinancialEffect(financialEffect)
                  }
                  onEditDMACategory={(dmaCategory) => onEditDMACategory(esrsTopic, dmaCategory)}
                  onAddChildDMACategory={(dmaCategory) =>
                    onAddChildDMACategory(esrsTopic, dmaCategory)
                  }
                  onOptOutOfDMACategory={(dmaCategory) =>
                    onOptOutOfDMACategory(esrsTopic, dmaCategory)
                  }
                  onEditOptOutOfDMACategory={(dmaCategory) =>
                    onEditOptOutOfDMACategory(esrsTopic, dmaCategory)
                  }
                  onOptBackIntoDMACategory={(dmaCategory) =>
                    onOptBackIntoDMACategory(esrsTopic, dmaCategory)
                  }
                  onClickFeedback={(dmaCategory) => onOpenFeedbackDialog(esrsTopic, dmaCategory)}
                  setOpen={setOpen}
                  open={open}
                />
              );
            },
          )}
        </Box>
      </ESRSTopicContextProvider>
    );
  }, [
    esrsTopic,
    esrsTopicContextValue,
    onlyViewCategory,
    onOptOutOfRecordingESRSTopic,
    onStartDataRecordingForESRSTopic,
    canUpdateEsrsTopic,
    onEditOptOutReasonOfESRSTopic,
    showEmpty,
    showMaterialImpacts,
    showFinancialEffects,
    open,
    onCreateMaterialImpact,
    onCreateFinancialEffect,
    onEditMaterialImpact,
    onEditFinancialEffect,
    onEditDMACategory,
    onAddChildDMACategory,
    onOptOutOfDMACategory,
    onEditOptOutOfDMACategory,
    onOptBackIntoDMACategory,
    onOpenFeedbackDialog,
  ]);

  // Render Component

  return (
    <Box display="flex" gap={2} flexDirection="column">
      {content}
      {canUpdateEsrsTopic && !onlyViewCategory && (
        <Tooltip
          title={t("create_category_button_tooltip", { esrsTopicName: renderTopicName(esrsTopic) })}
          placement="right"
        >
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => onCreateDMACategory(esrsTopic, null)}
            sx={{ alignSelf: "start" }}
          >
            {t("create_category_button")}
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

interface IESRSTopicNotYetRecordedComponentProps {
  esrsTopic: IESRSTopic;
  onOptOutOfRecordingESRSTopic: IESRSTopicComponentProps["onOptOutOfRecordingESRSTopic"];
  onStartDataRecordingForESRSTopic: IESRSTopicComponentProps["onStartDataRecordingForESRSTopic"];
  showActions: boolean;
}

const ESRSTopicNotYetRecordedComponent: FC<IESRSTopicNotYetRecordedComponentProps> = ({
  esrsTopic,
  onOptOutOfRecordingESRSTopic,
  onStartDataRecordingForESRSTopic,
  showActions,
}) => {
  const { t } = useTranslation("dma_esrs_topic_not_yet_recorded_component");
  const renderName = useRenderESRSTopicName();
  return (
    <Box display="flex" flexDirection="column" gap={1} alignItems="start">
      <Typography variant="subtitle1" component="div">
        {t("header", { name: renderName(esrsTopic), topic: esrsTopic?.topic })}
      </Typography>
      {showActions && (
        <Box display="flex" flexDirection="row" gap={2}>
          {esrsTopic.currentTemplate && esrsTopic.currentTemplate.categories.length > 0 && (
            <Tooltip title={t("start_collection_button_tooltip")} placement="bottom">
              <Button
                variant="outlined"
                color="success"
                onClick={() => onStartDataRecordingForESRSTopic(esrsTopic)}
              >
                {t("start_collection_button")}
              </Button>
            </Tooltip>
          )}
          <Tooltip title={t("skip_collection_button_tooltip")} placement="bottom">
            <Button
              variant="outlined"
              color="error"
              onClick={() => onOptOutOfRecordingESRSTopic(esrsTopic)}
            >
              {t("skip_collection_button")}
            </Button>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

interface IESRSTopicOptedOutComponentProps {
  esrsTopic: IESRSTopic;
  onStartDataRecordingForESRSTopic: IESRSTopicComponentProps["onStartDataRecordingForESRSTopic"];
  onEditOptOutReason: (esrsTopic: IESRSTopic) => void;
  showActions: boolean;
}

const ESRSTopicOptedOutComponent: FC<IESRSTopicOptedOutComponentProps> = ({
  esrsTopic,
  onStartDataRecordingForESRSTopic,
  onEditOptOutReason,
  showActions,
}) => {
  const { t } = useTranslation("dma_esrs_topic_opted_out_component");
  const renderName = useRenderESRSTopicName();
  return (
    <Box display="flex" flexDirection="column" gap={1} alignItems="start">
      {/* Header */}
      <Typography variant="subtitle1" component="div">
        {t("header", { name: renderName(esrsTopic), topic: esrsTopic?.topic })}
      </Typography>
      {/* Opt Out Reason */}
      <Box display="flex" gap={2} mb={1}>
        <Typography fontWeight="bold" mt={0.15}>
          {t("opt_out_reason_prefix")}
        </Typography>
        <Typography mt={0.15}>{esrsTopic.recordedESRSTopic?.optOutReason}</Typography>
        {showActions && (
          <Tooltip title={t("edit_opt_out_reason_button_tooltip")} placement="right">
            <IconButton size="small" onClick={() => onEditOptOutReason(esrsTopic)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {/* Start Recording - only show if template is available */}
      {showActions &&
        esrsTopic.currentTemplate &&
        esrsTopic.currentTemplate.categories.length > 0 && (
          <Tooltip title={t("start_collection_button_tooltip")} placement="right">
            <Button
              variant="outlined"
              color="success"
              onClick={() => onStartDataRecordingForESRSTopic(esrsTopic)}
            >
              {t("start_collection_button")}
            </Button>
          </Tooltip>
        )}
    </Box>
  );
};
