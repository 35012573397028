import React, { FC, useCallback, useMemo } from "react";
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  IActivity,
  IGhgCategoriesIdentifier,
  IGhgEvaluationType,
  IPerformEvaluationForRecordingPeriod200Response,
} from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import {
  GhgEvaluationUtilities,
  IRecordingPeriodGhgCategoryIdentity,
} from "@netcero/netcero-common";
import { EmissionFactorChip } from "../../emission-factors/components/emission-factor-chip.component";
import { UserAvatarWithQuery } from "../../user/components/user-avatar.component";
import { useDataEntryObjectsContext } from "../../data-entry-objects/data-entry-objects.context";
import { GhgActivityStatusIndicator } from "./ghg-activity-status-indicator.component";
import { ClickableTableRow } from "../../common/components/clickable-table-row.component";
import { GhgEmissionsSumChip } from "../../ghg-evaluation/components/ghg-emissions-sum-chip.component";
import { AppWarningIcon } from "../../common/components/app-warning-icon.component";
import { useGhgActivityReappropriatedText } from "../hooks/ghg-activity-reappropriated-text.hook";

interface IGhgActivitiesTableProps {
  ghgCategoryIdentity: IRecordingPeriodGhgCategoryIdentity;
  activities: IActivity[];
  onClick: (activity: IActivity) => void;
  disabled?: boolean;
}

export const GhgActivitiesTable: FC<IGhgActivitiesTableProps> = ({
  ghgCategoryIdentity,
  activities,
  onClick,
  disabled,
}) => {
  const { t } = useTranslation("ghg_activities", { keyPrefix: "table" });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("head_title")}</TableCell>
            <TableCell>{t("head_emission_factors")}</TableCell>
            <TableCell align="right">
              <Box mr={8}>{t("head_emissions")}</Box>
            </TableCell>
            <TableCell>{t("head_data_entry_object")}</TableCell>
            <TableCell>{t("head_status")}</TableCell>
            <TableCell align="right">{t("head_responsible_person")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map((activity) => (
            <GhgActivityRow
              key={activity.id}
              ghgCategoryIdentity={ghgCategoryIdentity}
              activity={activity}
              onClick={onClick}
              disabled={disabled}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface IGhgActivityRowProps {
  ghgCategoryIdentity: IRecordingPeriodGhgCategoryIdentity;
  activity: IActivity;
  onClick: (activity: IActivity) => void;
  disabled?: boolean;
}

export const GhgActivityRow: FC<IGhgActivityRowProps> = ({
  ghgCategoryIdentity,
  activity,
  onClick,
  disabled,
}) => {
  const { t } = useTranslation("user_picker_components");
  const { getDataEntryObject, getConsolidationForDataEntryObject } = useDataEntryObjectsContext();

  const selectActivityEmissionsSum = useCallback(
    (data: IPerformEvaluationForRecordingPeriod200Response) => {
      const filteredResults =
        ghgCategoryIdentity.ghgCategoryIdentifier ===
        // always display all emissions of the activity in 3.3
        IGhgCategoriesIdentifier._33EnergyRelatedActivitiesNotInScope1And2
          ? // Always display 3.3 emissions
            data.results
          : // don't display 3.3 emissions in all other categories
            GhgEvaluationUtilities.filterGhgEvaluationResultsByCategories(
              data.results,
              [IGhgCategoriesIdentifier._33EnergyRelatedActivitiesNotInScope1And2],
              true,
            );

      return GhgEvaluationUtilities.calculateTotalEmissionsForActivity(filteredResults, activity.id)
        .marketBasedTotal;
    },
    [activity.id, ghgCategoryIdentity.ghgCategoryIdentifier],
  );

  const getReappropriatedText = useGhgActivityReappropriatedText();
  const reappropriatedTo = useMemo(
    () =>
      activity
        ? getConsolidationForDataEntryObject(activity?.dataEntryObjectId)?.relocateEmissionsTo ??
          null
        : null,
    [activity],
  );

  return (
    <ClickableTableRow key={activity.id} onClick={disabled ? undefined : () => onClick(activity)}>
      <TableCell>{activity.title}</TableCell>
      <TableCell>
        <Box display="flex" flexWrap="wrap" gap={1}>
          {activity.emissionFactorIds.length > 0
            ? activity.emissionFactorIds.map((emissionFactorId) => (
                <EmissionFactorChip
                  key={emissionFactorId}
                  organizationId={ghgCategoryIdentity.organizationId}
                  emissionFactorId={emissionFactorId}
                />
              ))
            : "-"}
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box display="inline-flex" alignItems="center" gap={1}>
          {reappropriatedTo && (
            <Tooltip title={getReappropriatedText(reappropriatedTo)}>
              <AppWarningIcon fontSize="small" />
            </Tooltip>
          )}
          <GhgEmissionsSumChip
            recordingPeriodIdentity={ghgCategoryIdentity}
            evaluationConfiguration={{
              evaluationType: IGhgEvaluationType.Category,
              categoryIdentifier: ghgCategoryIdentity.ghgCategoryIdentifier,
            }}
            shouldComputeScope3Values={false}
            selectSum={selectActivityEmissionsSum}
            fontSize={14}
          />
        </Box>
      </TableCell>
      <TableCell>
        {/* This is fine since this should never occur */}
        <Chip label={getDataEntryObject(activity.dataEntryObjectId)?.name ?? "ERROR"} />
      </TableCell>
      <TableCell>
        <Box display="flex">
          <GhgActivityStatusIndicator status={activity.status} />
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box display="inline-block">
          <UserAvatarWithQuery
            organizationId={ghgCategoryIdentity.organizationId}
            userId={activity.responsibleUserId}
            disabledOverlay
            withTooltip
            noUserSelectedTooltip={t("no_user_selected")}
          />
        </Box>
      </TableCell>
    </ClickableTableRow>
  );
};
