import { FC, useMemo } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { IInputParameterRecordingStructureIdentity } from "@netcero/netcero-common";
import { useGhgProtocolTabItems } from "../../modules/ghg-evaluation/hooks/ghg-protocol-tab-items.hook";
import { IIntercomEntity } from "@netcero/netcero-core-api-client";
import { withValidatedRouteParams } from "../../modules/common/components/with-validated-router-parameters.hoc";
import { Box, Button, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useRecordingPeriodGhgCategories } from "../../modules/ghg-categories/ghg-categories.queries";
import { useIntercomReferencesForEntityQuery } from "../../modules/intercom-references/intercom-references.queries";
import {
  IIntercomReferencesContextState,
  IntercomReferencesProvider,
} from "../../modules/intercom-references/intercom-references.context";
import { RequireFeatureFlag } from "../../modules/common/components/require-feature-flag.component";
import { FeatureFlag } from "../../modules/common/constants/feature-flags.constants";
import { EditIcon, OpenInNewTabIcon } from "../../modules/common/constants/tabler-icon.constants";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { GhgInventoryScreeningScopesOverview } from "../../modules/ghg-inventory-screening/components/ghg-inventory-screening-scopes-overview.component";
import {
  CommentsAndAuditLogSidebarStateProvider,
  useCommentsAndAuditLogSidebarStateContext,
} from "../../modules/comments-and-audit-log/context/comments-and-audit-log-sidebar-state.context";
import { GhgCategoryCommentsAndAuditLogsSidebar } from "../../modules/ghg-categories/components/ghg-category-comments-and-audit-logs-sidebar.component";

export const GhgProtocolInventoryScreeningPage: FC = withValidatedRouteParams(
  ["organizationId", "recordingPeriodId", "recordingStructureId"] as const,
  ({ organizationId, recordingPeriodId, recordingStructureId }) => {
    const identity: IInputParameterRecordingStructureIdentity = useMemo(
      () => ({
        organizationId,
        recordingPeriodId,
        inputParameterRecordingStructureId: recordingStructureId,
      }),
      [organizationId, recordingPeriodId, recordingStructureId],
    );

    const navigate = useNavigate();

    const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
    const sideBarConfiguration = useMemo<ISideBarConfiguration>(
      () => ({
        organizationSelect: {
          organizationId: organizationId,
          onChange: (organizationId) => {
            navigate(`/organizations/${organizationId}`);
          },
        },
        navigationItems: {
          activePath: AppInternalNavigationUtilities.getGhgProtocolPageUrl(identity),
          items: sideBarOrganizationNavItems,
        },
      }),
      [organizationId, identity, sideBarOrganizationNavItems, navigate],
    );
    useCustomSideBarContent(sideBarConfiguration);

    const tabItems = useGhgProtocolTabItems(identity);
    const topBarConfiguration = useMemo<ITopBarConfiguration>(
      () => ({
        organizationSelect: {
          organizationId: organizationId,
          onChange: (organizationId) => {
            navigate(`/organizations/${organizationId}`);
          },
        },
        recordingPeriodId: recordingPeriodId,
        tabNavigation: {
          activeTabKey: "inventoryScreening",
          items: tabItems,
        },
      }),
      [organizationId, recordingPeriodId, tabItems, navigate],
    );
    useCustomTopBarContent(topBarConfiguration);

    const ghgCategoryIntercomReferencesQuery = useIntercomReferencesForEntityQuery(
      IIntercomEntity.GhgCategory,
    );
    const intercomReferencesState: IIntercomReferencesContextState = useMemo(
      () => ({
        [IIntercomEntity.GhgCategory]: ghgCategoryIntercomReferencesQuery.data,
      }),
      [ghgCategoryIntercomReferencesQuery.data],
    );

    return (
      <IntercomReferencesProvider state={intercomReferencesState}>
        <CommentsAndAuditLogSidebarStateProvider>
          <InternalGhgProtocolInventoryScreening identity={identity} />
        </CommentsAndAuditLogSidebarStateProvider>
      </IntercomReferencesProvider>
    );
  },
);

interface IInternalGhgProtocolInventoryScreeningProps {
  identity: IInputParameterRecordingStructureIdentity;
}

const InternalGhgProtocolInventoryScreening: FC<IInternalGhgProtocolInventoryScreeningProps> = ({
  identity,
}) => {
  const { t } = useTranslation("ghg_protocol");
  const recordingPeriodGhgCategoriesQuery = useRecordingPeriodGhgCategories(identity);

  const { mainContentSxProps } = useCommentsAndAuditLogSidebarStateContext();

  return (
    <Box>
      {/* Main Content */}
      <Box display="flex" flexDirection="column" gap={2.5} sx={mainContentSxProps}>
        {/* Title & Subtitle */}
        <Box>
          <Typography variant="h3" component="h2" mb={1}>
            {t("inventory_screening.title")}
          </Typography>
          <Typography>
            <Trans
              i18nKey="inventory_screening.subtitle"
              t={t}
              components={{
                a: (
                  <a
                    href={AppInternalNavigationUtilities.getConfigurationRecordingPeriodOrganizationStructurePageUrl(
                      identity,
                    )}
                    target="_blank"
                    style={{ color: "inherit" }}
                  />
                ),
                icon: <OpenInNewTabIcon style={{ marginBottom: -4 }} />,
              }}
            />
          </Typography>
        </Box>
        {/* Back to normal view button for legacy UI */}
        <RequireFeatureFlag featureFlag={FeatureFlag.APP_SHELL_V2} negate>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            sx={{ mb: 2, mr: "auto" }}
            component={RouterLink}
            to={AppInternalNavigationUtilities.getGhgProtocolPageUrl(identity)}
          >
            {t("button_back_to_recording")}
          </Button>
        </RequireFeatureFlag>
        {/* Actual Page Content */}
        <QueryWrapper
          query={recordingPeriodGhgCategoriesQuery}
          build={(ghgCategories) => (
            <GhgInventoryScreeningScopesOverview
              recordingStructureIdentity={identity}
              ghgCategoriesByScope={ghgCategories}
            />
          )}
        />
      </Box>
      {/* Comments & Audit Log Paper */}
      <GhgCategoryCommentsAndAuditLogsSidebar recordingStructureIdentity={identity} />
    </Box>
  );
};
