import { Box, CircularProgress, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { SourcesConfigurationComponent } from "../../modules/sources/components/sources-configuration.component";
import { useSourcesQuery } from "../../modules/sources/sources.queries";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import {
  AppBreadCrumbs,
  IBreadCrumb,
} from "../../modules/common/components/app-bread-crumbs.component";
import { PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT } from "../../modules/common/components/app-shell-wrapper.component";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";

export function createSourcesConfigurationPageLink(organizationId: string, sourceId?: string) {
  return AppInternalNavigationUtilities.getConfigurationSourcesPageUrl(organizationId, sourceId);
}

interface ISourcesConfigurationPageProps {}

export const SourcesConfigurationPage: FC<ISourcesConfigurationPageProps> = () => {
  const { t } = useTranslation("sources_configuration_page");
  const { t: tNavItems } = useTranslation("side_bar_nav_items");

  const { organizationId } = useParams();

  const breadCrumbItems: IBreadCrumb[] = [
    {
      label: tNavItems("configuration"),
      to: AppInternalNavigationUtilities.getConfigurationPageUrl(organizationId!),
    },
    { label: tNavItems("configuration_sources") },
  ];

  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(createSourcesConfigurationPageLink(orgId).toString());
            },
          }
        : undefined,
      navigationItems: {
        activePath: createSourcesConfigurationPageLink(organizationId ?? "").toString(),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );

  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(createSourcesConfigurationPageLink(orgId).toString());
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  const currentOrganization = useCurrentOrganization();
  const sourcesQuery = useSourcesQuery(organizationId!);

  return (
    <Box>
      <AppBreadCrumbs items={breadCrumbItems} />
      <Box width="100%" maxWidth="100%" mx="auto">
        <Typography variant="h1" mb={2} mt={1}>
          {t("title")}
        </Typography>
        {!currentOrganization ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT}
          >
            <CircularProgress />
          </Box>
        ) : (
          <QueryWrapper
            query={sourcesQuery}
            loadingOverride={() => (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT}
              >
                <CircularProgress />
              </Box>
            )}
            build={(source) => (
              <SourcesConfigurationComponent organization={currentOrganization} sources={source} />
            )}
          />
        )}
      </Box>
    </Box>
  );
};
