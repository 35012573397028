import { Skeleton } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { useGetEmissionFactorQuery } from "../emission-factors.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";

interface IEmissionFactorNameProps {
  organizationId: string;
  emissionFactorId: string;
}

export const EmissionFactorName: FC<IEmissionFactorNameProps> = ({
  organizationId,
  emissionFactorId,
}) => {
  const { t } = useTranslation("emission_factor_databases");
  const translateContent = useTranslateContent();

  const emissionFactorQuery = useGetEmissionFactorQuery({
    organizationId,
    emissionFactorId,
  });

  return (
    <QueryWrapper
      query={emissionFactorQuery}
      loadingOverride={() => <EmissionFactorNameSkeleton />}
      build={(emissionFactor) => (
        <span>
          {translateContent(emissionFactor.title)}, {t(emissionFactor.database)}
        </span>
      )}
    />
  );
};

export const EmissionFactorNameSkeleton = () => (
  <Skeleton variant="text" width={140} sx={{ borderRadius: 4 }} />
);
