import { FC, useCallback } from "react";
import { Box, Checkbox } from "@mui/material";
import { FilterSelect } from "./filter-select.component";
import { FilterOpenSearch } from "./filter-open-search.component";
import { FilterMenuSearch } from "./filter-menu-search.component";
import { FilterType, IFilterConfig } from "./filter.type";

interface IFilterBarProps {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams?: URLSearchParams) => void;
  filters: IFilterConfig[];
}

export const SearchParamsFilters: FC<IFilterBarProps> = ({
  filters,
  searchParams,
  setSearchParams,
}) => {
  const handleChange = useCallback(
    (name: string, value: string | string[]) => {
      const params = new URLSearchParams(searchParams.toString());
      if (Array.isArray(value)) {
        params.delete(name);
        value.forEach((v) => params.append(name, v));
      } else {
        if (!params.getAll(name).includes(value)) {
          params.set(name, value);
        } else {
          params.delete(name);
        }
      }
      setSearchParams(params);
    },
    [searchParams, setSearchParams],
  );

  return (
    <>
      {filters.map((filter) => {
        switch (filter.type) {
          case FilterType.SearchOpen:
            return (
              <FilterOpenSearch
                key={filter.name}
                filter={filter}
                searchParams={searchParams}
                onChange={handleChange}
              />
            );
          case FilterType.SearchMenu:
            return (
              <FilterMenuSearch
                key={filter.name}
                filter={filter}
                searchParams={searchParams}
                onChange={handleChange}
              />
            );
          case FilterType.Select:
            return (
              <FilterSelect
                key={filter.name}
                filter={filter}
                searchParams={searchParams}
                onChange={handleChange}
              />
            );
          case FilterType.Checkbox:
            return (
              <Box
                key={filter.name}
                sx={{ fontSize: "14px", display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={!!searchParams.get(filter.name)}
                  onChange={(e) => handleChange(filter.name, e.target.checked ? "true" : "")}
                  size="small"
                />
                <Box>{filter.label}</Box>
              </Box>
            );
          default:
            return null;
        }
      })}
    </>
  );
};
