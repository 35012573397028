import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  IBaseSourceData,
  ISource,
  ISourceType,
  ISourceVisibility,
  ISourceVisibilityData,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditDialogWrapper } from "../common/dialogs/variants/edit-dialog.wrapper";
import { SourceVisibilityButton } from "./components/source-visibility-button.component";
import { useCanCurrentUserModifySource } from "./hooks/can-current-user-modify-source.hook";

const getFormDefaultValues = (source: IBaseSourceData | null | undefined): IBaseSourceData => {
  return {
    name: source?.name ?? "",
    description: source?.description ?? "",
    sourceType: source?.sourceType ?? ISourceType.DataSource,
    tags: source?.tags ?? [],
  };
};

interface ISourcesEditDialogCommonProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
}

interface ISourcesEditDialogCreateProps extends ISourcesEditDialogCommonProps {
  onOpenFileDialog?: undefined;
  editDialogData?: undefined;
  onClose: (data: IBaseSourceData | null, openFileDialog: boolean) => void;
  onUpdateSourceVisibility?: undefined;
}

interface ISourcesEditDialogEditProps extends ISourcesEditDialogCommonProps {
  onOpenFileDialog: () => void;
  editDialogData: ISource | undefined | null;
  onClose: (data: IBaseSourceData | null) => void;
  onUpdateSourceVisibility: (data: ISourceVisibilityData) => void;
}

type ISourceEditDialogProps = ISourcesEditDialogCreateProps | ISourcesEditDialogEditProps;

export const ISourceDropdownEnum: { [variant in ISourceType]: string } = {
  [ISourceType.DataSource]: "sources:source_type.data_source",
  [ISourceType.CalculationSource]: "sources:source_type.calculation_source",
  [ISourceType.MethodologySource]: "sources:source_type.methodology_source",
  [ISourceType.GuidelineOrTemplateSource]: "sources:source_type.guideline_or_template_source",
} as const;

export const SourceEditDialog: FC<ISourceEditDialogProps> = ({
  disabled,
  onClose,
  error,
  open,
  loading,
  onOpenFileDialog,
  editDialogData,
  onUpdateSourceVisibility,
}) => {
  const { t } = useTranslation(["sources", "sources_edit_dialog"]);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: getFormDefaultValues(editDialogData),
  });

  // Ensures fields in edit form are filled with data from proper source
  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(editDialogData));
    }
    // This is fine since we only want to reset the form when the dialog is opened
    // eslint-disable-next-line
  }, [open]);

  const handleEmitData = useCallback(
    (openFileDialog: boolean) => (data: IBaseSourceData) => {
      onClose(
        {
          name: data.name.trim(),
          description: data.description?.trim() || undefined,
          sourceType: data.sourceType,
          tags: [],
        },
        openFileDialog,
      );
    },
    [onClose],
  );

  const handleClickManageFiles = useCallback(() => {
    if (editDialogData) {
      onOpenFileDialog();
    } else {
      void handleSubmit(handleEmitData(true))();
    }
  }, [editDialogData, handleEmitData, handleSubmit, onOpenFileDialog]);

  const handleChangeVisibility = useCallback(
    (visibility: ISourceVisibility) => {
      onUpdateSourceVisibility?.({ visibility });
    },
    [onUpdateSourceVisibility],
  );

  // visibility changes are not automatically allowed for all public sources
  const canCurrentUserChangeVisibility = useCanCurrentUserModifySource(false)(editDialogData);
  const canCurrentUserModifySource = useCanCurrentUserModifySource()(editDialogData);

  return (
    <EditDialogWrapper
      open={open}
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>
            {t(
              editDialogData
                ? `sources_edit_dialog:${canCurrentUserModifySource ? "title_edit" : "title_view"}`
                : "sources_edit_dialog:title_create",
            )}
          </span>
          {/* only possible if source already exists + user is allowed to modify visibility */}
          {editDialogData && canCurrentUserChangeVisibility && (
            <SourceVisibilityButton
              onValueChange={handleChangeVisibility}
              value={editDialogData.visibility}
            />
          )}
        </Box>
      }
      mode={editDialogData ? "edit" : "create"}
      loading={loading}
      hasChanges={isDirty}
      onCancel={() => onClose(null, false)}
      onSave={handleSubmit(handleEmitData(false))}
      error={error}
      disabled={disabled}
      dialogProps={{ fullWidth: true, maxWidth: "md" }}
      cancelButtonContentOverride={!isDirty ? t("close", { ns: "buttons" }) : undefined}
      additionalStartActions={
        canCurrentUserModifySource ? (
          <Button
            variant="text"
            onClick={handleClickManageFiles}
            disabled={!editDialogData && !isDirty}
            sx={{ gap: 1 }}
          >
            {t("sources_edit_dialog:labels.manage_files")}
            {editDialogData?.files && (
              <Chip color="primary" size="small" label={editDialogData.files.length} />
            )}
          </Button>
        ) : null
      }
      readOnly={!canCurrentUserModifySource}
    >
      <Box display="flex" flexDirection="column" gap={2} mt={1}>
        <Controller
          control={control}
          name="name"
          rules={{ required: t("sources_edit_dialog:errors.name_required") }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              required
              label={t("sources_edit_dialog:labels.name")}
              {...field}
              error={!!error}
              helperText={error?.message}
              disabled={disabled || !canCurrentUserModifySource}
            />
          )}
        />

        <Controller
          name="sourceType"
          control={control}
          rules={{ required: t("sources_edit_dialog:errors.source_type_required") }}
          render={({ field, fieldState: { error } }) => (
            <FormControl variant="outlined" size="medium" error={!!error} fullWidth>
              <InputLabel id={t("sources_edit_dialog:labels.source_type")}>
                {t("sources_edit_dialog:labels.source_type")}
              </InputLabel>
              <Select
                {...field}
                required
                label={t("sources_edit_dialog:labels.source_type")}
                labelId="labels.source_type"
                error={!!error}
                disabled={disabled || !canCurrentUserModifySource}
              >
                {Object.keys(ISourceDropdownEnum).map((objectType, index) => (
                  <MenuItem key={objectType} value={objectType}>
                    {t(Object.values(ISourceDropdownEnum)[index])}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={t("sources_edit_dialog:labels.description")}
              multiline
              minRows={5}
              maxRows={15}
              {...field}
              error={!!error}
              helperText={error?.message}
              disabled={disabled || !canCurrentUserModifySource}
            />
          )}
        />
      </Box>
    </EditDialogWrapper>
  );
};
