export class FileUtilities {
  private static readonly UTF8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

  /**
   * Downloads a (csv) string as a UTF-8 encoded csv file.
   *
   * This method creates a Blob from the input string, prepends a UTF-8 BOM (Byte Order Mark),
   * and initiates a file download with the specified file name.
   *
   * @param data - The string content to be downloaded as a file.
   * @param fileName - The name to be given to the downloaded file, including extension.
   *
   * @returns void This method doesn't return a value, but triggers a file download in the browser.
   */
  public static downloadCsvStringAsUtf8CsvFile(data: string, fileName: string) {
    // Combine BOM with the CSV data
    const asBlob = new Blob([FileUtilities.UTF8_BOM, data], {
      type: "text/csv;charset=utf-8",
    });

    // actually download the file
    FileUtilities.downloadBlobAsFile(fileName, asBlob);
  }

  public static downloadBlobAsFile(fileName: string, blob: Blob) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
