import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCommentsAndAuditLogState } from "../hooks/comments-and-audit-log-state.hook";
import { useLocation } from "react-router-dom";
import { SxProps, Theme } from "@mui/material";
import { RouterUtilities } from "../../common/utilities/router.utilities";
import { IInputParameter } from "@netcero/netcero-core-api-client";
import { getContentSxPropsForCommentsAndChangelogSidebarPaper } from "../components/sidebar-comments-and-audit-log-paper.component";
import { useViewedCommentWithReset } from "../hooks/viewed-comment-id-with-reset.hook";

interface IDeoCommentsAndAuditLogContext {
  showCommentsAndAuditLogSideSection: boolean;
  toggleCommentsAndAuditLogSideSection: () => void;
  openCommentsAndAuditLogSideSection: () => void;
  closeCommentsAndAuditLogSideSection: () => void;
  mainContentSxProps: SxProps<Theme>;
  selectedInputParameterId: string | null;
  setSelectedInputParameterId: (ip: string | null) => void;
  disclosureRequirementInputParameter: IInputParameterWithDisclosureRequirementType | null;
  setDisclosureRequirementInputParameter: (
    dr: IInputParameterWithDisclosureRequirementType | null,
  ) => void;
  sectionId?: string | null;
  setSectionId?: (sectionId: string | null) => void;
}

export const DeoCommentsAndAuditLogContext = createContext<IDeoCommentsAndAuditLogContext | null>(
  null,
);

export interface IInputParameterWithDisclosureRequirementType {
  disclosureRequirement: IInputParameter | null;
  inputParameter: IInputParameter | null;
}

export const DeoCommentsAndAuditLogProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const {
    showCommentsAndAuditLogSideSection,
    toggleCommentsAndAuditLogSideSection: toggleCommentsAndAuditLogSideSectionInternal,
    openCommentsAndAuditLogSideSection,
    closeCommentsAndAuditLogSideSection: closeCommentsAndAuditLogSideSectionInternal,
  } = useCommentsAndAuditLogState();

  const [selectedInputParameterId, setSelectedInputParameterId] = useState<string | null>(null);
  const [disclosureRequirementInputParameter, setDisclosureRequirementInputParameter] =
    useState<IInputParameterWithDisclosureRequirementType | null>(null);
  const [sectionId, setSectionId] = useState<string | null>();

  const { isViewedCommentSet, resetViewedCommentAndExecuteAction } = useViewedCommentWithReset();

  useEffect(() => {
    if (!isViewedCommentSet) {
      return;
    }
    // Get selected IP UUID from URL fragment and set it as selected IP
    const hash = RouterUtilities.readUuidFromLocationFragment(location);
    if (!hash) {
      return;
    }

    setSelectedInputParameterId(hash);
    openCommentsAndAuditLogSideSection();
    // This is fine since we only want this check to run initially
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainContentSxProps = useMemo(
    () =>
      getContentSxPropsForCommentsAndChangelogSidebarPaper(
        showCommentsAndAuditLogSideSection && selectedInputParameterId !== null,
      ),
    [showCommentsAndAuditLogSideSection, selectedInputParameterId],
  );

  const value = useMemo(
    () => ({
      showCommentsAndAuditLogSideSection,
      toggleCommentsAndAuditLogSideSection: resetViewedCommentAndExecuteAction(
        toggleCommentsAndAuditLogSideSectionInternal,
      ),
      openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSection: resetViewedCommentAndExecuteAction(
        closeCommentsAndAuditLogSideSectionInternal,
      ),
      mainContentSxProps,
      selectedInputParameterId,
      setSelectedInputParameterId,
      disclosureRequirementInputParameter,
      setDisclosureRequirementInputParameter,
      sectionId,
      setSectionId,
    }),
    [
      showCommentsAndAuditLogSideSection,
      resetViewedCommentAndExecuteAction,
      toggleCommentsAndAuditLogSideSectionInternal,
      openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSectionInternal,
      mainContentSxProps,
      selectedInputParameterId,
      setSelectedInputParameterId,
      disclosureRequirementInputParameter,
      setDisclosureRequirementInputParameter,
      sectionId,
      setSectionId,
    ],
  );

  return (
    <DeoCommentsAndAuditLogContext.Provider value={value}>
      {children}
    </DeoCommentsAndAuditLogContext.Provider>
  );
};

export const useDeoCommentsAndAuditLogContext = () => {
  const context = useContext(DeoCommentsAndAuditLogContext);
  if (!context) {
    throw new Error(
      "useDeoCommentsAndAuditLogContext must be used within DeoCommentsAndAuditLogProvider",
    );
  }
  return context;
};
