import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { ILocalEvaluationResult } from "@netcero/netcero-common";

export const getEvaluationForRecordingPeriodQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
  inputParameterRecordingStructureId?: string,
) => [
  "recordingPeriodEvaluation",
  organizationId,
  recordingPeriodId,
  inputParameterRecordingStructureId,
];

export const useEvaluationForRecordingPeriodQuery = (
  organizationId: string,
  recordingPeriodId: string,
  keys: string[],
  inputParameterRecordingStructureId: string,
) => {
  const sortedKey = [...keys].sort();
  return useQuery({
    queryKey: [
      ...getEvaluationForRecordingPeriodQueryKey(
        organizationId,
        recordingPeriodId,
        inputParameterRecordingStructureId,
      ),
      sortedKey,
    ],
    queryFn: async () => {
      return CoreApiService.RecordingPeriodEvaluationApi.getRecordingPeriodEvaluation(
        organizationId,
        recordingPeriodId,
        sortedKey,
        inputParameterRecordingStructureId,
      )
        .then((req) => req())
        .then((r) => r.data.evaluationResult as ILocalEvaluationResult);
    },
  });
};
