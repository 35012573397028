// Interface to define the structure of the combined state returned by the hook
type ICombinedQueryState = {
  isLoading: boolean;
} & (
  | {
      isError: false;
      error?: null;
    }
  | {
      isError: true;
      error: Error;
    }
);

/**
 * Custom hook to combine the loading, error, and error message states of multiple queries
 * @param queries - An array of query results to combine. Note: The size of the queries array should NOT change.
 */
export const useCombineQueryState = (...queries: ICombinedQueryState[]): ICombinedQueryState => {
  // Determine if any of the queries are still loading
  const isLoading = queries.some((query) => query.isLoading);

  // Determine if any of the queries have encountered an error
  const isError = queries.some((query) => query.isError);

  // Get the first error object if any query has encountered an error, otherwise null
  const error = isError ? queries.find((query) => query.isError)?.error ?? null : null;

  // Return the combined state object
  // as ICombinedQueryState is fine since it's a subset of ICombinedMutationState
  return { isLoading, isError, error } as ICombinedQueryState;
};
