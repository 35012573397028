import { FC, useMemo } from "react";
import {
  ITableOfContentsEntry,
  TableOfContents,
} from "../../common/components/table-of-contents.component";
import {
  GhgCategoriesUtilities,
  IGhgCategoriesPerScopeForRecordingPeriod,
  IInputParameterRecordingStructureIdentity,
} from "@netcero/netcero-common";
import { Box } from "@mui/material";
import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";
import {
  useRenderGhgCategoryName,
  useRenderGhgScopeName,
} from "../../ghg-protocol/ghg-protocol.hooks";

interface IGhgProtocolCategoriesTableOfContentsProps {
  identity: IInputParameterRecordingStructureIdentity;
  ghgCategories: IGhgCategoriesPerScopeForRecordingPeriod;
  viewedGhgCategory: IGhgCategoriesIdentifier | null;
}

export const GhgCategoriesOverviewTableOfContents: FC<
  IGhgProtocolCategoriesTableOfContentsProps
> = ({ identity, ghgCategories, viewedGhgCategory }) => {
  const renderScopeName = useRenderGhgScopeName();
  const renderGhgCategoryName = useRenderGhgCategoryName();

  const tocEntries: ITableOfContentsEntry[] = useMemo(() => {
    return Object.entries(ghgCategories).map(([scope, categories]) => ({
      id: scope,
      title: renderScopeName(scope, { style: "long" }),
      exclude: categories.every((category) => category.exclude),
      children: categories.map(({ ghgCategoryIdentifier, exclude }) => ({
        id: ghgCategoryIdentifier,
        title: renderGhgCategoryName(ghgCategoryIdentifier),
        exclude: !!exclude,
      })),
    }));
  }, [ghgCategories, renderGhgCategoryName, renderScopeName]);

  return (
    <Box>
      <TableOfContents
        entries={tocEntries}
        activeEntryId={viewedGhgCategory}
        getLinkForEntry={forwardFirstCategoryIdIfScopeIsClicked((categoryToNavigateTo) => {
          return AppInternalNavigationUtilities.getGhgProtocolPageUrl(identity, {
            viewedScope:
              GhgCategoriesUtilities.getScopeOfGhgCategory(categoryToNavigateTo) ?? undefined,
            viewedGhgCategoryId: categoryToNavigateTo,
          });
        })}
      />
    </Box>
  );
};

/**
 * Creates a wrapper function that forwards the first child category ID if a scope is clicked,
 * or the entry ID if it's already a category.
 */
function forwardFirstCategoryIdIfScopeIsClicked<T>(cb: (entry: IGhgCategoriesIdentifier) => T) {
  return (entry: ITableOfContentsEntry) => {
    const categoryToNavigateTo = entry.children ? entry.children[0].id : entry.id;
    return cb(categoryToNavigateTo as IGhgCategoriesIdentifier);
  };
}
