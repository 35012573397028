import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { IEmissionFactorUnit } from "@netcero/netcero-core-api-client";
import { EMISSION_UNIT_TEXT } from "./emission-factors.constants";
import { IRenderEmissionFactorUnitType } from "@netcero/netcero-common";

export interface IRenderEmissionFactorUnitHookOptions {
  withPrefix?: boolean;
}

export const useRenderEmissionFactorUnit = () => {
  const { t } = useTranslation("emission_factor_units");

  return useCallback(
    (
      unit: IEmissionFactorUnit,
      type: IRenderEmissionFactorUnitType = "short",
      options?: IRenderEmissionFactorUnitHookOptions,
    ) => {
      const unitTranslation = t(`${type}.${unit}`);
      return options?.withPrefix ? `${EMISSION_UNIT_TEXT} / ${unitTranslation}` : unitTranslation;
    },
    [t],
  );
};
