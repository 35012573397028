import { useQuery } from "@tanstack/react-query";
import { ReferenceBudgetMappingUtilities } from "../utilities/reference-budget-mapping.utilities";
import { CoreApiService } from "../../core-api/core-api.service";

export const getReferenceBudgetsQueryKey = (organizationId: string) => [
  "reference-budgets",
  organizationId,
];

/**
 * Fetches all reference budgets for a given organization
 * @param organizationId
 */
export const useReferenceBudgetsQuery = (organizationId: string) => {
  return useQuery({
    queryKey: getReferenceBudgetsQueryKey(organizationId),
    queryFn: async () => {
      return CoreApiService.ReferenceBudgetsApi.getReferenceBudgetsForOrganization(organizationId)
        .then((req) => req())
        .then((res) => res.data)
        .then((data) => ({
          referenceBudgets: data.referenceBudgets.map(
            ReferenceBudgetMappingUtilities.convertApiReferenceBudgetToLocal,
          ),
        }));
    },
  });
};
