import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";
import { PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT } from "../../modules/common/components/app-shell-wrapper.component";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";

interface IEvaluationSelectDataEntryObjectPageProps {
  evaluationKey: string;
}
export const RedirectEvaluationToRootDEOPage: FC<IEvaluationSelectDataEntryObjectPageProps> = ({
  evaluationKey,
}) => {
  const { organizationId, recordingPeriodId, recordingStructureId } = useParams();

  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  return (
    <QueryWrapper
      query={organizationStructureQuery}
      loadingOverride={() => (
        <CenteredCircularProgress minHeight={PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT} />
      )}
      build={(organizationStructure) => (
        <OrganizationStructureDraftWrapper
          organizationId={organizationId!}
          recordingPeriodId={recordingPeriodId!}
          organizationStructure={organizationStructure}
          build={(structure) => (
            <Navigate
              to={AppInternalNavigationUtilities.getEvaluationSelectDataEntryObjectPageUrl(
                organizationId!,
                recordingPeriodId!,
                evaluationKey,
                recordingStructureId!,
                structure.id,
              )}
              replace
            />
          )}
        />
      )}
    />
  );
};
