import { FC, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getTableCellNodeFromLexicalNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $isTableCellNode,
  $isTableSelection,
  getTableElement,
  getTableObserverFromTableElement,
  TableCellNode,
  TableObserver,
} from "@lexical/table";
import { TableActionMenu } from "../components/table-action-menu";
import { Box, IconButton } from "@mui/material";
import { ChevronDownIcon } from "../../common/constants/tabler-icon.constants";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  getDOMSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { mergeRegister } from "@lexical/utils";

interface ITableActionMenuProps {
  anchorElement: HTMLElement;
}

const TableCellActionMenuContainer: FC<ITableActionMenuProps> = ({ anchorElement }) => {
  const [editor] = useLexicalComposerContext();

  const menuButtonRef = useRef<HTMLDivElement | null>(null);
  const menuRootRef = useRef<HTMLButtonElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [tableCellNode, setTableMenuCellNode] = useState<TableCellNode | null>(null);

  const $moveMenu = useCallback(() => {
    const menu = menuButtonRef.current;
    const selection = $getSelection();
    const nativeSelection = getDOMSelection(editor._window);
    const activeElement = document.activeElement;
    function disable() {
      if (menu) {
        menu.classList.remove("table-cell-action-button-container--active");
        menu.classList.add("table-cell-action-button-container--inactive");
      }
      setTableMenuCellNode(null);
    }

    if (selection == null || menu == null) {
      return disable();
    }

    const rootElement = editor.getRootElement();
    let tableObserver: TableObserver | null = null;
    let tableCellParentNodeDOM: HTMLElement | null = null;

    if (
      $isRangeSelection(selection) &&
      rootElement !== null &&
      nativeSelection !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(
        selection.anchor.getNode(),
      );

      if (tableCellNodeFromSelection == null) {
        return disable();
      }

      tableCellParentNodeDOM = editor.getElementByKey(tableCellNodeFromSelection.getKey());

      if (tableCellParentNodeDOM == null || !tableCellNodeFromSelection.isAttached()) {
        return disable();
      }

      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNodeFromSelection);
      const tableElement = getTableElement(tableNode, editor.getElementByKey(tableNode.getKey()));

      if (tableElement === null) {
        throw new Error("TableActionMenu: Expected to find tableElement in DOM");
      }

      tableObserver = getTableObserverFromTableElement(tableElement);
      setTableMenuCellNode(tableCellNodeFromSelection);
    } else if ($isTableSelection(selection)) {
      const anchorNode = $getTableCellNodeFromLexicalNode(selection.anchor.getNode());
      if (!$isTableCellNode(anchorNode)) {
        throw new Error("TableSelection anchorNode must be a TableCellNode");
      }
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(anchorNode);
      const tableElement = getTableElement(tableNode, editor.getElementByKey(tableNode.getKey()));
      if (tableElement === null) {
        throw new Error("TableActionMenu: Expected to find tableElement in DOM");
      }
      tableObserver = getTableObserverFromTableElement(tableElement);
      tableCellParentNodeDOM = editor.getElementByKey(anchorNode.getKey());

      if (tableCellParentNodeDOM === null) {
        return disable();
      }
    } else if (!activeElement) {
      return disable();
    }
    if (tableObserver === null || tableCellParentNodeDOM === null) {
      return disable();
    }
    const enabled = !tableObserver || !tableObserver.isSelecting;
    menu.classList.toggle("table-cell-action-button-container--active", enabled);
    menu.classList.toggle("table-cell-action-button-container--inactive", !enabled);
    if (enabled) {
      const menuButtonDOM = menuButtonRef.current as HTMLButtonElement | null;
      if (!menuButtonDOM) {
        return;
      }

      const tableCellRect = tableCellParentNodeDOM.getBoundingClientRect();
      const anchorRect = anchorElement.getBoundingClientRect();
      const menuRect = menuButtonDOM.getBoundingClientRect();

      const top = tableCellRect.top - anchorRect.top;
      const left = tableCellRect.right - anchorRect.left - menuRect.width;
      menu.style.transform = `translate(${left}px, ${top}px)`;
    }
  }, [editor, anchorElement]);

  useEffect(() => {
    // We call the $moveMenu callback every time the selection changes,
    // once up front, and once after each pointerUp
    let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
    const callback = () => {
      timeoutId = undefined;
      editor.getEditorState().read($moveMenu);
    };
    const delayedCallback = () => {
      if (timeoutId === undefined) {
        timeoutId = setTimeout(callback, 0);
      }
      return false;
    };
    return mergeRegister(
      editor.registerUpdateListener(delayedCallback),
      editor.registerCommand(SELECTION_CHANGE_COMMAND, delayedCallback, COMMAND_PRIORITY_CRITICAL),
      editor.registerRootListener((rootElement, prevRootElement) => {
        if (prevRootElement) {
          prevRootElement.removeEventListener("pointerup", delayedCallback);
        }
        if (rootElement) {
          rootElement.addEventListener("pointerup", delayedCallback);
          delayedCallback();
        }
      }),
      () => clearTimeout(timeoutId),
    );
  });

  const prevTableCellDOM = useRef(tableCellNode);

  useEffect(() => {
    if (prevTableCellDOM.current !== tableCellNode) {
      setIsMenuOpen(false);
    }

    prevTableCellDOM.current = tableCellNode;
  }, [prevTableCellDOM, tableCellNode]);

  return (
    <Box className="table-cell-action-button-container" ref={menuButtonRef}>
      {tableCellNode && (
        <>
          <TableActionMenu
            open={isMenuOpen}
            contextRef={menuRootRef}
            onClose={() => setIsMenuOpen(false)}
            tableCellNode={tableCellNode}
          />

          <IconButton
            ref={menuRootRef}
            className="table-cell-action-button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <ChevronDownIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

interface ITableActionMenuPluginProps {
  anchorElement: HTMLElement | null;
}

export const TableActionsMenuPlugin: FC<ITableActionMenuPluginProps> = ({ anchorElement }) => {
  if (!anchorElement) {
    return null;
  }

  return createPortal(
    <TableCellActionMenuContainer anchorElement={anchorElement} />,
    anchorElement,
  );
};
