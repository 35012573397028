import { Box } from "@mui/material";

import { FC, PropsWithChildren } from "react";
import { ContextBasedSideBar, SideBarContext } from "../contexts/side-bar.context";
import {
  SIDE_BAR_CORNER_RADIUS_V1,
  SIDE_BAR_EXPANDED_WIDTH_V1,
  SIDE_BAR_SHRUNK_WIDTH_V1,
  SIDE_BAR_TRANSITION_DURATION_V1,
} from "./side-bar-v1.component";
import {
  getSidebarElementAnimationProperties,
  SIDE_BAR_CORNER_RADIUS_V2,
  SIDE_BAR_EXPANDED_WIDTH_V2,
  SIDE_BAR_SHRUNK_WIDTH_V2,
} from "./side-bar-v2.component";
import { LS_SIDEBAR_KEY } from "../constants/localstorage.constants";
import { useLocalStorage } from "@uidotdev/usehooks";
import { FeatureFlag } from "../constants/feature-flags.constants";
import { ContextBasedTopBar, TopBarContext } from "../contexts/top-bar-context";
import { TOP_BAR_HEIGHT } from "./top-bar.component";
import { useIsFeatureFlagEnabled } from "../hooks/is-feature-flag-enabled.hook";

export const CONTENT_CONTAINER_ID = "content-box";
export const CONTENT_X_PADDING = 32;
export const CONTENT_BREADCRUMB_HEIGHT = 24;
export const HEADER_HEIGHT = 48;
export const INTERCOM_HEIGHT = 64;
export const CONTENT_Y_PADDING = 40;
export const PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT = `calc(100vh - ${
  HEADER_HEIGHT + INTERCOM_HEIGHT
}px)`;

/**
 * This component sets up the basic layout of the app (mainly the side bar).
 * The Sidebar uses the SideBarContext which allows children to set the sideBar configuration by using the SideBarContext.
 * (You probably want to use the custom useCustomSideBarContent hooks to set the SideBarContext)
 */
export const AppShellWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useLocalStorage(LS_SIDEBAR_KEY, false);

  // TODO: NC-XYZ Clean up - delete sideBarContentV1
  const sideBarContentV1 = (
    <SideBarContext>
      <ContextBasedSideBar
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      />
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        ml={`${open ? SIDE_BAR_EXPANDED_WIDTH_V1 : SIDE_BAR_SHRUNK_WIDTH_V1}px`}
        sx={{
          transition: `margin-left ${SIDE_BAR_TRANSITION_DURATION_V1}ms`,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          pl={`${SIDE_BAR_CORNER_RADIUS_V1}px`}
          sx={{
            transition: `padding-left ${SIDE_BAR_TRANSITION_DURATION_V1}ms`,
            px: `${CONTENT_X_PADDING + SIDE_BAR_CORNER_RADIUS_V1}px !important`,
            py: `${CONTENT_Y_PADDING}px`,
          }}
        >
          {children}
        </Box>
      </Box>
    </SideBarContext>
  );

  const sideBarContentV2 = (
    <TopBarContext>
      <ContextBasedTopBar open={open} />
      <SideBarContext>
        <ContextBasedSideBar
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        />
        <Box
          position="absolute"
          top={`${TOP_BAR_HEIGHT}px`}
          right={0}
          bottom={0}
          left={`${
            (open ? SIDE_BAR_EXPANDED_WIDTH_V2 : SIDE_BAR_SHRUNK_WIDTH_V2) +
            SIDE_BAR_CORNER_RADIUS_V2
          }px`}
          sx={{
            ...getSidebarElementAnimationProperties("left"),
            backgroundColor: (theme) => theme.palette.common.white,
          }}
        >
          <Box
            height="100%"
            sx={{
              ...getSidebarElementAnimationProperties("padding-left"),
              border: "1px solid",
              borderColor: (theme) => theme.palette.grey[100],
              borderTopLeftRadius: 20,
              backgroundColor: (theme) => theme.palette.background.default,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              id={CONTENT_CONTAINER_ID}
              flex={1}
              overflow="auto"
              sx={{
                height: "100%",
                px: `${CONTENT_X_PADDING}px`,
                py: `${CONTENT_Y_PADDING}px`,
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </SideBarContext>
    </TopBarContext>
  );

  // TODO: NC-XYZ Clean up - only return V2
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);
  return isFeatureFlagEnabled ? sideBarContentV2 : sideBarContentV1;
};
