import React, { FC } from "react";
import { Box, Fade, TableContainer, Typography } from "@mui/material";
import {
  AppUrlUtilities,
  IGhgCategoriesPerScopeForRecordingPeriod,
  IInputParameterRecordingStructureIdentity,
} from "@netcero/netcero-common";
import { useSearchParamEnumValue } from "../../common/hooks/use-search-param-enum-value.hook";
import { IGhgProtocolScopeEnum } from "@netcero/netcero-core-api-client";
import { StyledCardWithOnHoverShadow } from "../../common/components/styled-card.component";
import { GhgScopeTabs } from "../../ghg-protocol/components/ghg-scope-tabs.component";
import { GhgScopeName } from "../../ghg-protocol/components/ghg-scope-name.component";
import { GhgInventoryScreeningCategoriesTable } from "./ghg-inventory-screening-categories-table.component";

interface IGhgInventoryScreeningScopesOverviewProps {
  recordingStructureIdentity: IInputParameterRecordingStructureIdentity;
  ghgCategoriesByScope: IGhgCategoriesPerScopeForRecordingPeriod;
}

export const GhgInventoryScreeningScopesOverview: FC<IGhgInventoryScreeningScopesOverviewProps> = ({
  recordingStructureIdentity,
  ghgCategoriesByScope,
}) => {
  const { value: viewedScope, updateEnumValue: updateViewedScope } =
    useSearchParamEnumValue<IGhgProtocolScopeEnum>(
      AppUrlUtilities.VIEWED_SCOPE_QUERY_PARAM,
      IGhgProtocolScopeEnum,
      IGhgProtocolScopeEnum.Scope1,
    );

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* Scope Tabs Card */}
      <StyledCardWithOnHoverShadow>
        <Box px={2.5} pt={1.5} pb={2}>
          {/* Tabs */}
          <GhgScopeTabs value={viewedScope} onChange={updateViewedScope} />
        </Box>
      </StyledCardWithOnHoverShadow>
      {/* Ghg Categories Card */}
      <Fade key={viewedScope} in appear>
        <StyledCardWithOnHoverShadow>
          <Box display="flex" flexDirection="column" gap={1.5} p={2.5}>
            {/* Viewed Scope Title */}
            <Typography component="h2" variant="h5">
              <GhgScopeName ghgScope={viewedScope} options={{ style: "long" }} />
            </Typography>
            {/* Ghg Categories Table */}
            <TableContainer>
              <GhgInventoryScreeningCategoriesTable
                recordingStructureIdentity={recordingStructureIdentity}
                ghgCategories={ghgCategoriesByScope[viewedScope] ?? []}
              />
            </TableContainer>
          </Box>
        </StyledCardWithOnHoverShadow>
      </Fade>
    </Box>
  );
};
