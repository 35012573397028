import { Box, SvgIcon, Typography } from "@mui/material";
import { IIROState } from "@netcero/netcero-core-api-client";
import { IconProps } from "@tabler/icons-react";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DMA_IRO_STATE_COLORS, DMA_IRO_STATE_ICONS } from "./dma.constants";
import { ChevronDownIcon, ChevronUpIcon } from "../../common/constants/tabler-icon.constants";

interface IIROStateIndicatorProps {
  state: IIROState;
  isMenuOpen?: boolean;
  shouldRenderChevronIcon?: boolean;
}

export const IROStateIndicator: FC<IIROStateIndicatorProps> = ({
  state,
  isMenuOpen,
  shouldRenderChevronIcon,
}) => {
  const { t } = useTranslation("dma_common");

  const [color, Icon] = useMemo(() => {
    return [DMA_IRO_STATE_COLORS[state], DMA_IRO_STATE_ICONS[state] as FC<IconProps>];
  }, [state]);

  return (
    <Box display="flex" alignItems="end">
      <SvgIcon color={color}>
        <Icon />
      </SvgIcon>
      {/* TODO: subtitle1 isn't fitting here, update at some point */}
      <Box display="flex" flexDirection="row" alignItems="center" gap={0.5}>
        <Typography variant="subtitle1">{t(`iro_state_${state}`)}</Typography>
        {shouldRenderChevronIcon && (
          <Box flexShrink={0} lineHeight={0}>
            {isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Box>
        )}
      </Box>
    </Box>
  );
};
