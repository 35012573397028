import { Box, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { FC } from "react";

export interface ITopBarNavigationItem {
  key: string;
  label: string;
  path: string;
}

export interface ITopBarTabNavigationProps {
  activeTab: string;
  items: Array<ITopBarNavigationItem>;
}

export const TopBarTabNavigationTabs: FC<ITopBarTabNavigationProps> = ({ activeTab, items }) => {
  return (
    <Box display="flex" flexDirection="row" gap={2}>
      {items.map((item) => (
        <Button
          component={RouterLink}
          key={item.key}
          variant={activeTab === item.key ? "contained" : "text"}
          color="inherit"
          to={item.path}
          sx={{
            px: 2,
          }}
        >
          {item.label}
        </Button>
      ))}
    </Box>
  );
};
