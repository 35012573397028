/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IEmissionFactorUnit = {
  FZkm: "FZkm",
  Pkm: "Pkm",
  Tkm: "Tkm",
  T: "t",
  L: "l",
  M2: "m2",
  KWh: "kWh",
  MioEuros: "mio_euros",
  KgCo2eq: "kgCO2eq",
} as const;

export type IEmissionFactorUnit = (typeof IEmissionFactorUnit)[keyof typeof IEmissionFactorUnit];
