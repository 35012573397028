import { FC } from "react";
import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";

import {
  IGhgCategoryNameHookOptions,
  useRenderGhgCategoryName,
} from "../../ghg-protocol/ghg-protocol.hooks";

interface IGhgCategoryNameProps {
  ghgCategory: IGhgCategoriesIdentifier;
  options?: IGhgCategoryNameHookOptions;
}

export const GhgCategoryName: FC<IGhgCategoryNameProps> = ({ ghgCategory, options }) => {
  const renderGhgCategoryName = useRenderGhgCategoryName();
  return renderGhgCategoryName(ghgCategory, options);
};
