import {
  IHydratedCompleteInputParameterRecordingESRSStructure,
  IHydratedCompleteInputParameterRecordingStructureGroupDisclosureRequirementIP,
  IHydratedCompleteInputParameterRecordingVSMEStructure,
  IHydratedInputParameterRecordingESRSStructure,
  IHydratedInputParameterRecordingStructureGroupDisclosureRequirement,
  IHydratedInputParameterRecordingStructureGroupDisclosureRequirementIP,
  IHydratedInputParameterRecordingStructureGroupDisclosureRequirementSection,
  IHydratedInputParameterRecordingVSMEStructure,
} from "./input-parameter-recording-esrs-structures.interfaces";
import { ILocalDataEntryObjectInputParameter } from "../../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import {
  IAction,
  IInputParameter,
  IInputParameterESRSMetaData,
  IInputParameterESRSMetaDataPhaseInEligibility,
  IInputParameterRecordingStructureGroupDisclosureRequirement,
  IInputParameterRecordingStructureGroupDisclosureRequirementIP,
  IInputParameterRecordingStructureGroupDisclosureRequirementSection,
  IInputParameterRecordingStructureGroupESRS,
  IInputParameterRecordingStructureGroupVSME,
  IPolicy,
  ITarget,
  IWithUsers,
} from "@netcero/netcero-core-api-client";
import { InputParameterRecordingStructuresUtilities } from "../input-parameter-recording-structures.utilities";
import { isEnum } from "class-validator";
import { EsrsFilters, StatusExcludedOption } from "../../filters/filter.constants";
import {
  FilterUtilities,
  LookupUtilities,
  OrganizationRole,
  UndefinedOrNull,
} from "@netcero/netcero-common";
import { EntityKey } from "@netcero/netcero-entities";
import { EsrsValueEditingUtilities } from "../../data-entry-object-values/esrs/value-editing/esrs-value-editing.utilities";
import { IEsrsFilters } from "../../filters/filter.type";
import { InputParameterDisplayInformationLookup } from "../../data-entry-object-values/hooks/conditional-display-input-parameters.hook";

/** EFRAG IDs of DRs which are eligible for phase in */
export enum EligiblePhaseInDrsEfragIds {
  SBM_1 = "SBM-1",
  SBM_3 = "SBM-3",
  E1_6 = "E1-6",
  E1_9 = "E1-9",
  E2_6 = "E2-6",
  E3_5 = "E3-5",
  E4_6 = "E4-6",
  E5_6 = "E5-6",
  S1_7 = "S1-7",
  S1_8 = "S1-8",
  S1_11 = "S1-11",
  S1_12 = "S1-12",
  S1_13 = "S1-13",
  S1_14 = "S1-14",
  S1_15 = "S1-15",
}

// Interface that combines policy, target, and action data using in filtering
interface IFiltersPTAData {
  policies: IPolicy[];
  targets: ITarget[];
  actions: IAction[];
}

type IEitherESRSOrVSMERecordingStructureGroup =
  | IInputParameterRecordingStructureGroupESRS
  | IInputParameterRecordingStructureGroupVSME;

type IConditionalRecordingStructureGroup<S extends IEitherESRSOrVSMERecordingStructureGroup> =
  S extends IInputParameterRecordingStructureGroupESRS
    ? IHydratedInputParameterRecordingESRSStructure
    : IHydratedInputParameterRecordingVSMEStructure;

export type IEitherESRSOrVSMEHydratedRecordingStructureGroup =
  | IHydratedInputParameterRecordingESRSStructure
  | IHydratedInputParameterRecordingVSMEStructure;

type IConditionalHydratedRecordingStructureGroup<
  S extends IEitherESRSOrVSMEHydratedRecordingStructureGroup,
> = S extends IHydratedInputParameterRecordingESRSStructure
  ? IHydratedInputParameterRecordingESRSStructure
  : IHydratedInputParameterRecordingVSMEStructure;

type IEitherESRSOrVSMEHydratedCompleteRecordingStructureGroup =
  | IHydratedCompleteInputParameterRecordingESRSStructure
  | IHydratedCompleteInputParameterRecordingVSMEStructure;

type IConditionalHydratedCompleteRecordingStructureGroup<
  S extends IEitherESRSOrVSMEHydratedRecordingStructureGroup,
> = S extends IHydratedInputParameterRecordingESRSStructure
  ? IHydratedCompleteInputParameterRecordingESRSStructure
  : IHydratedCompleteInputParameterRecordingVSMEStructure;

export class InputParameterRecordingEsrsStructuresUtilities {
  /**
   * Hydrates the given structure with the given data entry object input parameters
   * @param esrsStructure The structure to hydrate
   * @param dataEntryObjectInputParameters The data entry object input parameters to hydrate the structure with
   */
  public static hydrateStructureGroup<S extends IEitherESRSOrVSMERecordingStructureGroup>(
    esrsStructure: S,
    dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[],
  ): IConditionalRecordingStructureGroup<S> {
    const inputParameterLookup =
      InputParameterRecordingStructuresUtilities.getInputParameterEntryLookupMap(
        dataEntryObjectInputParameters,
      );

    const sections = esrsStructure.sections.map((section) =>
      InputParameterRecordingEsrsStructuresUtilities.hydrateDisclosureRequirementSection(
        section,
        inputParameterLookup,
      ),
    );

    const hydratedStructure: IEitherESRSOrVSMEHydratedRecordingStructureGroup =
      esrsStructure.type === "vsme"
        ? {
            ...esrsStructure,
            sections,
          }
        : {
            ...esrsStructure,
            sections,
            // Handle Phase In
            phaseIn: esrsStructure.phaseIn
              ? {
                  ...esrsStructure.phaseIn,
                  inputParameters: esrsStructure.phaseIn.inputParameterIds.map(
                    (inputParameterId) => inputParameterLookup.get(inputParameterId) ?? null,
                  ),
                }
              : null,
          };

    return hydratedStructure as IConditionalRecordingStructureGroup<S>;
  }

  private static hydrateDisclosureRequirementSection(
    section: IInputParameterRecordingStructureGroupDisclosureRequirementSection,
    inputParametersLookup: Map<string, ILocalDataEntryObjectInputParameter>,
  ): IHydratedInputParameterRecordingStructureGroupDisclosureRequirementSection {
    return {
      ...section,
      disclosureRequirements: section.disclosureRequirements.map((disclosureRequirement) =>
        InputParameterRecordingEsrsStructuresUtilities.hydrateDisclosureRequirement(
          disclosureRequirement,
          inputParametersLookup,
        ),
      ),
    };
  }

  private static hydrateDisclosureRequirement(
    disclosureRequirement: IInputParameterRecordingStructureGroupDisclosureRequirement,
    inputParametersLookup: Map<string, ILocalDataEntryObjectInputParameter>,
  ): IHydratedInputParameterRecordingStructureGroupDisclosureRequirement {
    const disclosureRequirementInputParameter = inputParametersLookup.get(disclosureRequirement.id);

    if (!disclosureRequirementInputParameter) {
      console.warn(
        `Could not find disclosure requirement with id (input parameter that holds disclosure requirement data): ${disclosureRequirement.id}`,
      );
    }

    return {
      ...disclosureRequirement,
      disclosureRequirementInputParameter: disclosureRequirementInputParameter ?? null,
      inputParameters: disclosureRequirement.inputParameters.map((inputParameter) =>
        InputParameterRecordingEsrsStructuresUtilities.hydrateDisclosureRequirementInputParameter(
          inputParameter,
          inputParametersLookup,
        ),
      ),
    };
  }

  private static hydrateDisclosureRequirementInputParameter(
    inputParameter: IInputParameterRecordingStructureGroupDisclosureRequirementIP,
    inputParametersLookup: Map<string, ILocalDataEntryObjectInputParameter>,
  ): IHydratedInputParameterRecordingStructureGroupDisclosureRequirementIP {
    const deoInputParameter = inputParametersLookup.get(inputParameter.parameterId);

    if (!deoInputParameter) {
      console.warn(
        `Could not find input parameter inside disclosure requirement id: ${inputParameter.parameterId}`,
      );
    }

    return {
      ...inputParameter,
      inputParameter: deoInputParameter ?? null,
    };
  }

  // Clean Up Structure Group

  /**
   * Cleans up the given hydrated structure group by removing incomplete disclosure requirements and input parameters.
   * @param hydratedStructure
   * @returns A tuple containing the cleaned up structure, incomplete disclosure requirements and incomplete input parameters
   */
  public static cleanUpStructureGroup<S extends IEitherESRSOrVSMEHydratedRecordingStructureGroup>(
    hydratedStructure: S,
  ): [
    IConditionalHydratedCompleteRecordingStructureGroup<S>,
    IHydratedInputParameterRecordingStructureGroupDisclosureRequirement[],
    IHydratedInputParameterRecordingStructureGroupDisclosureRequirementIP[],
  ] {
    const incompleteDisclosureRequirements: IHydratedInputParameterRecordingStructureGroupDisclosureRequirement[] =
      [];
    const incompleteInputParameters: IHydratedInputParameterRecordingStructureGroupDisclosureRequirementIP[] =
      [];

    const sections = hydratedStructure.sections.map((section) => ({
      ...section,
      disclosureRequirements: section.disclosureRequirements
        // Remove all incomplete disclosure requirements and add to incomplete list
        .filter((disclosureRequirement) => {
          if (disclosureRequirement.disclosureRequirementInputParameter === null) {
            incompleteDisclosureRequirements.push(disclosureRequirement);
            return false;
          }
          return true;
        })
        .map((disclosureRequirement) => ({
          ...disclosureRequirement,
          // Now only the complete disclosure requirements are left
          disclosureRequirementInputParameter:
            disclosureRequirement.disclosureRequirementInputParameter!,
          // Remove all incomplete disclosure requirements and add to incomplete list
          inputParameters: disclosureRequirement.inputParameters.filter((inputParameter) => {
            if (inputParameter.inputParameter === null) {
              incompleteInputParameters.push(inputParameter);
              return false;
            }
            return true;
          }) as IHydratedCompleteInputParameterRecordingStructureGroupDisclosureRequirementIP[], // This is fine since we filtered out the incomplete ones
        })),
    }));

    const completeStructure: IEitherESRSOrVSMEHydratedCompleteRecordingStructureGroup =
      hydratedStructure.type === "vsme"
        ? {
            ...hydratedStructure,
            sections,
          }
        : {
            ...hydratedStructure,
            sections,
            phaseIn: hydratedStructure.phaseIn
              ? {
                  ...hydratedStructure.phaseIn,
                  inputParameters: hydratedStructure.phaseIn.inputParameters.filter(
                    (
                      inputParameter,
                      index,
                    ): inputParameter is ILocalDataEntryObjectInputParameter => {
                      if (inputParameter === null) {
                        incompleteInputParameters.push({
                          parameterId: hydratedStructure.phaseIn!.inputParameterIds[index],
                          level: 0,
                          inputParameter,
                        });
                        return false;
                      }
                      return true;
                    },
                  ),
                }
              : null,
          };

    return [
      completeStructure as IConditionalHydratedCompleteRecordingStructureGroup<S>,
      incompleteDisclosureRequirements,
      incompleteInputParameters,
    ];
  }

  // Helper functions for filter conditions
  private static matchesResponsiblePerson(
    responsibleUserId: string | undefined,
    filterValues: string[],
  ): boolean {
    return filterValues.length === 0 || filterValues.includes(responsibleUserId ?? "");
  }

  private static matchesContributingPeople(
    contributingUserIds: string[] | undefined,
    filterValues: string[],
  ): boolean {
    if (filterValues.length === 0) {
      return true;
    }
    if (!contributingUserIds) {
      return false;
    }
    return contributingUserIds?.some((id) => filterValues.includes(id));
  }

  private static matchesAuthenticatedUser({
    authenticatedUserId,
    responsibleUserId,
    contributingUserIds,
  }: {
    authenticatedUserId: EntityKey;
    responsibleUserId: string | undefined;
    contributingUserIds: string[] | undefined;
  }) {
    return (
      responsibleUserId === authenticatedUserId ||
      contributingUserIds?.includes(authenticatedUserId)
    );
  }

  public static canUserAccessInputParameter({
    authenticatedUserId,
    authenticatedUserOrgRole,
    inputParameter,
    hasObjectAdminDataEntryObjectAccess,
    pta,
    /*
     * Lookup map of input parameters
     * Used to check if the parent input parameter of a conditional input parameter can be accessed
     */
    allInputParameters,
  }: {
    authenticatedUserId?: EntityKey;
    authenticatedUserOrgRole?: OrganizationRole | null;
    inputParameter: UndefinedOrNull<ILocalDataEntryObjectInputParameter>;
    hasObjectAdminDataEntryObjectAccess?: boolean;
    pta?: IFiltersPTAData;
    allInputParameters?: Record<string, ILocalDataEntryObjectInputParameter | undefined>;
  }) {
    if (!authenticatedUserId) {
      return false;
    }

    // Admin Bypass
    if (authenticatedUserOrgRole === OrganizationRole.ORGANIZATION_ADMIN) {
      return true;
    }
    if (
      authenticatedUserOrgRole === OrganizationRole.OBJECT_ADMIN &&
      hasObjectAdminDataEntryObjectAccess
    ) {
      return true;
    }

    // Check direct assignment on the IP
    if (
      (authenticatedUserOrgRole === OrganizationRole.VIEW_MEMBERS ||
        authenticatedUserOrgRole === OrganizationRole.OBJECT_ADMIN) &&
      InputParameterRecordingEsrsStructuresUtilities.matchesAuthenticatedUser({
        responsibleUserId: inputParameter?.responsibleUserId,
        contributingUserIds: inputParameter?.contributingUserIds,
        authenticatedUserId,
      })
    ) {
      return true;
    }

    // Check if the input parameter is a conditional input parameter and if the parent input parameter can be accessed
    if (
      InputParameterRecordingEsrsStructuresUtilities.canAccessConditionalInputParameter(
        inputParameter,
        authenticatedUserId,
        authenticatedUserOrgRole,
        hasObjectAdminDataEntryObjectAccess,
        allInputParameters,
      )
    ) {
      return true;
    }

    // Check if the user has access to a policy, target, or action wrapped by the input parameter
    if (pta) {
      const ptaMatches = InputParameterRecordingEsrsStructuresUtilities.getPtaUserMatches(
        inputParameter,
        {
          responsiblePerson: [authenticatedUserId],
          contributingPeople: [authenticatedUserId],
        },
        pta,
      );
      // If the user is set as responsible or contributing to a policy, target, or action, they have access to the input parameter
      if (ptaMatches.responsible || ptaMatches.contributing) {
        return true;
      }
    }

    return false;
  }

  /**
   * Checks if the parent input parameter of a conditional input parameter can be accessed by the user.
   * Will return false if the input parameter is not conditional.
   * @param inputParameter
   * @param authenticatedUserId
   * @param authenticatedUserOrgRole
   * @param hasObjectAdminDataEntryObjectAccess
   * @param allInputParameters
   * @returns Whether the input parameter is conditional and the parent input parameter can be accessed
   */
  private static canAccessConditionalInputParameter(
    inputParameter: UndefinedOrNull<ILocalDataEntryObjectInputParameter>,
    authenticatedUserId: EntityKey,
    authenticatedUserOrgRole?: OrganizationRole | null,
    hasObjectAdminDataEntryObjectAccess?: boolean,
    allInputParameters?: Record<string, ILocalDataEntryObjectInputParameter | undefined>,
  ): boolean {
    if (inputParameter?.inputParameter.condition && allInputParameters) {
      // Get the parent input parameter of the current input parameter
      const parentInputParameter =
        allInputParameters[inputParameter.inputParameter.condition.checkInputParameterId];

      // If the parent input parameter is found in the lookup map
      if (parentInputParameter) {
        // Check if the parent input parameter can be accessed by the user
        // If the user can access the parent input parameter, they can access the current input parameter
        return InputParameterRecordingEsrsStructuresUtilities.canUserAccessInputParameter({
          authenticatedUserId,
          authenticatedUserOrgRole,
          inputParameter: parentInputParameter,
          hasObjectAdminDataEntryObjectAccess,
          allInputParameters, // if nested conditions (ever) exist, they will be checked recursively
        });
      }
    }
    return false;
  }

  private static matchesStatus(
    inputParameter: UndefinedOrNull<ILocalDataEntryObjectInputParameter>,
    filterValues: string[],
    isDrExcluded?: boolean,
  ): boolean {
    if (filterValues.length === 0) {
      return true;
    }

    const isExcludedSelected = filterValues.includes(StatusExcludedOption);
    const statusFilters = filterValues.filter((value) => value !== StatusExcludedOption);

    // if either the parameter itself or the DR is excluded, skip checking the status
    if (isDrExcluded || inputParameter?.exclude !== undefined) {
      return isExcludedSelected;
    }

    return statusFilters.includes(inputParameter?.status ?? "");
  }

  private static matchesPhaseIn(
    inputParameter: IInputParameter | undefined,
    filterValues: string[],
  ): boolean {
    if (filterValues.length === 0) {
      return true;
    }
    if (!inputParameter) {
      return false;
    }

    const isEligible =
      InputParameterRecordingEsrsStructuresUtilities.isInputParameterEligibleForPhaseIn(
        inputParameter,
      );
    const filterIncludesTrue = filterValues.includes("true");
    const filterIncludesFalse = filterValues.includes("false");

    // Check if eligible and filterTrue OR not eligible and filterFalse
    // Also covers both filters being selected
    return (filterIncludesTrue && isEligible) || (filterIncludesFalse && !isEligible);
  }

  private static matchesOptional(
    esrsMetaData: IInputParameterESRSMetaData | undefined,
    filterValues: string[],
  ): boolean {
    if (filterValues.length === 0) {
      return true;
    }
    if (!esrsMetaData) {
      return false;
    }

    const isOptional = !esrsMetaData.required;
    const filterIncludesTrue = filterValues.includes("true");
    const filterIncludesFalse = filterValues.includes("false");

    // Check if optional and filterTrue OR not optional and filterFalse
    // Also covers both filters being selected
    return (filterIncludesTrue && isOptional) || (filterIncludesFalse && !isOptional);
  }

  public static getFiltersFromSearchParams(searchParams: URLSearchParams): IEsrsFilters {
    return {
      responsiblePerson: searchParams.getAll(EsrsFilters.ResponsiblePerson),
      contributingPeople: searchParams.getAll(EsrsFilters.ContributingPeople),
      status: searchParams.getAll(EsrsFilters.Status),
      phaseIn: searchParams.getAll(EsrsFilters.PhaseIn),
      optional: searchParams.getAll(EsrsFilters.Optional),
    };
  }

  /**
   * Filters the hydrated structure group based on the provided search parameters.
   * @param hydratedStructure The hydrated structure group to be filtered.
   * @param searchParams - The URL search parameters containing filter criteria.
   * @param authenticatedUserId - The ID of the authenticated user.
   * @param authenticatedUserOrgRole - The role of the authenticated user.
   * @param hasObjectAdminDataEntryObjectAccess - Whether the authenticated user has object admin access.
   * @param pta - The policies, targets and actions to check against.
   */
  public static filterStructureGroup<S extends IEitherESRSOrVSMEHydratedRecordingStructureGroup>(
    hydratedStructure: S,
    searchParams: URLSearchParams,
    pta: IFiltersPTAData,
    authenticatedUserId?: EntityKey,
    authenticatedUserOrgRole?: OrganizationRole | null,
    hasObjectAdminDataEntryObjectAccess?: boolean,
  ): IConditionalHydratedRecordingStructureGroup<S> {
    const filters =
      InputParameterRecordingEsrsStructuresUtilities.getFiltersFromSearchParams(searchParams);

    const sections = hydratedStructure.sections
      ?.map((section) => ({
        ...section,
        disclosureRequirements: section.disclosureRequirements
          .map((dr) => {
            const canAccessDisclosureRequirement =
              InputParameterRecordingEsrsStructuresUtilities.canUserAccessInputParameter({
                authenticatedUserId,
                authenticatedUserOrgRole,
                inputParameter: dr.disclosureRequirementInputParameter,
                hasObjectAdminDataEntryObjectAccess,
              });

            // Lookup map of all IPs that can be used to find a specific ip by id (used for conditional IPs)
            const ipLookup =
              InputParameterRecordingEsrsStructuresUtilities.createInputParameterLookup(
                dr.inputParameters,
              );

            return {
              ...dr,
              // First filter IPs
              inputParameters: dr.inputParameters.filter((ip) => {
                const ipInput = ip.inputParameter;

                return (
                  (canAccessDisclosureRequirement ||
                    InputParameterRecordingEsrsStructuresUtilities.canUserAccessInputParameter({
                      authenticatedUserId,
                      authenticatedUserOrgRole,
                      inputParameter: ipInput,
                      hasObjectAdminDataEntryObjectAccess,
                      allInputParameters: ipLookup,
                      pta,
                    })) &&
                  InputParameterRecordingEsrsStructuresUtilities.doesInputParameterMatchFilters(
                    ipInput,
                    filters,
                    dr.disclosureRequirementInputParameter?.exclude !== undefined,
                    pta,
                  )
                );
              }),
            };
          })
          // Then filter DRs
          .filter((dr) => {
            // Keep DR if it has filtered IPs
            if (dr.inputParameters.length > 0) {
              return true;
            }

            // Otherwise check input parameter of DR
            const drInput = dr.disclosureRequirementInputParameter;
            return (
              InputParameterRecordingEsrsStructuresUtilities.canUserAccessInputParameter({
                authenticatedUserId,
                authenticatedUserOrgRole,
                inputParameter: drInput,
                hasObjectAdminDataEntryObjectAccess,
              }) &&
              InputParameterRecordingEsrsStructuresUtilities.doesInputParameterMatchFilters(
                drInput,
                filters,
              )
            );
          }),
      }))
      // Finally filter sections
      .filter((section) => section.disclosureRequirements.length > 0);

    const filteredStructureGroup: IEitherESRSOrVSMEHydratedRecordingStructureGroup =
      hydratedStructure.type === "vsme"
        ? {
            ...hydratedStructure,
            sections,
          }
        : {
            ...hydratedStructure,
            // Sections
            sections,
            // Phase In
            phaseIn: hydratedStructure.phaseIn
              ? {
                  ...hydratedStructure.phaseIn,
                  inputParameters: hydratedStructure.phaseIn?.inputParameters.filter(
                    (inputParameter) =>
                      InputParameterRecordingEsrsStructuresUtilities.canUserAccessInputParameter({
                        authenticatedUserId,
                        authenticatedUserOrgRole,
                        inputParameter,
                      }) &&
                      InputParameterRecordingEsrsStructuresUtilities.doesInputParameterMatchFilters(
                        inputParameter,
                        filters,
                      ),
                  ),
                }
              : null,
          };
    return filteredStructureGroup as IConditionalHydratedRecordingStructureGroup<S>;
  }

  /**
   * This method determines whether the passed input parameter matches the given filters.
   * @param inputParameter The input parameter to evaluate (can also be the IP of a DR)
   * @param filters The filters to evaluate against
   * @param isDrExcluded Whether the input parameter is a child of a disclosure requirement that is excluded - if the IP is a DR itself, this should be omitted.
   * @param pta - The policies, targets and actions to check against.
   * @returns Whether the input parameter matches the filters
   */
  public static doesInputParameterMatchFilters(
    inputParameter: UndefinedOrNull<ILocalDataEntryObjectInputParameter>,
    filters: {
      responsiblePerson: string[];
      contributingPeople: string[];
      status: string[];
      phaseIn: string[];
      optional: string[];
    },
    isDrExcluded?: boolean,
    pta?: IFiltersPTAData,
  ): boolean {
    // Direct matches against the input parameter.
    const directResponsibleMatch =
      InputParameterRecordingEsrsStructuresUtilities.matchesResponsiblePerson(
        inputParameter?.responsibleUserId,
        filters.responsiblePerson,
      );
    const directContributingMatch =
      InputParameterRecordingEsrsStructuresUtilities.matchesContributingPeople(
        inputParameter?.contributingUserIds,
        filters.contributingPeople,
      );

    // Check if the filtered users have access to a policy, target, or action wrapped by the input parameter
    const { responsible: ptaResponsibleMatch, contributing: ptaContributingMatch } =
      InputParameterRecordingEsrsStructuresUtilities.getPtaUserMatches(
        inputParameter,
        filters,
        pta,
      );

    // Combine the matches for each filter
    const responsibleUserAccess = directResponsibleMatch || ptaResponsibleMatch;
    const contributingUserAccess = directContributingMatch || ptaContributingMatch;

    return (
      responsibleUserAccess &&
      contributingUserAccess &&
      InputParameterRecordingEsrsStructuresUtilities.matchesStatus(
        inputParameter,
        filters.status,
        isDrExcluded,
      ) &&
      InputParameterRecordingEsrsStructuresUtilities.matchesPhaseIn(
        inputParameter?.inputParameter,
        filters.phaseIn,
      ) &&
      InputParameterRecordingEsrsStructuresUtilities.matchesOptional(
        inputParameter?.inputParameter?.metaData.esrs,
        filters.optional,
      )
    );
  }

  /**
   * Builds a lookup map for the input parameters so you can search for a specific input parameter by id with O(1) complexity.
   * @param inputParameters
   * @returns A lookup map for the input parameters with the input parameter id as the key.
   */
  private static createInputParameterLookup(
    inputParameters: IHydratedInputParameterRecordingStructureGroupDisclosureRequirementIP[],
  ): Record<string, ILocalDataEntryObjectInputParameter | undefined> {
    return LookupUtilities.generateLookUp(
      FilterUtilities.filterNotNull(inputParameters.map((ip) => ip.inputParameter)),
      (ip) => ip.inputParameter.id,
    );
  }

  /**
   * Checks for user matches on the policies, targets, and actions wrapped by the input parameter.
   */
  private static getPtaUserMatches(
    inputParameter: UndefinedOrNull<ILocalDataEntryObjectInputParameter>,
    filters: { responsiblePerson: string[]; contributingPeople: string[] },
    pta?: IFiltersPTAData,
  ): { responsible: boolean; contributing: boolean } {
    if (
      !inputParameter?.inputParameter ||
      !pta ||
      !EsrsValueEditingUtilities.doesInputParameterValueStoreValue(
        inputParameter.inputParameter.values[0].valueConfiguration,
      ) ||
      !EsrsValueEditingUtilities.isInputParameterDirectSave(inputParameter.inputParameter)
    ) {
      return { responsible: false, contributing: false };
    }

    const entityType =
      inputParameter.inputParameter.values[0].valueConfiguration.configuration.type;
    let entities: Array<IWithUsers> = [];

    if (entityType === "policy") {
      entities = pta.policies;
    } else if (entityType === "target") {
      entities = pta.targets;
    } else if (entityType === "action") {
      entities = pta.actions;
    }

    return {
      responsible: entities.some((entity) =>
        InputParameterRecordingEsrsStructuresUtilities.matchesResponsiblePerson(
          entity.responsibleUserId,
          filters.responsiblePerson,
        ),
      ),
      contributing: entities.some((entity) =>
        InputParameterRecordingEsrsStructuresUtilities.matchesContributingPeople(
          entity.contributingUserIds,
          filters.contributingPeople,
        ),
      ),
    };
  }

  /**
   * Checks whether a given input parameter is eligible for phase in
   * @param inputParameter
   */
  public static isInputParameterEligibleForPhaseIn = (inputParameter: IInputParameter) => {
    return (
      inputParameter.metaData.esrs?.phaseInEligibility ===
      IInputParameterESRSMetaDataPhaseInEligibility.Eligible
    );
  };

  /**
   * Gets the translation key for the disclosure requirement of the given input parameter
   * @param inputParameter
   */
  public static getDisclosureRequirementTranslationKey(inputParameter: IInputParameter) {
    if (isEnum(inputParameter.metaData.esrs?.efragId, EligiblePhaseInDrsEfragIds)) {
      return inputParameter.metaData.esrs?.efragId as EligiblePhaseInDrsEfragIds;
    }

    console.warn(
      "Tried to read Disclosure Requirement translation key for non-eligible input parameter",
    );

    return null;
  }

  /**
   * Gets the dislosure requirement input parameter to which the given input parameter belongs to
   * @param inputParameterId
   * @param recordingStructure
   */
  public static getDisclosureRequirementOfInputParameterById(
    inputParameterId: string | null,
    recordingStructure: IEitherESRSOrVSMEHydratedCompleteRecordingStructureGroup,
  ) {
    for (const section of recordingStructure.sections) {
      for (const dr of section.disclosureRequirements) {
        const hasMatchingInputParameter = dr.inputParameters.some(
          (ip: IHydratedCompleteInputParameterRecordingStructureGroupDisclosureRequirementIP) =>
            ip.inputParameter.inputParameter.id === inputParameterId,
        );

        if (hasMatchingInputParameter) {
          return dr.disclosureRequirementInputParameter.inputParameter;
        }
      }
    }

    return null;
  }

  /**
   * Gets the input parameter by id
   * @param inputParameterId
   * @param recordingStructure
   */
  public static getInputParameterById(
    inputParameterId: string | null,
    recordingStructure: IEitherESRSOrVSMEHydratedCompleteRecordingStructureGroup,
  ) {
    for (const section of recordingStructure.sections) {
      for (const dr of section.disclosureRequirements) {
        for (const ip of dr.inputParameters) {
          if (ip.inputParameter.inputParameter.id === inputParameterId) {
            return ip.inputParameter.inputParameter;
          }
        }

        if (dr.disclosureRequirementInputParameter.inputParameter.id === inputParameterId) {
          return dr.disclosureRequirementInputParameter.inputParameter;
        }
      }
    }

    return null;
  }

  /**
   * Gets the section id to which the given input parameter belongs
   * @param inputParameterId The id of the input parameter
   * @param recordingStructure The recording structure to search in
   */
  public static getSectionIdOfInputParameterById(
    inputParameterId: string | null,
    recordingStructure: IHydratedCompleteInputParameterRecordingESRSStructure,
  ): string | null {
    for (const section of recordingStructure.sections) {
      for (const dr of section.disclosureRequirements) {
        // Check input parameters
        const hasMatchingInputParameter = dr.inputParameters.some(
          (ip) => ip.inputParameter.inputParameter.id === inputParameterId,
        );

        // Check disclosure requirement input parameter
        if (
          hasMatchingInputParameter ||
          dr.disclosureRequirementInputParameter.inputParameter.id === inputParameterId
        ) {
          return section.id;
        }
      }
    }
    return null;
  }

  public static removeConditionallyHiddenDisclosureRequirements(
    disclosureRequirements: IHydratedInputParameterRecordingStructureGroupDisclosureRequirement[],
    conditionalDisplayInputParametersLookup: InputParameterDisplayInformationLookup,
  ) {
    return disclosureRequirements.filter(
      (dr) =>
        !conditionalDisplayInputParametersLookup[dr.id] ||
        conditionalDisplayInputParametersLookup[dr.id]!.displayMode !== "hide",
    );
  }
}
