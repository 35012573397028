import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { useMapUserIdsToUsers } from "../hooks/map-user-ids-to-users.hook";
import { sortUsers } from "../hooks/sorted-organization-users.hook";
import { CenteredCircularProgress } from "../../common/components/centered-circular-progress.component";
import { UserAvatar } from "./user-avatar.component";
import { SearchTextInput } from "../../common/components/search-text-input.component";
import { UserUtilities } from "@netcero/netcero-common";

interface IPopupMenuFilterUsersSectionProps {
  organizationId: string;
  filterableUserIds: string[];
  filterByUserIds: string[];
  onChangeFilterByUserIds: (userIds: string[]) => void;
  noUsersAvailableMessage: string;
}

export const PopupMenuFilterUsersSectionComponent: FC<IPopupMenuFilterUsersSectionProps> = ({
  organizationId,
  filterableUserIds,
  filterByUserIds,
  onChangeFilterByUserIds,
  noUsersAvailableMessage,
}) => {
  const { t } = useTranslation("popup_menu_filter_users_section_component");

  const [usersSearch, setUsersSearch] = useState("");
  const debouncedUsersSearch = useDebounce(usersSearch, 1_000);

  const [filterableUsers, usersQuery] = useMapUserIdsToUsers(
    organizationId,
    filterableUserIds,
    debouncedUsersSearch || undefined,
  );

  const sortedFilterableUsers = useMemo(() => {
    return filterableUsers ? sortUsers(filterableUsers) : null;
  }, [filterableUsers]);

  return (
    <>
      <SearchTextInput search={usersSearch} onSearchChange={setUsersSearch} />
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        py={1}
        px={1.5}
        mt={0.5}
        mx={-1.5}
        height={250}
        overflow="auto"
      >
        {filterableUserIds.length > 0 ? (
          <QueryWrapper
            query={usersQuery}
            loadingOverride={() => <CenteredCircularProgress />}
            build={() =>
              sortedFilterableUsers &&
              (sortedFilterableUsers.length > 0 ? (
                sortedFilterableUsers.map((filterableUser) => (
                  <FormControlLabel
                    key={filterableUser.id}
                    label={
                      <Box display="flex" alignItems="center" gap={1} ml={0.5}>
                        <UserAvatar user={filterableUser} />
                        {UserUtilities.formatUserFullName(filterableUser)}
                      </Box>
                    }
                    control={
                      <Checkbox
                        size="small"
                        checked={filterByUserIds.includes(filterableUser.id!)}
                        onChange={(evt, checked) => {
                          if (checked) {
                            onChangeFilterByUserIds([...filterByUserIds, filterableUser.id!]);
                          } else {
                            onChangeFilterByUserIds(
                              filterByUserIds.filter((id) => id !== filterableUser.id),
                            );
                          }
                        }}
                      />
                    }
                  />
                ))
              ) : (
                <Typography>{t("users_search_no_results")}</Typography>
              ))
            }
          />
        ) : (
          <Typography maxWidth={225}>{noUsersAvailableMessage}</Typography>
        )}
      </Box>
    </>
  );
};
