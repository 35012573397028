import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { TreePickerSingleSelect } from "../../common/inputs/tree-picker-single-select.component";
import { TreeNodeData, UndefinedOrNull } from "@netcero/netcero-common";
import { FC } from "react";

interface IDeoTreePickerWithAuthorizationProps {
  selectedDeoId: UndefinedOrNull<string>;
  selectableDeoIds?: string[];
  onChange: (selectedDeoId: string) => void;
  rootDeo: IDataEntryObject;
}

export const DeoTreePicker: FC<IDeoTreePickerWithAuthorizationProps> = ({
  selectedDeoId,
  selectableDeoIds,
  onChange,
  rootDeo,
}) => {
  return (
    <TreePickerSingleSelect
      treeNodeData={mapDeoToTreeNodeData(rootDeo)}
      selectedNodeId={selectedDeoId}
      onChange={onChange}
      selectableNodeIds={selectableDeoIds}
    />
  );
};

function mapDeoToTreeNodeData(deo: IDataEntryObject): TreeNodeData {
  return {
    id: deo.id,
    label: deo.name,
    children: deo.children?.map(mapDeoToTreeNodeData),
  };
}
