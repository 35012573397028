import { FC, useCallback } from "react";
import { Box, Checkbox } from "@mui/material";
import { FilterSelect } from "./filter-select.component";
import { FilterOpenSearch } from "./filter-open-search.component";
import { FilterMenuSearch } from "./filter-menu-search.component";
import { FilterType, IFilterConfig } from "./filter.type";
import { ArrayUtilities, MaybeArray } from "@netcero/netcero-common";

export type MapFilterState = Map<string, string[]>;

interface IFilterBarProps {
  filters: IFilterConfig[];
  mutableMapFilterState: MapFilterState;
}

export const MapStateFilters: FC<IFilterBarProps> = ({ filters, mutableMapFilterState }) => {
  const handleChange = useCallback(
    (name: string, value: MaybeArray<string>) => {
      const valueAsArray = ArrayUtilities.alwaysArray(value);
      mutableMapFilterState.set(name, valueAsArray);
    },
    [mutableMapFilterState],
  );

  return (
    <>
      {filters.map((filter) => {
        switch (filter.type) {
          case FilterType.SearchOpen:
            return (
              <FilterOpenSearch
                key={filter.name}
                filter={filter}
                value={mutableMapFilterState.get(filter.name)?.[0] ?? undefined}
                onChange={handleChange}
              />
            );
          case FilterType.SearchMenu:
            return (
              <FilterMenuSearch
                key={filter.name}
                filter={filter}
                selectedValues={mutableMapFilterState.get(filter.name)}
                onChange={handleChange}
              />
            );
          case FilterType.Select:
            return (
              <FilterSelect
                key={filter.name}
                filter={filter}
                selectedValues={mutableMapFilterState.get(filter.name)}
                onChange={handleChange}
              />
            );
          case FilterType.Checkbox:
            return (
              <Box
                key={filter.name}
                sx={{ fontSize: "14px", display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  key={filter.name}
                  checked={Boolean(mutableMapFilterState.get(filter.name)?.[0])}
                  onChange={(e) => handleChange(filter.name, e.target.checked ? "true" : "")}
                  size="small"
                />
                <Box>{filter.label}</Box>
              </Box>
            );
          default:
            return null;
        }
      })}
    </>
  );
};
