import { useUserContext } from "../../user/user.context";
import { useCallback } from "react";
import { ObjectUtilities } from "@netcero/netcero-common";

/**
 * Custom hook to check if the current user's ID matches a given target ID.
 *
 * @returns A callback function that takes a target ID and returns a boolean indicating if it matches the current user's ID. If any of the IDs is not present, false is returned.
 */
export const useDoesCurrentUserIdMatchId = () => {
  const { user } = useUserContext();
  return useCallback(
    (targetId: string | undefined | null) => {
      const currentUserId = user?.userProfile?.id;

      // if any of the values isn't defined, always return false
      if ([currentUserId, targetId].some((v) => !ObjectUtilities.isValuePresent(v))) {
        return false;
      }

      return targetId === currentUserId;
    },
    [user?.userProfile.id],
  );
};
