import { FC } from "react";
import { useValidatedRouterParameters } from "../hooks/validated-router-parameters.hook";

export function withValidatedRouteParams<T extends string>(
  expectedParameters: T[],
  component: FC<Record<T, string>>,
): FC {
  return () => {
    const validatedParams = useValidatedRouterParameters(expectedParameters);
    if (!validatedParams) {
      return "ERROR: Invalid URL parameters - this should not occur.";
    }
    return component(validatedParams);
  };
}
