/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevant
 */
export interface IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevant {
  /**
   *
   * @type {string}
   * @memberof IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevant
   */
  reason: IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevantReasonEnum;
  /**
   *
   * @type {string}
   * @memberof IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevant
   */
  explanation?: string;
}

export const IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevantReasonEnum = {
  NotRelevant: "not-relevant",
} as const;

export type IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevantReasonEnum =
  (typeof IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevantReasonEnum)[keyof typeof IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevantReasonEnum];
