import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IGhgActivityIdentity } from "@netcero/netcero-common";

export const getActivityValuesQueryKey = ({
  organizationId,
  recordingPeriodId,
  ghgCategoryIdentifier,
  activityId,
}: IGhgActivityIdentity) => [
  "ghg-activity-values",
  organizationId,
  recordingPeriodId,
  ghgCategoryIdentifier,
  activityId,
];

export const useActivityValuesQuery = (activityIdentity: IGhgActivityIdentity) =>
  useQuery({
    queryKey: getActivityValuesQueryKey(activityIdentity),
    queryFn: () =>
      CoreApiService.ActivityValuesApi.getActivityValuesForActivity(
        activityIdentity.organizationId,
        activityIdentity.recordingPeriodId,
        activityIdentity.ghgCategoryIdentifier,
        activityIdentity.activityId,
      )
        .then((req) => req())
        .then((res) => res.data),
  });
