import { FC, useEffect, useMemo } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DialogCloseButton } from "../../common/dialogs/dialog-button.components";
import { useTranslation } from "react-i18next";
import { EmissionFactorsDialogTable } from "../components/dialog/emission-factor-dialog-table.component";
import { Link as RouterLink } from "react-router-dom";
import { EmissionFactorsDialogFilters } from "../components/dialog/emission-factor-dialog-filter.component";
import { OpenInNewTabIcon } from "../../common/constants/tabler-icon.constants";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";
import { EmissionFactorChip } from "../components/emission-factor-chip.component";
import { useMap } from "@uidotdev/usehooks";
import { EmissionFactorDialogFilter } from "../../filters/filter.constants";
import { GHG_CATEGORIES_BY_SCOPE, UndefinedOrNull } from "@netcero/netcero-common";
import {
  IEmissionFactorType,
  IEmissionFactorUnit,
  IGhgProtocolScopeEnum,
} from "@netcero/netcero-core-api-client";
import { MapFilterState } from "../../filters/map-state-filters.component";

const DEFAULT_FILTERS: [string, string[]][] = [
  // Set categories that are in section 2 as default filters
  [
    EmissionFactorDialogFilter.ghgCategoriesDialog,
    GHG_CATEGORIES_BY_SCOPE[IGhgProtocolScopeEnum.Scope2] as string[],
  ],
  // Set all types other than market based as default filters
  [
    EmissionFactorDialogFilter.emissionFactorTypesDialog,
    Object.values(IEmissionFactorType).filter(
      (type) => type !== IEmissionFactorType.MarketBasedEnergy,
    ),
  ],
];

interface IEmissionFactorLinkToLocationBasedEditDialogProps {
  organizationId: string;
  forEmissionFactorId: UndefinedOrNull<string>;
  relatedLocationBasedEmissionFactorId: string | null;
  emissionFactorUnit: IEmissionFactorUnit;
  open: boolean;
  onClose: () => void;
  onChange: (emissionFactorId: string, selected: boolean) => void;
}

export const EmissionFactorLinkToLocationBasedEditDialog: FC<
  IEmissionFactorLinkToLocationBasedEditDialogProps
> = ({
  organizationId,
  forEmissionFactorId,
  relatedLocationBasedEmissionFactorId,
  emissionFactorUnit,
  open,
  onClose,
  onChange,
}) => {
  const { t } = useTranslation("emission_factor_dialog");

  const filters: MapFilterState = useMap();

  useEffect(() => {
    if (open) {
      (
        [...DEFAULT_FILTERS, [EmissionFactorDialogFilter.unitsDialog, [emissionFactorUnit]]] as [
          string,
          string[],
        ][]
      ).forEach(([name, value]) => {
        filters.set(name, value);
      });
    }
    // We only want to reset filters when dialog is opened
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const disabledEmissionFactorIds = useMemo(
    () => (forEmissionFactorId ? [forEmissionFactorId] : undefined),
    [forEmissionFactorId],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          minHeight: "60vh",
        },
      }}
    >
      <DialogTitle>{t("link_emission_factor")}</DialogTitle>
      <DialogContent>
        <EmissionFactorChip
          organizationId={organizationId}
          emissionFactorId={relatedLocationBasedEmissionFactorId}
        />
        <EmissionFactorsDialogFilters mutableFilters={filters} />
        <EmissionFactorsDialogTable
          organizationId={organizationId}
          disabledEmissionFactorIds={disabledEmissionFactorIds}
          selectedEmissionFactorIds={
            relatedLocationBasedEmissionFactorId ? [relatedLocationBasedEmissionFactorId] : []
          }
          onChange={onChange}
          filtersMap={filters}
        />
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button
            variant="contained"
            component={RouterLink}
            to={AppInternalNavigationUtilities.getConfigurationEmissionFactorsPageUrl(
              organizationId,
            )}
            target="_blank"
            startIcon={<OpenInNewTabIcon />}
          >
            {t("manage_emission_factors", { ns: "emission_factors_common" })}
          </Button>
          <DialogCloseButton onClick={handleClose}>
            {t("button_close", { ns: "error_dialog" })}
          </DialogCloseButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
