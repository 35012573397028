import { ValidatorOptions } from "class-validator/types/validation/ValidatorOptions";
import { ValidationError } from "class-validator/types/validation/ValidationError";
import { validate, validateSync } from "class-validator";
import { ClassConstructor, plainToInstance } from "class-transformer";

export class ValidationUtilities {
  public static readonly DefaultValidatorSettings: ValidatorOptions = {
    whitelist: true,
    forbidNonWhitelisted: true,
  };

  /**
   * Simple forwards the call to `class-validator` while applying the default validation parameters (constant DefaultValidatorSettings)
   * @param value
   * @param validatorOptions
   */
  public static validateWithDefaults(
    value: object,
    validatorOptions: ValidatorOptions = {},
  ): Promise<ValidationError[]> {
    return validate(value, {
      ...ValidationUtilities.DefaultValidatorSettings,
      ...validatorOptions,
    });
  }

  /**
   * Simple forwards the call to `class-validator` while applying the default validation parameters (constant DefaultValidatorSettings)
   * @param value
   * @param validatorOptions
   */
  public static validateWithDefaultsSync(
    value: object,
    validatorOptions: ValidatorOptions = {},
  ): ValidationError[] {
    return validateSync(value, {
      ...ValidationUtilities.DefaultValidatorSettings,
      ...validatorOptions,
    });
  }

  /**
   * Checks if a given value matches any of the provided class structures.
   * This method attempts to instantiate the value as each of the provided classes
   * and validates it against the class's decorators.
   *
   * @param value - The value to be checked against the provided classes.
   * @param classes - An array of class constructors to check the value against.
   * @returns A promise that resolves to a boolean. Returns true if the value
   *          successfully validates against any of the provided classes, false otherwise.
   */
  public static async isValueOneOfClasses(
    value: unknown,
    ...classes: ClassConstructor<object>[]
  ): Promise<boolean> {
    for (const currentClass of classes) {
      const instantiated = plainToInstance(currentClass, value);
      const result = await ValidationUtilities.validateWithDefaults(instantiated);
      if (result.length === 0) {
        return true;
      }
    }
    return false;
  }
}
