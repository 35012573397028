import { FC, useMemo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { IActionEditDialogData } from "../actions-app.utilities";
import { MdrInputComponentFactory } from "../../minimum-disclosure-requirements-common/components/mdr-input-components/mdr-input-component.factory";
import { IMDRInput } from "../../minimum-disclosure-requirements-common/mdr-input.types";
import { useTranslation } from "react-i18next";

const TOPIC_PREFIX = "general";
const TRANSLATION_PREFIX = `${TOPIC_PREFIX}.financialAllocationTable`;

const FINANCIAL_ALLOCATION_TABLE_LAYOUT = {
  rows: [
    "financialResourcesAllocatedToActionPlanOpEx",
    "financialResourcesAllocatedToActionPlanCapEx",
  ],
  columns: ["shortTerm", "midTerm", "longTerm"],
} as const;

interface IFinancialAllocationTableProps {
  disabled?: boolean;
}

export const FinancialAllocationTable: FC<IFinancialAllocationTableProps> = ({ disabled }) => {
  const { control } = useFormContext<IActionEditDialogData>();
  const { t } = useTranslation("data_entry_object_action_component");

  const inputMetaData: IMDRInput = useMemo(
    () => ({
      type: "currency",
      initialValue: null,
      placeholder: t("placeholder", { ns: "currency_input" }),
      size: "small",
    }),
    [t],
  );

  return (
    <TableContainer component={Paper} sx={{ padding: 1, mt: 0.5, mb: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              {t(`labels.${TRANSLATION_PREFIX}.allocatedResources`)}
            </TableCell>
            {FINANCIAL_ALLOCATION_TABLE_LAYOUT.columns.map((period) => (
              <TableCell key={period} sx={{ border: "none" }}>
                {t(`labels.${TRANSLATION_PREFIX}.${period}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {FINANCIAL_ALLOCATION_TABLE_LAYOUT.rows.map((type) => (
            <TableRow key={type}>
              <TableCell sx={{ border: "none" }}>
                {t(`labels.${TRANSLATION_PREFIX}.${type}`)}
              </TableCell>
              {FINANCIAL_ALLOCATION_TABLE_LAYOUT.columns.map((period) => (
                <TableCell key={`${type}.${period}`} sx={{ border: "none" }}>
                  {MdrInputComponentFactory.createComponent(
                    inputMetaData,
                    {
                      propertyPath: `${TOPIC_PREFIX}.${type}.${period}`,
                      label: "",
                      control,
                      isDependedUponByAnother: false,
                      isConditional: false,
                      getRuleTranslation: (property, rule) => t(`input_errors.${property}.${rule}`),
                      disabled: !!disabled,
                    },
                    // Will not be used, because we don't have any enum values, but it's required by the factory
                    (value) => value,
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
