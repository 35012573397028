import { FC, PropsWithChildren } from "react";
import { CardContent } from "@mui/material";
import { StyledCardWithOnHoverShadow } from "../../common/components/styled-card.component";

export const GhgDashboardCard: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledCardWithOnHoverShadow
      sx={{
        padding: 0,
        overflow: "visible",
      }}
    >
      <CardContent>{children}</CardContent>
    </StyledCardWithOnHoverShadow>
  );
};
