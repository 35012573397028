import { FC, ReactNode } from "react";
import { Box, Divider, IconButton, Paper, Slide, SxProps, Theme } from "@mui/material";
import { CloseIcon } from "../../common/constants/tabler-icon.constants";
import { CommentsButtonWithQuery } from "../../comments/components/comments-button.component";
import { AuditLogsButton } from "../../audit-logging/components/audit-logs-button.component";
import { CommentsList } from "../../comments/components/comments-list.component";
import { useIsFeatureFlagEnabled } from "../../common/hooks/is-feature-flag-enabled.hook";
import { FeatureFlag } from "../../common/constants/feature-flags.constants";
import { TOP_BAR_HEIGHT } from "../../common/components/top-bar.component";
import { ICommentLinkMetadata, ICommentRelations } from "@netcero/netcero-core-api-client";

const COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH = 450;

export function getContentSxPropsForCommentsAndChangelogSidebarPaper(
  open: boolean,
): SxProps<Theme> {
  const transitionEasing = open ? "cubic-bezier(0, 0, 0.2, 1)" : "cubic-bezier(0.4, 0, 0.6, 1)";

  return {
    width: "100%",
    pr: open ? `${COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH}px` : undefined,
    transition: `margin-right 250ms ease, padding-right 300ms ${transitionEasing} !important`, // Override the transition property otherwise it won't work
  };
}

interface ISidebarCommentsAndAuditLogPaperProps {
  open: boolean;
  organizationId: string;
  relations: ICommentRelations;
  linkMetadata: ICommentLinkMetadata;
  topBarContent: ReactNode;
  onClose: VoidFunction;
}

export const SidebarCommentsAndAuditLogPaper: FC<ISidebarCommentsAndAuditLogPaperProps> = ({
  open,
  organizationId,
  relations,
  linkMetadata,
  topBarContent,
  onClose,
}) => {
  // TODO: NC-XYZ Clean up - only return V2
  const isAppShell2Enabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);
  const intercomIconHeight = (isAppShell2Enabled ? TOP_BAR_HEIGHT : 0) + 96;

  return (
    <Slide direction="left" in={open} mountOnEnter timeout={300}>
      <Box
        onClick={(event) => event.stopPropagation()}
        sx={{
          position: "fixed",
          top: (isAppShell2Enabled ? TOP_BAR_HEIGHT : 0) + 10,
          right: 0,
          height: "100vh",
          width: `${COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH}px`,
        }}
      >
        <Paper
          variant="elevation"
          sx={{
            width: `${COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH}px`,
            height: `calc(100dvh - ${intercomIconHeight}px)`,
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.05);",
          }}
        >
          <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
            {/* Top Bar of Sidebar */}
            <Box display="flex">
              {topBarContent}
              <Box ml="auto" my="auto">
                <IconButton onClick={() => onClose()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            {/* Buttons (for Tab Switching) */}
            <Box display="flex" gap={1} p={1}>
              <CommentsButtonWithQuery
                organizationId={organizationId}
                relations={relations}
                onClick={() => {
                  // Will later be used for tab switching (between comments and audit logs)
                }}
              />
              {/* Audit Logs */}
              <AuditLogsButton organizationId={organizationId} active={false} />
            </Box>
            {/* Sidebar Content (only comments now) */}
            <CommentsList
              organizationId={organizationId}
              relations={relations}
              linkMetadata={linkMetadata}
            />
          </Box>
        </Paper>
      </Box>
    </Slide>
  );
};
