import { useEffect, useMemo, useRef, useState } from "react";
import { getDialogSxPropsForEditDialogCommentsAndChangelogPaper } from "../components/edit-dialog-comments-and-audit-log-paper.component";
import { useCommentsAndAuditLogState } from "./comments-and-audit-log-state.hook";
import { useViewedCommentWithReset } from "./viewed-comment-id-with-reset.hook";

/**
 * Hook to manage the state of the Edit Dialog Comments and Audit Log Paper
 * @param dialogOpenState
 */
export const useEditDialogCommentsAndAuditLogPaperState = (dialogOpenState: boolean) => {
  const {
    showCommentsAndAuditLogSideSection,
    toggleCommentsAndAuditLogSideSection: toggleCommentsAndAuditLogSideSectionInternal,
    openCommentsAndAuditLogSideSection,
    closeCommentsAndAuditLogSideSection: closeCommentsAndAuditLogSideSectionInternal,
  } = useCommentsAndAuditLogState();

  const { isViewedCommentSet, resetViewedCommentAndExecuteAction } = useViewedCommentWithReset();

  const wasOpened = useRef(false);
  useEffect(() => {
    if (!dialogOpenState && wasOpened.current) {
      resetViewedCommentAndExecuteAction()();
    }
    wasOpened.current = dialogOpenState;
  }, [dialogOpenState, resetViewedCommentAndExecuteAction]);

  // Close Paper with Dialog change
  useEffect(() => {
    if (!dialogOpenState) {
      const timeout = setTimeout(closeCommentsAndAuditLogSideSectionInternal, 300);
      return () => clearTimeout(timeout);
    } else if (isViewedCommentSet) {
      openCommentsAndAuditLogSideSection();
    }
  }, [
    dialogOpenState,
    closeCommentsAndAuditLogSideSectionInternal,
    isViewedCommentSet,
    openCommentsAndAuditLogSideSection,
  ]);

  const dialogSxProps = useMemo(
    () =>
      getDialogSxPropsForEditDialogCommentsAndChangelogPaper(showCommentsAndAuditLogSideSection),
    [showCommentsAndAuditLogSideSection],
  );

  // Create Dialog Size Observer
  const [dialogElement, setDialogElement] = useState<HTMLDivElement | null>(null);

  return useMemo(
    () => ({
      showCommentsAndAuditLogSideSection,
      toggleCommentsAndAuditLogSideSection: resetViewedCommentAndExecuteAction(
        toggleCommentsAndAuditLogSideSectionInternal,
      ),
      openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSection: resetViewedCommentAndExecuteAction(
        closeCommentsAndAuditLogSideSectionInternal,
      ),
      dialogProps: {
        sx: dialogSxProps,
        PaperProps: {
          ref: setDialogElement,
        },
        disableEnforceFocus: true,
      },
      dialogElement,
    }),
    [
      showCommentsAndAuditLogSideSection,
      resetViewedCommentAndExecuteAction,
      toggleCommentsAndAuditLogSideSectionInternal,
      openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSectionInternal,
      dialogSxProps,
      dialogElement,
      setDialogElement,
    ],
  );
};
