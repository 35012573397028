import { createContext, FC, PropsWithChildren, useContext, useMemo } from "react";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import {
  GhgEvaluationUtilities,
  IDataEntryObjectConsolidationResult,
  LookupUtilities,
  ObjectUtilities,
  RecursiveUtilities,
} from "@netcero/netcero-common";

export interface IDataEntryObjectsContextValue {
  rootDataEntryObject: IDataEntryObject;
  getDataEntryObject: (id: string) => IDataEntryObject | null;
  getConsolidationForDataEntryObject: (id: string) => IDataEntryObjectConsolidationResult | null;
  areDeoEmissionsReappropriated: (id: string) => boolean;
}

const DataEntryObjectsContext = createContext<IDataEntryObjectsContextValue | null>(null);

interface IDataEntryObjectsContextProviderProps {
  rootDataEntryObject: IDataEntryObject;
}

export const DataEntryObjectsContextProvider: FC<
  PropsWithChildren<IDataEntryObjectsContextProviderProps>
> = ({ rootDataEntryObject, children }) => {
  const contextValue: IDataEntryObjectsContextValue = useMemo(() => {
    const dataEntryObjectsLookup = LookupUtilities.generateEntityLookUp(
      RecursiveUtilities.flattenRecursiveStructureDown(rootDataEntryObject),
    );
    const consolidation =
      GhgEvaluationUtilities.generateCalculationMetadataForDataEntryObjectTree(rootDataEntryObject);

    const getDataEntryObject = (id: string) => dataEntryObjectsLookup[id] ?? null;
    const getConsolidationForDataEntryObject = (id: string) => consolidation[id] ?? null;
    const areDeoEmissionsReappropriated = (id: string) =>
      ObjectUtilities.isValuePresent(getConsolidationForDataEntryObject(id)?.relocateEmissionsTo);

    return {
      rootDataEntryObject,
      getDataEntryObject,
      getConsolidationForDataEntryObject,
      areDeoEmissionsReappropriated,
    };
  }, [rootDataEntryObject]);

  return (
    <DataEntryObjectsContext.Provider value={contextValue}>
      {children}
    </DataEntryObjectsContext.Provider>
  );
};

export const useDataEntryObjectsContext = () => {
  const context = useContext(DataEntryObjectsContext);
  if (!context) {
    throw new Error(
      "useDataEntryObjectsContext must be used within a DataEntryObjectsContextProvider",
    );
  }
  return context;
};
