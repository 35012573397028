const INTRODUCTION_SHEET_NAME = "Introduction";
const ENTER_DATA_SHEET_NAME = "ENTER DATA";
const EMISSION_FACTOR_OPTIONS_SHEET_NAME = "ef_options";

export const ACTIVITY_TEMPLATE_CONSTANTS = {
  CELL_TO_REPLACE_WITH_ACTIVITY_TITLE: "B10",
  INTRODUCTION_SHEET_NAME,
  ENTER_DATA_SHEET_NAME,
  EMISSION_FACTOR_OPTIONS_SHEET_NAME,
  SHEETS: [
    INTRODUCTION_SHEET_NAME,
    ENTER_DATA_SHEET_NAME,
    EMISSION_FACTOR_OPTIONS_SHEET_NAME,
  ] as const,
  // Columns
  EMISSION_FACTOR_SHEET_COLUMNS: {
    NAME_IDENTIFIER: "A",
    UUID: "B",
    UNIT: "C",
  } as const,
  SIMPLE_VALUE_SHEET_COLUMNS: {
    EMISSION_FACTOR_IDENTIFIER: "B",
    EMISSION_FACTOR_UUID: "C",
    VALUE: "D",
    UNIT: "E",
    VALIDATION: "F",
  } as const,
} as const;

export type ACTIVITY_TEMPLATE_CONSTANT_SHEETS = (typeof ACTIVITY_TEMPLATE_CONSTANTS.SHEETS)[number];
