import { RecordingPeriodsUtilities } from "../../modules/recording-periods/recording-periods.utilities";
import {
  IGetAllTargetPathsResponse,
  IGetTargetPathProgressResponse,
  IRecordingPeriod,
} from "@netcero/netcero-core-api-client";
import { ReferenceBudgetMappingUtilities } from "../../modules/reference-budgets/utilities/reference-budget-mapping.utilities";

export const REFERENCE_BUDGETS_DEMO_DATA = {
  referenceBudgets: [
    {
      id: "c24cac81-4a8b-4353-b9d9-7597dbe5da44",
      name: "Referenzbudget",
      organizationId: "1beb8666-dfb3-4bd2-a2b0-c7aa0ce210e7",
      referencedRecordingStructureIds: ["1d74a697-766d-46c6-b0d1-fd258e7839c4"],
      valuesFetchedAt: "2024-06-25T10:17:17.231Z",
      calculatedValues: {
        sum: 2719188.086749145,
        sumsPerScope: {
          co2_equiv_scope_1: 762634.1692020991,
          co2_equiv_scope_2: 267361.2989124779,
          co2_equiv_scope_3: 1689192.618634568,
        },
        recordingPeriods: {
          "fecb4d64-8eba-4799-bbcd-78f271ce4fce": {
            name: "Geschäftsjahr 2023",
            startDate: "2022-12-31T23:00:00.000Z",
            endDate: "2023-12-31T22:59:59.999Z",
          },
        },
      },
      referenceYear: 2023,
      referenceRevenue: 1000000000,
    },
  ].map(ReferenceBudgetMappingUtilities.convertApiReferenceBudgetToLocal),
};

const TARGET_PATHS_DEMO_TARGET_PATHS_BASE: IGetAllTargetPathsResponse = {
  targetPaths: [
    {
      id: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
      name: "Unternehmenszielpfad",
      organizationId: "1beb8666-dfb3-4bd2-a2b0-c7aa0ce210e7",
      referenceBudgetId: "c24cac81-4a8b-4353-b9d9-7597dbe5da44",
      referenceBudget: {
        id: "c24cac81-4a8b-4353-b9d9-7597dbe5da44",
        name: "Referenzbudget",
        organizationId: "1beb8666-dfb3-4bd2-a2b0-c7aa0ce210e7",
        referencedRecordingStructureIds: ["1d74a697-766d-46c6-b0d1-fd258e7839c4"],
        valuesFetchedAt: "2024-06-25T10:17:17.231Z",
        calculatedValues: {
          sum: 2719188.086749145,
          sumsPerScope: {
            co2_equiv_scope_1: 762634.1692020991,
            co2_equiv_scope_2: 267361.2989124779,
            co2_equiv_scope_3: 1689192.618634568,
          },
          recordingPeriods: {
            "fecb4d64-8eba-4799-bbcd-78f271ce4fce": {
              name: "Geschäftsjahr 2023",
              startDate: "2022-12-31T23:00:00.000Z",
              endDate: "2023-12-31T22:59:59.999Z",
            },
          },
        },
        referenceYear: 2023,
        referenceRevenue: 1000000000,
      },
      goals: [
        {
          id: "294fb349-8dc1-4e89-a840-3a1ae8df1087",
          targetYear: 2025,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 508000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.04,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.04,
            },
          },
        },
        {
          id: "ee0962ed-a736-4ecb-b669-bdaa46a36dd1",
          targetYear: 2026,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 496000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.08,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.1,
            },
          },
        },
        {
          id: "531040fa-e0f5-4b83-b2d5-f9a5044b2263",
          targetYear: 2027,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 483000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.16,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.16,
            },
          },
        },
        {
          id: "52876450-c85a-4b01-aaf2-f59e4cef1f44",
          targetYear: 2028,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 471000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.2,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.2,
            },
          },
        },
        {
          id: "0d7e9cb6-9037-428b-98b0-2496715d3244",
          targetYear: 2029,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 458000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.24,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.24,
            },
          },
        },
        {
          id: "425b4a39-7c74-4de5-99b6-e8eed22f86fc",
          targetYear: 2030,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 446000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.28,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.28,
            },
          },
        },
        {
          id: "8fba282d-7e8d-41ca-88c4-ac6668150680",
          targetYear: 2031,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 424000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.32,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.32,
            },
          },
        },
        {
          id: "40508a87-205b-41ae-b8fb-5ff3950691a2",
          targetYear: 2032,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 402000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.36,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.36,
            },
          },
        },
        {
          id: "5a439b42-ee91-4865-8745-120fef44613d",
          targetYear: 2033,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 379000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.4,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.42,
            },
          },
        },
        {
          id: "8f4cf9c7-da25-41f4-a054-15003b6a719b",
          targetYear: 2034,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 357000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.5,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.48,
            },
          },
        },
        {
          id: "b05da0c1-1141-405e-900c-453f3e537708",
          targetYear: 2035,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "toValue",
              value: 334000,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.55,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.55,
            },
          },
        },
        {
          id: "a8574f08-5f56-457d-b084-c65159087a7d",
          targetYear: 2040,
          targetPathId: "c6bf42c5-bf0a-4117-8469-5599daf8c6a5",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "inPercent",
              value: 0.9,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.9,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.9,
            },
          },
        },
      ],
      state: "committed",
    },
    {
      id: "ccbc654c-f0bd-4362-b761-55aee6130a76",
      name: "konformer Zielpfad - relativ",
      organizationId: "1beb8666-dfb3-4bd2-a2b0-c7aa0ce210e7",
      referenceBudgetId: "c24cac81-4a8b-4353-b9d9-7597dbe5da44",
      referenceBudget: {
        id: "c24cac81-4a8b-4353-b9d9-7597dbe5da44",
        name: "Referenzbudget",
        organizationId: "1beb8666-dfb3-4bd2-a2b0-c7aa0ce210e7",
        referencedRecordingStructureIds: ["1d74a697-766d-46c6-b0d1-fd258e7839c4"],
        valuesFetchedAt: "2024-06-25T10:17:17.231Z",
        calculatedValues: {
          sum: 2719188.086749145,
          sumsPerScope: {
            co2_equiv_scope_1: 762634.1692020991,
            co2_equiv_scope_2: 267361.2989124779,
            co2_equiv_scope_3: 1689192.618634568,
          },
          recordingPeriods: {
            "fecb4d64-8eba-4799-bbcd-78f271ce4fce": {
              name: "Geschäftsjahr 2023",
              startDate: "2022-12-31T23:00:00.000Z",
              endDate: "2023-12-31T22:59:59.999Z",
            },
          },
        },
        referenceYear: 2023,
        referenceRevenue: 1000000000,
      },
      goals: [
        {
          id: "75be48ed-8eaa-4487-9676-424327cbcd4f",
          targetYear: 2030,
          targetPathId: "ccbc654c-f0bd-4362-b761-55aee6130a76",
          targetValues: {
            type: "relative",
            intensities: {
              intensityScope1: 25,
              intensityScope2: 24,
              intensityScope3: 55,
            },
            targetRevenue: {
              type: "toValue",
              value: 20000,
            },
          },
        },
        {
          id: "2097d9ce-9371-4b27-a521-f0c98485dc9a",
          targetYear: 2036,
          targetPathId: "ccbc654c-f0bd-4362-b761-55aee6130a76",
          targetValues: {
            type: "relative",
            intensities: {
              intensityScope1: 0.03,
              intensityScope2: 0.01,
              intensityScope3: 0.05,
            },
            targetRevenue: {
              type: "toValue",
              value: 20000000,
            },
          },
        },
        {
          id: "c81cf765-d9c3-425d-a614-27b48ab8e516",
          targetYear: 2040,
          targetPathId: "ccbc654c-f0bd-4362-b761-55aee6130a76",
          targetValues: {
            type: "absolute",
            reductionScope1: {
              type: "inPercent",
              value: 0.7140000000000001,
            },
            reductionScope2: {
              type: "inPercent",
              value: 0.7140000000000001,
            },
            reductionScope3: {
              type: "inPercent",
              value: 0.42500000000000004,
            },
          },
        },
        {
          id: "142aa65a-b4fb-4af6-a3ba-29abeef40869",
          targetYear: 2050,
          targetPathId: "ccbc654c-f0bd-4362-b761-55aee6130a76",
          targetValues: {
            type: "relative",
            intensities: {
              intensityScope1: 0,
              intensityScope2: 0,
              intensityScope3: 0,
            },
            targetRevenue: {
              type: "toValue",
              value: 0,
            },
          },
        },
      ],
      state: "draft",
    },
  ],
};

export const TARGET_PATHS_DEMO_TARGET_PATHS = {
  targetPaths: TARGET_PATHS_DEMO_TARGET_PATHS_BASE.targetPaths.map((t) => ({
    ...t,
    referenceBudget: {
      ...t.referenceBudget,
      referenceRevenue:
        t.referenceBudget.referenceRevenue !== undefined
          ? t.referenceBudget.referenceRevenue / 100
          : undefined,
    },
  })),
};

const TARGET_PATHS_DEMO_RECORDING_PERIODS_BASE: IRecordingPeriod[] = [
  // {
  //   id: "43d0e6f7-adfa-4e4f-b779-e07cfdd34a64",
  //   name: "Geschäftsjahr 2027",
  //   startDate: "2026-12-31T23:00:00.000Z",
  //   endDate: "2027-12-31T22:59:59.999Z",
  //   recordingStructures: [],
  // },
  // {
  //   id: "c87a795a-139f-414d-8ce6-3e9c6cf52ce7",
  //   name: "Geschäftsjahr 2026",
  //   startDate: "2025-12-31T23:00:00.000Z",
  //   endDate: "2026-12-31T22:59:59.999Z",
  //   recordingStructures: [],
  // },
  {
    id: "0fe997c0-e4b7-438a-90e5-dd5155a11856",
    name: "Geschäftsjahr 2025",
    startDate: "2024-12-31T23:00:00.000Z",
    endDate: "2025-12-31T22:59:59.999Z",
    recordingStructures: [
      {
        id: "be669d65-f368-4d55-aca1-0e3ee4ea98cd",
        name: {
          defaultLanguage: "de",
          translationsPerLanguage: {
            de: "GHG Protocol",
          },
        },
        description: {
          defaultLanguage: "de",
          translationsPerLanguage: {
            de: "Greenhouse Gas Protocol",
          },
        },
        categoryIdentifier: "greenhouse_gas_emission_ghg_protocol",
        icon: "mdi:molecule-co2",
        evaluations: ["emissions"],
        recordingModelId: "2d332330-536b-40f3-bd87-73c6d1c6e7e6",
        recordingModelOrder: 3,
        templateUsedId: "d0f3b08b-b189-414e-bd46-f5052449bc2b",
        structure: {
          type: "thg",
          rootSustainabilityCategory: {
            name: {
              defaultLanguage: "de",
              translationsPerLanguage: {
                de: "GHG Protocol",
              },
            },
            children: [
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 1: Direkte Emissionen",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Stationäre Verbrennung",
                      },
                    },
                    children: [
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Fossile Brennstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "9c7a576f-8b18-4a5e-8d2c-c03aab30bec3",
                          "f07db0b1-7151-4a76-b8fd-712065f4d69d",
                          "c5f959a8-51c8-47a5-b220-216096329f9b",
                          "71898040-a4cf-4419-9960-60d49f8f2b9e",
                          "7f0b4970-08f1-41e7-bd54-f3952ef73b4a",
                          "32505497-9913-4865-87b3-cb9d4fce4cb0",
                          "60f05805-bd5b-4c56-87d8-5e8f8ce58e2c",
                          "8f896502-eb0f-4a6d-996a-c1ac43e2aaf6",
                          "87cff712-f006-40dd-8577-22fac00d81d8",
                          "ac5cb484-9274-43bf-8039-94c64c62ff5b",
                          "9466a183-7720-42b1-a55e-6cc7006cf96a",
                          "58bac968-5459-4860-98f3-9270cf747aac",
                          "50f2fc82-773b-46ad-86cd-543c72fb78f8",
                          "3f440692-5818-44b8-8633-6d863135d2d5",
                          "88e1860b-c74c-494f-b458-612a361326ce",
                          "6a814e55-b08a-419d-aecf-2d19ab62542c",
                          "c2373822-bc63-47dd-a540-9b25ccfbe967",
                          "527f8c0d-44ad-46b5-ae75-bd625500316f",
                          "183132ec-7f39-4ed0-9f6a-9b90ab19f1cb",
                          "8a744613-8d35-4402-bca8-999bce576100",
                          "4a182864-b6f8-493b-8b47-e57dc86d2572",
                          "85050d61-c509-4f70-990d-143ef7abd8f1",
                          "b277d8cd-a485-4451-a514-6992f986b96b",
                          "4e6455b6-bf83-4d87-b17f-5498931548bd",
                          "049c53e0-538a-4280-acda-ffc113e13681",
                          "3d66840c-a7c6-4b68-8240-6c3c5aa2c0c0",
                          "90347371-c2d4-4390-9682-520e657e65b7",
                          "8b9b8c48-32e0-4b7a-aa79-d8b91c7f50e9",
                          "b84e2f05-b531-475d-9e54-43ef307bd11c",
                          "b7ccea41-086e-4052-b138-9bac3bbd66d3",
                          "9206a510-f304-4b7c-9871-d5b8412d89b1",
                          "1a7ed39a-a924-47d9-906f-429d6b46c891",
                          "825cd285-3542-45f6-b96b-98c063e49714",
                          "9f349cae-7e0b-4721-8ab8-86b1da22ee2c",
                          "7fdf1a85-22fd-4c07-920a-1fc0fcf9f208",
                          "92e87437-3ecd-4317-9032-f435b603cae9",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Biogene Brennstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "bba80f8e-acf4-4dee-882c-120a2cda014a",
                          "2006a604-8621-4b5e-8880-e99af9c83371",
                          "3f0adfaf-752d-4065-823c-946b811e02de",
                          "1070c7eb-5f0a-437b-8d0d-124e9c8f30e5",
                          "f81b42be-0c37-4e29-bc37-35b64f410e0e",
                          "53b37822-1f78-4345-8432-1b419f91d497",
                          "b5da4622-7e5b-4f2c-84b9-374c7ed59623",
                          "30ef5dfb-d3c3-4f8a-ba32-bc3659085001",
                          "0c60fcbb-c1f9-4199-a0c2-ecd46fb54301",
                          "3ea8584b-af3f-4349-9478-6fd15501fa7c",
                          "138548b5-3614-4b53-bcd4-c037bde249ed",
                          "4defffff-4322-42d0-aad0-2195015d43f3",
                          "3760ee43-40ea-47d1-a640-473306afbed2",
                          "aa0493d1-7468-4a66-87fd-a09cf34e76ee",
                          "30370be1-f812-4d0e-a81d-71cae7ad9a43",
                          "a31ac693-7ab0-4266-aa06-28d6e643f078",
                          "816f28e2-2acf-4fe6-8a37-52ff8a64ed40",
                          "64f5e221-0c8a-4f9c-bdaa-aa10ad9af6d7",
                          "38afe4c9-1ea8-4ba3-9768-45305ebcc2f9",
                          "f6c43d30-25fe-43c5-855e-25e16c0e562b",
                          "105c9a90-6cb9-4c73-9bab-c61479dea509",
                          "029c1947-633e-452c-a2ba-5bccdcec3bec",
                          "1c25ff30-e725-4e2f-88f2-a114e8e11b85",
                          "9a249675-2b83-444e-91e2-bd0d82fb2005",
                          "c13b1676-e4b1-4e45-a353-5c36cde129de",
                        ],
                      },
                    ],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Verbrennung in stationären Anlagen wie Heizkesseln, Öfen und Turbinen",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Mobile Verbrennung",
                      },
                    },
                    children: [
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Personenfahrzeuge",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "e284c9bb-7da4-4a30-98ff-dd6f403cc50f",
                          "51225dba-c6a9-4e6c-b3a9-8e5e09f48405",
                          "119c208d-aaa5-4823-beac-4c644d1ef0a4",
                          "f5c7ef4b-1968-485c-ac1f-f2c9957570c4",
                          "99507e93-603c-478e-9612-7b3672835b05",
                          "fd707fb6-91ce-43d5-a616-368756f680ec",
                          "995947ae-0129-4e9f-9c08-0ba39d7c0ed7",
                          "e123e6bf-4ab8-40df-9e82-22a97c991835",
                          "6f709c2e-d866-4ec2-835f-62221f585fc9",
                          "4ecdba16-013e-4887-a408-8965af129142",
                          "ae6ba963-de83-4189-8d88-abbfa0f85c9d",
                          "3e7393c8-ef4e-44a7-b837-2c6578bc5a37",
                          "a7a46fd3-fd25-431b-bcab-4c92abab1cd7",
                          "99334cff-9927-481f-a2f3-70f5da57106f",
                          "a0e58d83-1d66-47b5-8698-2ee32a4eea3f",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Transportfahrzeuge",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "9f7fc0ab-16d5-474c-b3b3-db1b03651387",
                          "2b769219-9447-4be8-befa-f73d444041fd",
                          "12b8c1a7-7a17-4c62-8f7e-2cfc6d2943ce",
                          "ebefbbd6-d432-414d-b6d5-d85fa64f351b",
                          "6231d411-4e72-4837-b8fc-df46ca8886a5",
                          "12db05cb-bdfa-4e4c-b3ba-ef15d68e3d8a",
                          "e2f0d8af-8ed7-48b3-a336-9fd8fc29932f",
                          "a5589a5b-a6e7-46bc-a253-ce6f03fd8c56",
                          "587fbd16-207c-4688-9752-a35dc71f42c1",
                          "c88d40cd-ae9c-4983-9498-c4ac69d61e2c",
                          "490e128e-bb9c-4e11-b512-c85f1fb1c424",
                          "a8a9d103-ae4e-42e9-9340-93d7a2950ef0",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Weitere mobile Brenstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "af1bd8e8-2cfa-4398-9fff-940cb1ac11c6",
                          "89f71de1-3b6a-44a4-9af4-01d4c7dcb78d",
                          "c0e1fd00-644c-4518-9082-8ac87b4b0325",
                          "d96ce5e3-fc1f-415e-8318-a6bc1964ed8b",
                          "0bf69f45-4c25-413b-afc2-13b1875acac8",
                          "0cb2a601-af53-4927-8e6c-f8d41edd0e7d",
                          "a28a5d21-721b-4cab-a147-51677c02050d",
                          "7e2742c7-5df4-4143-8b7c-78f40e4a4740",
                          "001ce258-e294-4133-9c67-ec292a69b157",
                          "0a8c795d-577c-4ebd-9f6f-c84e4a62eee2",
                          "d06d574d-f206-41cb-8760-eeb57d9e9126",
                          "e722f39c-aeb5-4b1a-b1a0-08c0f6471a1f",
                          "2bd55ad8-b32d-49e8-b2fe-cb8c81c60e47",
                          "27bd0c61-f135-4521-8558-7948e5bdc2bd",
                          "049904c9-710d-45ad-b68f-72d7234859b3",
                          "78bcb512-7c0d-4ee6-8ad8-9ed662249cc1",
                          "16629ca4-2a75-453b-9f43-01c263f693dc",
                          "51a26e21-bea8-4bf0-9bfd-249d48f56247",
                          "b13148fc-e97c-4b91-9a21-b0f113a2400d",
                          "83cd8228-9928-4e7e-b692-9dbdf4b2e01d",
                          "4517e03f-bbea-4821-8935-00bc22665dac",
                          "e58a5a32-7b6a-4e5a-a1eb-237c3f3bc4d9",
                          "0a76f123-798a-4c92-9f4b-4d2d27705331",
                          "9b31efbb-4497-4c46-87e8-b1098f2d5438",
                          "942b747f-351b-4611-8c5e-85264b867a84",
                          "230b5ba9-3753-4604-a33b-3747b2de03e5",
                          "97164e6e-a7a5-4b9a-a158-b7de7e8f7f11",
                          "0380a020-6654-4bd2-898d-f3a4478cd872",
                          "f2258aa4-941f-4003-8404-e6d67e82e367",
                        ],
                      },
                    ],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Zu den Emissionen aus der Verbrennung von Kraftstoffen in mobilen Quellen gehören Fahrzeuge, die auf der Straße für den Transport von Mitarbeitern oder Verteilerfahrzeugen eingesetzt werden, sowie Geländefahrzeuge, Motoren und Geräte, die für viele andere Zwecke verwendet werden. Geräte, die sich nicht aus eigener Kraft fortbewegen, sondern von Ort zu Ort transportiert werden können (z. B. ein Notstromaggregat), werden als stationäre (nicht mobile) Verbrennungsquelle definiert.",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Flüchtige Emissionen aus Klimaanlagen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Kältemittel wie Ammoniak, Propan und Isobutan sind keine Treibhausgase (THG) und sind daher nicht zu erfassen. Alle THG-verursachenden Kältemittel, die Sie berücksichten müssen, sind in der Konfiguration gelistet. Achten Sie dabei insbesonds auf Ihre Kühltransporte, industrielle Prozesskühlungen, Kühlhäuser und mobile Klimaanlagen.",
                      },
                    },
                    inputParameterIds: [
                      "4c75ef88-270c-412c-867c-63b990c5f1f9",
                      "e4c2d812-9da2-43d3-88f6-363dc33b7a30",
                      "2764fffd-c8f7-4f8d-985a-e70734cc5ef2",
                      "f003fb04-b7a8-40c1-80ec-91b8611c325d",
                      "8554e2ae-0ac3-4bcc-ae82-14ce628010d1",
                      "b765226b-7926-4bca-9b1b-c1c6f8fb6534",
                      "4ae55f75-9d0c-4ef0-a72c-cd3a60b0b281",
                      "22cda166-6d89-49a3-a0e7-b8b8f7569bb9",
                      "a574529d-1879-42ab-8ffe-76f2b04ed6bd",
                      "ca7b1c67-36c3-4866-b435-85b2c51ae09a",
                      "87d088be-077b-42c3-b72d-bcad869822e7",
                      "1d1259f1-5b00-4967-a6ec-1a527fae35d5",
                      "92e5f36a-f128-4d54-be61-c301eea9393e",
                      "c11eda39-3e9f-4ca4-baa8-7bd71980d7a0",
                      "4cc3a677-a665-4b14-bfdf-d2d808730b63",
                      "eb61bfdb-cce4-446c-a445-75f92af02969",
                      "13957eb6-e0c7-4f6c-856e-e1bd81aa7237",
                      "4dad6667-856d-4411-a8fd-4b251357ccfe",
                      "1db1e97e-eb22-4a3e-ac97-772650410805",
                      "1c97aa0e-f4bd-4c1e-9050-2ea234ed95d5",
                      "308b6153-4648-4b3e-8422-0e0e6e876089",
                      "f1406b52-9e26-4203-b703-ba0252752fac",
                      "f23ee296-efa4-4535-a2ed-cef296d1c210",
                      "8388fed1-131a-4787-955c-78add39622f3",
                      "2c852a57-20f2-47ed-bdb7-9bcbfbcd2dd1",
                      "f479c696-7105-4f7b-934b-2df16e8a2479",
                      "44153c55-d588-4e6d-8c53-4e3e411b2dce",
                      "9394635f-bc09-40cc-b556-cb37b2b79af4",
                      "c7d75e5a-28cc-4d11-80dd-261e5593e266",
                      "25e6ef08-4b4f-4085-831e-411cdf3ac934",
                      "f587bcdb-0879-4388-bbf1-263745bdced1",
                      "1ed781e0-079c-44d0-8c7e-00b1dc3e6e76",
                      "bcde895c-0417-47dc-909a-96a07d67e675",
                      "403ce41e-a8f8-48c3-a3d6-b688083ba4b8",
                      "6207faf2-6f55-4bf2-8ef4-191788e94c6c",
                      "72973e2a-e857-4d53-b75f-61c0b304a3bf",
                      "e139bfeb-e154-48c3-bcef-dc4c5426034d",
                      "5ab18133-3dd4-4103-86c0-3574cedcc540",
                      "ccd2aa1f-9be3-4eba-9ec5-b0d6d8c3357a",
                      "faa55496-ffdf-4116-92a3-d802543d536a",
                      "91fcfe4d-4a3b-4fe8-a519-0ff068b2bf21",
                      "962f2c5a-d95f-4c37-94c0-f6a20b3e3a90",
                      "a2edfe0e-1395-411e-a741-bc0b02397f3b",
                      "a9986c76-0454-4ae3-a8fd-ed3cc4527fa2",
                      "0679ebcc-0bb6-4e7d-bce1-fb72d56bd1d0",
                      "531486a6-b5ab-4bc8-bf59-e9aabd465cba",
                      "3aafb132-721a-41a1-bed4-71a902f8673d",
                      "f2b54458-2cba-4b57-94a3-2aadbf22f7f7",
                      "579eb9da-d523-406c-8f2e-e5de662c4901",
                      "7eec864b-d94e-4cc4-9232-0ba1ddde52e6",
                      "15f6995d-264a-4288-b89c-82b8f207fea2",
                      "4d02b73c-2a11-409a-9983-4190eebd61cf",
                      "22a7b2a0-43e6-4788-8814-e83cea26e7b9",
                      "2920b2de-7a85-44f4-84e3-0984097f957b",
                      "d096cd4d-7ff1-457b-9a18-e269862ac386",
                      "798a9e82-fff3-459d-a122-03d1de8cd6f1",
                      "2f419b8e-88a9-4225-ab5d-e594f68afdd6",
                      "adb8349c-258c-47ca-a13c-63e67e35f3b5",
                      "f63428f3-7def-4b5b-823f-ba754272b290",
                      "9559f9a7-7c35-465f-ac11-5931932bc771",
                      "aac4fb33-a488-4808-9a0e-8b417f0ce358",
                      "5c1452bb-6461-49cc-afad-c10c4ee98e35",
                      "6b6bd529-02d8-494c-88be-34cd3b33fc62",
                      "0cccf753-a235-48dc-9d35-9338bd7c321b",
                      "8d8894fb-93d3-494d-9548-17de5e384033",
                      "d25912c9-7bec-4772-8b23-0c61b7475f25",
                      "15a51520-cba2-43b4-9092-a6b9d114ff12",
                      "331fa0a0-de8f-406e-8594-52b9e74e790e",
                      "0c3875fc-ca5e-4f72-b14f-dd0bc37f163e",
                      "d0e549c6-659b-4c06-a6f7-a4bf8d5a5d27",
                      "3226ef52-b048-467a-bbc7-c83ab8134773",
                      "ac0f338a-64a6-4ac5-808b-7a7f7ccefd8a",
                      "489bd708-dca8-4de5-8c69-b5b7fc3bb443",
                      "7910af0f-4083-4ae1-9247-5d9a1ced5c17",
                      "a3d77ba9-bf37-403f-a91c-8762d5ee42f8",
                      "d2932b1c-3167-4f56-a31d-97c729cf54f5",
                      "c04e1e75-1dbd-4349-8ef3-b60c05ccd4e6",
                      "b516816f-2622-4511-aed9-1ab7fe2d06c1",
                      "aa23270d-ba3f-41eb-9cc4-95eb0ee4f0f1",
                      "84fe054e-7d14-445b-9709-55c1643e1861",
                      "39f15589-bcb0-4b41-92d4-2e9a62b09d7f",
                      "82e97b5f-8173-42e4-b29f-dd500c987408",
                      "c96a065e-7907-435b-8753-504dabc5b5b5",
                      "f07223d3-43b3-4b67-a332-0ec0590aa53c",
                      "1c6b9db6-86ee-45df-977c-48e0e9fcea23",
                      "8e50ef16-d69f-401b-b842-3daa2b9b831d",
                      "60c79881-449b-495b-be6b-307e38753f11",
                      "7d8799b8-c648-4a8d-ae47-cb4d4c11c883",
                      "52bbc192-11c5-4325-a9a5-c000be647069",
                      "de918377-d741-4eb9-afa8-a663719792fe",
                      "762bc2a8-fce4-4f4c-9337-53f9db1f3420",
                      "b1b93d78-b73d-484a-887b-a1bca9e36fc7",
                      "d89ea569-19b1-4b2b-a88d-c211a4027f08",
                      "3b5d30fe-45c9-4314-a0db-504dcb76d15d",
                      "3e859061-a3cb-4aa4-a7be-b83ce45f593b",
                      "1e843ff0-a280-451d-9dbb-02648f3a751c",
                      "6024e155-e139-440a-81bf-ded6887adfc1",
                      "8280b26e-3aef-49e7-9216-1ba74eafb49f",
                      "32376c04-fc5d-400e-99a0-7025c0729751",
                      "71a8943a-0155-4472-acaa-b880076b1b0b",
                      "cfa5ec38-d63b-4df0-b22c-a5f8e44c81dc",
                      "c801f7f1-dd92-4a27-a9f8-b80037f0f21a",
                      "bac9ffd1-7451-4770-8dc3-88d9182d5aac",
                      "2e9f2671-0fbf-45ff-a4a1-32bf893147a4",
                      "e36c29f9-8f20-48e9-8e90-b4ee85ba8937",
                      "3ec31a4b-7a01-4154-a2ed-7bbe84fef2a3",
                      "24daaa10-7a09-48f1-ba47-1f62b4401260",
                      "60951ae7-dd31-46cf-b3af-8b2c7ea27350",
                      "22283d5f-4539-4d2c-bcf7-e69b734a2f4b",
                      "540e9c5d-9d26-4492-b999-8d7c6f53a92a",
                      "c9f901cf-d7a5-4d1e-b198-7e76b4642f49",
                      "19fb9e7d-b658-4828-ab8b-6db2f33d1013",
                      "5bfbb230-f5a1-45c0-8f8d-ab0ad43826be",
                      "bad4188c-2252-442f-83d4-92a30075eb44",
                      "ec11a28e-14f6-4fbd-8b7c-72493bd9e88d",
                      "8ca45c5b-5115-4c62-9964-4837d4c14c42",
                      "922e89c5-e747-4916-9596-81cbbb2e46bb",
                      "45c2d49e-fa88-4538-853c-d3e52b249b12",
                      "efbd7a82-a6cf-4d4a-9e14-67e129738311",
                      "72d41ed0-5992-460d-8529-66bc39429166",
                      "9be25235-b42a-4c38-b884-99d1cc59f0db",
                      "0f2a816f-50d1-4e44-811e-54eff9f393b9",
                      "b8c29b41-2153-423b-8419-4a94664ba58c",
                      "f00aa7f5-537a-4a46-8df8-840f09b38240",
                      "f2aa205e-30bf-474f-ad30-b3ddfdf0378b",
                      "422d1b18-cb7c-4f97-97a4-97daba0e09e9",
                      "594b1746-5a15-405b-b705-bae5be0093e1",
                      "933a00c6-c808-45b9-9c09-65bdeb9f2e37",
                      "35eedace-0c63-4451-9309-454bf1508298",
                      "3b972973-bf27-4f86-9fee-43e557f4cee7",
                      "c9f9ecfa-9738-4217-a50f-d6bac70dd7e5",
                      "b2785218-67a7-48d6-8301-aa44f0b8abf8",
                      "77b12148-e6e4-4544-81e4-069dabb4145e",
                      "4a3ea521-2aac-4c7e-aa96-6d9740fe50e8",
                      "b9963959-71db-4386-8483-966308ababb3",
                      "88f01b21-5139-466c-9ab3-ba7f424c6396",
                      "300df598-8a99-4162-8e70-2551d38c1744",
                      "3bee318a-b54a-4fca-885b-7df67db9f45f",
                      "82070eef-448d-4bb4-a670-1dfafe8212be",
                      "e2bcf442-ea82-4645-a169-5ef977a9597d",
                      "b351cbaf-723e-465e-97e8-3b4f83734e04",
                      "d8508e8d-ce72-407a-81d6-c271bede8030",
                      "a244d36b-7b89-4810-a550-26297c8e3ffc",
                      "ff866450-7078-4ddb-9329-55cec5dffab0",
                      "b4684e65-9a67-4561-b0ee-841f88843869",
                      "22e18448-2e63-49ac-92d9-b2f903b484d6",
                      "34370aba-79e7-4634-bf5c-7a4570e329ef",
                      "2ee947fd-df8c-44d9-9129-e407f54506d0",
                      "45cfb315-f849-45aa-bbf0-d0fada1080fb",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Sonstige Emissionen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus chemischen Prozessen oder anderen betriebseigenen Quellen",
                      },
                    },
                    inputParameterIds: ["d72011d5-49df-4a07-9eb1-064fc7b497ab"],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Emissionen, die direkt aus Quellen stammen, die im Besitz oder unter der Kontrolle des Unternehmens sind.",
                  },
                },
                inputParameterIds: [],
              },
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 2: Energiezukauf (indirekte Emissionen)",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufter Strom - standortbasiert",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die auf dem durchschnittlichen Energiemix des Standorts basieren, an dem der Strom verbraucht wird.",
                      },
                    },
                    inputParameterIds: [
                      "493bef68-7334-4471-8d96-1fbf8ecf9940",
                      "0de96d45-66ad-492a-89ba-d7510ce31b7a",
                      "1a7c47d5-1c66-4bcf-8c58-eda92f872823",
                      "720c0bd1-d8f8-46c6-87b6-736a1c5e12c7",
                      "96b13505-9b0a-4217-8e3c-d91d006ca059",
                      "141c59c9-265d-4246-ab36-4269332f597c",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufter Strom - marktbasiert",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die auf spezifischen Verträgen und Herkunftsnachweisen für den eingekauften Strom basieren.",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufte Kälte, Wärme und Dampf",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Erzeugung von eingekaufter Kälte, Wärme und Dampf.",
                      },
                    },
                    inputParameterIds: [
                      "89ddd73b-c3e2-47c5-9d60-c421d40f9aeb",
                      "a995b1eb-1f5e-4c10-aabb-cabf1f597330",
                      "6e4f14b4-4ded-467a-a7cf-9d8779cd5d44",
                      "4f53d8db-b018-4dde-bc6b-160bad8d07ec",
                      "55390cfa-fab2-48cc-8ce1-38b6945fcc28",
                      "d8db5366-1e87-473d-9fb2-dd32eb4a6fdd",
                      "fb0cf7d9-f438-4e68-812f-0193597b74b7",
                      "f6829641-4ec5-4582-b27f-d9a1402ec9e7",
                      "4751adec-4595-437a-bb30-35924c1c7fde",
                      "f10dbb8c-447f-43b3-b197-5f79efc1ddba",
                      "5c5d765d-114d-49ad-81dd-0cbff9fc01c0",
                      "d41183f6-ac22-4700-bb2b-93bd9be9db19",
                      "123ee853-c851-4b29-9956-7debda72d2f2",
                      "677c2cd5-a7ff-4d1c-8308-905ea1753aaa",
                      "7787533d-ecaa-467e-91ca-bea12e0d2aa2",
                      "e725eda4-4124-40e3-892c-a77b2bee5b3e",
                      "3c740a6e-f25a-4740-bc79-d7efccfe6afe",
                      "09078002-9026-4b95-b539-8b5c72f34cd5",
                      "cf595f4e-7e50-448f-8547-f3c39381ef4b",
                      "ff312653-55bb-4972-af21-458358a48ae0",
                      "4e8df935-d982-4d4c-8dcc-96a56d39f60b",
                      "98ba4467-af7c-4f04-a6fb-0a6c9d9cb453",
                      "9f274b10-be28-4e31-96db-6967939d29eb",
                      "6a762c34-cc90-419f-b0aa-bbf3b7fde63b",
                      "36b77393-c222-4d95-9c05-2eecebda672d",
                      "1cbd4dae-ab2d-477c-8e40-8d0a633b426f",
                      "55c0e317-45cf-48d1-9ac2-49258043f9a8",
                    ],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Emissionen aus der Erzeugung von eingekaufter Energie (Strom, Wärme, Kälte, Dampf), die vom Unternehmen verbraucht wird.",
                  },
                },
                inputParameterIds: [],
              },
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 3: Wertschöpfungskette (indirekte Emissionen)",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.1 Eingekaufte Waren und Dienstleistungen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Produktion und Lieferung von eingekauften Waren und Dienstleistungen.",
                      },
                    },
                    inputParameterIds: [
                      "0132694a-e092-4294-9e08-b404fb6e764b",
                      "0cd6e3e6-5114-4f26-864f-5639096f9e71",
                      "815e4163-a1e4-4bfa-bffd-5dd7b59b531d",
                      "6539ce88-5392-4018-bad6-78ac7d31a1da",
                      "9c06a82d-59f1-4b38-ad03-ea3a8fd3d30f",
                      "465aaeb2-546d-4c6e-92c0-5837ebba4ee6",
                      "ce18196f-0319-436b-962a-045162ecf46d",
                      "254ebe77-581e-42c7-965c-8c6fef4878a9",
                      "7348e08f-c225-4447-873b-d0eb4bb212ef",
                      "cc15a4cd-bd59-4363-9eef-e4411c2d011e",
                      "383963a7-9428-4da2-87c1-65d914a2d867",
                      "1e627ca8-322f-469c-b7ac-e18b8240928d",
                      "98a8fd4b-91e5-4b26-9bf7-e0a8c6250c4c",
                      "059eba83-43a8-47a2-a3d1-46ebee4ec3d3",
                      "d4dbaf56-a140-45be-b5ef-1e11a49bfd2b",
                      "973c640f-2960-4503-899b-2207c3c6ed38",
                      "9b47d7e1-cc09-45ad-8b50-85d9485d8d7f",
                      "98dfa94d-9b3f-488d-84de-0591c371dd9c",
                      "c5ab1947-546d-4d7e-a044-f45ac8a4c451",
                      "c5667df8-b234-4cc7-a1f0-5b3cc96cf025",
                      "d95d341b-1430-42ac-b5a2-3ac0e8406044",
                      "effb4ea7-33b6-4692-bfd1-d08fca76488b",
                      "c64a1b03-a353-4b51-ad56-6c3c2b3a4215",
                      "ed678762-eeb7-4a88-8313-70f82dbf0895",
                      "89d60598-cc44-469c-8c62-2c0ed723caf5",
                      "8cdc3f2f-8d70-4c15-871e-695e2cdafb88",
                      "00317cfb-29c2-4d22-85c3-f6c1c2d2a676",
                      "0c374558-a325-460f-ae85-39be5587c49e",
                      "7336a9d9-ccc1-4d31-ac56-daf7d8eab5b6",
                      "7986da60-1c59-413e-87f4-6a740d3d1dc2",
                      "3c5b5c38-9b4d-44e3-b985-fcf92007563c",
                      "b4edf081-d160-494b-89ea-53a9a4066af2",
                      "2b4a3416-093e-463c-b434-6442d348970b",
                      "c6f375d1-c6c6-4917-af65-121ddfebf06c",
                      "3c65ff87-154b-42a9-9a3a-e95619cb84c3",
                      "f06a5edd-8df7-4a3e-a7bd-b58436c7ef29",
                      "e4b1e6d7-8aab-44a6-89e2-2e73953d1be5",
                      "71c697f7-1c7b-4e55-b25b-2aeaa609673f",
                      "4dfd5e4f-9928-4373-b87c-475348e1a0c5",
                      "a7f772c3-284b-4216-a2cd-90b7599e6354",
                      "06d752c8-2a88-4383-975c-15021934b420",
                      "b8c53fc5-c486-4d6a-90cc-9c99b085c138",
                      "442a7f57-bc8e-47ac-878c-b35f418f0f2a",
                      "6328a878-ccdc-4292-b40c-78c14318654b",
                      "7c1a319c-9ffb-4676-ba75-6fa53503cff3",
                      "970dc775-3883-43f7-a796-7d2f6735a2f1",
                      "b56a2ba5-81b0-43c5-bf24-9443e3fd25ec",
                      "be5f69c5-90f0-42a5-b4c8-377c89f275c4",
                      "7a90eec1-6306-4fc0-b71c-f2847695e587",
                      "31e7d809-b8dc-4f2b-96ea-a5e83b56364a",
                      "3b4f5ba6-5593-4377-b416-978f384e42ff",
                      "fa9241f2-a528-4cf2-9b6a-b339be061abb",
                      "dab0fa72-18f5-465a-9b43-9f802099926b",
                      "b3b84b07-dd31-48c3-b28d-98ccba4d2a09",
                      "ba245139-cefb-4706-a978-0d8f732fc501",
                      "889e5fed-d28d-4903-8ba2-b3bfe55c2b5e",
                      "892357bd-2e40-4866-905a-b9599b8e0041",
                      "7d102153-14b2-49bf-b4d1-ecd2a9e2d53f",
                      "ce5f15c5-19a4-4299-be58-79ddcdc28f69",
                      "3cd34593-cdc4-4183-8aee-59a7b684fd20",
                      "ad675ab5-86be-4833-8f3e-b45aefc64436",
                      "22f51a24-dca4-4310-9b01-474f46d27e4a",
                      "d35a32d7-5eee-4c73-b0cf-fed7e0449475",
                      "58a10a15-b17b-4b8e-a38c-3edcba99061b",
                      "53c68360-b06c-42a7-841d-5a6ff32daf36",
                      "9b4e2e25-9aab-40b2-9719-926b571db254",
                      "16660997-a771-47a3-9135-028eb053c175",
                      "860388f9-5826-4b54-b280-44aff1bfc150",
                      "07188b69-192c-4e7a-9261-24a3c7e4b45b",
                      "c035907e-667c-4a12-98b0-9efc918fd57a",
                      "8a793522-d575-4a05-912b-fa9d30ac3b5c",
                      "9bf362a1-0ca8-4eda-9e84-c6cd07fda336",
                      "7fd02523-76b9-4122-bb36-37d30a3739a0",
                      "edffb755-20bc-4898-9400-1ca1e8037001",
                      "638cb08a-cfd1-4b2b-9077-3670d35d1918",
                      "077fe9cf-5551-4638-9cf8-1b9e1e6e36ad",
                      "f368f170-7205-4b2f-b493-704bcbf9a472",
                      "d66e8cc7-5403-4492-b962-a2b187e534c9",
                      "8a92b068-8b75-4514-9fd2-705b620d9302",
                      "0b447d19-4b7b-459e-af9c-c918a31faad0",
                      "e3e8c91d-f180-46f4-85a5-8e0ab117220b",
                      "ea3b296a-0ebb-47eb-8947-d37256f4b219",
                      "deeed460-322e-4200-b0d2-624d4630b1d0",
                      "d6b5db29-cfc4-4241-9613-0f3f9f7ea548",
                      "e057d986-d1df-4e7a-9fe6-6f50f0ee8b60",
                      "639d072c-ffc5-4b1f-92ca-bd3ffd8bbae5",
                      "59ac9d93-cccd-445a-b194-32b752d93a5d",
                      "adad9f6c-2b21-49c8-835c-e2463bb1847f",
                      "545a7d5d-561e-4abd-adab-a27a4d036d83",
                      "4041bd07-ed0e-4cf5-b6d1-5a99ae489c07",
                      "33b38292-de8c-4124-b3dc-e4d1d226e5c1",
                      "c3a46f0f-3485-4646-b821-035df3e02c37",
                      "0806b603-a7c1-4a0b-ac31-663fb96f28ae",
                      "c34eed41-bac3-45b5-9e60-c8de3a7af55d",
                      "6c16fdcb-61e2-461f-9130-d45d0bc03f7c",
                      "b07eb270-7f38-4a43-a367-c73529899ac2",
                      "7c7794d2-cc8e-4412-9c6b-19281d1a2d33",
                      "0a051310-a069-4ae5-99a7-0f787bce10b5",
                      "f1c52b87-0d9d-43d9-8725-7c3bb33bccd9",
                      "9f34c4ea-ced1-4412-a203-d12a748fc416",
                      "8c7ac4f4-b046-4fd1-80ab-3942e2b0d0b0",
                      "68401040-e9af-4cde-870c-1fbcffa8fce8",
                      "aae324b9-0090-4b8b-9009-262ba94a97c2",
                      "86436c3e-dfbe-4489-bfbf-b986fffd6b86",
                      "b75add4f-b681-4982-82c4-88fc15e14121",
                      "2d457d96-829b-4443-9255-c94ff2341edc",
                      "32b7ce7b-c066-49fe-953b-d50e661d2616",
                      "c2970e69-89a7-42ae-be5b-db19392f901d",
                      "c73b864b-c245-426c-a8a0-614d83ba0f98",
                      "a029f279-5eac-4027-b427-1c4b09c2ce3d",
                      "13ff3af6-e604-46ff-8c67-9f0f56dabc02",
                      "b4f89ae1-3d84-4d17-aa93-f3de1c5234cd",
                      "40dc6744-4506-430b-8f07-ac914a90f9a8",
                      "7df47995-91b6-4cf9-8d94-6534520e826e",
                      "faf2c723-5ab0-466e-95b5-f3bad7c8c5db",
                      "d81bfa62-2001-4a33-bd93-985e2fa78c48",
                      "cd584a6b-c3c2-4aae-9931-b5916ee8bdc3",
                      "8ef46358-0bcb-4a60-bc60-5fcc7fa9da3b",
                      "bc76288d-d5ec-4f47-a662-131b74af08e4",
                      "7097d16a-3fbc-4496-9ba0-f9ea9caebd60",
                      "9dff3ccc-3cd3-4d4e-b7c9-6e19b9a631e7",
                      "f647cd79-8dce-4bef-89c3-4bc95e0d0954",
                      "47cc688c-ecbe-4a82-90ad-84c9f0925cf7",
                      "7274299d-efcd-4dd6-b1f9-dae73f094db8",
                      "910dfd04-814b-42aa-9d0f-68a883877eff",
                      "71f5dfe7-b259-4879-8c00-af6506264942",
                      "63e6d642-85c3-4e90-a74f-ffd7378f4456",
                      "27e6f56d-67f5-472c-8a4e-feb28cacc6c5",
                      "fa9fe886-08dd-4c00-a161-3ac625c9f0c0",
                      "64b37928-b510-46cb-a0ae-697232051877",
                      "e367e5d1-5c53-4322-acb5-6dfdb6277eb7",
                      "bd94853d-3edc-4660-9bf5-8e9f8a520659",
                      "e6aef790-cffa-4d81-bc46-38ee3530b8ca",
                      "fded6c72-e83f-4c3d-8eaf-e47b1043804d",
                      "ff1bef7c-f2e6-4e2d-9bee-8d2e7d3cbf1c",
                      "d47d5564-0d5c-486c-98e6-16bd3cc12fa3",
                      "45a3e98e-fe46-444c-88a7-c6e252d3a5cd",
                      "74193253-07be-4050-829b-14ec99aefdca",
                      "d2e74441-b990-4c2b-977c-7dd24ff9a9ce",
                      "7675d567-b232-4050-b870-099918fe7397",
                      "160a6af2-2dfa-4d92-9214-2dbdb7bfe0b4",
                      "2bb0c886-e5a1-4854-bf60-cffcf981c9e1",
                      "28c4f793-a5d8-4869-a924-e2bd26a6e7c8",
                      "d7033ba2-f03d-4174-a7bf-773e70f98856",
                      "4464b5e6-deff-40a5-8edf-8e9aec61e04d",
                      "1861302f-f311-4dcc-9ba9-7134dd65bc0c",
                      "ab8af4f5-81b5-46f1-8ee8-96740ce09cbd",
                      "d8528587-abf5-470f-b8c8-7af845178c87",
                      "5770d279-c89b-4e89-b14f-4db4045c1b61",
                      "444d2406-fe0d-4e57-94a3-2419ae1fc0dd",
                      "e767866b-8925-4b75-b6e9-493f2ccd6da0",
                      "444cbca0-1dac-44e5-a044-68ad52eb8254",
                      "26bda8eb-758e-4834-a7c7-ad9a59428e6f",
                      "ab23d389-8f93-491b-8ca0-b3e5aa893db6",
                      "e6693e9a-4e13-44e4-ad63-138b2bb03f0a",
                      "b74bc0c6-a0bb-405f-90bb-cbdac793aa39",
                      "1892adb0-770c-43da-96db-bd3f15797a30",
                      "fe3447a9-985f-4647-ad56-7787d1f5101d",
                      "ec0af584-3a3d-494f-bc06-3f754a03a3d8",
                      "921aa571-9f22-472b-a655-070c48cc8be9",
                      "808642c6-cb00-489e-bae0-7693cdb7e0fa",
                      "e8ab502e-9575-485b-a938-c313d1c8815f",
                      "c993fba8-5f4a-4de1-a8b8-bbeb0980b2f5",
                      "257d5988-c7ef-4e47-b008-a72bfbf52c54",
                      "3fcf372e-2fe8-401f-9976-70e4cba79561",
                      "9802d745-e5eb-4dfa-9fad-9d98e821e3bc",
                      "31efe540-2fde-4a0e-9904-cbabeeb21f1a",
                      "fceaee22-09d0-4342-b62e-259cd7ecd8c9",
                      "ebf03966-9623-42ed-b679-8ef74b4bc92d",
                      "8ca4e76e-2245-46dc-a2f1-c36cb96a8d6e",
                      "4fa7a4ae-1cd2-4b1b-9691-6a41ef2d775d",
                      "7988bf2b-288b-4afa-822b-82adc09a49bd",
                      "e92efad5-c75a-4958-8eb6-b293508333b6",
                      "51013ea4-eb13-4933-8e84-5f2f9f160a72",
                      "6a8be0ac-06a4-4316-af6c-d25e558dfffd",
                      "ae30fad6-1d31-4345-92a6-6ce9ce6f1930",
                      "82ad158a-c721-40d6-ba84-3fbfda37a029",
                      "a8a7cca8-2065-4e06-a0df-86357338ca5b",
                      "373fde00-3cb1-4837-a677-9dace1ad357b",
                      "5121192e-cd1b-484e-881f-4dce898615ed",
                      "cfb73b57-6d12-40a7-a9ec-61ffe38fabb2",
                      "cd2f930f-2aa4-4649-9770-5c60b50db5d3",
                      "dce16bcd-ecf3-43c5-8953-e0b4f5ffa40d",
                      "ac525f3a-d786-4eac-84d2-6dc5b970fee6",
                      "39e9e1dc-b9fc-452c-ae2e-278874ae84c4",
                      "e3a16d0a-74f0-4cf8-b891-46fd109d3926",
                      "bcc8ae4a-531b-472f-bb32-fe6872aa59be",
                      "b99b40d1-9abb-4304-9c95-70a04b77fcf8",
                      "801b1816-041e-4386-98a4-aab268c94b7c",
                      "5e6dc89e-f62e-4691-b82c-f87a8f0b401f",
                      "2bbd16e6-db14-4573-98c2-4bb13885ed99",
                      "508dd367-fce3-438a-be0f-c185700ab630",
                      "8764ee8a-087d-4bf7-a108-679c5be92af4",
                      "45be093d-e04a-43ca-923c-288694e0a1b2",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.2 Kapitalgüter",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Herstellung und dem Transport von langlebigen Gütern und Infrastruktur, die vom Unternehmen genutzt werden.",
                      },
                    },
                    inputParameterIds: [
                      "9e44d141-1a55-48cc-a570-0a7b84af1ea1",
                      "50e9bcba-71d6-4570-a115-20922b6937c3",
                      "2dc9e4e4-37b8-4f34-b34a-8f1724580cc7",
                      "e702c34d-ac2a-4d05-8658-e4625e7a474c",
                      "b1c4bf84-61d2-42a4-9220-4236c989d0e7",
                      "b1efc4c7-b2a5-479e-b136-64b52a9f7bb1",
                      "39123181-18c7-425e-933e-0f3eb4d2b1b8",
                      "6375cb81-fbd4-47dc-93cc-ad30f94a1187",
                      "50e77e71-b04d-4fc5-9ea5-acc33571c879",
                      "1997b4f8-b017-42ad-a481-bf8e74f55db9",
                      "0c87e061-3939-4e84-98a1-4db4a9280f00",
                      "cef0c2c1-790b-48a6-aac6-f0323ee93a03",
                      "46a60f13-9ab6-40a7-a590-a2558549086e",
                      "bdd2670f-5158-469f-98f5-8c7d38b1c9d9",
                      "3a2b7691-2d16-49d8-afa1-605836a6d286",
                      "a3d75f6a-6417-434e-8b95-2c3cc2976947",
                      "ccc92d9a-4a1c-45db-a1a6-ba84f076af1e",
                      "388fb5b1-24d0-4f87-991b-b55471573ee9",
                      "ec0adb0d-a45d-4e9a-a951-ad593e8939f8",
                      "b33975e3-8d36-40fa-9a28-0434f8d84331",
                      "925da0e4-289d-4944-a332-3bedaa01bfca",
                      "8e543287-5396-470b-a011-e8d4d9db3a21",
                      "4e902662-593a-4e27-ae05-3938db4785ed",
                      "844a7ced-52ef-4d14-bfa0-bc893c141e31",
                      "c46dc334-54fd-4fe0-ac0a-37be4dca48cc",
                      "29e3f35c-5997-40ba-90bf-21b0f18d1a35",
                      "a2c986a0-fbd2-4ca9-a1c6-383f28faa8bf",
                      "f5372168-a82a-41c9-bcb4-5f9d556df50b",
                      "4f89b535-fda8-4ea4-ba60-d551fea11fe1",
                      "65e93dda-eb96-40f9-961e-a2394f09dcb1",
                      "b954578c-0607-40e3-85f0-edb63c31657a",
                      "dc981a2a-e24f-40ed-ab6f-3a951cb4ea6c",
                      "582de41f-97c9-4d24-a05e-4ca1f4b07f02",
                      "8878b9c7-d05f-4b10-bade-4e08aea0e0f8",
                      "726e1c6b-8e1e-4db2-889b-1fe97aafa255",
                      "25847756-c8ce-4ba9-b28c-62180aaea18b",
                      "9552d7b5-68b3-4a73-9c74-f390f2798f07",
                      "a4bbdb0c-4dc7-48ab-a67b-ebae55d58938",
                      "5b005cab-125d-4974-96b5-d35de4c85aa8",
                      "d85e5e80-c727-4358-a290-3b3523dbecda",
                      "9b140aa7-0c8c-4819-879c-7a6f561c5e4d",
                      "72efad37-6267-4471-b819-b99e35259ce0",
                      "106a1843-2daa-4cda-82fb-3d46afead478",
                      "a21d6f59-be7b-474a-b490-7562aea77902",
                      "028dc872-17e8-4268-9ef5-1591e899b4f1",
                      "f672ce30-1e1c-46e3-a514-871e6708789b",
                      "08f85061-d447-49f8-b0c1-b97139641724",
                      "8512018c-b7ed-4e44-83fc-8d0946519fc9",
                      "6dcdd9c8-d8ab-47d6-b595-db2eb1b4d521",
                      "73c44b59-2ea6-4f28-99ff-d67352e66efc",
                      "40d027f4-5aa0-4787-baaf-70589c4fce96",
                      "9d1af810-cc22-4364-8075-0be2e133ca78",
                      "77538e4e-6a9b-42f7-89ab-1ebf3ac7e7f7",
                      "dfaa0026-f972-4391-bd6d-bec25672a7f2",
                      "c26d3fa9-920f-41f6-b1bf-6b98d50de0d0",
                      "73fbc734-4c47-4423-97d7-52752a12f56e",
                      "1c76f168-120f-4b32-800f-f3610dc9724f",
                      "9bcf521d-e983-4a6c-9a76-25b9af42f281",
                      "4f9cade4-d732-48c2-a979-f64a93735915",
                      "879db7ed-4fe2-4821-9267-5a9385a30d96",
                      "1d93cdba-b6a9-4e5e-8585-93cad86685af",
                      "458f1eb1-e734-402e-89b3-e6ebfa593658",
                      "f0bdd366-7965-4ae7-b28c-d721a44e6eb3",
                      "cbdcbb74-30ea-4f01-a266-039a3af3b678",
                      "cb9cf0aa-220b-47f7-9cbd-dd807257465a",
                      "ba35ce60-f84a-486b-8ddc-de98f9141ffc",
                      "0a5fffd5-3579-4a18-b0b7-1265857de668",
                      "5fde7d6b-0520-4903-a7a2-c7f7a30d5d10",
                      "2b001273-fc4a-429c-8bf6-0aad266f5dde",
                      "ab924dad-b7e3-45f6-b641-e0ba16058e35",
                      "3156db2c-faa9-4af6-9ce0-2be87045e4af",
                      "71436f02-c201-4e8e-bdff-dc786c1561b4",
                      "2cf68c4e-13b4-44cc-b848-2a8832c5a888",
                      "422e6903-d534-43b6-a57d-f43fc387b5d9",
                      "bda0e082-8cd7-43fd-bb77-853b2205f2bb",
                      "65160db3-f1e3-414b-9cc0-3b3d46026bff",
                      "b5ea47a3-cf71-4f89-ad6d-4d1db81e51ba",
                      "46cf95b9-3bbe-4c7d-80ef-7500d7aaa7af",
                      "961fac51-fbaa-46f5-a53b-b69f18e39dc4",
                      "157346c6-af1e-4e94-b88e-f49b252a1a9b",
                      "8d6dee17-55c1-479f-b9d7-a973ed81c59e",
                      "ae92d991-6d1a-49ec-aa80-32b4c3c2dc63",
                      "2c5bbbd3-b1d2-4c43-bc5f-ffae7367f447",
                      "699b9234-8952-43af-9cfe-1bc89a78d7e4",
                      "d0c90777-95e5-48b7-8c67-611a9b1ba695",
                      "92800d69-1838-4568-802d-b6ae60df503b",
                      "30d26258-cc2e-48de-9efc-3c7c44839e44",
                      "c6ca1a86-6ed2-4dab-b34d-9038c6125fc1",
                      "8953c6d5-ff82-40fc-bbf8-0d0b15f4af3a",
                      "bc378f04-d517-4b7a-974a-c9c45d85e975",
                      "cd7c91c6-58e5-443a-9897-27e4fda0f367",
                      "644005d8-2335-48c0-a23a-664c3783b2a3",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.3 Brennstoff- und energiebezogene Aktivitäten (nicht in Scope 1 oder Scope 2 enthalten)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Gewinnung, Produktion und dem Transport von Brennstoffen und Energie, die nicht in Scope 1 oder Scope 2 erfasst sind.",
                      },
                    },
                    inputParameterIds: [
                      "634a0804-1a33-4d6b-8cf3-74495f8358f9",
                      "1229158b-cfa1-4b25-8de2-ed6cdc3336c0",
                      "cc7e23fe-72a4-40f5-b357-c27193e94db2",
                      "3be525b6-0256-4316-a253-902a14d9231d",
                      "1ec0ab3c-30d1-4237-9abe-e92c09ad177d",
                      "407ebe79-3826-4d29-8493-c512827f8ba8",
                      "68843259-6c26-444f-bf77-ab1f2f7b480a",
                      "d405158f-d2c6-45d2-bc1f-38e5f08fdc84",
                      "744ab353-481e-479c-bd21-96afcf8b4f6f",
                      "8442efb7-9bfe-4fe2-915c-058157b1c8c1",
                      "c0c6c52f-bc8d-4922-83d1-6d7e5b465613",
                      "f3659019-8b34-474f-8f44-4a238a7d2615",
                      "76a14511-015d-4da5-ae34-47212f6f0d59",
                      "3717e626-d3cc-414d-8b66-23796af7a437",
                      "e21e2f53-87b4-4255-b68c-4c484b4af31d",
                      "3feded8f-ea3d-46c8-bf7c-886e26108023",
                      "452f7d64-c0d5-40ae-b42e-e3151ddbef2a",
                      "af4fa2ec-11f5-4538-a7ef-122d1394f751",
                      "29ce1fb9-778b-4d7e-a066-036c269236c7",
                      "da0b2cdf-7585-45ce-a015-42568790a73b",
                      "1ee1cddf-300d-4f0a-a72d-0c6cd2d2d8bb",
                      "1b311961-d9e2-40b3-89bc-3724ea384e57",
                      "b51899ef-042a-49e6-b906-4127ca40e50b",
                      "605a011a-f572-4f0b-ae3e-7216bba2358b",
                      "fce13418-4192-4e54-85bc-7f51f5f3e785",
                      "97132d29-060b-4064-85b3-60e1a1e4796f",
                      "109fdda1-0b17-4663-99a8-606059629b8f",
                      "91f07c9e-7b6c-47af-a1ce-c2148d067a4f",
                      "c325bf94-5a6b-4eca-92fe-003896f52ad1",
                      "bcffaa37-8f06-4d1f-99e8-74c484db1488",
                      "e15ab0be-42df-4e4a-8325-82fff1b06259",
                      "b469690a-3fa4-4bc4-b25f-ee976fc5e786",
                      "21cd4c7d-acee-45cd-9233-f4e86981ef92",
                      "7b360139-1a44-40bc-8a35-54353e6cd959",
                      "79978d4c-efdb-4062-a2ec-5e290717b45c",
                      "473f90bc-d1ec-40cd-8cf8-d4571bcc5975",
                      "5fee1fe9-30c9-43f2-90fb-34550438805f",
                      "937931e4-ca88-4139-97fa-d9bb64e1fd7f",
                      "ef55207e-8bd6-43b7-a735-1eb07ecc6b70",
                      "6a17e494-73b0-4b36-ba6a-107ba42dbde8",
                      "564176c6-1cf3-48dd-aa2e-0e96b90556fd",
                      "c1821bb1-bf54-481e-90a9-54b6abfa79dc",
                      "82e71469-e4d8-4cac-8d48-e43db089fb3a",
                      "1a62340d-8b4e-4bf1-925b-b7a286c8fcc5",
                      "4358bd71-3fd7-4c39-9127-d8b5384bba22",
                      "e59a2f32-d7b4-4647-8d1f-8910a80c7699",
                      "74278c20-d13d-4c18-b463-5cb8ceab85f3",
                      "b93b8845-0df9-4dc3-aea7-6f1db03f61ba",
                      "7a3dd3f1-c7f0-4383-8b1a-b532042742d4",
                      "122bdb0b-6ede-4c9f-b96d-b749fdefc04e",
                      "41c0a344-614f-4964-ad2d-83efb9150c6f",
                      "31b7dd9e-df29-4b54-aac2-5181a7bc1ff9",
                      "38e3d8fb-6827-42bc-8894-cf889ca7b368",
                      "f7dd2117-98ab-46f0-b186-6fd53fb3aa51",
                      "0cf104c0-46e5-4d6e-840c-d3f0739d13f9",
                      "788b93b2-85c9-4c26-affb-300e6466ccdd",
                      "cd817fde-d797-4e41-9971-a97c3e632eb0",
                      "b578bd26-cf9c-4443-b1a8-c3996f936a2e",
                      "94bc6551-aec3-4d30-8b3b-458207286593",
                      "a9a5d6c9-2e25-4528-8919-403ca9ccb26e",
                      "b5ea57a8-4bf3-4843-a57a-360f57df8951",
                      "76017873-c62d-4f35-b441-39312092177c",
                      "8c8add8a-995d-489d-9e56-fe8906fa72b4",
                      "e1ba6446-38d8-4645-a099-ff82c1b33301",
                      "64a1d9bd-9acd-41cb-ab44-205b90bfc744",
                      "9e60f889-6c36-45ef-a8bd-e7eab0c13d19",
                      "7645cda4-3960-44c0-bc2b-cdbcd1c7934d",
                      "e8845e54-e2ec-4150-8283-641bf60934a6",
                      "001ea7e1-aa12-4cbf-97a0-8e2b559d2cfe",
                      "f233b227-b846-45b1-b289-02c027006baf",
                      "0c54c73b-0b57-4d92-93fe-0d7fa3ef389f",
                      "7f8940cf-e198-4e37-82ef-e22d0eeff8df",
                      "963d6bfc-0556-4d61-a22a-660b29fb3043",
                      "1ec50b36-d386-4f34-afb2-2aa6f8a67650",
                      "b8ac02d1-2191-42ff-86eb-f88eee0d8f9c",
                      "eb69cfb0-13dc-4e34-b915-c70a961b7000",
                      "5482d75b-a42c-443b-b538-dc8bf9c5ad86",
                      "dcb2c1c8-da04-4d13-b9e5-6829aa35ae30",
                      "15c18689-6801-45eb-aac8-468c17d8e020",
                      "bc717cf6-3a85-4238-9145-fa1c50dca45c",
                      "f9a02c7e-9f32-408d-8bf4-0050d774fcd2",
                      "9b240865-5047-4056-92a9-f83e3faf2e19",
                      "31dea5a1-6740-45e1-afef-43e1214ab69e",
                      "0143d584-eda9-4360-956f-609b5cdf8ef2",
                      "02aaedff-839d-480f-be7d-7a3a3535556c",
                      "528643c1-d25d-4ad9-ac3d-583cd67e5bd0",
                      "ad266512-c667-43a6-a3b6-dd96982840f1",
                      "9343d72d-656a-4f27-9264-0a423f66cf0f",
                      "68a9ff5b-fb82-4f5b-8cc0-e97c6204a7e7",
                      "7a179d47-71ac-422a-9266-f02518e540f3",
                      "5ab2738b-7437-4edf-8837-334a3790b49b",
                      "c66e2e8d-dcb4-4813-825c-7c34849bc881",
                      "b16b72a3-3005-4c2b-a631-77388d414eac",
                      "09640a8e-1614-4cd6-8234-00eca817a212",
                      "2169b78d-03e5-40bf-847f-fc597979122f",
                      "0445b6ec-57bb-4f41-aaf2-5e2e2bb1e745",
                      "9f15adf7-929e-4ea5-ad24-183f331d2702",
                      "42f15315-ea12-47cf-8682-d43090296d44",
                      "83f4a349-4a80-43ec-8ef4-6504b318ded2",
                      "8c3f02c0-b469-4852-91c1-18807679770a",
                      "2c8ba75a-25f0-44c6-80d2-ff2c92825618",
                      "dbc92ca9-e83f-45db-a5cc-5af98d92bbd1",
                      "3125ff10-9bbd-4f2e-a9b4-a8d18402cd6d",
                      "8d862d2a-5e51-4a24-aa47-e5fd2f9753ac",
                      "fdd96670-9149-4dbd-bade-55c87cf644ee",
                      "7c57916a-af67-46c0-b655-e55002519e37",
                      "b8d8aad6-8fe7-47a8-b8f1-04081ee36516",
                      "fc9e0ebf-a111-4112-973a-854528610f0d",
                      "8adbadc5-3849-4bf1-82e5-72027129c928",
                      "948c8ebd-1b81-4f5f-acad-772f77e2a9c2",
                      "6fdf2cf5-2a10-4fdd-8c38-397b2c9361e7",
                      "28f433d0-5ad7-47f4-bbca-4e94e46f4b14",
                      "196ced27-e5e3-4587-8a20-5cdd2f012d84",
                      "ac81009a-123d-40cd-9296-69c4adf7939b",
                      "075da3b5-f58a-4c18-bd79-4a5398ddeaac",
                      "e5503f93-80f0-4209-93d1-0b16d5206baa",
                      "8425feeb-9c7c-4c1f-b931-f53e15faae94",
                      "192c46b9-f3ef-42c9-9aa8-611b48a7348d",
                      "729ac0fb-12c9-45c9-b898-270a6fb11bf3",
                      "c34650bb-375d-4278-9d60-90f344dc3f6d",
                      "61e5dadc-533f-4917-a1a9-f436bf6e00bf",
                      "a011f3ab-2fe8-409d-9657-1ec25ffdbfd1",
                      "4d4d0658-1b8d-44bc-aff2-4b145e7b1987",
                      "e5c407fc-4b69-4602-8408-52d4b9bd539c",
                      "d55d7c59-6010-4f16-ab1b-2c30b69cf2a3",
                      "c6032892-28a3-4b45-b1bf-08dc089def37",
                      "b6c5abb4-6ddd-4670-bbfc-b0d65a72ffd7",
                      "e35e5428-9440-458a-8ac2-be95ead561c4",
                      "539c425c-5d71-4328-9389-df800a9fafa2",
                      "dc9dab0a-9b5d-43f5-ab53-ff35807e7022",
                      "f4569849-e27a-41c4-a70e-6ab45b98f5b1",
                      "6dbf9d7c-8041-472f-b266-fc3333242042",
                      "a1d6d6b7-43d7-428f-8f12-63385a112a9c",
                      "fb5d17b5-6f66-486d-b46e-08d7aaa4bdd9",
                      "4f59f6cf-89c4-406b-883b-df58b9195755",
                      "45313a8c-97d1-439d-909d-5a70123d559f",
                      "2025ad0c-6075-4ae7-b865-69d3df82541f",
                      "9304b8bb-8ce6-42c7-aea7-81f8ad3493ec",
                      "dba80d05-70f9-400b-a494-b9bcd7b1df3f",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.4 Vorgelagerter Transport (Upstream)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem Transport und der Verteilung von Waren und Dienstleistungen vor dem Verkauf.",
                      },
                    },
                    inputParameterIds: [
                      "45b9d84c-0381-43a9-bd9b-170c2c83a60d",
                      "f67a1f89-961f-40be-8357-5e21543adaa0",
                      "146134d5-1147-44fd-b3ce-d7d0bd6808fd",
                      "a3d85b83-901d-4489-a095-6316e140e981",
                      "1ecf9f1b-d2b8-414e-8f15-0c7e15f66a62",
                      "b78a8222-74c2-472d-b194-342532fa1663",
                      "b4e6d944-7037-4cee-b8b2-b864821d05ba",
                      "be6f9dc7-2ee5-4740-86c9-e3b569adfdd6",
                      "63e591df-f322-40a2-a1f9-5f2be2133c0b",
                      "b9e55714-87d3-434f-a415-7fb755eaf82e",
                      "efedf1a1-66a8-4fc0-a3b0-aa4007851ccd",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.5 Abfall aus dem Betrieb",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Entsorgung und Behandlung von Abfällen, die im Betrieb des Unternehmens anfallen.",
                      },
                    },
                    inputParameterIds: [
                      "a5c56940-83ad-4c65-b963-0ada314fb0a5",
                      "a3edb946-1661-4019-b050-9a9902ef17a6",
                      "0cd4d873-4fb2-49f2-b2b4-affaa0deb222",
                      "966774c6-9645-45c9-82d4-a04bb96d8ab7",
                      "61ba1277-0121-4cd1-8ccd-635edbb41327",
                      "d6a9c766-8627-40e2-882c-fd94b1d2e5a4",
                      "bf88c17b-ad02-4bb4-a0db-a2413655baae",
                      "484b20e5-9bcf-44d8-a059-8156d1fe7b6f",
                      "f556ac4d-205e-4c02-b587-24e5e2278d29",
                      "a4182fb5-362e-4f45-9ba8-2013f53118d3",
                      "6a3cc2dc-5f23-46bf-9d02-ed968b54d444",
                      "a46a752d-7cc5-4429-a50f-18d9581fefc1",
                      "fc4b316f-ca33-419d-8883-8de3edf86f21",
                      "f7d1ff15-1e7c-4f2c-97b1-b626945342cd",
                      "1fce1ff2-1f61-4a7d-a438-bd316aad7430",
                      "8a536d34-517d-46ca-a6fe-c1b89b06afc8",
                      "fb8d6d73-0082-42a3-832e-d7007596f3b9",
                      "cf59b521-47a3-4142-ba23-9f31173f4810",
                      "41edc1fe-ea40-429a-9aef-372a88266fea",
                      "95faa3db-947e-457e-a2e0-2b46bb677a89",
                      "d50caa9d-1aa9-4791-b55f-c8c6d9bd10d1",
                      "202adf84-c9ba-49d3-be0e-f7f2bb12f424",
                      "a1daaa9d-f30a-40ad-8615-13eea7aa677d",
                      "3b6a8388-fc4c-4651-8397-6201c4a4dcfd",
                      "33bea9a9-0465-4ef5-b9f7-279b72bcc89a",
                      "8173b0d5-24c5-4e9a-a0da-d8d7df9529ab",
                      "18fce9f4-5015-4d00-a6ac-a805e740c152",
                      "da880031-3b28-4d08-b7be-647ec54971fe",
                      "91267d49-08c5-4fa3-9a86-0ed905788bd6",
                      "34498a31-4e06-44c3-9644-3b6219622914",
                      "a5127b94-2ee3-4220-9c7c-260f0e4754ae",
                      "5805526e-25c2-47da-920d-49ce734ba97e",
                      "5fb31e7d-afa1-425d-b1d4-0de29c1bf2cf",
                      "7fc14318-40c6-46c3-976e-e78f99f1093f",
                      "870e84b7-d07e-4b2f-b955-8d4dcfffc1ca",
                      "aadc0f86-3fcd-49cb-b19b-fbf32e8b5223",
                      "fd781bbc-03f9-4f03-bd9e-5cf71f4f71fa",
                      "3f6ab5e8-3e84-4dfd-a02c-c10114610e3c",
                      "7ae459bc-2fb7-4fac-99bc-ab142ca0011c",
                      "0d81bc09-369d-4ba2-b734-d000ede5581c",
                      "c8b8de0e-78d2-411f-a3d4-ec619e6897b8",
                      "e8723ce8-0c6a-406d-ba2f-e6d683b9b6f4",
                      "5f910672-b872-4cf8-a2fc-a4654b544128",
                      "e2465f57-45db-4fc7-87d7-f3dd91359361",
                      "c7800128-bf53-4e93-b9e1-1fc26fae47f8",
                      "16ab11b6-ee1f-4d6c-810b-1017773c261c",
                      "1f93a1bf-7c5d-402a-9df2-4f1ad3075341",
                      "a3bfc646-a06a-45c9-8099-a1a51015b521",
                      "19235ce8-38c1-47ea-93f1-fffabb03fa08",
                      "95eb6753-599c-40ec-9a19-177eb1462f62",
                      "9db376b3-65a1-4140-8144-8bdaa9357a0e",
                      "3b3746b5-b166-4a49-ae13-e50666385b82",
                      "c082433a-c633-4476-b679-1c93e44fefd5",
                      "f06fd15b-ab16-48b9-bca6-dd394458e98f",
                      "9300f890-39fb-4904-af2f-b7ea8473d4f8",
                      "34c0b0e5-0908-437b-b83e-dfa3e8f4949e",
                      "00e8b6c5-df40-4231-9663-3cc81ad5acad",
                      "45b1ef75-22eb-4a49-aefb-e3d875023287",
                      "07eb675a-09f4-4d6c-83c7-606829cf0e21",
                      "2628981e-5692-4f18-8c6f-8168073bd60e",
                      "ace43fdd-c556-4517-b180-58c14dd498cd",
                      "c1d6365a-86e5-400f-8c7a-0c0a37fd4ec8",
                      "a3426714-a80c-4d32-a418-d3cadf3cada1",
                      "981c8bbe-3b0f-4c0e-82e0-89114386ee78",
                      "87f6bb0e-192e-4f0b-956e-c985595eaf71",
                      "d9f87ac8-30a5-4911-af1a-7fdfef848966",
                      "11f7afbe-4622-4333-8057-d1acdd3026ca",
                      "5a7af634-c682-4122-b5aa-5b8fa396cec1",
                      "bf9e7b74-ccd7-4fe1-a628-5223d375a372",
                      "0923d51d-b2b3-4f73-9d3a-397ef99ac475",
                      "ed7487cb-01e0-489e-a076-4e9251ab183b",
                      "dbf88509-b29b-4f7c-9a83-c5e0354fa06f",
                      "6d218d18-1e37-4174-8b46-727ff4014d7b",
                      "070d4af5-3fb8-403b-8dc7-3dba4de1deb5",
                      "660204a9-eb43-4ab2-bba8-442f7b170227",
                      "fd714df2-402f-4de8-aa4a-c1dc72504846",
                      "24d41bb1-1cc5-4daf-8d65-1bbf2307e5a9",
                      "90a38c50-4b66-48e7-9d8e-bb6003de3bca",
                      "6469feac-5ca6-4209-a381-797dc998d421",
                      "5ae76770-2308-439d-a106-6363f38328e3",
                      "301f354a-f2ef-453d-ba33-44dbc756e540",
                      "addf7dc0-c8ae-4b5d-b7f0-22ea932bc172",
                      "646aff49-c7bb-4f82-a833-9f4ae9f084d2",
                      "f02e511f-1657-45da-aaa9-57e4fde85526",
                      "7fc648a9-1211-4b46-b6fd-f585468198eb",
                      "77d42ab4-1b83-421f-acbc-abdf6ed35520",
                      "d12e65a0-447c-4d5c-83a3-fd8e214287a1",
                      "b3338b57-f574-46f5-bc19-3048210658b9",
                      "8b84a70b-3dbd-4a83-bc53-4164a5714839",
                      "22f41d97-f83a-44e0-87d5-b3ec7d147b14",
                      "3e87e16e-a423-47a5-8063-566be6667e61",
                      "58abeab3-17e3-448a-bf16-de556cffac72",
                      "6998246f-01c7-4b18-b87c-b8d5fb546205",
                      "9788fc18-fb6d-4f14-8ad3-dc124a635569",
                      "d6e0c9fe-feda-4b80-88a7-b74cea7053c5",
                      "351b56e8-9106-40cb-b407-324eaa292e09",
                      "2d1e18a5-2ddb-4944-a99a-11b3705da0bb",
                      "a387c1c8-af6d-4380-8933-1c08c1207dbd",
                      "8ff721fa-41d9-4a94-8aea-3c9c073f2e84",
                      "10667bb4-82e2-4db1-b354-7bde96727bc6",
                      "2c30de21-c893-49bb-9bd6-965c6aeb98be",
                      "89bf7aae-217c-4be8-ae03-1bfa4f3a7a0d",
                      "097168d2-116a-4352-88dc-89c1cbc52c61",
                      "1f67bb38-726d-4c36-9e26-83189a0e150b",
                      "a7df468b-c77b-4668-b6fb-51d536fcb8ff",
                      "1e0bad78-3973-4d49-b4c6-9b33f786e741",
                      "c8a3e500-6ea4-416a-8579-37e6ba252548",
                      "1bb25cde-46ef-4d06-bad5-9d3b5964191a",
                      "eac57fc3-d1d0-4588-8af1-b8998c838f01",
                      "0533bb29-0677-4c33-b58d-8f78c17519da",
                      "c1262435-0dc4-47e5-a249-c8469c1a9ba9",
                      "89385b7a-d6b0-4b96-ac7e-4a59f8a80da5",
                      "e0560a98-3a80-406d-9eb0-97ef28ec33a1",
                      "467c1402-8d57-4761-85bb-d86111845794",
                      "3d308827-b5a1-4fae-bb38-5fec2c4266fc",
                      "1e799533-f97c-408d-8621-cfc7412d416e",
                      "518f724f-b9f8-47b0-814e-7e70e14fd52c",
                      "334c0f7b-e99c-4820-85e7-e973881b30bf",
                      "c025b085-21d7-4132-a50a-06be30964cf9",
                      "c14ae066-eefb-4146-9582-113302a2c795",
                      "abeccd5c-4747-4f2b-bd33-efcc66d1501d",
                      "6ba0b295-6bd5-459c-ace7-a7677ecb93f1",
                      "f1b542b2-2032-4d74-b582-e68cde05e4b5",
                      "b985257e-4833-471d-80c6-3db92ec072df",
                      "06d9b417-b09f-4c30-8ab2-4b5f1e20233a",
                      "0b953496-9063-4600-b6a8-d343dc7d7ac3",
                      "0581d0d6-deb0-421e-8d06-4bd2ddf0cef6",
                      "22c32c2b-6e49-4a5b-888e-d876cf02474a",
                      "4757970f-ec2a-4d6d-b32a-f01b3fa6aa0c",
                      "2c7ec53f-5bcd-42ad-8428-7225ece5bbb6",
                      "71d68fde-9e2b-479a-bc3b-6a2f852d045a",
                      "22643cfb-bbf1-46cc-86b2-c62e4e932fff",
                      "23cd7eb4-e632-4392-b386-df542cf0ef7a",
                      "6226f7c2-0847-44cf-bea7-d2ca2443c2b2",
                      "547fffa8-848c-4781-b4a2-7c7f6b8d2455",
                      "aa9e69fb-53c1-4014-8c55-ba9c1decb841",
                      "d2ab5955-9442-4e17-819e-00982cfe7f69",
                      "a17b26c3-ea52-4dff-8010-7bad14555a72",
                      "daefb693-7f6b-4a13-a36a-e7a3603a844c",
                      "1b1dd286-098f-4c79-9e70-2e89e0f77d6e",
                      "c061d881-8dd4-4c43-a600-9897a28bf833",
                      "590059e7-9abf-4adf-848f-43b6a76f00cb",
                      "48bd2030-2b57-4401-af0e-73480b7091ee",
                      "b37181f3-97a8-4560-b8af-0e9b3d084653",
                      "3437a522-dc35-4fe3-8d42-ffb73450f59d",
                      "4e49d694-14a5-411b-901f-e849bdaae241",
                      "d893f714-dff3-4397-89b1-85b09504a777",
                      "9e0230ff-1144-4c95-b340-7713f085d449",
                      "e07ec658-c0e8-4e67-aa4b-6e7d8547c722",
                      "e1eab662-ca55-4535-9d5e-c9a3b6dd206e",
                      "9a7bf282-ec7d-4ef7-9afb-d3e10110aa5a",
                      "6bf67cd1-a42b-4f17-bc98-d16c28201c33",
                      "7bd3f65d-f346-4264-a85b-d398393c2797",
                      "13df5cf3-ff8b-45ce-baf3-f1160e458da4",
                      "73787f52-f6c3-4a99-95d3-fefab15e6882",
                      "6baf5743-7da7-4029-8955-cbfee174021a",
                      "f69ad3a4-6489-45b1-bea7-7b5f8de3241a",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.6 Geschäftsreisen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Reisen, die von Mitarbeitern des Unternehmens für geschäftliche Zwecke unternommen werden.",
                      },
                    },
                    inputParameterIds: [
                      "b30e0476-9a5c-40c5-bc28-d0c931507cbd",
                      "800b8583-7d23-4abd-b6fd-7c97fdb71284",
                      "8b7257d0-c452-4fc4-9020-908db2be22ff",
                      "4aa99499-3a64-45d9-8d2f-f9764f9400d7",
                      "b4be9b1d-da79-4c26-a858-7d709e63c1d1",
                      "e0dd44c1-856a-466f-aca5-e5f5810b43d2",
                      "12534683-9272-41c9-ab10-0d2705f123fc",
                      "d1b73334-6e24-4dd9-8e2c-3c92c0d82336",
                      "2c0fc784-e28a-4574-8383-5ac9ff5bbf76",
                      "fdf69951-b9f4-4504-8c1a-e9bdec1ad8e9",
                      "7218fdc5-7def-4177-a772-20d8781fcbf8",
                      "b46fe708-b713-474c-8f45-5b168419a404",
                      "1949aaa0-c64d-4f84-96f9-b36b003d07ab",
                      "c25a43cc-79f1-400b-9686-fc024dfca020",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.7 Mitarbeiterpendeln",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem täglichen Pendeln der Mitarbeiter zum und vom Arbeitsplatz.",
                      },
                    },
                    inputParameterIds: [
                      "e85e89c2-cfec-417f-a417-883125761e7b",
                      "51edffdb-dd55-490a-8632-15708d675c93",
                      "0e5b3157-69d0-429f-b230-3ed366c70bfd",
                      "8c7825b7-cdf7-449f-9c1f-25338f37a9de",
                      "dac5fd04-8ab2-4606-9893-ff11be5c68fa",
                      "70020f8a-f866-4f10-804f-66abbe1f8122",
                      "e299132c-ddc2-4827-84a1-18ae7110d8db",
                      "a679dab4-d91a-4e41-8290-212284cb1c81",
                      "c16da327-9c6c-48e1-8970-1ee6c29c14b2",
                      "eb009a94-74be-4de0-9841-6ae4cf64a903",
                      "f1b11055-eef7-43e6-b749-ac9f7e619306",
                      "942eb06d-b223-45f3-af08-733d55d27f1f",
                      "09626126-0537-4c4f-ad2f-8a499012d5e3",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.8 Upstream gepachtete Vermögenswerte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Vermögenswerten, die vom Unternehmen gepachtet werden, aber nicht im Besitz des Unternehmens sind.",
                      },
                    },
                    inputParameterIds: [
                      "e0656e99-1025-4fb7-ade6-ced97b95b96d",
                      "f8b15605-f6fa-4499-8853-3f06295ed43c",
                      "cb83b266-0642-4798-a343-99d92f581b96",
                      "25f477d5-bc58-477f-ae10-41644841d720",
                      "201da021-273d-41fd-ae4b-57c7d1110eb5",
                      "afc97af2-67c9-4c1d-8bd9-660341e2683d",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.9 Nachgelagerter Transport (Downstream)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem Transport und der Verteilung von Produkten nach dem Verkauf.",
                      },
                    },
                    inputParameterIds: [
                      "f2627ec9-3141-47ba-ad6a-d65882d1955c",
                      "201aae7e-86e9-4085-8cb3-d4eb325da38e",
                      "01c1107e-6ca7-4b86-941c-211ecef35dd4",
                      "5f64e806-9456-4b66-94d3-4556ae511be2",
                      "b17f032c-64d0-4b1b-a11f-4549ce72cf6c",
                      "4348cb79-47e6-437f-8c70-a3d30392e687",
                      "517a9a0c-958e-4508-ac2e-2c436ff03594",
                      "3ea03200-edcf-473c-9d34-5252a727b3b5",
                      "ffa2426e-41fb-4efb-b4e2-3a63a6792b7f",
                      "39f8efbc-8ab9-4bda-bdf4-be861f0a0049",
                      "ae54b9e4-0970-4290-951d-8b9616bddc5e",
                      "3499abfd-cafa-4e2e-b897-07d6f4e03065",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.10 Verarbeitung verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Weiterverarbeitung der vom Unternehmen verkauften Produkte.",
                      },
                    },
                    inputParameterIds: ["ddbc5e7d-d905-4af3-81ab-a55ce01ba9e8"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.11 Nutzung verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die bei der Nutzung der verkauften Produkte durch Endverbraucher entstehen.",
                      },
                    },
                    inputParameterIds: ["55518846-f8f1-4506-a4bb-d318d84b88a0"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.12 End of Life verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Entsorgung und Behandlung der verkauften Produkte, wenn diese am Ende ihrer Lebensdauer sind.",
                      },
                    },
                    inputParameterIds: [
                      "c01ead89-f510-43a3-9d47-280d5e64a2d3",
                      "84a7d3ba-1689-4fa3-9551-3e664a66a6cd",
                      "91358b1b-168f-46d7-9bd1-87b1505a4551",
                      "e7915b5b-2bab-4ee2-a98c-9fe714e5419b",
                      "f4e98067-a9ad-4c50-80c8-942006203d2f",
                      "354020ef-ccc3-41dc-a52b-95dac5044b8d",
                      "6ad6758e-e566-4992-be1c-0b213c3b5017",
                      "524d489b-f5c0-415b-9e78-5493870465c7",
                      "9584a436-0a05-494a-a576-6d330c54f21a",
                      "4ac56869-3469-40dd-b591-f66fb2193d2e",
                      "925adb3f-d51d-4867-90e5-46e3cde8c90f",
                      "4669c0cf-fd6e-482d-a30c-036ef228365d",
                      "f451e722-7026-40c7-83e4-6359139bf148",
                      "bad3c0de-65c9-43b5-9077-4f52555f68e9",
                      "51de1dfc-9e45-47ee-9cf9-b1a40faf6acf",
                      "21dac60b-6489-464c-87c7-3de954554d3d",
                      "87088fe8-01f4-4795-92be-147bc580b690",
                      "09c31886-9c49-4f2c-875c-232a195c157f",
                      "0774cd63-cbb3-4ff9-bbd8-9db22c938e25",
                      "ff1fbf9e-ff7c-45cf-8c77-0bea75edb1c4",
                      "56f2c7cb-8e8e-47a9-adef-2cf795c72aa7",
                      "bc9d210c-38ff-420e-a6d6-0616f1766fb2",
                      "825e952b-c6d8-475c-8732-023ab68248c0",
                      "3a8cc51e-fb7f-4149-8f67-4c6ccc389472",
                      "fae9f097-eac5-4958-b0fe-112f3ad38df7",
                      "0b7a4be1-7231-4565-952e-22870401cccf",
                      "dade104e-c416-419b-b2c6-680653561617",
                      "6c97bd11-c24c-46b2-a208-965ed752b71b",
                      "944775cf-48af-4e83-a4c4-e35e6909cdc6",
                      "52e859e4-1684-439b-815c-adce86c4afc7",
                      "6e85f383-9003-433c-8b6d-778bdacb5963",
                      "9c6c60f3-7efc-449a-a70c-6c2afe8f2ac8",
                      "7b85c369-b4a0-4c6e-9176-8938f1ff4750",
                      "267afcfd-e27b-4b8b-9c9a-61ea8cf59431",
                      "bcd61a9d-1f4c-492d-b57e-6f35d07cfb37",
                      "4d2a230c-29d6-4b7e-ac59-21264db9b294",
                      "a527a0c2-10be-414d-b6b6-ac728f48e174",
                      "94633433-8c18-4a6c-97bf-cbf17b9edf2c",
                      "f59606d3-58ef-4511-a015-21fa44464c54",
                      "1ac4f9f0-16ff-419c-bb75-a409a139b2d6",
                      "00e43fe7-a207-4504-b8cd-7f5e5f40b676",
                      "01288207-41b2-4032-a442-8d0bddd1fc83",
                      "d1cffc44-08fd-4e38-9639-7c33a0f6eba4",
                      "ef79f480-8463-42a4-a7b7-85428fd8aee9",
                      "7bad38b5-23c9-4706-a664-27d4c31e2c62",
                      "e687a99e-d648-4ff9-aac0-c8f14ee6ce38",
                      "8aaf55b7-c73b-4260-9573-01c1e6a61287",
                      "21788702-9089-45ca-82c7-1dd153f349ed",
                      "50f5f0c8-3928-4edc-8110-2177f643967a",
                      "403601ce-e060-4e1f-9eea-f7a4e5b41f75",
                      "f6212e0b-7164-414d-8d3d-1abe4781b4a8",
                      "30b8097e-fb0e-48c3-9027-b09dd50c0cdb",
                      "36e3771b-7f83-40f7-8e32-4d997ec4bfe8",
                      "4e7be62b-956a-4d27-9fb4-1cf66ce48be4",
                      "fe13c181-52f1-46a1-a9c5-543415a507f0",
                      "498f346d-bc1f-4081-975e-c0586dabaf6c",
                      "695a36bd-fd25-4c51-86a4-d1842b73fac5",
                      "50d44533-e623-4e5a-9bad-52c74422d651",
                      "1fb1a6b2-736c-45de-a155-2fd0da43e7a6",
                      "d915113b-6a04-46af-baec-269719e60c23",
                      "2bed3864-3d6e-431c-82f6-f6d69f373540",
                      "399e1da6-38e8-4882-87e1-132cc1b83cd7",
                      "c5d4d01c-5146-4d35-b681-a460a8512272",
                      "92b30660-ec13-428f-9a3a-037c29602e2d",
                      "b68292a5-e16b-46b0-a772-8ab14dc27a7a",
                      "4525050f-9c8c-4b0c-bb9f-b6d34cb9ec2c",
                      "f3e40e5d-25e0-4713-8d2d-6158599491f5",
                      "5d281fbc-3c6d-485f-b2f4-b45d966588ae",
                      "b07baa20-7770-42d4-b1d0-6f0dacd552f4",
                      "b8826064-c848-4ebf-a397-80908a462546",
                      "62a667f8-b1cf-40e8-a772-1195c1261285",
                      "f5cbd6e0-fda8-4ccf-8ef0-5eb7c75cee71",
                      "52676979-4b5e-4eda-92b6-1a38f1f67734",
                      "5212c8d0-0512-4cde-a4f1-b47fd4c4ccc7",
                      "a6732932-6d16-4b1b-b8bf-a572b3afd063",
                      "d72c5df3-ff2d-4c9a-a86e-53902fa8bfa4",
                      "b82287e4-8460-480a-9586-1fecd5ed6fe6",
                      "b8f6644b-b49c-4c97-8ac0-bd7b5e529399",
                      "38fd79f6-abfe-47a6-b7b3-d62cd033254e",
                      "18aaee7d-a555-42de-b7ea-f80309a13a95",
                      "8a2f5504-5c55-4309-9791-84bad54b21e7",
                      "79f81b8e-dd96-4a71-a787-97907e1cfd1e",
                      "9c1c862e-39b8-46e0-a1d1-c13c77e857e1",
                      "0cf60327-3a9a-48ef-a818-2b1566cb4119",
                      "3d847863-bfd6-40aa-a034-73fb8f357812",
                      "5c52ebaf-88b0-4ad3-bc2a-d0c0fd408db4",
                      "fea8a9ed-e227-4f3d-8eb5-e89174749bc4",
                      "8eddad2d-556c-427d-98a7-b56e10f28dc4",
                      "444b9cdf-1deb-4001-8fc6-913afb54960f",
                      "fed64046-4303-4529-8d83-cc0dcc180723",
                      "88a5a420-1c0b-4020-8f77-4c8a1546b376",
                      "fbb63eb9-06de-4c4f-abe1-ad0d1c0c06dc",
                      "3bd57b70-c3dc-4236-b482-03b54c522c91",
                      "8740f1c5-66c6-4dad-be33-a98e573563ba",
                      "67c566e1-e6d9-41bc-aab6-92f5a89e1de0",
                      "dcc938e1-1f8c-4ec1-ae77-d7e1eda61e82",
                      "7e6f0984-e5ff-4e23-9683-8218d61a09e9",
                      "74f438f4-3ad9-4f36-8e66-fca840f12274",
                      "3231ecde-0d64-43cd-92b1-2be3711353a9",
                      "121a0862-cc53-40e5-9872-5f8aff4d085d",
                      "957af064-6b4f-4dce-a54b-7a2fc663ae96",
                      "e516226d-3748-442a-bca8-491c067e7ef8",
                      "a58fd6c6-d392-4ac1-9a6d-1b34833bc6bd",
                      "a8f8d4c7-5498-4484-8cb9-3f4075fe99a8",
                      "44425866-97bb-4a00-8ff5-fa8603b190a4",
                      "d3807a4a-3151-4d1c-ad3d-6b3f387433ee",
                      "b895c6b0-00db-4e92-9f7b-d994054c8b6a",
                      "01406b80-ff69-4d46-9fdb-a768832c3699",
                      "ab459d9d-e48b-4cc9-b5a7-b9ced180f8df",
                      "802a87af-f6f8-4e06-8c5f-8364d5b20f23",
                      "c301cc96-02fc-43b1-8af0-1842d1871213",
                      "b70bed33-932b-42bb-818e-5123225e9b35",
                      "3ee3a45b-9339-4c06-b208-8307f84acd89",
                      "4ee56fae-827d-41df-9551-de50c7073872",
                      "6b9166c0-6412-406d-b4e7-5e7ed9dcf49e",
                      "a7392ef2-7f4c-4999-9fb6-ab39fccd8a81",
                      "88977430-e5a8-467f-9e85-a0b84ae7b54a",
                      "c27893df-7410-4e85-a3e1-3855b05e590f",
                      "891d2f12-5557-4b83-986e-5b27a94dba01",
                      "a80607d2-ce53-4e81-b157-d4a9e06d8585",
                      "2e55079c-5110-450f-9080-6a69c1a7294a",
                      "f7a01d9f-153f-44c5-a192-f9e4ebd155fe",
                      "6b2b30e2-210f-4f4f-9885-5d44e6d91de0",
                      "aac5e40a-adf4-4338-ba74-3a0fc1bc0e49",
                      "33b56409-2737-4110-8323-4bd49fe0bedb",
                      "2e288d77-7118-4733-90bf-a5f0bf8f74ba",
                      "0f446de6-79fd-476e-ae46-f38d80e9eb10",
                      "0ce6536e-8d64-43ea-8175-3334ddb39259",
                      "7c1265ca-d7c7-4921-a366-950641f639ef",
                      "47273952-6c1f-4bdd-88e6-7e64a202198b",
                      "edb36d67-ae84-41ff-8f50-090ed36d9c1d",
                      "88958589-47fd-427b-88d0-622a50d1e679",
                      "3c1a0162-c711-48cf-8a29-17f686d38095",
                      "d38a2c82-a437-4532-a4f2-eac8e844f9d6",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.13 Downstream gepachtete Vermögenswerte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Vermögenswerten, die vom Unternehmen an Dritte verpachtet werden.",
                      },
                    },
                    inputParameterIds: [
                      "e093af51-905f-4871-82f0-1f8e9fac0266",
                      "9c2ead4e-86be-4f45-9f1d-5424da2a5ad6",
                      "4defbeca-4a02-4946-83e3-ae9fff06884a",
                      "b7a2acbc-03f6-4804-a17b-bab912cafb60",
                      "4ef27c19-fba1-4485-a36f-1b1d01a8c5a9",
                      "b8b6373f-8979-4690-ac07-31981cfc0ec4",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.14 Franchises",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus den Aktivitäten von Franchise-Unternehmen, die unter dem Markennamen des Unternehmens operieren.",
                      },
                    },
                    inputParameterIds: ["22a6dcda-f799-465e-929d-ea69e57ac706"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.15 Investitionen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus den Investitionen des Unternehmens in andere Unternehmen oder Projekte.",
                      },
                    },
                    inputParameterIds: [
                      "b8ca13d2-1a66-4f4f-ad5a-211e69ab5625",
                      "a352e9ca-aefb-4623-81d4-0c86a011c2b6",
                      "06c78786-4436-411d-801e-67ff0c0d1e7b",
                      "2c78edf5-3919-4e02-91e8-42ac8e193bd9",
                    ],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Überprüfen Sie im ersten Schritt, welche Scope-3-Kategorien für Sie relevant sind, anhand folgender Kriterien: Größe: Sie tragen erheblich zu den erwarteten Scope-3-Emissionen des Unternehmens bei (siehe Abschnitt 7.1 für Hinweise zur Verwendung von Schätzmethoden). Einfluss: Es gibt mögliche Emissionsreduktionen, die vom Unternehmen durchgeführt oder beeinflusst werden können (siehe Kasten 6.2). Risiko: Sie tragen zur Risikobelastung des Unternehmens bei (z. B. klimawandelbezogene Risiken wie finanzielle, regulatorische, Lieferketten-, Produkt- und Kundenrisiken, Rechtsstreitigkeiten und Reputationsrisiken) (siehe Tabelle 2.2). Stakeholder: Sie werden von wichtigen Stakeholdern als kritisch angesehen (z. B. Kunden, Lieferanten, Investoren oder die Zivilgesellschaft). Outsourcing: Es handelt sich um ausgelagerte Aktivitäten, die zuvor intern durchgeführt wurden, oder um Aktivitäten, die vom berichtenden Unternehmen ausgelagert wurden und die typischerweise intern von anderen Unternehmen in der Branche des berichtenden Unternehmens durchgeführt werden. Branchenspezifische Leitlinien: Sie wurden in branchenspezifischen Leitlinien als bedeutend identifiziert. Sonstiges: Sie erfüllen zusätzliche vom Unternehmen oder der Branche entwickelte Kriterien zur Bestimmung der Relevanz.",
                  },
                },
                inputParameterIds: [],
              },
            ],
            description: {
              defaultLanguage: "de",
              translationsPerLanguage: {
                de: "Greenhouse Gas Protocol Standard Template",
              },
            },
            inputParameterIds: [],
          },
        },
      },
    ],
  },
  {
    id: "e0183d80-8928-487d-b23f-cac434eb149b",
    name: "Geschäftsjahr 2024",
    startDate: "2023-12-31T23:00:00.000Z",
    endDate: "2024-12-31T22:59:59.999Z",
    recordingStructures: [
      {
        id: "f4115e36-9a53-4d7e-a400-6bef891e2e89",
        name: {
          defaultLanguage: "de",
          translationsPerLanguage: {
            de: "GHG Protocol",
          },
        },
        description: {
          defaultLanguage: "de",
          translationsPerLanguage: {
            de: "Greenhouse Gas Protocol",
          },
        },
        categoryIdentifier: "greenhouse_gas_emission_ghg_protocol",
        icon: "mdi:molecule-co2",
        evaluations: ["emissions"],
        recordingModelId: "2d332330-536b-40f3-bd87-73c6d1c6e7e6",
        recordingModelOrder: 3,
        templateUsedId: "f5f0af36-bbbe-4c52-b3d7-1e69bcd71ea6",
        structure: {
          type: "thg",
          rootSustainabilityCategory: {
            name: {
              defaultLanguage: "de",
              translationsPerLanguage: {
                de: "GHG Protocol",
              },
            },
            children: [
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 1: Direkte Emissionen",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Stationäre Verbrennung",
                      },
                    },
                    children: [
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Fossile Brennstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "963ac837-a1f4-427a-8cc8-674283a67597",
                          "0c66edb4-773d-4cf0-81be-0a09f23abc79",
                          "04278cda-f48f-49cc-9c05-e6e0fad929ba",
                          "a86faf89-1664-4607-8b8e-a20a6deef5a3",
                          "9603c431-61b8-4758-bc10-781c9fd9a541",
                          "40a86ce1-990c-4aa9-8e2c-60b0ffc478c2",
                          "e3c2797d-b591-49e3-b3e8-84d94647f01e",
                          "877c756b-bc95-406e-90c6-40b0b7537dfa",
                          "d71f519b-5526-4ef5-a4a8-e81ff178aeef",
                          "64b3281d-2c8b-4c0a-a9f4-21ef18c819ee",
                          "8974fd6b-f692-4748-be17-d8dfadb07627",
                          "16c0c9f4-58fc-482e-91e2-7565cd151ddd",
                          "dc3a9fa7-a708-49eb-a40a-f46ed3ecb935",
                          "0b827966-8609-4e36-ba06-119d0274e5fb",
                          "3057c8e4-072c-4723-80f2-0e9c3a10188f",
                          "918bb55c-5e22-46f9-8db3-4e87a74d019f",
                          "df22bfbb-b5fc-4dcf-a290-541d5a665184",
                          "89621629-e887-4d21-bdd2-0465d0c0c4f6",
                          "82042809-bfda-46b7-93f5-e90db219cd34",
                          "a5cf9f96-b837-4400-87bc-b21f60ce983d",
                          "43803b02-7ccb-41ad-93ef-b675544f1225",
                          "54ca7d7d-74bc-400b-805f-7c93a7ef6ae5",
                          "bc4868ed-f4fc-4c2a-a3f7-fe4a23e9f9bd",
                          "edf846af-b9d2-4ee0-91b7-2aee2a7731b1",
                          "7ca6ad70-610e-4348-a870-e1c8e96a4016",
                          "74f17ea9-a921-407d-bf24-731a0d24c29d",
                          "84818f5b-4bc7-4811-9f16-211009c3d814",
                          "91162400-1a13-4925-92ce-de68905e1e9f",
                          "bb5f8c63-aed6-47b6-b155-c85d8db87eef",
                          "a427aaf3-2afa-41c3-bbe1-e3dec0441967",
                          "bf853edf-69e7-4009-a459-7e414c68fe3b",
                          "c3bf9c35-f100-4230-82ff-bae1ef9d2950",
                          "60ad8eff-e80d-428c-b1fa-bc5cc2190043",
                          "47e626f1-05df-40ce-bbec-b66b458b0855",
                          "c1fc97c3-ac90-45d7-8f27-2ded7b70e029",
                          "07b1568f-1114-4d34-b68b-f7c6f149f410",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Biogene Brennstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "1f95656c-ed0d-4ab5-a04f-f4ed31d34a72",
                          "0036c983-6efd-4c15-bb20-4240d5f2adc9",
                          "495e39c7-d98a-4158-9c23-bb66d86979b0",
                          "487f09e3-7709-40df-af9f-8e28c0252487",
                          "35075999-3e45-464c-bea1-1f2d3ebf94bc",
                          "c3bcaf16-99d9-4b0b-a20c-09f8f73db104",
                          "93ac4cf1-5bd9-4c66-8f30-7b3fe4fb5427",
                          "b3ce26f9-ab46-4c5c-ae74-0d420aad6032",
                          "20c84611-e3ba-4fc5-8331-a7cf93f0ea43",
                          "a11745b7-463c-459b-95a2-36b31a23c891",
                          "5605efa1-b12d-4fdb-8b79-f82eb7b763ed",
                          "73a9d36c-686e-4331-9aec-b8667d302e39",
                          "4372986a-d9b4-4f9c-94d6-00f0022505f1",
                          "ddea1217-01ac-42ed-8a4c-0f5b4378e5a4",
                          "ab519de2-5226-4cdf-bcdd-54062f8b8a8b",
                          "539395fe-022a-40bb-a0dc-33651a39abcb",
                          "987f4184-a6ff-4664-8556-0ccd1f4fb732",
                          "cf716fcc-d80e-4786-aeb3-a5f1df10b5fb",
                          "b54583d2-ac5b-4805-a493-bc687b1baf7a",
                          "28ed291d-18bd-4538-b408-c51895e803a1",
                          "669dc668-1dd9-438f-8606-0229ba06cb70",
                          "e03f3877-1e6a-45d2-b3e2-381d00f7280e",
                          "c7c91256-f325-4a49-90a5-241e8186e006",
                          "3d53938e-1fbe-4504-a3cc-8e2494a40db1",
                          "012b5657-e91a-4429-bcaa-384501e6f1e4",
                        ],
                      },
                    ],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Verbrennung in stationären Anlagen wie Heizkesseln, Öfen und Turbinen",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Mobile Verbrennung",
                      },
                    },
                    children: [
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Personenfahrzeuge",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "978307f4-4603-4deb-aa5e-f8361eee39ed",
                          "7a652db3-ab66-498c-97a9-1e7c2bdba9a8",
                          "792f9fb3-f90c-4248-a694-47369cd8fffc",
                          "d11bad42-2de0-4e00-94f5-3583c996b6bf",
                          "859becfe-7a1b-4dcd-8687-d4d71e211434",
                          "572cf040-5e4d-4c92-b596-51b322e97473",
                          "b9a53bda-f2e2-4b4e-baa4-8c70a9b373e9",
                          "23925f22-d3b0-4f06-950b-21444a7da1df",
                          "a1e342b4-1bca-4895-8783-509350ecab5e",
                          "16df488e-6e5e-4c4a-9bf0-b91d11c11e87",
                          "bbf1d8c7-ff12-44c3-a48b-025e312f5e17",
                          "4b068b06-5058-46b8-85a2-ba62c71f4d45",
                          "35c3085a-819e-43ee-b51d-d102d04a5477",
                          "81ac9535-93a5-438b-82ad-f37e707cdb27",
                          "de2587da-d684-4046-ab09-162d72c3a0e7",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Transportfahrzeuge",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "4b10024d-f75d-44d4-b196-f819ed174f6e",
                          "a0e71fc0-7345-4bdc-8c8a-32d340239d79",
                          "5c11f8e4-77ca-4973-92d6-dd2402c7a89d",
                          "46e26ea6-405e-4020-9c2c-2e95adce5b68",
                          "38a3029a-2f4c-4f53-ac99-bea12c76fe50",
                          "03dfe860-dbc5-4c79-ba7a-bb35ff5fb58b",
                          "4b96f2ce-ca75-4d4e-ad47-52a51b82bb06",
                          "079e3bee-b30b-4c76-8ca4-1a9b53016e6f",
                          "226eb42b-862b-454e-b4b2-696e7cb7e00f",
                          "94017694-c1dd-4935-bf88-a9d527bc1964",
                          "5fc4179c-03cf-44c9-88b5-3c7ad0c5f5da",
                          "eca02d4a-de5d-4c95-b894-39eb42bb8d3c",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Weitere mobile Brenstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "42fd1d42-e7b9-4cb3-807c-ebcdc1afe289",
                          "9b66b91b-5bc3-49a9-97ab-5e06eb14bc02",
                          "a5d5bdba-d266-4331-a5a7-26a5b5a9199f",
                          "a54c926d-567e-4518-857b-db985f099073",
                          "583e2f92-4249-4dc7-b3a8-5a549ddf798f",
                          "ad2346a3-2ad3-435d-9655-78030d666061",
                          "85e3c9b8-d153-415d-959f-bc0de5050c89",
                          "c201f823-02bb-44da-a622-c3f500b85fc7",
                          "e021a059-fb6b-44c1-82c2-c90d34970774",
                          "9f33ac54-8941-463f-92d6-6ccfe52279bf",
                          "2ce65cb6-f121-40a6-b224-344a6e279b89",
                          "115448f6-729f-468b-bd97-21053323b077",
                          "f9bdd31d-6302-4453-aad9-3ed3563ca89b",
                          "51684f7a-7e01-448f-a8cf-7948901bc1ac",
                          "4309d6e0-ac2f-4a18-a222-39c32c83696d",
                          "bd32d4bf-5074-4a77-8840-ebfad53826de",
                          "25547fd9-05df-437e-8889-aa45b5bce6c7",
                          "01b205f0-adc8-45e7-81ff-d482b7e7bee1",
                          "5ed36147-fd60-4e8c-a840-7014942091a6",
                          "f4cfc3cb-176b-4b51-818a-5558b48affd9",
                          "980c46bb-b3e2-47da-91de-2bd0c79ff74f",
                          "4f600211-c360-40d1-aff3-560b196bc36c",
                          "440036a9-4b20-463c-9916-d24416519dde",
                          "9798a7fe-f0f0-494e-bd83-20e69c4cdd4d",
                          "b4a5dc07-14b2-4db8-80eb-c72a2c17c4e8",
                          "be7d5077-0d61-4891-b860-048bbfc35efa",
                          "e64308a7-622e-45d8-ad41-f7017c3fc521",
                          "230c9ee6-1415-4ff4-9519-0a8435d56f9c",
                          "99de7186-ff28-409d-9ef4-ec00ded2cabb",
                        ],
                      },
                    ],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Zu den Emissionen aus der Verbrennung von Kraftstoffen in mobilen Quellen gehören Fahrzeuge, die auf der Straße für den Transport von Mitarbeitern oder Verteilerfahrzeugen eingesetzt werden, sowie Geländefahrzeuge, Motoren und Geräte, die für viele andere Zwecke verwendet werden. Geräte, die sich nicht aus eigener Kraft fortbewegen, sondern von Ort zu Ort transportiert werden können (z. B. ein Notstromaggregat), werden als stationäre (nicht mobile) Verbrennungsquelle definiert.",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Flüchtige Emissionen aus Klimaanlagen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Kältemittel wie Ammoniak, Propan und Isobutan sind keine Treibhausgase (THG) und sind daher nicht zu erfassen. Alle THG-verursachenden Kältemittel, die Sie berücksichten müssen, sind in der Konfiguration gelistet. Achten Sie dabei insbesonds auf Ihre Kühltransporte, industrielle Prozesskühlungen, Kühlhäuser und mobile Klimaanlagen.",
                      },
                    },
                    inputParameterIds: [
                      "c822c253-5bb9-4b0f-8184-522296269f51",
                      "8237c24f-1acc-4450-a235-37af6ba54b14",
                      "5afcd7fe-16b5-4aa5-8aad-ffe81550ca94",
                      "b9f0a519-470e-4e33-b5b8-97800d843cbd",
                      "46e66f4c-f3aa-4fc4-9b26-881c08500c3b",
                      "f854c6ff-025f-4507-8fb5-e115fe35adeb",
                      "ccf54ce7-95c7-4444-ba15-9b7bbade0ebc",
                      "3c11157b-bea5-4712-8292-e8852550a66d",
                      "667a8c68-77eb-4c6a-ac6b-aff943da3cca",
                      "29dea9f9-47e5-49a9-9f2c-3597ff28f07f",
                      "70d6ffe5-024d-46f8-a305-17de9dc7c61e",
                      "b4a9a5b1-c2e0-4359-96a8-8c9de5ff9818",
                      "30648fe3-d295-480c-91ab-fe5de1c106ea",
                      "50f9b984-4eb5-43a8-9d0d-1ef02571e388",
                      "975d6ea1-a139-4a04-ace8-92318f498a6e",
                      "298fdcd3-18e3-4c23-b302-140c76518e9b",
                      "5c2be643-bdea-4d76-b022-4fc2c88dd509",
                      "342882ff-840c-476c-a559-4d860db72863",
                      "5daf827c-524d-460f-b0d5-322b45158540",
                      "59de4c4d-cc39-4e0b-afcf-600ba3705eeb",
                      "698f485f-fc49-4d87-bd03-5d399e35c386",
                      "16d3bf22-823f-43b9-bed5-3039a518cf02",
                      "743e8e78-3979-431c-8922-6d207bab8a13",
                      "21422704-04f1-448d-b1af-aff21c646178",
                      "f1bc55c1-c246-4e9b-860e-142d591b2883",
                      "e841eb00-7f62-421d-9c75-5ef6d0f6e9ce",
                      "e55d619b-1759-4627-bb3d-7cbd85bf8eb3",
                      "5e8bb18a-19ba-4cde-a91b-4cf12eee2fff",
                      "4098f9c7-8448-4227-ab2f-af4f23402710",
                      "cb3a08bf-1cec-459f-9b21-1b963185c02b",
                      "f43bfa15-8da0-41fe-829c-4f364127d903",
                      "868de89a-44c4-4c94-8197-72afdb246e8f",
                      "ecedd6da-e896-49f4-a192-65af9b1dbb97",
                      "64131f39-7162-42a7-8908-afcaa74ec0a0",
                      "d085428a-6766-4f79-8126-238ef1c5f06d",
                      "b11c749f-757f-49d2-9858-a8e6d672f634",
                      "9d85366e-3bc2-4aab-ba95-ec47bd09a677",
                      "f6db7387-8aa3-4a23-bbda-3a9fcc5888e6",
                      "695668c7-ddcb-4a7c-ab3a-94000e494b3e",
                      "75e2145b-4144-4438-95b9-9d1cbfa3cbdf",
                      "13fc4205-fa0f-49a3-b53c-ad006388e0c4",
                      "79608b93-f422-4763-a539-0d87aef66206",
                      "c5210d5d-cc92-47aa-9b3c-a72871876d59",
                      "7779a624-2577-4135-a3d5-847edc34c25d",
                      "549d48de-9ce1-41f8-bac9-86f36b02b090",
                      "a3128bbc-14cd-4d1f-b54c-a0b7034eb7b0",
                      "f525c38d-663a-448b-b4e5-914428adf360",
                      "2530c324-35a1-4c7e-b437-f34cca41e882",
                      "9246f3cf-9663-4fac-ba8a-fc26d27d2439",
                      "0b072a5c-40ab-4426-a789-e5ae2db5e15c",
                      "5a45753b-7700-458a-9e1b-59ae719b5139",
                      "3c84ea77-03d1-4d41-8e72-3192a4ee8b69",
                      "b8275279-a45b-4583-be8f-e3bb3e5fb4f9",
                      "11a7dab0-d552-4fdb-baba-358897bbc466",
                      "dde47fef-779e-48d4-b0a3-45ae15df09e9",
                      "3f0a8814-8791-49b0-bbaf-69e6a0f328d3",
                      "d2504f12-9df0-4f35-a436-43ceb1984aa2",
                      "d1afb3f0-b37f-4cfc-8928-f581d5102cf7",
                      "56795b06-52b3-4114-b21a-3f52dafb757a",
                      "085173ae-6cd8-4bbc-9d3f-5ff7d30447bf",
                      "af532e57-5e88-4330-a9de-39f6b2163a32",
                      "5b5714ca-6f73-423a-adb2-74d97a324540",
                      "f3df3872-8a88-43c4-968e-209e13a7a8ed",
                      "c4a96062-96c7-4d02-817c-13aa2e09565f",
                      "bfa44e16-12d4-4fa5-bef7-faa1ab785695",
                      "72eb8011-6e87-480f-b855-851569e8d6af",
                      "9ace7de5-9e93-42fc-89ae-9933ba7252d9",
                      "368e9cd9-cdd3-4aca-8ff5-410460cefa23",
                      "5ecaf87b-22cc-4db6-b89a-8977975ca630",
                      "a5d5c673-5179-40be-b3cf-b45b81f0b164",
                      "d4dd518f-8cf3-4713-8956-73f52b371ae9",
                      "4b9d787c-5d33-4efc-8695-31a36298db39",
                      "81bfa2db-5f5b-4d4d-8016-591fe8584b5e",
                      "c232ba84-f24c-4bb6-921e-53811d820bca",
                      "aee3c18e-b587-4b4f-a749-f2703cfc0d58",
                      "3d813225-f690-4cc6-bd92-656a876bbeea",
                      "d0f2f645-7627-4495-b534-cee39f4eb23b",
                      "4033637f-dd4f-4b27-b849-003211849d99",
                      "86683fe2-3252-4fe2-a2e1-df331f0b27f7",
                      "0a6a8d87-2002-4884-b2f8-a16e4eb24165",
                      "4140b88a-8bb1-4136-97f7-3795b00e3a84",
                      "c464d20d-4e73-4c37-a665-49ace3eb4c51",
                      "3b0968cc-eaa2-4f5c-a96a-54808f6c115d",
                      "4efbc185-6eec-4ba8-8f7e-ba2ca8c44494",
                      "f08e8180-9256-4c14-8919-dc3fd30c386d",
                      "bc3f854d-1465-4269-a66a-9ee6e4234439",
                      "b0c58015-6dad-4b36-9590-55451a8aa294",
                      "c73ef9be-6b3a-446c-ac4f-1c371f2650d3",
                      "090dac24-9765-4c29-945d-c82cdec7499d",
                      "0a6fa522-624d-42f1-9d0f-4c78254c592d",
                      "c3b5b26a-104b-4289-bd1c-9e198329bb65",
                      "91e973d0-2018-4374-aa4c-0f9885444919",
                      "9aa9b0d5-3e04-4604-bb9a-31f0d728b0d9",
                      "b663cb1d-409d-4a8d-86b5-99d5919308f2",
                      "72d524e1-9dde-4a2b-9264-c1eddee43494",
                      "38e0581c-640c-4786-a6a5-95465e648547",
                      "2291122b-aaa4-4ee7-8821-81976a235bca",
                      "926dcf8d-c590-4b8c-8c09-dc132d66ca14",
                      "be0f7e16-dad4-4d63-8ecb-68058eb16590",
                      "914f311f-06e6-4221-8849-f95218e1d68a",
                      "a8556704-afcb-41b2-8580-f21f8c451fa3",
                      "8be847f0-9182-4fea-8dcc-c50d4922b058",
                      "6320b178-5d8f-4254-87c0-59e467d07272",
                      "5391dcd0-bf45-4278-a08d-6eaff98819e1",
                      "0a2f0654-a78b-4b3d-a543-d444bafe4899",
                      "18154487-54de-4363-9ce6-b3c4f807c32c",
                      "d33783f5-6df4-43aa-9c3c-b1fe11eb9c8e",
                      "6fdf1ce0-4e84-4055-a3b2-8ef3a202dde9",
                      "aee7328e-7fb2-41ad-a333-63d0bf8193e5",
                      "b5ed824c-cf1f-4239-a9a5-c7553227e961",
                      "9191d860-2dcc-4ca9-8e4b-ac433e2e9896",
                      "6fe858ed-50d9-4f54-8fd2-30002b4917b3",
                      "98be8dbd-2c6b-4aa5-8633-1436aaf01486",
                      "7ab2335a-cd04-4158-87fe-218f599302c7",
                      "78d7a79a-311e-471a-ab74-7e8df9806a92",
                      "f906f190-75aa-48aa-9a8f-5bb2cfd793f2",
                      "18c24912-ce08-40b8-9413-27114bdeacdb",
                      "9b564d2e-72cc-4aff-a4fe-0949bda5f6e1",
                      "1fb574a2-c47f-4e03-8a46-f498f79f27d6",
                      "8e37446c-8289-4612-8ae2-1147076aa97f",
                      "3f65f1f3-3337-4dc2-b88c-ef971267ee35",
                      "8c573276-5133-472f-98b2-cfa7f52ead81",
                      "c8b53c12-6866-4711-819a-902f82974a4c",
                      "78fe0a16-4084-405d-ba72-699523d73d59",
                      "4103aa38-aeb0-4efc-bc94-1ac207860528",
                      "38968114-90c8-46af-9b8b-5521c4aa8266",
                      "863cf9a6-e88c-4979-9396-a15f98c485e6",
                      "1e64dab6-4eb7-4ed1-b0dc-cfe0f1c1bc30",
                      "307d02f2-b614-41c5-8185-1e2c690b34e1",
                      "d8d4d88f-fe07-4ae6-a0ed-90ec1d5b0ef7",
                      "7876f81d-9ba0-44ad-9c96-aaf30c38d987",
                      "b763caf6-d965-4c20-9e38-541664cb207f",
                      "b026561b-c957-4acb-9668-d616a0f82276",
                      "d045277f-2d7e-40a0-964c-eaf62f0ed6a2",
                      "bf041ec7-1306-4b2b-95be-05799c3c60cb",
                      "c33aef4d-7fe8-4b4e-bffd-43773236a1fb",
                      "cf0116d7-4526-46c3-b468-54edd98840be",
                      "25d82b01-20c6-422e-8b9b-74b5b2d9d0d3",
                      "8dc0d56a-f3e8-4cc3-b228-24c370408cc6",
                      "f3fb8186-a903-4c6c-880d-73c16c2c0e3d",
                      "fec6b844-3f80-4e3a-9c82-43f636d15d45",
                      "2b19d150-2ae3-474d-bf88-4b14d7fe1db4",
                      "341c73a6-fedd-4aab-bf6d-155cd6d3764e",
                      "a9d74704-b20e-4b10-89eb-6cdfba61557c",
                      "57d427d9-8e7f-46bd-a0ce-727d2e5dc4fe",
                      "0caacf4a-2f38-47d1-8753-9a972b3bcf80",
                      "0d237170-71f8-4753-a21c-42a1decc7d78",
                      "7741defe-4d78-4393-836c-acfad7d9fa1c",
                      "42a0224a-d9e4-42a6-9931-d082df263b83",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Sonstige Emissionen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus chemischen Prozessen oder anderen betriebseigenen Quellen",
                      },
                    },
                    inputParameterIds: ["5b046ace-45f5-4f33-b5e4-f2c47206a463"],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Emissionen, die direkt aus Quellen stammen, die im Besitz oder unter der Kontrolle des Unternehmens sind.",
                  },
                },
                inputParameterIds: [],
              },
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 2: Energiezukauf (indirekte Emissionen)",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufter Strom - standortbasiert",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die auf dem durchschnittlichen Energiemix des Standorts basieren, an dem der Strom verbraucht wird.",
                      },
                    },
                    inputParameterIds: [
                      "97068089-3e05-41bf-b4a1-131d49e0c204",
                      "2bdf091c-85cb-4988-bc3f-c43b9e7f5465",
                      "5fa29a55-4c77-4811-9c04-1cbbda74c6f9",
                      "4f0405b9-72f4-47a4-9d62-16669f41ff18",
                      "346bcddb-1f60-4b94-85d7-53f1fa961461",
                      "ff80d653-2b20-441f-9ee8-4748b73c6130",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufter Strom - marktbasiert",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die auf spezifischen Verträgen und Herkunftsnachweisen für den eingekauften Strom basieren.",
                      },
                    },
                    inputParameterIds: [
                      "62b10a72-373a-4066-a8f9-9f8285c17225",
                      "72cffb4f-323c-4f4a-a346-3c029035ce36",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufte Kälte, Wärme und Dampf",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Erzeugung von eingekaufter Kälte, Wärme und Dampf.",
                      },
                    },
                    inputParameterIds: [
                      "e8b00818-6fa8-45e0-a2d9-3195c17b3920",
                      "83a20bea-b688-43d3-9890-9692eabf95ef",
                      "827f1d46-24c6-438d-bf4d-f73d1b84fb40",
                      "56db0842-4f8b-45b7-9872-6bd3643f014f",
                      "e5198c5a-de60-4612-9ba7-442cc1acfd20",
                      "896c554a-dd1f-40a6-976d-4c64e40acb02",
                      "c2c8d744-b676-4a73-b52b-0344e54e2ee4",
                      "1b4014f8-a231-4535-ba22-fd5491b6be1c",
                      "69986d2b-fd66-4d18-af7a-c479aec373dc",
                      "e16e23e1-99d4-44af-a15c-b33474195e96",
                      "1aa067a8-68ea-4ecc-9511-40becf9df863",
                      "f2a6a630-c524-44e1-a9ca-de31564ccb08",
                      "c7bd0669-3f27-4c2a-951f-09c2ff0575d0",
                      "9c86ce5e-6b26-46c4-a267-8ae1df73084b",
                      "22634f5c-db46-40c7-9854-fda3546c40c4",
                      "049dcaf9-127a-4d30-9daa-4a13115ab6fc",
                      "5429e5e2-e47b-451b-88ac-2f36173bdacd",
                      "9e44acc0-770c-430f-9d9d-f40af47b0144",
                      "ef7d3c83-299e-4fb2-aabf-2c230b620f8e",
                      "505b2354-1fd5-4f73-a65d-20cd8d96067a",
                      "3bcc3821-0005-468c-969a-993554792d48",
                      "05d5ddc7-f366-48c6-b3a5-d4aa885c361d",
                      "594b5bd6-b95e-4561-bebf-8821cc0b4fe3",
                      "88758a0b-d8b1-4875-8cb7-6e50dabfc827",
                      "bf908071-1b42-45c9-86e0-1f2c160b3b1b",
                    ],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Emissionen aus der Erzeugung von eingekaufter Energie (Strom, Wärme, Kälte, Dampf), die vom Unternehmen verbraucht wird.",
                  },
                },
                inputParameterIds: [],
              },
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 3: Wertschöpfungskette (indirekte Emissionen)",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.1 Eingekaufte Waren und Dienstleistungen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Produktion und Lieferung von eingekauften Waren und Dienstleistungen.",
                      },
                    },
                    inputParameterIds: [
                      "180c4872-d274-401f-9f56-2d5826115133",
                      "2cbfb58f-9918-4c68-a81b-1a4ae4cabae9",
                      "48d06887-e72c-416c-bcd7-9267a4182c15",
                      "02b04e95-8f6d-45f1-9297-83c2016a03fe",
                      "650dea1e-4cd7-459e-ad8b-2a2a367e8971",
                      "fc065320-57c4-4be6-a8aa-86371ba28615",
                      "4b946e54-98bd-4c14-8882-c50d38f11653",
                      "8bffad10-aa13-4498-837c-9aff6081ec52",
                      "0f3106d6-77e6-4720-9396-a656737c5e97",
                      "280a11de-4a40-44c6-bc3e-15e9c39ee0f1",
                      "08f40fb9-9fe0-4cea-9daa-19030f307eaa",
                      "ff65ea42-9cac-4dc9-8432-53519836ead0",
                      "92a3a754-9ecb-4613-bd3a-0b70357029ce",
                      "15d45b79-2c99-4c0a-93b4-46252dbcc22f",
                      "8d568edc-5397-4a37-b8c9-1428d2c209f3",
                      "c63dc044-b0c3-434c-ad9c-cd1518e9b6af",
                      "17b0e21c-39d5-4690-9293-fe40f3c36e23",
                      "96dc9c74-57c2-4bd5-afaf-48135eaeff93",
                      "ca7b2917-148d-4a30-ae1d-aca2500f3fb3",
                      "26bff6a7-e248-47e3-b5e8-966af16c6e99",
                      "98003c47-61f8-430d-8867-7c4f5a2ebe00",
                      "8c26aa1c-addd-4fae-a7c9-530719335092",
                      "0fbfe8ab-29ef-4d36-ba2a-17d210c53ae0",
                      "9b56b6d8-6286-4a9a-aaa9-5214b8888498",
                      "883e4d31-370e-4658-bcf0-41a094303a8c",
                      "c994586b-ca62-4e06-944a-7373a526811e",
                      "99a73b95-ec31-4ecc-ac6d-4d336f70dc36",
                      "19c5d0bb-4979-4613-b35f-49164aa6e80b",
                      "386eec66-a472-4637-9c5b-8facc104a3e3",
                      "72d37936-4cab-4060-a0a8-002427686322",
                      "8e70de0a-bdba-4a99-a7ee-ca2748521cf7",
                      "2e95c2e6-067c-4a51-b129-94827328230e",
                      "33590901-1b65-454a-bb7d-c1623497069c",
                      "d62e72b4-fa98-41e8-a078-463ea6f87cbd",
                      "b37ccfb5-d8ea-4077-bd94-8fc457303675",
                      "7df87be0-7c4a-4222-8a35-2f729a216242",
                      "ebf2c9ab-be84-4762-b6e5-24e0793cfe76",
                      "4e8e0f46-1e50-4600-b873-9c122027e8fa",
                      "30a8bc99-27b2-4f44-ae90-a6cb417f32e8",
                      "746479d3-2d09-412f-9c02-1eba47dc187b",
                      "3b97f047-326e-4a6b-870a-698d1e6f66c1",
                      "b6877a38-35ce-4df5-8b47-73fd2221f7c7",
                      "428d9d78-0bee-4cf3-9484-873c611803fc",
                      "e183fb49-81dc-4a1c-8a7b-7522d6c91742",
                      "e48aacca-7138-4f84-ad2d-61489fd25783",
                      "d83b1f7c-3d10-4cdd-9baa-04830b21842d",
                      "06df4c59-c3aa-4c7b-9371-3119c3e77cb5",
                      "fb57761e-65b4-4c8b-84ad-1e3e57b2a55e",
                      "a5106eef-57d2-41e8-bb04-d231b7de7ba0",
                      "f590d2d7-7b2c-43de-8cad-888f84aaa169",
                      "f0b3811c-97ab-41ca-a9f7-3abb537ca769",
                      "4a086141-f7ba-4ea6-9117-521bb1ea0410",
                      "1652a1b0-cb40-4293-be83-888d6f368fd1",
                      "ca947fd2-0402-40ea-a448-a239c6fbd55d",
                      "cbfebf30-90f5-427e-b107-960d87e163fe",
                      "16bde8d3-416b-4d31-9a69-238282924e2a",
                      "e20cee7e-f66e-425a-a02e-04b8d3da9e27",
                      "5d545deb-e1c1-4f0b-85df-097ed4ef81fb",
                      "f5bf90fe-88a7-4cff-adee-005b70efc4c7",
                      "2a414044-ab96-4761-9765-46791df8482c",
                      "79cb2088-7cff-485f-83e0-acf5823ab313",
                      "e4dbb674-b026-4a91-9866-76520a7472a3",
                      "74bfd23c-a332-4549-ba9a-b2dc54060842",
                      "c01f0dfb-7c95-47ca-8091-7c569bffc08b",
                      "0d819383-830f-453b-bb1e-ab05d0c611de",
                      "f23e7792-eb1f-46dc-971b-c1fb1bcbbb18",
                      "a45ee059-7107-44a3-b7c8-74da1d8b9015",
                      "4b07af94-ad02-40b8-9dee-93f590ae6c35",
                      "58f12cc3-af32-4c3f-85cb-490d2be6e5d8",
                      "f24846c5-436e-4c6c-aa38-b625fb6fe370",
                      "1d1ce330-0c51-4512-b471-107bda710125",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.2 Kapitalgüter",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Herstellung und dem Transport von langlebigen Gütern und Infrastruktur, die vom Unternehmen genutzt werden.",
                      },
                    },
                    inputParameterIds: [
                      "1ce121bb-c8da-49ab-9788-d937b5c61816",
                      "1325d6bb-7505-4648-b13f-7579ed0c2f44",
                      "d1c27dcb-cea1-4cc9-b306-cca6aa44c2ef",
                      "7e9a83e9-abc1-43fb-a8fb-77dae0649fc5",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.3 Brennstoff- und energiebezogene Aktivitäten (nicht in Scope 1 oder Scope 2 enthalten)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Gewinnung, Produktion und dem Transport von Brennstoffen und Energie, die nicht in Scope 1 oder Scope 2 erfasst sind.",
                      },
                    },
                    inputParameterIds: [
                      "a97d59c2-d877-4b08-ad3d-142e124186ba",
                      "e5924001-ac81-4b3c-87af-18e5e713ba25",
                      "98020e65-d848-4f99-b387-29ef695c5b15",
                      "34dafd31-29d1-4b81-a5d2-4694390ebd5f",
                      "78732ac0-83b3-48c8-929a-c0fecaaa2dc3",
                      "a1e3f403-c0aa-4ac6-8d1f-af7b70468ea4",
                      "828ff153-016e-44af-bdb3-0da359606ffa",
                      "95a8df84-4aba-4786-9246-ef85d4006e1c",
                      "73b8e443-a0b2-4355-b22b-8cc151bacf72",
                      "00553e49-23b0-426b-be86-7c4f2692ecbf",
                      "022b7bc3-d0d9-432e-95f3-ea070e2f053d",
                      "4f22cb6d-1533-4add-aa9a-45650d54efe4",
                      "79e6bceb-5dfe-47f6-9ce2-f03309912ed1",
                      "2f0e3248-e645-4934-80eb-857547023dd2",
                      "713369c5-2837-451d-851b-c8bb39ba40c3",
                      "b98cb44a-eb1b-4a3d-8c72-413b2a293a5c",
                      "27bc9114-3e8f-4544-9824-2eaebac7ed36",
                      "5b077e73-b2cf-49a0-8dc5-a79aa51cba63",
                      "6db92786-5f65-4d00-8114-688b0ef9c5f7",
                      "5a8633f3-ac90-4ab3-8f53-52d3e647cba2",
                      "fc93edd2-40b1-4d51-87f7-fc5303aebe91",
                      "a307eed4-f66a-4409-bd9f-484b3b6f0e76",
                      "c447d2a7-366c-411b-9dc3-dc4855c07ac0",
                      "e1cb835f-fc90-49de-81a0-30161824936b",
                      "2eb2687d-bffd-418f-8886-f032e13423f5",
                      "3b0ed2ea-6700-44dd-bd88-692d1c335222",
                      "185ba86a-6c68-4af5-accc-4a6a57ea048c",
                      "e4c82383-c619-4ea4-a7e3-aa822e08657f",
                      "7d278772-1376-44a5-a9ff-813e5ec94fd0",
                      "1521686a-bfd6-429c-b79e-9815a7ff8e43",
                      "44f361d3-240b-4ee4-a86f-a4f670c8aa77",
                      "1dabbf3b-8866-4341-81ab-430ed4c3d16b",
                      "06610cb6-9337-4b50-bd4f-09eadaa102c2",
                      "87a01aa6-4803-4d16-a5a2-35d6d30d4c38",
                      "979e6c81-5898-423b-ba90-0d35e6f2be57",
                      "957e8545-0258-4413-be4f-c92fb167c36a",
                      "3ee72c5c-3a21-46f7-84a7-b1bc13ead437",
                      "04e4e910-0a23-44b6-8b78-f9d5f47f9d81",
                      "ec6fded4-6b37-4114-8c06-92546448f59e",
                      "625b2664-378d-4dc9-b317-9807d05a067c",
                      "63d0129c-c353-461f-9019-de6c6e0a2aa9",
                      "3c057dbf-f4de-46e2-ad21-124b2218a2eb",
                      "7fedb4e7-95a8-4f7a-9b85-7e7b518ab589",
                      "3663f5c3-27d4-47dc-9f6b-0961ed1d93b9",
                      "49383d88-471b-4cda-af04-1ce2ffb0f63a",
                      "e8a7f23d-fc12-41af-919e-77a971745160",
                      "75f38ded-c58f-4def-a385-ca0e3191654b",
                      "d21d2442-ddbf-4004-a768-1468b9f27a94",
                      "71ba280b-b890-43c5-8553-a5cf4546b1d3",
                      "57caeb36-2638-4bd9-8ebe-6435ca11a00f",
                      "d7205811-fe49-43cb-8260-85a61031fda6",
                      "d83924fe-273a-40b5-be31-f995c6f728bd",
                      "d8193c3b-37a4-4c0b-9cc9-58c9d54db5b0",
                      "550256b5-3ac9-4628-b3b8-7cfc47502bd1",
                      "43873d3c-e56f-4899-94d6-f9301d06c90e",
                      "dbc1bf05-be77-4891-b9ae-1dbac13cf833",
                      "258838cb-fe7a-4898-8d7e-5f70de691c28",
                      "79c87779-dc7a-4c78-a8a7-eb559dcf6ea8",
                      "fc85ddf7-4ba3-44cb-b1d9-615c227b5234",
                      "dbd35dd6-c8f2-4866-b232-bd912f51254c",
                      "d44d210a-9b7f-4e1b-ba80-057f8c752931",
                      "80bc41bc-a546-4512-8194-f21cf3026b3a",
                      "684ff436-a604-4b52-88c3-dba80e9c7e58",
                      "5a8932f1-5733-47a8-a9b2-c5671adee4f3",
                      "738556d2-d7fb-47e9-a95a-3e992b5c18d0",
                      "f4f4eecf-6a3c-4f8a-8e50-d372aa607056",
                      "30866d07-7a63-4b5b-a30b-69ef2f9db117",
                      "fb7ac0f6-4621-49b3-839f-efd9f33f3382",
                      "9027cb41-0d89-4af5-9285-38c78e4a69f7",
                      "993a6cf9-cab5-4d7d-8268-1172c31b534c",
                      "960d3032-772a-470c-9665-2c79f8de9860",
                      "0f8be108-48cb-4b5d-9dfd-29b71b981d55",
                      "321758b9-76e1-4950-98ad-61d1ec834b1c",
                      "dcac2600-a2fc-4614-b480-5cd0c28c0c0b",
                      "6c5fff53-615a-4bc3-8bbc-fb0ecfd517b4",
                      "9848ea19-ad18-4ab3-9f19-0a788342fd37",
                      "af947c63-5b29-4633-9f16-28d106485435",
                      "48306a81-28c2-4f5a-bd56-bf06012d34c2",
                      "772685dd-ddc8-4d74-bda3-c30cf1bc2cab",
                      "9542d8b8-4c82-43db-9186-7d71be1e2701",
                      "41eb1f0f-2216-401a-8c94-0713479de11a",
                      "a2dedc91-4b19-4b86-814b-38360e76338e",
                      "18f98678-9d5a-4e1a-a152-64b1f3e85aba",
                      "14778f38-b02c-43e8-bad8-84454985332d",
                      "6a851462-c98e-4511-8dc9-f72d3a77d4ad",
                      "5a51ccaa-9edb-4a1a-81e0-64878a66911e",
                      "c2540d94-0550-4635-8943-6c9b63d3a21d",
                      "8b911ce6-aa0a-4c82-9677-75c2d1b89c5d",
                      "0692ce4e-fada-48fd-bff1-69e807fe769c",
                      "ca7caa66-da68-4223-b8f7-5c3a6ef0d6b1",
                      "88ee1a5c-ef34-40ed-bd2c-8e57b85ea3ad",
                      "e43a0738-3c9b-4911-9837-cd3abba77674",
                      "a48fc419-ddec-48d4-b2f8-f504b70682fe",
                      "9ebac38e-81a3-4445-9e3f-1c0c479935e0",
                      "b160758c-25eb-4d6c-9f52-c8d196ca441b",
                      "6dd867aa-430c-459a-8711-40e8c45c9ed1",
                      "893c6ba4-cf30-49a3-b646-d613431a2b11",
                      "1bf4d0a7-2326-466a-8bf4-0107fe776cb6",
                      "77d1cf0b-6f8d-4983-9238-9e0a96439427",
                      "2545730a-8f24-434b-9162-f213794e901a",
                      "432cb1df-4ae1-407c-8ee0-0207a600a446",
                      "720934c2-fb6a-48cc-9588-b812a41087bd",
                      "07affb1d-91a3-4967-a647-52bf6e72d3fe",
                      "c12940b8-b720-425e-ab28-ecaf9f260f17",
                      "d0c0262c-2da6-429c-a495-910b6c536487",
                      "9256a3e2-54ea-4408-8269-9e853d42e792",
                      "caf4ee27-baf0-4fc9-a740-a07fb2ef5afa",
                      "47a82637-99bf-4846-a7a3-9f8ae0ce230f",
                      "7e98520f-9e54-4d57-b846-53e0a051568f",
                      "301661f5-a9ef-4f3c-a12a-47ce23a85122",
                      "166b3f0c-0617-4cf4-8298-04da9c2ba841",
                      "8a8f4e0a-db2a-4a98-bdc1-a3809ce4c100",
                      "3996b26c-7c0c-4071-b507-be852ba45a18",
                      "f42f8a5f-2184-4d31-9e8d-50a529681e30",
                      "f975096f-2914-4c30-956e-7fe261fff500",
                      "02ff232e-4503-4bb4-bc0b-363ef9c05862",
                      "3ae81068-f757-4f10-91e8-b6507b0e32eb",
                      "ce3f9c0d-fbf9-4f91-a9e5-89f0a2f39c54",
                      "b359c24c-17a7-4e92-a340-d57ebfb111df",
                      "10125a86-d6fb-4383-bb8f-f2aacbe4b6c1",
                      "d93c476d-4688-4b08-a1e4-5ca996966cdd",
                      "8170d133-4e71-4d02-99be-0730cfe9456e",
                      "ff42772e-65e5-4cfe-a218-820d5c065e43",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.4 Vorgelagerter Transport (Upstream)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem Transport und der Verteilung von Waren und Dienstleistungen vor dem Verkauf.",
                      },
                    },
                    inputParameterIds: [
                      "878141eb-5b98-44fb-b424-83685f9513e5",
                      "a6ee430a-af89-4811-b91a-8294f037b78b",
                      "ac5dba27-60a9-48e5-8279-c17603c6c24b",
                      "dd575672-1181-486f-85dd-7a7dd90db17a",
                      "c71a4cf6-4484-4abd-9aed-bd87ecb78c9f",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.5 Abfall aus dem Betrieb",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Entsorgung und Behandlung von Abfällen, die im Betrieb des Unternehmens anfallen.",
                      },
                    },
                    inputParameterIds: [
                      "cf8c8e62-69d5-470d-89d0-f797cac38f71",
                      "ede1be14-c446-43e0-a9cf-1534120a6bef",
                      "917294f8-8a67-45f7-957f-cb8f55058b4c",
                      "1ba5e74b-fea6-4274-b39e-864fb2cf9411",
                      "758fdc1d-cd2d-4000-90ba-bca26e96e807",
                      "dd112827-34dc-4a79-97b6-d81274a89789",
                      "0e62b989-9205-460c-b5db-2bcf86ead3d9",
                      "e84167db-f404-4dd6-a96e-8c3e219aef83",
                      "7171cdde-c25f-42d3-a287-3a663c017d9d",
                      "8fd3c63b-1306-44a1-a1fe-18cb945ad5ca",
                      "1a4537cb-1e33-49a8-9c0e-5cc2d1c6cc0c",
                      "a878083e-7912-4b1a-b020-8e8d93b94fd9",
                      "cba232ce-f4cf-415e-8745-04b652f601c1",
                      "cd264016-5640-4d1c-ad34-8476031148a5",
                      "4bf6ff89-1cbc-4058-902d-ada51e698409",
                      "fb2ff2a6-ba99-4280-a2bd-f7f654fbb6a0",
                      "57deb857-7310-4d45-ba9e-7bb2fe7a4bfe",
                      "7b5a1b85-1239-4735-9f59-f1d275cf8096",
                      "29d91608-0023-4d95-8690-1efd4f17ffb2",
                      "0879f5c0-3fbc-403f-93c4-410efd435cb2",
                      "68fb4104-56b5-48b5-b12d-b73b8a1bdb4f",
                      "dfd28aa0-c1da-48b5-b5c5-5ddaa73bc44a",
                      "2defabc9-e808-4a68-86d2-a961c792d16e",
                      "a775448d-73e2-4b1a-aa9f-fa04b790c980",
                      "c390d834-03a1-4d74-be9f-71af91da0fbd",
                      "e84d8857-4b4b-4275-bb82-b808c427bcee",
                      "8f0d8a6f-0ee8-45e4-a514-305b06e06c6d",
                      "e3214ce3-6757-497f-baeb-13084d7e81d4",
                      "5fc0b92e-f175-4991-a998-de454cd76a90",
                      "f7278080-8a11-4f8a-ae03-83f19d2d2e39",
                      "23a62e56-9c47-43e2-9c36-2edd48f1d57c",
                      "c38c0465-d652-4ad7-881a-36430af9b479",
                      "7e3b0e47-9075-4df2-83ce-ca0d7ff4911f",
                      "54aa00f7-ef19-4c60-9d2c-f815c439fa09",
                      "17f6459f-c763-4499-a56a-6ddaaa9ed6e0",
                      "d5e95462-ceb6-42df-99e7-6ab8281a2e29",
                      "5dcfaea8-10bf-4cc8-93b6-e970d633a7b6",
                      "3461ba5a-bbe3-4b26-911e-07ecacd05e2f",
                      "0b543d9e-45ac-4b72-a9f2-fbac251c09f5",
                      "965340f7-ab43-4982-a0a0-b3f73d8415e4",
                      "b55d26c5-59f3-4848-ad4d-9ee0a5949a49",
                      "ed916445-aefb-48b6-88a9-25e7f169d15c",
                      "0cfb6495-0e4c-48c7-bfca-0b85ff7c4574",
                      "a0e73e43-cb44-4540-9e6a-5aa3df24c71b",
                      "b9947e0b-ee36-4104-af91-a16eefd5a780",
                      "dc97606a-0272-4635-a65e-73b3aa6d7715",
                      "b089aed6-56e6-4eaf-9fd1-4def244a5f28",
                      "580a4e8a-f3b2-41ac-a0ac-c2f16ef330f2",
                      "da3df2fd-f025-464e-b2d7-a4923c303e75",
                      "6a961316-a9e7-458c-9f65-72864b303043",
                      "2d13e5f2-465c-421e-8893-f7b6c28b6627",
                      "8e9ab682-6334-4e91-a1cc-a421c7976ee2",
                      "46130e39-f2e8-428d-bc04-fafd90b871d4",
                      "9615a4a2-f3d5-4b5b-8bc7-b354aaa737bd",
                      "85577ca6-428f-44fc-a392-b34aae8b0966",
                      "6babe2ca-bbed-41f6-a444-427b52151c61",
                      "271b771d-3e8b-4df4-8f19-9ed61343bbb9",
                      "e72d7e10-eb73-416b-b579-cfad146e637b",
                      "3c719357-5a3a-411f-bb65-8c88b22330ca",
                      "dd5924ac-2403-4168-98f2-1ce36955ea5a",
                      "423e5ec8-e6f1-40e8-835b-946fcf53b9d0",
                      "1a6a9007-1f1c-4109-a77e-65a0fb12124e",
                      "409a1a3b-653e-4f59-b678-071895d30c84",
                      "99cbefd5-1880-47bf-9114-3f945225b7cf",
                      "c0af96fb-fd62-44b2-928e-b59fce78f8b4",
                      "1e0e8d73-730e-4ac1-a467-4b4b62a2972e",
                      "aae76bbc-0344-4a95-b26b-02150adf7db8",
                      "73036b9e-15c6-4eba-bfa0-ff9cb27fb370",
                      "6f7dd4e9-3bef-4a1c-bcde-b0176f88c8ad",
                      "8c0e1a9e-7cab-4248-ac68-4f32809d6b41",
                      "003dbea5-41e8-4cf9-9e4e-3351e0d2e94e",
                      "6f63a341-f7fa-4d8c-849b-be5481dd44e6",
                      "9c0e7618-1f67-4c1d-a079-7cf42b12fdaa",
                      "13aec58c-1b59-4e67-bf5a-d0ace408015a",
                      "cb1ae8a4-b304-45cc-827a-a0e7a1f89a7c",
                      "afabc0fb-b3fa-4a1a-9681-8653f14f6434",
                      "d6daf01a-4c0b-4d9d-a979-3bd6d66a4fa7",
                      "9d277ade-af6e-482f-b37c-539e213085e5",
                      "cea5310a-d08d-4cda-9536-0891895fbbfc",
                      "f9e40396-996d-4f23-8e85-1ea756bf9f66",
                      "076fa181-4439-404d-b3ce-d8e2f28fca0e",
                      "c6383a20-dbff-4d07-aaa2-5a16ff94ef64",
                      "49d2b028-9de5-4246-bb78-1f5ca6871419",
                      "74931396-adde-445b-964c-3c6157bd8ccc",
                      "2c88d505-bc6b-4a26-8a6a-b5367f6f1228",
                      "95a896f2-db01-4783-94b3-ce6bb5c87d55",
                      "2a651cff-4bf5-4380-a004-dfb546bac103",
                      "80ee6047-2371-4d81-bdd0-6ae6115f6627",
                      "cbe32e85-2324-4557-9e3c-4316c5fcb7a3",
                      "58c5e6da-0cca-40d8-a29d-db176b52b9ee",
                      "aa987e42-1984-454b-a225-76f33e2a5a99",
                      "e6dfd4a7-4335-4fc7-8d87-ad853e86f9e7",
                      "96a1a47c-9b82-452e-888c-6e795dfe4ce4",
                      "c1460186-4013-48f1-bec6-a71dec7a5abc",
                      "5c5b6fbc-1e67-4104-9376-47a120563f72",
                      "7aeba435-586a-40e1-b482-6de73e426081",
                      "a358e41b-ffbe-4bc0-8e3c-efa49aeb4b0a",
                      "2568f325-843d-4359-81f8-4504cf2f0245",
                      "0f2f5e79-5b3b-4de3-ae57-79e7282f05b8",
                      "31867357-2bff-4d0a-80cb-b60dca46bbf9",
                      "aa644540-8e77-483f-8324-b54e913a32ee",
                      "a013ead2-da40-4744-9948-1971d490f49f",
                      "17f5e2b0-8548-4d46-b144-7c6de76f7e26",
                      "2e9e396a-90b7-4736-a692-02b04810b286",
                      "e904530d-0996-4180-97f3-792b27466a52",
                      "a26e72ca-bf6a-444e-8f87-1e81754966d4",
                      "c883e85c-d0bc-44e9-b9a5-db8684dfa1a7",
                      "3d661ec8-bc33-4ba8-beb0-76e1aa35f3f6",
                      "7b48e78e-21fa-4896-a096-f43cfcb30a49",
                      "24071064-574c-4e5c-9009-76b05cf5f8ac",
                      "360e7d48-15e5-43f5-8a7c-439599c4511e",
                      "ac60ce1d-b0bb-4157-9e13-164156759cc0",
                      "8b9ffbd0-4e83-47ad-97ce-ed86c23ba03f",
                      "93bcdc1b-d283-4f99-94f7-9f47c796b137",
                      "3ab2ee55-c0b6-4f0f-ac2e-acb9cc24aedf",
                      "b69a6ece-00bb-4585-9f66-ec85c50b6dba",
                      "c8bac46f-ca31-453b-add6-a5fda4300475",
                      "ab713ec8-5343-4a56-81ad-3e59edc5847b",
                      "ba48425a-2126-4ace-a5cb-a3c30ab391ad",
                      "2dda93bd-78fa-4594-a51e-f9a709eb9ad9",
                      "5848fc94-b06d-401c-b165-5f430f7952d2",
                      "d1600094-f2ce-4cfc-a0ad-89f278225675",
                      "456670a2-c59b-4cbe-93f1-d382d43e53fe",
                      "2b76dc68-3435-480e-b959-09df4c5c6e62",
                      "3d56b741-ed49-4a3c-824d-0cccfa6f5275",
                      "2c1e0a07-fc89-4e19-bbe8-1c247a48c985",
                      "abad9065-ea2f-46b6-9799-ec5ac92db7df",
                      "7f708a48-5994-4bfe-8018-0d6982a51984",
                      "215a6178-b398-423d-804c-71a02e331b0f",
                      "f94bdbf2-4106-43ff-bf0e-0dd0256d224b",
                      "b0e8976f-a9bd-48d4-a3c2-2649e5c97f30",
                      "ecc1cf01-4a76-4df3-8274-e6095bc184d7",
                      "29979699-fa63-4e46-8b3c-15efc826ee31",
                      "b48ae131-9be5-44b4-8a6f-cd669c5d56d9",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.6 Geschäftsreisen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Reisen, die von Mitarbeitern des Unternehmens für geschäftliche Zwecke unternommen werden.",
                      },
                    },
                    inputParameterIds: [
                      "208dccae-fabb-4a1e-8706-281fa21b4b99",
                      "ac3e2478-fd8c-409c-b5c7-52d51de529a9",
                      "a0db8218-6084-4dd2-bef3-d29d0cb55bbe",
                      "4189266c-026b-430b-832e-fce97a94a31f",
                      "76de89af-ba2f-4211-bb18-3e29212ad204",
                      "d06cde08-1cac-43c7-a337-c9ff2d56d0d9",
                      "11e89346-d175-419f-af33-35384a207fb8",
                      "771d58ce-d9af-4e08-9151-0a5fc3b78ccb",
                      "0e72f55e-69ca-404f-8e7f-a76a54f16020",
                      "061bd5fe-3f22-4faf-9bc5-a0845acd33d1",
                      "e68c9e10-7333-4940-8fef-697413c6aca9",
                      "e9cec0a7-b757-410b-8d4f-c702f922487d",
                      "e9f4b90d-39fd-4854-8d8d-664dd3ef3023",
                      "7a0225dc-4793-4396-87ae-599c60a000e4",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.7 Mitarbeiterpendeln",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem täglichen Pendeln der Mitarbeiter zum und vom Arbeitsplatz.",
                      },
                    },
                    inputParameterIds: [
                      "4f1a6114-8ec6-4d21-be40-fe3a0d0eaf4d",
                      "f6983727-eaa0-47ad-8383-30cfa75a9164",
                      "cb1e1d69-b5eb-453f-82e2-d84b17989625",
                      "cc4b8741-c39c-4e96-a7f1-4414250f4e46",
                      "d1dbf888-a554-4f79-9ed7-b64a7523a9ae",
                      "5266c9d4-875c-4897-bc29-417ebfedc528",
                      "6a0ce27d-cd9c-463a-9c90-bc5509091c19",
                      "da7136f4-2a3e-4aeb-96e1-99e9ac10fb44",
                      "326affab-ba4a-4477-a923-8c1152d648b3",
                      "37413fee-778d-4ccc-8396-3a0635360d81",
                      "6cbc5f64-b4b3-4395-995d-8c6233ee63a7",
                      "724529ad-ec4a-4616-9cd7-be92f7dd9dd3",
                      "446ba3c3-57e4-44f0-8fc8-0f94c186e00d",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.8 Upstream gepachtete Vermögenswerte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Vermögenswerten, die vom Unternehmen gepachtet werden, aber nicht im Besitz des Unternehmens sind.",
                      },
                    },
                    inputParameterIds: [
                      "64919e92-f1d3-4069-b523-583b91b0127b",
                      "2aa74d3d-14a7-4b30-90ed-2330767cebd8",
                      "2d3e73b0-ebdf-4831-8b06-d82f6eb27a7d",
                      "45e12b23-a447-48aa-990c-9b68a9eea973",
                      "85fec2eb-c779-4b0c-a352-78eb5dd62857",
                      "6a804de1-9d6f-44e5-a8a2-949b385cba62",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.9 Nachgelagerter Transport (Downstream)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem Transport und der Verteilung von Produkten nach dem Verkauf.",
                      },
                    },
                    inputParameterIds: [
                      "3c8ff06f-05ac-4a8a-bf09-785f03627ac2",
                      "e6e81547-42ca-44d8-ba5e-c450dbb87f24",
                      "06b6c0cd-216b-44ad-b4d1-bbc2a62a252d",
                      "7e0b3c37-7ecc-4e2f-b8fa-94a783fee815",
                      "007b3d19-947e-41bc-b421-a0cd319e67a8",
                      "481920fc-939a-4313-81fc-95268739e225",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.10 Verarbeitung verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Weiterverarbeitung der vom Unternehmen verkauften Produkte.",
                      },
                    },
                    inputParameterIds: ["86c1a92f-251a-4b7c-9cd4-24c14365a851"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.11 Nutzung verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die bei der Nutzung der verkauften Produkte durch Endverbraucher entstehen.",
                      },
                    },
                    inputParameterIds: ["74756a2b-6e9a-4b09-8147-8a47c77db92d"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.12 End of Life verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Entsorgung und Behandlung der verkauften Produkte, wenn diese am Ende ihrer Lebensdauer sind.",
                      },
                    },
                    inputParameterIds: [
                      "f446b696-7abc-4c32-8bc7-3f93f64eefd6",
                      "aa2e2ef8-b8df-45ee-a878-9c10e0274228",
                      "a1093538-6741-4f9e-b24b-c5babc4447b0",
                      "da041a02-8ca4-4006-9404-7b18eaa7b802",
                      "b0ad3ffd-d084-4980-a94a-a833dc23d073",
                      "115cda8e-63a5-4735-8545-8378be3e710c",
                      "5f538ab4-be93-4d4c-9e7f-1e9551f28b8c",
                      "a6049e8f-6d20-43ed-9a42-f714e2a11d1c",
                      "61109dd7-04bb-41be-b872-a0b4757f97f1",
                      "78c192ca-1bb4-45fd-ac6d-6d732966568d",
                      "14b32a9d-02bc-4c15-8f48-f2614381a97e",
                      "a169a261-d662-4928-9bf1-0dc50f15e045",
                      "57456d0e-f9b9-4b3c-8ec2-dc56093e4656",
                      "c891b8fe-3ffc-46bc-a2cc-4305b1d84756",
                      "3261fe99-678b-415c-9375-27123ba78b46",
                      "56dbdcbb-f1f8-4c68-8fc8-594e7267d07d",
                      "36702d51-d012-44a2-ae2f-3a3bdc56df80",
                      "7b2c50a3-1533-4ee0-a874-6365f0a55a2b",
                      "8f5eb4cf-6dce-4252-9409-4277fa34a6db",
                      "a23a6c9d-ac62-482a-b327-e5b901289da7",
                      "0275bbd1-4e41-4ed7-bb6e-333dc134d34b",
                      "f5d65cac-f46d-4ae4-a163-d5ea89fe71e7",
                      "1d26424e-71ee-4d80-8a58-e70cc55e8f10",
                      "5cb1daea-8869-41e6-86f2-0366e7b1b8fe",
                      "be3795a0-5269-4c79-8ccb-22fd02d59ec6",
                      "566943b7-d91c-4c8b-8649-0a1f668c8683",
                      "c93ca2e7-aec7-4406-bf7b-ca8f87de8b62",
                      "b7d89994-b7d1-48f2-8aca-d0736e67aaf4",
                      "4afa9bfb-4771-4367-8ca4-a0b9aa5a6d00",
                      "bc73665d-7739-4365-89a2-2c291531f980",
                      "eaf58205-35a7-437f-ab92-682f012a4c7f",
                      "bfeb2f9f-c7e1-411d-89fc-c7bb6bad8b84",
                      "d330ee79-e1ac-4ac6-8eab-daeb284961fb",
                      "45dd5d82-dfdf-4e15-9991-1aea0350f233",
                      "c4478c6d-9866-4099-99ef-0369f9451070",
                      "852f3992-bec4-4c54-aa28-ba7ec4384eed",
                      "08ccd2d0-a4a0-45d5-ab87-9a9ceb949494",
                      "75970b3e-f707-46b4-bf12-e636e0f9c8fb",
                      "396298f7-fb5e-444b-a6f0-d8b0131dcc3b",
                      "ff33918d-98c3-4bd2-88ba-15176f8f26bc",
                      "5ae23a68-35dd-4a2d-b64e-c2985424efc2",
                      "2e9eb71b-38b2-49fb-85bb-dfb1bbd7d9a1",
                      "ceb61033-1ba9-44e3-91ac-ccc043ab9ee3",
                      "c9811c41-f51b-4d8a-9150-f483b1fc0aaf",
                      "2f898ee1-64ff-4042-9d75-960e0ea5b956",
                      "526b20cd-4089-49fd-a17c-f737272c795d",
                      "b153f40c-96bd-49c4-83f3-58b987a278a1",
                      "b1d75d2d-16ab-4020-9378-c53f542d3d80",
                      "84b317d7-f361-423d-9cd2-120fe5edccc9",
                      "8748b42b-77d7-44a4-9df7-e3bc2982e0bd",
                      "221c15bc-f9ad-4f48-8758-8cddfb8f8143",
                      "11b31e71-66e4-4d0c-8bb7-d4358af258ed",
                      "c50c477c-a324-4715-bcb9-aa950bcbc2a4",
                      "ca513f62-99cb-4ff5-b50a-e477d6743ec7",
                      "10ea8162-6bbc-4130-871e-3760f5fc40c8",
                      "9f1e0df7-fde1-422b-ba7b-3b85b3cff019",
                      "9d23ca68-774b-4d82-8b54-f36ce83a14a0",
                      "532009ab-8d2d-4b6a-9c56-1009044814f7",
                      "58db01be-7987-4e07-939d-10a0a6274ad4",
                      "9a64c98b-9779-4d21-860b-425420cc0391",
                      "e0cfe0a8-0236-42ad-a311-a8612898b058",
                      "83efbb83-6e3b-4951-ab1c-bd9cafc7893a",
                      "1a4848f7-2f77-43e5-a163-49ed428dc6ff",
                      "679d4c1e-8a29-4cc6-8b88-007c1df31023",
                      "e349cf48-5c4a-4afc-a3f0-28ac7eeea619",
                      "b39ac545-e860-46fe-82f0-c5b91e71c13b",
                      "9c9918f5-e07b-4746-9eb7-b1077cb64c1e",
                      "1df29c80-2935-4baa-912b-c5481538b426",
                      "f8782cac-0b2c-405b-9be5-c4a3beac488c",
                      "47d0e5fd-d824-40a5-b396-bd530ed6dd18",
                      "007e376d-9d93-401d-a122-05e691cedf63",
                      "92ca89dc-c434-465d-a9a5-2776bf09cc6d",
                      "0133c72b-cc48-4aad-81b8-bafac0c1d8f2",
                      "ac719133-eca4-4cf7-825d-81c4b18d85c1",
                      "d8c743bc-da75-4bb0-b48b-9efb847a2b82",
                      "fd64ddae-f82b-4047-898a-0874cb8097de",
                      "768500b0-5cf6-4b15-97d3-5544a7d8cfcf",
                      "57949f96-05ed-4f88-86f6-33220968b58c",
                      "84a034ed-2c8a-4e65-8f53-a688c1882c0c",
                      "2f1769b3-e3df-492e-9120-d139e91c480f",
                      "b49c5e6e-9b0d-456c-a742-4ecc9e06cd8f",
                      "35798589-d274-438a-b3b3-9211f616f47c",
                      "b2f04a60-0916-4b15-a9e8-a9c4c478bd4c",
                      "932e42a4-3f36-426d-91dd-868e444f7682",
                      "62f26f2a-5082-4241-ac46-2548232d9418",
                      "073a052e-552b-442c-8ee2-e80dfa96638f",
                      "593d470c-6b36-4cf4-8266-e52ffc2e57d1",
                      "e9ec8dfc-3ed3-4a29-81f7-8e94175bbb38",
                      "c21af532-b987-4544-8891-f508250135bd",
                      "4be1bf7d-b537-4993-a356-15121d9bce8f",
                      "438d42b4-a311-4300-93cc-9b6611e39ff0",
                      "91a563b6-dcf8-4e0f-a205-9afced9585de",
                      "7123b924-89dc-4622-a256-ae263fc6adc5",
                      "5efcba27-4d45-43ac-be0f-13f2304cf7d1",
                      "3cff68b0-18fd-40b1-a941-05150de99d00",
                      "18c3bb3a-2ad8-4d88-b85c-d625835b4ff5",
                      "b44e1c4d-4d23-438d-a543-a082fdc2e1d5",
                      "25c0628f-4525-46e8-818b-21f0f89218c4",
                      "3a3e2fd3-5b82-4e44-9c73-914da9ca9ce8",
                      "f1b1b340-edda-466c-8d2a-5ece503751fb",
                      "0a185400-2a03-43b4-a2b2-b7523bcfd854",
                      "786ba2f6-ebe7-42ba-bcf4-48578be3efad",
                      "2196fb50-2298-4d33-9559-22c66bb5dd01",
                      "09354848-7751-46f9-a291-06fefd97e0f6",
                      "b0d4bdf7-244b-4dfb-b3f3-34891ad544f5",
                      "1fcc96aa-afeb-4599-be62-556387419eb7",
                      "214189ec-4a00-47e2-ae70-dcb100f290cf",
                      "de8f7131-f8f0-44b3-a0c2-d305dbef40e1",
                      "0dcf8cdf-b553-4db4-ac70-3adf57712e69",
                      "f0532060-7203-44e3-a84b-329ec65cd428",
                      "4a6289eb-ce76-4bfa-886e-03b436d7597e",
                      "e30ee70e-4242-4d15-aeb4-e0bb4940b90f",
                      "346398f1-66e9-489e-b061-660157f218ab",
                      "8ef995d4-bf68-42d0-8049-19060fdeabba",
                      "0d929fb3-ee3e-488b-9887-a13328d579f0",
                      "9908ed46-0ad6-4c5b-9047-74437ea82af6",
                      "8f9ef520-8074-46a0-bc58-fb7a751857a4",
                      "c0eab161-ab53-4693-add1-a816999adc8e",
                      "514b865a-d063-4a71-bc25-07f4533abdc6",
                      "b17bb2bc-40f4-4e51-8bf9-3edd2fa50e09",
                      "ea8a9214-87d7-4e10-b49a-ed1222d76ffa",
                      "38baa78b-ffed-4979-8047-b91c97246f02",
                      "7eda8143-e3c3-446b-8776-fba04f5f328e",
                      "64d19e10-afd9-472f-a344-23b37c866fa9",
                      "66a84eb0-e270-4860-a4ea-59a292a6ae6b",
                      "114b2de7-43eb-4897-86ec-e3479d99f63b",
                      "49048e0b-487f-4e21-90d3-aad38299c58d",
                      "0fa3c49a-c6d4-456d-a59f-4572b7853c6e",
                      "744a6e1d-6655-4c3f-9b44-fd5c1d2bb07e",
                      "5eed5dd7-891e-4b68-8aad-6cbc8a468fdf",
                      "49e4f67a-d1af-41d8-bace-3b2c015a3357",
                      "23a974d3-3180-48f6-af80-e08ccb978bd7",
                      "6d2f60a6-227f-4c93-84d8-73664ba3840b",
                      "b447436b-b929-412d-ba05-d278c293667e",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.13 Downstream gepachtete Vermögenswerte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Vermögenswerten, die vom Unternehmen an Dritte verpachtet werden.",
                      },
                    },
                    inputParameterIds: [
                      "e1cd6156-0b8e-429d-8d68-0bfc6a96f80e",
                      "f88f3460-ae29-4138-99c6-903a6070ad13",
                      "0b13ea84-1e80-4aa5-aca3-a9e865627fc1",
                      "f6318c72-1088-4daa-a6dc-0135a56dec50",
                      "0d65a991-c994-42fb-a8d2-9820017854b3",
                      "ab33ca2e-bb18-44fa-a495-9a31f1c57505",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.14 Franchises",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus den Aktivitäten von Franchise-Unternehmen, die unter dem Markennamen des Unternehmens operieren.",
                      },
                    },
                    inputParameterIds: ["d20440f6-1dd3-42af-a9ff-e0cabe9095db"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.15 Investitionen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus den Investitionen des Unternehmens in andere Unternehmen oder Projekte.",
                      },
                    },
                    inputParameterIds: [
                      "c9c47511-8372-4db2-b323-e275f53d6e7b",
                      "d1769009-0d58-4eae-a637-40092b4197a3",
                      "32cd6d3d-21f4-4766-9f53-7a77d0305ffb",
                      "f8b68ec8-6d09-4c5f-9f18-74fb05737451",
                    ],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Überprüfen Sie im ersten Schritt, welche Scope-3-Kategorien für Sie relevant sind, anhand folgender Kriterien: Größe: Sie tragen erheblich zu den erwarteten Scope-3-Emissionen des Unternehmens bei (siehe Abschnitt 7.1 für Hinweise zur Verwendung von Schätzmethoden). Einfluss: Es gibt mögliche Emissionsreduktionen, die vom Unternehmen durchgeführt oder beeinflusst werden können (siehe Kasten 6.2). Risiko: Sie tragen zur Risikobelastung des Unternehmens bei (z. B. klimawandelbezogene Risiken wie finanzielle, regulatorische, Lieferketten-, Produkt- und Kundenrisiken, Rechtsstreitigkeiten und Reputationsrisiken) (siehe Tabelle 2.2). Stakeholder: Sie werden von wichtigen Stakeholdern als kritisch angesehen (z. B. Kunden, Lieferanten, Investoren oder die Zivilgesellschaft). Outsourcing: Es handelt sich um ausgelagerte Aktivitäten, die zuvor intern durchgeführt wurden, oder um Aktivitäten, die vom berichtenden Unternehmen ausgelagert wurden und die typischerweise intern von anderen Unternehmen in der Branche des berichtenden Unternehmens durchgeführt werden. Branchenspezifische Leitlinien: Sie wurden in branchenspezifischen Leitlinien als bedeutend identifiziert. Sonstiges: Sie erfüllen zusätzliche vom Unternehmen oder der Branche entwickelte Kriterien zur Bestimmung der Relevanz.",
                  },
                },
                inputParameterIds: [],
              },
            ],
            description: {
              defaultLanguage: "de",
              translationsPerLanguage: {
                de: "Greenhouse Gas Protocol Standard Template",
              },
            },
            inputParameterIds: [],
          },
        },
      },
    ],
  },
  {
    id: "fecb4d64-8eba-4799-bbcd-78f271ce4fce",
    name: "Geschäftsjahr 2023",
    startDate: "2022-12-31T23:00:00.000Z",
    endDate: "2023-12-31T22:59:59.999Z",
    recordingStructures: [
      {
        id: "1d74a697-766d-46c6-b0d1-fd258e7839c4",
        name: {
          defaultLanguage: "de",
          translationsPerLanguage: {
            de: "GHG Protocol UBA",
          },
        },
        description: {
          defaultLanguage: "de",
          translationsPerLanguage: {
            de: "Greenhouse Gas Protocol",
          },
        },
        categoryIdentifier: "greenhouse_gas_emission_ghg_protocol",
        icon: "mdi:molecule-co2",
        evaluations: ["emissions"],
        recordingModelId: "2d332330-536b-40f3-bd87-73c6d1c6e7e6",
        recordingModelOrder: 3,
        templateUsedId: "f5f0af36-bbbe-4c52-b3d7-1e69bcd71ea6",
        structure: {
          type: "thg",
          rootSustainabilityCategory: {
            name: {
              defaultLanguage: "de",
              translationsPerLanguage: {
                de: "GHG Protocol",
              },
            },
            children: [
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 1: Direkte Emissionen",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Stationäre Verbrennung",
                      },
                    },
                    children: [
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Fossile Brennstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "963ac837-a1f4-427a-8cc8-674283a67597",
                          "0c66edb4-773d-4cf0-81be-0a09f23abc79",
                          "04278cda-f48f-49cc-9c05-e6e0fad929ba",
                          "a86faf89-1664-4607-8b8e-a20a6deef5a3",
                          "9603c431-61b8-4758-bc10-781c9fd9a541",
                          "40a86ce1-990c-4aa9-8e2c-60b0ffc478c2",
                          "e3c2797d-b591-49e3-b3e8-84d94647f01e",
                          "877c756b-bc95-406e-90c6-40b0b7537dfa",
                          "d71f519b-5526-4ef5-a4a8-e81ff178aeef",
                          "64b3281d-2c8b-4c0a-a9f4-21ef18c819ee",
                          "8974fd6b-f692-4748-be17-d8dfadb07627",
                          "16c0c9f4-58fc-482e-91e2-7565cd151ddd",
                          "dc3a9fa7-a708-49eb-a40a-f46ed3ecb935",
                          "0b827966-8609-4e36-ba06-119d0274e5fb",
                          "3057c8e4-072c-4723-80f2-0e9c3a10188f",
                          "918bb55c-5e22-46f9-8db3-4e87a74d019f",
                          "df22bfbb-b5fc-4dcf-a290-541d5a665184",
                          "89621629-e887-4d21-bdd2-0465d0c0c4f6",
                          "82042809-bfda-46b7-93f5-e90db219cd34",
                          "a5cf9f96-b837-4400-87bc-b21f60ce983d",
                          "43803b02-7ccb-41ad-93ef-b675544f1225",
                          "54ca7d7d-74bc-400b-805f-7c93a7ef6ae5",
                          "bc4868ed-f4fc-4c2a-a3f7-fe4a23e9f9bd",
                          "edf846af-b9d2-4ee0-91b7-2aee2a7731b1",
                          "7ca6ad70-610e-4348-a870-e1c8e96a4016",
                          "74f17ea9-a921-407d-bf24-731a0d24c29d",
                          "84818f5b-4bc7-4811-9f16-211009c3d814",
                          "91162400-1a13-4925-92ce-de68905e1e9f",
                          "bb5f8c63-aed6-47b6-b155-c85d8db87eef",
                          "a427aaf3-2afa-41c3-bbe1-e3dec0441967",
                          "bf853edf-69e7-4009-a459-7e414c68fe3b",
                          "c3bf9c35-f100-4230-82ff-bae1ef9d2950",
                          "60ad8eff-e80d-428c-b1fa-bc5cc2190043",
                          "47e626f1-05df-40ce-bbec-b66b458b0855",
                          "c1fc97c3-ac90-45d7-8f27-2ded7b70e029",
                          "07b1568f-1114-4d34-b68b-f7c6f149f410",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Biogene Brennstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "1f95656c-ed0d-4ab5-a04f-f4ed31d34a72",
                          "0036c983-6efd-4c15-bb20-4240d5f2adc9",
                          "495e39c7-d98a-4158-9c23-bb66d86979b0",
                          "487f09e3-7709-40df-af9f-8e28c0252487",
                          "35075999-3e45-464c-bea1-1f2d3ebf94bc",
                          "c3bcaf16-99d9-4b0b-a20c-09f8f73db104",
                          "93ac4cf1-5bd9-4c66-8f30-7b3fe4fb5427",
                          "b3ce26f9-ab46-4c5c-ae74-0d420aad6032",
                          "20c84611-e3ba-4fc5-8331-a7cf93f0ea43",
                          "a11745b7-463c-459b-95a2-36b31a23c891",
                          "5605efa1-b12d-4fdb-8b79-f82eb7b763ed",
                          "73a9d36c-686e-4331-9aec-b8667d302e39",
                          "4372986a-d9b4-4f9c-94d6-00f0022505f1",
                          "ddea1217-01ac-42ed-8a4c-0f5b4378e5a4",
                          "ab519de2-5226-4cdf-bcdd-54062f8b8a8b",
                          "539395fe-022a-40bb-a0dc-33651a39abcb",
                          "987f4184-a6ff-4664-8556-0ccd1f4fb732",
                          "cf716fcc-d80e-4786-aeb3-a5f1df10b5fb",
                          "b54583d2-ac5b-4805-a493-bc687b1baf7a",
                          "28ed291d-18bd-4538-b408-c51895e803a1",
                          "669dc668-1dd9-438f-8606-0229ba06cb70",
                          "e03f3877-1e6a-45d2-b3e2-381d00f7280e",
                          "c7c91256-f325-4a49-90a5-241e8186e006",
                          "3d53938e-1fbe-4504-a3cc-8e2494a40db1",
                          "012b5657-e91a-4429-bcaa-384501e6f1e4",
                        ],
                      },
                    ],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Verbrennung in stationären Anlagen wie Heizkesseln, Öfen und Turbinen",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Mobile Verbrennung",
                      },
                    },
                    children: [
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Personenfahrzeuge",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "978307f4-4603-4deb-aa5e-f8361eee39ed",
                          "7a652db3-ab66-498c-97a9-1e7c2bdba9a8",
                          "792f9fb3-f90c-4248-a694-47369cd8fffc",
                          "d11bad42-2de0-4e00-94f5-3583c996b6bf",
                          "859becfe-7a1b-4dcd-8687-d4d71e211434",
                          "572cf040-5e4d-4c92-b596-51b322e97473",
                          "b9a53bda-f2e2-4b4e-baa4-8c70a9b373e9",
                          "23925f22-d3b0-4f06-950b-21444a7da1df",
                          "a1e342b4-1bca-4895-8783-509350ecab5e",
                          "16df488e-6e5e-4c4a-9bf0-b91d11c11e87",
                          "bbf1d8c7-ff12-44c3-a48b-025e312f5e17",
                          "4b068b06-5058-46b8-85a2-ba62c71f4d45",
                          "35c3085a-819e-43ee-b51d-d102d04a5477",
                          "81ac9535-93a5-438b-82ad-f37e707cdb27",
                          "de2587da-d684-4046-ab09-162d72c3a0e7",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Transportfahrzeuge",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "4b10024d-f75d-44d4-b196-f819ed174f6e",
                          "a0e71fc0-7345-4bdc-8c8a-32d340239d79",
                          "5c11f8e4-77ca-4973-92d6-dd2402c7a89d",
                          "46e26ea6-405e-4020-9c2c-2e95adce5b68",
                          "38a3029a-2f4c-4f53-ac99-bea12c76fe50",
                          "03dfe860-dbc5-4c79-ba7a-bb35ff5fb58b",
                          "4b96f2ce-ca75-4d4e-ad47-52a51b82bb06",
                          "079e3bee-b30b-4c76-8ca4-1a9b53016e6f",
                          "226eb42b-862b-454e-b4b2-696e7cb7e00f",
                          "94017694-c1dd-4935-bf88-a9d527bc1964",
                          "5fc4179c-03cf-44c9-88b5-3c7ad0c5f5da",
                          "eca02d4a-de5d-4c95-b894-39eb42bb8d3c",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Weitere mobile Brenstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "42fd1d42-e7b9-4cb3-807c-ebcdc1afe289",
                          "9b66b91b-5bc3-49a9-97ab-5e06eb14bc02",
                          "a5d5bdba-d266-4331-a5a7-26a5b5a9199f",
                          "a54c926d-567e-4518-857b-db985f099073",
                          "583e2f92-4249-4dc7-b3a8-5a549ddf798f",
                          "ad2346a3-2ad3-435d-9655-78030d666061",
                          "85e3c9b8-d153-415d-959f-bc0de5050c89",
                          "c201f823-02bb-44da-a622-c3f500b85fc7",
                          "e021a059-fb6b-44c1-82c2-c90d34970774",
                          "9f33ac54-8941-463f-92d6-6ccfe52279bf",
                          "2ce65cb6-f121-40a6-b224-344a6e279b89",
                          "115448f6-729f-468b-bd97-21053323b077",
                          "f9bdd31d-6302-4453-aad9-3ed3563ca89b",
                          "51684f7a-7e01-448f-a8cf-7948901bc1ac",
                          "4309d6e0-ac2f-4a18-a222-39c32c83696d",
                          "bd32d4bf-5074-4a77-8840-ebfad53826de",
                          "25547fd9-05df-437e-8889-aa45b5bce6c7",
                          "01b205f0-adc8-45e7-81ff-d482b7e7bee1",
                          "5ed36147-fd60-4e8c-a840-7014942091a6",
                          "f4cfc3cb-176b-4b51-818a-5558b48affd9",
                          "980c46bb-b3e2-47da-91de-2bd0c79ff74f",
                          "4f600211-c360-40d1-aff3-560b196bc36c",
                          "440036a9-4b20-463c-9916-d24416519dde",
                          "9798a7fe-f0f0-494e-bd83-20e69c4cdd4d",
                          "b4a5dc07-14b2-4db8-80eb-c72a2c17c4e8",
                          "be7d5077-0d61-4891-b860-048bbfc35efa",
                          "e64308a7-622e-45d8-ad41-f7017c3fc521",
                          "230c9ee6-1415-4ff4-9519-0a8435d56f9c",
                          "99de7186-ff28-409d-9ef4-ec00ded2cabb",
                        ],
                      },
                    ],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Zu den Emissionen aus der Verbrennung von Kraftstoffen in mobilen Quellen gehören Fahrzeuge, die auf der Straße für den Transport von Mitarbeitern oder Verteilerfahrzeugen eingesetzt werden, sowie Geländefahrzeuge, Motoren und Geräte, die für viele andere Zwecke verwendet werden. Geräte, die sich nicht aus eigener Kraft fortbewegen, sondern von Ort zu Ort transportiert werden können (z. B. ein Notstromaggregat), werden als stationäre (nicht mobile) Verbrennungsquelle definiert.",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Flüchtige Emissionen aus Klimaanlagen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Kältemittel wie Ammoniak, Propan und Isobutan sind keine Treibhausgase (THG) und sind daher nicht zu erfassen. Alle THG-verursachenden Kältemittel, die Sie berücksichten müssen, sind in der Konfiguration gelistet. Achten Sie dabei insbesonds auf Ihre Kühltransporte, industrielle Prozesskühlungen, Kühlhäuser und mobile Klimaanlagen.",
                      },
                    },
                    inputParameterIds: [
                      "c822c253-5bb9-4b0f-8184-522296269f51",
                      "8237c24f-1acc-4450-a235-37af6ba54b14",
                      "5afcd7fe-16b5-4aa5-8aad-ffe81550ca94",
                      "b9f0a519-470e-4e33-b5b8-97800d843cbd",
                      "46e66f4c-f3aa-4fc4-9b26-881c08500c3b",
                      "f854c6ff-025f-4507-8fb5-e115fe35adeb",
                      "ccf54ce7-95c7-4444-ba15-9b7bbade0ebc",
                      "3c11157b-bea5-4712-8292-e8852550a66d",
                      "667a8c68-77eb-4c6a-ac6b-aff943da3cca",
                      "29dea9f9-47e5-49a9-9f2c-3597ff28f07f",
                      "70d6ffe5-024d-46f8-a305-17de9dc7c61e",
                      "b4a9a5b1-c2e0-4359-96a8-8c9de5ff9818",
                      "30648fe3-d295-480c-91ab-fe5de1c106ea",
                      "50f9b984-4eb5-43a8-9d0d-1ef02571e388",
                      "975d6ea1-a139-4a04-ace8-92318f498a6e",
                      "298fdcd3-18e3-4c23-b302-140c76518e9b",
                      "5c2be643-bdea-4d76-b022-4fc2c88dd509",
                      "342882ff-840c-476c-a559-4d860db72863",
                      "5daf827c-524d-460f-b0d5-322b45158540",
                      "59de4c4d-cc39-4e0b-afcf-600ba3705eeb",
                      "698f485f-fc49-4d87-bd03-5d399e35c386",
                      "16d3bf22-823f-43b9-bed5-3039a518cf02",
                      "743e8e78-3979-431c-8922-6d207bab8a13",
                      "21422704-04f1-448d-b1af-aff21c646178",
                      "f1bc55c1-c246-4e9b-860e-142d591b2883",
                      "e841eb00-7f62-421d-9c75-5ef6d0f6e9ce",
                      "e55d619b-1759-4627-bb3d-7cbd85bf8eb3",
                      "5e8bb18a-19ba-4cde-a91b-4cf12eee2fff",
                      "4098f9c7-8448-4227-ab2f-af4f23402710",
                      "cb3a08bf-1cec-459f-9b21-1b963185c02b",
                      "f43bfa15-8da0-41fe-829c-4f364127d903",
                      "868de89a-44c4-4c94-8197-72afdb246e8f",
                      "ecedd6da-e896-49f4-a192-65af9b1dbb97",
                      "64131f39-7162-42a7-8908-afcaa74ec0a0",
                      "d085428a-6766-4f79-8126-238ef1c5f06d",
                      "b11c749f-757f-49d2-9858-a8e6d672f634",
                      "9d85366e-3bc2-4aab-ba95-ec47bd09a677",
                      "f6db7387-8aa3-4a23-bbda-3a9fcc5888e6",
                      "695668c7-ddcb-4a7c-ab3a-94000e494b3e",
                      "75e2145b-4144-4438-95b9-9d1cbfa3cbdf",
                      "13fc4205-fa0f-49a3-b53c-ad006388e0c4",
                      "79608b93-f422-4763-a539-0d87aef66206",
                      "c5210d5d-cc92-47aa-9b3c-a72871876d59",
                      "7779a624-2577-4135-a3d5-847edc34c25d",
                      "549d48de-9ce1-41f8-bac9-86f36b02b090",
                      "a3128bbc-14cd-4d1f-b54c-a0b7034eb7b0",
                      "f525c38d-663a-448b-b4e5-914428adf360",
                      "2530c324-35a1-4c7e-b437-f34cca41e882",
                      "9246f3cf-9663-4fac-ba8a-fc26d27d2439",
                      "0b072a5c-40ab-4426-a789-e5ae2db5e15c",
                      "5a45753b-7700-458a-9e1b-59ae719b5139",
                      "3c84ea77-03d1-4d41-8e72-3192a4ee8b69",
                      "b8275279-a45b-4583-be8f-e3bb3e5fb4f9",
                      "11a7dab0-d552-4fdb-baba-358897bbc466",
                      "dde47fef-779e-48d4-b0a3-45ae15df09e9",
                      "3f0a8814-8791-49b0-bbaf-69e6a0f328d3",
                      "d2504f12-9df0-4f35-a436-43ceb1984aa2",
                      "d1afb3f0-b37f-4cfc-8928-f581d5102cf7",
                      "56795b06-52b3-4114-b21a-3f52dafb757a",
                      "085173ae-6cd8-4bbc-9d3f-5ff7d30447bf",
                      "af532e57-5e88-4330-a9de-39f6b2163a32",
                      "5b5714ca-6f73-423a-adb2-74d97a324540",
                      "f3df3872-8a88-43c4-968e-209e13a7a8ed",
                      "c4a96062-96c7-4d02-817c-13aa2e09565f",
                      "bfa44e16-12d4-4fa5-bef7-faa1ab785695",
                      "72eb8011-6e87-480f-b855-851569e8d6af",
                      "9ace7de5-9e93-42fc-89ae-9933ba7252d9",
                      "368e9cd9-cdd3-4aca-8ff5-410460cefa23",
                      "5ecaf87b-22cc-4db6-b89a-8977975ca630",
                      "a5d5c673-5179-40be-b3cf-b45b81f0b164",
                      "d4dd518f-8cf3-4713-8956-73f52b371ae9",
                      "4b9d787c-5d33-4efc-8695-31a36298db39",
                      "81bfa2db-5f5b-4d4d-8016-591fe8584b5e",
                      "c232ba84-f24c-4bb6-921e-53811d820bca",
                      "aee3c18e-b587-4b4f-a749-f2703cfc0d58",
                      "3d813225-f690-4cc6-bd92-656a876bbeea",
                      "d0f2f645-7627-4495-b534-cee39f4eb23b",
                      "4033637f-dd4f-4b27-b849-003211849d99",
                      "86683fe2-3252-4fe2-a2e1-df331f0b27f7",
                      "0a6a8d87-2002-4884-b2f8-a16e4eb24165",
                      "4140b88a-8bb1-4136-97f7-3795b00e3a84",
                      "c464d20d-4e73-4c37-a665-49ace3eb4c51",
                      "3b0968cc-eaa2-4f5c-a96a-54808f6c115d",
                      "4efbc185-6eec-4ba8-8f7e-ba2ca8c44494",
                      "f08e8180-9256-4c14-8919-dc3fd30c386d",
                      "bc3f854d-1465-4269-a66a-9ee6e4234439",
                      "b0c58015-6dad-4b36-9590-55451a8aa294",
                      "c73ef9be-6b3a-446c-ac4f-1c371f2650d3",
                      "090dac24-9765-4c29-945d-c82cdec7499d",
                      "0a6fa522-624d-42f1-9d0f-4c78254c592d",
                      "c3b5b26a-104b-4289-bd1c-9e198329bb65",
                      "91e973d0-2018-4374-aa4c-0f9885444919",
                      "9aa9b0d5-3e04-4604-bb9a-31f0d728b0d9",
                      "b663cb1d-409d-4a8d-86b5-99d5919308f2",
                      "72d524e1-9dde-4a2b-9264-c1eddee43494",
                      "38e0581c-640c-4786-a6a5-95465e648547",
                      "2291122b-aaa4-4ee7-8821-81976a235bca",
                      "926dcf8d-c590-4b8c-8c09-dc132d66ca14",
                      "be0f7e16-dad4-4d63-8ecb-68058eb16590",
                      "914f311f-06e6-4221-8849-f95218e1d68a",
                      "a8556704-afcb-41b2-8580-f21f8c451fa3",
                      "8be847f0-9182-4fea-8dcc-c50d4922b058",
                      "6320b178-5d8f-4254-87c0-59e467d07272",
                      "5391dcd0-bf45-4278-a08d-6eaff98819e1",
                      "0a2f0654-a78b-4b3d-a543-d444bafe4899",
                      "18154487-54de-4363-9ce6-b3c4f807c32c",
                      "d33783f5-6df4-43aa-9c3c-b1fe11eb9c8e",
                      "6fdf1ce0-4e84-4055-a3b2-8ef3a202dde9",
                      "aee7328e-7fb2-41ad-a333-63d0bf8193e5",
                      "b5ed824c-cf1f-4239-a9a5-c7553227e961",
                      "9191d860-2dcc-4ca9-8e4b-ac433e2e9896",
                      "6fe858ed-50d9-4f54-8fd2-30002b4917b3",
                      "98be8dbd-2c6b-4aa5-8633-1436aaf01486",
                      "7ab2335a-cd04-4158-87fe-218f599302c7",
                      "78d7a79a-311e-471a-ab74-7e8df9806a92",
                      "f906f190-75aa-48aa-9a8f-5bb2cfd793f2",
                      "18c24912-ce08-40b8-9413-27114bdeacdb",
                      "9b564d2e-72cc-4aff-a4fe-0949bda5f6e1",
                      "1fb574a2-c47f-4e03-8a46-f498f79f27d6",
                      "8e37446c-8289-4612-8ae2-1147076aa97f",
                      "3f65f1f3-3337-4dc2-b88c-ef971267ee35",
                      "8c573276-5133-472f-98b2-cfa7f52ead81",
                      "c8b53c12-6866-4711-819a-902f82974a4c",
                      "78fe0a16-4084-405d-ba72-699523d73d59",
                      "4103aa38-aeb0-4efc-bc94-1ac207860528",
                      "38968114-90c8-46af-9b8b-5521c4aa8266",
                      "863cf9a6-e88c-4979-9396-a15f98c485e6",
                      "1e64dab6-4eb7-4ed1-b0dc-cfe0f1c1bc30",
                      "307d02f2-b614-41c5-8185-1e2c690b34e1",
                      "d8d4d88f-fe07-4ae6-a0ed-90ec1d5b0ef7",
                      "7876f81d-9ba0-44ad-9c96-aaf30c38d987",
                      "b763caf6-d965-4c20-9e38-541664cb207f",
                      "b026561b-c957-4acb-9668-d616a0f82276",
                      "d045277f-2d7e-40a0-964c-eaf62f0ed6a2",
                      "bf041ec7-1306-4b2b-95be-05799c3c60cb",
                      "c33aef4d-7fe8-4b4e-bffd-43773236a1fb",
                      "cf0116d7-4526-46c3-b468-54edd98840be",
                      "25d82b01-20c6-422e-8b9b-74b5b2d9d0d3",
                      "8dc0d56a-f3e8-4cc3-b228-24c370408cc6",
                      "f3fb8186-a903-4c6c-880d-73c16c2c0e3d",
                      "fec6b844-3f80-4e3a-9c82-43f636d15d45",
                      "2b19d150-2ae3-474d-bf88-4b14d7fe1db4",
                      "341c73a6-fedd-4aab-bf6d-155cd6d3764e",
                      "a9d74704-b20e-4b10-89eb-6cdfba61557c",
                      "57d427d9-8e7f-46bd-a0ce-727d2e5dc4fe",
                      "0caacf4a-2f38-47d1-8753-9a972b3bcf80",
                      "0d237170-71f8-4753-a21c-42a1decc7d78",
                      "7741defe-4d78-4393-836c-acfad7d9fa1c",
                      "42a0224a-d9e4-42a6-9931-d082df263b83",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Sonstige Emissionen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus chemischen Prozessen oder anderen betriebseigenen Quellen",
                      },
                    },
                    inputParameterIds: ["5b046ace-45f5-4f33-b5e4-f2c47206a463"],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Emissionen, die direkt aus Quellen stammen, die im Besitz oder unter der Kontrolle des Unternehmens sind.",
                  },
                },
                inputParameterIds: [],
              },
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 2: Energiezukauf (indirekte Emissionen)",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufter Strom - standortbasiert",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die auf dem durchschnittlichen Energiemix des Standorts basieren, an dem der Strom verbraucht wird.",
                      },
                    },
                    inputParameterIds: [
                      "97068089-3e05-41bf-b4a1-131d49e0c204",
                      "2bdf091c-85cb-4988-bc3f-c43b9e7f5465",
                      "5fa29a55-4c77-4811-9c04-1cbbda74c6f9",
                      "4f0405b9-72f4-47a4-9d62-16669f41ff18",
                      "346bcddb-1f60-4b94-85d7-53f1fa961461",
                      "ff80d653-2b20-441f-9ee8-4748b73c6130",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufter Strom - marktbasiert",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die auf spezifischen Verträgen und Herkunftsnachweisen für den eingekauften Strom basieren.",
                      },
                    },
                    inputParameterIds: [
                      "62b10a72-373a-4066-a8f9-9f8285c17225",
                      "72cffb4f-323c-4f4a-a346-3c029035ce36",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufte Kälte, Wärme und Dampf",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Erzeugung von eingekaufter Kälte, Wärme und Dampf.",
                      },
                    },
                    inputParameterIds: [
                      "e8b00818-6fa8-45e0-a2d9-3195c17b3920",
                      "83a20bea-b688-43d3-9890-9692eabf95ef",
                      "827f1d46-24c6-438d-bf4d-f73d1b84fb40",
                      "56db0842-4f8b-45b7-9872-6bd3643f014f",
                      "e5198c5a-de60-4612-9ba7-442cc1acfd20",
                      "896c554a-dd1f-40a6-976d-4c64e40acb02",
                      "c2c8d744-b676-4a73-b52b-0344e54e2ee4",
                      "1b4014f8-a231-4535-ba22-fd5491b6be1c",
                      "69986d2b-fd66-4d18-af7a-c479aec373dc",
                      "e16e23e1-99d4-44af-a15c-b33474195e96",
                      "1aa067a8-68ea-4ecc-9511-40becf9df863",
                      "f2a6a630-c524-44e1-a9ca-de31564ccb08",
                      "c7bd0669-3f27-4c2a-951f-09c2ff0575d0",
                      "9c86ce5e-6b26-46c4-a267-8ae1df73084b",
                      "22634f5c-db46-40c7-9854-fda3546c40c4",
                      "049dcaf9-127a-4d30-9daa-4a13115ab6fc",
                      "5429e5e2-e47b-451b-88ac-2f36173bdacd",
                      "9e44acc0-770c-430f-9d9d-f40af47b0144",
                      "ef7d3c83-299e-4fb2-aabf-2c230b620f8e",
                      "505b2354-1fd5-4f73-a65d-20cd8d96067a",
                      "3bcc3821-0005-468c-969a-993554792d48",
                      "05d5ddc7-f366-48c6-b3a5-d4aa885c361d",
                      "594b5bd6-b95e-4561-bebf-8821cc0b4fe3",
                      "88758a0b-d8b1-4875-8cb7-6e50dabfc827",
                      "bf908071-1b42-45c9-86e0-1f2c160b3b1b",
                    ],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Emissionen aus der Erzeugung von eingekaufter Energie (Strom, Wärme, Kälte, Dampf), die vom Unternehmen verbraucht wird.",
                  },
                },
                inputParameterIds: [],
              },
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 3: Wertschöpfungskette (indirekte Emissionen)",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.1 Eingekaufte Waren und Dienstleistungen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Produktion und Lieferung von eingekauften Waren und Dienstleistungen.",
                      },
                    },
                    inputParameterIds: [
                      "180c4872-d274-401f-9f56-2d5826115133",
                      "2cbfb58f-9918-4c68-a81b-1a4ae4cabae9",
                      "48d06887-e72c-416c-bcd7-9267a4182c15",
                      "02b04e95-8f6d-45f1-9297-83c2016a03fe",
                      "650dea1e-4cd7-459e-ad8b-2a2a367e8971",
                      "fc065320-57c4-4be6-a8aa-86371ba28615",
                      "4b946e54-98bd-4c14-8882-c50d38f11653",
                      "8bffad10-aa13-4498-837c-9aff6081ec52",
                      "0f3106d6-77e6-4720-9396-a656737c5e97",
                      "280a11de-4a40-44c6-bc3e-15e9c39ee0f1",
                      "08f40fb9-9fe0-4cea-9daa-19030f307eaa",
                      "ff65ea42-9cac-4dc9-8432-53519836ead0",
                      "92a3a754-9ecb-4613-bd3a-0b70357029ce",
                      "15d45b79-2c99-4c0a-93b4-46252dbcc22f",
                      "8d568edc-5397-4a37-b8c9-1428d2c209f3",
                      "c63dc044-b0c3-434c-ad9c-cd1518e9b6af",
                      "17b0e21c-39d5-4690-9293-fe40f3c36e23",
                      "96dc9c74-57c2-4bd5-afaf-48135eaeff93",
                      "ca7b2917-148d-4a30-ae1d-aca2500f3fb3",
                      "26bff6a7-e248-47e3-b5e8-966af16c6e99",
                      "98003c47-61f8-430d-8867-7c4f5a2ebe00",
                      "8c26aa1c-addd-4fae-a7c9-530719335092",
                      "0fbfe8ab-29ef-4d36-ba2a-17d210c53ae0",
                      "9b56b6d8-6286-4a9a-aaa9-5214b8888498",
                      "883e4d31-370e-4658-bcf0-41a094303a8c",
                      "c994586b-ca62-4e06-944a-7373a526811e",
                      "99a73b95-ec31-4ecc-ac6d-4d336f70dc36",
                      "19c5d0bb-4979-4613-b35f-49164aa6e80b",
                      "386eec66-a472-4637-9c5b-8facc104a3e3",
                      "72d37936-4cab-4060-a0a8-002427686322",
                      "8e70de0a-bdba-4a99-a7ee-ca2748521cf7",
                      "2e95c2e6-067c-4a51-b129-94827328230e",
                      "33590901-1b65-454a-bb7d-c1623497069c",
                      "d62e72b4-fa98-41e8-a078-463ea6f87cbd",
                      "b37ccfb5-d8ea-4077-bd94-8fc457303675",
                      "7df87be0-7c4a-4222-8a35-2f729a216242",
                      "ebf2c9ab-be84-4762-b6e5-24e0793cfe76",
                      "4e8e0f46-1e50-4600-b873-9c122027e8fa",
                      "30a8bc99-27b2-4f44-ae90-a6cb417f32e8",
                      "746479d3-2d09-412f-9c02-1eba47dc187b",
                      "3b97f047-326e-4a6b-870a-698d1e6f66c1",
                      "b6877a38-35ce-4df5-8b47-73fd2221f7c7",
                      "428d9d78-0bee-4cf3-9484-873c611803fc",
                      "e183fb49-81dc-4a1c-8a7b-7522d6c91742",
                      "e48aacca-7138-4f84-ad2d-61489fd25783",
                      "d83b1f7c-3d10-4cdd-9baa-04830b21842d",
                      "06df4c59-c3aa-4c7b-9371-3119c3e77cb5",
                      "fb57761e-65b4-4c8b-84ad-1e3e57b2a55e",
                      "a5106eef-57d2-41e8-bb04-d231b7de7ba0",
                      "f590d2d7-7b2c-43de-8cad-888f84aaa169",
                      "f0b3811c-97ab-41ca-a9f7-3abb537ca769",
                      "4a086141-f7ba-4ea6-9117-521bb1ea0410",
                      "1652a1b0-cb40-4293-be83-888d6f368fd1",
                      "ca947fd2-0402-40ea-a448-a239c6fbd55d",
                      "cbfebf30-90f5-427e-b107-960d87e163fe",
                      "16bde8d3-416b-4d31-9a69-238282924e2a",
                      "e20cee7e-f66e-425a-a02e-04b8d3da9e27",
                      "5d545deb-e1c1-4f0b-85df-097ed4ef81fb",
                      "f5bf90fe-88a7-4cff-adee-005b70efc4c7",
                      "2a414044-ab96-4761-9765-46791df8482c",
                      "79cb2088-7cff-485f-83e0-acf5823ab313",
                      "e4dbb674-b026-4a91-9866-76520a7472a3",
                      "74bfd23c-a332-4549-ba9a-b2dc54060842",
                      "c01f0dfb-7c95-47ca-8091-7c569bffc08b",
                      "0d819383-830f-453b-bb1e-ab05d0c611de",
                      "f23e7792-eb1f-46dc-971b-c1fb1bcbbb18",
                      "a45ee059-7107-44a3-b7c8-74da1d8b9015",
                      "4b07af94-ad02-40b8-9dee-93f590ae6c35",
                      "58f12cc3-af32-4c3f-85cb-490d2be6e5d8",
                      "f24846c5-436e-4c6c-aa38-b625fb6fe370",
                      "1d1ce330-0c51-4512-b471-107bda710125",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.2 Kapitalgüter",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Herstellung und dem Transport von langlebigen Gütern und Infrastruktur, die vom Unternehmen genutzt werden.",
                      },
                    },
                    inputParameterIds: [
                      "1ce121bb-c8da-49ab-9788-d937b5c61816",
                      "1325d6bb-7505-4648-b13f-7579ed0c2f44",
                      "d1c27dcb-cea1-4cc9-b306-cca6aa44c2ef",
                      "7e9a83e9-abc1-43fb-a8fb-77dae0649fc5",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.3 Brennstoff- und energiebezogene Aktivitäten (nicht in Scope 1 oder Scope 2 enthalten)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Gewinnung, Produktion und dem Transport von Brennstoffen und Energie, die nicht in Scope 1 oder Scope 2 erfasst sind.",
                      },
                    },
                    inputParameterIds: [
                      "a97d59c2-d877-4b08-ad3d-142e124186ba",
                      "e5924001-ac81-4b3c-87af-18e5e713ba25",
                      "98020e65-d848-4f99-b387-29ef695c5b15",
                      "34dafd31-29d1-4b81-a5d2-4694390ebd5f",
                      "78732ac0-83b3-48c8-929a-c0fecaaa2dc3",
                      "a1e3f403-c0aa-4ac6-8d1f-af7b70468ea4",
                      "828ff153-016e-44af-bdb3-0da359606ffa",
                      "95a8df84-4aba-4786-9246-ef85d4006e1c",
                      "73b8e443-a0b2-4355-b22b-8cc151bacf72",
                      "00553e49-23b0-426b-be86-7c4f2692ecbf",
                      "022b7bc3-d0d9-432e-95f3-ea070e2f053d",
                      "4f22cb6d-1533-4add-aa9a-45650d54efe4",
                      "79e6bceb-5dfe-47f6-9ce2-f03309912ed1",
                      "2f0e3248-e645-4934-80eb-857547023dd2",
                      "713369c5-2837-451d-851b-c8bb39ba40c3",
                      "b98cb44a-eb1b-4a3d-8c72-413b2a293a5c",
                      "27bc9114-3e8f-4544-9824-2eaebac7ed36",
                      "5b077e73-b2cf-49a0-8dc5-a79aa51cba63",
                      "6db92786-5f65-4d00-8114-688b0ef9c5f7",
                      "5a8633f3-ac90-4ab3-8f53-52d3e647cba2",
                      "fc93edd2-40b1-4d51-87f7-fc5303aebe91",
                      "a307eed4-f66a-4409-bd9f-484b3b6f0e76",
                      "c447d2a7-366c-411b-9dc3-dc4855c07ac0",
                      "e1cb835f-fc90-49de-81a0-30161824936b",
                      "2eb2687d-bffd-418f-8886-f032e13423f5",
                      "3b0ed2ea-6700-44dd-bd88-692d1c335222",
                      "185ba86a-6c68-4af5-accc-4a6a57ea048c",
                      "e4c82383-c619-4ea4-a7e3-aa822e08657f",
                      "7d278772-1376-44a5-a9ff-813e5ec94fd0",
                      "1521686a-bfd6-429c-b79e-9815a7ff8e43",
                      "44f361d3-240b-4ee4-a86f-a4f670c8aa77",
                      "1dabbf3b-8866-4341-81ab-430ed4c3d16b",
                      "06610cb6-9337-4b50-bd4f-09eadaa102c2",
                      "87a01aa6-4803-4d16-a5a2-35d6d30d4c38",
                      "979e6c81-5898-423b-ba90-0d35e6f2be57",
                      "957e8545-0258-4413-be4f-c92fb167c36a",
                      "3ee72c5c-3a21-46f7-84a7-b1bc13ead437",
                      "04e4e910-0a23-44b6-8b78-f9d5f47f9d81",
                      "ec6fded4-6b37-4114-8c06-92546448f59e",
                      "625b2664-378d-4dc9-b317-9807d05a067c",
                      "63d0129c-c353-461f-9019-de6c6e0a2aa9",
                      "3c057dbf-f4de-46e2-ad21-124b2218a2eb",
                      "7fedb4e7-95a8-4f7a-9b85-7e7b518ab589",
                      "3663f5c3-27d4-47dc-9f6b-0961ed1d93b9",
                      "49383d88-471b-4cda-af04-1ce2ffb0f63a",
                      "e8a7f23d-fc12-41af-919e-77a971745160",
                      "75f38ded-c58f-4def-a385-ca0e3191654b",
                      "d21d2442-ddbf-4004-a768-1468b9f27a94",
                      "71ba280b-b890-43c5-8553-a5cf4546b1d3",
                      "57caeb36-2638-4bd9-8ebe-6435ca11a00f",
                      "d7205811-fe49-43cb-8260-85a61031fda6",
                      "d83924fe-273a-40b5-be31-f995c6f728bd",
                      "d8193c3b-37a4-4c0b-9cc9-58c9d54db5b0",
                      "550256b5-3ac9-4628-b3b8-7cfc47502bd1",
                      "43873d3c-e56f-4899-94d6-f9301d06c90e",
                      "dbc1bf05-be77-4891-b9ae-1dbac13cf833",
                      "258838cb-fe7a-4898-8d7e-5f70de691c28",
                      "79c87779-dc7a-4c78-a8a7-eb559dcf6ea8",
                      "fc85ddf7-4ba3-44cb-b1d9-615c227b5234",
                      "dbd35dd6-c8f2-4866-b232-bd912f51254c",
                      "d44d210a-9b7f-4e1b-ba80-057f8c752931",
                      "80bc41bc-a546-4512-8194-f21cf3026b3a",
                      "684ff436-a604-4b52-88c3-dba80e9c7e58",
                      "5a8932f1-5733-47a8-a9b2-c5671adee4f3",
                      "738556d2-d7fb-47e9-a95a-3e992b5c18d0",
                      "f4f4eecf-6a3c-4f8a-8e50-d372aa607056",
                      "30866d07-7a63-4b5b-a30b-69ef2f9db117",
                      "fb7ac0f6-4621-49b3-839f-efd9f33f3382",
                      "9027cb41-0d89-4af5-9285-38c78e4a69f7",
                      "993a6cf9-cab5-4d7d-8268-1172c31b534c",
                      "960d3032-772a-470c-9665-2c79f8de9860",
                      "0f8be108-48cb-4b5d-9dfd-29b71b981d55",
                      "321758b9-76e1-4950-98ad-61d1ec834b1c",
                      "dcac2600-a2fc-4614-b480-5cd0c28c0c0b",
                      "6c5fff53-615a-4bc3-8bbc-fb0ecfd517b4",
                      "9848ea19-ad18-4ab3-9f19-0a788342fd37",
                      "af947c63-5b29-4633-9f16-28d106485435",
                      "48306a81-28c2-4f5a-bd56-bf06012d34c2",
                      "772685dd-ddc8-4d74-bda3-c30cf1bc2cab",
                      "9542d8b8-4c82-43db-9186-7d71be1e2701",
                      "41eb1f0f-2216-401a-8c94-0713479de11a",
                      "a2dedc91-4b19-4b86-814b-38360e76338e",
                      "18f98678-9d5a-4e1a-a152-64b1f3e85aba",
                      "14778f38-b02c-43e8-bad8-84454985332d",
                      "6a851462-c98e-4511-8dc9-f72d3a77d4ad",
                      "5a51ccaa-9edb-4a1a-81e0-64878a66911e",
                      "c2540d94-0550-4635-8943-6c9b63d3a21d",
                      "8b911ce6-aa0a-4c82-9677-75c2d1b89c5d",
                      "0692ce4e-fada-48fd-bff1-69e807fe769c",
                      "ca7caa66-da68-4223-b8f7-5c3a6ef0d6b1",
                      "88ee1a5c-ef34-40ed-bd2c-8e57b85ea3ad",
                      "e43a0738-3c9b-4911-9837-cd3abba77674",
                      "a48fc419-ddec-48d4-b2f8-f504b70682fe",
                      "9ebac38e-81a3-4445-9e3f-1c0c479935e0",
                      "b160758c-25eb-4d6c-9f52-c8d196ca441b",
                      "6dd867aa-430c-459a-8711-40e8c45c9ed1",
                      "893c6ba4-cf30-49a3-b646-d613431a2b11",
                      "1bf4d0a7-2326-466a-8bf4-0107fe776cb6",
                      "77d1cf0b-6f8d-4983-9238-9e0a96439427",
                      "2545730a-8f24-434b-9162-f213794e901a",
                      "432cb1df-4ae1-407c-8ee0-0207a600a446",
                      "720934c2-fb6a-48cc-9588-b812a41087bd",
                      "07affb1d-91a3-4967-a647-52bf6e72d3fe",
                      "c12940b8-b720-425e-ab28-ecaf9f260f17",
                      "d0c0262c-2da6-429c-a495-910b6c536487",
                      "9256a3e2-54ea-4408-8269-9e853d42e792",
                      "caf4ee27-baf0-4fc9-a740-a07fb2ef5afa",
                      "47a82637-99bf-4846-a7a3-9f8ae0ce230f",
                      "7e98520f-9e54-4d57-b846-53e0a051568f",
                      "301661f5-a9ef-4f3c-a12a-47ce23a85122",
                      "166b3f0c-0617-4cf4-8298-04da9c2ba841",
                      "8a8f4e0a-db2a-4a98-bdc1-a3809ce4c100",
                      "3996b26c-7c0c-4071-b507-be852ba45a18",
                      "f42f8a5f-2184-4d31-9e8d-50a529681e30",
                      "f975096f-2914-4c30-956e-7fe261fff500",
                      "02ff232e-4503-4bb4-bc0b-363ef9c05862",
                      "3ae81068-f757-4f10-91e8-b6507b0e32eb",
                      "ce3f9c0d-fbf9-4f91-a9e5-89f0a2f39c54",
                      "b359c24c-17a7-4e92-a340-d57ebfb111df",
                      "10125a86-d6fb-4383-bb8f-f2aacbe4b6c1",
                      "d93c476d-4688-4b08-a1e4-5ca996966cdd",
                      "8170d133-4e71-4d02-99be-0730cfe9456e",
                      "ff42772e-65e5-4cfe-a218-820d5c065e43",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.4 Vorgelagerter Transport (Upstream)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem Transport und der Verteilung von Waren und Dienstleistungen vor dem Verkauf.",
                      },
                    },
                    inputParameterIds: [
                      "878141eb-5b98-44fb-b424-83685f9513e5",
                      "a6ee430a-af89-4811-b91a-8294f037b78b",
                      "ac5dba27-60a9-48e5-8279-c17603c6c24b",
                      "dd575672-1181-486f-85dd-7a7dd90db17a",
                      "c71a4cf6-4484-4abd-9aed-bd87ecb78c9f",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.5 Abfall aus dem Betrieb",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Entsorgung und Behandlung von Abfällen, die im Betrieb des Unternehmens anfallen.",
                      },
                    },
                    inputParameterIds: [
                      "cf8c8e62-69d5-470d-89d0-f797cac38f71",
                      "ede1be14-c446-43e0-a9cf-1534120a6bef",
                      "917294f8-8a67-45f7-957f-cb8f55058b4c",
                      "1ba5e74b-fea6-4274-b39e-864fb2cf9411",
                      "758fdc1d-cd2d-4000-90ba-bca26e96e807",
                      "dd112827-34dc-4a79-97b6-d81274a89789",
                      "0e62b989-9205-460c-b5db-2bcf86ead3d9",
                      "e84167db-f404-4dd6-a96e-8c3e219aef83",
                      "7171cdde-c25f-42d3-a287-3a663c017d9d",
                      "8fd3c63b-1306-44a1-a1fe-18cb945ad5ca",
                      "1a4537cb-1e33-49a8-9c0e-5cc2d1c6cc0c",
                      "a878083e-7912-4b1a-b020-8e8d93b94fd9",
                      "cba232ce-f4cf-415e-8745-04b652f601c1",
                      "cd264016-5640-4d1c-ad34-8476031148a5",
                      "4bf6ff89-1cbc-4058-902d-ada51e698409",
                      "fb2ff2a6-ba99-4280-a2bd-f7f654fbb6a0",
                      "57deb857-7310-4d45-ba9e-7bb2fe7a4bfe",
                      "7b5a1b85-1239-4735-9f59-f1d275cf8096",
                      "29d91608-0023-4d95-8690-1efd4f17ffb2",
                      "0879f5c0-3fbc-403f-93c4-410efd435cb2",
                      "68fb4104-56b5-48b5-b12d-b73b8a1bdb4f",
                      "dfd28aa0-c1da-48b5-b5c5-5ddaa73bc44a",
                      "2defabc9-e808-4a68-86d2-a961c792d16e",
                      "a775448d-73e2-4b1a-aa9f-fa04b790c980",
                      "c390d834-03a1-4d74-be9f-71af91da0fbd",
                      "e84d8857-4b4b-4275-bb82-b808c427bcee",
                      "8f0d8a6f-0ee8-45e4-a514-305b06e06c6d",
                      "e3214ce3-6757-497f-baeb-13084d7e81d4",
                      "5fc0b92e-f175-4991-a998-de454cd76a90",
                      "f7278080-8a11-4f8a-ae03-83f19d2d2e39",
                      "23a62e56-9c47-43e2-9c36-2edd48f1d57c",
                      "c38c0465-d652-4ad7-881a-36430af9b479",
                      "7e3b0e47-9075-4df2-83ce-ca0d7ff4911f",
                      "54aa00f7-ef19-4c60-9d2c-f815c439fa09",
                      "17f6459f-c763-4499-a56a-6ddaaa9ed6e0",
                      "d5e95462-ceb6-42df-99e7-6ab8281a2e29",
                      "5dcfaea8-10bf-4cc8-93b6-e970d633a7b6",
                      "3461ba5a-bbe3-4b26-911e-07ecacd05e2f",
                      "0b543d9e-45ac-4b72-a9f2-fbac251c09f5",
                      "965340f7-ab43-4982-a0a0-b3f73d8415e4",
                      "b55d26c5-59f3-4848-ad4d-9ee0a5949a49",
                      "ed916445-aefb-48b6-88a9-25e7f169d15c",
                      "0cfb6495-0e4c-48c7-bfca-0b85ff7c4574",
                      "a0e73e43-cb44-4540-9e6a-5aa3df24c71b",
                      "b9947e0b-ee36-4104-af91-a16eefd5a780",
                      "dc97606a-0272-4635-a65e-73b3aa6d7715",
                      "b089aed6-56e6-4eaf-9fd1-4def244a5f28",
                      "580a4e8a-f3b2-41ac-a0ac-c2f16ef330f2",
                      "da3df2fd-f025-464e-b2d7-a4923c303e75",
                      "6a961316-a9e7-458c-9f65-72864b303043",
                      "2d13e5f2-465c-421e-8893-f7b6c28b6627",
                      "8e9ab682-6334-4e91-a1cc-a421c7976ee2",
                      "46130e39-f2e8-428d-bc04-fafd90b871d4",
                      "9615a4a2-f3d5-4b5b-8bc7-b354aaa737bd",
                      "85577ca6-428f-44fc-a392-b34aae8b0966",
                      "6babe2ca-bbed-41f6-a444-427b52151c61",
                      "271b771d-3e8b-4df4-8f19-9ed61343bbb9",
                      "e72d7e10-eb73-416b-b579-cfad146e637b",
                      "3c719357-5a3a-411f-bb65-8c88b22330ca",
                      "dd5924ac-2403-4168-98f2-1ce36955ea5a",
                      "423e5ec8-e6f1-40e8-835b-946fcf53b9d0",
                      "1a6a9007-1f1c-4109-a77e-65a0fb12124e",
                      "409a1a3b-653e-4f59-b678-071895d30c84",
                      "99cbefd5-1880-47bf-9114-3f945225b7cf",
                      "c0af96fb-fd62-44b2-928e-b59fce78f8b4",
                      "1e0e8d73-730e-4ac1-a467-4b4b62a2972e",
                      "aae76bbc-0344-4a95-b26b-02150adf7db8",
                      "73036b9e-15c6-4eba-bfa0-ff9cb27fb370",
                      "6f7dd4e9-3bef-4a1c-bcde-b0176f88c8ad",
                      "8c0e1a9e-7cab-4248-ac68-4f32809d6b41",
                      "003dbea5-41e8-4cf9-9e4e-3351e0d2e94e",
                      "6f63a341-f7fa-4d8c-849b-be5481dd44e6",
                      "9c0e7618-1f67-4c1d-a079-7cf42b12fdaa",
                      "13aec58c-1b59-4e67-bf5a-d0ace408015a",
                      "cb1ae8a4-b304-45cc-827a-a0e7a1f89a7c",
                      "afabc0fb-b3fa-4a1a-9681-8653f14f6434",
                      "d6daf01a-4c0b-4d9d-a979-3bd6d66a4fa7",
                      "9d277ade-af6e-482f-b37c-539e213085e5",
                      "cea5310a-d08d-4cda-9536-0891895fbbfc",
                      "f9e40396-996d-4f23-8e85-1ea756bf9f66",
                      "076fa181-4439-404d-b3ce-d8e2f28fca0e",
                      "c6383a20-dbff-4d07-aaa2-5a16ff94ef64",
                      "49d2b028-9de5-4246-bb78-1f5ca6871419",
                      "74931396-adde-445b-964c-3c6157bd8ccc",
                      "2c88d505-bc6b-4a26-8a6a-b5367f6f1228",
                      "95a896f2-db01-4783-94b3-ce6bb5c87d55",
                      "2a651cff-4bf5-4380-a004-dfb546bac103",
                      "80ee6047-2371-4d81-bdd0-6ae6115f6627",
                      "cbe32e85-2324-4557-9e3c-4316c5fcb7a3",
                      "58c5e6da-0cca-40d8-a29d-db176b52b9ee",
                      "aa987e42-1984-454b-a225-76f33e2a5a99",
                      "e6dfd4a7-4335-4fc7-8d87-ad853e86f9e7",
                      "96a1a47c-9b82-452e-888c-6e795dfe4ce4",
                      "c1460186-4013-48f1-bec6-a71dec7a5abc",
                      "5c5b6fbc-1e67-4104-9376-47a120563f72",
                      "7aeba435-586a-40e1-b482-6de73e426081",
                      "a358e41b-ffbe-4bc0-8e3c-efa49aeb4b0a",
                      "2568f325-843d-4359-81f8-4504cf2f0245",
                      "0f2f5e79-5b3b-4de3-ae57-79e7282f05b8",
                      "31867357-2bff-4d0a-80cb-b60dca46bbf9",
                      "aa644540-8e77-483f-8324-b54e913a32ee",
                      "a013ead2-da40-4744-9948-1971d490f49f",
                      "17f5e2b0-8548-4d46-b144-7c6de76f7e26",
                      "2e9e396a-90b7-4736-a692-02b04810b286",
                      "e904530d-0996-4180-97f3-792b27466a52",
                      "a26e72ca-bf6a-444e-8f87-1e81754966d4",
                      "c883e85c-d0bc-44e9-b9a5-db8684dfa1a7",
                      "3d661ec8-bc33-4ba8-beb0-76e1aa35f3f6",
                      "7b48e78e-21fa-4896-a096-f43cfcb30a49",
                      "24071064-574c-4e5c-9009-76b05cf5f8ac",
                      "360e7d48-15e5-43f5-8a7c-439599c4511e",
                      "ac60ce1d-b0bb-4157-9e13-164156759cc0",
                      "8b9ffbd0-4e83-47ad-97ce-ed86c23ba03f",
                      "93bcdc1b-d283-4f99-94f7-9f47c796b137",
                      "3ab2ee55-c0b6-4f0f-ac2e-acb9cc24aedf",
                      "b69a6ece-00bb-4585-9f66-ec85c50b6dba",
                      "c8bac46f-ca31-453b-add6-a5fda4300475",
                      "ab713ec8-5343-4a56-81ad-3e59edc5847b",
                      "ba48425a-2126-4ace-a5cb-a3c30ab391ad",
                      "2dda93bd-78fa-4594-a51e-f9a709eb9ad9",
                      "5848fc94-b06d-401c-b165-5f430f7952d2",
                      "d1600094-f2ce-4cfc-a0ad-89f278225675",
                      "456670a2-c59b-4cbe-93f1-d382d43e53fe",
                      "2b76dc68-3435-480e-b959-09df4c5c6e62",
                      "3d56b741-ed49-4a3c-824d-0cccfa6f5275",
                      "2c1e0a07-fc89-4e19-bbe8-1c247a48c985",
                      "abad9065-ea2f-46b6-9799-ec5ac92db7df",
                      "7f708a48-5994-4bfe-8018-0d6982a51984",
                      "215a6178-b398-423d-804c-71a02e331b0f",
                      "f94bdbf2-4106-43ff-bf0e-0dd0256d224b",
                      "b0e8976f-a9bd-48d4-a3c2-2649e5c97f30",
                      "ecc1cf01-4a76-4df3-8274-e6095bc184d7",
                      "29979699-fa63-4e46-8b3c-15efc826ee31",
                      "b48ae131-9be5-44b4-8a6f-cd669c5d56d9",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.6 Geschäftsreisen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Reisen, die von Mitarbeitern des Unternehmens für geschäftliche Zwecke unternommen werden.",
                      },
                    },
                    inputParameterIds: [
                      "208dccae-fabb-4a1e-8706-281fa21b4b99",
                      "ac3e2478-fd8c-409c-b5c7-52d51de529a9",
                      "a0db8218-6084-4dd2-bef3-d29d0cb55bbe",
                      "4189266c-026b-430b-832e-fce97a94a31f",
                      "76de89af-ba2f-4211-bb18-3e29212ad204",
                      "d06cde08-1cac-43c7-a337-c9ff2d56d0d9",
                      "11e89346-d175-419f-af33-35384a207fb8",
                      "771d58ce-d9af-4e08-9151-0a5fc3b78ccb",
                      "0e72f55e-69ca-404f-8e7f-a76a54f16020",
                      "061bd5fe-3f22-4faf-9bc5-a0845acd33d1",
                      "e68c9e10-7333-4940-8fef-697413c6aca9",
                      "e9cec0a7-b757-410b-8d4f-c702f922487d",
                      "e9f4b90d-39fd-4854-8d8d-664dd3ef3023",
                      "7a0225dc-4793-4396-87ae-599c60a000e4",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.7 Mitarbeiterpendeln",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem täglichen Pendeln der Mitarbeiter zum und vom Arbeitsplatz.",
                      },
                    },
                    inputParameterIds: [
                      "4f1a6114-8ec6-4d21-be40-fe3a0d0eaf4d",
                      "f6983727-eaa0-47ad-8383-30cfa75a9164",
                      "cb1e1d69-b5eb-453f-82e2-d84b17989625",
                      "cc4b8741-c39c-4e96-a7f1-4414250f4e46",
                      "d1dbf888-a554-4f79-9ed7-b64a7523a9ae",
                      "5266c9d4-875c-4897-bc29-417ebfedc528",
                      "6a0ce27d-cd9c-463a-9c90-bc5509091c19",
                      "da7136f4-2a3e-4aeb-96e1-99e9ac10fb44",
                      "326affab-ba4a-4477-a923-8c1152d648b3",
                      "37413fee-778d-4ccc-8396-3a0635360d81",
                      "6cbc5f64-b4b3-4395-995d-8c6233ee63a7",
                      "724529ad-ec4a-4616-9cd7-be92f7dd9dd3",
                      "446ba3c3-57e4-44f0-8fc8-0f94c186e00d",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.8 Upstream gepachtete Vermögenswerte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Vermögenswerten, die vom Unternehmen gepachtet werden, aber nicht im Besitz des Unternehmens sind.",
                      },
                    },
                    inputParameterIds: [
                      "64919e92-f1d3-4069-b523-583b91b0127b",
                      "2aa74d3d-14a7-4b30-90ed-2330767cebd8",
                      "2d3e73b0-ebdf-4831-8b06-d82f6eb27a7d",
                      "45e12b23-a447-48aa-990c-9b68a9eea973",
                      "85fec2eb-c779-4b0c-a352-78eb5dd62857",
                      "6a804de1-9d6f-44e5-a8a2-949b385cba62",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.9 Nachgelagerter Transport (Downstream)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem Transport und der Verteilung von Produkten nach dem Verkauf.",
                      },
                    },
                    inputParameterIds: [
                      "3c8ff06f-05ac-4a8a-bf09-785f03627ac2",
                      "e6e81547-42ca-44d8-ba5e-c450dbb87f24",
                      "06b6c0cd-216b-44ad-b4d1-bbc2a62a252d",
                      "7e0b3c37-7ecc-4e2f-b8fa-94a783fee815",
                      "007b3d19-947e-41bc-b421-a0cd319e67a8",
                      "481920fc-939a-4313-81fc-95268739e225",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.10 Verarbeitung verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Weiterverarbeitung der vom Unternehmen verkauften Produkte.",
                      },
                    },
                    inputParameterIds: ["86c1a92f-251a-4b7c-9cd4-24c14365a851"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.11 Nutzung verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die bei der Nutzung der verkauften Produkte durch Endverbraucher entstehen.",
                      },
                    },
                    inputParameterIds: ["74756a2b-6e9a-4b09-8147-8a47c77db92d"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.12 End of Life verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Entsorgung und Behandlung der verkauften Produkte, wenn diese am Ende ihrer Lebensdauer sind.",
                      },
                    },
                    inputParameterIds: [
                      "f446b696-7abc-4c32-8bc7-3f93f64eefd6",
                      "aa2e2ef8-b8df-45ee-a878-9c10e0274228",
                      "a1093538-6741-4f9e-b24b-c5babc4447b0",
                      "da041a02-8ca4-4006-9404-7b18eaa7b802",
                      "b0ad3ffd-d084-4980-a94a-a833dc23d073",
                      "115cda8e-63a5-4735-8545-8378be3e710c",
                      "5f538ab4-be93-4d4c-9e7f-1e9551f28b8c",
                      "a6049e8f-6d20-43ed-9a42-f714e2a11d1c",
                      "61109dd7-04bb-41be-b872-a0b4757f97f1",
                      "78c192ca-1bb4-45fd-ac6d-6d732966568d",
                      "14b32a9d-02bc-4c15-8f48-f2614381a97e",
                      "a169a261-d662-4928-9bf1-0dc50f15e045",
                      "57456d0e-f9b9-4b3c-8ec2-dc56093e4656",
                      "c891b8fe-3ffc-46bc-a2cc-4305b1d84756",
                      "3261fe99-678b-415c-9375-27123ba78b46",
                      "56dbdcbb-f1f8-4c68-8fc8-594e7267d07d",
                      "36702d51-d012-44a2-ae2f-3a3bdc56df80",
                      "7b2c50a3-1533-4ee0-a874-6365f0a55a2b",
                      "8f5eb4cf-6dce-4252-9409-4277fa34a6db",
                      "a23a6c9d-ac62-482a-b327-e5b901289da7",
                      "0275bbd1-4e41-4ed7-bb6e-333dc134d34b",
                      "f5d65cac-f46d-4ae4-a163-d5ea89fe71e7",
                      "1d26424e-71ee-4d80-8a58-e70cc55e8f10",
                      "5cb1daea-8869-41e6-86f2-0366e7b1b8fe",
                      "be3795a0-5269-4c79-8ccb-22fd02d59ec6",
                      "566943b7-d91c-4c8b-8649-0a1f668c8683",
                      "c93ca2e7-aec7-4406-bf7b-ca8f87de8b62",
                      "b7d89994-b7d1-48f2-8aca-d0736e67aaf4",
                      "4afa9bfb-4771-4367-8ca4-a0b9aa5a6d00",
                      "bc73665d-7739-4365-89a2-2c291531f980",
                      "eaf58205-35a7-437f-ab92-682f012a4c7f",
                      "bfeb2f9f-c7e1-411d-89fc-c7bb6bad8b84",
                      "d330ee79-e1ac-4ac6-8eab-daeb284961fb",
                      "45dd5d82-dfdf-4e15-9991-1aea0350f233",
                      "c4478c6d-9866-4099-99ef-0369f9451070",
                      "852f3992-bec4-4c54-aa28-ba7ec4384eed",
                      "08ccd2d0-a4a0-45d5-ab87-9a9ceb949494",
                      "75970b3e-f707-46b4-bf12-e636e0f9c8fb",
                      "396298f7-fb5e-444b-a6f0-d8b0131dcc3b",
                      "ff33918d-98c3-4bd2-88ba-15176f8f26bc",
                      "5ae23a68-35dd-4a2d-b64e-c2985424efc2",
                      "2e9eb71b-38b2-49fb-85bb-dfb1bbd7d9a1",
                      "ceb61033-1ba9-44e3-91ac-ccc043ab9ee3",
                      "c9811c41-f51b-4d8a-9150-f483b1fc0aaf",
                      "2f898ee1-64ff-4042-9d75-960e0ea5b956",
                      "526b20cd-4089-49fd-a17c-f737272c795d",
                      "b153f40c-96bd-49c4-83f3-58b987a278a1",
                      "b1d75d2d-16ab-4020-9378-c53f542d3d80",
                      "84b317d7-f361-423d-9cd2-120fe5edccc9",
                      "8748b42b-77d7-44a4-9df7-e3bc2982e0bd",
                      "221c15bc-f9ad-4f48-8758-8cddfb8f8143",
                      "11b31e71-66e4-4d0c-8bb7-d4358af258ed",
                      "c50c477c-a324-4715-bcb9-aa950bcbc2a4",
                      "ca513f62-99cb-4ff5-b50a-e477d6743ec7",
                      "10ea8162-6bbc-4130-871e-3760f5fc40c8",
                      "9f1e0df7-fde1-422b-ba7b-3b85b3cff019",
                      "9d23ca68-774b-4d82-8b54-f36ce83a14a0",
                      "532009ab-8d2d-4b6a-9c56-1009044814f7",
                      "58db01be-7987-4e07-939d-10a0a6274ad4",
                      "9a64c98b-9779-4d21-860b-425420cc0391",
                      "e0cfe0a8-0236-42ad-a311-a8612898b058",
                      "83efbb83-6e3b-4951-ab1c-bd9cafc7893a",
                      "1a4848f7-2f77-43e5-a163-49ed428dc6ff",
                      "679d4c1e-8a29-4cc6-8b88-007c1df31023",
                      "e349cf48-5c4a-4afc-a3f0-28ac7eeea619",
                      "b39ac545-e860-46fe-82f0-c5b91e71c13b",
                      "9c9918f5-e07b-4746-9eb7-b1077cb64c1e",
                      "1df29c80-2935-4baa-912b-c5481538b426",
                      "f8782cac-0b2c-405b-9be5-c4a3beac488c",
                      "47d0e5fd-d824-40a5-b396-bd530ed6dd18",
                      "007e376d-9d93-401d-a122-05e691cedf63",
                      "92ca89dc-c434-465d-a9a5-2776bf09cc6d",
                      "0133c72b-cc48-4aad-81b8-bafac0c1d8f2",
                      "ac719133-eca4-4cf7-825d-81c4b18d85c1",
                      "d8c743bc-da75-4bb0-b48b-9efb847a2b82",
                      "fd64ddae-f82b-4047-898a-0874cb8097de",
                      "768500b0-5cf6-4b15-97d3-5544a7d8cfcf",
                      "57949f96-05ed-4f88-86f6-33220968b58c",
                      "84a034ed-2c8a-4e65-8f53-a688c1882c0c",
                      "2f1769b3-e3df-492e-9120-d139e91c480f",
                      "b49c5e6e-9b0d-456c-a742-4ecc9e06cd8f",
                      "35798589-d274-438a-b3b3-9211f616f47c",
                      "b2f04a60-0916-4b15-a9e8-a9c4c478bd4c",
                      "932e42a4-3f36-426d-91dd-868e444f7682",
                      "62f26f2a-5082-4241-ac46-2548232d9418",
                      "073a052e-552b-442c-8ee2-e80dfa96638f",
                      "593d470c-6b36-4cf4-8266-e52ffc2e57d1",
                      "e9ec8dfc-3ed3-4a29-81f7-8e94175bbb38",
                      "c21af532-b987-4544-8891-f508250135bd",
                      "4be1bf7d-b537-4993-a356-15121d9bce8f",
                      "438d42b4-a311-4300-93cc-9b6611e39ff0",
                      "91a563b6-dcf8-4e0f-a205-9afced9585de",
                      "7123b924-89dc-4622-a256-ae263fc6adc5",
                      "5efcba27-4d45-43ac-be0f-13f2304cf7d1",
                      "3cff68b0-18fd-40b1-a941-05150de99d00",
                      "18c3bb3a-2ad8-4d88-b85c-d625835b4ff5",
                      "b44e1c4d-4d23-438d-a543-a082fdc2e1d5",
                      "25c0628f-4525-46e8-818b-21f0f89218c4",
                      "3a3e2fd3-5b82-4e44-9c73-914da9ca9ce8",
                      "f1b1b340-edda-466c-8d2a-5ece503751fb",
                      "0a185400-2a03-43b4-a2b2-b7523bcfd854",
                      "786ba2f6-ebe7-42ba-bcf4-48578be3efad",
                      "2196fb50-2298-4d33-9559-22c66bb5dd01",
                      "09354848-7751-46f9-a291-06fefd97e0f6",
                      "b0d4bdf7-244b-4dfb-b3f3-34891ad544f5",
                      "1fcc96aa-afeb-4599-be62-556387419eb7",
                      "214189ec-4a00-47e2-ae70-dcb100f290cf",
                      "de8f7131-f8f0-44b3-a0c2-d305dbef40e1",
                      "0dcf8cdf-b553-4db4-ac70-3adf57712e69",
                      "f0532060-7203-44e3-a84b-329ec65cd428",
                      "4a6289eb-ce76-4bfa-886e-03b436d7597e",
                      "e30ee70e-4242-4d15-aeb4-e0bb4940b90f",
                      "346398f1-66e9-489e-b061-660157f218ab",
                      "8ef995d4-bf68-42d0-8049-19060fdeabba",
                      "0d929fb3-ee3e-488b-9887-a13328d579f0",
                      "9908ed46-0ad6-4c5b-9047-74437ea82af6",
                      "8f9ef520-8074-46a0-bc58-fb7a751857a4",
                      "c0eab161-ab53-4693-add1-a816999adc8e",
                      "514b865a-d063-4a71-bc25-07f4533abdc6",
                      "b17bb2bc-40f4-4e51-8bf9-3edd2fa50e09",
                      "ea8a9214-87d7-4e10-b49a-ed1222d76ffa",
                      "38baa78b-ffed-4979-8047-b91c97246f02",
                      "7eda8143-e3c3-446b-8776-fba04f5f328e",
                      "64d19e10-afd9-472f-a344-23b37c866fa9",
                      "66a84eb0-e270-4860-a4ea-59a292a6ae6b",
                      "114b2de7-43eb-4897-86ec-e3479d99f63b",
                      "49048e0b-487f-4e21-90d3-aad38299c58d",
                      "0fa3c49a-c6d4-456d-a59f-4572b7853c6e",
                      "744a6e1d-6655-4c3f-9b44-fd5c1d2bb07e",
                      "5eed5dd7-891e-4b68-8aad-6cbc8a468fdf",
                      "49e4f67a-d1af-41d8-bace-3b2c015a3357",
                      "23a974d3-3180-48f6-af80-e08ccb978bd7",
                      "6d2f60a6-227f-4c93-84d8-73664ba3840b",
                      "b447436b-b929-412d-ba05-d278c293667e",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.13 Downstream gepachtete Vermögenswerte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Vermögenswerten, die vom Unternehmen an Dritte verpachtet werden.",
                      },
                    },
                    inputParameterIds: [
                      "e1cd6156-0b8e-429d-8d68-0bfc6a96f80e",
                      "f88f3460-ae29-4138-99c6-903a6070ad13",
                      "0b13ea84-1e80-4aa5-aca3-a9e865627fc1",
                      "f6318c72-1088-4daa-a6dc-0135a56dec50",
                      "0d65a991-c994-42fb-a8d2-9820017854b3",
                      "ab33ca2e-bb18-44fa-a495-9a31f1c57505",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.14 Franchises",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus den Aktivitäten von Franchise-Unternehmen, die unter dem Markennamen des Unternehmens operieren.",
                      },
                    },
                    inputParameterIds: ["d20440f6-1dd3-42af-a9ff-e0cabe9095db"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.15 Investitionen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus den Investitionen des Unternehmens in andere Unternehmen oder Projekte.",
                      },
                    },
                    inputParameterIds: [
                      "c9c47511-8372-4db2-b323-e275f53d6e7b",
                      "d1769009-0d58-4eae-a637-40092b4197a3",
                      "32cd6d3d-21f4-4766-9f53-7a77d0305ffb",
                      "f8b68ec8-6d09-4c5f-9f18-74fb05737451",
                    ],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Überprüfen Sie im ersten Schritt, welche Scope-3-Kategorien für Sie relevant sind, anhand folgender Kriterien: Größe: Sie tragen erheblich zu den erwarteten Scope-3-Emissionen des Unternehmens bei (siehe Abschnitt 7.1 für Hinweise zur Verwendung von Schätzmethoden). Einfluss: Es gibt mögliche Emissionsreduktionen, die vom Unternehmen durchgeführt oder beeinflusst werden können (siehe Kasten 6.2). Risiko: Sie tragen zur Risikobelastung des Unternehmens bei (z. B. klimawandelbezogene Risiken wie finanzielle, regulatorische, Lieferketten-, Produkt- und Kundenrisiken, Rechtsstreitigkeiten und Reputationsrisiken) (siehe Tabelle 2.2). Stakeholder: Sie werden von wichtigen Stakeholdern als kritisch angesehen (z. B. Kunden, Lieferanten, Investoren oder die Zivilgesellschaft). Outsourcing: Es handelt sich um ausgelagerte Aktivitäten, die zuvor intern durchgeführt wurden, oder um Aktivitäten, die vom berichtenden Unternehmen ausgelagert wurden und die typischerweise intern von anderen Unternehmen in der Branche des berichtenden Unternehmens durchgeführt werden. Branchenspezifische Leitlinien: Sie wurden in branchenspezifischen Leitlinien als bedeutend identifiziert. Sonstiges: Sie erfüllen zusätzliche vom Unternehmen oder der Branche entwickelte Kriterien zur Bestimmung der Relevanz.",
                  },
                },
                inputParameterIds: [],
              },
            ],
            description: {
              defaultLanguage: "de",
              translationsPerLanguage: {
                de: "Greenhouse Gas Protocol Standard Template",
              },
            },
            inputParameterIds: [],
          },
        },
      },
      {
        id: "45991f43-b088-4ef5-a3c8-50b8d2afcdb3",
        name: {
          defaultLanguage: "de",
          translationsPerLanguage: {
            de: "GHG Protocol",
          },
        },
        description: {
          defaultLanguage: "de",
          translationsPerLanguage: {
            de: "Greenhouse Gas Protocol",
          },
        },
        categoryIdentifier: "greenhouse_gas_emission_ghg_protocol",
        icon: "mdi:molecule-co2",
        evaluations: ["emissions"],
        recordingModelId: "2d332330-536b-40f3-bd87-73c6d1c6e7e6",
        recordingModelOrder: 5,
        templateUsedId: "d68b8d2d-0938-4fe6-9738-84e863e100a2",
        structure: {
          type: "thg",
          rootSustainabilityCategory: {
            name: {
              defaultLanguage: "de",
              translationsPerLanguage: {
                de: "GHG Protocol All",
              },
            },
            children: [
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 1: Direkte Emissionen",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Stationäre Verbrennung",
                      },
                    },
                    children: [
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Fossile Brennstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "ebb631e5-777a-4481-af94-174e32cbc344",
                          "030e7157-5363-4430-af31-a0c0f2e9f017",
                          "4c71a8a2-0443-4cc9-a322-ba9e837f7209",
                          "c4eb0119-ad9e-487d-b950-060384861ad1",
                          "4b17554c-9ec7-4cfb-b8ce-2f022706c58f",
                          "7e8c805f-4d71-4643-a305-6883b379cd79",
                          "8b619eff-b11a-4ecf-b1de-7616367c7298",
                          "d6eefcd1-d46f-4781-964c-3f3438fdf67d",
                          "a6780822-9eb9-4707-aee9-66957ca11aac",
                          "869a3dfe-37bf-44f2-945b-4c7a6c1b6dfe",
                          "29ec2cb4-5c5b-4ab8-94a5-26acdc14fd6f",
                          "77c893ba-4b72-40fd-8fe6-2a99df140c2c",
                          "464f803f-b946-411e-a27a-2068e9bc602e",
                          "6a516ee2-aaee-4c36-a2db-916f85cb9a2b",
                          "64ff0f93-03d0-4e2a-b774-0e851974819b",
                          "f9e1792b-2bc9-4841-8160-b5030f955550",
                          "0a3f9363-2e54-4697-9e8a-c3f9ee285d67",
                          "611402da-8851-4263-929b-356e697e00e4",
                          "172cb887-bd25-4be9-a86d-b6fb6e01228f",
                          "b2a8caa4-f91d-4230-a303-69cea8e4b897",
                          "57ba26ed-46e7-48d9-befb-10aa82c90728",
                          "e2c0a2aa-3b5e-441a-ac90-12b2495483c3",
                          "176761c3-c706-44f1-a525-f5726d50ddab",
                          "4723f7ca-7a12-452c-82fb-fc7e841e2ea4",
                          "b01722d2-37ac-4074-85bc-1258da1a80b1",
                          "325698cf-7078-4f83-aa00-ecf8589e64ec",
                          "b5724d23-333b-4c2d-a076-682ebbb002bd",
                          "849b715a-a537-4683-8646-8a62ae04c136",
                          "a7af48e4-4d5a-4cb3-b9cf-5efe3da0b4cd",
                          "f7c1ff84-39af-442e-bfd6-e3cd67ea6f8c",
                          "f5ce15a4-5980-41e7-b7ef-9f3a228290b2",
                          "651fb559-78f0-4975-8574-07fd74f5a44f",
                          "2494394f-572e-496f-9958-a155c045481f",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Biogene Brennstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "c447f6ee-5faf-4f88-a008-a3a795b6bff6",
                          "1daeda5b-0a93-4cc3-a4cd-2a1919954590",
                          "2adfb02a-2fc7-4e10-aafb-434695b851ec",
                          "9bbc97c0-3136-4dc6-a38e-538fc917a4fe",
                          "8e2c3d31-1d57-469b-bcab-18c9a87e25e1",
                          "7c1240f2-6f00-498c-bf2b-88260ccef018",
                          "265d753a-ac73-4188-ac08-1e1e2dc00a98",
                          "dcf7f891-6f84-469c-a38e-42d31523d0a9",
                          "2b281692-168d-4550-bdcf-1829efa803c1",
                          "10ebd054-3d59-4b29-9a60-e0d2568b2c5f",
                          "be57021b-a8dc-4b0a-980a-e8d723cdd869",
                          "10f7a065-ba59-4717-9afc-851ab20edc05",
                          "aefc21d2-5ba6-430e-86ff-ad714fd7e92b",
                          "5a23f85b-2656-4e42-b16f-230061bb9719",
                          "b504cd67-4308-409c-9928-b910a3605b93",
                          "70c9ea95-f896-4833-8789-eac83c259e02",
                          "b8969d3f-cea7-403f-a36b-b8394a7ffa56",
                          "c1bb5fb5-f09c-4305-b98b-3c090cf30813",
                          "85672361-fdd4-4528-afe8-392946e2f37d",
                          "dd466fcd-dbbc-437c-908e-a4473f48d40b",
                          "a0c40219-7697-4ab4-b7f5-62d7a07272d3",
                          "710b7b23-d42b-4724-ae21-cd3b2592fafb",
                          "58e91d1f-6e6a-4400-9f26-13c2650500d5",
                          "916c4e25-48f5-4c99-9672-bc85621c3efd",
                          "4d172601-16ff-4d60-af52-3737d3b100b7",
                        ],
                      },
                    ],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Verbrennung in stationären Anlagen wie Heizkesseln, Öfen und Turbinen",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Mobile Verbrennung",
                      },
                    },
                    children: [
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Personenfahrzeuge",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "eff0ec87-57e5-4b59-812c-46506654dd83",
                          "b4f71c1e-c6b5-47bb-ab68-f839909e3dc6",
                          "e3ec6210-8cef-48e5-9b9a-3e6facc8ea27",
                          "8c3c957d-5d2e-49d8-9f9e-239054735463",
                          "46172421-a599-4855-94bb-42bd62d903c2",
                          "152fc686-23da-4be9-bbf6-df51aaac0321",
                          "fca73c47-3ce4-4d3a-9357-f879a9ecba58",
                          "d5fada53-cde9-4b12-9e22-33903fc82005",
                          "a5da96ed-e317-4aa9-868f-59a9441a9847",
                          "d934b30b-18d4-4a24-93f8-db85928fade7",
                          "ba985d08-f860-4462-ad6b-5fcd079a188b",
                          "1c43d101-4037-41f0-b53d-e8268da4fa6d",
                          "2256c342-bbf2-4e46-9fb5-1337b3c311fc",
                          "3a1474ed-fbf2-409c-bd6a-b17b49c3bdf3",
                          "6de3701b-ed53-4671-9ae5-803971992bf1",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Transportfahrzeuge",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "e5be7f0f-ebe2-44ff-a2df-64b2e63c0750",
                          "f3faeab7-a9b9-4ecd-bd88-7a5be752e9fe",
                          "35fde1e5-de91-4b75-bbca-6c33f479823d",
                          "7ba32c47-3059-4503-9a79-fe028c03173b",
                          "ebe5d2d3-a361-426e-823a-a924f3944ac5",
                          "cc1b5210-768d-4b7b-b22d-3296865a22c7",
                          "4f352219-11ba-4adf-b167-dc0bdedcca94",
                          "a6a3842d-f41f-4967-97f5-b298de605e88",
                          "8909227d-362f-416a-bc72-ac3cee15f56e",
                          "210d8291-ca5b-40e4-83f0-c040c8a3092a",
                          "6ea4f262-346c-43ed-8583-42fe210c7076",
                          "8422ab97-021c-486c-a3f3-4f354d2543f1",
                        ],
                      },
                      {
                        name: {
                          defaultLanguage: "de",
                          translationsPerLanguage: {
                            de: "Weitere mobile Brenstoffe",
                          },
                        },
                        children: [],
                        inputParameterIds: [
                          "51a8bf1e-aea0-4765-b38e-53a10b0eab6a",
                          "ed4156f7-f8e3-4bd9-b634-3ba3870a0023",
                          "e43b0e16-7787-4293-910d-e31b4858b3bf",
                          "baba5181-7de3-49ea-9dc0-5f18ba625700",
                          "46544d3d-3210-4e01-8f70-032d4270e301",
                          "1195aa40-beaa-4773-bb1f-f834bf6cd90b",
                          "0b258643-bc92-42e0-bb94-3080f7ba1e22",
                          "70ddaa9e-ece1-4ad0-b2e2-3a8a16a3ecef",
                          "a0fb9169-6457-4368-8c3b-a2ed0a7409e8",
                          "4a1a6e96-0099-4a0e-9037-e9c93cfb33fe",
                          "97d3a5d1-8df9-4848-b407-c865255fd264",
                          "b1145be8-5ae6-48bc-87fa-8e21b547da57",
                          "17a62553-88d2-4690-95f1-e49b05da97e2",
                          "94788610-11d0-4bfc-aa0e-40da8214a3c5",
                          "56e383cf-2a53-4e1c-bd27-68adb43d82e0",
                          "50836424-f7c3-488a-a55a-c1168ff35725",
                          "43261306-cd4d-4c49-abef-ab7350ecd4e7",
                          "dd2609c6-4da3-49e1-8a06-54904a1e8b8f",
                          "c80989fd-2cb3-4b99-bb4c-208def406072",
                          "ba09f104-418f-47ee-a01f-9e4c2b42a90f",
                          "8f16ddfa-6307-4b81-8d96-ce35db3a844a",
                          "8cebce74-e3ed-4b64-b243-d04ca6261246",
                          "c7764370-64d5-41eb-86e5-62bbd015c7ec",
                          "b0985951-ee64-43a6-a8ff-99a184ea5ce5",
                          "9539ed87-2dfd-437e-8cd9-2669080fdd90",
                          "8efd3426-6e32-429c-a446-c65801908064",
                          "5cb0b667-d792-4056-992e-b899934bd515",
                          "8a1e2b48-1f1b-40c5-98a5-9b463d77cd77",
                          "17d09654-59de-456e-99f8-4fc0fb011e4c",
                        ],
                      },
                    ],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Zu den Emissionen aus der Verbrennung von Kraftstoffen in mobilen Quellen gehören Fahrzeuge, die auf der Straße für den Transport von Mitarbeitern oder Verteilerfahrzeugen eingesetzt werden, sowie Geländefahrzeuge, Motoren und Geräte, die für viele andere Zwecke verwendet werden. Geräte, die sich nicht aus eigener Kraft fortbewegen, sondern von Ort zu Ort transportiert werden können (z. B. ein Notstromaggregat), werden als stationäre (nicht mobile) Verbrennungsquelle definiert.",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Flüchtige Emissionen aus Klimaanlagen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Kältemittel wie Ammoniak, Propan und Isobutan sind keine Treibhausgase (THG) und sind daher nicht zu erfassen. Alle THG-verursachenden Kältemittel, die Sie berücksichten müssen, sind in der Konfiguration gelistet. Achten Sie dabei insbesonds auf Ihre Kühltransporte, industrielle Prozesskühlungen, Kühlhäuser und mobile Klimaanlagen.",
                      },
                    },
                    inputParameterIds: [
                      "32192163-68ba-43be-8703-41c6b852621c",
                      "1d9f1668-f525-4b82-896c-29010c7e0568",
                      "158c45a5-d86e-4c18-8d27-3bef276a02c2",
                      "77b5203e-a75e-4241-8bb7-e9aa4888fb44",
                      "ba231002-f9ed-41f4-a2c4-37899db6a8f3",
                      "dcdae379-451d-4789-9047-078b4c54b188",
                      "303fba28-630e-4876-aa9c-d2e5fcedc337",
                      "39d18d24-8e0a-4313-bab1-2dc1a69fe695",
                      "9ee83d91-c0e2-4102-b660-1c26e8cba438",
                      "4e444f37-a1ca-4c13-bd34-b003fcc9dbb1",
                      "e2e67343-1b91-407d-9d37-5fb81e7761c3",
                      "25617f05-7ea9-4c6c-a08a-254a706db5a1",
                      "7ca92b43-ab70-45d3-ba92-4693d968fb85",
                      "eedebf75-51d2-4262-89f6-6d89dd9da4d0",
                      "84e671eb-e3c3-446d-a699-fbc0f118b74d",
                      "ffc0b12f-82c2-4f84-83df-d41c8241c4b7",
                      "00be3645-605d-4704-91f7-af5afa2d4be5",
                      "8d9cc5ab-1cc3-4a53-9f8f-0dc0ec6c9d77",
                      "1b6217c7-9b46-41a2-ac44-e39fd53c0c74",
                      "781aad03-7b48-4b0f-a427-e1542bf38df9",
                      "44c834f4-a056-4f30-92b9-71a7b0d7a522",
                      "0e37368d-747d-4cad-be1b-5f8d4533f05c",
                      "f780beee-5544-48dd-8ba4-231a6e4c7eb2",
                      "1efe4c1d-72fe-44c8-8ca6-0e6581f5095b",
                      "cfb0ce20-b4a1-4a86-9b40-8c7279d91f53",
                      "fea87025-56f9-4d7e-ba30-997e8fd3632b",
                      "8733d8e2-91d9-4c68-a620-faeabb91b59c",
                      "1c8e8c78-0978-4588-a8a2-e989a64ed68a",
                      "9e103b49-1434-4b5d-8e4b-ac812396bcae",
                      "d0028374-f05e-455c-958b-0d858227e7f0",
                      "622532b3-34e7-44eb-9704-08a95de87cc7",
                      "669510c1-106a-4810-b326-8b93b0b2bdc0",
                      "37f59190-535e-4c50-aa70-7df21f17dd0a",
                      "a59ebb53-80f7-44f1-b71b-fcbd6c509ad6",
                      "7319393d-3f99-4e4a-b28e-2793d2b3914e",
                      "ebdad72d-ecb5-4887-977a-4bbb1ce0fcc2",
                      "edbefdf5-ed07-4f85-8ad7-9677d4231307",
                      "49312e75-f4ab-4ade-8c6c-f6eaba0ef447",
                      "2844181e-f4f0-455d-9bee-dcf0d42bd758",
                      "e97d28a8-6641-471d-89e9-d8a4d9924c16",
                      "da20d576-ac81-4deb-a588-bdfdd6cabdd6",
                      "26b94300-dced-4645-8c97-910c0e2498cd",
                      "9550ef07-9e1f-4d67-94ea-a0b8c432ae13",
                      "ca11feb4-0f72-43cb-955d-a5d5a43fd6f4",
                      "ac9b1ed5-0326-448f-8458-c24b2703dd19",
                      "0e0ed9cb-e454-4992-baf8-588141b3b662",
                      "87fb22c4-3488-46b5-b923-bbc1f7a6b099",
                      "171b322a-1c7d-49a6-9ac7-5cc6223a0f47",
                      "7b3ebaaa-5d44-43d2-bcf6-6a0f00f9889b",
                      "c11b2f2a-d5dc-4de3-b7bf-e3486b829455",
                      "fe646ff1-fa07-4eca-90ad-11d8c3cf657d",
                      "b1a082d4-e4fe-45c8-b22b-ef9cf98153e4",
                      "9b3f427a-88e3-4a05-859e-5981b9b66d6e",
                      "940dc2e5-0522-4cb7-831b-086526629656",
                      "837672dc-f5b9-4961-962d-e7a2781fa914",
                      "1918673c-580e-4b30-80df-82335607227c",
                      "104980b9-0f19-4901-9e9a-ce05b4a01de1",
                      "05f679fd-d2d9-4648-b7e6-a1112c42afdb",
                      "86c26464-839f-4b1d-a945-076cc512fc57",
                      "f8cf815e-7459-4c3a-ae70-93902ec1233c",
                      "4ea41a5a-b7dd-40f3-8e84-42e1d002eca6",
                      "618e2d5b-8238-4cb5-a055-08e0a89394b1",
                      "d083d8b5-097a-4690-bca6-dcb7ae481697",
                      "c7574cec-8e75-454a-9f95-683dfa6867df",
                      "915e370c-8551-4552-a9ee-7eca8d54994a",
                      "c51684a3-38a5-4e3e-80a0-5896b0907f31",
                      "c24b7fb3-61ee-4ea0-ab5d-6c452c1d5510",
                      "e34b9b70-d21e-4b12-bee9-089fc4ba3dcf",
                      "8e1dc292-9153-45c2-a7e0-3346e0deb83c",
                      "d029b9b6-4bd5-4b63-804e-e65e65ee9719",
                      "43db155c-db30-4444-943f-f99c195dc2d0",
                      "65d06fdc-2edb-49d3-8c9a-1560421ed0de",
                      "0b14d849-bd2e-42ec-9f8e-eb1a316b876e",
                      "666e150a-3029-4d79-b280-1c956eca55c5",
                      "19473247-2f11-4252-886f-552516bc0085",
                      "2b4a46c3-a9d4-4e1d-b45c-a89885740525",
                      "38ece427-17ac-46cf-bf4a-7860e37d358a",
                      "52e8ec48-6e96-4963-a780-eebf5d720ea9",
                      "40e05fa4-eb75-4ba5-9a57-a8cb284693ad",
                      "cb7d9704-8eaf-4e5f-9160-a3bffa381936",
                      "642c8944-bfb0-41f9-b34e-258cc6b6850c",
                      "4c1fc92a-d8c5-4808-9bc2-f7f11de586b7",
                      "64952af6-9570-47b6-98aa-0d8496a5b109",
                      "0d4a6a26-1a8b-4f36-9fe9-3196a64e0dbc",
                      "b2b6c0a5-618b-487a-995a-bbde08332b37",
                      "aa05ec08-c695-4530-bd06-9d2975d280ba",
                      "1ae1f79f-faf0-42b2-a3b8-374b4e1f106a",
                      "a970fc16-abbc-4daa-8932-b4ef47cbbb3f",
                      "8dc768e6-8b89-42a5-8e0b-f7a00ca993c2",
                      "1b0777eb-e099-47c2-bf85-620b28de6c28",
                      "1407931f-cf61-44b4-8be7-80ac732b9ac6",
                      "13d94db7-5f6f-415d-b1ec-7f12762a32d2",
                      "a863752d-9d15-47bc-aae7-0b78eb20dc52",
                      "ef34788d-d29f-4ffc-9b29-e99b112d867b",
                      "e87d2877-dfed-4262-909a-f4037e598296",
                      "acf1aa3c-1f4e-4895-abf1-6b244fd11fc5",
                      "0223d9ac-eb82-4b9c-bd96-d3d7ecbf13ac",
                      "80f6abdf-23ce-4ddb-a7f5-3b26adb13940",
                      "51ebc560-491f-4048-ad26-2106e1be9192",
                      "103eb264-4803-4367-b6f4-675308615634",
                      "ef5755a9-85e6-4ea4-9086-a3cfa83a57a8",
                      "92d604da-08c8-4834-b3f3-734125ad9ae1",
                      "6406b396-c3f6-4ebf-bec9-f3219303f729",
                      "03ed5c82-d520-4c2c-8e7d-781df1918ae7",
                      "1a258e02-8345-4a01-af5a-8a7ab0186514",
                      "5d9a38aa-7a7f-4f89-ab51-58c827428c75",
                      "263a1aab-c75b-4317-a634-4300d4dbebee",
                      "42cb4b33-150d-413d-b42c-748af9092275",
                      "e6018fb7-35cb-4d09-b173-a48abb949671",
                      "8b6dfb65-e66a-41e6-9687-45d15f42cacb",
                      "bc6e6df7-9a0f-4099-837d-b70607e4486b",
                      "eba7f855-3d9f-458f-a32f-4e11e26cc493",
                      "245626f2-a0d1-4b5c-8509-b1929e19ccdc",
                      "2a5f1e32-18ea-41ba-b846-741eb642c9b1",
                      "c91cb0b2-9a2e-4583-8672-03811139b97f",
                      "5a5afbb4-9027-476c-8a0a-6ed34f38a0c7",
                      "517bf9cb-29ba-4abc-8be6-230dba4535a9",
                      "379fee9b-fa95-4673-8afc-fb61088b7fde",
                      "3fd0c148-b80e-4843-9d2d-cdcc5b900263",
                      "672b4bd1-43c4-4dde-a549-059d563ba615",
                      "b03b6cba-e9a8-48c2-8626-4ddb56866dde",
                      "6ca8316f-40ad-4f32-8679-d8ac4306f101",
                      "c4aefc3a-2f11-4384-a5f5-d2adef838a28",
                      "7a6cdad9-9035-467a-a5b1-cef06ca34229",
                      "e058b248-49e2-43c3-94ca-8afa9fba8d42",
                      "e8315e91-2084-46f1-87d7-14754342dee5",
                      "f8d1d8b2-9450-4e9f-b76d-d70d9f7aa1f2",
                      "783be21c-509e-4f5e-8929-7613d42f69b5",
                      "a8a4af8a-01a0-4cde-bd4c-8af4f599d44a",
                      "727013a5-a273-4b91-ab67-aa326bc1e99a",
                      "55af7259-533a-4049-baae-0bb89fe3d4ec",
                      "483f055f-2ce9-4920-85d7-d0931f2ba341",
                      "02d95aa8-a3ee-40cc-b7a3-663af4505ff6",
                      "c76f50e4-69cd-4ae1-b85e-ab97e90e8f3e",
                      "77ea1b55-5121-42d1-a7d0-ff6922cbf577",
                      "5e334e47-9486-4eef-a0cc-48e9cbd9aadc",
                      "87da6105-b766-4eaf-b12c-008fb3429d5b",
                      "a5d44bcf-d665-4776-bc15-a0f2f7336c10",
                      "bcba6c7c-6d8a-402d-9f01-467e44773514",
                      "97c27f21-67fe-4a98-8268-804dda25171d",
                      "96dd2bb9-0a16-4363-acf6-888256dbf11c",
                      "ab44da71-d902-44ed-9b3c-59a54cf41bd5",
                      "88437379-b8f9-496e-ba56-723195d53cd6",
                      "8b410b8a-e157-4a27-96d1-5ab9b51656b0",
                      "4ce3250e-cd50-4118-9f07-c3d81c5e3c78",
                      "4c6ae90c-ce7b-490c-a48a-27b8c7c5f3dd",
                      "af2044d1-2ed1-4944-b14b-835ac049f112",
                      "c673c887-c2bd-4156-b42d-71ccb6a8de97",
                      "d28501de-f908-482d-89d2-d51c0c344d9f",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Sonstige Emissionen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus chemischen Prozessen oder anderen betriebseigenen Quellen",
                      },
                    },
                    inputParameterIds: ["53bdaef2-5b26-417d-b082-2da8f8f07299"],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Emissionen, die direkt aus Quellen stammen, die im Besitz oder unter der Kontrolle des Unternehmens sind.",
                  },
                },
                inputParameterIds: [],
              },
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 2: Energiezukauf (indirekte Emissionen)",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufter Strom - standortbasiert",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die auf dem durchschnittlichen Energiemix des Standorts basieren, an dem der Strom verbraucht wird.",
                      },
                    },
                    inputParameterIds: [
                      "41826120-c7d0-4f16-a451-675ae6d49b71",
                      "87e7af51-41fe-4437-a7d1-8cf182fcac7d",
                      "e1ddaad8-8fef-4002-af7a-93738e6cabad",
                      "1b75dfe1-881f-413e-8f18-723079409a8e",
                      "5e2ae052-d221-4766-805a-b11f93e4d019",
                      "27cf872b-38c5-4ccb-9675-cf0c12cfaf6c",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufter Strom - marktbasiert",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die auf spezifischen Verträgen und Herkunftsnachweisen für den eingekauften Strom basieren.",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Eingekaufte Kälte, Wärme und Dampf",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Erzeugung von eingekaufter Kälte, Wärme und Dampf.",
                      },
                    },
                    inputParameterIds: [
                      "3548a6c5-db61-4a07-962a-f531ee95281f",
                      "d6f9c653-480e-47d5-9c88-2dff4c724554",
                      "15bbfdd5-685c-4019-ba60-30aede373985",
                      "2e727049-8f22-4908-a4c6-d1590cd98d45",
                      "41225d76-a3a8-4493-bd46-97a9f009d62a",
                      "9a9dc0d7-aa3b-4bd0-8b6b-ac60a3914cc5",
                      "1d1c4d7d-7a61-4455-baac-94ae93e93ce3",
                      "0d85611c-1fb1-4b68-835c-fe70918b3a26",
                      "be331266-df44-44a8-88ef-0e70cd3fa9bc",
                      "873ffce2-10fc-44f2-9ee5-14e5ef9df7bf",
                      "220a06c2-5005-433d-809a-26d042417d99",
                      "5cb6d894-251f-4b46-b013-1fd7d4be31b7",
                      "61afa312-fab4-43c6-bef2-7a9cbd1a701b",
                      "e5fe6978-bc43-41f2-97ec-b6efdb2e12e3",
                      "0d14993a-a65f-4229-ad55-b746b47528be",
                      "6ac785db-00c8-494a-8aae-018eb45f0612",
                      "dbd847ae-60e3-4172-81eb-11552fdb71d4",
                      "10e8d063-248b-43af-9cb9-022fba7a2765",
                      "e2837300-2599-4623-819e-038d3dbba85e",
                      "525ee409-19ab-4609-8229-ca4b0eb028c9",
                      "6e6cc455-2158-4356-8e26-e7c198309bcd",
                      "1449ca01-7439-4bf4-b0fb-6142b31e73dd",
                      "36602fce-4a11-45af-8a8d-b67e04eac4d0",
                      "116d51ac-0e3b-4cbb-9964-2ec4a7d7802a",
                      "61f273a4-f329-4917-85b6-eefdfbe9ec12",
                      "22a74388-bcad-45af-bc24-2e156afd27c6",
                      "e6adeed6-250e-4aed-87a6-f592284b4dda",
                    ],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Emissionen aus der Erzeugung von eingekaufter Energie (Strom, Wärme, Kälte, Dampf), die vom Unternehmen verbraucht wird.",
                  },
                },
                inputParameterIds: [],
              },
              {
                name: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Scope 3: Wertschöpfungskette (indirekte Emissionen)",
                  },
                },
                children: [
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.1 Eingekaufte Waren und Dienstleistungen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Produktion und Lieferung von eingekauften Waren und Dienstleistungen.",
                      },
                    },
                    inputParameterIds: [
                      "40a44e08-cc7d-4e1f-8d0e-024a9731993c",
                      "cbe2724a-5757-4a20-9f31-b102b3b37c6d",
                      "28aa41d5-e1dd-4f5a-8e47-bc5408b72c2a",
                      "5097b360-5b46-4f4b-bb56-ff5c58140557",
                      "a7f88aae-2da9-4c9e-a5ec-ba1e91d74572",
                      "29541a22-ba78-42de-b766-13b964e6d050",
                      "2545e1b5-a9f6-4aff-b894-1440055a6aa8",
                      "c71b5699-2923-4850-b19c-407d19381cf8",
                      "7df702b4-7bda-435c-a755-26bf3a5320d0",
                      "df649da2-89f7-4a3f-838d-4989ea992dc0",
                      "f6b24607-c2fd-45ca-ac73-4b85c0eeaa2c",
                      "332fae50-cc3d-4023-86a2-f87049412ef3",
                      "6f060351-5448-46f6-9f78-5be4a88290c3",
                      "f430c23c-faa1-412f-a891-25a19f28cf33",
                      "dc00888c-88bf-47cb-9094-0c26fcfa49fc",
                      "0da19b23-abfe-4a04-bfe7-e249d5e416cb",
                      "99e1a8e6-8cc3-413d-bb15-419d7db8dbc7",
                      "b087a7e8-991f-4edd-9466-84590cb29956",
                      "62c1da03-e544-4ebd-a15d-79aee0334c3a",
                      "60f8c5e7-6ede-48a3-8530-975d95a2090d",
                      "13ad1913-ff6e-459a-9947-87854421e53f",
                      "2cd75860-0fb4-48d8-872c-92a5e5d38320",
                      "b8a8f0bf-eaf1-4ac4-8274-71cad618c8e5",
                      "55b525d3-ba7a-4ca6-975a-8f566afbbf69",
                      "7a0f1f74-881d-4911-8f99-abe12619c475",
                      "bffa6dbc-1266-4fbd-a43c-68c075358269",
                      "810cbdd7-27f5-4e1b-9745-7c7da0fbff27",
                      "f96ea402-795e-4f1f-a3ad-1273f5fe89c1",
                      "c1072aaf-a710-463f-9436-dc17ad5b4412",
                      "0d219d4f-79fc-49f7-b7a7-a72ac3f66922",
                      "31df257b-d2ed-424d-a86e-ae249eaff14c",
                      "e7730b75-7bc8-480d-99ac-7fb90dd6ed71",
                      "cc59f8af-8b5d-44b6-bdf3-86ea4bad09a6",
                      "480fd7a6-a85a-4786-9c6d-e9f62e35f229",
                      "46fd3876-8171-4bfe-b6b0-20752b83dc1a",
                      "23d4e5f0-f37a-42fe-8e67-c343fbb5495c",
                      "8f8e5109-6979-411d-801a-5ec60dd1508c",
                      "83e8d4a0-9b5c-48c3-8db6-14b88df92530",
                      "45590122-8119-47e4-99e6-533e290bb5c0",
                      "478b7b9c-ef29-4dc3-89f9-b86dfdddf779",
                      "693dd364-6d91-486b-9a28-3f3607a70b15",
                      "6125ea66-285a-40a5-aacf-dc58e79eb40c",
                      "372f9d59-093b-4f9b-bfea-b4b1b96756fe",
                      "9695644b-3144-40e7-8685-a4505ab87690",
                      "fbddfdd1-3881-4853-950f-d216fad44ae6",
                      "d2c0a41b-7d8c-4126-94ba-256a010e25ec",
                      "d95bf31a-9045-4a19-bd32-32cb5f1bfb71",
                      "3eb62aa7-9299-4427-8d3b-91eccefa6abe",
                      "22a806cd-cfb1-408e-a4b2-b26f4aa235d6",
                      "3cf04386-aac3-46d3-acb5-03ed9c01e5af",
                      "1866bb36-fcbc-4a8a-8076-3654cf61f2a1",
                      "a201b9e6-6d40-46a4-8ac7-eb9440e28130",
                      "0a479747-08cc-464e-911c-c004e054ef9c",
                      "7f2fed83-c356-4636-9aa5-dd994df899d0",
                      "88426b1e-1bc3-4df6-b645-3b4352bc1188",
                      "3f5c162b-5f01-405a-a061-fdaa560dad0f",
                      "efdf2fd6-d7ca-4cd5-85bc-30ab1d9ef24d",
                      "562cf655-3681-4e29-8224-f99c5d224b4d",
                      "a15615bc-2dcd-477a-8274-1ebbd2b052bf",
                      "2472b8e6-9e8c-48b4-912e-a64e09e56b9e",
                      "397fe232-9546-4868-ae42-3a6961a2ed7f",
                      "03ee6c6f-3d2a-41dd-bf05-e9878e53e72e",
                      "f770ca48-70ca-4f83-af65-0034e732dbe7",
                      "588e9fdd-3c05-4fbd-9631-16488a59a2ad",
                      "1339d17e-096a-4ea4-84a6-a8b8c758a4a5",
                      "07797039-1f6c-4d24-9bb7-83d02dcd6c9e",
                      "ecb8bf46-13d7-475a-bf79-b206b251ac48",
                      "778377f2-0d3d-4b82-9bbe-61dd674758bb",
                      "d59a8a55-f47e-46d1-871c-077e17893eeb",
                      "3a72a183-8208-4254-984d-899e5287dd8c",
                      "f91aa9a4-3b66-490f-b600-0af29c406d83",
                      "258e86bd-63cd-43fa-bc9c-7797e11e5743",
                      "292e9b28-b60b-495c-8df4-7b70d02dca85",
                      "a7be421a-150c-4773-98db-ff775b57656d",
                      "1eb38032-5dbd-4e61-b496-7769aa59e9d2",
                      "0f41b4fd-905b-4389-8b4f-1f4122574a24",
                      "96f2d62d-7296-4918-a885-cf01b3071a9d",
                      "76bae73d-0be8-4b44-bdf6-2313c0496e0d",
                      "8da9cc94-f671-4cb3-869a-b906a6c3da78",
                      "60d8d4a7-809a-40aa-ac37-f0ff856f1caa",
                      "c7affbb3-a2e4-4084-b586-b55c4dadbf68",
                      "e36dd089-b437-4b75-bc44-b9a82137f705",
                      "a1cfad6b-9e1d-454a-be80-6179364bfbaa",
                      "fdb7c714-a379-4398-80e5-ad94a5e1d58b",
                      "03f14639-1884-4987-845a-5fcd93a42dc4",
                      "16e655e0-fe41-40f5-96af-cb1c114e96bf",
                      "e2922075-3d6e-41f3-96eb-a6f48678e9fe",
                      "4118cec7-7273-4e54-a0d1-49c4e5f4efed",
                      "b07c1771-c701-45d3-afac-92404d44bc8d",
                      "7009354e-2d73-4e08-af12-ad5063d3f431",
                      "2c888683-8ba2-4620-acdf-b6d7b02ef2e0",
                      "2d2fef25-1b88-4077-b90f-142b6d7c74d4",
                      "f7b706ea-d3be-4332-829c-e98ebc78cade",
                      "c4067735-7f7e-4102-bfb8-67becadb4d1d",
                      "bca57c13-395d-4c10-a306-16468a796d81",
                      "77cd2867-e2ae-4e91-9128-b3df6900b51c",
                      "0a41425c-0b6b-41b0-b911-978ea225bae6",
                      "f683c6e9-4ded-4aa7-87e1-46ebc7df2780",
                      "f075b83d-2396-47cd-b074-9d5de8e42316",
                      "c74a48b2-5834-4fad-b3f1-d740207186c5",
                      "89789f09-91cb-49b0-8b61-5e59e794ffd8",
                      "42021b56-e758-46c5-863f-60ddaa0172ae",
                      "7f007b55-597f-4e96-a838-4cfd44393a32",
                      "4fc96daa-b44e-424d-97b8-f6d3ec5ae0ef",
                      "d5d7346d-1660-4d16-9417-e43eb72389bf",
                      "fe8b5337-110d-494d-93e7-b2ed2fcb5521",
                      "fd43e5e4-767a-4d9e-bcb6-af9650da4fd3",
                      "5c92c2ca-593f-474c-b704-6c4237376673",
                      "f0715058-6e4e-4fc6-8cb5-f75630c56603",
                      "a06bf695-2f76-4dfb-9a6a-1eb7c052253f",
                      "eaea5955-6970-4198-b623-45d61c2c4b21",
                      "82fac525-3473-4f75-9ac2-7ea46ed5992b",
                      "818ce9b2-7a7b-4d1c-a905-b8164010661a",
                      "617f5e77-253a-418f-ba7d-525162baf712",
                      "7f7073d2-f360-441e-b77e-e3ca5e280843",
                      "64608ae0-d022-41eb-a146-393c72b4a0ee",
                      "1f4efd01-3eb7-4617-bbe2-9a80ddd310d7",
                      "6baa58d3-4a72-462b-8232-5318e2c606a3",
                      "ba567342-981b-4d0c-9ff0-6b6c95e41ae9",
                      "05ef457d-0167-4186-aed5-dd097b1abeb5",
                      "c7430497-4616-418a-8838-1aec66f2be04",
                      "8db21f07-9e07-41d0-8d92-f90e46d0c7a6",
                      "bac36fb0-29ef-48b0-961a-3d9ddd7131ff",
                      "ecc23cdb-7672-4723-bfef-3205b204e82b",
                      "973a84ab-6ca2-4e76-8bd8-b201902c64ea",
                      "ab17d73e-9d91-49ad-a49f-f9d6e0182a0c",
                      "61c00707-47e1-4d84-9ff6-82cadf1c9bec",
                      "29be443f-110e-4225-8ce9-84932dfac6ae",
                      "188b7e08-c603-4c06-a162-641c288fbee8",
                      "af061d30-1d24-468e-8140-70c9eb33599a",
                      "3e4f04ac-52ab-4bd5-aa17-96b5a4c8aded",
                      "f2e60f59-cf72-4c74-bce3-6846608b3f29",
                      "cc4ae02c-be8b-4929-89b7-f4f4e59c4640",
                      "98c54150-c04c-4f73-8226-0c2acd4271df",
                      "3b29d3c4-414b-4680-80b4-288a8c940271",
                      "d4b03a14-1910-4b98-aa41-cff168824068",
                      "9a898f33-e04a-4fac-ab6e-83b9f294851f",
                      "66c063e8-1d68-4524-8f64-7b2b1327300c",
                      "db490c2e-cf03-408d-8dc9-2e1c4900a0d6",
                      "0fcb34f2-00b9-497b-9421-0a034ef7c251",
                      "72b645b6-bb18-4dfb-aab0-940684559432",
                      "0b426289-9dc9-4e24-a1a5-664b90244e83",
                      "6c39552a-f0a8-4e75-9170-de37fe9fa2b8",
                      "2338b7b9-0754-4ae7-95c1-8f95cab76e3a",
                      "4c935b35-4fc6-4dcc-a642-64bec729b775",
                      "2e9a391a-e106-47c3-88cb-865064e201c8",
                      "9ead1a44-ac79-4799-a324-d522eb4e8453",
                      "a297d212-8772-46b4-951a-4e9436ec3fbd",
                      "bd16d5f6-b0c3-463b-aba4-f871789f4f73",
                      "bb591b22-2626-4a55-868c-66d020a1ef00",
                      "29bc69bb-bc26-4fb1-87c1-3ff11eeb7724",
                      "e4155124-07ba-4d46-aeed-0ce0880fe01f",
                      "23d1c7b5-c576-4e47-a104-ad25f2b95daf",
                      "52018830-a673-4651-90a7-1c7bc7d0be73",
                      "3042c998-2752-478d-bab2-7dedba9c2490",
                      "390c810a-c4ec-4341-a449-d38332d00488",
                      "56935455-b3c7-43d8-9fa6-692b538c06a6",
                      "e233e220-03f8-4382-90c0-e1c43e5d66f1",
                      "fae690cd-560e-4fc4-90b9-7f9869634366",
                      "eff5c50f-03c6-4c84-a48f-dc74a91a4c43",
                      "e780872d-1d05-43b5-9920-5b727454e990",
                      "a536b99c-9a12-4440-993b-3e6aaf2ba2e8",
                      "3a63c4ac-fb1b-430c-a3c8-f4d709b005cf",
                      "f8c4b209-fea1-4a3b-9c3c-bab1ac490aab",
                      "87e1a891-2f03-433d-83ed-1dfad8ca648b",
                      "ec638301-c735-488d-9761-2a0402897d3a",
                      "5c0a92d4-d45f-4481-b410-487ad54ed9ce",
                      "da6bb3ed-8455-41eb-9089-4f9dd2ecec8b",
                      "59178d5a-5a21-4e9e-a45b-6b56983c9b3e",
                      "f312c31f-3839-42b1-81a8-9469154dd847",
                      "96d824b5-ce6a-40df-826b-3f8a260495ba",
                      "c609593c-55e9-4f70-ba28-69b940148999",
                      "f2a51cf1-d593-4e5b-bbc0-9ef9bc43a4fe",
                      "43de193d-eea0-4756-9b3c-10821afa2f0d",
                      "0301bf9c-92ad-49b3-a9a6-181fce2230d0",
                      "b016567d-763f-4c4e-b13b-81bc1a21b2ad",
                      "2df620bf-a43f-4a16-a278-ac1d232bffca",
                      "32152863-8a78-46c5-be02-6fbcaf93e89a",
                      "39aac2e9-b386-4404-a2ea-dc29edb75334",
                      "b076766f-8bf8-430d-be46-e8b1d91566a5",
                      "9e0528de-a117-423b-97c4-522d06881c3c",
                      "0a19885c-2302-4c0a-bcb6-4a0c10c45657",
                      "592cbcb8-4bed-4be9-8b4f-854f6fe6aa20",
                      "aeea8141-c513-4297-a6b6-50e405e11a7f",
                      "335f84f5-427f-40c2-8449-cc6eb53b4f53",
                      "24c790bf-6dfa-47c5-afbb-12c20f3366ad",
                      "2bd2627f-980e-4847-9c43-81d40e0a84ca",
                      "1489702e-edba-4bc1-a66b-cc58e7ceccf6",
                      "60d80b80-0174-4719-bea2-c8a47c5f6c99",
                      "fd9ea88e-738c-4418-9438-d081bd07e586",
                      "8eebc514-d274-4869-9715-0b8908c5562f",
                      "d70fdbb9-b52c-488e-9c50-3217893fb5ec",
                      "572cba89-4118-4376-afba-1156ad3ac607",
                      "ffb5f44f-e90c-460a-84e7-27ca3d8d36d9",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.2 Kapitalgüter",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Herstellung und dem Transport von langlebigen Gütern und Infrastruktur, die vom Unternehmen genutzt werden.",
                      },
                    },
                    inputParameterIds: [
                      "80ef6d4a-b7a8-4ecb-a393-61fcbc880501",
                      "c048ac94-3b9a-4c92-80d9-acd08d9a1a40",
                      "cee4458c-0412-481e-a59c-7bb3f702b451",
                      "dcc903a3-5304-41f3-9eea-64238ec13962",
                      "3198c5cc-e0c0-4ae2-803f-a12b114b1cbf",
                      "e06f1525-7b70-42fe-a05b-7fb61d094bcc",
                      "1eeab337-4447-462d-ba0f-403e5801ed65",
                      "f4d5a75e-4768-4c22-921b-bece3509fb7e",
                      "44db9b83-61f4-4bd9-a2db-cf046ff28453",
                      "45301bdd-1b26-4839-81f8-f60cffdb5b43",
                      "7e417ea9-5228-4b62-8701-023f4fa01bbd",
                      "e27e5d3c-bcc2-4f52-8555-aa592d6c0078",
                      "67191839-9a96-46d7-aad9-62a0e87db95e",
                      "e5cca2b7-4303-4653-b46a-bb28061b5319",
                      "4e250aee-2680-4c41-b7a1-13d69f634965",
                      "36fa9eaa-b5d3-4aa1-8c5d-00f54ef3e7ec",
                      "dc74d744-6187-499c-8357-dd20d3313bb9",
                      "c1fd3902-c6c4-426f-a817-b7ebac342dfa",
                      "fa664242-14ad-44a7-a1f2-bb2dd976124e",
                      "a3ac8e3b-413a-4f33-994f-eaa9a5fad981",
                      "c4dae483-1b08-426e-a5a8-450783635bba",
                      "199f8c14-f74a-43f8-9018-d8e12bda1f99",
                      "9621911c-5812-40d8-bdc0-95400cae1f23",
                      "b793c89d-6d68-41bb-94f0-50902d1793b7",
                      "c930faee-23c3-4179-930e-961661937179",
                      "7d828f1a-9b98-40c2-aa31-e5e40146e6d5",
                      "07daff3c-9c02-453f-bd37-937e6695e6d3",
                      "5c2330e2-c15b-4da0-9f85-487af99eb67b",
                      "f2c618e3-f8a4-4b82-a88d-e4c9e3419584",
                      "b95b2aba-81e0-44b3-98ca-52ec50ddadd6",
                      "5993cde1-0e65-4540-b2c5-298c9af71e28",
                      "2692248b-873c-474e-963e-1a9003c1ba45",
                      "2cb226c9-52c4-4a38-b709-7d67979ad5ed",
                      "5cb55eb9-4e53-4d50-8c90-b2697a638130",
                      "a271b57f-e6ba-4994-a884-c5da1fdabc4a",
                      "4e1ee8f7-2272-4a97-84bc-406544118d78",
                      "0c517cc0-0d6c-4d44-9527-64f949bd6852",
                      "5db1b371-2423-41e0-a6fd-d3d5c1eeb436",
                      "eb98deaf-c5a9-479f-a4ec-cd055499a5cc",
                      "f516d774-d279-4414-8ce2-92130fc9b89f",
                      "50ca5049-9c0d-44f5-8054-d068f4f1d99b",
                      "9c69934d-fdc6-491f-b46b-6f3e5d253fcd",
                      "92545102-83f2-4194-806c-9abfbadb189a",
                      "331c8d43-0558-4f97-bfd1-00af1c68d498",
                      "74efbc53-ac71-4406-9cf5-5489b243b369",
                      "0fd1dbb4-343f-482c-9963-6b8645cbb1de",
                      "f4956f7d-1d31-47a1-bdeb-2b5817a30eaa",
                      "540f658b-711c-4344-91eb-dea29ffd0e30",
                      "0f81f7b1-cfa0-4eab-b379-d3b576c672c6",
                      "361864b9-4260-43ad-83fd-d89b637eb22f",
                      "cbd5664e-bb61-46a7-8be8-345e01978b47",
                      "2e6fb565-cf72-4b9e-80f5-c29ed1a03af0",
                      "1d0bcc7a-6193-4edf-8147-fcecfcb35b62",
                      "9b5206cf-2f1f-4ee1-8869-e1df78bf04e1",
                      "8f886939-5a40-435a-ba7d-ea3b14b5e46f",
                      "f25ab2ad-9512-4b97-9c84-ce579e4cef7a",
                      "bd185060-dd5a-493b-ac55-8af61bffd8d3",
                      "5f128d27-3bed-4544-a49a-c2ce2fac473b",
                      "aeed5046-8ac0-4f11-a9a1-ea4dc84938cf",
                      "29a8ca66-46c3-4dc7-831c-8d00c480ee73",
                      "fcfc0945-c4a6-46ac-aec8-b3f3b5250ca7",
                      "c5e0fa1d-acf7-451b-a0b9-d345f49c303e",
                      "52a084a5-05ae-47bd-96b7-538e4f3b7f05",
                      "47b4aacf-3aec-4d4d-a097-01c839273c25",
                      "c12daee0-f081-47e0-9317-e3feb648c9be",
                      "ad8c687f-5d15-4702-ad19-a0bdea8c4360",
                      "146da9a5-2f4b-4888-9053-29e9bc2f7fb2",
                      "b8a52cb7-5a3b-403d-b024-76c30dbccafe",
                      "54c3e2ea-29d2-4342-8e92-38838a2250a1",
                      "23e6003c-2859-4cf7-b4a6-6ca948009101",
                      "979e9245-17a9-460f-a6a2-1b76ed488039",
                      "7df87a22-4563-49af-a60b-b00bbd936809",
                      "4f6ca6ed-1ab3-4d18-972f-e59d6293a72b",
                      "50be98fd-9a85-41bd-b755-13c261cf8f33",
                      "bb99f3fc-dcd9-4f48-9dfc-f20f06beccb8",
                      "87115b7e-4df7-4960-b17a-672859c3dc56",
                      "50e2c2e6-7b83-4e5c-a4e5-2c9aff39fe1d",
                      "0e632db0-f4cd-4708-8580-80fa9afd74e0",
                      "bc6b5750-c19b-4289-a9fb-01ef06f01378",
                      "642a06f9-3d48-4ac3-837d-937815786f91",
                      "66436b58-5c5c-4f63-80ff-85cadc3475d8",
                      "8499715c-fb4b-4ec5-b947-0640e38115e2",
                      "b4767d9b-6cc2-4201-a07f-098fa62370cc",
                      "06834cce-be8c-44c8-94cd-4f4346190a92",
                      "f9f9cbe8-4cef-4b2b-b5ad-cb18cdce1cdd",
                      "b8a908b4-a91f-4238-9b01-1a7df3aadb33",
                      "73883850-c500-4c5d-9663-b9a80b1c6e70",
                      "b98b8f79-a68d-4e27-b17d-7a9594209f7d",
                      "3a11768b-951e-4c1a-9120-7144b99ad534",
                      "0500503a-0ce4-4f23-8ad1-38158512226b",
                      "d8e46dad-a794-49c8-8fa6-b5732ce04f6d",
                      "ffd3b7b7-1bbf-423b-89cd-533c94021695",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.3 Brennstoff- und energiebezogene Aktivitäten (NICHT Scope 1 - 2)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Hier einzutragen sind alle Emissionen aus Energiezukäufen (Strom, Wärme, Kälte Dampf - KEINE Primärträger wie Öl und Gas), die an den Endnutzer WEITERVERKAUFT werden (d). Hinweis: Laut GHG-Protokoll sind in Punkt 3.3 folgende Emissionen zu erfassen: a) Scope 3 Anteile zugekaufter Brennstoffe, b) Scope 3 Anteile zugekaufter Energie (Strom, Wärme, Kälte Dampf), c) und deren Emissionen aus Transport und Verteilung, sowie d) Emissionen durch Erzeugung von Strom, Wärme, Kälte und Dammpf, die an Endverbraucher verkauft werden. Die Werte für Punkte a-c) werden automatisch über Ihre Eingaben in Scope 1 und 2 erfasst, daher sind hier nur noch die Angaben zu Punkt d) zu tätigen: Tragen Sie hier alle Emissionen aus Energiezukäufen (Strom, Wärme, Kälte Dampf - KEINE Primärträger wie Öl und Gas) ein, die an den Endnutzer WEITERVERKAUFT werden.",
                      },
                    },
                    inputParameterIds: [
                      "a191ca16-fa80-48c6-8cef-17e852306885",
                      "bf5bc407-05ca-41fc-a968-3ded73515cc8",
                      "52fca828-ccc0-4ef9-93a9-229c97f97662",
                      "950af510-05b5-4d6c-875b-a4ba2f57f5dc",
                      "949d697d-dff5-4b7f-b824-45df974107b4",
                      "0cb570d5-1733-4b7b-9dd1-59867ef1a92b",
                      "b85ebfcf-44c7-4536-935d-805c56f5cccc",
                      "b9333dd2-536c-4503-9daf-6178f4104c88",
                      "550eb9f3-4282-4fed-a56d-f486a41fb673",
                      "4bfba339-3fcf-42e7-a8e8-a7250ff0f433",
                      "fa9936fd-3dbd-4c95-b705-86e288c63499",
                      "0ea14112-da99-4427-a47c-a974586480c6",
                      "d409e52e-75cc-473f-8c05-e13e9011f625",
                      "5b4ae8e2-4ff2-42e7-8140-4042e39ef3b7",
                      "99c29c68-e881-4f88-8c99-68d9b66681e3",
                      "1e309c59-1575-495d-aae0-f75a888c6f82",
                      "94acb00d-fc07-4121-a871-19842834094d",
                      "d9c183bd-0bcf-4b2d-ac8b-bd3a4d4c3c23",
                      "1372151a-8e65-459e-85d1-7f548f75e582",
                      "adfcde3f-fea0-4a30-a1f0-f19949658012",
                      "52ef37ce-9817-4581-8f2b-fe09a82adc5b",
                      "b2f20b35-5a0e-4f92-8673-a40ca57e3a85",
                      "8b6b67e4-5c3f-4c98-8f63-7a9955d636ca",
                      "d5e3de40-ffd2-4b7b-a71e-bff3748cfce6",
                      "e5be9e23-bfd5-4443-80ce-ecd35037bb4c",
                      "a5bd33e1-08bf-4bf3-9a05-8628f97e010a",
                      "4ba86f78-0a07-4964-a1da-25014d3659ad",
                      "60833faa-100d-441f-be03-2ae0385ea58f",
                      "10f31066-a617-4123-92ff-63a877dcc392",
                      "a7c776e4-7ca3-497f-8027-71d42baa3432",
                      "b38e6d2a-474d-4227-8c50-3ce208597648",
                      "c02de95b-ec78-4e21-93e7-ef9144c720ed",
                      "382d6611-c4e7-4357-bbb6-7cfc4cb3c495",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.4 Vorgelagerter Transport (Upstream)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem Transport und der Verteilung von Waren und Dienstleistungen vor dem Verkauf.",
                      },
                    },
                    inputParameterIds: [
                      "c0ad446e-8c60-442e-aa91-f7241a61fef1",
                      "051f9e0d-27cb-45f1-8bc6-2667bf88d145",
                      "9be71de6-1fe0-4167-9dbc-5c3a4185a623",
                      "8dbdc207-861c-40e3-b28b-d298c1e0c13b",
                      "fd845ada-7f23-4917-b8df-74dc7b0d6414",
                      "0e2b5bff-2a51-4fd4-8ce7-3b00f24b81bc",
                      "ace68da6-4004-400b-8856-cedbab00bc30",
                      "e666c6bb-e539-411c-a537-513cce8a6f8e",
                      "6f4f67d2-8ca8-419a-8dbb-0c686493657e",
                      "ff3fef99-73f4-4369-a066-cf70aa7b348a",
                      "96a63034-500e-4c83-bf72-d17b99ba5b82",
                      "76af4349-beeb-4b23-a523-9f8c3e48739e",
                      "451154d4-e155-48b5-9797-3c7581056805",
                      "089267d4-8197-4972-91bf-cc65329d8079",
                      "30152b18-98d8-476f-bf7c-755688b24779",
                      "3e84a697-57ef-41be-9596-8cec4e562d50",
                      "86ae4bdb-b0cb-48d7-bc33-8171a10d7065",
                      "257ebe43-9514-464f-854a-479a476ff31e",
                      "cf148579-8113-4d32-8f6d-8777315e1c6e",
                      "551457f9-ef45-4c77-817c-e5f0ed63e3ca",
                      "05e27559-eae0-4293-9f82-e162f2a8260f",
                      "23a01013-08a5-42c9-9ce2-a075006f3137",
                      "dcb8ed75-3d48-4ffc-93e7-63d0a2ee90a6",
                      "0a2a7fb3-e1da-4e08-aa7c-266f5bf38ea4",
                      "84e43800-5835-4a1c-a112-1c4be5074d76",
                      "e4c75788-782d-4ad1-a332-2f77bd7a98d1",
                      "2cdae920-a99a-42a5-be40-1fe1544c6f67",
                      "add012a0-43d3-4bf8-a04b-b3ece4b488f6",
                      "067d7c74-7d11-4045-95f8-e67fec02cfd9",
                      "26e4e59b-4b99-495e-bb1b-793e90f9974f",
                      "57eed5d9-9f26-4b71-b2ca-a342429041b9",
                      "862b077b-a682-4a40-ae89-a8db6e28df09",
                      "07021a75-2b3d-4f7b-a7ca-87c64c41aa73",
                      "42b3a4eb-57e9-4039-a0e7-f26366d6c54f",
                      "080fed14-7ad8-4fbd-bea3-1bb04e1a7ae7",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.5 Abfall aus dem Betrieb",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Entsorgung und Behandlung von Abfällen, die im Betrieb des Unternehmens anfallen.",
                      },
                    },
                    inputParameterIds: [
                      "c78e1ffa-2632-42b4-a705-7f551e49bd98",
                      "8272f34b-b7d1-40df-ac8f-09b187f6eddb",
                      "c135ce75-6f7a-483f-94ac-761667dbc904",
                      "7d7e7bec-ef2f-4c0e-8802-13b672a44d2a",
                      "0285fa49-03c7-4b65-bb60-ea81e0e84cc9",
                      "9bd285e2-bf2a-47e6-aa0d-86d2d4e455c9",
                      "fa80e844-6b01-478c-acb6-1c7e139a83c6",
                      "81e59319-1bce-41ad-bd80-47dfe76b4912",
                      "4d521904-5148-42bd-8d43-5bcdfcca9927",
                      "84b28a11-7ae6-48cf-9573-0832c2b18179",
                      "596c2b63-2534-4710-b4ad-d5b636be095b",
                      "f0f658ea-5c1b-404c-97d4-fd195a180169",
                      "edafa097-1c5c-470e-9dec-15bdf2bc196b",
                      "b5a1acda-1e79-49fa-8807-448d125636a5",
                      "99031096-d586-43a3-9cb0-b4d0bb89778f",
                      "aa947286-e108-41d5-adb1-8e082c351403",
                      "71b58ff9-9935-484b-b5aa-ea7a809377bd",
                      "c4bc4f02-dbf3-49fc-bbdf-e9d59b4bb1e6",
                      "f1439430-b50e-4250-926d-28e779ffb8ee",
                      "d700cc49-5333-424e-8f2b-bfa1ae277f3a",
                      "65895ceb-5027-4b9d-a3d4-0ff4634a2d69",
                      "f3429ef5-c269-4f4d-801f-dd5c282394d5",
                      "b021d896-bbe7-4e96-abb7-197fa2bb437a",
                      "d75c9cca-e5b7-4414-883d-af9dff058157",
                      "f6bbf1b8-fab9-418b-a5c3-ab675b05fa7e",
                      "c9a83e83-ff4a-4dd9-815b-0f0939cc34ae",
                      "a2ef771e-b72a-49ae-ad45-6a3cdf5b487d",
                      "af12e83a-9363-4223-be70-c55803769a5d",
                      "ab9fe19c-c2be-48da-89ad-62ec9093fed3",
                      "51dc805f-8a99-43e7-83e5-ee0ef8102220",
                      "db9f5787-d641-4b42-8695-de5a799334d7",
                      "5aba25a8-4f4e-419e-b6db-fda261c62faf",
                      "7c51cb1c-9b4d-4ec2-9491-62d618d5e6d1",
                      "877438d0-31cf-4249-98e3-7a2d79e37685",
                      "078370c7-4e32-4253-b61e-4e9ba0acb9b9",
                      "5e3066f1-7cdf-4a0f-91f2-1a428504b943",
                      "ca0800af-94d9-4c7b-a135-964df8e6363e",
                      "ec7da0d0-8dd0-4982-9390-d4c6d06a3876",
                      "070796ea-d660-4511-9457-fb020d2d4840",
                      "3426bb5a-092e-4ea2-9933-e08def46a156",
                      "9ce3f755-06cd-4449-95a5-e3d514dfad67",
                      "e6ff4d3a-7fe2-40b9-aa20-c8acc0429e7d",
                      "cbbfb9ea-3e35-45e8-97da-9091b35f10c5",
                      "2e940d70-c90f-42f2-b689-a13bc9b7a342",
                      "c0dd6ee4-cc20-4178-a40e-5de26fa27c32",
                      "6c33ddfa-e52a-4dec-b0ad-ed3224620161",
                      "89edfcd1-4d66-49ed-bbae-a5d011885503",
                      "1b56cf00-6dac-4797-8bfe-286a1925c490",
                      "a7a7b2ec-fe2b-4f4a-83d4-23dbbef06f70",
                      "d0a60c25-cc18-485d-9ca3-67bb2a00bee8",
                      "5d8d515b-1453-419a-a05f-28c4c35e0aa7",
                      "2aad1e21-a4bb-4c68-adbe-a4129579cea9",
                      "ec050404-57fb-4e07-95c5-22d99c89acb5",
                      "c2d86d1d-0677-4b7b-abd7-61556df9e5b6",
                      "4399fe0a-120d-418d-8a82-ac63d94beda4",
                      "5dd8aae9-0b86-4b11-8901-e8383a5812c5",
                      "ad47fad2-92af-466a-b078-dbe79283ac3e",
                      "a51cd04b-b1a6-41c2-a373-6ae949a86572",
                      "ad9da5c9-28e8-47ae-a11a-fbf53978ceac",
                      "5c0740ea-06eb-4b49-8253-61313c6e0245",
                      "6cde254a-8d2e-4733-8470-99aab140334b",
                      "d30c98f4-0535-4809-bf9f-8c4c73bf7d9d",
                      "0a715122-9104-46b9-901e-76c2d7077cba",
                      "d70ed8ee-4a38-47f3-9d10-118e1bdfa2ac",
                      "2d27503b-b5a1-4e2d-9e53-5c38ddffad44",
                      "58c74c67-a245-4269-b04c-79c6e97b4eb1",
                      "54f9ff9f-7080-4bc9-bfb2-be10a011ab4e",
                      "a7b6fbcf-4788-46ef-b3ec-1ea6e7f1d3b1",
                      "54dfe2b2-6074-4c12-b365-46006425944a",
                      "8f4338da-fa3a-4243-b7cb-00b01145093d",
                      "c29599be-2fff-4a98-817e-081a488fcd18",
                      "2522219b-5f68-46ed-bee5-af7d48e28941",
                      "c6711c57-1fca-4c75-a498-edef8881b70c",
                      "22eeb663-bb61-473d-b4a2-4be74f1aa73c",
                      "087ee302-80c1-46fc-aa10-04475413c63a",
                      "8d9feae3-516a-4446-9e1f-6ede5fe043a3",
                      "5874468d-a96c-41f9-bb92-ec26c235aec6",
                      "d6db6d19-e0a8-4594-a66f-93ab0061027e",
                      "8d97f66e-6c59-425e-9f37-70589644fac6",
                      "83f59637-3f78-4c64-8426-2c6368ed3884",
                      "7f1ed80f-a619-4bb7-8bb1-18556c73545a",
                      "8f735d88-7876-4b71-b50d-1b675667860c",
                      "a187963d-e344-4f72-a3d6-a4536830645e",
                      "2ea34563-d5af-476e-b822-e79c7dc8fcca",
                      "d860b9af-b44f-47e5-83c9-10083ba42ea1",
                      "258bb6f3-9497-4e04-9ff9-fcedcad87ea9",
                      "5978dcc3-5718-4edd-b32f-fb0adaede825",
                      "794c9e79-f6e1-4624-8f45-93c8caa578d4",
                      "b83e31e1-679d-4839-8dd9-03e299002467",
                      "7b56aea3-40a2-4212-8086-47ca40e7411f",
                      "1d2c6cdf-5438-44ec-9f4d-09760797ba16",
                      "4598ecc4-a163-440c-a696-baacaf17dd40",
                      "8fd9a08a-8ca8-4116-b286-a6c1c0fff3f0",
                      "6191b89d-edc1-458a-b418-2334450acee4",
                      "06ac6914-147a-4ae5-b854-e7c49b4db4a1",
                      "d8e0938f-ac66-4770-a029-3d4249139d27",
                      "d26720e1-7c17-48ab-9185-5a6b1e86c8bd",
                      "d45f66d5-13d0-456f-8ca4-eee31bef53af",
                      "823568a5-59d4-4c96-9c87-35ab1950b6c0",
                      "d6b9b41f-cb62-40ef-b31c-4af3a52f42ea",
                      "1c2c9884-6e14-4804-bdcf-13f14c7f020b",
                      "4f02ba33-e12e-401f-995d-99b84ad2541f",
                      "abd5693f-f8b7-47ee-846a-604c24bae1a5",
                      "248b0084-c797-48af-be9c-067bc3c0cf61",
                      "2d139d35-adc9-462e-8e54-bbfa8fd925da",
                      "20f38ed6-2a48-439f-85be-934c4392f832",
                      "36c49812-e25c-4fe1-99ca-289d425f9bf7",
                      "37b8f712-f084-4eb4-8faf-aa1dd857cd37",
                      "3b380dd6-852b-4ba0-ba67-a0f7b6491978",
                      "8055732c-9502-4f7c-a290-0199c82044dd",
                      "24ba2448-1f70-49d6-aef0-9676d209d284",
                      "d4ef4dd7-3123-4e0c-bcd1-8f81889cf3e6",
                      "0d87f536-2c95-4218-9a56-6baf69be6acf",
                      "711a156e-6028-45d3-bd11-dca0d8412e05",
                      "7716bb3a-a8e0-49af-8d0d-a1c90579a722",
                      "5c8ffcb0-cb84-4dfa-a359-e5574e343d19",
                      "77dd2f59-204f-4603-813e-78ac7c7d0af8",
                      "0dbea2cf-eaad-41c7-ba64-c734ffbab616",
                      "75e004f4-2c22-4661-b907-3c7e51dd9023",
                      "7a8f4245-81d4-4e63-85bf-53d394efd3a8",
                      "44e8cd09-d19f-4f1f-a55a-822da884aa6c",
                      "c97b748a-3f54-4b03-9ef7-f0a605b02f16",
                      "5147795c-d7f8-4470-ad72-adf96dd1404e",
                      "0f0eb300-ed0d-4540-b278-7d7ffb5b6088",
                      "06f2ee5c-2944-4569-abf8-4e1a50322c66",
                      "c95ac134-b26a-4508-9cee-d8d3fca25d74",
                      "f5bf3452-ec04-48ef-b23d-ef041b317657",
                      "eecbaa40-90ad-4019-90c6-4e53178fe01d",
                      "117c286c-e3dd-41e7-9f75-e29801bed044",
                      "642fe127-2cab-45fc-a2c0-dbadc2f50737",
                      "be552d3d-6ee3-4676-9e5a-d87ee260b7c2",
                      "09990fb2-03b2-4cc6-a431-a7a2e0a0f942",
                      "20719d03-d478-4875-9dc9-c345737c84a2",
                      "89025ee0-b2f0-4bb5-9b0d-8a42c5ef0d76",
                      "69f7fec0-0252-4d73-b513-16bc52571f99",
                      "ab014c7e-b98f-4e37-8b91-204fc3c9271e",
                      "dfd076fa-ddd3-4537-90ef-d5b4d3cc608b",
                      "5d69d731-47c5-4c2b-829d-09b11ca2db5d",
                      "a901dc87-2b6f-46b0-b439-8521037742cb",
                      "085605b5-b56a-4a46-941d-f1d0ad6865e6",
                      "9795c166-a5d2-4ecf-b02d-4748ba2d52fc",
                      "90f4955d-43bd-4347-9291-00affcdcdc01",
                      "bb4b105f-5c3c-4cd4-afcf-ae84f12939d1",
                      "2901649a-90e3-4ddb-aa35-d792c0ed1cf9",
                      "aa9663a3-63c6-41dd-89ec-61e18cd4c6e7",
                      "828eb227-3860-48d0-bbb0-b09e567e4788",
                      "686a8958-b1b8-4bac-8a04-a965eca26cc2",
                      "661ff3f5-1086-4947-a92d-4ebe266d6a54",
                      "9c050361-fc2d-4515-8d85-5b793041301b",
                      "f82a3591-edc2-4cc5-bcdf-a016280b965d",
                      "7e625f04-3da7-4f81-9131-b2b3350a40fd",
                      "19e3a362-9803-4796-b49f-04c1c869645f",
                      "5fa682c4-b0aa-4d09-b5d5-9e0c45c3cf06",
                      "8df88b52-e891-4f7b-8bb1-fa2bbf0ac849",
                      "63482b82-315e-49af-882c-4d93087687ed",
                      "29abc2ed-2018-4a23-b478-cb7e7317f72b",
                      "9cfd35ae-d49e-46b4-b40f-ecb2fe2eccbd",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.6 Geschäftsreisen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Reisen, die von Mitarbeitern des Unternehmens für geschäftliche Zwecke unternommen werden.",
                      },
                    },
                    inputParameterIds: [
                      "280036f2-88f5-4528-82e6-efc2ec7217d7",
                      "3829abbd-f18c-466b-b3b1-f5612eeb5bf3",
                      "e3a67e71-d12f-4dce-98ae-838b8d36a126",
                      "49d3bff1-3eb3-4404-9366-1c5b847e84f5",
                      "5ce90cc7-691d-4834-9855-e0ddb2880da8",
                      "e98aa088-e995-478f-8123-4e0e83ca990f",
                      "bdf13404-e115-469e-b310-f2b075476d13",
                      "f97fae04-e27c-4996-ad2e-142d9915040a",
                      "010eecda-d143-4ae2-b436-02e506399308",
                      "c15396fe-c84b-49d3-88ba-eaba76016eae",
                      "353063a7-ded5-4554-ac82-a59ed98dce98",
                      "e12866ad-58be-4cca-8803-af43c9c6e1a9",
                      "cc2b544a-490b-4823-b1a7-89a992859f6b",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.7 Mitarbeiterpendeln",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem täglichen Pendeln der Mitarbeiter zum und vom Arbeitsplatz.",
                      },
                    },
                    inputParameterIds: [
                      "294c2f93-fd5d-4366-999c-497f2b941423",
                      "9c4d120c-f03e-476b-bc12-024771799bb2",
                      "6c248eb8-7af4-4267-bc0b-0fd9b6d26dc4",
                      "7bd5532d-ff4e-4a01-88bb-19e26eb828d8",
                      "33c93176-0ebb-4012-b686-c2114bae5726",
                      "5d596056-181c-4acc-8008-0ec119a213c8",
                      "4bcc647f-9b73-4a2a-8540-2c7b768feee5",
                      "fe7d4565-8748-46c9-abec-c28f3a6cc8c1",
                      "208f226f-c526-437b-a157-5917903eda11",
                      "70486615-ed87-4307-9d99-3a2fc4ff7dea",
                      "a997cdf5-9dc8-4e72-b82b-a723d093ebc9",
                      "dffea1c0-2a2c-4c20-b3ae-433ef1177d02",
                      "f9d15a45-ca02-4b64-84c9-0280c5e99816",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.8 Upstream gepachtete Vermögenswerte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Diese Kategorie ist laut neuestem Stand des GHG-Protokolls nicht mehr anzuwenden.",
                      },
                    },
                    inputParameterIds: [],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.9 Nachgelagerter Transport (Downstream)",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus dem Transport und der Verteilung von Produkten nach dem Verkauf.",
                      },
                    },
                    inputParameterIds: [
                      "a1c0d456-035c-456c-a168-db9e1afe7f9c",
                      "89afc97c-cbbc-4943-9020-ab304e40d9f6",
                      "3226c636-e00a-4f94-92a4-d1929010df80",
                      "0a938f21-667e-4535-8d5e-920f2c8510f3",
                      "6d29bf62-7840-48d1-8859-d195f266db77",
                      "e2c0322b-9b75-444c-a259-1a61885bd832",
                      "65a4ce60-64e7-43d4-aeaf-74318b65a765",
                      "06ab22db-a47a-415d-9728-41efa790df01",
                      "b329928d-579f-42ec-b570-b8b1b649311c",
                      "ccc2bf76-fa89-4c4f-951f-40e9f0e2cd43",
                      "3ae05064-77c7-407e-83d7-6989551a7324",
                      "cf842767-f7db-42e8-b0df-723125e58dcc",
                      "e8cde283-f1af-4d17-85ed-81376a8715d3",
                      "8c09add0-cc3a-40fe-be06-c705da8f2406",
                      "89d701af-99d6-44bb-b71f-5f66767c3ab5",
                      "f99591d1-2b8e-4a20-9db3-1caf80d75dca",
                      "7b63496b-e463-4ef1-be56-bfe2c03bf615",
                      "829ba614-7e24-4de0-8fd0-a3d04563dc9d",
                      "b8471e78-7ff8-43df-b6c3-2e36957174e5",
                      "a350d8c7-acd8-4fd0-b331-26614a7f7334",
                      "0783251e-e1eb-4e44-9da7-ecbb9467d1b6",
                      "6990a26a-6357-4cef-b275-10e1dec9db7d",
                      "fbe928cc-6ccb-4906-bd01-bb821bbf8819",
                      "a0d330aa-a6b5-4fd8-9308-3f59076117b4",
                      "0fe8dd64-a0ef-4c44-8aff-c0a4779e5da0",
                      "c8d4c96a-1b3c-40d0-92b8-459cb4c4b54c",
                      "54e70802-62d5-4b30-92e5-14586af23e59",
                      "67b0943b-79ab-4b86-bc12-396b7ab9ef7f",
                      "458f5ac2-f229-4d86-9391-b66c39f43fef",
                      "e694ba4c-4317-4d20-9526-787b5aadf232",
                      "84d61159-3250-4e04-8f49-73538d627e92",
                      "79946640-683b-4b63-b2b0-99b80d781cc4",
                      "8def4c54-0278-4c92-b0f7-871174faf2fa",
                      "e9ff3ab3-055e-4524-a9c0-6c297a1d1267",
                      "740d3d07-7bda-45ad-ad96-e085837a7b66",
                      "3b4ff239-ecd5-41dc-8093-77046e48304c",
                      "33c5ec5e-014b-4f36-afb7-7c2795652953",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.10 Verarbeitung verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Weiterverarbeitung der vom Unternehmen verkauften Produkte. Holen Sie diese Informationen über Ihre Kunden ein.",
                      },
                    },
                    inputParameterIds: ["2707ceb2-de22-4e4f-97c6-74115bd6e543"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.11 Nutzung verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen, die bei der Nutzung der verkauften Produkte durch Endverbraucher entstehen: Nennen Sie im Kommentarfeld den Namen Ihres Produkts. Geben Sie dann zu diesem Produkt die geforderten Werte an. Nach dem Speichern kann ein weiteres Produkt hinzugefügt werden. Erfassen Sie so Ihr gesamtes Produktportfolio entsprechend der Energirträge, die diese im Betrieb benötigen.",
                      },
                    },
                    inputParameterIds: [
                      "d72f585f-28b3-4e4c-a883-24230ba11d4f",
                      "4b046b2c-3dff-43fa-a871-6376651cb6f0",
                      "ad56d8e7-c05d-43df-9f17-38b737d5ef0f",
                      "5fc84b98-601f-4305-9be4-8bf16a8a64d4",
                      "64d1e767-908e-44cb-8617-c805f7cea58b",
                      "f10d8ee0-0caa-4902-a687-757dabaafb92",
                      "673ed130-e80e-4dfd-83a2-163c6c441fcc",
                      "2d9803a6-da63-4689-9da5-01cb00af0594",
                      "9f25a6fb-aeff-4f72-81bf-213fca76006e",
                      "2c0295ed-a235-4480-b2ee-9ad95613b2dc",
                      "0887a9c4-e822-4043-ac53-2e4c1995d5b4",
                      "057ef39d-b25d-4d0b-a23c-4b69fb93c8e6",
                      "f9711b1f-b96c-4985-8d5a-2468cf144fd4",
                      "f0f5a504-19ac-4c70-a6fd-5f8ec5fc5bce",
                      "ed3794b2-24e1-42f2-81f3-eb1182b2118b",
                      "fbfe3896-bec2-4c6e-ac44-093a7ea82b5b",
                      "2871e1fa-771e-491d-b58d-2b7826bf6cea",
                      "a8cb58ba-558e-4560-a576-3d5f25ce73ed",
                      "ff3ca367-7711-44c9-a361-a22a5496da13",
                      "9ddf7cfe-5623-4f2a-8073-c7b9c12bd905",
                      "d9e1523f-6e0e-4b04-8acd-94e67ef56733",
                      "571b8f81-c6c7-4f0e-a1f5-2bda735cf192",
                      "22e4aeee-e9ce-4a86-838e-750b41531a30",
                      "0ec32587-4730-436c-b0ca-d9d85d45c906",
                      "6340ab38-3422-481e-94fe-4d60f90cc533",
                      "b29d78e6-eb2c-4d57-a64b-678c61e853b4",
                      "58623876-d38b-469c-aa69-c6aa5315d5a4",
                      "8b44d804-6f63-45b2-9ed8-581d35d46155",
                      "37bf5a4f-9399-45b7-93d4-6a2fee5a661d",
                      "8ce5d84c-bd08-462d-86e2-9ce919fd34bf",
                      "82e8321a-5c5a-48d4-a78d-6762cf9f0b44",
                      "cd1e4462-bbc4-4e71-8265-479e9444eea6",
                      "ea1964e1-5d04-4b66-9c42-29e8477946c3",
                      "09f6925c-99e6-4203-ae6e-9a7711dac480",
                      "c9a2f38f-438b-4b75-8c72-4d282b3a0606",
                      "a17fa4a2-5673-4eec-9b70-655ef0e90b49",
                      "8775cdae-3f45-425d-9a6e-c86fac6da9b6",
                      "0abfa643-9f97-4499-9f3f-5655271bdeba",
                      "aba45340-48e7-432d-b259-8abcc51d75be",
                      "0cbeda3a-dc34-4d91-8e66-3da955c3ce96",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.12 End of Life verkaufter Produkte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus der Entsorgung und Behandlung der verkauften Produkte, wenn diese am Ende ihrer Lebensdauer sind.",
                      },
                    },
                    inputParameterIds: [
                      "0bb63ded-83fa-45f6-9fb4-6890981a1972",
                      "fc39cecf-4dd0-4cd8-99e6-c8e0d879927f",
                      "1b2c20e9-f742-4c29-9120-defdaf40dd7f",
                      "4ee4b95c-4543-474b-986b-a151a164f138",
                      "6aa26a80-d3dd-4e8a-8c78-742ab6b5366c",
                      "cd02c81c-0877-402e-b9a4-73fa66af7dea",
                      "85849088-7aed-4b98-ae1e-a8ca63b21821",
                      "a1b064f2-8ba3-4e8c-b50e-b709b581ce7a",
                      "b88e4635-e407-4253-ac48-aa15dd4e9259",
                      "d9251ae6-622f-454d-bb51-ceef28a81633",
                      "358b19ad-35c8-4211-8579-50b78af0d593",
                      "f85e1b9e-1630-4ec2-8315-82f42e74ccfd",
                      "b892ad56-a396-4c7d-841f-8bc85acc8974",
                      "f92a5e8a-2f37-4626-ae9e-4316bd6c1c82",
                      "6d5ee0e4-9747-4a39-a723-6d2ef225bffe",
                      "7237cb83-3439-40f3-aaba-8c4001f61e2b",
                      "bf016291-08c8-4b62-aed8-fffdff74f032",
                      "56b73b47-35be-4c26-a608-4c3f0d8055ce",
                      "762b36c3-7893-4fdd-a02e-7ed3bb9643cb",
                      "cd5d4c4d-7225-427a-83a7-638318ce6dd8",
                      "6dccb546-0dcb-4657-b034-04b0b7dda657",
                      "6e912c3f-5f1d-4460-9eab-be4a73d28fcf",
                      "9507f897-520a-4559-8797-f6be656718d3",
                      "0504094d-099f-4a74-bdfc-eb0cc8548dbb",
                      "52bae310-f447-4885-ae75-1db2d031642a",
                      "cf5d3cbf-72e4-4c46-b985-e87ee3bf1883",
                      "85533994-db96-479c-ab36-c186e38b6189",
                      "aaf23e00-b191-4538-bcad-ec7dce45a693",
                      "ff397273-e3a2-4481-8557-b60b02caa736",
                      "9c87fe3b-a14d-4fbd-8000-7e34d324c7f2",
                      "698523f6-1989-48f3-8110-02b3cda1b733",
                      "b4ab554f-aeb2-4911-ab0a-827780313360",
                      "f1670123-c735-49e0-9f81-c7532bd0496a",
                      "e352fcdf-2587-4020-9416-8ea2d2438bcf",
                      "cc619b00-5817-49ae-a176-36963a054041",
                      "cb8efe9c-dd89-48a9-b64d-46e2ab0c686e",
                      "5e1bccd2-a12e-4583-bb6f-952b56ac38c2",
                      "903be5c0-f66c-44fa-b925-0c9069402a67",
                      "6167dbdd-4ecd-4b7d-9791-0ce89dcf2920",
                      "2229c6ba-9e4d-457f-82cc-4d5a8f4798ba",
                      "3314ebbe-5890-4617-9a9a-9fdc061a4596",
                      "cc085d79-b33b-42c8-88f3-fb3783394808",
                      "9c43f4da-3247-404b-ba3a-5479e46b6c8d",
                      "a692370c-be04-4e57-bc7a-68209c00e8c3",
                      "a3380dee-5a82-4ad1-ba70-e4d134a7cad6",
                      "cfb4ef56-44bd-4cf4-84f6-e3a10dab7dcb",
                      "ee07d16e-b1e5-4b97-b2e7-a9f852291691",
                      "223f4d38-7ee8-47ee-8bb3-0273ea6098e3",
                      "99a4dd3e-469c-4db1-8d28-5885737b6527",
                      "c95f1b9f-47a6-4882-b6fa-06e6571e4bde",
                      "c4d238b4-045a-4f7d-8859-5374ea25e5f0",
                      "4fcf453d-1b12-4c2a-9b02-dbce234aa8c1",
                      "dbf02b6c-edd4-46bf-b317-97795813dd09",
                      "4cdfac86-8cee-4b6f-bf03-3e67b65ae510",
                      "e70efa29-c604-4088-b613-2dca8f586c2c",
                      "969acb0d-e17f-4568-abf6-e97b9bd7c8ff",
                      "460b3eac-3ad8-4dfe-8078-5af4509d6785",
                      "275609c9-7c5e-4798-b6ac-5484210c5a9d",
                      "39f2025d-5ade-40e3-af57-7a56f0905f18",
                      "bcc9e9b8-9695-4cff-a8b9-d8ad1ad0e391",
                      "a133defa-94df-4548-b8b9-5e186124dbfc",
                      "67935b29-fbca-4123-9e34-f3dfdc397559",
                      "d54d4e8b-a9a9-416e-85cc-57f243362d62",
                      "aeea8eb6-5f5b-4cb5-919c-1f8bfd0b47c7",
                      "65a76f5d-e36a-413d-9d08-720d89f28114",
                      "798a9a8f-a9f5-418e-8cfa-7b6e1fdac798",
                      "28e83891-6705-4a10-a610-172a2417eb1b",
                      "15a14097-3845-4f6d-9ad9-debfa7757f53",
                      "54ab2967-8291-4456-b0aa-047803ede14e",
                      "0406acab-6123-4777-bec8-12a3f8938627",
                      "39e3dfec-4cc3-4382-8517-edfba849db53",
                      "229ebfd1-a9bf-4c71-aedf-786f53950035",
                      "3bf0f265-033d-4488-9b3c-1ea81577f876",
                      "f06bf53b-7f29-4ae2-82c4-39808907393f",
                      "b29b773b-5ac0-4650-826b-0644918409d1",
                      "42aa8c74-da67-4a31-aa2b-0b6c5dd744ad",
                      "5d2799ec-8132-413a-aecd-c6040951e90f",
                      "e7ffac1f-87f1-4cf9-bff7-c18ea77c9b93",
                      "59fce7c9-fafb-4f46-89c6-c14ffc8f1d74",
                      "76df52e3-ed3b-4f8a-99d1-6aa9834b23cd",
                      "f3a37232-79d5-4815-b00f-87c7635873a1",
                      "a0ec2592-638e-4d67-ba86-a477d60ca8d4",
                      "5526d109-191f-466e-9f55-a76b21315dfd",
                      "3ad79d5b-f23a-4a99-8431-181a78759471",
                      "bd3c9fe9-ef54-4dee-814d-ac3763df3aa6",
                      "49787c91-318a-4ffe-8b7b-71f7e5de353d",
                      "f5222216-3203-48d3-bab1-4d41c90eb46d",
                      "99418ab8-d10c-4c9d-8267-7ce7a6fdfc69",
                      "5b225901-5584-4b95-b482-77166fbc193d",
                      "e653ea3a-d1d7-4fe4-8ac8-af6c8f1ee2b7",
                      "91f7d109-c0b5-4b4a-81bc-994b1899c844",
                      "8adec74f-9be9-4bd8-a545-3e205d337fa1",
                      "6d82b78e-021e-4475-a75d-ce46c7a91e0e",
                      "240b6602-7367-47b4-82d6-3adc5b2d4b91",
                      "6c2d8489-a7b5-467b-b4b1-a4904407c163",
                      "9ce6b6e1-8b24-4716-89cf-340c62e95ce6",
                      "e7e217d3-5c43-46d9-918f-3b33e2ff1f6d",
                      "cc99c0cf-91ee-4d25-bc00-b2b348427c9b",
                      "824265d0-9a30-4f25-ae49-8c6223b12a47",
                      "2d48d116-1e63-4d5b-a6a5-a2d72509f30a",
                      "6a10c84d-90da-47d7-b3e4-570df829b672",
                      "740083e8-7d5e-434e-bacc-a802bf5b392f",
                      "9c8bb057-75d5-46b3-8010-c4c0f9582946",
                      "bf5cbb71-5e3f-4fee-bbb9-4917bd4facd1",
                      "95036ae8-5632-499d-b412-8bfdaedebdf2",
                      "444521de-fe6d-4829-a610-4748fa2c698f",
                      "f9f586c1-26e0-480f-b9bb-4d6253daae37",
                      "5fa5b2c9-80a6-4019-96f0-ffc707f4c19c",
                      "4883da28-388e-4358-882a-ccb47461dd81",
                      "74234221-f372-42c7-9cfd-e0b9c43c3c28",
                      "bac796e6-19a6-454c-b93e-6e655c362f97",
                      "bb2c4cab-ec69-4a10-b0f4-cf3cd69160ba",
                      "a9d9085a-a9bc-4286-9fd6-04b57f3d3430",
                      "1d6213b1-9907-481e-88d3-ecbcf8993b63",
                      "2e320e93-3621-4840-a41c-a4cb7029c9be",
                      "d6f1df79-f4ad-4171-a545-46eb5330ce07",
                      "31da1577-b5b9-4a7b-a0b3-36ee2d0ae7ce",
                      "d0b601e3-8520-45d4-84cf-74cb07f30725",
                      "d136cf66-6054-401d-b9b4-366914dd9e27",
                      "e0adf0c2-188b-49b7-9a7c-c49f7fff3e04",
                      "0967c69d-986d-4768-811c-f0b1cec53010",
                      "73498b35-60c6-4018-b858-7b633ea88517",
                      "34ea5a01-bb82-46de-992e-3033bf99912e",
                      "f21e4553-0a81-4522-a48c-abe92e221a42",
                      "802b3b28-3870-4d7b-a4ea-469cbddd1372",
                      "b54fc446-8795-42dd-900f-3ad611e01773",
                      "c435f156-1102-471a-8ca1-4646d74ac7ad",
                      "a37f5dc6-7148-4816-bb47-a8a78b493af9",
                      "8443db45-ca8b-42dc-b488-4446d5df7998",
                      "64dd7eb1-36e6-48fe-95c5-156440ed1984",
                      "60f9a737-979f-4f62-83e8-4097527bde40",
                      "a8697f28-c881-48b0-bf2b-afb8c198f958",
                      "ea39ad32-930f-4c92-8fdf-c50d165732a9",
                      "5d92911f-0009-4441-a993-8f19fd937743",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.13 Downstream verpachtete Vermögenswerte",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus Vermögenswerten, die vom Unternehmen an Dritte verpachtet werden.",
                      },
                    },
                    inputParameterIds: [
                      "a0e6abcd-6dbf-405c-b8fb-810c3d55491e",
                      "cd53f026-32bf-4d97-927d-9f9fc1822ab2",
                      "0426fe60-354a-4a6a-afc9-64caf24bf37a",
                      "7ccb1150-5a5e-439f-810e-6929bc3cbd43",
                      "eb12ae93-c810-40e1-b144-aecd2b1b6bef",
                      "53768dac-c78a-4031-9896-13352a5f09dc",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.14 Franchises",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus den Aktivitäten von Franchise-Unternehmen, die unter dem Markennamen des Unternehmens operieren.",
                      },
                    },
                    inputParameterIds: ["0e2620f5-ffe1-4958-8383-178fa1ec8fe6"],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "3.15 Investitionen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Emissionen aus den Investitionen des Unternehmens in andere Unternehmen oder Projekte.",
                      },
                    },
                    inputParameterIds: [
                      "60dfce07-8bfb-409f-8e42-e4b316fa07d9",
                      "3db110ad-92c1-4822-b6b7-3032bd82907a",
                      "6a8cab55-de40-4ac7-8af1-adce3bdf6fbe",
                      "3fd7373c-202d-4041-b669-a5675a1be993",
                    ],
                  },
                  {
                    name: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Andere Scope 3 Emissionen",
                      },
                    },
                    children: [],
                    description: {
                      defaultLanguage: "de",
                      translationsPerLanguage: {
                        de: "Weitere Scope 3 Emissionen, die nicht den vorgegebenen Kategorien zugeordnet werden können (zB Transportemissionen von Konferenz- oder Eventteilnehmer:innen)",
                      },
                    },
                    inputParameterIds: [],
                  },
                ],
                description: {
                  defaultLanguage: "de",
                  translationsPerLanguage: {
                    de: "Überprüfen Sie im ersten Schritt, welche Scope-3-Kategorien für Sie relevant sind, anhand folgender Kriterien: Größe: Sie tragen erheblich zu den erwarteten Scope-3-Emissionen des Unternehmens bei (siehe Abschnitt 7.1 für Hinweise zur Verwendung von Schätzmethoden). Einfluss: Es gibt mögliche Emissionsreduktionen, die vom Unternehmen durchgeführt oder beeinflusst werden können (siehe Kasten 6.2). Risiko: Sie tragen zur Risikobelastung des Unternehmens bei (z. B. klimawandelbezogene Risiken wie finanzielle, regulatorische, Lieferketten-, Produkt- und Kundenrisiken, Rechtsstreitigkeiten und Reputationsrisiken) (siehe Tabelle 2.2). Stakeholder: Sie werden von wichtigen Stakeholdern als kritisch angesehen (z. B. Kunden, Lieferanten, Investoren oder die Zivilgesellschaft). Outsourcing: Es handelt sich um ausgelagerte Aktivitäten, die zuvor intern durchgeführt wurden, oder um Aktivitäten, die vom berichtenden Unternehmen ausgelagert wurden und die typischerweise intern von anderen Unternehmen in der Branche des berichtenden Unternehmens durchgeführt werden. Branchenspezifische Leitlinien: Sie wurden in branchenspezifischen Leitlinien als bedeutend identifiziert. Sonstiges: Sie erfüllen zusätzliche vom Unternehmen oder der Branche entwickelte Kriterien zur Bestimmung der Relevanz.",
                  },
                },
                inputParameterIds: [],
              },
            ],
            description: {
              defaultLanguage: "de",
              translationsPerLanguage: {
                de: "Greenhouse Gas Protocol Standard Template (All)",
              },
            },
            inputParameterIds: [],
          },
        },
      },
    ],
  },
];

export const TARGET_PATHS_DEMO_RECORDING_PERIODS = {
  recordingPeriods: TARGET_PATHS_DEMO_RECORDING_PERIODS_BASE.map(
    RecordingPeriodsUtilities.convertApiToLocalRecordingPeriod,
  ),
};

export const TARGET_PATHS_DEMO_TARGET_PATH_PROGRESS: IGetTargetPathProgressResponse = {
  progress: {
    "e0183d80-8928-487d-b23f-cac434eb149b": {
      name: "Geschäftsjahr 2024",
      startDate: "2023-12-31T23:00:00.000Z",
      endDate: "2024-12-31T22:59:59.999Z",
      total: {
        co2_equiv_scope_1: 606713.5172735357,
        co2_equiv_scope_2: 255282.30000000002,
        co2_equiv_scope_3: 1611992.681650441,
      },
    },
    "0fe997c0-e4b7-438a-90e5-dd5155a11856": {
      name: "Geschäftsjahr 2025",
      startDate: "2024-12-31T23:00:00.000Z",
      endDate: "2025-12-31T22:59:59.999Z",
      total: {
        co2_equiv_scope_1: 21242.391346147655,
        co2_equiv_scope_2: 0,
        co2_equiv_scope_3: 14142.520178119887,
      },
    },
  },
};

export function getTargetPathsProgressByRecordingStructureIds(
  structureIds: string[],
): IGetTargetPathProgressResponse {
  const result: Partial<(typeof TARGET_PATHS_DEMO_TARGET_PATH_PROGRESS)["progress"]> = {
    ...TARGET_PATHS_DEMO_TARGET_PATH_PROGRESS.progress,
  };
  // Only include first entry if associated structureId is included
  if (!structureIds.includes("be669d65-f368-4d55-aca1-0e3ee4ea98cd")) {
    delete result["0fe997c0-e4b7-438a-90e5-dd5155a11856"];
  }
  // Only include second entry if associated structureId is included
  if (!structureIds.includes("f4115e36-9a53-4d7e-a400-6bef891e2e89")) {
    delete result["e0183d80-8928-487d-b23f-cac434eb149b"];
  }
  return { progress: result };
}
