// Only allow PNG, JPEG, PDF, XLSX and DOCX files
export enum AllowedMimeTypes {
  PNG = "image/png",
  JPEG = "image/jpeg",
  PDF = "application/pdf",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}

export const ALLOWED_MIME_TYPES_KEYS = Object.keys(AllowedMimeTypes);
export const ALLOWED_MIME_TYPES_VALUES = Object.values(AllowedMimeTypes);
