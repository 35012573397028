import { FC, useCallback, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { IDMAConfiguration, IESRSTopic } from "@netcero/netcero-core-api-client";
import {
  IFilterIROs,
  IIROsOverviewIROClickHandler,
  IIROsOverviewIROClickHandlerData,
  IROsOverviewList,
} from "../../double-materiality-assessment/impacts-risks-opportunities/components/iros-overview-list.component";
import { useTranslation } from "react-i18next";
import { IIrosListEntry } from "../../double-materiality-assessment/impacts-risks-opportunities/impacts-risks-opportunitites.types";
import { IRO_TYPES } from "@netcero/netcero-common";
import { IROsESRSTopicsTabs } from "../../data-entry-object-values/esrs/fill-in-helpers/iros-esrs-topics-tabs.component";
import { FilterSelect } from "../../filters/filter-select.component";
import { FilterType, IFilterConfig } from "../../filters/filter.type";
import { IrosListFilter } from "../../filters/filter.constants";
import { getFilterConfigGenerator } from "../../filters/filter-bar.component";
import { EffectAreaType, FilterValues, Materiality } from "./dma-iro-overview-list.types";
import { DMAOverviewTableUtilities } from "../dma-overview-table/dma-overview-table.utilities";
import { DMAMaterialImpactEditDialog } from "../../double-materiality-assessment/material-impacts/dma-material-impact-edit.dialog";
import { DMAFinancialEffectEditDialog } from "../../double-materiality-assessment/financial-effects/dma-financial-effect-edit.dialog";
import { useDeoDmaDashboardContext } from "../deo-dma-dashboard.context";
import { DeleteImpactOrEffectConfirmDialog } from "../../double-materiality-assessment/common/delete-impact-or-effect-confirm.dialog";
import { MoveImpactOrEffectConfirmDialog } from "../../double-materiality-assessment/dialogs/iros/move-impact-or-effect-confirm.dialog";

import { useIroDialogHandlers } from "../../double-materiality-assessment/hooks/iro-dialog-handlers.hook";
import { IroExportButton } from "../../double-materiality-assessment/common/iro-export-button.component";

interface IDmaIroOverviewListProps {
  dmaConfiguration: IDMAConfiguration;
  esrsTopics: IESRSTopic[];
}

export const DmaIroOverviewList: FC<IDmaIroOverviewListProps> = ({
  dmaConfiguration,
  esrsTopics,
}) => {
  const { t } = useTranslation("dma_dashboard");
  const { organizationId, recordingPeriodId, dataEntryObject } = useDeoDmaDashboardContext();

  const [selectedFilters, setSelectedFilters] = useState({
    [IrosListFilter.EsrsTopic]: esrsTopics[0]?.identifier ?? null,
    [IrosListFilter.IroType]: [],
    [IrosListFilter.EffectAreaType]: [],
    [IrosListFilter.Materiality]: [],
  });

  const filterIroTypeOptions = useMemo(
    () =>
      IRO_TYPES.map((type) => ({
        value: type,
        name: t(`plural.${type}`, { ns: "iro_types" }),
      })),
    [t],
  );
  const filterAffectedAreaOptions = useMemo(
    () =>
      Object.values(EffectAreaType).map((area) => ({
        value: area,
        name: t(`${area}_name`, { ns: "double_materiality_assessment_sub_category_component" }),
      })),
    [t],
  );
  const filterMaterialityOptions = useMemo(
    () =>
      Object.values(Materiality).map((materiality) => ({
        value: materiality,
        name: t(materiality),
      })),
    [t],
  );

  const generateFilterConfig = useMemo(() => getFilterConfigGenerator(t), [t]);

  const filters: IFilterConfig[] = useMemo(
    () => [
      generateFilterConfig(IrosListFilter.IroType, {
        type: FilterType.Select,
        options: filterIroTypeOptions,
        multiple: true,
      }),
      generateFilterConfig(IrosListFilter.EffectAreaType, {
        type: FilterType.Select,
        options: filterAffectedAreaOptions,
        multiple: true,
      }),
      generateFilterConfig(IrosListFilter.Materiality, {
        type: FilterType.Select,
        options: filterMaterialityOptions,
        multiple: true,
      }),
    ],
    [
      generateFilterConfig,
      filterIroTypeOptions,
      filterAffectedAreaOptions,
      filterMaterialityOptions,
    ],
  );

  const handleFilterChange = useCallback((filterKey: string, value: string | string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  }, []);

  const filterIros: IFilterIROs = useCallback(
    (iro: IIrosListEntry) => {
      const { esrsTopic, iroType, effectAreaType, materiality } = selectedFilters;
      return DMAOverviewTableUtilities.filterIrosBySelectedFilters(
        iro,
        esrsTopic,
        iroType,
        effectAreaType,
        materiality,
      );
    },
    [selectedFilters],
  );

  const selectedESRSTopic = useMemo(() => {
    return esrsTopics.find((t) => t.identifier === selectedFilters.esrsTopic) ?? null;
  }, [esrsTopics, selectedFilters.esrsTopic]);

  // IRO Dialog Hook
  const {
    states,
    updateHandlers: {
      handleUpdateMaterialImpact,
      handleUpdateFinancialEffect,
      handleUpdateMaterialImpactIROState,
      handleUpdateFinancialEffectIROState,
      handleCloseUpdateMaterialImpactDialog,
      handleCloseUpdateFinancialEffectDialog,
    },
    moveHandlers,
    deleteHandlers,
  } = useIroDialogHandlers(
    esrsTopics,
    selectedESRSTopic,
    organizationId,
    recordingPeriodId,
    dataEntryObject,
  );

  // Handles a click on an IRO
  const handleClickIRO: IIROsOverviewIROClickHandler = useCallback(
    (data: IIROsOverviewIROClickHandlerData) => {
      if (data.type === "materialImpact") {
        handleUpdateMaterialImpact(data.materialImpact);
      } else {
        handleUpdateFinancialEffect(data.financialEffect);
      }
    },
    [handleUpdateMaterialImpact, handleUpdateFinancialEffect],
  );

  return (
    <Box>
      {/* IRO Edit Dialogs */}
      {/* Material Impact Dialog */}
      <DMAMaterialImpactEditDialog
        open={states.showEditMaterialImpactDialogState.open}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObject={dataEntryObject}
        dmaConfiguration={dmaConfiguration}
        esrsTopic={states.showEditMaterialImpactDialogState.esrsTopic}
        dmaCategory={states.showEditMaterialImpactDialogState.category}
        materialImpact={states.showEditMaterialImpactDialogState.effectOrImpact}
        loading={states.materialImpactEditDialogLoading}
        disabled={states.materialImpactEditDialogLoading}
        error={states.materialImpactEditDialogError}
        onClose={handleCloseUpdateMaterialImpactDialog}
        onUpdateIROState={handleUpdateMaterialImpactIROState}
        onMoveIRO={moveHandlers.handleMoveMaterialImpact}
        onDelete={deleteHandlers.handleDeleteMaterialImpact}
      />
      <DeleteImpactOrEffectConfirmDialog
        open={states.showDeleteMaterialImpactDialogState.open}
        loading={states.materialImpactDeleteDialogLoading}
        disabled={states.materialImpactDeleteDialogLoading}
        error={states.materialImpactDeleteDialogError}
        type="material-impact"
        effectOrImpact={states.showDeleteMaterialImpactDialogState.effectOrImpact}
        onCancel={() => deleteHandlers.handleCloseDeleteMaterialImpactDialog(false)}
        onConfirm={() => deleteHandlers.handleCloseDeleteMaterialImpactDialog(true)}
      />
      <MoveImpactOrEffectConfirmDialog
        open={states.moveMaterialImpactDialogState.open}
        loading={states.moveMaterialImpactDialogState.loading}
        disabled={states.moveMaterialImpactDialogState.loading}
        error={states.moveMaterialImpactDialogState.error}
        type="material_impact"
        effectOrImpact={states.moveMaterialImpactDialogState.effectOrImpact}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObjectId={dataEntryObject.id}
        dmaCategory={states.moveMaterialImpactDialogState.dmaCategory}
        onClose={moveHandlers.handleCloseMoveMaterialImpactDialog}
      />

      {/* Financial Effect Dialog */}
      <DMAFinancialEffectEditDialog
        open={states.showEditFinancialEffectDialogState.open}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObject={dataEntryObject}
        dmaConfiguration={dmaConfiguration}
        esrsTopic={states.showEditFinancialEffectDialogState.esrsTopic}
        dmaCategory={states.showEditFinancialEffectDialogState.category}
        financialEffect={states.showEditFinancialEffectDialogState.effectOrImpact}
        loading={states.financialEffectEditDialogLoading}
        disabled={states.financialEffectEditDialogLoading}
        error={states.financialEffectEditDialogError}
        onClose={handleCloseUpdateFinancialEffectDialog}
        onDelete={deleteHandlers.handleDeleteFinancialEffect}
        onUpdateIROState={handleUpdateFinancialEffectIROState}
        onMoveIRO={moveHandlers.handleMoveFinancialEffect}
      />
      <DeleteImpactOrEffectConfirmDialog
        open={states.showDeleteFinancialEffectDialogState.open}
        loading={states.financialEffectDeleteDialogLoading}
        disabled={states.financialEffectDeleteDialogLoading}
        error={states.financialEffectDeleteDialogError}
        type="financial-effect"
        effectOrImpact={states.showDeleteFinancialEffectDialogState.effectOrImpact}
        onCancel={() => deleteHandlers.handleCloseDeleteFinancialEffectDialog(false)}
        onConfirm={() => deleteHandlers.handleCloseDeleteFinancialEffectDialog(true)}
      />
      <MoveImpactOrEffectConfirmDialog
        open={states.moveFinancialEffectDialogState.open}
        loading={states.moveFinancialEffectDialogState.loading}
        disabled={states.moveFinancialEffectDialogState.loading}
        error={states.moveFinancialEffectDialogState.error}
        type="financial_effect"
        effectOrImpact={states.moveFinancialEffectDialogState.effectOrImpact}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObjectId={dataEntryObject.id}
        dmaCategory={states.moveFinancialEffectDialogState.dmaCategory}
        onClose={moveHandlers.handleCloseMoveFinancialEffectDialog}
      />

      {/* Title */}
      <Typography variant="h3" component="h2" mb={1}>
        {t("title_iro_overview_list")}
      </Typography>
      {/* Filters */}
      <Box display="flex" gap={2} mb={2}>
        {filters.map((filter) => (
          <FilterSelect
            key={filter.name}
            selectedValues={selectedFilters[filter.name as keyof FilterValues]}
            onChange={(_, value) => handleFilterChange(filter.name, value)}
            filter={filter}
          />
        ))}
        {/* Spacer */}
        <Box flex={1} />
        {/* Button for export*/}
        <IroExportButton
          organizationId={organizationId}
          recordingPeriodId={recordingPeriodId}
          dataEntryObjectId={dataEntryObject.id}
        />
      </Box>
      {/* Topics Tabs */}
      <IROsESRSTopicsTabs
        esrsTopics={esrsTopics}
        selectedTopic={selectedFilters.esrsTopic}
        onSelectTopic={(value) =>
          setSelectedFilters((prev) => ({
            ...prev,
            esrsTopic: value,
          }))
        }
      />
      {/* IRO List*/}
      <IROsOverviewList
        esrsTopics={esrsTopics}
        filterIROs={filterIros}
        sortIROs={DMAOverviewTableUtilities.sortIrosByMateriality}
        onClick={handleClickIRO}
      />
    </Box>
  );
};
