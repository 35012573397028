import { Alert, Box, Card, Collapse, Divider, SvgIcon, Tooltip, Typography } from "@mui/material";
import {
  IDataEntryObjectInputParameterContext,
  IIntercomEntity,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CopyToClipboardButton,
  CopyToClipboardButtonAnimationProps,
} from "../../common/components/copy-to-clipboard-button.component";
import { ExpansionIconButton } from "../../common/components/expansion-icon-button.component";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { LinkChip } from "../../common/components/link-chip.component";
import { InfoIcon } from "../../common/constants/tabler-icon.constants";
import { EnvironmentUtilities } from "../../common/utilities/environment.utilities";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../../content-translation/hooks/translate-content.hook";
import { DataEntryObjectInputParameterStatusIndicatorWithMenu } from "../../data-entry-objects-input-parameters/components/data-entry-object-input-parameter-status-indicator-with-menu.component";
import { useIsInputParameterLocked } from "../../data-entry-objects-input-parameters/hooks/is-input-parameter-locked.hook";
import {
  IHydratedCompleteInputParameterRecordingStructureGroupDisclosureRequirementIP,
  IHydratedInputParameterRecordingStructureGroupDisclosureRequirementIP,
} from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { IntercomReferenceWidget } from "../../intercom-references/intercom-reference.widget";
import { OrganizationUsersComponent } from "../../user/components/organization-users.component";
import { SingleOrganizationUserAvatarPicker } from "../../user/components/single-organization-user-avatar-picker.component";
import { ConditionalRecordingInfo } from "../components/conditional-recoding-info.component";
import { DataEntryObjectInputParameterLinkedSourcesPicker } from "../components/linked-sources-data-entry-object-input-parameter-picker.component";
import { IInputParameterDisplayInformation } from "../hooks/conditional-display-input-parameters.hook";
import { useDeoEsrsInputContext } from "./deo-esrs-input.context";
import { UPDATE_INPUT_PARAMETER_SOURCES_ACTION } from "./esrs-api-actions.constants";
import { EsrsInputParameterTypeIndicator } from "./esrs-input-parameter-type-indicator.component";
import { DrValueEditor } from "./value-editing/dr-value-editor.component";
import { InputParameterMaterialityChip } from "./input-parameter-materiality-chip.component";
import { DataEntryObjectValuesUtilities } from "../utilities/data-entry-object-values.utilities";
import { MultipleChip } from "../../common/components/multiple-chip.component";
import { VsmeComprehensiveIndicatorForInputParameter } from "../../vsme/components/vsme-comprehensive-indicator.component";

const COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME = "copy-ip-to-clipboard-button";

interface IDrInputParametersListProps {
  disclosureRequirementInputParameters: IHydratedInputParameterRecordingStructureGroupDisclosureRequirementIP[];
  drIsExcluded: boolean;
}

export const DrInputParametersList: FC<IDrInputParametersListProps> = ({
  disclosureRequirementInputParameters,
  drIsExcluded,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {disclosureRequirementInputParameters.map((disclosureRequirementInputParameter) => (
        <Box
          key={disclosureRequirementInputParameter.parameterId}
          sx={{ pl: (disclosureRequirementInputParameter.level - 1) * 4 }}
        >
          {disclosureRequirementInputParameter.inputParameter ? (
            <DrInputParameterListItem
              disclosureRequirementInputParameter={
                disclosureRequirementInputParameter as IHydratedCompleteInputParameterRecordingStructureGroupDisclosureRequirementIP
              }
              drIsExcluded={drIsExcluded}
            />
          ) : (
            "No input parameter"
          )}
        </Box>
      ))}
    </Box>
  );
};

interface IDrInputParameterListItemProps {
  disclosureRequirementInputParameter: IHydratedCompleteInputParameterRecordingStructureGroupDisclosureRequirementIP;
  drIsExcluded: boolean;
}

const DrInputParameterListItem: FC<IDrInputParameterListItemProps> = ({
  disclosureRequirementInputParameter,
  drIsExcluded,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_common");
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();
  const {
    organization,
    recordingPeriod,
    organizationStructure,
    dataEntryObject,
    recordingStructureId,
    recordingStructure,
    sectionId,
    expandedInputParameterIds,
    setExpandedInputParameterIds,
    conditionalDisplayInputParametersLookup,
    handleInputParameterContributingUserIdsChange,
    handleInputParameterResponsibleUsersChange,
    handleCreateIPValue,
    handleUpdateIPValue,
    handleDeleteIPValue,
    handleUpdateTableValue,
    handleResetInputParameter,
    handleSubmitInputParameter,
    handleApproveInputParameter,
    handleRejectInputParameter,
    createInputParameterUrl,
    isLoading,
  } = useDeoEsrsInputContext();

  const conditionalDisplayInfo: IInputParameterDisplayInformation | null = useMemo(
    () =>
      conditionalDisplayInputParametersLookup[disclosureRequirementInputParameter.parameterId] ??
      null,
    [conditionalDisplayInputParametersLookup, disclosureRequirementInputParameter.parameterId],
  );

  const isExpanded = useMemo(
    () => expandedInputParameterIds.includes(disclosureRequirementInputParameter.parameterId),
    [expandedInputParameterIds, disclosureRequirementInputParameter.parameterId],
  );

  const handleChangeExpand = useCallback(
    () =>
      setExpandedInputParameterIds((prev) =>
        prev.includes(disclosureRequirementInputParameter.parameterId)
          ? prev.filter((id) => id !== disclosureRequirementInputParameter.parameterId)
          : [...prev, disclosureRequirementInputParameter.parameterId],
      ),
    [disclosureRequirementInputParameter.parameterId, setExpandedInputParameterIds],
  );

  const translatedTitle = useMemo(
    () => translateContent(disclosureRequirementInputParameter.inputParameter.inputParameter.name),
    [translateContent, disclosureRequirementInputParameter.inputParameter.inputParameter.name],
  );

  const translatedDescription = useMemo(
    () =>
      translateOptionalContent(
        disclosureRequirementInputParameter.inputParameter.inputParameter.description,
      ),
    [
      translateOptionalContent,
      disclosureRequirementInputParameter.inputParameter.inputParameter.description,
    ],
  );

  const translatedLinkToEuLaw = useMemo(
    () =>
      translateOptionalContent(
        disclosureRequirementInputParameter.inputParameter.inputParameter.metaData.esrs?.xbrl
          ?.linkToRegulation,
      ),
    [
      disclosureRequirementInputParameter.inputParameter.inputParameter.metaData.esrs?.xbrl
        ?.linkToRegulation,
      translateOptionalContent,
    ],
  );

  const isExcluded = useMemo(
    () => drIsExcluded || !!disclosureRequirementInputParameter.inputParameter.exclude,
    [drIsExcluded, disclosureRequirementInputParameter.inputParameter.exclude],
  );

  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const copyLinkComponent = useMemo(
    () => (
      <Box display="inline-block" className={COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}>
        <Box sx={{ transform: "translateY(-2px)" }}>
          <CopyToClipboardButton
            size="small"
            disableRipple
            value={createInputParameterUrl(disclosureRequirementInputParameter.inputParameter)}
            tooltip={t("tooltip_copy_link", { ns: "buttons" })}
            tooltipSuccess={t("tooltip_copy_link_success", { ns: "buttons" })}
            tooltipPlacement="right"
          />
        </Box>
      </Box>
    ),
    [disclosureRequirementInputParameter.inputParameter, createInputParameterUrl, t],
  );

  const temporarilyDisabled = useIsInputParameterLocked(
    disclosureRequirementInputParameter.inputParameter.inputParameter.id,
  );

  const deoInputParameterContext: IDataEntryObjectInputParameterContext = {
    sectionId,
    recordingStructureId,
    recordingStructureType: recordingStructure.structure.type,
  };

  const isPhaseIn =
    disclosureRequirementInputParameter.inputParameter.inputParameter.values[0]?.valueConfiguration
      .type === "esrs-topic-phase-in";

  return (
    <Card
      id={disclosureRequirementInputParameter.inputParameter.inputParameter.id}
      sx={{
        borderRadius: 2,
        borderColor: isExpanded ? "divider" : "transparent",
        my: isExpanded ? 1 : 0,
        transition: "margin 300ms ease, border-color 300ms ease",
        position: "relative",
        "&:hover": {
          borderColor: "divider",
        },
      }}
    >
      <Box p={1}>
        {/* Header Bar */}
        <Box display="flex" alignItems="center" gap={2}>
          {/* Expansion Button and Title */}
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            gap={1}
            onClick={handleChangeExpand}
            sx={{
              cursor: "pointer",
              ...CopyToClipboardButtonAnimationProps(`.${COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}`),
            }}
          >
            <ExpansionIconButton expanded={isExpanded} />
            <Box display="flex" alignItems="baseline" gap={1}>
              {/* VSME Comprehensive Indicator */}
              <Box sx={{ mt: -0.5 }}>
                <VsmeComprehensiveIndicatorForInputParameter
                  type="input-parameter"
                  inputParameter={disclosureRequirementInputParameter.inputParameter.inputParameter}
                />
              </Box>
              <LineClampTypographyWithTooltip
                tooltipOverride={translatedTitle}
                onOverflowChange={setIsTextOverflowing}
                variant="body2"
                component="h4"
                maxLines={!isExpanded ? 2 : undefined}
                display="inline-block"
                sx={{
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                  opacity: DataEntryObjectValuesUtilities.getInputParameterOpacity(isExcluded),
                }}
              >
                {/* Actual Input Parameter Name */}
                {translatedTitle}
                {/* Copy IP Link - shown here when NOT overflowing (end of text) */}
                {!isTextOverflowing && copyLinkComponent}
              </LineClampTypographyWithTooltip>
            </Box>
            {/* Copy IP Link - shown here when overflowing (would be hidden by lineclamp) */}
            {isTextOverflowing && (
              <Box alignSelf="end" mt={-0.65} ml={-1}>
                {copyLinkComponent}
              </Box>
            )}
          </Box>
          {!temporarilyDisabled && (
            <>
              {/* Optional Indicator */}
              {disclosureRequirementInputParameter.inputParameter.inputParameter.metaData.esrs
                ?.required === false && (
                <Typography variant="body2">
                  {t("indicator_optional_text", { ns: "data_entry_object_values_overview_common" })}
                </Typography>
              )}
              {/* Description */}
              {translatedDescription && (
                <Tooltip title={translatedDescription} placement="left">
                  <SvgIcon
                    component={InfoIcon}
                    color="action"
                    fontSize="small"
                    sx={{ mr: 0.5, fill: "transparent" }}
                  />
                </Tooltip>
              )}
              {/* Materiality Status */}
              <InputParameterMaterialityChip
                identity={{
                  organizationId: organization.id,
                  recordingPeriodId: recordingPeriod.id,
                  dataEntryObjectId: organizationStructure.id,
                  inputParameterId:
                    disclosureRequirementInputParameter.inputParameter.inputParameter.id,
                }}
              />
              {/* Status Indicator (hidden for phase-in IPs) */}
              {!isPhaseIn && (
                <DataEntryObjectInputParameterStatusIndicatorWithMenu
                  type="disclosure-requirement"
                  organizationId={organization.id}
                  dataEntryObjectId={dataEntryObject.id}
                  recordingPeriodId={recordingPeriod.id}
                  recordingStructureId={recordingStructureId}
                  dataEntryObjectInputParameter={disclosureRequirementInputParameter.inputParameter}
                  onReset={() =>
                    handleResetInputParameter(
                      disclosureRequirementInputParameter.inputParameter.inputParameter,
                    )
                  }
                  onSubmit={() =>
                    handleSubmitInputParameter(
                      disclosureRequirementInputParameter.inputParameter.inputParameter,
                      deoInputParameterContext,
                    )
                  }
                  onApprove={() =>
                    handleApproveInputParameter(
                      disclosureRequirementInputParameter.inputParameter.inputParameter,
                    )
                  }
                  onReject={() =>
                    handleRejectInputParameter(
                      disclosureRequirementInputParameter.inputParameter.inputParameter,
                      deoInputParameterContext,
                    )
                  }
                  disabled={isLoading || drIsExcluded || temporarilyDisabled}
                  parentIsExcluded={drIsExcluded}
                />
              )}
              {/* Responsible Person */}
              <SingleOrganizationUserAvatarPicker
                avatarButtonDiameter={30}
                organizationId={organization.id}
                value={disclosureRequirementInputParameter.inputParameter.responsibleUserId ?? null}
                tooltipNoUserSelected={t("add_tooltip", { ns: "add_responsible_user_button" })}
                noUsersAvailableText={t("no_more_users_available", {
                  ns: "add_responsible_user_button",
                })}
                noUserOption
                noUserOptionText={t("no_user_selected", { ns: "add_responsible_user_button" })}
                onChange={(userId: string | null) =>
                  handleInputParameterResponsibleUsersChange(
                    disclosureRequirementInputParameter.inputParameter,
                    userId,
                  )
                }
                disabled={isLoading || temporarilyDisabled}
              />
              {/* Type Indicator */}
              <EsrsInputParameterTypeIndicator
                inputParameter={disclosureRequirementInputParameter.inputParameter.inputParameter}
              />
            </>
          )}
          <Box ml={-0.75}>
            <IntercomReferenceWidget
              entityType={IIntercomEntity.InputParameter}
              identifier={disclosureRequirementInputParameter.inputParameter.inputParameter.id}
            />
          </Box>
        </Box>
        {/* Collapsable */}
        <Collapse in={isExpanded} unmountOnExit>
          {temporarilyDisabled ? (
            <Box display="flex" flexDirection="column" gap={1} p={1}>
              <Alert severity="info">
                <FormatTranslation
                  i18nKey="temporary_disabled_text"
                  t={t}
                  components={{
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={`mailto:${EnvironmentUtilities.SUPPORT_EMAIL}`}
                        style={{ color: "inherit" }}
                      />
                    ),
                  }}
                  values={{
                    email: EnvironmentUtilities.SUPPORT_EMAIL,
                  }}
                />
              </Alert>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" gap={1} p={1}>
              {/* First Row */}
              <Box display="flex" alignItems="start" gap={1}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  {/* EU Law */}
                  {translatedLinkToEuLaw && (
                    <LinkChip
                      to={translatedLinkToEuLaw}
                      label={t("eu_law_label", {
                        ns: "data_entry_object_values_overview_esrs_component",
                      })}
                    />
                  )}
                  {/* ChipContents */}
                  <Box pt={0.75}>
                    <MultipleChip
                      chipContents={
                        disclosureRequirementInputParameter.inputParameter.inputParameter.metaData
                          .esrs?.chipContents ?? []
                      }
                      size="small"
                    />
                  </Box>
                </Box>
                {/* Spacer */}
                <Box flex={1} />
                {/* Wrap the following content into a separate box for line breaks */}
                <Box display="flex" flexWrap="wrap" gap={1} alignItems="center">
                  {/* Linked Sources */}
                  <DataEntryObjectInputParameterLinkedSourcesPicker
                    organizationId={organization.id}
                    recordingPeriodId={recordingPeriod.id}
                    dataEntryObjectId={dataEntryObject.id}
                    inputParameterId={
                      disclosureRequirementInputParameter.inputParameter.inputParameter.id
                    }
                    snackbarMessage={UPDATE_INPUT_PARAMETER_SOURCES_ACTION}
                    disabled={isLoading}
                  />
                  {/*Vertical Divider*/}
                  <Divider flexItem orientation="vertical" sx={{ my: 1, mx: 1 }} />
                  {/* Assigned Users */}
                  <OrganizationUsersComponent
                    values={disclosureRequirementInputParameter.inputParameter.contributingUserIds}
                    organizationId={organization.id}
                    onChange={(newAssignedUserIds) =>
                      handleInputParameterContributingUserIdsChange(
                        disclosureRequirementInputParameter.inputParameter,
                        newAssignedUserIds,
                      )
                    }
                    emptyMessageAddButton={t("no_more_users_available", {
                      ns: "add_contributing_user_button",
                    })}
                    emptyMessage={t("no_user_selected", { ns: "add_contributing_user_button" })}
                    tooltipAddButton={t("add_tooltip", { ns: "add_contributing_user_button" })}
                    disabled={isLoading}
                  />
                </Box>
              </Box>

              {/* Info about conditional recording */}
              <ConditionalRecordingInfo displayInformation={conditionalDisplayInfo} />
              {/* Values Input */}
              <DrValueEditor
                type="input-parameter"
                deoInputParameter={disclosureRequirementInputParameter.inputParameter}
                organizationId={organization.id}
                recordingPeriod={recordingPeriod}
                rootDataEntryObjectId={organizationStructure.id}
                dataEntryObjectId={dataEntryObject.id}
                disabled={isLoading}
                parentIsExcluded={drIsExcluded}
                onCreate={(data) =>
                  handleCreateIPValue(
                    disclosureRequirementInputParameter.inputParameter.inputParameter,
                    data,
                  )
                }
                onUpdate={(valueId, data) =>
                  handleUpdateIPValue(
                    disclosureRequirementInputParameter.inputParameter.inputParameter,
                    valueId,
                    data,
                  )
                }
                onDelete={(valueId) =>
                  handleDeleteIPValue(
                    disclosureRequirementInputParameter.inputParameter.inputParameter,
                    valueId,
                  )
                }
                onTableUpdate={(created, updated, deleted) =>
                  handleUpdateTableValue(
                    disclosureRequirementInputParameter.inputParameter.inputParameter,
                    created,
                    updated,
                    deleted,
                  )
                }
              />
            </Box>
          )}
        </Collapse>
      </Box>
    </Card>
  );
};
