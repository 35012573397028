import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import {
  IGhgCategoriesIdentifier,
  IGhgEvaluationType,
  IPerformEvaluationForRecordingPeriod200Response,
} from "@netcero/netcero-core-api-client";
import { IRecordingPeriodIdentity } from "@netcero/netcero-common";

export type IGhgEvaluationModeConfiguration =
  | {
      evaluationType: typeof IGhgEvaluationType.RecordingStructure;
      recordingStructureId: string;
    }
  | {
      evaluationType: typeof IGhgEvaluationType.Category;
      categoryIdentifier: IGhgCategoriesIdentifier;
    };

/**
 * Returns a query key for the GHG evaluation. Used externally for invalidation.
 */
export const getGhgEvaluationQueryKey = ({
  organizationId,
  recordingPeriodId,
}: IRecordingPeriodIdentity) => ["ghgEvaluation", organizationId, recordingPeriodId];

/**
 * Returns a query key for the GHG evaluation.
 */
const getGhgEvaluationQueryKeyInternal = (
  recordingPeriodIdentity: IRecordingPeriodIdentity,
  options: {
    evaluationConfiguration?: IGhgEvaluationModeConfiguration;
  },
) => [...getGhgEvaluationQueryKey(recordingPeriodIdentity), options];

/**
 * Query hook to fetch the GHG evaluation.
 *
 * @param recordingPeriodIdentity - The recording period identity.
 * @param evaluationConfiguration - The evaluation configuration. Whether to evaluate for a recording structure or a category.
 * @param select - Optional selector function to transform the data.
 *
 * @returns A React Query object with the GHG evaluation result.
 */
export const useGhgEvaluationQuery = <T = IPerformEvaluationForRecordingPeriod200Response>(
  recordingPeriodIdentity: IRecordingPeriodIdentity,
  evaluationConfiguration: IGhgEvaluationModeConfiguration,
  select?: (data: IPerformEvaluationForRecordingPeriod200Response) => T,
) => {
  return useQuery({
    queryKey: getGhgEvaluationQueryKeyInternal(recordingPeriodIdentity, {
      evaluationConfiguration,
    }),
    queryFn: async () => {
      return CoreApiService.RecordingPeriodGhgCategoriesApi.performEvaluationForRecordingPeriod(
        recordingPeriodIdentity.organizationId,
        recordingPeriodIdentity.recordingPeriodId,
        evaluationConfiguration.evaluationType,
        evaluationConfiguration.evaluationType === IGhgEvaluationType.RecordingStructure
          ? evaluationConfiguration.recordingStructureId
          : undefined,
        evaluationConfiguration.evaluationType === IGhgEvaluationType.Category
          ? evaluationConfiguration.categoryIdentifier
          : undefined,
      )
        .then((req) => req())
        .then((r) => r.data);
    },
    select,
  });
};
