import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { TargetPathDetailsComponent } from "../../modules/target-path-goals/components/target-path-details.component";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";
import {
  REFERENCE_BUDGETS_DEMO_DATA,
  TARGET_PATHS_DEMO_TARGET_PATHS,
} from "./target-paths-demo-data.constants";

interface ITargetPathDetailsPageProps {}

export const TargetPathDetailsPage: FC<ITargetPathDetailsPageProps> = () => {
  const { organizationId } = useParams();

  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(AppInternalNavigationUtilities.getTargetPageUrl(orgId));
            },
          }
        : undefined,
      navigationItems: {
        activePath: AppInternalNavigationUtilities.getTargetPageUrl(organizationId!),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(AppInternalNavigationUtilities.getTargetPageUrl(orgId));
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  // const currentOrganization = useCurrentOrganization();
  // const referenceBudgetsQuery = useReferenceBudgetsQuery(organizationId!);
  // const currentTargetPath = useCurrentTargetPath(ReferenceRevenueIn.Euros);
  //
  // return (
  //   <Box width="100%" maxWidth="100%" mx="auto">
  //     {currentTargetPath === null ||
  //     currentOrganization === null ||
  //     referenceBudgetsQuery.isLoading ? (
  //       <CircularProgress />
  //     ) : (
  //       // TODO: maybe add error handling for target paths that can not be found
  //       <TargetPathDetailsComponent
  //         targetPath={currentTargetPath.targetPath}
  //         organizationTargetPath={currentTargetPath.currentOrganizationTargetPath}
  //         organization={currentOrganization}
  //         availableReferenceBudgets={referenceBudgetsQuery.data!.referenceBudgets}
  //         allTargetPaths={currentTargetPath.allTargetPaths}
  //       />
  //     )}
  //   </Box>
  // );

  // NC-2121: Temporary solution to allow for target Paths etc. to still be demoed
  const organizationTargetPath = TARGET_PATHS_DEMO_TARGET_PATHS.targetPaths[0];
  const { targetPathId } = useParams();
  const currentTargetPath = useMemo(
    () => TARGET_PATHS_DEMO_TARGET_PATHS.targetPaths.find((tp) => tp.id === targetPathId) ?? null,
    [targetPathId],
  );
  return (
    <Box width="100%" maxWidth="100%" mx="auto">
      {currentTargetPath && (
        <TargetPathDetailsComponent
          organizationId={organizationId!}
          allTargetPaths={TARGET_PATHS_DEMO_TARGET_PATHS.targetPaths}
          targetPath={currentTargetPath}
          organizationTargetPath={organizationTargetPath}
          availableReferenceBudgets={REFERENCE_BUDGETS_DEMO_DATA.referenceBudgets}
        />
      )}
    </Box>
  );
};
