import { FC, useMemo } from "react";
import {
  GhgCategoriesUtilities,
  IRecordingPeriodGhgCategoryIdentity,
} from "@netcero/netcero-common";
import { Breadcrumbs, Typography } from "@mui/material";
import { GhgScopeName } from "../../ghg-protocol/components/ghg-scope-name.component";
import { GhgCategoryName } from "../../ghg-categories/components/ghg-category-name.component";
import { useTranslation } from "react-i18next";
import { IActivity } from "@netcero/netcero-core-api-client";

interface IActivityDetailsDialogBreadcrumbsComponentProps {
  ghgCategoryIdentity: IRecordingPeriodGhgCategoryIdentity;
  activity?: IActivity | null;
}

export const ActivityDetailsDialogBreadcrumbsComponent: FC<
  IActivityDetailsDialogBreadcrumbsComponentProps
> = ({ ghgCategoryIdentity, activity }) => {
  const { t } = useTranslation("ghg_activities");

  const scopeOfCategory = useMemo(
    () => GhgCategoriesUtilities.getScopeOfGhgCategory(ghgCategoryIdentity.ghgCategoryIdentifier),
    [ghgCategoryIdentity.ghgCategoryIdentifier],
  );

  return (
    <Breadcrumbs>
      {/* GHG Scope */}
      <Typography color="inherit">
        <GhgScopeName ghgScope={scopeOfCategory} options={{ style: "long" }} />
      </Typography>
      {/* GHG Category */}
      <Typography color="inherit">
        <GhgCategoryName ghgCategory={ghgCategoryIdentity.ghgCategoryIdentifier} />
      </Typography>
      {/* Activity Title */}
      <Typography color="textPrimary">{activity?.title ?? t("new_activity")}</Typography>
    </Breadcrumbs>
  );
};
