import { AppModule, OrganizationRole } from "@netcero/netcero-common";
import { FC, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  IUseTranslateContentResult,
  useTranslateContent,
} from "../../content-translation/hooks/translate-content.hook";
import { InputParameterRecordingStructuresUtilities } from "../../input-parameter-recording-structures/input-parameter-recording-structures.utilities";
import { useHasOrganizationFullAccess } from "../../organization-module-access/has-organization-full-access.hook";
import { useHasOrganizationModuleAccess } from "../../organization-module-access/has-organization-module-access.hook";
import { useLastUsedRecordingPeriodFromRouter } from "../../recording-periods/use-last-used-recording-period.hooks";
import { useRecordingPeriodRecordingStructuresQuery } from "../../recording-structures/recording-structures.queries";
import { useAuthenticatedUserOrganizationRole } from "../../user/hooks/authenticated-user-org-role.hook";
import { FeatureFlag } from "../constants/feature-flags.constants";
import {
  ConfigureIcon,
  DmaIcon,
  EmissionFactorsIcon,
  EntryDataIcon,
  EsrsIcon,
  GhgIcon,
  LayoutDashboardIcon,
  RecordingPeriodIcon,
  SettingsIcon,
  SourcesIcon,
  StakeholderIcon,
  TargetPathsIcon,
  TargetPathsReferenceBudgetsIcon,
  UsersIcon,
  VsmeIcon,
} from "../constants/tabler-icon.constants";
import { ISideBarNavigationItemTopLevelV1 } from "../side-bar-sections/navigation-items-side-bar-section-v1.component";
import {
  ISideBarNavigationGroupV2,
  ISideBarNavigationItem,
} from "../side-bar-sections/navigation-items-side-bar-section-v2.component";
import { EnvironmentUtilities } from "../utilities/environment.utilities";
import { AppInternalNavigationUtilities } from "../utilities/app-internal-navigation.utilities";
import { useIsFeatureFlagEnabled } from "./is-feature-flag-enabled.hook";
import { IInputParameterRecordingStructureBase } from "@netcero/netcero-core-api-client";

function setNavbarIconProps<T>(Icon: FC<T>) {
  return forwardRef((props: T, ref) => {
    return <Icon size={24} {...props} ref={ref} />;
  });
}

export const useOrganizationSideBarNavItems = () => {
  const { organizationId, recordingPeriodId } = useParams();
  const translateContent = useTranslateContent();

  const doesCurrentOrgHaveFullAccess = useHasOrganizationFullAccess(organizationId ?? "");
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);

  const lastUsedRecordingPeriod = useLastUsedRecordingPeriodFromRouter();

  const activeRecordingPeriodId = useMemo(
    () => recordingPeriodId ?? lastUsedRecordingPeriod?.id ?? undefined,
    [recordingPeriodId, lastUsedRecordingPeriod],
  );

  const hasAccessToDMA = useHasOrganizationModuleAccess(organizationId ?? "", AppModule.DMA);
  const hasAccessToESRS = useHasOrganizationModuleAccess(organizationId ?? "", AppModule.ESRS);
  const hasAccessToVSME = useHasOrganizationModuleAccess(organizationId ?? "", AppModule.VSME);
  const hasAccessToGHG = useHasOrganizationModuleAccess(
    organizationId ?? "",
    AppModule.CARBON_ACCOUNTING,
  );

  const authenticatedUserOrgRole = useAuthenticatedUserOrganizationRole();
  const isOrganizationAdmin = authenticatedUserOrgRole === OrganizationRole.ORGANIZATION_ADMIN;

  const recordingStructuresQuery = useRecordingPeriodRecordingStructuresQuery(
    organizationId!,
    recordingPeriodId! ?? lastUsedRecordingPeriod?.id,
  );

  const recordingStructures = useMemo(() => {
    if (!recordingStructuresQuery.data) {
      return [];
    }
    return recordingStructuresQuery.data.recordingStructures;
  }, [recordingStructuresQuery.data]);

  const esrsSubItems = useMemo(() => {
    return recordingStructures.filter((structure) =>
      InputParameterRecordingStructuresUtilities.isEsrsRecordingStructureByIdentifier(structure),
    );
  }, [recordingStructures]);

  const vsmeSubItems = useMemo(() => {
    return recordingStructures.filter((structure) =>
      InputParameterRecordingStructuresUtilities.isVsmeRecordingStructureByIdentifier(structure),
    );
  }, [recordingStructures]);

  const ghgSubItems = useMemo(() => {
    return recordingStructures.filter((structure) =>
      InputParameterRecordingStructuresUtilities.isGhgRecordingStructureByIdentifier(structure),
    );
  }, [recordingStructures]);

  const { t } = useTranslation("side_bar_nav_items");

  // TODO: NC-XYZ Clean up - remove resultV1, clean up types
  return useMemo<ISideBarNavigationItemTopLevelV1[] | ISideBarNavigationGroupV2[]>(() => {
    const resultV1: ISideBarNavigationItemTopLevelV1[] = [
      {
        id: "value_input",
        name: t("value_input"),
        icon: setNavbarIconProps(EntryDataIcon),
        path: `/organizations/${organizationId}`,
        children: [],
      },
    ];

    if (!EnvironmentUtilities.IS_PRODUCTION || doesCurrentOrgHaveFullAccess) {
      resultV1.push({
        id: "target_paths",
        name: t("target_paths"),
        icon: setNavbarIconProps(TargetPathsIcon),
        path: `/organizations/${organizationId}/target-paths`,
        children: [
          {
            id: "target_paths_reference_budgets",
            name: t("target_paths_reference_budgets"),
            icon: setNavbarIconProps(TargetPathsReferenceBudgetsIcon),
            path: `/organizations/${organizationId}/target-paths/reference-budgets`,
          },
        ],
      });
    }

    // Always add Configuration
    resultV1.push({
      id: "configuration",
      name: t("configuration"),
      icon: setNavbarIconProps(ConfigureIcon),
      path: `/organizations/${organizationId}/configuration`,
      children: [
        ...(isOrganizationAdmin
          ? [
              {
                id: "configuration_recording_periods",
                name: t("configuration_recording_periods"),
                icon: setNavbarIconProps(RecordingPeriodIcon),
                path: `/organizations/${organizationId}/configuration/recording-periods`,
              },
            ]
          : []),
        ...(hasAccessToDMA
          ? [
              {
                id: "configuration_stakeholder",
                name: t("configuration_stakeholder"),
                icon: setNavbarIconProps(StakeholderIcon),
                path: `/organizations/${organizationId}/configuration/stakeholder`,
              },
            ]
          : []),
        {
          id: "configuration_sources",
          name: t("configuration_sources"),
          icon: setNavbarIconProps(SourcesIcon),
          path: `/organizations/${organizationId}/configuration/sources`,
        },
        {
          id: "configuration_users",
          name: t("configuration_users"),
          icon: setNavbarIconProps(UsersIcon),
          path: `/organizations/${organizationId}/configuration/users`,
        },
        ...(hasAccessToGHG
          ? [
              {
                id: "configuration_emission_factors",
                name: t("configuration_emission_factors"),
                icon: setNavbarIconProps(EmissionFactorsIcon),
                path: AppInternalNavigationUtilities.getConfigurationEmissionFactorsPageUrl(
                  organizationId!,
                ),
              },
            ]
          : []),
      ],
    });

    const resultV2: ISideBarNavigationGroupV2[] = [
      {
        id: "dashboard",
        name: undefined,
        items: [
          {
            id: "value_input",
            name: t("dashboard"),
            icon: setNavbarIconProps(LayoutDashboardIcon),
            path:
              organizationId && activeRecordingPeriodId
                ? AppInternalNavigationUtilities.getRecordingPeriodDashboardPageUrl(
                    organizationId,
                    activeRecordingPeriodId,
                  )
                : `/organizations/${organizationId}`,
          },
        ],
      },
    ];

    // Add Reporting sections if user has access to any of the modules
    if ((hasAccessToDMA || hasAccessToESRS || hasAccessToGHG) && activeRecordingPeriodId) {
      resultV2.push({
        id: "reporting",
        name: t("reporting"),
        items: [
          ...(hasAccessToDMA
            ? [
                {
                  id: "dma",
                  name: t("dma"),
                  icon: setNavbarIconProps(DmaIcon),
                  path: AppInternalNavigationUtilities.getDMAPageUrl({
                    organizationId: organizationId!,
                    recordingPeriodId: activeRecordingPeriodId,
                  }),
                },
              ]
            : []),
          ...getItemForRecordingStructuresGroup(
            organizationId!,
            activeRecordingPeriodId,
            !!hasAccessToESRS,
            "esrs",
            setNavbarIconProps(EsrsIcon),
            esrsSubItems,
            t,
            translateContent,
          ),
          ...getItemForRecordingStructuresGroup(
            organizationId!,
            activeRecordingPeriodId,
            !!hasAccessToVSME,
            "vsme",
            setNavbarIconProps(VsmeIcon),
            vsmeSubItems,
            t,
            translateContent,
          ),
          ...getItemForRecordingStructuresGroup(
            organizationId!,
            activeRecordingPeriodId,
            !!hasAccessToGHG,
            "ghg",
            setNavbarIconProps(GhgIcon),
            ghgSubItems,
            t,
            translateContent,
          ),
        ],
      });
    }

    if (!EnvironmentUtilities.IS_PRODUCTION || doesCurrentOrgHaveFullAccess) {
      resultV2.push({
        id: "transformation",
        name: t("transformation"),
        items: [
          {
            id: "target_paths",
            name: t("target_paths"),
            icon: setNavbarIconProps(TargetPathsIcon),
            path: AppInternalNavigationUtilities.getTargetPageUrl(organizationId!),
          },
          {
            id: "target_paths_reference_budgets",
            name: t("target_paths_reference_budgets"),
            icon: setNavbarIconProps(TargetPathsReferenceBudgetsIcon),
            path: AppInternalNavigationUtilities.getReferenceBudgetsPageUrl(organizationId!),
          },
        ],
      });
    }

    // Always add Configuration
    resultV2.push({
      id: "configuration",
      name: "",
      items: [
        {
          id: "configuration",
          name: t("configuration"),
          icon: setNavbarIconProps(SettingsIcon),
          path: AppInternalNavigationUtilities.getConfigurationPageUrl(organizationId!),
        },
      ],
    });

    // TODO: NC-XYZ Clean up - remove the ternary operator with V1 and only return V2
    return isFeatureFlagEnabled ? resultV2 : resultV1;
  }, [
    t,
    organizationId,
    doesCurrentOrgHaveFullAccess,
    isOrganizationAdmin,
    hasAccessToDMA,
    activeRecordingPeriodId,
    hasAccessToESRS,
    hasAccessToGHG,
    isFeatureFlagEnabled,
    esrsSubItems,
    ghgSubItems,
    translateContent,
  ]);
};

function getItemForRecordingStructuresGroup(
  organizationId: string,
  activeRecordingPeriodId: string,
  isAccessible: boolean,
  groupId: "ghg" | "esrs" | "vsme",
  icon: FC,
  subItems: IInputParameterRecordingStructureBase[],
  t: (key: string) => string,
  translateContent: IUseTranslateContentResult,
): ISideBarNavigationItem[] {
  const isSingleStructure = subItems.length === 1;

  const getSubItemPath = (subItem: IInputParameterRecordingStructureBase) =>
    AppInternalNavigationUtilities.getRecordingStructurePageUrl(
      organizationId,
      activeRecordingPeriodId,
      groupId,
      subItem.id,
    );

  return isAccessible && subItems.length > 0
    ? [
        {
          id: groupId,
          name: t(groupId),
          icon,
          // Directly link to structure if only one subItem exists
          path: isSingleStructure
            ? getSubItemPath(subItems[0])
            : AppInternalNavigationUtilities.getFilteredRecordingPeriodDashboardPageUrl(
                organizationId,
                activeRecordingPeriodId,
                groupId,
              ),
          // Only add items if at least one subItem exists (otherwise direct link to structure)
          items: !isSingleStructure
            ? subItems.map((subItem) => ({
                id: subItem.categoryIdentifier,
                name: translateContent(subItem.name),
                path: getSubItemPath(subItem),
              }))
            : undefined,
        },
      ]
    : [];
}
