import { createContext, FC, PropsWithChildren, useContext } from "react";
import {
  IUseCommentsAndAuditLogSidebarStateResult,
  useCommentsAndAuditLogSidebarState,
} from "../hooks/comments-and-audit-log-sidebar-state.hook";

export const CommentsAndAuditLogSidebarStateContext =
  createContext<IUseCommentsAndAuditLogSidebarStateResult<unknown> | null>(null);

export const CommentsAndAuditLogSidebarStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const sidebarState = useCommentsAndAuditLogSidebarState();
  return (
    <CommentsAndAuditLogSidebarStateContext.Provider value={sidebarState}>
      {children}
    </CommentsAndAuditLogSidebarStateContext.Provider>
  );
};

export function useCommentsAndAuditLogSidebarStateContext<T = unknown>() {
  const context = useContext(CommentsAndAuditLogSidebarStateContext);
  if (!context) {
    throw new Error(
      "useCommentsAndAuditLogSidebarStateContext must be used within CommentsAndAuditLogSidebarStateProvider",
    );
  }
  return context as IUseCommentsAndAuditLogSidebarStateResult<T>;
}
