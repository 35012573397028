import { IContentLanguage, IEmissionFactorDatabase } from "@netcero/netcero-core-api-client";

const EMISSION_FACTOR_DATABASE_TRANSLATIONS_DE: Record<IEmissionFactorDatabase, string> = {
  [IEmissionFactorDatabase.UbaAt]: "UBA Österreich",
  [IEmissionFactorDatabase.Defra]: "DEFRA",
  [IEmissionFactorDatabase.OrganizationSpecific]: "Organisationsspezifisch",
  [IEmissionFactorDatabase.Climcalc]: "ClimCalc",
  [IEmissionFactorDatabase.Dgnb]: "DGNB",
  [IEmissionFactorDatabase.Ecoinvent]: "ecoinvent",
  [IEmissionFactorDatabase.Exiobase]: "EXIOBASE",
  [IEmissionFactorDatabase.Bafa]: "BAFA",
  [IEmissionFactorDatabase.Owid]: "Our World in Data",
  [IEmissionFactorDatabase.Aib]: "AIB",
  [IEmissionFactorDatabase.Unspecified]: "Nicht spezifiziert",
};

const EMISSION_FACTOR_DATABASE_TRANSLATIONS_EN: Record<IEmissionFactorDatabase, string> = {
  [IEmissionFactorDatabase.UbaAt]: "UBA Austria",
  [IEmissionFactorDatabase.Defra]: "DEFRA",
  [IEmissionFactorDatabase.OrganizationSpecific]: "Organization-Specific",
  [IEmissionFactorDatabase.Climcalc]: "ClimCalc",
  [IEmissionFactorDatabase.Dgnb]: "DGNB",
  [IEmissionFactorDatabase.Ecoinvent]: "ecoinvent",
  [IEmissionFactorDatabase.Exiobase]: "EXIOBASE",
  [IEmissionFactorDatabase.Bafa]: "BAFA",
  [IEmissionFactorDatabase.Owid]: "Our World in Data",
  [IEmissionFactorDatabase.Aib]: "AIB",
  [IEmissionFactorDatabase.Unspecified]: "Unspecified",
};

export const EMISSION_FACTOR_DATABASE_TRANSLATIONS_PER_LANGUAGE: Record<
  IContentLanguage,
  typeof EMISSION_FACTOR_DATABASE_TRANSLATIONS_DE
> = {
  [IContentLanguage.De]: EMISSION_FACTOR_DATABASE_TRANSLATIONS_DE,
  [IContentLanguage.En]: EMISSION_FACTOR_DATABASE_TRANSLATIONS_EN,
};
