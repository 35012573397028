import { Button, Menu } from "@mui/material";
import { IIROState } from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IROStateIndicator } from "./iro-state-indicator.component";
import {
  IStatusChangeMenuItem,
  StatusChangeMenuItems,
} from "../../common/components/status-change-menu-items.component";

interface IIROStateSelectProps {
  value: IIROState;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (value: IIROState) => void;
}
export const IROStateSelect: FC<IIROStateSelectProps> = ({
  value,
  disabled,
  readOnly,
  onChange,
}) => {
  const { t } = useTranslation("dma_common");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleChangeValue = useCallback(
    (newState: IIROState) => {
      onChange(newState);
      setShowMenu(false);
    },
    [onChange],
  );

  const statusChangeItems: IStatusChangeMenuItem[] = useMemo(() => {
    switch (value) {
      case IIROState.Draft:
        return [
          {
            id: "share",
            onClick: () => handleChangeValue(IIROState.Shared),
            title: t(`iro_state_transition.share`),
            newStatusIndicator: <IROStateIndicator state={IIROState.Shared} />,
            disabled: false,
          },
        ];
      case IIROState.Shared:
        return [
          {
            id: "move_to_draft",
            onClick: () => handleChangeValue(IIROState.Draft),
            title: t(`iro_state_transition.move_to_draft`),
            newStatusIndicator: <IROStateIndicator state={IIROState.Draft} />,
            disabled: false,
          },
        ];
      default:
        return [];
    }
  }, [value, handleChangeValue, t]);

  if (readOnly) {
    return <IROStateIndicator state={value} />;
  }

  return (
    <>
      <Menu open={showMenu} anchorEl={buttonRef.current} onClose={() => setShowMenu(false)}>
        <StatusChangeMenuItems items={statusChangeItems} />
      </Menu>

      <Button
        ref={buttonRef}
        variant="outlined"
        size="small"
        color="inherit"
        onClick={() => setShowMenu(true)}
        disabled={disabled}
        // TODO: Quick and dirty color change, maybe update later
        sx={{ pl: 0.5, borderColor: "#0000004D" }}
      >
        <IROStateIndicator state={value} isMenuOpen={showMenu} shouldRenderChevronIcon />
      </Button>
    </>
  );
};
