import React, { Dispatch, FC } from "react";
import { SingleOrganizationUserAvatarPicker } from "../../user/components/single-organization-user-avatar-picker.component";
import { IOrganizationIdentity, ROLE_ACCESS } from "@netcero/netcero-common";
import { IRecordingPeriodGhgCategory } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { useDoesUserHaveRole } from "../../authentication/hooks/does-user-have-role.hook";

interface IGhgCategoryResponsibleUserPickerProps {
  organizationIdentity: IOrganizationIdentity;
  recordingPeriodGhgCategory: IRecordingPeriodGhgCategory;
  onChange: Dispatch<string | null>;
  disabled: boolean;
}

export const GhgCategoryResponsibleUserPicker: FC<IGhgCategoryResponsibleUserPickerProps> = ({
  organizationIdentity,
  recordingPeriodGhgCategory,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation("add_responsible_user_button");
  const { responsibleUserId } = recordingPeriodGhgCategory;

  const isUserAllowedToChangeResponsiblePerson = useDoesUserHaveRole(
    ROLE_ACCESS.ALLOWED_TO_MODIFY_GHG_CATEGORY_RESPONSIBLE_USER,
  );

  return (
    <SingleOrganizationUserAvatarPicker
      organizationId={organizationIdentity.organizationId}
      value={responsibleUserId ?? null}
      tooltipNoUserSelected={t("add_tooltip")}
      noUsersAvailableText={t("no_users_available")}
      noUserOption
      noUserOptionText={t("no_user_selected")}
      onChange={onChange}
      disabled={disabled || !isUserAllowedToChangeResponsiblePerson}
    />
  );
};
