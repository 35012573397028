import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { AssociatedIROsControllersComponent } from "./accociated-iros/associated-iros-controllers.component";
import {
  RecursiveKeyOf,
  RecursiveObjectPaths,
  WithEqualUndefinedAndNullRecursive,
} from "@netcero/netcero-common";
import { MdrInputComponentFactory } from "./mdr-input-components/mdr-input-component.factory";
import {
  IAction,
  IDMACategoryWithEffectsAndChildren,
  IPolicy,
} from "@netcero/netcero-core-api-client";
import { Control, Path, UseFormWatch } from "react-hook-form";
import { IMDRInput } from "../mdr-input.types";
import { TFunction } from "i18next";
import { AssociatedPoliciesWithController } from "./associated-policies-controller.component";
import { AssociatedActionsWithController } from "./associated-actions-controller.component";
import { UseControllerProps } from "react-hook-form/dist/types/controller";

/** Special business logic components that will have custom handling */
export type ISpecialTopicSectionInputIdentifier = "iros" | "policies" | "actions"; // Possibly extend this in the future

export interface IMdrTopicSectionCommonProps<T extends object> {
  topicPrefix: RecursiveObjectPaths<T>;
  sectionTitle?: string;
  control: Control<WithEqualUndefinedAndNullRecursive<T>>;
  watch: UseFormWatch<WithEqualUndefinedAndNullRecursive<T>>;
  inputs: (string | ISpecialTopicSectionInputIdentifier)[];
  inputsMetaData: Record<string, IMDRInput>;
  inputsRules?: Partial<
    Record<RecursiveKeyOf<T>, UseControllerProps<WithEqualUndefinedAndNullRecursive<T>>["rules"]>
  >;
  disabled: boolean;
  /** Only required if the section contains the special input "iros" */
  dmaCategories?: IDMACategoryWithEffectsAndChildren[];
  /** Only required if the section contains the special input "policies" */
  policies?: IPolicy[];
  /** Only required if the section contains the special input "actions" */
  actions?: IAction[];
}

type DependencyCallbackType<T> = Omit<
  T,
  | "financialEffectIds"
  | "materialImpactIds"
  | "policyIds"
  | "actionIds"
  | "dataEntryObjectId"
  | "recordingPeriodId"
>;

interface IMdrTopicSectionProps<T extends object> extends IMdrTopicSectionCommonProps<T> {
  t: TFunction<string, undefined>;
  // Dependency Management
  isInputConditional: (propertyPath: RecursiveKeyOf<DependencyCallbackType<T>>) => boolean;
  isInputDependedUponByAnother: (
    propertyPath: RecursiveKeyOf<DependencyCallbackType<T>>,
  ) => boolean;
  isInputAvailable: (
    propertyPath: RecursiveKeyOf<DependencyCallbackType<T>>,
    allProperties: WithEqualUndefinedAndNullRecursive<T>,
  ) => boolean;
}

export function MdrTopicSection<T extends object>({
  topicPrefix,
  t,
  sectionTitle,
  control,
  watch,
  inputs,
  inputsMetaData,
  inputsRules,
  disabled,
  isInputConditional,
  isInputDependedUponByAnother,
  isInputAvailable,
  dmaCategories,
  policies,
  actions,
}: IMdrTopicSectionProps<T>) {
  const allProperties = watch();

  const inputComponents = useMemo(
    () =>
      inputs.map((propertyName) => {
        // Special Inputs
        if (propertyName === "iros") {
          return dmaCategories ? (
            <AssociatedIROsControllersComponent
              key={propertyName}
              dmaCategories={dmaCategories}
              disabled={disabled}
              label={t("iro_dropdown_text", { ns: "mdr_common" })}
            />
          ) : (
            <span key={propertyName}>
              "ERROR: Please provide dmaCategories prop for the special input 'iros'"
            </span>
          );
        }

        if (propertyName === "policies") {
          return policies ? (
            <AssociatedPoliciesWithController
              label={t("label_associated_policies", { ns: "mdr_common" })}
              key={propertyName}
              policies={policies}
            />
          ) : (
            <span key={propertyName}>
              "ERROR: Please provide policies prop for the special input 'policies'"
            </span>
          );
        }

        if (propertyName === "actions") {
          return actions ? (
            <AssociatedActionsWithController
              label={t("label_associated_actions", { ns: "mdr_common" })}
              key={propertyName}
              actions={actions}
            />
          ) : (
            <span key={propertyName}>
              "ERROR: Please provide actions prop for the special input 'actions'"
            </span>
          );
        }

        // Handle normal Inputs
        const inputMetaData = inputsMetaData[propertyName];
        const fullPropertyPath = `${topicPrefix}.${propertyName}` as RecursiveKeyOf<
          DependencyCallbackType<T>
        >;
        const isDependedUponByAnother = isInputDependedUponByAnother(fullPropertyPath);
        const isConditional = isInputConditional(fullPropertyPath);
        // As is fine since fullPropertyPath is a subset of RecursiveKeyOf<T>
        const rules = inputsRules?.[fullPropertyPath as RecursiveKeyOf<T>];

        const InputComponent = MdrInputComponentFactory.createComponent<
          WithEqualUndefinedAndNullRecursive<T>
        >(
          inputMetaData,
          {
            propertyPath: fullPropertyPath as Path<WithEqualUndefinedAndNullRecursive<T>>,
            label: t(`labels.${fullPropertyPath}`),
            control,
            isDependedUponByAnother,
            isConditional,
            rules,
            getRuleTranslation: (property, rule) => t(`input_errors.${property}.${rule}`),
            disabled,
          },
          // ns separator is false because the enum key contains ':' which is the default ns separator
          (enumValueKey) => t(`enums.${fullPropertyPath}.${enumValueKey}`, { nsSeparator: false }),
          t("optional", { ns: "mdr_common" }),
        );

        return (
          isInputAvailable(fullPropertyPath, allProperties) && (
            <React.Fragment key={propertyName}>{InputComponent}</React.Fragment>
          )
        );
      }),
    [
      actions,
      allProperties,
      control,
      disabled,
      dmaCategories,
      inputs,
      inputsMetaData,
      inputsRules,
      isInputAvailable,
      isInputConditional,
      isInputDependedUponByAnother,
      policies,
      t,
      topicPrefix,
    ],
  );

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={1}>
      {sectionTitle && (
        <Typography variant="h6" component="h4">
          {sectionTitle}
        </Typography>
      )}
      {inputComponents}
    </Box>
  );
}
