import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NoticeComponent } from "../../common/components/notice.component";
import { Link } from "@react-email/components";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";
import { useCurrentOrganization } from "../../organizations/use-current-organization.hook";

export const SUPPORT_EMAIL = "support@netcero.eu";

export const NotAuthorizedNotice: FC = () => {
  const { t } = useTranslation("require_organization_role");
  const organization = useCurrentOrganization();

  return (
    <NoticeComponent
      title={t("access_denied_title")}
      content={
        <Trans
          i18nKey={t("access_denied_description")}
          values={{
            link: SUPPORT_EMAIL,
          }}
          components={{
            anchor: <Link href={`mailto:${SUPPORT_EMAIL}`} />,
          }}
        />
      }
      button={{
        text: t("back_to_home"),
        link: AppInternalNavigationUtilities.getOrganizationPageUrl(organization?.id ?? ""),
      }}
    />
  );
};
