import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataAttributesCompany,
  IntercomProps,
  IntercomProvider,
  useIntercom,
} from "react-use-intercom";
import { EnvironmentUtilities } from "../common/utilities/environment.utilities";
import { useUserContext } from "../user/user.context";
import crypto from "crypto";
import { useLocation } from "react-router-dom";
import { useOrganizations } from "../organizations/organizations.hook";
import { useIsFeatureFlagEnabled } from "../common/hooks/is-feature-flag-enabled.hook";
import { FeatureFlag } from "../common/constants/feature-flags.constants";

const { INTERCOM_APP_ID, IS_INTERCOM_ENABLED, INTERCOM_SECRET_KEY } = EnvironmentUtilities;

const INTERCOM_DEFAULT_MARGIN = 0;
const INTERCOM_SAFE_MARGIN = 425;

const MUI_DIALOG_SELECTOR = "body > div.MuiDialog-root";
const CONTENT_SELECTOR = "#content-box";
export const INTERCOM_MUI_DIALOG_MARGIN_TRANSITION = "margin-right 250ms ease";

// If the URL path contains any of these strings, Intercom will be hidden
const BLACKLISTED_URL_PATHS: string[] = [];

const IntercomHandler: FC<PropsWithChildren<IIntercomWrapperProps>> = ({ children, anonymous }) => {
  const organizations = useOrganizations();
  const location = useLocation();
  const intercom = useIntercom();
  const { i18n } = useTranslation();
  const { user } = useUserContext();

  const userProfile = useMemo(() => user?.userProfile, [user]);

  const companies = useMemo(() => {
    return organizations.map<DataAttributesCompany>((organization) => ({
      name: organization.name,
      companyId: organization.id,
    }));
  }, [organizations]);

  // Boot Intercom messenger once user exists
  useEffect(() => {
    const shouldHide = BLACKLISTED_URL_PATHS.some((url) => location.pathname.includes(url));
    const baseOptions: IntercomProps = {
      languageOverride: i18n.language,
      hideDefaultLauncher: shouldHide,
    };

    // Handle anonymous mode
    if (anonymous) {
      intercom.boot(baseOptions);
      return;
    }

    // Handle user expected
    if (!userProfile || !userProfile.id) {
      return;
    }

    const userIdentifier = userProfile.id;
    const hash = crypto
      .createHmac("sha256", INTERCOM_SECRET_KEY)
      .update(userIdentifier)
      .digest("hex");

    intercom.boot({
      ...baseOptions,
      createdAt: userProfile.createdTimestamp,
      userId: userProfile.id,
      email: userProfile.email,
      name: `${userProfile.firstName} ${userProfile.lastName}`,
      userHash: hash,
      companies,
    });

    if (user?.jwtPayload.redirectToKb) {
      const checkIntercomBooted = setInterval(() => {
        if (window.Intercom?.booted) {
          clearInterval(checkIntercomBooted);
          window.location.href = "https://help.netcero.eu";
        }
      }, 500);
    }
  }, [
    userProfile,
    intercom,
    anonymous,
    i18n.language,
    location.pathname,
    companies,
    user?.jwtPayload.redirectToKb,
  ]);

  return children;
};

interface IIntercomWrapperProps {
  anonymous?: boolean;
}

export const IntercomWrapper: FC<PropsWithChildren<IIntercomWrapperProps>> = ({
  children,
  anonymous,
}) => {
  // NOTE:
  // If the marginRight Code (modifying the document node directly) ever causes issues (because state mangement or something reinitializes is),
  // we might want to switch to just adding some css into the DOM dynamically to apply the margin
  const [margin, setMargin] = useState(`${INTERCOM_DEFAULT_MARGIN}px`);

  const handleUpdateMargin = useCallback(
    (newMargin: string) => {
      setMargin(newMargin);
    },
    [setMargin],
  );

  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      shouldInitialize={IS_INTERCOM_ENABLED}
      onShow={() => handleUpdateMargin(`${INTERCOM_SAFE_MARGIN}px`)}
      onHide={() => handleUpdateMargin(`${INTERCOM_DEFAULT_MARGIN}px`)}
      // Autoboot may be unnecessary, but it doesn't break anything so yeah
      autoBoot
    >
      {/* Intercom dependant styles */}
      {/* TODO: NC-XYZ remove styling which requires !isFeatureFlagEnabled */}
      <style>
        {!isFeatureFlagEnabled && `body { margin-right: ${margin}; background-color: #FBFBFB}`}
        {!isFeatureFlagEnabled &&
          `${MUI_DIALOG_SELECTOR} { margin-right: ${margin}; transition: ${INTERCOM_MUI_DIALOG_MARGIN_TRANSITION}; }`}
        {`${CONTENT_SELECTOR} { margin-right: ${margin}; transition: ${INTERCOM_MUI_DIALOG_MARGIN_TRANSITION}; }`}
      </style>
      {/* Handler */}
      <IntercomHandler anonymous={anonymous}>{children}</IntercomHandler>
    </IntercomProvider>
  );
};
