import {
  Badge,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Menu,
  Paper,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DashboardIcon,
  FilterIcon,
  HideIcon,
  IconSize,
  ShowIcon,
} from "../common/constants/tabler-icon.constants";
import { PopupMenuFilterUsersSectionComponent } from "../user/components/popup-menu-filter-users-section.component";
import { IShowGroupsState } from "./esrs-topics-stepper.component";
import { AppInternalNavigationUtilities } from "../common/utilities/app-internal-navigation.utilities";
import { OnlyShowToUsersWithRole } from "../authentication/components/only-show-to-users-with-role.component";
import { ROLE_ACCESS } from "@netcero/netcero-common";
import { useDoesUserHaveRole } from "../authentication/hooks/does-user-have-role.hook";
import { RequireFeatureFlag } from "../common/components/require-feature-flag.component";
import { FeatureFlag } from "../common/constants/feature-flags.constants";
import { IUseStepperNavigationProps, useStepperNavigation } from "./hooks/stepper-navigation";

const BOX_SHADOW_STYLE = { boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.1)" };
const TOOLTIP_PLACEMENT = "bottom";

interface IESRSTopicsStepperFloatingActionBarComponentProps {
  organizationId: string;
  recordingPeriodId: string;
  showGroups: IShowGroupsState;
  setShowGroups: Dispatch<SetStateAction<IShowGroupsState>>;
  stepperNavigationConfiguration: IUseStepperNavigationProps;
  filterableUserIds: string[];
  filterByUserIds: string[];
  onChangeFilterByUserIds: (userIds: string[]) => void;
  hasDMABeenCompleted: boolean;
  canCompleteDMA: boolean;
  onComplete: () => void;
}

export const ESRSTopicsStepperFloatingActionBarComponent: FC<
  IESRSTopicsStepperFloatingActionBarComponentProps
> = ({
  organizationId,
  recordingPeriodId,
  showGroups,
  setShowGroups,
  stepperNavigationConfiguration,
  filterableUserIds,
  filterByUserIds,
  onChangeFilterByUserIds,
  hasDMABeenCompleted,
  canCompleteDMA,
  onComplete,
}) => {
  const { t } = useTranslation("double_materiality_assessment_stepper_component");

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const actionBarContainerRef = useRef<HTMLDivElement>(null);

  const { handleChangeStep, selectedESRSTopicIndex, stepperIndexesLookup } = useStepperNavigation(
    stepperNavigationConfiguration,
  );

  const hasFiltersEnabled =
    !showGroups.showFinancialEffects ||
    !showGroups.showMaterialImpacts ||
    filterByUserIds.length > 0;

  const hasAccessToDmaConfiguration = useDoesUserHaveRole(
    ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CONFIGURATION,
  );
  const smallestAvailableIndex = hasAccessToDmaConfiguration ? 0 : 1;

  return (
    <>
      {/* Floating Action Bar */}
      <Box ref={actionBarContainerRef} position="fixed" right={86} bottom={19} pt={2}>
        <Paper sx={{ ...BOX_SHADOW_STYLE, borderRadius: 6, border: 0, p: 0.25 }}>
          <Box display="flex" gap={2} px={1.5} py={0.25}>
            <Tooltip title={t("previous_step_button")} placement={TOOLTIP_PLACEMENT}>
              <span>
                <IconButton
                  onClick={() => handleChangeStep(-1)}
                  disabled={selectedESRSTopicIndex <= smallestAvailableIndex}
                >
                  <ChevronLeftIcon size={IconSize.Medium} />
                </IconButton>
              </span>
            </Tooltip>
            <RequireFeatureFlag negate featureFlag={FeatureFlag.APP_SHELL_V2}>
              <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_ACCESS_DMA_DASHBOARD}>
                <Tooltip title={t("label_dma_dashboard")} placement={TOOLTIP_PLACEMENT}>
                  <span>
                    <IconButton
                      component={RouterLink}
                      to={AppInternalNavigationUtilities.getDMADashboardPageUrl({
                        organizationId,
                        recordingPeriodId,
                      })}
                    >
                      <DashboardIcon size={IconSize.Medium} />
                    </IconButton>
                  </span>
                </Tooltip>
              </OnlyShowToUsersWithRole>
            </RequireFeatureFlag>
            <Tooltip title={t("next_step_button")} placement={TOOLTIP_PLACEMENT}>
              <span>
                <IconButton
                  onClick={() => handleChangeStep(+1)}
                  disabled={stepperIndexesLookup.length - 1 <= selectedESRSTopicIndex}
                >
                  <ChevronRightIcon size={IconSize.Medium} />
                </IconButton>
              </span>
            </Tooltip>
            <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_COMPLETE_DMA}>
              {hasDMABeenCompleted ? (
                <Box p={0.5} display="flex" alignItems="center">
                  <Tooltip title={t("tooltip_icon_dma_completed")}>
                    <SvgIcon color="success">
                      <CheckIcon size={IconSize.Medium} />
                    </SvgIcon>
                  </Tooltip>
                </Box>
              ) : (
                <Tooltip
                  title={t(canCompleteDMA ? "complete_dma_button" : "can_not_complete_dma")}
                  placement={TOOLTIP_PLACEMENT}
                >
                  <span>
                    <IconButton onClick={() => onComplete()} disabled={!canCompleteDMA}>
                      <CheckIcon size={IconSize.Medium} />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </OnlyShowToUsersWithRole>
            {/* Divider */}
            <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
            <Tooltip title={t("button_filter")} placement={TOOLTIP_PLACEMENT}>
              <span>
                <IconButton onClick={() => setShowFilterMenu(true)}>
                  <Badge
                    variant="dot"
                    badgeContent=" "
                    color="primary"
                    overlap="circular"
                    invisible={!hasFiltersEnabled}
                  >
                    <FilterIcon size={IconSize.Medium} />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Paper>
      </Box>

      {/* Filter Menu */}
      <Menu
        elevation={0}
        slotProps={{
          paper: { variant: "elevation", sx: { ...BOX_SHADOW_STYLE, borderRadius: 6 } },
        }}
        open={showFilterMenu}
        anchorEl={actionBarContainerRef.current}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => setShowFilterMenu(false)}
      >
        <Box display="flex" flexDirection="column" px={2} minWidth={225}>
          <PopupMenuFilterUsersSectionComponent
            organizationId={organizationId}
            filterableUserIds={filterableUserIds}
            filterByUserIds={filterByUserIds}
            onChangeFilterByUserIds={onChangeFilterByUserIds}
            noUsersAvailableMessage={t("no_filterable_users_available")}
          />
          <Divider sx={{ mb: 1 }} />
          {/* Checkboxes for IRO Types */}
          <FormControlLabel
            label={t("material_impacts_checkbox_label")}
            control={
              <Checkbox
                icon={<ShowIcon size={IconSize.Medium} />}
                checkedIcon={<HideIcon size={IconSize.Medium} />}
                color="default"
                size="small"
                checked={showGroups.showMaterialImpacts}
                onChange={(_, checked) =>
                  setShowGroups((currState) => ({ ...currState, showMaterialImpacts: checked }))
                }
              />
            }
          />
          <FormControlLabel
            label={t("financial_effects_checkbox_label")}
            control={
              <Checkbox
                icon={<ShowIcon size={IconSize.Medium} />}
                checkedIcon={<HideIcon size={IconSize.Medium} />}
                color="default"
                size="small"
                checked={showGroups.showFinancialEffects}
                onChange={(_, checked) =>
                  setShowGroups((currState) => ({ ...currState, showFinancialEffects: checked }))
                }
              />
            }
          />
        </Box>
      </Menu>
    </>
  );
};

export const ESRSTopicsStepperFloatingActionBarComponentSpacer: FC = () => {
  return <Box height={124} />;
};
