import { useCallback } from "react";
import { useGetUsersByOrganizationRoleQuery } from "../users.queries";
import { OrganizationRole } from "@netcero/netcero-common";

export function useGetOrganizationRoleOfUser(organizationId: string) {
  const { data: organizationAdminUsers } = useGetUsersByOrganizationRoleQuery(
    organizationId,
    OrganizationRole.ORGANIZATION_ADMIN,
  );
  const { data: objectAdminUsers } = useGetUsersByOrganizationRoleQuery(
    organizationId,
    OrganizationRole.OBJECT_ADMIN,
  );
  const { data: viewMemberUsers } = useGetUsersByOrganizationRoleQuery(
    organizationId,
    OrganizationRole.VIEW_MEMBERS,
  );

  return useCallback(
    (userId: string | undefined | null) => {
      if (organizationAdminUsers?.find((user) => user.id === userId)) {
        return OrganizationRole.ORGANIZATION_ADMIN;
      } else if (objectAdminUsers?.find((user) => user.id === userId)) {
        return OrganizationRole.OBJECT_ADMIN;
      } else if (viewMemberUsers?.find((user) => user.id === userId)) {
        return OrganizationRole.VIEW_MEMBERS;
      }
      return null;
    },
    [organizationAdminUsers, objectAdminUsers, viewMemberUsers],
  );
}
