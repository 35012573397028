import { ArrayUtilities, CsvUtilities } from "@netcero/netcero-common";

/**
 * Options for generating a CSV export.
 *
 * @template T - The type of the data items to be exported.
 * @template P - The type of the data items after transformation. Defaults to T. (optional)
 *
 * @property {string[]} headers - The fields in the header row.
 * @property {(item: T) => string[]} rowBuilder - A function that builds a row from a data item.
 * @property {(data: T[]) => T[]} [transformFunction] - Optional function to map data on export.
 */
export interface ICsvExportOptions<T, P = T> {
  headers: string[];
  rowBuilder: (item: P) => string[];
  transformFunction?: (data: T) => P | P[];
}

export class CsvExportUtilities {
  /**
   * Generates a CSV string from the provided data using the given options.
   *
   * @template T - The type of the data items to be exported.
   * @param {T} data - The data to be exported.
   * @param {ICsvExportOptions<T>} options - Options defining how to generate the CSV.
   *
   * @returns {string} The generated CSV string.
   *
   * @example
   * const csvString = generateCsvString(myData, {
   *   headers: ['header1', 'header2'],
   *   rowBuilder: (item) => [item.value1, item.value2],
   *   transformFunction: (data) => data // if necessary
   * }, t);
   */
  public static generateCsvString<T, P = T>(data: T, options: ICsvExportOptions<T, P>): string {
    const transformedData = options.transformFunction
      ? options.transformFunction(data)
      : (data as unknown as P);
    const rows = ArrayUtilities.alwaysArray(transformedData).map(options.rowBuilder);

    return CsvUtilities.stringifyRows(options.headers, rows);
  }
}
