import { useCommentsAndAuditLogStateWithData } from "./comments-and-audit-log-state-with-data.hook";
import { useMemo } from "react";
import { getContentSxPropsForCommentsAndChangelogSidebarPaper } from "../components/sidebar-comments-and-audit-log-paper.component";
import { SxProps, Theme } from "@mui/material";
import { useViewedCommentWithReset } from "./viewed-comment-id-with-reset.hook";

export interface IUseCommentsAndAuditLogSidebarStateResult<T> {
  showCommentsAndAuditLogSideSection: boolean;
  isViewedCommentSet: boolean;
  // Passing undefined to this function will do nothing (keep data as is)
  toggleCommentsAndAuditLogSideSection: (newData?: T | null) => void;
  // Passing undefined to this function will do nothing (keep data as is)
  openCommentsAndAuditLogSideSection: (newData?: T | null) => void;
  // Passing undefined to this function will do nothing (keep data as is)
  closeCommentsAndAuditLogSideSection: (newData?: T | null) => void;
  mainContentSxProps: SxProps<Theme>;
  data: T | null;
  updateCommentsAndAuditLogSideSectionData: (newData: T | null) => void;
}

export function useCommentsAndAuditLogSidebarState<
  T,
>(): IUseCommentsAndAuditLogSidebarStateResult<T> {
  const {
    showCommentsAndAuditLogSideSection,
    data,
    updateCommentsAndAuditLogSideSectionData,
    toggleCommentsAndAuditLogSideSection: toggleCommentsAndAuditLogSideSectionInternal,
    openCommentsAndAuditLogSideSection,
    closeCommentsAndAuditLogSideSection: closeCommentsAndAuditLogSideSectionInternal,
  } = useCommentsAndAuditLogStateWithData<T>();

  const { isViewedCommentSet, resetViewedCommentAndExecuteAction } = useViewedCommentWithReset();

  const mainContentSxProps = useMemo(
    () => getContentSxPropsForCommentsAndChangelogSidebarPaper(showCommentsAndAuditLogSideSection),
    [showCommentsAndAuditLogSideSection],
  );

  return useMemo(
    () => ({
      data,
      isViewedCommentSet,
      updateCommentsAndAuditLogSideSectionData,
      showCommentsAndAuditLogSideSection,
      toggleCommentsAndAuditLogSideSection: resetViewedCommentAndExecuteAction(
        toggleCommentsAndAuditLogSideSectionInternal,
      ),
      openCommentsAndAuditLogSideSection: openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSection: resetViewedCommentAndExecuteAction(
        closeCommentsAndAuditLogSideSectionInternal,
      ),
      mainContentSxProps,
    }),
    [
      data,
      isViewedCommentSet,
      updateCommentsAndAuditLogSideSectionData,
      showCommentsAndAuditLogSideSection,
      resetViewedCommentAndExecuteAction,
      toggleCommentsAndAuditLogSideSectionInternal,
      openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSectionInternal,
      mainContentSxProps,
    ],
  );
}
