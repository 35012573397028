import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IManualStakeholderFeedback } from "@netcero/netcero-core-api-client";
import { FormatUtilities } from "../../../common/utilities/format.utilities";
import { DMA_MATERIALITY_MULTIPLIER } from "@netcero/netcero-dma";

interface IDMACategoryManualFeedbackViewProps {
  manualFeedback: IManualStakeholderFeedback;
}

export const DMACategoryManualFeedbackView = ({
  manualFeedback,
}: IDMACategoryManualFeedbackViewProps) => {
  const { t } = useTranslation("dma_category_manual_feedback_list");

  return (
    <Box>
      <table>
        <tbody>
          <tr>
            <Box component="td" fontWeight="bold" sx={{ verticalAlign: "top", px: 2 }}>
              {t("prefix_assessment")}
            </Box>
            <Box component="td" sx={{ px: 2 }}>
              {manualFeedback.assessment !== undefined
                ? FormatUtilities.formatNumber(
                    manualFeedback.assessment * DMA_MATERIALITY_MULTIPLIER,
                  )
                : "-"}
            </Box>
          </tr>
          <tr>
            <Box component="td" fontWeight="bold" sx={{ verticalAlign: "top", px: 2 }}>
              {t("prefix_comment")}
            </Box>
            <Box component="td" sx={{ px: 2, maxWidth: 400 }}>
              {manualFeedback.feedback ?? "-"}
            </Box>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};
