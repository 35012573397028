import { IDataEntryObjectBaseObjectTypeEnum } from "@netcero/netcero-core-api-client";

export const ORDERED_DATA_ENTRY_OBJECT_TYPES: IDataEntryObjectBaseObjectTypeEnum[] = [
  IDataEntryObjectBaseObjectTypeEnum.Organization,
  IDataEntryObjectBaseObjectTypeEnum.BusinessArea,
  IDataEntryObjectBaseObjectTypeEnum.Location,
  IDataEntryObjectBaseObjectTypeEnum.Department,
  IDataEntryObjectBaseObjectTypeEnum.Grouping,
  IDataEntryObjectBaseObjectTypeEnum.Team,
] as const;
export const DEFAULT_DATA_ENTRY_OBJECT_TYPE = IDataEntryObjectBaseObjectTypeEnum.Organization;
