import { FC } from "react";
import { IActivityValueDataQuality } from "@netcero/netcero-core-api-client";
import { UndefinedOrNull } from "@netcero/netcero-common";
import { ACTIVITY_VALUE_DATA_QUALITY_ICON } from "../ghg-activity-values.constants";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NoValueIcon } from "../../common/constants/tabler-icon.constants";

interface IGhgActivityValueDataQualityProps {
  dataQuality: UndefinedOrNull<IActivityValueDataQuality>;
}

export const GhgActivityValueDataQuality: FC<IGhgActivityValueDataQualityProps> = ({
  dataQuality,
}) => {
  const { t } = useTranslation("ghg_activity_values");
  const DataQualityIcon = dataQuality ? ACTIVITY_VALUE_DATA_QUALITY_ICON[dataQuality] : NoValueIcon;

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <DataQualityIcon />
      {dataQuality ? t(`data_quality.${dataQuality}`) : t("not_specified", { ns: "common" })}
    </Box>
  );
};
