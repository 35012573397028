import {
  IBaseDMAFinancialEffectData,
  IBaseDMAMaterialImpactData,
  IDataEntryObject,
  IDMACategoryWithEffectsAndChildren,
  IDMAFinancialEffect,
  IDMAMaterialImpact,
  IESRSTopic,
  IIROState,
} from "@netcero/netcero-core-api-client";
import { useIroEditDialogQueryParamState } from "./iro-edit-dialog-query-param-state.hook";
import { useIroMoveDialogState } from "./iro-move-dialog-state.hook";
import {
  useCreateFinancialEffectMutation,
  useCreateMaterialImpactMutation,
  useDeleteFinancialEffectMutation,
  useDeleteMaterialImpactMutation,
  useMoveFinancialEffectToOtherTopicMutation,
  useMoveMaterialImpactToOtherTopicMutation,
  useUpdateFinancialEffectIROStateMutation,
  useUpdateFinancialEffectMutation,
  useUpdateMaterialImpactIROStateMutation,
  useUpdateMaterialImpactMutation,
} from "../mutations/dma.mutations";
import { useCallback, useMemo, useState } from "react";
import {
  EDIT_FINANCIAL_EFFECT_QUERY_PARAM,
  EDIT_MATERIAL_IMPACT_QUERY_PARAM,
} from "@netcero/netcero-common";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";
import { DMAUtilities } from "../utilities/dma.utilities";
import { useResetMutationsOnOpen } from "../../common/hooks/use-reset-mutations-on-open.hook";

interface IShowEffectOrImpactCreateDialogState {
  open: boolean;
  category: IDMACategoryWithEffectsAndChildren | null;
  esrsTopic: IESRSTopic | null;
}

export interface IShowEditOrDeleteDialogState<T> {
  open: boolean;
  category: IDMACategoryWithEffectsAndChildren | null;
  esrsTopic: IESRSTopic | null;
  effectOrImpact: T | null;
}

export const useIroDialogHandlers = (
  esrsTopics: IESRSTopic[],
  selectedESRSTopic: IESRSTopic | null,
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObject: IDataEntryObject,
) => {
  // Lookups
  const dmaCategoriesForIROIdsLookup = useMemo(
    () => DMAUtilities.generateDmaCategoryForIROIdLookupFromTopics(esrsTopics),
    [esrsTopics],
  );
  const { impactsLookup, effectsLookup } = useMemo(
    () => DMAUtilities.getIROsLookupForTopics(esrsTopics),
    [esrsTopics],
  );

  // Mutations
  const createMaterialImpactMutation = useCreateMaterialImpactMutation();
  const updateMaterialImpactMutation = useUpdateMaterialImpactMutation();
  const deleteMaterialImpactMutation = useDeleteMaterialImpactMutation();
  const updateMaterialImpactIROStateMutation = useUpdateMaterialImpactIROStateMutation();
  const moveMaterialImpactMutation = useMoveMaterialImpactToOtherTopicMutation();

  const createFinancialEffectMutation = useCreateFinancialEffectMutation();
  const updateFinancialEffectMutation = useUpdateFinancialEffectMutation();
  const deleteFinancialEffectMutation = useDeleteFinancialEffectMutation();
  const moveFinancialEffectMutation = useMoveFinancialEffectToOtherTopicMutation();
  const updateFinancialEffectIROStateMutation = useUpdateFinancialEffectIROStateMutation();

  // Combined Mutation Loading States
  const materialImpactCreateDialogLoading = useMemo(
    () => createMaterialImpactMutation.isPending,
    [createMaterialImpactMutation.isPending],
  );
  const financialEffectCreateDialogLoading = useMemo(
    () => createFinancialEffectMutation.isPending,
    [createFinancialEffectMutation.isPending],
  );

  const materialImpactEditDialogLoading = useMemo(
    () => updateMaterialImpactMutation.isPending || updateMaterialImpactIROStateMutation.isPending,
    [updateMaterialImpactIROStateMutation.isPending, updateMaterialImpactMutation.isPending],
  );
  const financialEffectEditDialogLoading = useMemo(
    () =>
      updateFinancialEffectMutation.isPending || updateFinancialEffectIROStateMutation.isPending,
    [updateFinancialEffectMutation.isPending, updateFinancialEffectIROStateMutation.isPending],
  );

  const materialImpactDeleteDialogLoading = useMemo(
    () => deleteMaterialImpactMutation.isPending,
    [deleteMaterialImpactMutation.isPending],
  );
  const financialEffectDeleteDialogLoading = useMemo(
    () => deleteFinancialEffectMutation.isPending,
    [deleteFinancialEffectMutation.isPending],
  );

  // Combined Mutation Error States
  const materialImpactCreateDialogError = useMemo(
    () => createMaterialImpactMutation.error,
    [createMaterialImpactMutation.error],
  );
  const financialEffectCreateDialogError = useMemo(
    () => createFinancialEffectMutation.error,
    [createFinancialEffectMutation.error],
  );

  const materialImpactEditDialogError = useMemo(
    () => updateMaterialImpactMutation.error ?? updateMaterialImpactIROStateMutation.error,
    [updateMaterialImpactMutation.error, updateMaterialImpactIROStateMutation.error],
  );
  const financialEffectEditDialogError = useMemo(
    () => updateFinancialEffectMutation.error ?? updateFinancialEffectIROStateMutation.error,
    [updateFinancialEffectMutation.error, updateFinancialEffectIROStateMutation.error],
  );

  const materialImpactDeleteDialogError = useMemo(
    () => deleteMaterialImpactMutation.error,
    [deleteMaterialImpactMutation.error],
  );
  const financialEffectDeleteDialogError = useMemo(
    () => deleteFinancialEffectMutation.error,
    [deleteFinancialEffectMutation.error],
  );

  // Query Param State Logic
  const {
    handleUpdateEditState: handleUpdateEditMaterialImpactState,
    showEditIroDialogState: showEditMaterialImpactDialogState,
  } = useIroEditDialogQueryParamState<IDMAMaterialImpact>(
    EDIT_MATERIAL_IMPACT_QUERY_PARAM,
    selectedESRSTopic,
    dmaCategoriesForIROIdsLookup,
    impactsLookup,
  );
  const {
    handleUpdateEditState: handleUpdateEditFinancialEffectState,
    showEditIroDialogState: showEditFinancialEffectDialogState,
  } = useIroEditDialogQueryParamState<IDMAFinancialEffect>(
    EDIT_FINANCIAL_EFFECT_QUERY_PARAM,
    selectedESRSTopic,
    dmaCategoriesForIROIdsLookup,
    effectsLookup,
  );

  // Dialog States

  // Material Impact Dialog States
  const [showCreateMaterialImpactDialogState, setShowCreateMaterialImpactDialogState] =
    useState<IShowEffectOrImpactCreateDialogState>({
      open: false,
      category: null,
      esrsTopic: null,
    });
  const {
    dialogState: moveMaterialImpactDialogState,
    handleOpenDialog: executeOpenMoveMaterialImpactDialog,
    handleCloseDialog: executeCloseMoveMaterialImpactDialog,
  } = useIroMoveDialogState(
    moveMaterialImpactMutation,
    useCallback(
      () => handleUpdateEditMaterialImpactState(null),
      [handleUpdateEditMaterialImpactState],
    ),
  );
  // TODO: It would probably make sense to also refactor to also use a custom hook like useIroMoveDialogState
  const [showDeleteMaterialImpactDialogState, setShowDeleteMaterialImpactDialogState] = useState<
    IShowEditOrDeleteDialogState<IDMAMaterialImpact>
  >({
    open: false,
    category: null,
    esrsTopic: null,
    effectOrImpact: null,
  });

  // Financial Effects Dialog States
  const [showCreateFinancialEffectDialogState, setShowCreateFinancialEffectDialogState] =
    useState<IShowEffectOrImpactCreateDialogState>({
      open: false,
      category: null,
      esrsTopic: null,
    });
  const {
    dialogState: moveFinancialEffectDialogState,
    handleOpenDialog: executeOpenMoveFinancialEffectDialog,
    handleCloseDialog: executeCloseMoveFinancialEffectDialog,
  } = useIroMoveDialogState(
    moveFinancialEffectMutation,
    useCallback(
      () => handleUpdateEditFinancialEffectState(null),
      [handleUpdateEditFinancialEffectState],
    ),
  );
  // TODO: It would probably make sense to also refactor to also use a custom hook like useIroMoveDialogState
  const [showDeleteFinancialEffectDialogState, setShowDeleteFinancialEffectDialogState] = useState<
    IShowEditOrDeleteDialogState<IDMAFinancialEffect>
  >({
    open: false,
    category: null,
    esrsTopic: null,
    effectOrImpact: null,
  });

  // Handlers

  // Create Handlers
  const handleCreateMaterialImpact = useCallback(
    (esrsTopic: IESRSTopic, category: IDMACategoryWithEffectsAndChildren) => {
      setShowCreateMaterialImpactDialogState({
        open: true,
        category,
        esrsTopic,
      });
    },
    [],
  );
  const handleCloseCreateMaterialImpactDialog = useCallback(
    async (data: IBaseDMAMaterialImpactData | null) => {
      if (data) {
        const newlyCreatedMaterialImpact = await createMaterialImpactMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId: dataEntryObject.id,
          esrsTopicId: showCreateMaterialImpactDialogState.esrsTopic!.id,
          dmaCategoryId: showCreateMaterialImpactDialogState.category!.id,
          payload: data,
        });
        handleUpdateMaterialImpact(newlyCreatedMaterialImpact);
      }
      setShowCreateMaterialImpactDialogState((state) => ({
        ...state,
        open: false,
      }));
    },
    [
      createMaterialImpactMutation.mutateAsync,
      dataEntryObject.id,
      organizationId,
      recordingPeriodId,
      showCreateMaterialImpactDialogState.category,
      showCreateMaterialImpactDialogState.esrsTopic,
    ],
  );

  const handleCreateFinancialEffect = useCallback(
    (esrsTopic: IESRSTopic, category: IDMACategoryWithEffectsAndChildren) => {
      setShowCreateFinancialEffectDialogState({
        open: true,
        category,
        esrsTopic,
      });
    },
    [],
  );
  const handleCloseCreateFinancialEffectDialog = useCallback(
    async (data: IBaseDMAFinancialEffectData | null) => {
      // Handle create data
      if (data) {
        const newlyCreatedFinancialEffect = await createFinancialEffectMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId: dataEntryObject.id,
          esrsTopicId: showCreateFinancialEffectDialogState.esrsTopic!.id,
          dmaCategoryId: showCreateFinancialEffectDialogState.category!.id,
          payload: data,
        });
        handleUpdateFinancialEffect(newlyCreatedFinancialEffect);
      }
      // Close Dialog
      setShowCreateFinancialEffectDialogState((state) => ({
        ...state,
        open: false,
      }));
    },
    [
      createFinancialEffectMutation.mutateAsync,
      dataEntryObject.id,
      organizationId,
      recordingPeriodId,
      showCreateFinancialEffectDialogState.category,
      showCreateFinancialEffectDialogState.esrsTopic,
    ],
  );

  // Update Handlers
  const handleUpdateMaterialImpact = useCallback(
    (impact: IDMAMaterialImpact) => {
      handleUpdateEditMaterialImpactState(impact);
    },
    [handleUpdateEditMaterialImpactState],
  );
  const handleCloseUpdateMaterialImpactDialog = useCallback(
    async (data: IBaseDMAMaterialImpactData | null) => {
      if (data) {
        await updateMaterialImpactMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId: dataEntryObject.id,
          esrsTopicId: showEditMaterialImpactDialogState.esrsTopic!.id,
          dmaCategoryId: showEditMaterialImpactDialogState.category!.id,
          materialImpactId: showEditMaterialImpactDialogState.effectOrImpact!.id,
          payload: data,
        });
      }
      // Close Dialog
      handleUpdateEditMaterialImpactState(null);
    },
    [
      dataEntryObject.id,
      handleUpdateEditMaterialImpactState,
      organizationId,
      recordingPeriodId,
      showEditMaterialImpactDialogState.category,
      showEditMaterialImpactDialogState.effectOrImpact,
      showEditMaterialImpactDialogState.esrsTopic,
      updateMaterialImpactMutation.mutateAsync,
    ],
  );

  const handleUpdateFinancialEffect = useCallback(
    (effect: IDMAFinancialEffect) => {
      handleUpdateEditFinancialEffectState(effect);
    },
    [handleUpdateEditFinancialEffectState],
  );
  const handleCloseUpdateFinancialEffectDialog = async (
    data: IBaseDMAFinancialEffectData | null,
  ) => {
    // Handle Update data
    if (data) {
      await updateFinancialEffectMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId: dataEntryObject.id,
        esrsTopicId: showEditFinancialEffectDialogState.esrsTopic!.id,
        dmaCategoryId: showEditFinancialEffectDialogState.category!.id,
        financialEffectId: showEditFinancialEffectDialogState.effectOrImpact!.id,
        payload: data,
      });
    }
    // Close Dialog
    handleUpdateEditFinancialEffectState(null);
  };

  // IRO State Handlers
  const handleUpdateMaterialImpactIROState = async (newState: IIROState) => {
    await updateMaterialImpactIROStateMutation.mutateAsync({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId: dataEntryObject.id,
      esrsTopicId: selectedESRSTopic?.id ?? "",
      dmaCategoryId: showEditMaterialImpactDialogState.category!.id,
      materialImpactId: showEditMaterialImpactDialogState.effectOrImpact!.id,
      iroState: newState,
    });
    handleUpdateEditMaterialImpactState(null);
  };

  const handleUpdateFinancialEffectIROState = async (newState: IIROState) => {
    await updateFinancialEffectIROStateMutation.mutateAsync({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId: dataEntryObject.id,
      esrsTopicId: selectedESRSTopic?.id ?? "",
      dmaCategoryId: showEditFinancialEffectDialogState.category!.id,
      financialEffectId: showEditFinancialEffectDialogState.effectOrImpact!.id,
      iroState: newState,
    });
    handleUpdateEditFinancialEffectState(null);
  };

  // Move Handlers
  const handleMoveMaterialImpact = useCallback(() => {
    executeOpenMoveMaterialImpactDialog(
      showEditMaterialImpactDialogState.category!,
      showEditMaterialImpactDialogState.effectOrImpact!,
    );
  }, [
    executeOpenMoveMaterialImpactDialog,
    showEditMaterialImpactDialogState.category,
    showEditMaterialImpactDialogState.effectOrImpact,
  ]);
  const handleCloseMoveMaterialImpactDialog = useCallback(
    async (targetDMACategoryId: string | null) => {
      await executeCloseMoveMaterialImpactDialog(
        targetDMACategoryId,
        {
          organizationId,
          recordingPeriodId,
          dataEntryObjectId: dataEntryObject.id,
          esrsTopicId: selectedESRSTopic!.id,
          dmaCategoryId: moveMaterialImpactDialogState.dmaCategory?.id ?? "",
          materialImpactId: moveMaterialImpactDialogState.effectOrImpact?.id ?? "",
          targetDMACategoryId: targetDMACategoryId ?? "",
        },
        BasicSnackbarApiActionType.MOVE_MATERIAL_IMPACT_TO_OTHER_TOPIC,
      );
    },
    [
      dataEntryObject.id,
      executeCloseMoveMaterialImpactDialog,
      moveMaterialImpactDialogState.dmaCategory?.id,
      moveMaterialImpactDialogState.effectOrImpact?.id,
      organizationId,
      recordingPeriodId,
      selectedESRSTopic,
    ],
  );

  const handleMoveFinancialEffect = useCallback(() => {
    executeOpenMoveFinancialEffectDialog(
      showEditFinancialEffectDialogState.category!,
      showEditFinancialEffectDialogState.effectOrImpact!,
    );
  }, [
    executeOpenMoveFinancialEffectDialog,
    showEditFinancialEffectDialogState.category,
    showEditFinancialEffectDialogState.effectOrImpact,
  ]);

  const handleCloseMoveFinancialEffectDialog = useCallback(
    async (targetDMACategoryId: string | null) => {
      await executeCloseMoveFinancialEffectDialog(
        targetDMACategoryId,
        {
          organizationId,
          recordingPeriodId,
          dataEntryObjectId: dataEntryObject.id,
          esrsTopicId: selectedESRSTopic!.id,
          dmaCategoryId: moveFinancialEffectDialogState.dmaCategory?.id ?? "",
          financialEffectId: moveFinancialEffectDialogState.effectOrImpact?.id ?? "",
          targetDMACategoryId: targetDMACategoryId ?? "",
        },
        BasicSnackbarApiActionType.MOVE_FINANCIAL_EFFECT_TO_OTHER_TOPIC,
      );
    },
    [
      dataEntryObject.id,
      executeCloseMoveFinancialEffectDialog,
      moveFinancialEffectDialogState.dmaCategory?.id,
      moveFinancialEffectDialogState.effectOrImpact?.id,
      organizationId,
      recordingPeriodId,
      selectedESRSTopic,
    ],
  );

  // Delete Handlers
  const handleDeleteMaterialImpact = () => {
    setShowDeleteMaterialImpactDialogState({
      open: true,
      category: showEditMaterialImpactDialogState.category,
      esrsTopic: showEditMaterialImpactDialogState.esrsTopic,
      effectOrImpact: showEditMaterialImpactDialogState.effectOrImpact,
    });
  };
  const handleCloseDeleteMaterialImpactDialog = async (confirm: boolean) => {
    if (confirm) {
      await deleteMaterialImpactMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId: dataEntryObject.id,
        esrsTopicId: showDeleteMaterialImpactDialogState.esrsTopic!.id,
        dmaCategoryId: showDeleteMaterialImpactDialogState.category!.id,
        materialImpactId: showDeleteMaterialImpactDialogState.effectOrImpact!.id,
      });

      // Close Edit Dialog after delete
      handleUpdateEditMaterialImpactState(null);
    }
    // Close Dialogs
    setShowDeleteMaterialImpactDialogState((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleDeleteFinancialEffect = useCallback(() => {
    setShowDeleteFinancialEffectDialogState({
      open: true,
      category: showEditFinancialEffectDialogState.category,
      esrsTopic: showEditFinancialEffectDialogState.esrsTopic,
      effectOrImpact: showEditFinancialEffectDialogState.effectOrImpact,
    });
  }, [
    showEditFinancialEffectDialogState.category,
    showEditFinancialEffectDialogState.effectOrImpact,
    showEditFinancialEffectDialogState.esrsTopic,
  ]);
  const handleCloseDeleteFinancialEffectDialog = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        await deleteFinancialEffectMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId: dataEntryObject.id,
          esrsTopicId: showDeleteFinancialEffectDialogState.esrsTopic!.id,
          dmaCategoryId: showDeleteFinancialEffectDialogState.category!.id,
          financialEffectId: showDeleteFinancialEffectDialogState.effectOrImpact!.id,
        });
        // Close Edit Dialog after delete
        handleUpdateEditFinancialEffectState(null);
      }
      // Close Dialog
      setShowDeleteFinancialEffectDialogState((state) => ({
        ...state,
        open: false,
      }));
    },
    [
      dataEntryObject.id,
      deleteFinancialEffectMutation.mutateAsync,
      handleUpdateEditFinancialEffectState,
      organizationId,
      recordingPeriodId,
      showDeleteFinancialEffectDialogState.category,
      showDeleteFinancialEffectDialogState.effectOrImpact,
      showDeleteFinancialEffectDialogState.esrsTopic,
    ],
  );

  // Reset Mutations on dialog open
  useResetMutationsOnOpen(showCreateMaterialImpactDialogState.open, createMaterialImpactMutation);
  useResetMutationsOnOpen(
    showEditMaterialImpactDialogState.open,
    updateMaterialImpactMutation,
    updateMaterialImpactIROStateMutation,
  );
  useResetMutationsOnOpen(showDeleteMaterialImpactDialogState.open, deleteMaterialImpactMutation);
  useResetMutationsOnOpen(moveMaterialImpactDialogState.open, moveMaterialImpactMutation);

  useResetMutationsOnOpen(showCreateFinancialEffectDialogState.open, createFinancialEffectMutation);
  useResetMutationsOnOpen(
    showEditFinancialEffectDialogState.open,
    updateFinancialEffectMutation,
    updateFinancialEffectIROStateMutation,
  );
  useResetMutationsOnOpen(showDeleteFinancialEffectDialogState.open, deleteFinancialEffectMutation);
  useResetMutationsOnOpen(moveFinancialEffectDialogState.open, moveFinancialEffectMutation);

  return {
    states: {
      // Create Dialog States
      showCreateMaterialImpactDialogState,
      showCreateFinancialEffectDialogState,
      // Edit Dialog States
      showEditMaterialImpactDialogState,
      showEditFinancialEffectDialogState,
      // Loading States
      materialImpactCreateDialogLoading,
      financialEffectCreateDialogLoading,
      materialImpactEditDialogLoading,
      financialEffectEditDialogLoading,
      materialImpactDeleteDialogLoading,
      financialEffectDeleteDialogLoading,
      // Error States
      materialImpactCreateDialogError,
      financialEffectCreateDialogError,
      materialImpactEditDialogError,
      financialEffectEditDialogError,
      materialImpactDeleteDialogError,
      financialEffectDeleteDialogError,
      // Delete Dialog States
      showDeleteMaterialImpactDialogState,
      showDeleteFinancialEffectDialogState,
      // Move Dialog States
      moveMaterialImpactDialogState,
      moveFinancialEffectDialogState,
    },
    createHandlers: {
      // Create Dialog Handlers
      handleCloseCreateMaterialImpactDialog,
      handleCloseCreateFinancialEffectDialog,
      // Create Handlers
      handleCreateMaterialImpact,
      handleCreateFinancialEffect,
    },
    updateHandlers: {
      // Update Dialog Handlers
      handleCloseUpdateMaterialImpactDialog,
      handleCloseUpdateFinancialEffectDialog,
      // Update Handlers
      handleUpdateMaterialImpact,
      handleUpdateFinancialEffect,
      // Update IRO State Handlers
      handleUpdateMaterialImpactIROState,
      handleUpdateFinancialEffectIROState,
    },
    moveHandlers: {
      // Move Dialog Handlers
      executeOpenMoveMaterialImpactDialog,
      executeOpenMoveFinancialEffectDialog,
      executeCloseMoveMaterialImpactDialog,
      executeCloseMoveFinancialEffectDialog,
      // Move Handlers
      handleMoveMaterialImpact,
      handleMoveFinancialEffect,
      handleCloseMoveMaterialImpactDialog,
      handleCloseMoveFinancialEffectDialog,
    },
    deleteHandlers: {
      // Delete Dialog Handlers
      handleCloseDeleteMaterialImpactDialog,
      handleCloseDeleteFinancialEffectDialog,
      // Delete Handlers
      handleDeleteMaterialImpact,
      handleDeleteFinancialEffect,
    },
  };
};
