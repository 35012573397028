import { Box, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { IDataEntryObject, IInputParameter } from "@netcero/netcero-core-api-client";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TreeGroupContainer } from "../common/components/tree-group-container.component";
import { ChevronDownIcon } from "../common/constants/tabler-icon.constants";
import { IHydratedInputParametersModelStructureTreeThgGroup } from "../input-parameter-recording-structures/thg/input-parameter-recording-thg-structures.interfaces";
import { ILocalRecordingPeriod } from "../recording-periods/recording-periods.utilities";
import { DataEntryObjectValuesGroupInputParameterComponent } from "./data-entry-object-values-group-input-parameter.component";
import {
  ILocalDataEntryObjectInputParameter,
  ILocalDataEntryObjectInputParameterValueData,
} from "./interfaces/local-data-entry-object-values.interfaces";
import { useTranslateContent } from "../content-translation/hooks/translate-content.hook";
import { IDependencyMap } from "./utilities/data-entry-object-values.utilities";

interface IDataEntryObjectValuesTHGGroupComponentProps {
  // Data required to render component
  group: IHydratedInputParametersModelStructureTreeThgGroup;
  recordingPeriod: ILocalRecordingPeriod;
  organizationId: string;
  dataEntryObject: IDataEntryObject;
  rootDataEntryObjectId: string;
  // UI customization
  disabled?: boolean;
  initialExpanded?: boolean;
  prefix?: string;
  level?: number;
  // Event handlers
  onCreateValueEntry: (
    inputParameter: IInputParameter,
    value: ILocalDataEntryObjectInputParameterValueData,
  ) => Promise<void>;
  onUpdateValueEntry: (
    inputParameter: IInputParameter,
    valueId: string,
    value: ILocalDataEntryObjectInputParameterValueData,
  ) => Promise<void>;
  onAssignedUsersChange: (
    inputParameter: ILocalDataEntryObjectInputParameter,
    assignedUsers: string[],
  ) => void;
  onDeleteValueEntry: (inputParameter: IInputParameter, valueId: string) => Promise<void>;
  dependencyMap: IDependencyMap;
}

export const DataEntryObjectTHGValuesGroupComponent: FC<
  IDataEntryObjectValuesTHGGroupComponentProps
> = ({
  group,
  recordingPeriod,
  rootDataEntryObjectId,
  disabled,
  initialExpanded,
  prefix = "",
  level = 0,
  onCreateValueEntry,
  onUpdateValueEntry,
  onDeleteValueEntry,
  dataEntryObject,
  organizationId,
  onAssignedUsersChange,
  dependencyMap,
}) => {
  const { t } = useTranslation("data_entry_object_values_group");
  const translateContent = useTranslateContent();

  const { recordingGroupInfo, inputParameters, children } = group;

  const [expanded, setExpanded] = useState(initialExpanded || false);

  const handleClickCategory = () => {
    setExpanded((curr) => !curr);
  };

  const ChildrenContainer = useMemo(() => {
    return level === 0 ? Box : TreeGroupContainer;
  }, [level]);

  const headerVariant = useMemo(() => {
    switch (level) {
      case 0:
        return "h2";
      case 1:
        return "h3";
      case 2:
        return "h4";
      default:
        return "h5";
    }
  }, [level]);

  const filteredChildren = useMemo(() => {
    return children.filter((child) => {
      const shouldRender = child.inputParameters.some(
        (inputParam) => dependencyMap[inputParam.inputParameter.id]?.shouldRender,
      );
      const showAlert = child.inputParameters.some(
        (inputParam) => dependencyMap[inputParam.inputParameter.id]?.showAlert,
      );
      return shouldRender || showAlert;
    });
  }, [children, dependencyMap]);

  return (
    <Box>
      {/* Only display name of recording group level 1 and above */}
      {level > 0 ? (
        <>
          <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
            <Typography variant={headerVariant} onClick={handleClickCategory} flex={1}>
              {prefix} {translateContent(recordingGroupInfo.name)}
            </Typography>
            <IconButton
              size="medium"
              sx={{ rotate: expanded ? "0" : "180deg", transition: "0.3s ease" }}
              onClick={handleClickCategory}
            >
              <ChevronDownIcon />
            </IconButton>
          </Box>
          <Divider />
        </>
      ) : (
        <Box mb={2} ml={1}>
          <Typography variant={headerVariant} component="span" flex={1}>
            {translateContent(recordingGroupInfo.name)}
          </Typography>
        </Box>
      )}

      <Collapse in={expanded}>
        <ChildrenContainer py={level > 0 ? 4 : undefined}>
          {/* Empty Message */}
          {inputParameters.length === 0 && children.length === 0 && (
            <Typography variant="body1">{t("category_empty")}</Typography>
          )}

          {/* Input Parameters */}
          {inputParameters.length > 0 && (
            <Box pl={1}>
              {inputParameters.map(
                (inputParameter) =>
                  (dependencyMap[inputParameter.inputParameter.id].shouldRender ||
                    dependencyMap[inputParameter.inputParameter.id].showAlert) && (
                    <DataEntryObjectValuesGroupInputParameterComponent
                      key={inputParameter.inputParameter.id}
                      disabled={disabled}
                      organizationId={organizationId}
                      rootDataEntryObjectId={rootDataEntryObjectId}
                      dataEntryObject={dataEntryObject}
                      dataEntryObjectInputParameter={inputParameter}
                      recordingPeriod={recordingPeriod}
                      onCreate={(value) => onCreateValueEntry(inputParameter.inputParameter, value)}
                      onUpdate={(id, value) =>
                        onUpdateValueEntry(inputParameter.inputParameter, id, value)
                      }
                      onDelete={(id) => onDeleteValueEntry(inputParameter.inputParameter, id)}
                      onAssignedUsersChange={onAssignedUsersChange}
                      inputParameterId={inputParameter.inputParameter.id}
                      dependencyMap={dependencyMap}
                    />
                  ),
              )}
            </Box>
          )}

          {/* Children */}
          {filteredChildren.length > 0 && (
            <Box pl={1} display="flex" flexDirection="column" gap={2}>
              {filteredChildren.map((child, index) => {
                return (
                  <Box
                    key={index} // Index is fine here, because the order of the children is fixed
                    minHeight={
                      level === 0 && index === filteredChildren.length - 1
                        ? "calc(100vh - 32px)"
                        : undefined
                    }
                  >
                    <DataEntryObjectTHGValuesGroupComponent
                      group={child}
                      recordingPeriod={recordingPeriod}
                      rootDataEntryObjectId={rootDataEntryObjectId}
                      disabled={disabled}
                      prefix={prefix && `${prefix ? `${prefix}.` : ""}${index + 1}`}
                      initialExpanded={initialExpanded}
                      onCreateValueEntry={onCreateValueEntry}
                      onUpdateValueEntry={onUpdateValueEntry}
                      onDeleteValueEntry={onDeleteValueEntry}
                      level={level + 1}
                      dataEntryObject={dataEntryObject}
                      organizationId={organizationId}
                      onAssignedUsersChange={onAssignedUsersChange}
                      dependencyMap={dependencyMap}
                    />
                  </Box>
                );
              })}
            </Box>
          )}
        </ChildrenContainer>
      </Collapse>
    </Box>
  );
};
