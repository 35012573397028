import { Box, FormControl, FormHelperText, InputLabel, Select, Skeleton } from "@mui/material";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import React, { FC, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { useSortedOrganizationUsersHook } from "../hooks/sorted-organization-users.hook";
import { UserAvatar } from "./user-avatar.component";
import { NoUserAvatar } from "./no-user-selected.avatar.component";
import { HOVER_BACKGROUND_COLOR } from "../../../theme/theme";
import { UserSelectMenu } from "./user-select-menu.component";
import { useOpenStateWithForwardClose } from "../../common/hooks/open-state-with-forward-close.hook";
import { UserUtilities } from "@netcero/netcero-common";

const ValueBoxSx = {
  p: 1,
  borderRadius: 2,
  ":hover": { backgroundColor: HOVER_BACKGROUND_COLOR },
};

interface IOrganizationUserPickerProps {
  organizationId: string;
  value: string | null;
  includeUserIds?: string[];
  excludeUserIds?: string[];
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (userData: IUserRepresentation | null) => void;
}

export const OrganizationUserPicker: FC<IOrganizationUserPickerProps> = ({
  organizationId,
  value,
  label,
  placeholder,
  includeUserIds,
  excludeUserIds,
  helperText,
  error,
  disabled,
  readOnly,
  onChange,
}) => {
  const { t } = useTranslation("user_picker_components");

  const [sortedUsers, users] = useSortedOrganizationUsersHook(organizationId);
  const selectRef = useRef<HTMLDivElement>(null);
  const {
    showMenu: usersMenuOpen,
    setShowMenu: setShowUsersMenu,
    forwardWithCloseMenu: forwardWithCloseUsersMenu,
  } = useOpenStateWithForwardClose();

  const shownUsers = useMemo(() => {
    if (excludeUserIds) {
      return sortedUsers?.filter((user) => !excludeUserIds?.includes(user.id!));
    }
    if (includeUserIds) {
      return sortedUsers?.filter((user) => includeUserIds?.includes(user.id!));
    }

    return sortedUsers;
  }, [sortedUsers, excludeUserIds, includeUserIds]);

  if (users.isLoading) {
    return <Skeleton variant="rounded" width={240} height={56} />;
  }

  if (users.isError) {
    return <ErrorTextComponent error={users.error} />;
  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled || readOnly) {
      return;
    }
    setShowUsersMenu(true);
  };

  return (
    <>
      <UserSelectMenu
        show={usersMenuOpen}
        anchorEl={selectRef.current}
        onClose={() => setShowUsersMenu(false)}
        users={shownUsers}
        activeUserId={value}
        noUsersAvailableText={t("no_users_available")}
        noUserOption={true}
        noUserOptionText={t("no_user_selected")}
        onSelect={forwardWithCloseUsersMenu(onChange)}
      />

      <FormControl error={error}>
        {label && <InputLabel id="user-picker-select-label">{label}</InputLabel>}
        <Select
          ref={selectRef}
          variant="standard"
          disableUnderline
          value={value ?? ""}
          label={label}
          placeholder={placeholder}
          readOnly={readOnly}
          displayEmpty
          IconComponent={() => null}
          sx={{ "> .MuiSelect-select": ValueBoxSx }}
          onClick={handleOpenMenu}
          open={false} // Prevents the select from opening its own menu, because we're using a custom menu
          renderValue={(userId) => {
            if (!userId) {
              return (
                <Box py={0.5}>
                  <NoUserSelectedContent diameter={20} />
                </Box>
              );
            }

            const selectedUser = users.data?.find((user) => user.id === userId);

            if (!selectedUser) {
              return <Box>{t("selected_user_not_found")}</Box>;
            }

            return (
              <Box display="flex" alignItems="center" gap={1}>
                <UserAvatar user={selectedUser} disabledOverlay />
                {UserUtilities.formatUserFullName(selectedUser)}
              </Box>
            );
          }}
          error={error}
          disabled={disabled}
        />
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

interface INoUserSelectedContentProps {
  diameter: number;
}

const NoUserSelectedContent: FC<INoUserSelectedContentProps> = ({ diameter }) => {
  const { t } = useTranslation("user_picker_components");
  return (
    <Box display="flex" alignItems="center" gap={1} height={diameter}>
      <NoUserAvatar avatarDiameter={diameter} />
      {t("no_user_selected")}
    </Box>
  );
};
