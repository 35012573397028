import { IBaseSourceData, ISourceVisibilityData } from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { getSourcesQueryKey } from "./sources.queries";
import { IOrganizationIdentity, ISourceIdentity } from "@netcero/netcero-common";

interface ISourceData extends IOrganizationIdentity {
  payload: IBaseSourceData;
}

export interface ISourceEditData extends ISourceIdentity {
  payload: IBaseSourceData;
}

export interface ISourceUpdateVisibilityData extends ISourceIdentity {
  payload: ISourceVisibilityData;
}

export const useCreateSourceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, payload }: ISourceData) => {
      return CoreApiService.SourcesApi.createSource(organizationId, payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getSourcesQueryKey(variables.organizationId),
      }),
  });
};

export const useEditSourceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, sourceId, payload }: ISourceEditData) => {
      return CoreApiService.SourcesApi.updateSource(organizationId, sourceId, payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getSourcesQueryKey(variables.organizationId),
      }),
  });
};

export const useDeleteSourceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, sourceId }: ISourceIdentity) => {
      return CoreApiService.SourcesApi.deleteSource(organizationId, sourceId)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getSourcesQueryKey(variables.organizationId),
      }),
  });
};

export const useUpdateSourceVisibilityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, sourceId, payload }: ISourceUpdateVisibilityData) => {
      return CoreApiService.SourcesApi.updateSourceVisibility(organizationId, sourceId, payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getSourcesQueryKey(variables.organizationId),
      }),
  });
};
