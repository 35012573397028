import { useMemo } from "react";
import { IDMACategoryWithEffectsAndChildren, IESRSTopic } from "@netcero/netcero-core-api-client";

interface IStep {
  topicId: string | null;
  categoryId: string | null;
}

export interface IUseStepperNavigationProps {
  esrsTopics: IESRSTopic[];
  selectedESRSTopic: IESRSTopic | null;
  selectedDMACategory: IDMACategoryWithEffectsAndChildren | null;
  onChangeViewSettings: (topicId: string | null, categoryId: string | null) => void;
}

export const useStepperNavigation = ({
  esrsTopics,
  selectedESRSTopic,
  selectedDMACategory,
  onChangeViewSettings,
}: IUseStepperNavigationProps) => {
  const stepperIndexesLookup: IStep[] = useMemo(() => {
    return [
      { topicId: null, categoryId: null },
      ...esrsTopics
        .map((topic) => {
          if (topic.recordedESRSTopic && topic.recordedESRSTopic.dmaCategories.length > 0) {
            return topic.recordedESRSTopic.dmaCategories.map((category) => ({
              topicId: topic.id,
              categoryId: category.id,
            }));
          }
          return [{ topicId: topic.id, categoryId: null }];
        })
        .flat(),
    ];
  }, [esrsTopics]);

  const selectedESRSTopicIndex = useMemo(() => {
    const topicId = selectedESRSTopic?.id ?? null;
    const categoryId = selectedDMACategory?.id ?? null;
    return stepperIndexesLookup.findIndex(
      (lookup) => lookup.topicId === topicId && lookup.categoryId === categoryId,
    );
  }, [stepperIndexesLookup, selectedESRSTopic, selectedDMACategory]);

  const handleChangeStep = (change: number) => {
    const lookup = stepperIndexesLookup[selectedESRSTopicIndex + change];
    if (lookup) {
      onChangeViewSettings(lookup.topicId, lookup.categoryId);
    }
  };

  return {
    handleChangeStep,
    selectedESRSTopicIndex,
    stepperIndexesLookup,
  };
};
