import {
  IDMAEffectAreaType,
  IDMAFinancialEffectType,
  IFinancialEffectCategoryEnum,
  IFinancialEffectEffectTypeEnum,
  IIROProbabilityPredefinedValue,
  IIROState,
} from "@netcero/netcero-core-api-client";
import { DMASliderValues } from "@netcero/netcero-dma";
import { PointFilledIcon, PointIcon } from "../../common/constants/tabler-icon.constants";
import { FC } from "react";

// Areas

export const ORDERED_EFFECT_AND_IMPACT_AREAS = [
  IDMAEffectAreaType.UpstreamValueChain,
  IDMAEffectAreaType.DownstreamValueChain,
];

// Effect Types

export const ORDERED_EFFECT_TYPES = [
  IFinancialEffectEffectTypeEnum.InvestmentCosts,
  IFinancialEffectEffectTypeEnum.OperationsCosts,
  IFinancialEffectEffectTypeEnum.CapitalCosts,
  IFinancialEffectEffectTypeEnum.AssetsValueChange,
  IFinancialEffectEffectTypeEnum.Revenue,
  IFinancialEffectEffectTypeEnum.AccessToCapital,
];

// Slider Values

export type IDMASliderValueName = "very_low" | "low" | "average" | "high" | "very_high";

export const ORDERED_DMA_SLIDER_VALUES = [
  DMASliderValues.VeryLow,
  DMASliderValues.RatherLow,
  DMASliderValues.Average,
  DMASliderValues.RatherHigh,
  DMASliderValues.VeryHigh,
];
export const DEFAULT_DMA_SLIDER_VALUE = DMASliderValues.VeryLow;

export const DMA_SLIDER_VALUES_NAMES: { [key in DMASliderValues]: IDMASliderValueName } = {
  [DMASliderValues.VeryLow]: "very_low",
  [DMASliderValues.RatherLow]: "low",
  [DMASliderValues.Average]: "average",
  [DMASliderValues.RatherHigh]: "high",
  [DMASliderValues.VeryHigh]: "very_high",
};

// Probability Slider Values

export const ORDERED_DMA_PROBABILITY_SLIDER_VALUES = [
  IIROProbabilityPredefinedValue.VeryLow,
  IIROProbabilityPredefinedValue.Low,
  IIROProbabilityPredefinedValue.Average,
  IIROProbabilityPredefinedValue.High,
  IIROProbabilityPredefinedValue.VeryHigh,
];

export const DEFAULT_DMA_PROBABILITY_SLIDER_VALUE = IIROProbabilityPredefinedValue.VeryLow;

// IRO States

export const DMA_IRO_STATE_COLORS: { [key in IIROState]: "success" | "warning" } = {
  [IIROState.Draft]: "warning",
  [IIROState.Shared]: "success",
};

export const DMA_IRO_STATE_ICONS: { [key in IIROState]: FC } = {
  [IIROState.Draft]: PointIcon,
  [IIROState.Shared]: PointFilledIcon,
};

// Financial Effect Types

export const ORDERED_FINANCIAL_EFFECT_TYPES = [
  IDMAFinancialEffectType.Chance,
  IDMAFinancialEffectType.Risk,
];

// Financial Effect Categories

export const ORDERED_FINANCIAL_EFFECT_CATEGORIES = [
  IFinancialEffectCategoryEnum.AcutePhysical,
  IFinancialEffectCategoryEnum.ChronicPhysical,
  IFinancialEffectCategoryEnum.Market,
  IFinancialEffectCategoryEnum.Technology,
  IFinancialEffectCategoryEnum.PolicyAndLegal,
  IFinancialEffectCategoryEnum.Reputation,
  IFinancialEffectCategoryEnum.FinancialIncentives,
  IFinancialEffectCategoryEnum.ProductsServices,
  IFinancialEffectCategoryEnum.ResourceEfficiency,
  IFinancialEffectCategoryEnum.Resilience,
  IFinancialEffectCategoryEnum.Others,
] as const;
