import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ILocalDataEntryObjectInputParameterValueData } from "../interfaces/local-data-entry-object-values.interfaces";
import {
  IDataEntryObjectInputParameterValueValueForKey,
  IDEOIPValueDataQuality,
  IInputParameter,
  IInputParameterRecordingMetaDataRecordingIntervalEnum,
} from "@netcero/netcero-core-api-client";
import {
  DataEntryObjectInputParameterValuesVerification,
  deepEqual,
} from "@netcero/netcero-common";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import {
  DataEntryObjectValuesUtilities,
  IValuesErrorsPerKey,
} from "../utilities/data-entry-object-values.utilities";

// TODO: Remove when sure not needed anymore
// export type ValueDateRangeSetter = (date: Date | null, change: "start" | "end") => void;
// export type ValueDateRangeErrorSetter = (error: string | null, change: "start" | "end") => void;

export interface IManageDataEntryObjectValuesErrorsData {
  dateError: {
    errorStart: string | null;
    errorEnd: string | null;
  };
  errors: IValuesErrorsPerKey;
  hasError: boolean;
}

function cleanUpValueData(
  data: ILocalDataEntryObjectInputParameterValueData,
): ILocalDataEntryObjectInputParameterValueData {
  return {
    enteredForDateStart: data.enteredForDateStart,
    enteredForDateEnd: data.enteredForDateEnd,
    note: data.note?.trim() || undefined,
    dataQuality: data.dataQuality || null,
    sourceIds: data.sourceIds,
    // sanitization is taken care of by the input component
    valuesPerKey: data.valuesPerKey,
  };
}

interface IManageDataEntryObjectValueProperties {
  recordedValue: ILocalDataEntryObjectInputParameterValueData;
  inputParameter: IInputParameter;
  recordingPeriod: ILocalRecordingPeriod;
  subscribeToChanges?: boolean;
  onSave?: (value: ILocalDataEntryObjectInputParameterValueData) => void;
}

export const useManageDataEntryObjectValue = ({
  recordedValue,
  recordingPeriod,
  subscribeToChanges,
  inputParameter,
  onSave,
}: IManageDataEntryObjectValueProperties) => {
  // State for managing the value
  const [value, setValue] = useState(recordedValue);
  const [initialValue, setInitialValue] = useState(recordedValue);
  const [isDirty, setIsDirty] = useState(false);

  const dataQualityButtonRef = useRef<HTMLButtonElement>(null);
  const [showDataQualityPopup, setShowDataQualityPopup] = useState(false);

  const [errorsPerKey, setErrorsPerKey] = useState<IValuesErrorsPerKey>({});

  const [dateError, setDateError] = useState<{
    errorStart: string | null;
    errorEnd: string | null;
  }>({
    errorStart: null,
    errorEnd: null,
  });

  // Value verification
  const verifyValue = useCallback(() => {
    const sanitizedValues = DataEntryObjectInputParameterValuesVerification.sanitizeValues(
      value.valuesPerKey,
      inputParameter.values,
    );
    const newErrors = DataEntryObjectValuesUtilities.getErrorsForValues(
      inputParameter,
      sanitizedValues,
    );
    setErrorsPerKey(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [inputParameter, value.valuesPerKey, setErrorsPerKey]);

  // Event handlers

  const handleInputChanged = (
    key: string,
    newValue: IDataEntryObjectInputParameterValueValueForKey,
  ) => {
    setValue((prev) => ({ ...prev, valuesPerKey: { ...prev.valuesPerKey, [key]: newValue } }));
    setIsDirty(true);
  };

  const handleSave = () => {
    if (verifyValue() && onSave) {
      const result = cleanUpValueData(value);

      // Emit Value and update
      onSave(result);

      setValue(result);
      setIsDirty(false);
    }
  };

  const handleReset = () => {
    setValue(initialValue);
    setErrorsPerKey({});
    setIsDirty(false);
  };

  const handleAddDate = () => {
    setValue((prev) => ({
      ...prev,
      // TODO: maybe restore old behavior of selecting date
      enteredForDateStart: recordingPeriod.startDate,
      enteredForDateEnd: recordingPeriod.endDate,
    }));
  };

  const handleRemoveDate = () => {
    setValue((prev) => ({ ...prev, enteredForDateStart: null, enteredForDateEnd: null }));
  };

  const handleSetDate = (date: Date | null, change: "start" | "end") => {
    setValue((prev) => {
      const existing = { ...prev };

      if (change === "start") {
        existing.enteredForDateStart = date;
      } else {
        existing.enteredForDateEnd = date;
      }

      return existing;
    });
  };

  const handleSetDateError = (error: string | null, change: "start" | "end") => {
    setDateError((prev) => {
      const existing = { ...prev };

      if (change === "start") {
        existing.errorStart = error;
      } else {
        existing.errorEnd = error;
      }

      return existing;
    });
  };

  const handleNoteChanged = (newNote: string | undefined) => {
    setValue((prev) => ({ ...prev, note: newNote }));
  };

  // Data Quality
  const handleChangeDataQuality = (dataQuality: IDEOIPValueDataQuality | null) => {
    setValue((prev) => ({ ...prev, dataQuality }));
    setShowDataQualityPopup(false);
  };

  const toggleDataQualityPopup = () => {
    setShowDataQualityPopup((prev) => !prev);
  };

  // Computed State

  const showDataQuality = useMemo(
    () => inputParameter.hasDataQuality,
    [inputParameter.hasDataQuality],
  );

  const showDate = useMemo(() => {
    return (
      inputParameter.recordingMetadata.recordingInterval ===
      IInputParameterRecordingMetaDataRecordingIntervalEnum.Unspecified
    );
  }, [inputParameter.recordingMetadata.recordingInterval]);

  const hasError = useMemo(
    () =>
      !(
        Object.keys(errorsPerKey).length === 0 &&
        dateError.errorStart === null &&
        dateError.errorEnd === null
      ),
    [dateError.errorEnd, dateError.errorStart, errorsPerKey],
  );

  const hasChanges = useMemo(() => {
    const cleanData = cleanUpValueData(value);

    return !(
      cleanData.enteredForDateStart?.getTime() === initialValue.enteredForDateStart?.getTime() &&
      cleanData.enteredForDateEnd?.getTime() === initialValue.enteredForDateEnd?.getTime() &&
      deepEqual(cleanData.valuesPerKey, initialValue.valuesPerKey) &&
      cleanData.note === initialValue.note &&
      cleanData.dataQuality === initialValue.dataQuality &&
      deepEqual(cleanData.sourceIds, initialValue.sourceIds)
    );
  }, [value, initialValue]);

  // Change listeners
  // Update on parent changes
  useEffect(() => {
    setInitialValue(recordedValue);
    if (subscribeToChanges) {
      setErrorsPerKey({});
      setIsDirty(false);
      setValue(recordedValue);
    }
  }, [recordedValue, subscribeToChanges]);

  // Validate on Value change
  useEffect(() => {
    verifyValue();
  }, [value, verifyValue]);

  const errors: IManageDataEntryObjectValuesErrorsData = {
    dateError,
    errors: errorsPerKey,
    hasError,
  };

  return {
    hasChanges,
    value,
    isDirty,
    errors,
    show: {
      dataQuality: showDataQuality,
      date: showDate,
      dataQualityPopup: showDataQualityPopup,
    },
    ref: {
      dataQualityButtonRef,
    },
    handlers: {
      handleReset,
      handleSave,
      handleInputChanged,
      handleNoteChanged,
      date: {
        handleAddDate,
        handleSetDate,
        handleRemoveDate,
        handleSetDateError,
      },
      dataQuality: {
        handleChangeDataQuality,
        toggleDataQualityPopup,
      },
    },
  };
};
