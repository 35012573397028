import { FieldValues } from "react-hook-form";
import { IMDRCurrencyInput } from "../../mdr-input.types";
import { useCallback, useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { MaskedInput } from "../../../common/components/masked-input.component";
import { IMDRControlledInputProps, NON_FULL_WIDTH_WIDTH } from "./mdr-input-component.factory";

function convertValue(value: number | undefined | null) {
  return value ? (value / 100).toString() : "";
}

export function CurrencyInput<T extends FieldValues>({
  valueMetaData,
  label,
  isConditional,
  field,
  fieldState: { error },
  disabled,
}: IMDRControlledInputProps<T, IMDRCurrencyInput>) {
  const [inputValue, setInputValue] = useState<string>(convertValue(field.value));
  useEffect(() => setInputValue(convertValue(field.value)), [field.value]);

  const handleValueChange = useCallback(
    (newValue: string) => {
      const numericValue = +newValue.replace(",", ".");
      if (numericValue > Number.MAX_SAFE_INTEGER || numericValue < Number.MIN_SAFE_INTEGER) {
        field.onChange(field.value);
        return;
      }
      setInputValue(newValue);
      field.onChange(newValue ? +(numericValue * 100).toFixed() : null);
    },
    [field],
  );

  return (
    <FormControl
      error={!!error}
      fullWidth
      sx={{
        maxWidth: NON_FULL_WIDTH_WIDTH,
        pl: isConditional ? 6 : 0,
      }}
      required={valueMetaData.required}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <OutlinedInput
        required={valueMetaData.required}
        value={inputValue}
        disabled={disabled}
        error={!!error}
        onChange={(evt) => handleValueChange(evt as unknown as string)}
        inputProps={{
          mask: Number,
          scale: 2,
          thousandsSeparator: ".",
          padFractionalZeros: false,
          radix: ",",
          unmask: true,
          eager: true,
        }}
        placeholder={valueMetaData.placeholder}
        size={valueMetaData.size}
        endAdornment={<InputAdornment position="end">&euro;</InputAdornment>}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent={MaskedInput as any}
        label={label}
      />
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
}
