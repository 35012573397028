import { FC, PropsWithChildren, ReactNode } from "react";
import { useDoesUserHaveRole } from "../hooks/does-user-have-role.hook";
import { OrganizationRole } from "@netcero/netcero-common";

interface IOnlyShowToUsersWithRoleProps extends PropsWithChildren {
  roles: OrganizationRole[];
  placeholder?: ReactNode;
}

export const OnlyShowToUsersWithRole: FC<IOnlyShowToUsersWithRoleProps> = ({
  roles,
  placeholder,
  children,
}) => {
  const showToUser = useDoesUserHaveRole(roles);
  return showToUser ? children : placeholder;
};
