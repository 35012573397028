import * as React from "react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  GhgCategoriesUtilities,
  IDataEntryObjectConsolidationResult,
  IGhgActivityIdentity,
  IRecordingPeriodGhgCategoryIdentity,
} from "@netcero/netcero-common";
import { IActivity, IDataEntryObject } from "@netcero/netcero-core-api-client";
import {
  Alert,
  Box,
  Dialog,
  Divider,
  IconButton,
  LinearProgress,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import { ActivityDetailsDialogBreadcrumbsComponent } from "../components/activity-details-dialog-breadcrumbs.component";
import { useTranslation } from "react-i18next";
import {
  ActivityDetailsDialogEditTabContent,
  IActivityDetailsDialogEditTabOnSave,
} from "../components/activity-details-dialog-edit-tab-content.component";
import {
  useApproveActivityMutation,
  useCreateActivityMutation,
  useDeleteActivityMutation,
  useRejectActivityMutation,
  useResetActivityMutation,
  useSubmitActivityMutation,
  useUpdateActivityMutation,
  useUpdateActivitySourcesMutation,
} from "../ghg-activities.mutations";
import { DeleteIcon } from "../../common/constants/tabler-icon.constants";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import { useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { useResetMutationsOnOpen } from "../../common/hooks/use-reset-mutations-on-open.hook";
import {
  ActivityDetailsDialogSourcesAndValues,
  IActivityDetailsDialogSourcesAndValuesOnSave,
} from "../components/activity-details-dialog-sources-and-values.component";
import { useHasUserDataEntryObjectAccess } from "../../authorization/hooks/has-user-data-entry-object-access.hook";
import {
  useDeleteGhgActivityValueMutation,
  useUpdateGhgActivityValueMutation,
} from "../../ghg-activity-values/ghg-activity-values.mutations";
import { useCombineMutationState } from "../../common/hooks/combined-mutation-state.hook";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { IGhgActivityStatusUpdateEvents } from "../components/ghg-activity-status-indicator-with-menu.component";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { EditDialogCommentsAndChangelogPaper } from "../../comments-and-audit-log/components/edit-dialog-comments-and-audit-log-paper.component";
import { useEditDialogCommentsAndAuditLogPaperState } from "../../comments-and-audit-log/hooks/edit-dialog-comments-and-audit-log-paper-state.hook";
import { EditDialogExpandCommentsAndAuditLogPaperButton } from "../../comments-and-audit-log/components/edit-dialog-expand-comments-and-audit-log-paper-button.component";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";
import { useCreateMultipleGhgActivityValuesMutation } from "../../ghg-activity-values/ghg-activity-values-collection.mutations";
import { GhgActivityReappropriatedAlert } from "../components/ghg-activity-reappropriated-alert.component";

enum ActivityDetailsDialogTab {
  STEP_1_ACTIVITY,
  STEP_2_VALUES,
}

export interface IActivityDetailsDialogEditTabState {
  hasUnsavedChanges: boolean;
}

export interface IActivityDetailsDialogData {
  ghgCategoryIdentity: IRecordingPeriodGhgCategoryIdentity;
  rootDataEntryObject: IDataEntryObject;
  getDataEntryObjectConsolidation: (id: string) => IDataEntryObjectConsolidationResult | null;
  activity?: IActivity | null;
}

interface IActivityDetailsDialogProps extends IActivityDetailsDialogData {
  recordingStructureId: string;
  open: boolean;
  isCategoryDone: boolean;
  onClose: () => void;
  onUpdateActivity: (activity: IActivity) => void;
}

export const ActivityDetailsDialog: FC<IActivityDetailsDialogProps> = ({
  recordingStructureId,
  ghgCategoryIdentity,
  rootDataEntryObject,
  getDataEntryObjectConsolidation,
  open,
  isCategoryDone,
  activity,
  onClose,
  onUpdateActivity,
}) => {
  const mode = activity ? "edit" : "create";

  const activityIdentity: IGhgActivityIdentity = useMemo(
    () => ({
      ...ghgCategoryIdentity,
      activityId: activity?.id ?? "",
    }),
    [activity, ghgCategoryIdentity],
  );

  const { wrapApiPromise } = useAppSnackbar();

  const hasUserDataEntryObjectAccess = useHasUserDataEntryObjectAccess(ghgCategoryIdentity);
  const canUserEditOrDeleteActivity = useMemo(
    () => !activity || hasUserDataEntryObjectAccess(activity.dataEntryObjectId),
    [activity, hasUserDataEntryObjectAccess],
  );

  const createActivityMutation = useCreateActivityMutation();
  const updateActivityMutation = useUpdateActivityMutation();
  const deleteActivityMutation = useDeleteActivityMutation();

  const { t } = useTranslation("ghg_activities");

  const [viewedTab, setViewedTab] = useState<ActivityDetailsDialogTab>(
    activity ? ActivityDetailsDialogTab.STEP_2_VALUES : ActivityDetailsDialogTab.STEP_1_ACTIVITY,
  );
  // Always set initial tab based on activity availability when opening dialog
  useEffect(() => {
    setViewedTab(
      activity ? ActivityDetailsDialogTab.STEP_2_VALUES : ActivityDetailsDialogTab.STEP_1_ACTIVITY,
    );
  }, [activity]);
  // Reset after closing the dialog
  useEffect(() => {
    if (!open) {
      // Reset to correct step after timeout (to avoid flickering)
      const timeout = setTimeout(
        () =>
          setViewedTab(
            activity
              ? ActivityDetailsDialogTab.STEP_2_VALUES
              : ActivityDetailsDialogTab.STEP_1_ACTIVITY,
          ),
        300,
      );
      return () => clearTimeout(timeout);
    }
    // This is fine since we only want to update once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const handleTabInfoUpdate = useCallback((info: IActivityDetailsDialogEditTabState) => {
    setHasUnsavedChanges(info.hasUnsavedChanges);
  }, []);

  const handleSave: IActivityDetailsDialogEditTabOnSave = useCallback(
    async (data, mode) => {
      let result: IActivity;
      if (activity) {
        result = await wrapApiPromise(
          updateActivityMutation.mutateAsync({
            ...ghgCategoryIdentity,
            activityId: activity.id,
            data,
          }),
          { type: BasicSnackbarApiActionType.UPDATE_ACTIVITY },
        );
      } else {
        result = await wrapApiPromise(
          createActivityMutation.mutateAsync({
            ...ghgCategoryIdentity,
            inputParameterRecordingStructureId: recordingStructureId,
            data,
          }),
          { type: BasicSnackbarApiActionType.CREATE_ACTIVITY },
        );
      }
      onUpdateActivity(result);
      // Next step depending on mode
      if (mode === "close") {
        onClose();
      } else if (mode === "record_data") {
        setViewedTab(ActivityDetailsDialogTab.STEP_2_VALUES);
      }
    },
    [
      activity,
      createActivityMutation.mutateAsync,
      ghgCategoryIdentity,
      recordingStructureId,
      onClose,
      onUpdateActivity,
      updateActivityMutation.mutateAsync,
      wrapApiPromise,
    ],
  );

  // Delete Activity handling
  const {
    isOpen: showConfirmDeleteDialog,
    openDialog: openConfirmDeleteDialog,
    closeDialog: closeConfirmDeleteDialog,
  } = useDialogStateWithoutData();
  const handleCloseConfirmDeleteDialog = useCallback(
    async (confirm: boolean) => {
      if (activity && confirm) {
        await wrapApiPromise(
          deleteActivityMutation.mutateAsync({
            ...ghgCategoryIdentity,
            inputParameterRecordingStructureId: recordingStructureId,
            activityId: activity.id,
          }),
          {
            type: BasicSnackbarApiActionType.DELETE_ACTIVITY,
          },
        );
        onClose();
      }
      closeConfirmDeleteDialog();
    },
    [
      activity,
      closeConfirmDeleteDialog,
      deleteActivityMutation.mutateAsync,
      ghgCategoryIdentity,
      recordingStructureId,
      onClose,
      wrapApiPromise,
    ],
  );

  // Value & Update Handling

  const updateActivitySourcesMutation = useUpdateActivitySourcesMutation();
  const createMultipleActivityValuesMutation = useCreateMultipleGhgActivityValuesMutation();
  const updateActivityValueMutation = useUpdateGhgActivityValueMutation();
  const deleteActivityValueMutation = useDeleteGhgActivityValueMutation();

  const handleSaveSecondTab: IActivityDetailsDialogSourcesAndValuesOnSave = useCallback(
    async (newSourceIds, newValues, updatedValues, deletedValues) => {
      await wrapApiPromise(
        Promise.all([
          // Sources
          newSourceIds
            ? updateActivitySourcesMutation.mutateAsync({
                ...activityIdentity,
                data: { sourceIds: newSourceIds },
              })
            : Promise.resolve(),
          // Values
          newValues.length > 0 // Only create if there are new values (otherwise would cause API error)
            ? createMultipleActivityValuesMutation.mutateAsync({
                ...activityIdentity,
                activityValues: newValues,
              })
            : Promise.resolve([]),
          ...updatedValues.map(([valueId, value]) =>
            updateActivityValueMutation.mutateAsync({
              ...activityIdentity,
              valueId,
              payload: value,
            }),
          ),
          ...deletedValues.map((valueId) =>
            deleteActivityValueMutation.mutateAsync({ ...activityIdentity, valueId }),
          ),
        ]),
        { type: BasicSnackbarApiActionType.UPDATE_ACTIVITY_SOURCES_AND_VALUES },
      );
      // Close Dialog
      onClose();
    },
    // This is fine since we only want to subscribe to mutateAsync
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      ghgCategoryIdentity,
      activityIdentity,
      updateActivitySourcesMutation.mutateAsync,
      onClose,
      createMultipleActivityValuesMutation.mutateAsync,
      updateActivityValueMutation.mutateAsync,
      deleteActivityValueMutation.mutateAsync,
    ],
  );

  // Activity Status Changes

  const submitActivityMutation = useSubmitActivityMutation();
  const approveActivityMutation = useApproveActivityMutation();
  const rejectActivityMutation = useRejectActivityMutation();
  const resetActivityMutation = useResetActivityMutation();

  const statusChangeEvents: IGhgActivityStatusUpdateEvents = useMemo(() => {
    const scope = GhgCategoriesUtilities.getScopeOfGhgCategory(
      ghgCategoryIdentity.ghgCategoryIdentifier,
    );
    return {
      onSubmit: () =>
        wrapApiPromise(
          submitActivityMutation.mutateAsync({
            ...activityIdentity,
            context: {
              scope,
              ghgCategoryIdentifier: ghgCategoryIdentity.ghgCategoryIdentifier,
              recordingStructureId,
            },
          }),
          { type: BasicSnackbarApiActionType.SUBMIT_ACTIVITY },
        ),
      onApprove: () =>
        wrapApiPromise(approveActivityMutation.mutateAsync(activityIdentity), {
          type: BasicSnackbarApiActionType.APPROVE_ACTIVITY,
        }),
      onReject: () =>
        wrapApiPromise(rejectActivityMutation.mutateAsync(activityIdentity), {
          type: BasicSnackbarApiActionType.REJECT_ACTIVITY,
        }),
      onReset: () =>
        wrapApiPromise(resetActivityMutation.mutateAsync(activityIdentity), {
          type: BasicSnackbarApiActionType.RESET_ACTIVITY,
        }),
    };
  }, [
    wrapApiPromise,
    ghgCategoryIdentity.ghgCategoryIdentifier,
    submitActivityMutation.mutateAsync,
    activityIdentity,
    recordingStructureId,
    approveActivityMutation.mutateAsync,
    rejectActivityMutation.mutateAsync,
    resetActivityMutation.mutateAsync,
  ]);

  // Reset Migrations
  useResetMutationsOnOpen(
    open,
    createActivityMutation,
    updateActivityMutation,
    deleteActivityMutation,
    updateActivitySourcesMutation,
    createMultipleActivityValuesMutation,
    updateActivityMutation,
    deleteActivityValueMutation,
    submitActivityMutation,
    approveActivityMutation,
    rejectActivityMutation,
    resetActivityMutation,
  );

  // Comments

  const {
    showCommentsAndAuditLogSideSection,
    toggleCommentsAndAuditLogSideSection,
    closeCommentsAndAuditLogSideSection,
    dialogProps,
    dialogElement,
  } = useEditDialogCommentsAndAuditLogPaperState(open);

  const areCommentsAvailable = !!activity;

  // Rendering

  const {
    isPending: isAnyMutationPending,
    isError: isAnyMutationError,
    error: anyMutationError,
  } = useCombineMutationState(
    createActivityMutation,
    updateActivityMutation,
    deleteActivityMutation,
    updateActivitySourcesMutation,
    createMultipleActivityValuesMutation,
    updateActivityValueMutation,
    deleteActivityValueMutation,
    submitActivityMutation,
    approveActivityMutation,
    rejectActivityMutation,
    resetActivityMutation,
  );

  const reappropriatedTo = useMemo(
    () =>
      activity
        ? getDataEntryObjectConsolidation(activity?.dataEntryObjectId)?.relocateEmissionsTo ?? null
        : null,
    [activity],
  );

  // Disabled tabs if mutating or there are changes in the current tab
  const disableTabs = isAnyMutationPending || hasUnsavedChanges;

  return (
    <>
      {/* Confirm Delete Activity */}
      <ConfirmDialogTextBody
        open={showConfirmDeleteDialog}
        loading={deleteActivityMutation.isPending}
        error={deleteActivityMutation.isError ? deleteActivityMutation.error : null}
        disabled={deleteActivityMutation.isPending}
        title={t("confirm_delete_dialog.title")}
        content={t("confirm_delete_dialog.content")}
        onClose={handleCloseConfirmDeleteDialog}
      />

      {/* Comments Paper */}
      {areCommentsAvailable && (
        <EditDialogCommentsAndChangelogPaper
          open={showCommentsAndAuditLogSideSection}
          dialogOpen={open}
          organizationId={ghgCategoryIdentity.organizationId}
          relations={{
            entity: "activity",
            activityId: activity?.id ?? "",
          }}
          linkMetadata={{
            entity: "activity",
            organizationId: ghgCategoryIdentity.organizationId,
            recordingPeriodId: ghgCategoryIdentity.recordingPeriodId,
            ghgCategoryIdentifier: ghgCategoryIdentity.ghgCategoryIdentifier,
            recordingStructureId,
            recordingStructureType: "ghg",
            activityId: activity?.id ?? "",
          }}
          dialogElement={dialogElement}
          onClose={closeCommentsAndAuditLogSideSection}
        />
      )}

      <Dialog
        open={open}
        onClose={hasUnsavedChanges ? undefined : () => onClose()}
        maxWidth={viewedTab === ActivityDetailsDialogTab.STEP_1_ACTIVITY ? "lg" : "xl"}
        fullWidth
        {...dialogProps}
        PaperProps={{
          sx: {
            transition: "max-width 300ms",
          },
          ...dialogProps.PaperProps,
        }}
      >
        {/* Dialog Header */}
        <Box display="flex" alignItems="center" px={2} py={2}>
          <ActivityDetailsDialogBreadcrumbsComponent
            ghgCategoryIdentity={ghgCategoryIdentity}
            activity={activity}
          />
          <Box flex={1} />
          {canUserEditOrDeleteActivity && mode === "edit" && (
            <Tooltip
              title={isCategoryDone ? t("notice_ghg_category_done", { ns: "ghg_protocol" }) : null}
              placement="left"
            >
              <span>
                <IconButton onClick={() => openConfirmDeleteDialog()} disabled={isCategoryDone}>
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {/* Open Comment and Audit Log Sidebar */}
          {areCommentsAvailable && (
            <EditDialogExpandCommentsAndAuditLogPaperButton
              showSideSection={showCommentsAndAuditLogSideSection}
              onToggle={toggleCommentsAndAuditLogSideSection}
            />
          )}
        </Box>
        <Divider />
        {isAnyMutationPending && <LinearProgress />}
        {isCategoryDone && (
          <Box p={2} pb={0}>
            <Alert severity="info">{t("notice_ghg_category_done", { ns: "ghg_protocol" })}</Alert>
          </Box>
        )}
        {reappropriatedTo && (
          <Box p={2} pb={0}>
            <GhgActivityReappropriatedAlert reapporpriatedTo={reappropriatedTo} />
          </Box>
        )}
        {/* Tabs */}
        {canUserEditOrDeleteActivity && (
          <Box p={2} pb={0}>
            <Tabs variant="fullWidth" value={viewedTab}>
              <Tab
                label={t(activity ? "tabs.edit_activity" : "tabs.create_activity")}
                value={ActivityDetailsDialogTab.STEP_1_ACTIVITY}
                onClick={() => setViewedTab(ActivityDetailsDialogTab.STEP_1_ACTIVITY)}
                disabled={disableTabs}
              />
              <Tab
                label={t("tabs.collect_data")}
                value={ActivityDetailsDialogTab.STEP_2_VALUES}
                onClick={() => setViewedTab(ActivityDetailsDialogTab.STEP_2_VALUES)}
                // Only allow when activity has already been created
                disabled={disableTabs || !activity}
              />
            </Tabs>
          </Box>
        )}
        {/* Dialog Content */}
        {isAnyMutationError && (
          <Box p={2}>
            <ErrorTextComponent error={anyMutationError} />
          </Box>
        )}
        {
          {
            [ActivityDetailsDialogTab.STEP_1_ACTIVITY]: (
              <ActivityDetailsDialogEditTabContent
                mode={mode}
                ghgCategoryIdentity={ghgCategoryIdentity}
                rootDataEntryObject={rootDataEntryObject}
                getDataEntryObjectConsolidation={getDataEntryObjectConsolidation}
                activity={activity}
                disabled={isAnyMutationPending}
                onStateChange={handleTabInfoUpdate}
                onClose={onClose}
                onSave={handleSave}
              />
            ),
            [ActivityDetailsDialogTab.STEP_2_VALUES]: activity && (
              <ActivityDetailsDialogSourcesAndValues
                ghgCategoryIdentity={ghgCategoryIdentity}
                activity={activity}
                disabled={isAnyMutationPending}
                onStateChange={handleTabInfoUpdate}
                onClose={onClose}
                onSave={handleSaveSecondTab}
                statusChangeEvents={statusChangeEvents}
                onToggleCommentsAndAuditLogSideSection={toggleCommentsAndAuditLogSideSection}
              />
            ),
          }[viewedTab]
        }
      </Dialog>
    </>
  );
};
