import { FC } from "react";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { UserAvatar } from "./user-avatar.component";
import { Box } from "@mui/material";
import { UserUtilities } from "@netcero/netcero-common";

interface IUserItemProps {
  user: IUserRepresentation;
  onClick?: VoidFunction;
}

export const UserItem: FC<IUserItemProps> = ({ user, onClick }) => {
  return (
    <Box onClick={onClick} sx={{ display: "flex", gap: 1 }}>
      <UserAvatar user={user} disabledOverlay />
      {UserUtilities.formatUserFullName(user)}
    </Box>
  );
};
