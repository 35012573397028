import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from "react";
import { Menu } from "@mui/material";
import { useUserContext } from "../../user/user.context";
import {
  IRecordingPeriodGhgCategory,
  IRecordingPeriodGhgCategoryStatus,
} from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import {
  IStatusChangeMenuItem,
  StatusChangeMenuItems,
} from "../../common/components/status-change-menu-items.component";
import { GhgCategoryStatusIndicator } from "./ghg-category-status-indicator.component";
import { useOpenStateWithForwardClose } from "../../common/hooks/open-state-with-forward-close.hook";
import { useDialogState, useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { useDoesUserHaveRole } from "../../authentication/hooks/does-user-have-role.hook";
import { IRecordingPeriodGhgCategoryIdentity, ROLE_ACCESS } from "@netcero/netcero-common";
import { GhgCategoryExclusionDialogWithMutations } from "../dialogs/ghg-category-exclusion.dialog";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";

export interface IGhgCategoryStatusIndicatorWithMenuRef {
  exclude: VoidFunction;
}

export interface IGhgCategoryStatusUpdateEvents {
  onReset: VoidFunction;
  onSubmit: VoidFunction;
  onApprove: VoidFunction;
  onReject: VoidFunction;
}

interface IGhgCategoryStatusIndicatorWithMenuProps extends IGhgCategoryStatusUpdateEvents {
  identity: IRecordingPeriodGhgCategoryIdentity;
  inputParameterRecordingStructureId: string;
  category: IRecordingPeriodGhgCategory;
  hasIncompleteActivities?: boolean;
  disabled?: boolean;
  doNotShowConfirmTransitionDialog?: boolean;
}

export const GhgCategoryStatusIndicatorWithMenu = forwardRef<
  IGhgCategoryStatusIndicatorWithMenuRef,
  IGhgCategoryStatusIndicatorWithMenuProps
>(function GhgCategoryStatusIndicatorWithMenu(
  {
    identity,
    inputParameterRecordingStructureId,
    category,
    onReset,
    onSubmit,
    onApprove,
    onReject,
    hasIncompleteActivities = false,
    disabled,
    doNotShowConfirmTransitionDialog,
  },
  ref,
) {
  const { t } = useTranslation("ghg_protocol", { keyPrefix: "transition_actions" });

  const { user } = useUserContext();
  const doesUserHoveRoleToExclude = useDoesUserHaveRole(
    ROLE_ACCESS.ALLOWED_TO_EXCLUDE_GHG_CATEGORY,
  );
  const doesUserHaveRoleToInclude = useDoesUserHaveRole(
    ROLE_ACCESS.ALLOWED_TO_INCLUDE_GHG_CATEGORY,
  );
  const canUserSeeAllActivities = useDoesUserHaveRole(ROLE_ACCESS.ALLOWED_TO_SEE_ALL_ACTIVITIES);

  const { status, responsibleUserId, exclude } = category;
  const isResponsibleUser = useMemo(
    () => responsibleUserId && responsibleUserId === user?.userProfile?.id,
    [responsibleUserId, user],
  );
  const excluded = !!exclude;
  const withReview = !!responsibleUserId;

  const statusIndicatorRef = useRef<HTMLDivElement>(null);

  const { showMenu, setShowMenu, forwardWithCloseMenu } = useOpenStateWithForwardClose();

  const {
    isOpen: showConfirmSubmitDialog,
    openDialog: openConfirmSubmitDialog,
    closeDialog: closeConfirmSubmitDialog,
  } = useDialogStateWithoutData();
  const handleSubmit = useCallback(() => {
    // Do not allow immediate submit if there are incomplete activities
    if (hasIncompleteActivities) {
      openConfirmSubmitDialog();
      return;
    }
    onSubmit();
  }, [hasIncompleteActivities, onSubmit, openConfirmSubmitDialog]);

  const {
    isOpen: showConfirmTransitionWithoutSeeingAllActivitiesDialog,
    openDialog: openConfirmTransitionWithoutSeeingAllActivitiesDialog,
    closeDialog: closeConfirmTransitionWithoutSeeingAllActivitiesDialog,
    data: confirmTransitionWithoutSeeingAllActivitiesDialogData,
  } = useDialogState<VoidFunction>();
  const handleTransition = useCallback(
    (transition: VoidFunction) =>
      forwardWithCloseMenu(() => {
        if (!doNotShowConfirmTransitionDialog && !canUserSeeAllActivities) {
          openConfirmTransitionWithoutSeeingAllActivitiesDialog(transition);
          return;
        }
        transition();
      }),
    [
      doNotShowConfirmTransitionDialog,
      canUserSeeAllActivities,
      forwardWithCloseMenu,
      openConfirmTransitionWithoutSeeingAllActivitiesDialog,
    ],
  );

  const statusChangeItems: IStatusChangeMenuItem[] = useMemo(() => {
    switch (status) {
      case IRecordingPeriodGhgCategoryStatus.InProgress:
        return [
          {
            id: "submit",
            onClick: handleTransition(handleSubmit),
            disabled: !!disabled,
            title: t(withReview ? "submit_with_reviewer" : "submit_without_reviewer"),
            newStatusIndicator: (
              <GhgCategoryStatusIndicator
                status={
                  withReview
                    ? IRecordingPeriodGhgCategoryStatus.InReview
                    : IRecordingPeriodGhgCategoryStatus.Done
                }
              />
            ),
          },
        ];
      case IRecordingPeriodGhgCategoryStatus.InReview: {
        const tooltip = !isResponsibleUser
          ? t("notice_only_responsible_person_can_approve_or_reject")
          : null;

        return [
          /* Approve Option */
          {
            id: "approve",
            onClick: handleTransition(onApprove),
            tooltip,
            disabled: !isResponsibleUser || !!disabled,
            title: t("approve"),
            newStatusIndicator: (
              <GhgCategoryStatusIndicator status={IRecordingPeriodGhgCategoryStatus.Done} />
            ),
          },
          /* Reject Option */
          {
            id: "reject",
            onClick: handleTransition(onReject),
            tooltip,
            disabled: !isResponsibleUser || !!disabled,
            title: t("reject"),
            newStatusIndicator: (
              <GhgCategoryStatusIndicator status={IRecordingPeriodGhgCategoryStatus.InProgress} />
            ),
          },
        ];
      }
      case IRecordingPeriodGhgCategoryStatus.Done:
        return [
          {
            id: "reset",
            onClick: handleTransition(onReset),
            disabled: !!disabled,
            title: t("reset"),
            newStatusIndicator: (
              <GhgCategoryStatusIndicator status={IRecordingPeriodGhgCategoryStatus.InProgress} />
            ),
          },
        ];
      default:
        return [];
    }
  }, [
    status,
    handleTransition,
    handleSubmit,
    disabled,
    t,
    withReview,
    onReset,
    isResponsibleUser,
    onApprove,
    onReject,
  ]);

  // Exclude Handling
  const {
    isOpen: excludeDialogOpen,
    openDialog: openExcludeDialog,
    closeDialog: closeExcludeDialog,
  } = useDialogStateWithoutData();
  const handleClickExclude = useCallback(() => {
    openExcludeDialog();
  }, [openExcludeDialog]);

  // Only the responsible person can exclude or include
  const isOnlyUserAllowedToInOrExclude = !responsibleUserId || isResponsibleUser;
  // Only allow if the user has the role
  const canExclude = doesUserHoveRoleToExclude && isOnlyUserAllowedToInOrExclude;
  const canInclude = doesUserHaveRoleToInclude && isOnlyUserAllowedToInOrExclude;

  const isExcludeAvailable = useMemo(
    () => status !== IRecordingPeriodGhgCategoryStatus.Done,
    [status],
  );

  const allItems = useMemo(
    () => [
      // Normal Status Change Items (when not excluded)
      ...(!excluded ? statusChangeItems : []),
      // Add Exclusion Option
      ...(isExcludeAvailable
        ? [
            {
              id: "exclude",
              onClick: handleTransition(handleClickExclude),
              disabled: !canExclude,
              tooltip: canExclude
                ? null
                : t("notice_exclusion_only_possible_as_responsible_and_full_access"),
              title: t("exclude_transition_name"),
              newStatusIndicator: <GhgCategoryStatusIndicator excluded />,
            },
          ]
        : []),
    ],
    [
      canExclude,
      excluded,
      handleClickExclude,
      handleTransition,
      isExcludeAvailable,
      statusChangeItems,
      t,
    ],
  );

  // Imperative Handle
  useImperativeHandle(ref, () => ({
    exclude: handleClickExclude,
  }));

  return (
    <>
      {/* Exclude Dialog */}
      <GhgCategoryExclusionDialogWithMutations
        ghgCategoryIdentity={identity}
        inputParameterRecordingStructureId={inputParameterRecordingStructureId}
        open={excludeDialogOpen}
        onClose={closeExcludeDialog}
        exclusionData={exclude ?? null}
        cannotIncludeReason={
          canInclude
            ? undefined
            : t("notice_inclusion_only_possible_as_responsible_and_full_access")
        }
      />

      {/* Confirm Submit Dialog (incomplete activities notice) */}
      <ConfirmDialogTextBody
        open={showConfirmSubmitDialog}
        title={t("confirm_incomplete_submission.title")}
        content={t(
          `confirm_incomplete_submission.content.${
            withReview ? "with_reviewer" : "without_reviewer"
          }`,
        )}
        onCancel={() => closeConfirmSubmitDialog()}
        onConfirm={() => {
          onSubmit();
          closeConfirmSubmitDialog();
        }}
      />

      {/* Confirm transition without seeing all activities Dialog */}
      <ConfirmDialogTextBody
        open={showConfirmTransitionWithoutSeeingAllActivitiesDialog}
        title={t("confirm_transition_without_seeing_all_activities.title")}
        content={t("confirm_transition_without_seeing_all_activities.content")}
        onCancel={closeConfirmTransitionWithoutSeeingAllActivitiesDialog}
        onConfirm={() => {
          closeConfirmTransitionWithoutSeeingAllActivitiesDialog();
          confirmTransitionWithoutSeeingAllActivitiesDialogData?.();
        }}
      />

      {/* Menu */}
      <Menu
        open={showMenu}
        anchorEl={statusIndicatorRef.current}
        onClose={() => setShowMenu(false)}
      >
        <StatusChangeMenuItems items={allItems} />
      </Menu>

      {/* Status */}
      <GhgCategoryStatusIndicator
        ref={statusIndicatorRef}
        excluded={excluded}
        status={status}
        onClick={() => (excluded ? openExcludeDialog() : setShowMenu(true))}
        disabled={disabled}
        isMenuOpen={showMenu}
        shouldRenderChevronIcon
      />
    </>
  );
});
