import React, { FC, useCallback, useState } from "react";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import {
  $computeTableMapSkipCellCheck,
  $deleteTableColumn__EXPERIMENTAL,
  $deleteTableRow__EXPERIMENTAL,
  $getTableNodeFromLexicalNodeOrThrow,
  $getTableRowIndexFromTableCellNode,
  $insertTableColumn__EXPERIMENTAL,
  $insertTableRow__EXPERIMENTAL,
  $isTableCellNode,
  $isTableRowNode,
  $isTableSelection,
  $unmergeCell,
  getTableObserverFromTableElement,
  HTMLTableElementWithWithTableSelectionState,
  TableCellHeaderStates,
  TableCellNode,
} from "@lexical/table";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { createPortal } from "react-dom";
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isParagraphNode,
  $isTextNode,
  ElementNode,
} from "lexical";
import { useTranslation } from "react-i18next";
import {
  ColumnInsertLeftIcon,
  ColumnInsertRightIcon,
  ColumnRemoveIcon,
  DeleteIcon,
  IconSize,
  MergeIcon,
  RowHeaderIcon,
  RowInsertBottomIcon,
  RowInsertTopIcon,
  RowRemoveIcon,
  SplitIcon,
} from "../../common/constants/tabler-icon.constants";

function $cellContainsEmptyParagraph(cell: TableCellNode): boolean {
  if (cell.getChildrenSize() !== 1) {
    return false;
  }
  const firstChild = cell.getFirstChildOrThrow();
  return !(!$isParagraphNode(firstChild) || !firstChild.isEmpty());
}

function $selectLastDescendant(node: ElementNode): void {
  const lastDescendant = node.getLastDescendant();
  if ($isTextNode(lastDescendant)) {
    lastDescendant.select();
  } else if ($isElementNode(lastDescendant)) {
    lastDescendant.selectEnd();
  } else if (lastDescendant !== null) {
    lastDescendant.selectNext();
  }
}

interface ITableActionMenuProps {
  open: boolean;
  contextRef: { current: null | HTMLElement };
  onClose: () => void;
  tableCellNode: TableCellNode;
}

export const TableActionMenu: FC<ITableActionMenuProps> = ({
  open,
  contextRef,
  onClose,
  tableCellNode: _tableCellNode,
}) => {
  const { t } = useTranslation("lexical_tables");
  const [editor] = useLexicalComposerContext();

  const [tableCellNode, updateTableCellNode] = useState(_tableCellNode);

  const clearTableSelection = useCallback(() => {
    editor.update(() => {
      if (tableCellNode.isAttached()) {
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
        const tableElement = editor.getElementByKey(
          tableNode.getKey(),
        ) as HTMLTableElementWithWithTableSelectionState;

        if (!tableElement) {
          throw new Error("Expected to find tableElement in DOM");
        }

        const tableObserver = getTableObserverFromTableElement(tableElement);
        if (tableObserver !== null) {
          tableObserver.$clearHighlight();
        }

        tableNode.markDirty();
        updateTableCellNode(tableCellNode.getLatest());
      }

      const rootNode = $getRoot();
      rootNode.selectStart();
    });
  }, [editor, tableCellNode]);

  const insertTableRowAtSelection = useCallback(
    (shouldInsertAfter: boolean) => {
      editor.update(() => {
        $insertTableRow__EXPERIMENTAL(shouldInsertAfter);
        onClose();
      });
    },
    [editor, onClose],
  );

  const insertTableColumnAtSelection = useCallback(
    (shouldInsertAfter: boolean) => {
      editor.update(() => {
        $insertTableColumn__EXPERIMENTAL(shouldInsertAfter);
        onClose();
      });
    },
    [editor, onClose],
  );

  const deleteTableRowAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableRow__EXPERIMENTAL();
      onClose();
    });
  }, [editor, onClose]);

  const deleteTableAtSelection = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
      tableNode.remove();

      clearTableSelection();
      onClose();
    });
  }, [editor, tableCellNode, clearTableSelection, onClose]);

  const deleteTableColumnAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableColumn__EXPERIMENTAL();
      onClose();
    });
  }, [editor, onClose]);

  const toggleTableRowIsHeader = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

      const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);

      const tableRows = tableNode.getChildren();

      if (tableRowIndex >= tableRows.length || tableRowIndex < 0) {
        throw new Error("Expected table cell to be inside of table row.");
      }

      const tableRow = tableRows[tableRowIndex];

      if (!$isTableRowNode(tableRow)) {
        throw new Error("Expected table row");
      }

      const newStyle = tableCellNode.getHeaderStyles() ^ TableCellHeaderStates.ROW;
      tableRow.getChildren().forEach((tableCell) => {
        if (!$isTableCellNode(tableCell)) {
          throw new Error("Expected table cell");
        }

        tableCell.setHeaderStyles(newStyle, TableCellHeaderStates.ROW);
      });

      clearTableSelection();
      onClose();
    });
  }, [editor, tableCellNode, clearTableSelection, onClose]);

  const mergeTableCellsAtSelection = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isTableSelection(selection)) {
        // Get all selected cells and compute the total area
        const nodes = selection.getNodes();
        const tableCells = nodes.filter($isTableCellNode);

        if (tableCells.length === 0) {
          return;
        }

        // Find the table node
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCells[0]);
        const [gridMap] = $computeTableMapSkipCellCheck(tableNode, null, null);

        // Find the boundaries of the selection including merged cells
        let minRow = Infinity;
        let maxRow = -Infinity;
        let minCol = Infinity;
        let maxCol = -Infinity;

        // First pass: find the actual boundaries considering merged cells
        const processedCells = new Set();
        for (const row of gridMap) {
          for (const mapCell of row) {
            if (!mapCell || !mapCell.cell) {
              continue;
            }

            const cellKey = mapCell.cell.getKey();
            if (processedCells.has(cellKey)) {
              continue;
            }

            if (tableCells.some((cell) => cell.is(mapCell.cell))) {
              processedCells.add(cellKey);
              // Get the actual position of this cell in the grid
              const cellStartRow = mapCell.startRow;
              const cellStartCol = mapCell.startColumn;
              const cellRowSpan = mapCell.cell.__rowSpan || 1;
              const cellColSpan = mapCell.cell.__colSpan || 1;

              // Update boundaries considering the cell's actual position and span
              minRow = Math.min(minRow, cellStartRow);
              maxRow = Math.max(maxRow, cellStartRow + cellRowSpan - 1);
              minCol = Math.min(minCol, cellStartCol);
              maxCol = Math.max(maxCol, cellStartCol + cellColSpan - 1);
            }
          }
        }

        // Validate boundaries
        if (minRow === Infinity || minCol === Infinity) {
          return;
        }

        // The total span of the merged cell
        const totalRowSpan = maxRow - minRow + 1;
        const totalColSpan = maxCol - minCol + 1;

        // Use the top-left cell as the target cell
        const targetCellMap = gridMap[minRow][minCol];
        if (!targetCellMap?.cell) {
          return;
        }
        const targetCell = targetCellMap.cell;

        // Set the spans for the target cell
        targetCell.setColSpan(totalColSpan);
        targetCell.setRowSpan(totalRowSpan);

        // Move content from other cells to the target cell
        const seenCells = new Set([targetCell.getKey()]);

        // Second pass: merge content and remove other cells
        for (let row = minRow; row <= maxRow; row++) {
          for (let col = minCol; col <= maxCol; col++) {
            const mapCell = gridMap[row][col];
            if (!mapCell?.cell) {
              continue;
            }

            const currentCell = mapCell.cell;
            const key = currentCell.getKey();

            if (!seenCells.has(key)) {
              seenCells.add(key);
              const isEmpty = $cellContainsEmptyParagraph(currentCell);
              if (!isEmpty) {
                targetCell.append(...currentCell.getChildren());
              }
              currentCell.remove();
            }
          }
        }

        // Ensure target cell has content
        if (targetCell.getChildrenSize() === 0) {
          targetCell.append($createParagraphNode());
        }

        $selectLastDescendant(targetCell);
        onClose();
      }
    });
  };

  const unmergeTableCellsAtSelection = () => {
    editor.update(() => {
      $unmergeCell();
    });
  };

  return createPortal(
    <Menu open={open} anchorEl={contextRef.current} onClose={onClose}>
      <MenuItem onClick={toggleTableRowIsHeader}>
        <ListItemIcon>
          <RowHeaderIcon size={IconSize.Medium} />
        </ListItemIcon>
        <ListItemText primary={t("toggle_row_header")} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => insertTableRowAtSelection(false)}>
        <ListItemIcon>
          <RowInsertTopIcon size={IconSize.Medium} />
        </ListItemIcon>
        <ListItemText primary={t("insert_row_above")} />
      </MenuItem>
      <MenuItem onClick={() => insertTableRowAtSelection(true)}>
        <ListItemIcon>
          <RowInsertBottomIcon size={IconSize.Medium} />
        </ListItemIcon>
        <ListItemText primary={t("insert_row_below")} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => insertTableColumnAtSelection(false)}>
        <ListItemIcon>
          <ColumnInsertLeftIcon size={IconSize.Medium} />
        </ListItemIcon>
        <ListItemText primary={t("insert_column_left")} />
      </MenuItem>
      <MenuItem onClick={() => insertTableColumnAtSelection(true)}>
        <ListItemIcon>
          <ColumnInsertRightIcon size={IconSize.Medium} />
        </ListItemIcon>
        <ListItemText primary={t("insert_column_right")} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={deleteTableRowAtSelection}>
        <ListItemIcon>
          <RowRemoveIcon size={IconSize.Medium} />
        </ListItemIcon>
        <ListItemText primary={t("delete_row")} />
      </MenuItem>
      <MenuItem onClick={deleteTableColumnAtSelection}>
        <ListItemIcon>
          <ColumnRemoveIcon size={IconSize.Medium} />
        </ListItemIcon>
        <ListItemText primary={t("delete_column")} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={mergeTableCellsAtSelection}>
        <ListItemIcon>
          <MergeIcon size={IconSize.Medium} />
        </ListItemIcon>
        <ListItemText primary={t("merge_cells")} />
      </MenuItem>
      <MenuItem onClick={unmergeTableCellsAtSelection}>
        <ListItemIcon>
          <SplitIcon size={IconSize.Medium} />
        </ListItemIcon>
        <ListItemText primary={t("unmerge_cells")} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={deleteTableAtSelection}>
        <ListItemIcon>
          <DeleteIcon size={IconSize.Medium} />
        </ListItemIcon>
        <ListItemText primary={t("delete_table")} />
      </MenuItem>
    </Menu>,
    document.body,
  );
};
