import { Dispatch, FC, useCallback, useEffect, useMemo } from "react";
import { OptionalPropertiesToNull } from "@netcero/netcero-common";
import { IBaseActivityData } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { HookFormControllerUtilities } from "../../common/input-validation/hook-form-controller.utilities";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import {
  IActivityDetailsDialogData,
  IActivityDetailsDialogEditTabState,
} from "../dialogs/activity-details.dialog";
import { OrganizationUserPicker } from "../../user/components/organization-user-picker.component";
import { EmissionFactorsSelectionTableWithFiltersSection } from "../../emission-factors/components/emission-factors-selection-table-with-filters-section.component";
import {
  DialogCancelButton,
  DialogSaveButton,
  DialogSecondaryActionButton,
} from "../../common/dialogs/dialog-button.components";
import { Link as RouterLink } from "react-router-dom";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";
import { OpenInNewTabIcon } from "../../common/constants/tabler-icon.constants";
import { DeoSelectWithAuthorization } from "../../data-entry-objects/components/deo-select.component";
import { MapFilterState } from "../../filters/map-state-filters.component";
import { EmissionFactorDialogFilter } from "../../filters/filter.constants";
import { AppWarningIcon } from "../../common/components/app-warning-icon.component";
import { useGhgActivityReappropriatedText } from "../hooks/ghg-activity-reappropriated-text.hook";

export type IActivityDetailsDialogEditTabOnSave = (
  data: IBaseActivityData,
  mode: "close" | "record_data",
) => void;

interface IBaseActivityDataEditFormState
  extends Omit<OptionalPropertiesToNull<IBaseActivityData>, "dataEntryObjectId"> {
  dataEntryObjectId: string | null;
}

function getDialogEditData(activity?: IBaseActivityData | null): IBaseActivityDataEditFormState {
  return {
    title: activity?.title ?? "",
    dataEntryObjectId: activity?.dataEntryObjectId ?? null,
    responsibleUserId: activity?.responsibleUserId ?? null,
    emissionFactorIds: activity?.emissionFactorIds ?? [],
  };
}

function getApiEditData(data: IBaseActivityDataEditFormState): IBaseActivityData {
  return {
    ...data,
    responsibleUserId: data.responsibleUserId ?? undefined,
    // This is fine since we know dataEntryObjectId is required
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dataEntryObjectId: data.dataEntryObjectId!,
  };
}

interface IActivityDetailsDialogEditTabContentProps extends IActivityDetailsDialogData {
  mode: "create" | "edit";
  onStateChange: Dispatch<IActivityDetailsDialogEditTabState>;
  onClose: VoidFunction;
  onSave: IActivityDetailsDialogEditTabOnSave;
  disabled: boolean;
}

export const ActivityDetailsDialogEditTabContent: FC<IActivityDetailsDialogEditTabContentProps> = ({
  mode,
  activity,
  rootDataEntryObject,
  getDataEntryObjectConsolidation,
  ghgCategoryIdentity,
  onStateChange,
  onClose,
  onSave,
  disabled,
}) => {
  const { t } = useTranslation("ghg_activities");
  const getReappropriatedText = useGhgActivityReappropriatedText();

  const initialFormValues = useMemo(() => getDialogEditData(activity), [activity]);
  const initialEmissionFactorFilters: MapFilterState = useMemo(
    () =>
      new Map([
        [
          EmissionFactorDialogFilter.ghgCategoriesDialog,
          [ghgCategoryIdentity.ghgCategoryIdentifier],
        ],
      ]),
    [ghgCategoryIdentity.ghgCategoryIdentifier],
  );

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<IBaseActivityDataEditFormState>({
    defaultValues: initialFormValues,
  });

  useEffect(() => {
    onStateChange({
      hasUnsavedChanges: isDirty,
    });
  }, [isDirty, onStateChange]);

  const handleSave = useCallback(
    (data: IBaseActivityDataEditFormState) => {
      onSave(getApiEditData(data), "close");
    },
    [onSave],
  );

  const handleSaveAndRecordValues = useCallback(
    (data: IBaseActivityDataEditFormState) => {
      onSave(getApiEditData(data), "record_data");
    },
    [onSave],
  );

  return (
    <>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          {/* Tab Heading */}
          <Typography variant="subtitle1" mb={-1}>
            {t(`tabs.${mode}_activity`)}
          </Typography>
          {/* Title */}
          <Controller
            control={control}
            name="title"
            rules={HookFormControllerUtilities.createRequiredStringRules(
              t("error_field_required", { ns: "common" }),
            )}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("field_names.title")}
                error={!!error}
                helperText={error?.message}
                fullWidth
                multiline
                maxRows={3}
                disabled={disabled}
              />
            )}
          />

          {/* DEO & Responsible Person */}
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="dataEntryObjectId"
                rules={HookFormControllerUtilities.createRequiredStringRules(
                  t("error_field_required", { ns: "common" }),
                )}
                render={({ field, fieldState: { error } }) => {
                  const deoConsolidationReappropriatedTo =
                    field.value !== null
                      ? getDataEntryObjectConsolidation(field.value)?.relocateEmissionsTo ?? null
                      : null;

                  return (
                    <FormControl variant="outlined" error={!!error} fullWidth sx={{ mt: 2.5 }}>
                      <InputLabel>{t("field_names.data_entry_object")}</InputLabel>
                      <DeoSelectWithAuthorization
                        recordingPeriodIdentity={ghgCategoryIdentity}
                        selectedDeoId={field.value}
                        onChange={field.onChange}
                        rootDeo={rootDataEntryObject}
                        label={t("field_names.data_entry_object")}
                        error={!!error}
                        disabled={disabled}
                        fullWidth
                      />
                      {error && <FormHelperText>{error.message}</FormHelperText>}
                      {deoConsolidationReappropriatedTo && (
                        <FormHelperText
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            color: "warning.main",
                          }}
                        >
                          <AppWarningIcon fontSize="small" />
                          {getReappropriatedText(deoConsolidationReappropriatedTo)}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {t("label_responsible_user", { ns: "associated_users_dialog_inputs" })}
              </Typography>
              <Controller
                control={control}
                name="responsibleUserId"
                render={({ field }) => (
                  <OrganizationUserPicker
                    value={field.value ?? null}
                    onChange={(user) => field.onChange(user?.id ?? null)}
                    organizationId={ghgCategoryIdentity.organizationId}
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* Emission Factors Table */}
          <Box pt={4.5}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1">{t("field_names.emission_factors")}</Typography>
              {/* Manage Emission Factors Link */}
              <Button
                variant="text"
                size="small"
                component={RouterLink}
                to={AppInternalNavigationUtilities.getConfigurationEmissionFactorsPageUrl(
                  ghgCategoryIdentity.organizationId,
                )}
                target="_blank"
                startIcon={<OpenInNewTabIcon />}
                disabled={disabled}
              >
                {t("manage_emission_factors", { ns: "emission_factors_common" })}
              </Button>
            </Box>
            <Controller
              control={control}
              name="emissionFactorIds"
              render={({ field }) => (
                <EmissionFactorsSelectionTableWithFiltersSection
                  organizationId={ghgCategoryIdentity.organizationId}
                  selectedEmissionFactorIds={field.value}
                  onChange={field.onChange}
                  defaultFilters={initialEmissionFactorFilters}
                  disabled={disabled}
                />
              )}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* Close/Cancel and Create Activity Buttons */}
        <DialogCancelButton onClick={() => onClose()} disabled={disabled}>
          {t(mode === "create" ? "cancel" : "close", { ns: "buttons" })}
        </DialogCancelButton>
        {mode === "create" ? (
          <>
            {/* Spacer to align cancel button left */}
            <Box flex={1} />
            {/* Buttons */}
            <DialogSecondaryActionButton
              onClick={handleSubmit(handleSave)}
              disabled={disabled || !isDirty}
            >
              {t("buttons.create_only")}
            </DialogSecondaryActionButton>
            <DialogSaveButton
              onClick={handleSubmit(handleSaveAndRecordValues)}
              disabled={disabled || !isDirty}
            >
              {t("buttons.create_and_collect_data")}
            </DialogSaveButton>
          </>
        ) : (
          <DialogSaveButton onClick={handleSubmit(handleSave)} disabled={disabled || !isDirty}>
            {t("save", { ns: "buttons" })}
          </DialogSaveButton>
        )}
      </DialogActions>
    </>
  );
};
