import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IRecordingPeriodGhgCategoryIdentity } from "@netcero/netcero-common";

export const getGhgCategoryActivitiesQueryKey = ({
  organizationId,
  recordingPeriodId,
  ghgCategoryIdentifier,
}: IRecordingPeriodGhgCategoryIdentity) => [
  "recording-period-ghg-category-activities",
  organizationId,
  recordingPeriodId,
  ghgCategoryIdentifier,
];

export const useGhgCategoryActivitiesQuery = (identity: IRecordingPeriodGhgCategoryIdentity) => {
  return useQuery({
    queryKey: getGhgCategoryActivitiesQueryKey(identity),
    queryFn: () =>
      CoreApiService.ActivitiesApi.getActivitiesForRecordingPeriodGhgCategory(
        identity.organizationId,
        identity.recordingPeriodId,
        identity.ghgCategoryIdentifier,
      )
        .then((req) => req())
        .then((res) => res.data),
  });
};
