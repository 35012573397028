import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { NotAuthorizedNotice } from "../../modules/authentication/components/not-authorized-notice.component";
import { useDoesUserHaveRole } from "../../modules/authentication/hooks/does-user-have-role.hook";
import { OrganizationRole } from "@netcero/netcero-common";

interface IRequireOrganizationRoleLayoutProps extends PropsWithChildren {
  rolesWithAccess: OrganizationRole[];
}

export const RequireOrganizationRoleLayout: FC<IRequireOrganizationRoleLayoutProps> = ({
  rolesWithAccess,
}) => {
  const hasAccess = useDoesUserHaveRole(rolesWithAccess);
  return hasAccess ? <Outlet /> : <NotAuthorizedNotice />;
};
