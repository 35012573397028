import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { invalidateRecordingPeriodGhgCategoriesQueries } from "./ghg-categories.queries";
import {
  IInputParameterRecordingStructureIdentity,
  IRecordingPeriodGhgCategoryIdentity,
} from "@netcero/netcero-common";
import {
  IRecordingPeriodGhgCategoryContext,
  IRecordingPeriodGhgCategoryExclude,
  IRecordingPeriodGhgCategoryMateriality,
} from "@netcero/netcero-core-api-client";

export type IGhgCategoryMutationCommonProps = IRecordingPeriodGhgCategoryIdentity &
  IInputParameterRecordingStructureIdentity;

interface IGhgCategoryResponsiblePersonMutation extends IGhgCategoryMutationCommonProps {
  responsiblePersonId?: string;
}

export const useUpdateGhgCategoryResponsiblePersonMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      responsiblePersonId,
    }: IGhgCategoryResponsiblePersonMutation) =>
      CoreApiService.RecordingPeriodGhgCategoriesApi.updateRecordingPeriodGhgCategoryResponsiblePerson(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        { responsibleUserId: responsiblePersonId },
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateRecordingPeriodGhgCategoriesQueries(queryClient, variables),
  });
};

interface IGhgCategoryContributingUsersMutation extends IGhgCategoryMutationCommonProps {
  contributingUserIds: string[];
}

export const useUpdateGhgCategoryContributingUsersMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      contributingUserIds,
    }: IGhgCategoryContributingUsersMutation) =>
      CoreApiService.RecordingPeriodGhgCategoriesApi.updateRecordingPeriodGhgCategoryContributingUsers(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        { contributingUserIds: contributingUserIds },
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateRecordingPeriodGhgCategoriesQueries(queryClient, variables),
  });
};

interface IUpdateGhgCategoryStateMutationData extends IGhgCategoryMutationCommonProps {
  context: IRecordingPeriodGhgCategoryContext;
}

export const useSubmitGhgCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      context,
    }: IUpdateGhgCategoryStateMutationData) =>
      CoreApiService.RecordingPeriodGhgCategoriesApi.submitRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        context,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables) =>
      invalidateRecordingPeriodGhgCategoriesQueries(queryClient, variables),
  });
};

export const useApproveGhgCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
    }: IGhgCategoryMutationCommonProps) =>
      CoreApiService.RecordingPeriodGhgCategoriesApi.approveRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables) =>
      invalidateRecordingPeriodGhgCategoriesQueries(queryClient, variables),
  });
};

export const useRejectGhgCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      context,
    }: IUpdateGhgCategoryStateMutationData) =>
      CoreApiService.RecordingPeriodGhgCategoriesApi.rejectRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        context,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables) =>
      invalidateRecordingPeriodGhgCategoriesQueries(queryClient, variables),
  });
};

export const useResetGhgCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
    }: IUpdateGhgCategoryStateMutationData) =>
      CoreApiService.RecordingPeriodGhgCategoriesApi.resetRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables) =>
      invalidateRecordingPeriodGhgCategoriesQueries(queryClient, variables),
  });
};

interface IExcludeGhgCategoryStateMutationData extends IGhgCategoryMutationCommonProps {
  exclude: IRecordingPeriodGhgCategoryExclude;
}

export const useExcludeGhgCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      exclude,
    }: IExcludeGhgCategoryStateMutationData) =>
      CoreApiService.RecordingPeriodGhgCategoriesApi.excludeRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        { exclude },
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables) =>
      invalidateRecordingPeriodGhgCategoriesQueries(queryClient, variables),
  });
};

export const useIncludeGhgCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
    }: IGhgCategoryMutationCommonProps) =>
      CoreApiService.RecordingPeriodGhgCategoriesApi.includeRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables) =>
      invalidateRecordingPeriodGhgCategoriesQueries(queryClient, variables),
  });
};

export interface IUpdateGhgCategoryMaterialityMutationData extends IGhgCategoryMutationCommonProps {
  materiality: IRecordingPeriodGhgCategoryMateriality | undefined;
}

export const useUpdateGhgCategoryMaterialityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      materiality,
    }: IUpdateGhgCategoryMaterialityMutationData) =>
      CoreApiService.RecordingPeriodGhgCategoriesApi.updateRecordingPeriodGhgCategoryMateriality(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        { materiality },
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables) =>
      invalidateRecordingPeriodGhgCategoriesQueries(queryClient, variables),
  });
};
