import { FC, useMemo, useRef, useState } from "react";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { DataEntryObjectsAppUtilities } from "../data-entry-objects-app.utilities";
import { Chip, Menu, Select } from "@mui/material";
import { DeoTreePicker } from "./deo-tree-picker.component";
import { SelectProps } from "@mui/material/Select";
import { IRecordingPeriodIdentity, RecursiveUtilities } from "@netcero/netcero-common";
import { useHasUserDataEntryObjectAccess } from "../../authorization/hooks/has-user-data-entry-object-access.hook";

interface IDeoSelectProps extends Pick<SelectProps, "label" | "error" | "disabled" | "fullWidth"> {
  selectedDeoId: string | null;
  selectableDeoIds?: string[];
  onChange: (selectedDeoId: string) => void;
  rootDeo: IDataEntryObject;
}

export const DeoSelect: FC<IDeoSelectProps> = ({
  selectedDeoId,
  selectableDeoIds,
  onChange,
  rootDeo,
  ...selectProps
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const selectRef = useRef<HTMLSelectElement>(null);

  const selectedDeo = useMemo(
    () =>
      selectedDeoId
        ? DataEntryObjectsAppUtilities.findDataEntryObjectById(rootDeo, selectedDeoId)
        : null,
    [rootDeo, selectedDeoId],
  );

  return (
    <>
      {/* Menu */}
      <Menu open={showMenu} onClose={() => setShowMenu(false)} anchorEl={selectRef.current}>
        <DeoTreePicker
          selectedDeoId={selectedDeoId}
          selectableDeoIds={selectableDeoIds}
          onChange={(deoId) => {
            onChange(deoId);
            setShowMenu(false);
          }}
          rootDeo={rootDeo}
        />
      </Menu>

      {/* Input */}
      <Select
        ref={selectRef}
        open={false}
        onClick={() => setShowMenu(true)}
        value={selectedDeoId ?? ""}
        renderValue={() => <Chip label={selectedDeo?.name} />}
        {...selectProps}
      />
    </>
  );
};

interface IDeoSelectWithAuthorizationProps extends IDeoSelectProps {
  recordingPeriodIdentity: IRecordingPeriodIdentity;
}

export const DeoSelectWithAuthorization: FC<IDeoSelectWithAuthorizationProps> = ({
  recordingPeriodIdentity,
  ...selectProps
}) => {
  const hasUserAccessToDeo = useHasUserDataEntryObjectAccess(recordingPeriodIdentity);
  const accessibleDeoIds = useMemo(
    () =>
      RecursiveUtilities.flattenRecursiveStructureDown(selectProps.rootDeo)
        .map((deo) => deo.id)
        .filter((id) => hasUserAccessToDeo(id)),
    [hasUserAccessToDeo, selectProps.rootDeo],
  );

  return <DeoSelect selectableDeoIds={accessibleDeoIds} {...selectProps} />;
};
