import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { ICommentLinkMetadata, ICommentRelations } from "@netcero/netcero-core-api-client";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { IInputParameterWithDisclosureRequirementType } from "../context/deo-comments-and-audit-log.context";
import { useTranslateOptionalContent } from "../../content-translation/hooks/translate-content.hook";
import { SidebarCommentsAndAuditLogPaper } from "./sidebar-comments-and-audit-log-paper.component";

interface IDeoIpCommentsAndAuditLogPaperProps {
  organizationId: string;
  relations: ICommentRelations;
  linkMetadata: ICommentLinkMetadata;
  disclosureRequirementInputParameter: IInputParameterWithDisclosureRequirementType | null;
  open: boolean;
  onClose: VoidFunction;
}

export const DeoIpCommentsAndChangelogPaper: FC<IDeoIpCommentsAndAuditLogPaperProps> = ({
  organizationId,
  relations,
  linkMetadata,
  disclosureRequirementInputParameter,
  open,
  onClose,
}) => {
  const translateOptionalContent = useTranslateOptionalContent();
  const { disclosureRequirementName, inputParameterName } = useMemo(() => {
    if (disclosureRequirementInputParameter) {
      return {
        disclosureRequirementName: translateOptionalContent(
          disclosureRequirementInputParameter.disclosureRequirement?.name ?? undefined,
        ),
        inputParameterName: translateOptionalContent(
          disclosureRequirementInputParameter.inputParameter?.name ?? undefined,
        ),
      };
    }
    return { disclosureRequirementName: null, inputParameterName: null };
  }, [disclosureRequirementInputParameter, translateOptionalContent]);

  return (
    <SidebarCommentsAndAuditLogPaper
      open={open}
      organizationId={organizationId}
      relations={relations}
      linkMetadata={linkMetadata}
      topBarContent={
        <Box sx={{ m: 1.5 }}>
          {disclosureRequirementName && (
            <LineClampTypographyWithTooltip
              variant="subtitle2"
              flexGrow={1}
              maxLines={1}
              color="grey"
            >
              {disclosureRequirementName}
            </LineClampTypographyWithTooltip>
          )}
          <LineClampTypographyWithTooltip variant="subtitle2" flexGrow={1} maxLines={1}>
            {inputParameterName}
          </LineClampTypographyWithTooltip>
        </Box>
      }
      onClose={onClose}
    />
  );
};
