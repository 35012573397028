import { IStateMachineTransition } from "./state-machine.public-types";

export class StateMachineUtilities {
  public static isAnyOfTransitions<
    T extends string,
    AllowedTransitions extends IStateMachineTransition<T>,
  >(
    transition: IStateMachineTransition<T>,
    ...transitions: IStateMachineTransition<T>[]
  ): transition is AllowedTransitions {
    return transitions.some(
      (currentTransition) =>
        currentTransition.from === transition.from && currentTransition.to === transition.to,
    );
  }

  public static isTransitionOneOf<E extends string, R extends E>(
    transition: E | null,
    ...transitions: E[]
  ): transition is R {
    if (transition === null) {
      return false;
    }

    return transitions.includes(transition);
  }

  public static getStateTransition<Status extends string, StateTransitions extends string>(
    from: Status,
    to: Status,
    definitions: Record<StateTransitions, IStateMachineTransition<Status>>,
  ): StateTransitions | null {
    for (const [transition, definition] of Object.entries(definitions)) {
      const typedDefinition = definition as IStateMachineTransition<Status>;

      if (typedDefinition.from === from && typedDefinition.to === to) {
        return transition as StateTransitions;
      }
    }
    return null;
  }
}
