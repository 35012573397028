import { forwardRef } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import { WarningIcon } from "../constants/tabler-icon.constants";

type IAppWarningIconProps = SvgIconProps<typeof WarningIcon>;

export const AppWarningIcon = forwardRef<HTMLElement, IAppWarningIconProps>(function AppWarningIcon(
  { sx, ...props },
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      color="warning"
      component={WarningIcon}
      sx={{ fill: "transparent", ...sx }}
      {...props}
    />
  );
});
