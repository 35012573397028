import {
  IGhgProtocolActivityUrlData,
  IOrganizationUrlData,
  IRecordingPeriodDataEntryObjectInputParameterUrlData,
  IRecordingPeriodDmaUrlData,
  IRecordingPeriodUrlData,
  IRecordingStructureGhgCategoryUrlData,
  IRecordingStructurePageUrlData,
} from "./app-url.types";
import {
  DMA_CATEGORY_QUERY_PARAM,
  EDIT_FINANCIAL_EFFECT_QUERY_PARAM,
  EDIT_MATERIAL_IMPACT_QUERY_PARAM,
  ESRS_TOPIC_QUERY_PARAM,
  SECTION_QUERY_PARAM,
} from "./app-url.constants";

export class AppUrlUtilities {
  public static readonly VIEWED_SCOPE_QUERY_PARAM = "viewedGhgScope";
  public static readonly VIEWED_CATEGORY_QUERY_PARAM = "viewedGhgCategory";
  public static readonly INITIALLY_VIEWED_ACTIVITY_QUERY_PARAM = "initiallyViewedActivityId";

  public static getUrlForOrganization({ origin, organizationId }: IOrganizationUrlData): URL {
    return new URL(`${origin}/organizations/${organizationId}`);
  }

  public static getUrlForRecordingPeriod({
    recordingPeriodId,
    ...orgData
  }: IRecordingPeriodUrlData): URL {
    return new URL(
      `${AppUrlUtilities.getUrlForOrganization(orgData)}/recording-periods/${recordingPeriodId}`,
    );
  }

  public static getRecordingStructurePageUrl({
    recordingStructureId,
    recordingStructureType,
    ...recordingPeriodData
  }: IRecordingStructurePageUrlData) {
    return `${AppUrlUtilities.getUrlForRecordingPeriod(
      recordingPeriodData,
    )}/${recordingStructureType}/recording-structures/${recordingStructureId}`;
  }

  public static getUrlForRecordingPeriodDma({
    esrsTopicId,
    dmaCategoryId,
    editMaterialImpactId,
    editFinancialEffectId,
    ...recordingPeriodData
  }: IRecordingPeriodDmaUrlData): URL {
    const url = new URL(
      `${AppUrlUtilities.getUrlForRecordingPeriod(
        recordingPeriodData,
      )}/double-materiality-assessment`,
    );

    if (esrsTopicId) {
      url.searchParams.set(ESRS_TOPIC_QUERY_PARAM, esrsTopicId);
    }
    if (dmaCategoryId) {
      url.searchParams.set(DMA_CATEGORY_QUERY_PARAM, dmaCategoryId);
    }
    if (editMaterialImpactId) {
      url.searchParams.set(EDIT_MATERIAL_IMPACT_QUERY_PARAM, editMaterialImpactId);
    }
    if (editFinancialEffectId) {
      url.searchParams.set(EDIT_FINANCIAL_EFFECT_QUERY_PARAM, editFinancialEffectId);
    }

    return url;
  }

  public static getUrlForDataEntryObjectInputParameter({
    dataEntryObjectId,
    inputParameterId,
    sectionId,
    recordingStructureId,
    recordingStructureType,
    ...recordingPeriodData
  }: IRecordingPeriodDataEntryObjectInputParameterUrlData): URL {
    const url = new URL(
      `${AppUrlUtilities.getUrlForRecordingPeriod(
        recordingPeriodData,
      )}/${recordingStructureType}/recording-structures/${recordingStructureId}/data-entry-objects/${dataEntryObjectId}`,
    );
    if (sectionId) {
      url.searchParams.set(SECTION_QUERY_PARAM, sectionId);
      if (inputParameterId) {
        url.hash = `#${inputParameterId}`;
      }
    }

    return url;
  }

  public static getGhgProtocolPageUrl({
    viewedScope,
    viewedGhgCategoryId,
    ...recordingStructureData
  }: IRecordingStructureGhgCategoryUrlData): URL {
    const url = new URL(
      AppUrlUtilities.getRecordingStructurePageUrl({
        recordingStructureType: "ghg",
        ...recordingStructureData,
      }),
    );
    if (viewedScope) {
      url.searchParams.append(AppUrlUtilities.VIEWED_SCOPE_QUERY_PARAM, viewedScope);
    }
    if (viewedGhgCategoryId) {
      url.searchParams.append(AppUrlUtilities.VIEWED_CATEGORY_QUERY_PARAM, viewedGhgCategoryId);
    }
    return url;
  }

  public static getUrlForGhgProtocolActivity({
    viewedActivityId,
    ...base
  }: IGhgProtocolActivityUrlData) {
    const url = new URL(AppUrlUtilities.getGhgProtocolPageUrl(base));
    url.searchParams.append(
      AppUrlUtilities.INITIALLY_VIEWED_ACTIVITY_QUERY_PARAM,
      viewedActivityId,
    );
    return url;
  }
}
