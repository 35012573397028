import { IActivityValueDataQuality } from "@netcero/netcero-core-api-client";
import {
  CalculatedIcon,
  EstimatedIcon,
  MeasuredIcon,
} from "../common/constants/tabler-icon.constants";
import { FC } from "react";

export const ORDERED_ACTIVITY_VALUE_DATA_QUALITY = [
  IActivityValueDataQuality.Measured,
  IActivityValueDataQuality.Calculated,
  IActivityValueDataQuality.Estimated,
] as const;

export const ACTIVITY_VALUE_DATA_QUALITY_ICON: Record<IActivityValueDataQuality, FC> = {
  [IActivityValueDataQuality.Measured]: MeasuredIcon,
  [IActivityValueDataQuality.Calculated]: CalculatedIcon,
  [IActivityValueDataQuality.Estimated]: EstimatedIcon,
};
