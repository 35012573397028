import { TableCell, TableRow, Typography } from "@mui/material";
import { HOVER_BACKGROUND_COLOR } from "../../../theme/theme";
import { MaterialityChip } from "../common/materiality-chip.component";
import { IroAreasComponent } from "../common/iro-areas.component";
import { IROStateIndicatorInline } from "../common/iro-state-inline-indicator.component";
import {
  IDMAFinancialEffectWithCalculatedValues,
  IDMAMaterialImpactWithCalculatedValues,
} from "@netcero/netcero-core-api-client";
import React from "react";
import { UserAvatarWithQuery } from "../../user/components/user-avatar.component";
import { useTranslation } from "react-i18next";

type IEffectOrImpact =
  | IDMAMaterialImpactWithCalculatedValues
  | IDMAFinancialEffectWithCalculatedValues;

interface IBaseIroTableRowProps<T extends IEffectOrImpact> {
  effectOrImpact: T;
  onEdit: (effectOrImpact: T) => void;
  organizationId: string;
}

export const DmaIroTableRow = <T extends IEffectOrImpact>({
  effectOrImpact,
  onEdit,
  organizationId,
}: IBaseIroTableRowProps<T>) => {
  const { t } = useTranslation("double_materiality_assessment_sub_category_component");

  return (
    <TableRow
      onClick={() => onEdit(effectOrImpact)}
      sx={{
        cursor: "pointer",
        ":hover": { bgcolor: HOVER_BACKGROUND_COLOR },
      }}
    >
      <TableCell sx={{ width: "100%" /* grow table cell */ }}>
        <Typography variant="body1" sx={{ display: "inline-block" }}>
          {effectOrImpact.title}
        </Typography>
      </TableCell>
      <TableCell>
        <UserAvatarWithQuery
          organizationId={organizationId}
          userId={effectOrImpact.responsibleUserId}
          disabledOverlay
          withTooltip
          noUserSelectedTooltip={t("no_responsible_user_tooltip")}
        />
      </TableCell>
      <TableCell align="center">
        <MaterialityChip
          variant="outlined"
          materiality={effectOrImpact.material}
          materialityDegree={effectOrImpact.materialityDegree}
        />
      </TableCell>
      <TableCell>
        <IroAreasComponent typeOfIRO={effectOrImpact} />
      </TableCell>
      <TableCell align="left">
        <IROStateIndicatorInline state={effectOrImpact.state} />
      </TableCell>
    </TableRow>
  );
};
