import { FC, ReactNode } from "react";
import { Box, MenuItem, Tooltip } from "@mui/material";
import { ArrowRightIcon } from "../constants/tabler-icon.constants";

export interface IStatusChangeMenuItem {
  id: string;
  tooltip?: ReactNode;
  onClick: VoidFunction;
  disabled: boolean;
  title: ReactNode;
  newStatusIndicator: ReactNode;
}

interface IStatusChangeMenuProps {
  items: IStatusChangeMenuItem[];
}

export const StatusChangeMenuItems: FC<IStatusChangeMenuProps> = ({ items }) => {
  return (
    <>
      {items.map(({ id, tooltip, onClick, disabled, title, newStatusIndicator }) => (
        <Tooltip key={id} title={tooltip} placement="right">
          <span>
            <MenuItem onClick={onClick} disabled={disabled} sx={{ gap: 2 }}>
              {title}
              <Box flex={1} />
              <ArrowRightIcon />
              <Box display="flex" justifyContent="end">
                {newStatusIndicator}
              </Box>
            </MenuItem>
          </span>
        </Tooltip>
      ))}
    </>
  );
};
