import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDMACategoryInformation,
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IDMAFinancialEffect,
  IDMAMaterialImpact,
} from "@netcero/netcero-core-api-client";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TreeGroupContainer } from "../common/components/tree-group-container.component";
import { AddIcon, EditIcon, IconSize } from "../common/constants/tabler-icon.constants";
import { DMACategoryStatusIndicator } from "./common/dma-category-status-indicator.component";
import { MaterialityChip } from "./common/materiality-chip.component";
import { DMACategoryUtilities } from "./dialogs/dma-categories/dma-category.utilities";
import { useRenderDMACategoryName } from "./hooks/render-dma-category-name.hook";
import {
  ApiDataCleanupUtilities,
  DMACategoriesUtilities,
  OptionalPropertiesToNullRecursive,
  ROLE_ACCESS,
} from "@netcero/netcero-common";
import { DMACategoryActions } from "./dma-category-actions.component";
import { Controller, useForm } from "react-hook-form";
import { ClickToEditInput } from "../inputs/components/click-to-edit-input.component";
import { useClickToEditInputState } from "../inputs/hooks/click-to-edit-input-state.hook";
import { useUpdateDmaCategoryInformationMutation } from "./mutations/dma-category.mutations";
import { ErrorTextComponent } from "../common/components/error-text.component";
import { callOnEnter } from "../common/functions/call-on-enter.function";
import { useESRSTopicContext } from "./esrs-topic.context";
import { CancelButton, SaveButton } from "../common/components/buttons.components";
import { OnlyShowToUsersWithRole } from "../authentication/components/only-show-to-users-with-role.component";
import { DmaIroTableRow } from "./components/dma-iro-table-row.component";

const MAX_DEPTH = 2;

type IDmaCategoryInformationInternal = OptionalPropertiesToNullRecursive<IDMACategoryInformation>;

function getFormDefaultValues(data: IDMACategoryInformation): IDmaCategoryInformationInternal {
  return {
    summary: data.summary ?? null,
  };
}

interface IDMACategoryComponentProps {
  category: IDMACategoryWithEffectsAndChildren;
  showMaterialImpacts: boolean;
  showFinancialEffects: boolean;
  level?: number;
  showEmpty?: boolean;
  onCreateMaterialImpact: (category: IDMACategoryWithEffectsAndChildren) => void;
  onCreateFinancialEffect: (category: IDMACategoryWithEffectsAndChildren) => void;
  onEditMaterialImpact: (materialImpact: IDMAMaterialImpact) => void;
  onEditFinancialEffect: (financialEffect: IDMAFinancialEffect) => void;
  onEditDMACategory: (category: IDMACategoryWithEffectsAndChildren) => void;
  onAddChildDMACategory: (parentCategory: IDMACategoryWithEffectsAndChildren) => void;
  onOptOutOfDMACategory: (category: IDMACategoryWithEffectsAndChildren) => void;
  onEditOptOutOfDMACategory: (category: IDMACategoryWithEffectsAndChildren) => void;
  onOptBackIntoDMACategory: (category: IDMACategoryWithEffectsAndChildren) => void;
  onClickFeedback: (category: IDMACategoryWithEffectsAndChildren) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

/**
 * Component for displaying and editing DMA Categories
 * @param category The category to display
 * @param showMaterial Impacts Whether to show material impacts
 * @param showFinancialEffects Whether to show financial effects
 * @param level The level of the category in the tree (1 based)
 * @param showEmpty Whether to show empty categories
 * Default: true
 * @param onCreateMaterialImpact Callback for creating a material impact
 * @param onCreateFinancialEffect Callback for creating a financial effect
 * @param onEditMaterialImpact Callback for editing a material impact
 * @param onEditFinancialEffect Callback for editing a financial effect
 * @param onEditDMACategory Callback for editing the category
 * @param onAddChildDMACategory Callback for adding a child category
 * @param onOptOutOfDMACategory Callback for opting out of the category
 * @param onEditOptOutOfDMACategory Callback for editing the opt-out of the category
 * @param onOptBackIntoDMACategory Callback for opting back into the category
 * @param onClickFeedback Callback for opening the feedback dialog
 */
export const DMACategoryComponent: FC<IDMACategoryComponentProps> = ({
  category,
  showMaterialImpacts,
  showFinancialEffects,
  level = 1,
  showEmpty = true,
  onEditFinancialEffect,
  onCreateFinancialEffect,
  onEditMaterialImpact,
  onCreateMaterialImpact,
  onEditDMACategory,
  onAddChildDMACategory,
  onOptOutOfDMACategory,
  onEditOptOutOfDMACategory,
  onOptBackIntoDMACategory,
  onClickFeedback,
  setOpen,
  open,
}) => {
  const { t } = useTranslation("double_materiality_assessment_sub_category_component");
  const { organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId } =
    useESRSTopicContext();

  const hasIROs = useMemo(
    () => DMACategoryUtilities.doesCategoryOrChildrenContainIROs(category),
    [category],
  );

  const completeCategoryState = useMemo(() => {
    return DMACategoryUtilities.getCategoryTotalState(category);
  }, [category]);

  const showImpactsAndEffects = useMemo(() => !category.optOut, [category.optOut]);

  const showOptOutButton = useMemo(
    () =>
      !category.optOut && !hasIROs && completeCategoryState !== IDMACategoryState.Verified && !open,
    [category.optOut, hasIROs, completeCategoryState, open],
  );

  const updateDmaCategoryInformationMutation = useUpdateDmaCategoryInformationMutation();

  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<IDmaCategoryInformationInternal>({
    defaultValues: getFormDefaultValues(category),
  });
  useEffect(() => {
    reset(getFormDefaultValues(category));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const editSummaryState = useClickToEditInputState();

  const handleAddSummary = useCallback(() => {
    setValue("summary", "");
    editSummaryState.startEditing();
  }, [editSummaryState, setValue]);
  const currentSummaryValue = watch("summary");

  const handleResetCategoryInformation = useCallback(() => {
    reset(getFormDefaultValues(category));
    editSummaryState.stopEditing();
  }, [reset, category, editSummaryState]);

  const handleSaveCategoryInformation = useCallback(
    async (dmaCategoryInformation: IDmaCategoryInformationInternal) => {
      await updateDmaCategoryInformationMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId: category.id,
        payload: {
          summary: ApiDataCleanupUtilities.cleanUpString(dmaCategoryInformation.summary),
        },
      });
      editSummaryState.stopEditing();
    },
    [
      category.id,
      dataEntryObjectId,
      editSummaryState,
      esrsTopicId,
      organizationId,
      recordingPeriodId,
      updateDmaCategoryInformationMutation,
    ],
  );

  const renderName = useRenderDMACategoryName();

  useEffect(() => {
    setOpen(category.optOut);
  }, [category, setOpen]);

  const canCategoryBeAltered = useMemo(
    () => ![category.materialState, category.financialState].includes(IDMACategoryState.Verified),
    [category],
  );

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center" gap={1} pb={1}>
        <OnlyShowToUsersWithRole
          roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_ALL_IROS}
          placeholder={<Box />} // Spacer works without width due to flex gap
        >
          <DMACategoryStatusIndicator state={completeCategoryState} />
        </OnlyShowToUsersWithRole>
        <Typography variant="h5" component="h2" pr={1}>
          {renderName(category)}
        </Typography>
        <DMACategoryActions
          dmaCategory={category}
          onEdit={() => onEditDMACategory(category)}
          canEdit={canCategoryBeAltered}
          onShowFeedback={() => onClickFeedback(category)}
          onAddSummary={currentSummaryValue === null ? handleAddSummary : undefined}
          onAddChild={level < MAX_DEPTH ? () => onAddChildDMACategory(category) : undefined}
          onOptOut={
            showOptOutButton
              ? () => {
                  onOptOutOfDMACategory(category);
                }
              : undefined
          }
          showEdit={DMACategoriesUtilities.canCategoryBeAltered(category)}
        />
      </Box>

      <Box pl={1.25}>
        <TreeGroupContainer>
          <Box display="flex" flexDirection="column" gap={2}>
            {/* Error Message */}
            {updateDmaCategoryInformationMutation.isError && (
              <ErrorTextComponent error={updateDmaCategoryInformationMutation.error} />
            )}
            {/* Summary */}
            {currentSummaryValue !== null && (
              <Tooltip title={canCategoryBeAltered ? null : t("tooltip_edit_summary_disabled")}>
                <Box display="flex" flexDirection="column" gap={1} width="100%" maxWidth={1200}>
                  <Controller
                    control={control}
                    name="summary"
                    render={({ field }) => (
                      <ClickToEditInput
                        isEditing={editSummaryState.isEditing}
                        onEdit={editSummaryState.startEditing}
                        disabled={
                          updateDmaCategoryInformationMutation.isPending || !canCategoryBeAltered
                        }
                        value={field.value ?? ""}
                        boxProps={{ px: 2, py: 1 }}
                        inputComponent={
                          <TextField
                            variant="outlined"
                            size="small"
                            label={t("label_summary")}
                            {...field}
                            multiline
                            minRows={3}
                            maxRows={5}
                            onKeyDown={callOnEnter(handleSubmit(handleSaveCategoryInformation))}
                            disabled={updateDmaCategoryInformationMutation.isPending}
                          />
                        }
                      />
                    )}
                  />
                  {/* Cancel/Save Buttons for dma category information */}
                  {editSummaryState.isEditing && (
                    <Box display="flex" gap={1}>
                      <CancelButton
                        size="small"
                        onClick={handleResetCategoryInformation}
                        disabled={updateDmaCategoryInformationMutation.isPending}
                      >
                        {t("cancel", { ns: "buttons" })}
                      </CancelButton>
                      <SaveButton
                        size="small"
                        variant="contained"
                        onClick={handleSubmit(handleSaveCategoryInformation)}
                        disabled={updateDmaCategoryInformationMutation.isPending || !isDirty}
                      >
                        {t("save", { ns: "buttons" })}
                      </SaveButton>
                    </Box>
                  )}
                </Box>
              </Tooltip>
            )}
            {/* Opt Out Notice */}
            {!showImpactsAndEffects && (
              <Box display="flex" flexDirection="column" alignItems="start" gap={2}>
                <Typography>{t("category_opted_out_notice")}</Typography>
                <Box display="flex" gap={2}>
                  <Typography fontWeight="bold" mt={0.15}>
                    {t("label_category_opted_out_reason")}
                  </Typography>
                  <Typography mt={0.15}>{category.optOutReason}</Typography>
                  <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CATEGORIES}>
                    <Tooltip title={t("button_edit_opt_out_reason_tooltip")}>
                      <IconButton size="small" onClick={() => onEditOptOutOfDMACategory(category)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </OnlyShowToUsersWithRole>
                </Box>
                <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CATEGORIES}>
                  <Box display="flex" gap={2}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        onOptBackIntoDMACategory(category);
                      }}
                    >
                      {t("button_opt_back_into_category")}
                    </Button>
                    {category.children.length !== 0 && (
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        sx={{ minWidth: 124 }}
                        onClick={() => setOpen(!open)}
                      >
                        {open ? t("show_actions") : t("hide_actions")}
                      </Button>
                    )}
                  </Box>
                </OnlyShowToUsersWithRole>
              </Box>
            )}

            {/* Impacts & Effects */}
            {showImpactsAndEffects && (
              <Collapse in={!open}>
                {/* Material Impacts */}
                {showMaterialImpacts &&
                  (showEmpty || (!showEmpty && category.materialImpacts.length > 0)) && (
                    <Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="h6">{t("material_impacts_header")}</Typography>
                        <OnlyShowToUsersWithRole
                          roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CATEGORIES}
                        >
                          <MaterialityChip
                            materiality={category.materiality.materialityMaterial}
                            materialityDegree={category.materiality.materialityDegreeMaterial}
                          />
                        </OnlyShowToUsersWithRole>
                      </Box>
                      {/* List Impacts */}
                      <Table size="small">
                        <TableBody>
                          {category.materialImpacts.map((materialImpact) => (
                            <DmaIroTableRow
                              key={materialImpact.id}
                              organizationId={organizationId}
                              effectOrImpact={materialImpact}
                              onEdit={onEditMaterialImpact}
                            />
                          ))}
                        </TableBody>
                      </Table>
                      {/* Empty Message */}
                      {category.materialImpacts.length === 0 && (
                        <Typography variant="body2" color="text.secondary" sx={{ px: 1, py: 1 }}>
                          {t("material_impacts_empty_message")}
                        </Typography>
                      )}
                      {/* Create Button */}
                      <OnlyShowToUsersWithRole
                        roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CATEGORIES}
                        placeholder={<Box height={24} />}
                      >
                        <Tooltip title={t("create_material_impact_tooltip")} placement="right">
                          <span>
                            <IconButton
                              color="primary"
                              onClick={() => onCreateMaterialImpact(category)}
                              disabled={category.materialState === IDMACategoryState.Verified}
                            >
                              <AddIcon size={IconSize.Medium} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </OnlyShowToUsersWithRole>
                    </Box>
                  )}

                {/* Financial Effects */}
                {showFinancialEffects &&
                  (showEmpty || (!showEmpty && category.financialEffects.length > 0)) && (
                    <Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="h6">{t("financial_effects_header")}</Typography>
                        <OnlyShowToUsersWithRole
                          roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CATEGORIES}
                        >
                          <MaterialityChip
                            materiality={category.materiality.materialityFinancial}
                            materialityDegree={category.materiality.materialityDegreeFinancial}
                          />
                        </OnlyShowToUsersWithRole>
                      </Box>
                      {/* Effects List */}
                      <Table size="small">
                        <TableBody>
                          {category.financialEffects.map((financialEffect) => (
                            <DmaIroTableRow
                              key={financialEffect.id}
                              organizationId={organizationId}
                              effectOrImpact={financialEffect}
                              onEdit={onEditFinancialEffect}
                            />
                          ))}
                        </TableBody>
                      </Table>
                      {/* Empty Message */}
                      {category.financialEffects.length === 0 && (
                        <Typography variant="body2" color="text.secondary" sx={{ px: 1, py: 1 }}>
                          {t("financial_effects_empty_message")}
                        </Typography>
                      )}
                      {/* Create Button */}
                      <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CATEGORIES}>
                        <Tooltip title={t("create_financial_effect_tooltip")} placement="right">
                          <span>
                            <IconButton
                              color="primary"
                              onClick={() => onCreateFinancialEffect(category)}
                              disabled={category.financialState === IDMACategoryState.Verified}
                            >
                              <AddIcon size={IconSize.Medium} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </OnlyShowToUsersWithRole>
                    </Box>
                  )}
              </Collapse>
            )}

            {/* Display Children */}
            <Box pt={1} pl={2}>
              {category.children.map((childCategory) => (
                <Box key={childCategory.id} mt={1} mb={4}>
                  <DMACategoryComponent
                    category={childCategory}
                    showMaterialImpacts={showMaterialImpacts}
                    showFinancialEffects={showFinancialEffects}
                    level={level + 1}
                    showEmpty={showEmpty}
                    onCreateMaterialImpact={onCreateMaterialImpact}
                    onCreateFinancialEffect={onCreateFinancialEffect}
                    onEditMaterialImpact={onEditMaterialImpact}
                    onEditFinancialEffect={onEditFinancialEffect}
                    onEditDMACategory={onEditDMACategory}
                    onAddChildDMACategory={onAddChildDMACategory}
                    onOptOutOfDMACategory={onOptOutOfDMACategory}
                    onEditOptOutOfDMACategory={onEditOptOutOfDMACategory}
                    onOptBackIntoDMACategory={onOptBackIntoDMACategory}
                    onClickFeedback={onClickFeedback}
                    setOpen={setOpen}
                    open={open}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </TreeGroupContainer>
      </Box>
    </Box>
  );
};
