import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";

export const DialogCancelButton: FC<ButtonProps> = (props) => {
  return <Button variant="text" {...props} />;
};
export const DialogCloseButton = DialogCancelButton;
export const DialogSecondaryActionButton = DialogCancelButton;

export const DialogConfirmButton: FC<ButtonProps> = (props) => {
  return <Button variant="contained" {...props} />;
};
export const DialogSaveButton = DialogConfirmButton;
export const DialogCreateButton = DialogConfirmButton;
export const DialogDeleteButton = DialogConfirmButton;
export const DialogPublishButton = DialogConfirmButton;
export const DialogUploadButton = DialogConfirmButton;
