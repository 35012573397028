import { Dispatch, FC, useCallback } from "react";
import { Box } from "@mui/material";
import { EmissionFactorsDialogFilters } from "./dialog/emission-factor-dialog-filter.component";
import { MapFilterState } from "../../filters/map-state-filters.component";
import { useMap } from "@uidotdev/usehooks";
import { EmissionFactorsDialogTable } from "./dialog/emission-factor-dialog-table.component";
import { EmissionFactorChip } from "./emission-factor-chip.component";

interface IEmissionFactorsDialogTableProps {
  organizationId: string;
  disabledEmissionFactorIds?: string[];
  selectedEmissionFactorIds: string[];
  onChange: Dispatch<string[]>;
  defaultFilters?: MapFilterState;
  disabled?: boolean;
}

export const EmissionFactorsSelectionTableWithFiltersSection: FC<
  IEmissionFactorsDialogTableProps
> = ({
  organizationId,
  disabledEmissionFactorIds,
  selectedEmissionFactorIds,
  onChange,
  defaultFilters,
  disabled,
}) => {
  const filters = useMap(defaultFilters) as unknown as MapFilterState;

  const handleChange = useCallback(
    (emissionFactorId: string, selected: boolean) => {
      onChange(
        selected
          ? [...selectedEmissionFactorIds, emissionFactorId].sort()
          : selectedEmissionFactorIds.filter((id) => id !== emissionFactorId),
      );
    },
    [onChange, selectedEmissionFactorIds],
  );

  return (
    <Box>
      <Box display="flex" flexWrap="wrap" gap={1} py={selectedEmissionFactorIds.length > 0 ? 2 : 0}>
        {selectedEmissionFactorIds.map((emissionFactorId) => (
          <EmissionFactorChip
            key={emissionFactorId}
            organizationId={organizationId}
            emissionFactorId={emissionFactorId}
            onDelete={() => handleChange(emissionFactorId, false)}
            disabled={disabled}
          />
        ))}
      </Box>
      <EmissionFactorsDialogFilters mutableFilters={filters} />
      <EmissionFactorsDialogTable
        organizationId={organizationId}
        disabledEmissionFactorIds={disabledEmissionFactorIds}
        selectedEmissionFactorIds={selectedEmissionFactorIds}
        onChange={handleChange}
        filtersMap={filters}
        disabled={disabled}
      />
    </Box>
  );
};
