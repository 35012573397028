import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useHasOrganizationModuleAccess } from "../../organization-module-access/has-organization-module-access.hook";
import { AppModule, EsrsVsmeStructureType } from "@netcero/netcero-common";
import { EnvironmentUtilities } from "../../common/utilities/environment.utilities";

interface IEsrsVsmePaywallProps {
  organizationId?: string;
  type: EsrsVsmeStructureType;
}

export const EsrsVsmePaywall: FC<PropsWithChildren<IEsrsVsmePaywallProps>> = ({
  children,
  organizationId,
  type,
}) => {
  const hasAccess = useHasOrganizationModuleAccess(
    organizationId ?? "",
    type === "esrs" ? AppModule.ESRS : AppModule.VSME,
  );
  const { t } = useTranslation("esrs_vsme_paywall");

  const moduleName = type.toUpperCase();

  if (!hasAccess) {
    return (
      <Box display="flex" flexDirection="column" pt="7vh">
        <Card
          sx={{
            width: 768,
            maxWidth: "100%",
            mx: "auto",
            border: "none",
            borderRadius: 8,
            p: 1,
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 30px 2px",
          }}
        >
          <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h3">{t("title", { moduleName })}</Typography>
            <Typography variant="body1">{t("content", { moduleName })}</Typography>
            <Button
              variant="contained"
              color="primary"
              href={`mailto:${EnvironmentUtilities.SUPPORT_EMAIL}`}
              sx={{ mx: "auto", mt: 2 }}
            >
              {t("cta")}
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return children;
};
