/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IActivityValue } from "../models";
// @ts-ignore
import { IBaseActivityValueData } from "../models";
// @ts-ignore
import { ICreateActivityValue404Response } from "../models";
// @ts-ignore
import { ICreateMultipleActivityValuesRequest } from "../models";
// @ts-ignore
import { ICreateMultipleActivityValuesResponse } from "../models";
// @ts-ignore
import { IDeleteActivityValue404Response } from "../models";
// @ts-ignore
import { IGetActivityValuesForActivity404Response } from "../models";
// @ts-ignore
import { IGetActivityValuesForActivityResponse } from "../models";
// @ts-ignore
import { IGhgCategoriesIdentifier } from "../models";
// @ts-ignore
import { IUpdateActivityValue404Response } from "../models";
// @ts-ignore
import { IUpdateSource403Response } from "../models";
/**
 * ActivityValuesApi - axios parameter creator
 * @export
 */
export const ActivityValuesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This endpoint creates a new activity value.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IBaseActivityValueData} [iBaseActivityValueData] Create Activity Value Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createActivityValue: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iBaseActivityValueData?: IBaseActivityValueData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("createActivityValue", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("createActivityValue", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("createActivityValue", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("createActivityValue", "activityId", activityId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}/values`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseActivityValueData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint creates multiple new activity values.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {ICreateMultipleActivityValuesRequest} [iCreateMultipleActivityValuesRequest] Create multiple Activity Values Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMultipleActivityValues: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iCreateMultipleActivityValuesRequest?: ICreateMultipleActivityValuesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("createMultipleActivityValues", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("createMultipleActivityValues", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("createMultipleActivityValues", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("createMultipleActivityValues", "activityId", activityId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}/values-collection`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iCreateMultipleActivityValuesRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint deletes a given activity value.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {string} activityValueId The id of the activity value to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteActivityValue: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      activityValueId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("deleteActivityValue", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("deleteActivityValue", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("deleteActivityValue", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("deleteActivityValue", "activityId", activityId);
      // verify required parameter 'activityValueId' is not null or undefined
      assertParamExists("deleteActivityValue", "activityValueId", activityValueId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}/values/{activityValueId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)))
          .replace(`{${"activityValueId"}}`, encodeURIComponent(String(activityValueId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint retrieves all activity values for a given activity.
     * @summary This endpoint retrieves all activity values for a given activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityValuesForActivity: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getActivityValuesForActivity", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("getActivityValuesForActivity", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("getActivityValuesForActivity", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("getActivityValuesForActivity", "activityId", activityId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}/values`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint updates the activity value with the given ID.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {string} activityValueId The id of the activity value to use.
     * @param {IBaseActivityValueData} [iBaseActivityValueData] Update Activity Value Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivityValue: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      activityValueId: string,
      iBaseActivityValueData?: IBaseActivityValueData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateActivityValue", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("updateActivityValue", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("updateActivityValue", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("updateActivityValue", "activityId", activityId);
      // verify required parameter 'activityValueId' is not null or undefined
      assertParamExists("updateActivityValue", "activityValueId", activityValueId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}/values/{activityValueId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)))
          .replace(`{${"activityValueId"}}`, encodeURIComponent(String(activityValueId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseActivityValueData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ActivityValuesApi - functional programming interface
 * @export
 */
export const ActivityValuesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ActivityValuesApiAxiosParamCreator(configuration);
  return {
    /**
     * This endpoint creates a new activity value.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IBaseActivityValueData} [iBaseActivityValueData] Create Activity Value Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createActivityValue(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iBaseActivityValueData?: IBaseActivityValueData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IActivityValue>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createActivityValue(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        iBaseActivityValueData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint creates multiple new activity values.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {ICreateMultipleActivityValuesRequest} [iCreateMultipleActivityValuesRequest] Create multiple Activity Values Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMultipleActivityValues(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iCreateMultipleActivityValuesRequest?: ICreateMultipleActivityValuesRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ICreateMultipleActivityValuesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMultipleActivityValues(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        iCreateMultipleActivityValuesRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint deletes a given activity value.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {string} activityValueId The id of the activity value to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteActivityValue(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      activityValueId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivityValue(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        activityValueId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint retrieves all activity values for a given activity.
     * @summary This endpoint retrieves all activity values for a given activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActivityValuesForActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IGetActivityValuesForActivityResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityValuesForActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint updates the activity value with the given ID.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {string} activityValueId The id of the activity value to use.
     * @param {IBaseActivityValueData} [iBaseActivityValueData] Update Activity Value Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateActivityValue(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      activityValueId: string,
      iBaseActivityValueData?: IBaseActivityValueData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IActivityValue>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityValue(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        activityValueId,
        iBaseActivityValueData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ActivityValuesApi - factory interface
 * @export
 */
export const ActivityValuesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ActivityValuesApiFp(configuration);
  return {
    /**
     * This endpoint creates a new activity value.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IBaseActivityValueData} [iBaseActivityValueData] Create Activity Value Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createActivityValue(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iBaseActivityValueData?: IBaseActivityValueData,
      options?: any,
    ): AxiosPromise<IActivityValue> {
      return localVarFp
        .createActivityValue(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          activityId,
          iBaseActivityValueData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint creates multiple new activity values.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {ICreateMultipleActivityValuesRequest} [iCreateMultipleActivityValuesRequest] Create multiple Activity Values Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMultipleActivityValues(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iCreateMultipleActivityValuesRequest?: ICreateMultipleActivityValuesRequest,
      options?: any,
    ): AxiosPromise<ICreateMultipleActivityValuesResponse> {
      return localVarFp
        .createMultipleActivityValues(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          activityId,
          iCreateMultipleActivityValuesRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint deletes a given activity value.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {string} activityValueId The id of the activity value to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteActivityValue(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      activityValueId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteActivityValue(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          activityId,
          activityValueId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint retrieves all activity values for a given activity.
     * @summary This endpoint retrieves all activity values for a given activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityValuesForActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options?: any,
    ): AxiosPromise<IGetActivityValuesForActivityResponse> {
      return localVarFp
        .getActivityValuesForActivity(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          activityId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint updates the activity value with the given ID.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {string} activityValueId The id of the activity value to use.
     * @param {IBaseActivityValueData} [iBaseActivityValueData] Update Activity Value Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivityValue(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      activityValueId: string,
      iBaseActivityValueData?: IBaseActivityValueData,
      options?: any,
    ): AxiosPromise<IActivityValue> {
      return localVarFp
        .updateActivityValue(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          activityId,
          activityValueId,
          iBaseActivityValueData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ActivityValuesApi - interface
 * @export
 * @interface ActivityValuesApi
 */
export interface ActivityValuesApiInterface {
  /**
   * This endpoint creates a new activity value.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {IBaseActivityValueData} [iBaseActivityValueData] Create Activity Value Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityValuesApiInterface
   */
  createActivityValue(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    iBaseActivityValueData?: IBaseActivityValueData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IActivityValue>;

  /**
   * This endpoint creates multiple new activity values.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {ICreateMultipleActivityValuesRequest} [iCreateMultipleActivityValuesRequest] Create multiple Activity Values Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityValuesApiInterface
   */
  createMultipleActivityValues(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    iCreateMultipleActivityValuesRequest?: ICreateMultipleActivityValuesRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ICreateMultipleActivityValuesResponse>;

  /**
   * This endpoint deletes a given activity value.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {string} activityValueId The id of the activity value to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityValuesApiInterface
   */
  deleteActivityValue(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    activityValueId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * This endpoint retrieves all activity values for a given activity.
   * @summary This endpoint retrieves all activity values for a given activity.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityValuesApiInterface
   */
  getActivityValuesForActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetActivityValuesForActivityResponse>;

  /**
   * This endpoint updates the activity value with the given ID.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {string} activityValueId The id of the activity value to use.
   * @param {IBaseActivityValueData} [iBaseActivityValueData] Update Activity Value Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityValuesApiInterface
   */
  updateActivityValue(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    activityValueId: string,
    iBaseActivityValueData?: IBaseActivityValueData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IActivityValue>;
}

/**
 * ActivityValuesApi - object-oriented interface
 * @export
 * @class ActivityValuesApi
 * @extends {BaseAPI}
 */
export class ActivityValuesApi extends BaseAPI implements ActivityValuesApiInterface {
  /**
   * This endpoint creates a new activity value.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {IBaseActivityValueData} [iBaseActivityValueData] Create Activity Value Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityValuesApi
   */
  public createActivityValue(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    iBaseActivityValueData?: IBaseActivityValueData,
    options?: AxiosRequestConfig,
  ) {
    return ActivityValuesApiFp(this.configuration)
      .createActivityValue(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        iBaseActivityValueData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint creates multiple new activity values.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {ICreateMultipleActivityValuesRequest} [iCreateMultipleActivityValuesRequest] Create multiple Activity Values Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityValuesApi
   */
  public createMultipleActivityValues(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    iCreateMultipleActivityValuesRequest?: ICreateMultipleActivityValuesRequest,
    options?: AxiosRequestConfig,
  ) {
    return ActivityValuesApiFp(this.configuration)
      .createMultipleActivityValues(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        iCreateMultipleActivityValuesRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint deletes a given activity value.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {string} activityValueId The id of the activity value to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityValuesApi
   */
  public deleteActivityValue(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    activityValueId: string,
    options?: AxiosRequestConfig,
  ) {
    return ActivityValuesApiFp(this.configuration)
      .deleteActivityValue(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        activityValueId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint retrieves all activity values for a given activity.
   * @summary This endpoint retrieves all activity values for a given activity.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityValuesApi
   */
  public getActivityValuesForActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    options?: AxiosRequestConfig,
  ) {
    return ActivityValuesApiFp(this.configuration)
      .getActivityValuesForActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint updates the activity value with the given ID.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {string} activityValueId The id of the activity value to use.
   * @param {IBaseActivityValueData} [iBaseActivityValueData] Update Activity Value Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityValuesApi
   */
  public updateActivityValue(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    activityValueId: string,
    iBaseActivityValueData?: IBaseActivityValueData,
    options?: AxiosRequestConfig,
  ) {
    return ActivityValuesApiFp(this.configuration)
      .updateActivityValue(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        activityValueId,
        iBaseActivityValueData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
