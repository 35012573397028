import { Box } from "@mui/material";
import { FC, ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IFilterConfig } from "./filter.type";
import { ClearButton } from "../common/components/buttons.components";
import { SearchParamsFilters } from "./search-params-filters.component";

interface IFilterBarProps {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams?: URLSearchParams) => void;
  filters: IFilterConfig[];
  startSectionElement?: ReactNode | string;
}

export function getFilterConfigGenerator(t: (key: string) => string) {
  return (filter: string, config: Omit<IFilterConfig, "name" | "label">): IFilterConfig => ({
    name: filter,
    label: t(filter),
    ...config,
  });
}

export const FilterBar: FC<IFilterBarProps> = ({
  filters,
  startSectionElement,
  searchParams,
  setSearchParams,
}) => {
  const { t } = useTranslation("filter_bar");

  const handleClear = useCallback(() => {
    const params = new URLSearchParams(searchParams.toString());
    filters.forEach((filter) => params.delete(filter.name));
    setSearchParams(params);
  }, [filters, searchParams, setSearchParams]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "stretch",
        alignItems: "center",
        backgroundColor: "common.white",
        borderRadius: 5,
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 15px 5px",
        px: 2.5,
        py: 1.5,
      }}
    >
      {startSectionElement && <Box sx={{ flex: 1, display: "flex" }}>{startSectionElement}</Box>}
      <Box
        sx={{
          flex: startSectionElement ? undefined : 1,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 2.5, rowGap: 0.5 }}>
          <SearchParamsFilters
            filters={filters}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <ClearButton onClick={handleClear} sx={{ ml: 2 }}>
            {t("clear_filter")}
          </ClearButton>
        </Box>
      </Box>
    </Box>
  );
};
