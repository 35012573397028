import React, { FC, useCallback, useMemo, useRef } from "react";
import { Box, Link, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  IRecordingPeriodGhgCategory,
  IRecordingPeriodGhgCategoryMateriality,
} from "@netcero/netcero-core-api-client";
import { GhgCategoryName } from "../../ghg-categories/components/ghg-category-name.component";
import { GhgCategoryResponsibleUserPicker } from "../../ghg-categories/components/ghg-category-responsible-user-picker.component";
import {
  GhgCategoriesUtilities,
  IInputParameterRecordingStructureIdentity,
  IRecordingPeriodGhgCategoryIdentity,
} from "@netcero/netcero-common";
import {
  useIncludeGhgCategoryMutation,
  useUpdateGhgCategoryMaterialityMutation,
  useUpdateGhgCategoryResponsiblePersonMutation,
} from "../../ghg-categories/ghg-categories.mutations";
import { useCombineMutationState } from "../../common/hooks/combined-mutation-state.hook";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";
import { CommentsIconButtonWithQuery } from "../../comments/components/comments-icon-button.component";
import { useGhgCategoryCommentsAndAuditLogsSidebarContext } from "../../ghg-categories/components/ghg-category-comments-and-audit-logs-sidebar.component";
import { IntercomReferenceWidget } from "../../intercom-references/intercom-reference.widget";
import { IconSize } from "../../common/constants/tabler-icon.constants";
import { Link as RouterLink } from "react-router-dom";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";
import { GhgCategoryMaterialityIndicatorWithMenu } from "../../ghg-categories/components/ghg-category-materiality-indicator-with-menu.component";
import {
  GhgCategoryStatusIndicatorWithMenu,
  IGhgCategoryStatusIndicatorWithMenuRef,
} from "../../ghg-categories/components/ghg-category-status-indicator-with-menu.component";
import { useGhgCategoryStatusChangeHandling } from "../../ghg-categories/hooks/ghg-category-status-change-handling.hooks";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import { useDialogState } from "../../common/dialogs/dialog-state.hook";

interface IGhgInventoryScreeningCategoriesTableProps {
  recordingStructureIdentity: IInputParameterRecordingStructureIdentity;
  ghgCategories: IRecordingPeriodGhgCategory[];
}

export const GhgInventoryScreeningCategoriesTable: FC<
  IGhgInventoryScreeningCategoriesTableProps
> = ({ recordingStructureIdentity, ghgCategories }) => {
  const { t: tTable } = useTranslation("ghg_protocol", {
    keyPrefix: "inventory_screening.table",
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{tTable("heading_category")}</TableCell>
          <TableCell>{tTable("heading_materiality")}</TableCell>
          <TableCell>{tTable("heading_status")}</TableCell>
          <TableCell align="center">{tTable("heading_responsible_person")}</TableCell>
          {/* Actions Column */}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {ghgCategories.map((category) => (
          <GhgCategoryTableRow
            key={category.ghgCategoryIdentifier}
            recordingStructureIdentity={recordingStructureIdentity}
            category={category}
          />
        ))}
      </TableBody>
    </Table>
  );
};

interface IGhgCategoryTableRowProps {
  recordingStructureIdentity: IInputParameterRecordingStructureIdentity;
  category: IRecordingPeriodGhgCategory;
}

const GhgCategoryTableRow: FC<IGhgCategoryTableRowProps> = ({
  recordingStructureIdentity,
  category,
}) => {
  const { t: tExcludePrompt } = useTranslation("ghg_protocol", {
    keyPrefix: "inventory_screening.exclude_prompt",
  });

  const ghgCategoryIdentity: IRecordingPeriodGhgCategoryIdentity = useMemo(
    () => ({
      organizationId: recordingStructureIdentity.organizationId,
      recordingPeriodId: category.recordingPeriodId,
      ghgCategoryIdentifier: category.ghgCategoryIdentifier,
    }),
    [
      category.ghgCategoryIdentifier,
      category.recordingPeriodId,
      recordingStructureIdentity.organizationId,
    ],
  );
  const isExcluded = !!category.exclude;

  const { wrapApiPromise } = useAppSnackbar();
  const {
    data: viewedCommentsForCategoryId,
    openCommentsAndAuditLogSideSection,
    toggleCommentsAndAuditLogSideSection,
  } = useGhgCategoryCommentsAndAuditLogsSidebarContext();

  const updateGhgCategoryResponsiblePersonMutation =
    useUpdateGhgCategoryResponsiblePersonMutation();
  const updateGhgCategoryMaterialityMutation = useUpdateGhgCategoryMaterialityMutation();
  const includeGhgCategoryMutation = useIncludeGhgCategoryMutation();

  const handleChangeGhgCategoryResponsiblePerson = useCallback(
    (newResponsibleUserId: string | null) => {
      void wrapApiPromise(
        updateGhgCategoryResponsiblePersonMutation.mutateAsync({
          ...recordingStructureIdentity,
          ghgCategoryIdentifier: category.ghgCategoryIdentifier,
          responsiblePersonId: newResponsibleUserId ?? undefined,
        }),
        { type: BasicSnackbarApiActionType.UPDATE_GHG_CATEGORY_RESPONSIBLE_PERSON },
      );
    },
    [
      category.ghgCategoryIdentifier,
      recordingStructureIdentity,
      updateGhgCategoryResponsiblePersonMutation,
      wrapApiPromise,
    ],
  );

  // Materiality & Status Handling

  const {
    isOpen: showSyncExcludedStatusPrompt,
    openDialog: openSyncExcludedStatusPrompt,
    closeDialog: closeSyncExcludedStatusPrompt,
    data: syncExcludedStatusPromptData,
  } = useDialogState({ shouldBeExcluded: false });

  const handleChangeMateriality = useCallback(
    async (newMateriality: IRecordingPeriodGhgCategoryMateriality | null) => {
      await wrapApiPromise(
        updateGhgCategoryMaterialityMutation.mutateAsync({
          ...recordingStructureIdentity,
          ghgCategoryIdentifier: category.ghgCategoryIdentifier,
          materiality: newMateriality ?? undefined,
        }),
        { type: BasicSnackbarApiActionType.UPDATE_GHG_CATEGORY_MATERIALITY },
      );
      // Prompt user to update excluded status (should fit materiality)
      if (
        // "Not Material" But not yet excluded
        newMateriality === IRecordingPeriodGhgCategoryMateriality.NotMaterial &&
        !isExcluded
      ) {
        openSyncExcludedStatusPrompt({ shouldBeExcluded: true });
      } else if (
        // Excluded But not "Not Material"
        newMateriality !== IRecordingPeriodGhgCategoryMateriality.NotMaterial &&
        isExcluded
      ) {
        openSyncExcludedStatusPrompt({ shouldBeExcluded: false });
      }
    },
    [
      category.ghgCategoryIdentifier,
      isExcluded,
      recordingStructureIdentity,
      updateGhgCategoryMaterialityMutation.mutateAsync,
      wrapApiPromise,
      openSyncExcludedStatusPrompt,
    ],
  );

  const { statusChangeEvents, mutationsState: statusChangeMutationsState } =
    useGhgCategoryStatusChangeHandling({
      ghgCategoryIdentity,
      recordingStructureIdentity,
    });

  const statusIndicatorWithMenuRef = useRef<IGhgCategoryStatusIndicatorWithMenuRef>(null);

  const handleCloseChangeToExcludedPrompt = useCallback(
    async (confirmExclude: boolean) => {
      if (confirmExclude) {
        if (syncExcludedStatusPromptData?.shouldBeExcluded) {
          statusIndicatorWithMenuRef.current?.exclude();
        } else {
          await includeGhgCategoryMutation.mutateAsync({
            ...ghgCategoryIdentity,
            inputParameterRecordingStructureId:
              recordingStructureIdentity.inputParameterRecordingStructureId,
          });
        }
      }
      // Always close
      closeSyncExcludedStatusPrompt();
    },
    [
      closeSyncExcludedStatusPrompt,
      ghgCategoryIdentity,
      includeGhgCategoryMutation.mutateAsync,
      recordingStructureIdentity.inputParameterRecordingStructureId,
      syncExcludedStatusPromptData?.shouldBeExcluded,
    ],
  );

  const { isPending } = useCombineMutationState(
    updateGhgCategoryResponsiblePersonMutation,
    updateGhgCategoryMaterialityMutation,
    includeGhgCategoryMutation,
    statusChangeMutationsState,
  );

  const changeToExcludedStatusPromptKey = syncExcludedStatusPromptData?.shouldBeExcluded
    ? "exclude"
    : "include";

  return (
    <>
      {/* Change to excluded Prompt */}
      <ConfirmDialogTextBody
        open={showSyncExcludedStatusPrompt}
        title={tExcludePrompt(`title.${changeToExcludedStatusPromptKey}`)}
        content={tExcludePrompt(`content.${changeToExcludedStatusPromptKey}`)}
        onClose={handleCloseChangeToExcludedPrompt}
      />

      {/* Table Row */}
      <TableRow>
        <TableCell>
          <Box display="flex" alignItems="center" gap={1}>
            <Link
              color="inherit"
              underline="hover"
              component={RouterLink}
              to={AppInternalNavigationUtilities.getGhgProtocolPageUrl(recordingStructureIdentity, {
                viewedScope: GhgCategoriesUtilities.getScopeOfGhgCategory(
                  category.ghgCategoryIdentifier,
                ),
                viewedGhgCategoryId: category.ghgCategoryIdentifier,
              })}
            >
              <GhgCategoryName ghgCategory={category.ghgCategoryIdentifier} />
            </Link>
            <IntercomReferenceWidget
              entityType="ghg-category"
              identifier={`ghg_category_help.${category.ghgCategoryIdentifier}`}
              iconSize={IconSize.Smaller}
            />
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex" justifyContent="start">
            <GhgCategoryMaterialityIndicatorWithMenu
              materiality={category.materiality ?? null}
              onChangeMateriality={handleChangeMateriality}
              disabled={isPending}
            />
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex" justifyContent="start">
            <GhgCategoryStatusIndicatorWithMenu
              ref={statusIndicatorWithMenuRef}
              identity={ghgCategoryIdentity}
              inputParameterRecordingStructureId={
                recordingStructureIdentity.inputParameterRecordingStructureId
              }
              category={category}
              disabled={isPending}
              {...statusChangeEvents}
              // Hide confirm Dialogs (screen is only visible to Organization Admins anyways)
              doNotShowConfirmTransitionDialog
            />
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box display="inline-block">
            <GhgCategoryResponsibleUserPicker
              organizationIdentity={recordingStructureIdentity}
              recordingPeriodGhgCategory={category}
              onChange={handleChangeGhgCategoryResponsiblePerson}
              disabled={isPending}
            />
          </Box>
        </TableCell>
        {/* Actions Column */}
        <TableCell align="right">
          <CommentsIconButtonWithQuery
            organizationId={recordingStructureIdentity.organizationId}
            relations={{
              entity: "recording-period-ghg-category",
              ghgCategoryRecordingPeriodId: recordingStructureIdentity.recordingPeriodId,
              ghgCategoryIdentifier: category.ghgCategoryIdentifier,
            }}
            onClick={() => {
              if (viewedCommentsForCategoryId === category.ghgCategoryIdentifier) {
                toggleCommentsAndAuditLogSideSection(category.ghgCategoryIdentifier);
              } else {
                openCommentsAndAuditLogSideSection(category.ghgCategoryIdentifier);
              }
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
