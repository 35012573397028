import {
  IDataEntryObject,
  IGhgCategoriesIdentifier,
  IGhgEvaluationCalculationResult,
  IGhgEvaluationCalculationResults,
  IGhgProtocolScopeEnum,
} from "@netcero/netcero-core-api-client";
import {
  IDataEntryObjectConsolidationResult,
  IDataEntryObjectTreeForGhgEvaluation,
  IGhgEvaluationConsolidationResult,
  IGhgEvaluationSums,
  IGhgEvaluationValueRow,
} from "./ghg-evaluation.public-types";
import { NumberUtilities, RecursiveUtilities } from "../common";
import { GhgCategoriesUtilities } from "../ghg-categories";

export class GhgEvaluationUtilities {
  /**
   * Always use this share when the DEO is fully consolidated.
   * @private
   */
  private static readonly ShareWhenFullyConsolidated = 1;

  /**
   * Computes GHG evaluation values for a given row, including market-based and location-based calculations.
   * Optionally calculates Scope 3 values if specified.
   *
   * @param row - The GHG evaluation value row containing activity value data and factors.
   * @returns An array of GHG evaluation calculation results, including market-based and location-based values for the main activity and for Scope 3.
   */
  public static computeValueForRow(row: IGhgEvaluationValueRow) {
    const results: IGhgEvaluationCalculationResult[] = [];

    const addValueToResults = (
      value: IGhgEvaluationCalculationResults,
      category: "current" | "scope-3",
    ) => {
      results.push({
        activityId: row.activityId,
        ghgCategoryIdentifier:
          category === "current"
            ? row.ghgCategoryIdentifier
            : IGhgCategoriesIdentifier._33EnergyRelatedActivitiesNotInScope1And2,
        dataEntryObjectId: row.dataEntryObjectId,
        value,
      });
    };

    const marketBasedValue: number = row.value * row.factor;

    // add result for current row
    addValueToResults(
      {
        marketBasedValue,
        locationBasedValue:
          row.relatedFactor !== null ? row.value * row.relatedFactor : marketBasedValue,
      },
      "current",
    );

    const scope3Factor: number | undefined = row.scopeFactors[IGhgProtocolScopeEnum.Scope3];

    // no related factor exists --> just use the current factor, if any
    if (row.relatedFactor === null && row.relatedScopeFactors === null) {
      if (scope3Factor !== undefined) {
        addValueToResults(
          {
            marketBasedValue: row.value * scope3Factor,
            locationBasedValue: row.value * scope3Factor,
          },
          "scope-3",
        );
      }
    } else if (row.relatedFactor !== null && row.relatedScopeFactors !== null) {
      const relatedScope3Factor: number | undefined =
        row.relatedScopeFactors[IGhgProtocolScopeEnum.Scope3];

      // if any of the factors is defined, compute the value, using 0 as a fallback factor
      if (scope3Factor !== undefined || relatedScope3Factor !== undefined) {
        addValueToResults(
          {
            marketBasedValue: row.value * (scope3Factor ?? 0),
            locationBasedValue: row.value * (relatedScope3Factor ?? 0),
          },
          "scope-3",
        );
      }
    }

    return results;
  }

  public static applyConsolidationToRow(
    result: IGhgEvaluationCalculationResult,
    consolidation: IGhgEvaluationConsolidationResult,
  ) {
    const consolidationResult = consolidation[result.dataEntryObjectId];

    // if no calculation result can be found --> leave the value as is
    if (consolidationResult === undefined) {
      return result;
    }

    // only consider emissions of scopes that are specified in the consolidation result
    if (
      !consolidationResult.considerEmissionsOfScopes.includes(
        GhgCategoriesUtilities.getScopeOfGhgCategory(result.ghgCategoryIdentifier),
      )
    ) {
      return null;
    }

    // Move emissions to different category if specified
    if (consolidationResult.relocateEmissionsTo !== null) {
      result.ghgCategoryIdentifier = consolidationResult.relocateEmissionsTo;
    }

    // Update value with calculated share
    result.value.locationBasedValue *= consolidationResult.share;
    result.value.marketBasedValue *= consolidationResult.share;

    return result;
  }

  /**
   * Sums up the GHG evaluation sums of multiple entries.
   * @param sums The sums to sum up.
   */
  public static sumUpGhgEvaluationsSums(sums: IGhgEvaluationSums[]): IGhgEvaluationSums {
    const result = { locationBasedTotal: 0, marketBasedTotal: 0 };
    sums.forEach((entry) => {
      result.locationBasedTotal += entry.locationBasedTotal;
      result.marketBasedTotal += entry.marketBasedTotal;
    });
    return result;
  }

  /**
   * Filters GHG evaluation results by data entry object IDs. Will only return results that are relevant for the provided data entry objects.
   * @param results The GHG evaluation results to filter.
   * @param dataEntryObjectIds The data entry object IDs to filter by.
   */
  public static filterGhgEvaluationResultsByDataEntryObjects(
    results: IGhgEvaluationCalculationResult[],
    dataEntryObjectIds: string[],
  ): IGhgEvaluationCalculationResult[] {
    const dataEntryObjectIdsSet = new Set(dataEntryObjectIds);
    return results.filter((result) => dataEntryObjectIdsSet.has(result.dataEntryObjectId));
  }

  /**
   * Filters GHG evaluation results by data entry object IDs. Will only return results that are relevant for the provided data entry objects.
   * @param results The GHG evaluation results to filter.
   * @param categories The GHG categories to filter by.
   * @param invert Whether the check should be inverted, i.e. categories that are NOT part of the array should be returned
   */
  public static filterGhgEvaluationResultsByCategories(
    results: IGhgEvaluationCalculationResult[],
    categories: IGhgCategoriesIdentifier[],
    invert: boolean = false,
  ): IGhgEvaluationCalculationResult[] {
    const categoriesSet = new Set(categories);
    return results.filter((result) => {
      const contained = categoriesSet.has(result.ghgCategoryIdentifier);
      return invert ? !contained : contained;
    });
  }

  /**
   * Filters GHG evaluation results by data entry object and its children. Will only return
   * results that are relevant for the provided data entry object and its children.
   * @param results The GHG evaluation results to filter.
   * @param dataEntryObject The data entry object tree to filter by.
   */
  public static filterGhgEvaluationResultsForDataEntryObjectAndChildren(
    results: IGhgEvaluationCalculationResult[],
    dataEntryObject: IDataEntryObject,
  ) {
    const dataEntryObjectIds = RecursiveUtilities.flattenRecursiveStructureDown(
      dataEntryObject,
    ).map((deo) => deo.id);
    return GhgEvaluationUtilities.filterGhgEvaluationResultsByDataEntryObjects(
      results,
      dataEntryObjectIds,
    );
  }

  private static convertKgToTons(value: number): number {
    return value / 1000;
  }

  /**
   * Calculates both the location-based and market-based total emissions.
   *
   * @param evaluationResults An array of evaluation items.
   * @returns An object containing the total emissions in tons for both views.
   */
  public static calculateTotalEmissionsSumInTCO2Eq(
    evaluationResults: IGhgEvaluationCalculationResult[],
  ): IGhgEvaluationSums {
    return evaluationResults.reduce(
      (acc, curr) => {
        acc.locationBasedTotal += GhgEvaluationUtilities.convertKgToTons(
          curr.value.locationBasedValue,
        );
        acc.marketBasedTotal += GhgEvaluationUtilities.convertKgToTons(curr.value.marketBasedValue);
        return acc;
      },
      { locationBasedTotal: 0, marketBasedTotal: 0 },
    );
  }

  /**
   * Calculates the total emissions for each category in tons of CO2 equivalent.
   * @param evaluationResults An array of evaluation items.
   * @returns An object containing the total emissions in tons for each category.
   */
  public static calculateEmissionSumsPerCategoryInTCO2Eq(
    evaluationResults: IGhgEvaluationCalculationResult[],
  ): Partial<Record<IGhgCategoriesIdentifier, IGhgEvaluationSums>> {
    const result: Partial<Record<IGhgCategoriesIdentifier, IGhgEvaluationSums>> = {};

    evaluationResults.forEach(({ value, ghgCategoryIdentifier: categoryIdentifier }) => {
      const categorySums =
        // Get existing sum entry
        result[categoryIdentifier] ??
        // Or create a new one and use it
        (result[categoryIdentifier] = {
          locationBasedTotal: 0,
          marketBasedTotal: 0,
        });

      categorySums.locationBasedTotal += GhgEvaluationUtilities.convertKgToTons(
        value.locationBasedValue,
      );
      categorySums.marketBasedTotal += GhgEvaluationUtilities.convertKgToTons(
        value.marketBasedValue,
      );
    });

    return result;
  }

  /**
   * Calculates the total emissions for a specific activity in tons of CO2 equivalent.
   * @param evaluationResults
   * @param activityId
   */
  public static calculateTotalEmissionsForActivity(
    evaluationResults: IGhgEvaluationCalculationResult[],
    activityId: string,
  ): IGhgEvaluationSums {
    const activityResults = evaluationResults.filter((result) => result.activityId === activityId);
    return GhgEvaluationUtilities.calculateTotalEmissionsSumInTCO2Eq(activityResults);
  }

  /**
   * Determines if the DEO is not fully consolidated / emissions should be reattributed
   * @param deo
   * @private
   */
  private static isDeoNotFullyConsolidated(deo: IDataEntryObjectTreeForGhgEvaluation) {
    return deo.financiallyConsolidated === false && deo.operationalControl === false;
  }

  /**
   * This determines the relevant share for the consolidation. Will always be `ShareWhenFullyConsolidated` if the DEO is fully consolidated, otherwise the actual share.
   * @param deo The DEO to check.
   * @private
   * @returns The updated share for the DEO.
   */
  private static getShareForDeo(deo: IDataEntryObjectTreeForGhgEvaluation) {
    return GhgEvaluationUtilities.isDeoNotFullyConsolidated(deo)
      ? deo.shareHeldByParent
      : // always use default share if fully consolidated
        GhgEvaluationUtilities.ShareWhenFullyConsolidated;
  }

  /**
   * The threshold determines, together with part of value chain, whether Scope 3 emissions should considered.
   * @private
   */
  private static readonly ShareThreshold: number = 0.2;

  /**
   * Checks whether the value exceeds the threshold, i.e. value > THRESHOLD
   * The threshold determines, together with part of value chain, whether Scope 3 emissions should considered.
   * @param value
   */
  public static doesValueExceedShareThreshold(value: number) {
    return value > GhgEvaluationUtilities.ShareThreshold;
  }

  private static generateCalculationMetadataForDataEntryObject(
    deo: IDataEntryObjectTreeForGhgEvaluation,
    previousParents: IDataEntryObjectTreeForGhgEvaluation[],
  ): {
    result: IDataEntryObjectConsolidationResult;
    pathToProvidedNode: IDataEntryObjectTreeForGhgEvaluation[];
  } {
    // Constructs the entire path until here
    const entireTreeIncludingThisNode = [...previousParents, deo];

    // Always include the current node in the share calculation; be sure to use the correct share!
    const calculatedShare = NumberUtilities.product(
      entireTreeIncludingThisNode.map(GhgEvaluationUtilities.getShareForDeo),
    );

    // Determine whether any parent is not financially consolidated and not operational controlled (as this is inherited),
    // also determine whether any of those eligible parents is part of the value chain
    // Determine whether any eligible parent is part of the value chain
    let isAnyNotFinanciallyConsolidatedAndNotOperationalControl = false;
    let isAnyPartOfValueChain = false;

    for (const node of entireTreeIncludingThisNode) {
      const result = GhgEvaluationUtilities.isDeoNotFullyConsolidated(node);
      if (result) {
        // overwriting the result in any case is fine since true is the "strongest" value (also applies to "isAnyPartOfValueChain")
        isAnyNotFinanciallyConsolidatedAndNotOperationalControl = true;
        if (node.partOfValueChain === true) {
          isAnyPartOfValueChain = true;
        }
      }
    }

    if (isAnyNotFinanciallyConsolidatedAndNotOperationalControl) {
      if (
        isAnyPartOfValueChain &&
        GhgEvaluationUtilities.doesValueExceedShareThreshold(calculatedShare)
      ) {
        return {
          result: {
            relocateEmissionsTo: IGhgCategoriesIdentifier._315Investments,
            share: calculatedShare,
            considerEmissionsOfScopes: [
              IGhgProtocolScopeEnum.Scope1,
              IGhgProtocolScopeEnum.Scope2,
              IGhgProtocolScopeEnum.Scope3,
            ],
          },
          pathToProvidedNode: entireTreeIncludingThisNode,
        };
      } else {
        return {
          result: {
            relocateEmissionsTo: IGhgCategoriesIdentifier._315Investments,
            share: calculatedShare,
            considerEmissionsOfScopes: [IGhgProtocolScopeEnum.Scope1, IGhgProtocolScopeEnum.Scope2],
          },
          pathToProvidedNode: entireTreeIncludingThisNode,
        };
      }
    } else {
      return {
        result: {
          relocateEmissionsTo: null,
          share: GhgEvaluationUtilities.ShareWhenFullyConsolidated,
          considerEmissionsOfScopes: [
            IGhgProtocolScopeEnum.Scope1,
            IGhgProtocolScopeEnum.Scope2,
            IGhgProtocolScopeEnum.Scope3,
          ],
        },
        pathToProvidedNode: entireTreeIncludingThisNode,
      };
    }
  }

  /**
   * Generates calculation metadata for a data entry object tree.
   *
   * @param tree - The data entry object tree for GHG evaluation.
   * @returns The GHG evaluation consolidation result.
   */
  public static generateCalculationMetadataForDataEntryObjectTree(
    tree: IDataEntryObjectTreeForGhgEvaluation,
  ): IGhgEvaluationConsolidationResult {
    const previousParents: IDataEntryObjectTreeForGhgEvaluation[] = [];
    const totalResult: IGhgEvaluationConsolidationResult = {};

    GhgEvaluationUtilities.generateCalculationMetadataForDataEntryObjectTreeInternal(
      tree,
      previousParents,
      totalResult,
    );

    return totalResult;
  }

  private static generateCalculationMetadataForDataEntryObjectTreeInternal(
    tree: IDataEntryObjectTreeForGhgEvaluation,
    previousParents: IDataEntryObjectTreeForGhgEvaluation[],
    totalResult: IGhgEvaluationConsolidationResult,
  ) {
    // Calculate result for current DEO
    const { pathToProvidedNode, result } =
      GhgEvaluationUtilities.generateCalculationMetadataForDataEntryObject(tree, previousParents);

    // Save result
    totalResult[tree.id] = result;

    // Calculate result for children, taking into account the new share
    for (const child of tree.children) {
      GhgEvaluationUtilities.generateCalculationMetadataForDataEntryObjectTreeInternal(
        child,
        pathToProvidedNode,
        totalResult,
      );
    }

    return totalResult;
  }
}
