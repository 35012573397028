import { FC, useCallback, useEffect } from "react";
import { EditDialogWrapper } from "../../common/dialogs/variants/edit-dialog.wrapper";
import {
  IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevantReasonEnum,
  IRecordingPeriodGhgCategoryExclude,
} from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { ITranslatableError } from "../../common/hooks/translate-error.hook";
import { Controller, useForm } from "react-hook-form";
import {
  ApiDataCleanupUtilities,
  IRecordingPeriodGhgCategoryIdentity,
} from "@netcero/netcero-common";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { DialogConfirmButton } from "../../common/dialogs/dialog-button.components";
import { ORDERED_GHG_CATEGORY_EXCLUSION_REASONS } from "../ghg-categories.constants";
import { HookFormControllerUtilities } from "../../common/input-validation/hook-form-controller.utilities";
import { useResetMutationsOnOpen } from "../../common/hooks/use-reset-mutations-on-open.hook";
import {
  useExcludeGhgCategoryMutation,
  useIncludeGhgCategoryMutation,
} from "../ghg-categories.mutations";
import { useCombineMutationState } from "../../common/hooks/combined-mutation-state.hook";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";

interface IGhgCategoryExcludeDialogCommonProps {
  open: boolean;
  onClose: VoidFunction;
  exclusionData: IRecordingPeriodGhgCategoryExclude | null;
  /** If this property has a value the include button will be disabled and the value will be shown as a tooltip */
  cannotIncludeReason?: string | null;
}

interface IGhgCategoryExclusionDialogWithMutationsProps
  extends IGhgCategoryExcludeDialogCommonProps {
  ghgCategoryIdentity: IRecordingPeriodGhgCategoryIdentity;
  inputParameterRecordingStructureId: string;
}

export const GhgCategoryExclusionDialogWithMutations: FC<
  IGhgCategoryExclusionDialogWithMutationsProps
> = ({ ghgCategoryIdentity, inputParameterRecordingStructureId, ...props }) => {
  const { wrapApiPromise } = useAppSnackbar();

  const excludeCategoryMutation = useExcludeGhgCategoryMutation();
  const includeCategoryMutation = useIncludeGhgCategoryMutation();
  // Reset mutations on open
  useResetMutationsOnOpen(props.open, excludeCategoryMutation, includeCategoryMutation);

  const handleExclude = useCallback(
    async (data: IRecordingPeriodGhgCategoryExclude) => {
      await wrapApiPromise(
        excludeCategoryMutation.mutateAsync({
          ...ghgCategoryIdentity,
          inputParameterRecordingStructureId,
          exclude: data,
        }),
        { type: BasicSnackbarApiActionType.EXCLUDE_GHG_CATEGORY },
      );
      props.onClose();
    },
    [
      excludeCategoryMutation.mutateAsync,
      ghgCategoryIdentity,
      inputParameterRecordingStructureId,
      props,
      wrapApiPromise,
    ],
  );

  const handleInclude = useCallback(async () => {
    await wrapApiPromise(
      includeCategoryMutation.mutateAsync({
        ...ghgCategoryIdentity,
        inputParameterRecordingStructureId,
      }),
      { type: BasicSnackbarApiActionType.INCLUDE_GHG_CATEGORY },
    );
    props.onClose();
  }, [
    ghgCategoryIdentity,
    includeCategoryMutation.mutateAsync,
    inputParameterRecordingStructureId,
    props,
    wrapApiPromise,
  ]);

  const {
    isPending: isAnyMutationPending,
    isError: isAnyMutationError,
    error: anyMutationError,
  } = useCombineMutationState(excludeCategoryMutation, includeCategoryMutation);

  return (
    <GhgCategoryExclusionDialog
      {...props}
      loading={isAnyMutationPending}
      error={isAnyMutationError ? anyMutationError : undefined}
      onExclude={handleExclude}
      onInclude={handleInclude}
      disabled={isAnyMutationPending}
    />
  );
};

interface IGhgCategoryExclusionDialogData
  extends Pick<IRecordingPeriodGhgCategoryExclude, "reason"> {
  explanation: string;
}

function getFormData(
  data: IRecordingPeriodGhgCategoryExclude | null,
): IGhgCategoryExclusionDialogData {
  return {
    reason:
      data?.reason ?? IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevantReasonEnum.NotRelevant,
    explanation: data?.explanation ?? "",
  };
}

function getApiData(data: IGhgCategoryExclusionDialogData): IRecordingPeriodGhgCategoryExclude {
  return {
    reason: data.reason,
    // This is safe since the form does not allow an empty explanation being sent
    explanation: ApiDataCleanupUtilities.cleanUpString(data.explanation) as string,
  };
}

interface IGhgCategoryExcludeDialogProps extends IGhgCategoryExcludeDialogCommonProps {
  loading: boolean;
  error?: ITranslatableError;
  onExclude: (data: IRecordingPeriodGhgCategoryExclude) => void;
  onInclude: () => void;
  disabled?: boolean;
}

export const GhgCategoryExclusionDialog: FC<IGhgCategoryExcludeDialogProps> = ({
  open,
  onClose,
  exclusionData,
  loading,
  error,
  cannotIncludeReason,
  onExclude,
  onInclude,
  disabled,
}) => {
  const { t: tExclusionDialog } = useTranslation("ghg_protocol", {
    keyPrefix: "exclusion_dialog",
  });
  const { t: tGhgCategories } = useTranslation("ghg_protocol");
  const { t: tCommon } = useTranslation("common");

  const isExcluded = !!exclusionData;
  const readOnly = isExcluded;

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { isDirty, submitCount },
    reset,
  } = useForm<IGhgCategoryExclusionDialogData>({
    defaultValues: getFormData(exclusionData),
  });
  // Reset on Open
  useEffect(() => {
    if (open) {
      reset(getFormData(exclusionData));
    }
  }, [exclusionData, open, reset]);

  const handleExclude = useCallback(
    (result: IGhgCategoryExclusionDialogData) => {
      onExclude(getApiData(result));
    },
    [onExclude],
  );

  const isExplanationMandatory = watch("reason") === "other";
  // Trigger revalidation when isExplanationMandatory changes
  useEffect(() => {
    if (submitCount > 0) {
      void trigger("explanation");
    }
  }, [isExplanationMandatory, submitCount, trigger]);

  return (
    <EditDialogWrapper
      open={open}
      title={tExclusionDialog("title")}
      mode={isExcluded ? "edit" : "create"}
      loading={loading}
      error={error}
      hasChanges={isDirty}
      onCancel={onClose}
      onSave={handleSubmit(handleExclude)}
      disabled={disabled}
      readOnly={isExcluded}
      dialogProps={{ maxWidth: "md", fullWidth: true }}
      saveButtonContentOverride={tExclusionDialog("exclude")}
      allowSaveWithoutChanges={!isExcluded}
      additionalStartActions={
        <>
          {/* Include Button */}
          {isExcluded && (
            <Tooltip title={cannotIncludeReason} placement="right">
              <Box component="span" mr="auto">
                <DialogConfirmButton
                  onClick={onInclude}
                  disabled={disabled || !!cannotIncludeReason}
                >
                  {tExclusionDialog("button_include")}
                </DialogConfirmButton>
              </Box>
            </Tooltip>
          )}
        </>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="start" gap={2} py={1}>
        {/* Reason */}
        <Controller
          control={control}
          name="reason"
          render={({ field }) => (
            <FormControl sx={{ width: 180 }}>
              <InputLabel>{tExclusionDialog("label_reason")}</InputLabel>
              <Select
                label={tExclusionDialog("label_reason")}
                value={field.value}
                onChange={field.onChange}
                disabled={disabled || readOnly}
              >
                {ORDERED_GHG_CATEGORY_EXCLUSION_REASONS.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {tGhgCategories(`exclusion_reasons.${reason}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        {/* Explanation */}
        <Controller
          control={control}
          name="explanation"
          rules={
            isExplanationMandatory
              ? HookFormControllerUtilities.createRequiredStringRules(
                  tCommon("error_field_required"),
                )
              : // Allow any value if not mandatory (this is necessary to reset the validation)
                { required: false, validate: () => true }
          }
          render={({ field, fieldState: { error } }) => (
            <TextField
              required={isExplanationMandatory}
              label={tExclusionDialog("label_explanation", {
                context: !isExplanationMandatory ? "optional" : undefined,
              })}
              value={field.value}
              onChange={field.onChange}
              error={!!error}
              helperText={error?.message}
              multiline
              minRows={5}
              maxRows={5}
              fullWidth
              disabled={disabled || readOnly}
            />
          )}
        />
      </Box>
    </EditDialogWrapper>
  );
};
