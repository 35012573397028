import { Box } from "@mui/material";
import { IInputParameterRecordingStructureBase } from "@netcero/netcero-core-api-client";
import React, { FC, useMemo } from "react";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../content-translation/hooks/translate-content.hook";
import { GenericRecordingStructureCard } from "./generic-recording-structure-card.component";
import { useDmaStatusQuery } from "../data-entry-object-values/queries/data-entry-object-dma-status.queries";
import { MaterialityStatusChip } from "../double-materiality-assessment/common/materiality-status-chip.component";
import { useOrganizationRootStructureId } from "../common/hooks/organization-root-deo.hook";
import { DMAUtilities } from "@netcero/netcero-dma";

export interface IRecordingStructureCardProps {
  organizationId: string;
  recordingPeriodId: string;
  recordingStructure: IInputParameterRecordingStructureBase;
  recordingModel: string;
  disabled?: boolean;
}

export const RecordingStructureCard: FC<IRecordingStructureCardProps> = ({
  organizationId,
  recordingPeriodId,
  recordingStructure,
  recordingModel,
  disabled,
}) => {
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();

  const isEsrsWithMateriality = recordingStructure.categoryIdentifier.startsWith("esrs");

  const organizationRootStructureId = useOrganizationRootStructureId(
    organizationId,
    recordingPeriodId,
  );

  const dmaStatusQuery = useDmaStatusQuery(
    organizationId,
    recordingPeriodId,
    organizationRootStructureId,
    isEsrsWithMateriality,
  );

  const isTopicMaterial = useMemo(() => {
    // Only display materiality for ESRS
    if (!isEsrsWithMateriality) {
      return null;
    }
    // Check if the topic is material
    return dmaStatusQuery.data
      ? DMAUtilities.isTopicInDmaStatusMaterial(
          dmaStatusQuery.data,
          recordingStructure.categoryIdentifier,
          false,
        )
      : null;
  }, [isEsrsWithMateriality, dmaStatusQuery, recordingStructure.categoryIdentifier]);

  const recordingStructureType = useMemo(() => {
    switch (recordingModel) {
      case "carbon_accounting":
        return "ghg";
      case "esrs":
        return "esrs";
      case "vsme":
        return "vsme";
      default:
        return "all";
    }
  }, [recordingModel]);

  return (
    <GenericRecordingStructureCard
      id={recordingStructure.id}
      title={translateContent(recordingStructure.name)}
      subtitle={translateOptionalContent(recordingStructure.description)}
      iconName={recordingStructure.icon}
      organizationId={organizationId}
      recordingPeriodId={recordingPeriodId}
      url={`${recordingStructureType}/recording-structures/${recordingStructure.id}`}
      additionalContent={
        isTopicMaterial === null ? null : (
          <Box width="fit-content">
            <MaterialityStatusChip isMateriality={isTopicMaterial} />
          </Box>
        )
      }
      disabled={disabled}
    />
  );
};
