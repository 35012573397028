import { Box, Chip, Stack, styled, TableCell, Tooltip } from "@mui/material";
import { FC } from "react";
import { IEmissionFactor, IGhgProtocolScopeEnum } from "@netcero/netcero-core-api-client";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { MappingUtilities } from "@netcero/netcero-common";
import { GhgCategoriesChips } from "../../ghg-categories/components/ghg-category-chip.component";
import { useTranslation } from "react-i18next";
import { EMISSION_UNIT_TEXT } from "../emission-factors.constants";
import { CornerRightUpDoubleIcon } from "../../common/constants/tabler-icon.constants";
import { EmissionFactorUnitNameShortWithTooltip } from "./emission-factor-unit-name.component";
import { useRenderGhgCategoryName } from "../../ghg-protocol/ghg-protocol.hooks";

type EmissionFactorTableValueCellComponent<T extends keyof IEmissionFactor> = FC<
  Pick<IEmissionFactor, T>
>;

export const StyledTableCell = styled(TableCell)({
  padding: "16px",
});

export const EmissionFactorTableTitleCell: EmissionFactorTableValueCellComponent<"title"> = ({
  title,
}) => {
  const translateContent = useTranslateContent();
  return <StyledTableCell sx={{ minWidth: 260 }}>{translateContent(title)}</StyledTableCell>;
};

export const EmissionFactorTableDatabaseCell: EmissionFactorTableValueCellComponent<"database"> = ({
  database,
}) => {
  const { t } = useTranslation("emission_factor_databases");
  return (
    <StyledTableCell>
      {FormatUtilities.formatOptionalString(MappingUtilities.mapIfNotUndefined(database, t))}
    </StyledTableCell>
  );
};

export const EmissionFactorTableTypeCell: EmissionFactorTableValueCellComponent<
  "emissionFactorType"
> = ({ emissionFactorType }) => {
  const { t } = useTranslation("emission_factor_types");
  return <StyledTableCell>{t(emissionFactorType)}</StyledTableCell>;
};

export const EmissionFactorTableVersionCell: EmissionFactorTableValueCellComponent<"version"> = ({
  version,
}) => {
  return <StyledTableCell>{version}</StyledTableCell>;
};

const MAX_SHOW_GHG_CATEGORIES = 4;
export const EmissionFactorTableGhgCategoriesCell: EmissionFactorTableValueCellComponent<
  "ghgCategories"
> = ({ ghgCategories }) => {
  const renderGhgCategoryName = useRenderGhgCategoryName();
  const shownGhgCategories = ghgCategories.slice(0, MAX_SHOW_GHG_CATEGORIES);
  const notShownGhgCategories = ghgCategories.slice(MAX_SHOW_GHG_CATEGORIES);
  return (
    <StyledTableCell>
      <Box display="flex" gap={1}>
        <GhgCategoriesChips ghgCategories={shownGhgCategories} />
        {notShownGhgCategories.length > 0 && (
          <Tooltip
            title={
              // Line Break on new line
              <Box whiteSpace="pre-line">
                {notShownGhgCategories
                  .map((category) => renderGhgCategoryName(category))
                  .join("\n")}
              </Box>
            }
          >
            <Chip label={`+${notShownGhgCategories.length}`} size="small" />
          </Tooltip>
        )}
      </Box>
    </StyledTableCell>
  );
};

export const EmissionFactorTableCountryCell: EmissionFactorTableValueCellComponent<"country"> = ({
  country,
}) => {
  const { t } = useTranslation("common", { keyPrefix: "countries" });
  return (
    <StyledTableCell>
      {FormatUtilities.formatOptionalString(MappingUtilities.mapIfNotUndefined(country, t))}
    </StyledTableCell>
  );
};

export const EmissionFactorTableFactorCell: FC<
  Pick<IEmissionFactor, "factor" | "scopeFactors">
> = ({ factor, scopeFactors }) => {
  return (
    <StyledTableCell align="right">
      <Stack direction="row" alignItems="center" justifyContent="end" gap={0.5}>
        <Box textAlign="right">{FormatUtilities.formatEmissionFactorFactor(factor)}</Box>
        <Chip size="small" label={EMISSION_UNIT_TEXT} sx={{ ml: 0.5 }} />
        {scopeFactors[IGhgProtocolScopeEnum.Scope3] !== undefined ? (
          <Tooltip
            title={`${FormatUtilities.formatEmissionFactorFactor(
              scopeFactors[IGhgProtocolScopeEnum.Scope3],
            )} ${EMISSION_UNIT_TEXT}`}
          >
            <CornerRightUpDoubleIcon />
          </Tooltip>
        ) : (
          // Spacer if no scope 3 factor
          <Box width="20px" />
        )}
      </Stack>
    </StyledTableCell>
  );
};

export const EmissionFactorTableUnitCell: EmissionFactorTableValueCellComponent<"unit"> = ({
  unit,
}) => {
  return (
    <StyledTableCell>
      <EmissionFactorUnitNameShortWithTooltip unit={unit} />
    </StyledTableCell>
  );
};
