import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddDateRangeIcon,
  DeleteIcon,
  RemoveDateRangeIcon,
} from "../../common/constants/tabler-icon.constants";
import { Controller, useFormContext } from "react-hook-form";
import {
  IActivityValuesFormData,
  IGhgActivityValuesTableMode,
} from "./ghg-activity-values-table-form.component";
import { useTranslation } from "react-i18next";
import { useGetEmissionFactorQuery } from "../../emission-factors/emission-factors.queries";
import { useGhgCategoriesOverviewContext } from "../../ghg-categories/ghg-categories-overview.context";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { EmissionFactorUnitNameShortWithTooltip } from "../../emission-factors/components/emission-factor-unit-name.component";
import { EmissionFactorName } from "../../emission-factors/components/emission-factor-name.component";
import { ORDERED_ACTIVITY_VALUE_DATA_QUALITY } from "../ghg-activity-values.constants";
import { GhgActivityValueDataQuality } from "./ghg-activity-value-data-quality-text.component";
import { DateRangePicker } from "../../common/components/date-range-picker.component";
import { DateTime } from "luxon";
import { HOVER_BACKGROUND_COLOR } from "../../../theme/theme";
import { IBaseActivityValueData } from "@netcero/netcero-core-api-client";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { ArrayUtilities, DateUtilities, MappingUtilities } from "@netcero/netcero-common";

// Used for styling (show delete on hover in read mode)
const DELETE_ROW_BUTTON_CLASS = "delete-row-button";

const DATE_SET_VALUE_OPTIONS = {
  shouldDirty: true,
};

interface IGhgActivityValuesTableRowProps {
  mode: IGhgActivityValuesTableMode;
  index: number;
  selectableEmissionFactorIds: string[];
  onStartEditing: VoidFunction;
  onDelete: VoidFunction;
  disabled: boolean;
  isNewlyAdded?: boolean;
}

export const GhgActivityValuesTableRow: FC<IGhgActivityValuesTableRowProps> = ({
  mode,
  index,
  selectableEmissionFactorIds,
  onStartEditing,
  onDelete,
  disabled,
  isNewlyAdded,
}) => {
  const { recordingStructureIdentity } = useGhgCategoriesOverviewContext();
  const organizationId = recordingStructureIdentity.organizationId;

  const { t } = useTranslation("ghg_activity_values");
  const { control, watch, setValue, trigger } = useFormContext<IActivityValuesFormData>();

  const [emissionsFactorId, fromDate, toDate] = watch([
    `activityValues.${index}.emissionFactorId`,
    `activityValues.${index}.fromDate`,
    `activityValues.${index}.toDate`,
  ]);

  const [showDatePicker, setShowDatePicker] = useState(!!fromDate && !!toDate);

  const emissionFactorQuery = useGetEmissionFactorQuery(
    {
      organizationId,
      emissionFactorId: emissionsFactorId as string,
    },
    emissionsFactorId !== null,
  );

  const handleAddDates = useCallback(() => {
    setShowDatePicker(true);
    const initialValue = DateUtilities.serializeDateTime(DateTime.now().startOf("day"));
    setValue(`activityValues.${index}.fromDate`, initialValue, DATE_SET_VALUE_OPTIONS);
    setValue(`activityValues.${index}.toDate`, initialValue, DATE_SET_VALUE_OPTIONS);
  }, [index, setValue]);

  const handleRemoveDates = useCallback(() => {
    setShowDatePicker(false);
    setValue(`activityValues.${index}.fromDate`, null, DATE_SET_VALUE_OPTIONS);
    setValue(`activityValues.${index}.toDate`, null, DATE_SET_VALUE_OPTIONS);
  }, [index, setValue]);

  // Trigger Date validation when dates change
  useEffect(() => {
    void trigger(`activityValues.${index}.fromDate`);
  }, [index, toDate, trigger]);

  // Read & Edit Mode
  const clickedFieldName = useRef<keyof IBaseActivityValueData | null>(null);
  const handleStartEditing = useCallback(
    (fieldName: keyof IBaseActivityValueData | null) => () => {
      clickedFieldName.current = fieldName;
      onStartEditing();
    },
    [onStartEditing],
  );

  const renderField = useCallback(
    (readContent: ReactElement, editContent: ReactElement) => {
      return mode === IGhgActivityValuesTableMode.Read ? readContent : editContent;
    },
    [mode],
  );

  const showEmissionFactorSelect = useMemo(
    () =>
      // be sure to have at least 2 unique emission factors to show the select
      ArrayUtilities.deduplicatePrimArray(
        [...selectableEmissionFactorIds, emissionsFactorId].filter((v) => v !== null),
      ).length > 1,
    [selectableEmissionFactorIds, emissionsFactorId],
  );

  return (
    <TableRow
      sx={
        mode === IGhgActivityValuesTableMode.Read
          ? {
              [`.${DELETE_ROW_BUTTON_CLASS}`]: {
                opacity: 0,
              },
              ":hover": {
                [`.${DELETE_ROW_BUTTON_CLASS}`]: {
                  opacity: 1,
                },
              },
            }
          : undefined
      }
    >
      <InternalTableCell mode={mode} onStartEditing={handleStartEditing("emissionFactorId")}>
        <Box minWidth={160}>
          <Controller
            control={control}
            name={`activityValues.${index}.emissionFactorId`}
            rules={{
              required: t("error_field_required", { ns: "common" }),
            }}
            render={({ field, fieldState: { error } }) =>
              renderField(
                <EmissionFactorName
                  organizationId={organizationId}
                  emissionFactorId={field.value ?? ""}
                />,
                showEmissionFactorSelect ? (
                  <FormControl error={!!error}>
                    <Select
                      variant="standard"
                      size="small"
                      autoFocus={clickedFieldName.current === "emissionFactorId"}
                      value={field.value ?? ""}
                      onChange={field.onChange}
                      fullWidth
                      sx={{ minWidth: 120 }}
                      error={!!error}
                      // Only allow if emission factors are available
                      disabled={disabled || selectableEmissionFactorIds.length < 1}
                      displayEmpty
                      renderValue={(selectedId) =>
                        selectedId === "" ? (
                          <Box component="span" sx={{ opacity: 0.5, fontStyle: "italic" }}>
                            {t("table.heading_factor")}
                          </Box>
                        ) : (
                          <EmissionFactorName
                            organizationId={organizationId}
                            emissionFactorId={selectedId}
                          />
                        )
                      }
                    >
                      {selectableEmissionFactorIds.map((emissionFactorId) => (
                        <MenuItem key={emissionFactorId} value={emissionFactorId}>
                          <EmissionFactorName
                            organizationId={organizationId}
                            emissionFactorId={emissionFactorId}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                ) : (
                  <EmissionFactorName
                    organizationId={organizationId}
                    emissionFactorId={field.value ?? ""}
                  />
                ),
              )
            }
          />
        </Box>
      </InternalTableCell>
      <InternalTableCell mode={mode} onStartEditing={handleStartEditing("value")}>
        <Controller
          control={control}
          name={`activityValues.${index}.value`}
          rules={{
            required: t("error_field_required", { ns: "common" }),
          }}
          render={({ field, fieldState: { error } }) =>
            renderField(
              <Typography>
                {FormatUtilities.formatNumber(
                  MappingUtilities.mapIfNotNull(field.value, (v) => +v),
                )}
              </Typography>,
              <TextField
                size="small"
                variant="standard"
                type="number"
                autoFocus={clickedFieldName.current === "value" || isNewlyAdded}
                value={field.value ?? ""}
                onChange={(value) => field.onChange(value.currentTarget.value || null)}
                disabled={disabled}
                error={!!error}
                helperText={error ? error.message : undefined}
                sx={{ width: 130 }}
              />,
            )
          }
        />
      </InternalTableCell>
      <InternalTableCell mode={mode} onStartEditing={handleStartEditing(null)}>
        {emissionsFactorId ? (
          <QueryWrapper
            query={emissionFactorQuery}
            loadingOverride={() => <Skeleton variant="text" />}
            build={(emissionFactor) => (
              <EmissionFactorUnitNameShortWithTooltip unit={emissionFactor.unit} />
            )}
          />
        ) : (
          "-"
        )}
      </InternalTableCell>
      <InternalTableCell mode={mode} onStartEditing={handleStartEditing("dataQuality")}>
        <Controller
          control={control}
          name={`activityValues.${index}.dataQuality`}
          render={({ field, fieldState: { error } }) =>
            renderField(
              <GhgActivityValueDataQuality dataQuality={field.value} />,
              <FormControl>
                <Select
                  variant="standard"
                  size="small"
                  autoFocus={clickedFieldName.current === "dataQuality"}
                  value={field.value ?? ""}
                  onChange={(evt) => field.onChange(evt.target.value || null)}
                  placeholder={t("table.heading_data_quality")}
                  fullWidth
                  sx={{ minWidth: 120 }}
                  error={!!error}
                  disabled={disabled}
                  displayEmpty
                >
                  {/* No Data Quality */}
                  <MenuItem value="">
                    <Box component="span" sx={{ opacity: 0.5, fontStyle: "italic" }}>
                      <GhgActivityValueDataQuality dataQuality={null} />
                    </Box>
                  </MenuItem>
                  {/* Data Quality Icons */}
                  {ORDERED_ACTIVITY_VALUE_DATA_QUALITY.map((dataQuality) => {
                    return (
                      <MenuItem key={dataQuality} value={dataQuality}>
                        <GhgActivityValueDataQuality dataQuality={dataQuality} />
                      </MenuItem>
                    );
                  })}
                </Select>
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>,
            )
          }
        />
      </InternalTableCell>
      <InternalTableCell mode={mode} onStartEditing={handleStartEditing("fromDate")}>
        {renderField(
          // Read Only
          <Typography>
            {fromDate && toDate ? FormatUtilities.formatDateRange(fromDate, toDate) : "-"}
          </Typography>,
          // Edit
          showDatePicker ? (
            <Box display="flex" alignItems="center" gap={2}>
              <Tooltip title={t("table.remove_dates")} placement="top">
                <span>
                  <IconButton onClick={handleRemoveDates} disabled={disabled}>
                    <RemoveDateRangeIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Controller
                control={control}
                name={`activityValues.${index}.fromDate`}
                rules={{
                  required: t("error_invalid_timespan", { ns: "common" }),
                  validate: (fromDate, { activityValues }) => {
                    const toDate = activityValues[index].toDate;
                    if (
                      toDate &&
                      fromDate &&
                      DateTime.fromISO(fromDate) > DateTime.fromISO(toDate)
                    ) {
                      return t("error_invalid_timespan", { ns: "common" });
                    }
                    return undefined;
                  },
                }}
                render={({ field: fromDateField, fieldState: { error: fromDateError } }) => (
                  <Controller
                    control={control}
                    name={`activityValues.${index}.toDate`}
                    rules={{
                      required: t("error_invalid_timespan", { ns: "common" }),
                    }}
                    render={({ field: toDateField, fieldState: { error: toDateError } }) => (
                      <FormControl error={!!(fromDateError || toDateError)}>
                        <DateRangePicker
                          value={{
                            startDate: fromDateField.value
                              ? DateTime.fromISO(fromDateField.value)
                              : null,
                            endDate: toDateField.value ? DateTime.fromISO(toDateField.value) : null,
                          }}
                          onChange={(newValue) => {
                            fromDateField.onChange(
                              DateUtilities.serializeDateTime(newValue.startDate),
                            );
                            toDateField.onChange(DateUtilities.serializeDateTime(newValue.endDate));
                          }}
                          disabled={disabled}
                        />
                        {(fromDateError || toDateError) && (
                          <FormHelperText sx={{ ml: 0 }}>
                            {(fromDateError || toDateError)?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                )}
              />
            </Box>
          ) : (
            <Tooltip title={t("table.add_dates")} placement="top">
              <span>
                <IconButton onClick={handleAddDates} disabled={disabled}>
                  <AddDateRangeIcon />
                </IconButton>
              </span>
            </Tooltip>
          ),
        )}
      </InternalTableCell>
      <InternalTableCell mode={mode} onStartEditing={handleStartEditing("note")}>
        <Controller
          control={control}
          name={`activityValues.${index}.note`}
          render={({ field }) =>
            renderField(
              <Typography sx={{ whiteSpace: "pre-line" }}>{field.value}</Typography>,
              <TextField
                size="small"
                variant="standard"
                autoFocus={clickedFieldName.current === "note"}
                value={field.value ?? ""}
                onChange={(value) => field.onChange(value || null)}
                disabled={disabled}
                multiline
                minRows={1}
                maxRows={5}
                sx={{ minWidth: 160 }}
              />,
            )
          }
        />
      </InternalTableCell>
      <TableCell align="right">
        <IconButton
          className={DELETE_ROW_BUTTON_CLASS}
          onClick={onDelete}
          disabled={disabled}
          sx={{ transition: "opacity 150ms ease" }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

interface IInternalTableCellProps {
  mode: IGhgActivityValuesTableMode;
  onStartEditing: VoidFunction;
}

const InternalTableCell: FC<PropsWithChildren<IInternalTableCellProps>> = ({
  mode,
  onStartEditing,
  children,
}) => {
  const readOnlyMode = mode === IGhgActivityValuesTableMode.Read;

  return (
    <TableCell
      onClick={readOnlyMode ? onStartEditing : undefined}
      sx={
        readOnlyMode
          ? {
              cursor: "pointer",
              ":hover": { backgroundColor: HOVER_BACKGROUND_COLOR },
            }
          : undefined
      }
    >
      {children}
    </TableCell>
  );
};
