import { FC, useCallback } from "react";
import { DmaUtilities } from "@netcero/netcero-common";
import { FileUtilities } from "../../common/utilities/file.utilities";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import {
  BasicSnackbarApiActionType,
  IAppSnackbarEnqueueBasicSuccessMessageData,
} from "../../app-snackbar/app-snackbar.interfaces";
import { ExportButton } from "../../export/export-button.component";
import { ExportTypes } from "../../export/export.constants";
import { useExportIrosMutation } from "../../export/export.mutations";

interface IDmaExportButtonProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
}

const EXPORT_DMA_IROS_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.EXPORT_DMA_IROS,
};

export const IroExportButton: FC<IDmaExportButtonProps> = ({
  recordingPeriodId,
  dataEntryObjectId,
  organizationId,
}) => {
  const appSnackbar = useAppSnackbar();

  const { mutateAsync, isPending } = useExportIrosMutation(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
  );

  const handleDownload = useCallback(async () => {
    await appSnackbar.wrapApiPromise(async () => {
      const data = await mutateAsync();

      FileUtilities.downloadCsvStringAsUtf8CsvFile(data, DmaUtilities.getIroExportFileName());
    }, EXPORT_DMA_IROS_ACTION);
  }, [appSnackbar, mutateAsync]);

  return (
    <ExportButton
      handleDownload={handleDownload}
      exportType={ExportTypes.Iros}
      isLoading={isPending}
    />
  );
};
