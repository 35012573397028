import { OrganizationRole } from "./roles.enum";

// General Constants
export const ORG_BASE_PERMISSIONS = ["view-roles"] as const;

export const ORG_ROLES_ASCENDING = [
  OrganizationRole.VIEW_MEMBERS,
  OrganizationRole.OBJECT_ADMIN,
  OrganizationRole.ORGANIZATION_ADMIN,
];
export const ORG_ROLES_DESCENDING = [...ORG_ROLES_ASCENDING].reverse();

// Specific Authentication Rules

export const ROLE_ACCESS = {
  ALLOWED_TO_MODIFY_DEO_ACCESS: [
    OrganizationRole.ORGANIZATION_ADMIN,
    OrganizationRole.OBJECT_ADMIN,
  ],
  ALLOWED_TO_ACCESS_GAP_ANALYSIS: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_ACCESS_DMA_DASHBOARD: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_COMPLETE_DMA: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_ACCESS_GLOBAL_AUDIT_LOG: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_MODIFY_DMA_ESRS_TOPICS: [OrganizationRole.ORGANIZATION_ADMIN],
  /**
   * Currently used to limit actions on DMA categories (rename, validate, feedback, opt out etc.)
   * Also includes creating, updating and deleting IROs AS well as moving IROs and whether materiality chip is shown in UI
   */
  ALLOWED_TO_MODIFY_DMA_CATEGORIES: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_MODIFY_ALL_IROS: [OrganizationRole.ORGANIZATION_ADMIN],

  ALLOWED_TO_MODIFY_DMA_CONFIGURATION: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_MODIFY_CONFIGURATION_RECORDING_PERIOD: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_MODIFY_EMISSION_FACTORS: [
    OrganizationRole.ORGANIZATION_ADMIN,
    OrganizationRole.OBJECT_ADMIN,
  ],

  ALLOWED_TO_ACCESS_ALL_PTA: [OrganizationRole.ORGANIZATION_ADMIN],

  ALLOWED_TO_MODIFY_GHG_CATEGORY_RESPONSIBLE_USER: [OrganizationRole.ORGANIZATION_ADMIN],
  /**
   * The organization admin is allowed to modify the contributing users and the status of
   * ghg categories *for sure*.
   * The (modifying) access for all other roles depends on whether they are `responsible person`
   * or not.
   */
  ALLOWED_TO_MODIFY_GHG_CATEGORY_CONTRIBUTING_USERS: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_MODIFY_GHG_CATEGORY_STATUS_IN_ANY_CASE: [
    OrganizationRole.ORGANIZATION_ADMIN,
    OrganizationRole.OBJECT_ADMIN,
  ],

  /** Access rules for Activities */
  ALLOWED_TO_CREATE_ACTIVITIES: [
    OrganizationRole.ORGANIZATION_ADMIN,
    OrganizationRole.OBJECT_ADMIN,
  ],
  ALLOWED_TO_EDIT_ACTIVITIES: [OrganizationRole.ORGANIZATION_ADMIN, OrganizationRole.OBJECT_ADMIN],
  ALLOWED_TO_SEE_ALL_ACTIVITIES: [OrganizationRole.ORGANIZATION_ADMIN],

  /** These roles can view the ghg dashboard */
  ALLOWED_TO_ACCESS_GHG_DASHBOARD: [
    OrganizationRole.ORGANIZATION_ADMIN,
    OrganizationRole.OBJECT_ADMIN,
  ],

  /** GHG Categories */
  ALLOWED_TO_EXCLUDE_GHG_CATEGORY: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_INCLUDE_GHG_CATEGORY: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_UPDATE_GHG_CATEGORY_MATERIALITY: [OrganizationRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_ACCESS_INVENTORY_SCREENING: [OrganizationRole.ORGANIZATION_ADMIN],

  /** Sources */
  ALLOWED_TO_MODIFY_PRIVATE_SOURCES: [OrganizationRole.ORGANIZATION_ADMIN],
};
