import { FC, useMemo } from "react";
import {
  BasicSnackbarApiActionType,
  IAppSnackbarEnqueueBasicSuccessMessageData,
} from "../../app-snackbar/app-snackbar.interfaces";
import { ExportButton } from "../../export/export-button.component";
import { ExportTypes } from "../../export/export.constants";
import { IDMAOverviewTableItemData } from "./dma-overview-table.utilities";
import { DmaFormatUtilities } from "../../double-materiality-assessment/utilities/dma-format.utilities";
import { useTranslation } from "react-i18next";
import { useCsvExport } from "../../export/csv-export.hook";
import { DmaUtilities } from "@netcero/netcero-common";

const EXPORT_DMA_OVERVIEW_TABLE: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.EXPORT_OVERVIEW_TABLE,
};

const getExportFilename = () => DmaUtilities.getDmaOverviewExportFileName();

interface IDMAOverviewTableExportButton {
  data: IDMAOverviewTableItemData[];
}

export const DMAOverviewTableExportButton: FC<IDMAOverviewTableExportButton> = ({ data }) => {
  const { t } = useTranslation("dma_dashboard");

  const csvOptions = useMemo(
    () => ({
      headers: [
        t("header_name"),
        `${t("table_header_material_impacts_group")} ${t("header_materiality_short_term")}`,
        `${t("table_header_material_impacts_group")} ${t("header_materiality_medium_term")}`,
        `${t("table_header_material_impacts_group")} ${t("header_materiality_long_term")}`,
        `${t("table_header_material_impacts_group")} ${t("header_materiality_max")}`,
        `${t("table_header_financial_effects_group")} ${t("header_materiality_short_term")}`,
        `${t("table_header_financial_effects_group")} ${t("header_materiality_medium_term")}`,
        `${t("table_header_financial_effects_group")} ${t("header_materiality_long_term")}`,
        `${t("table_header_financial_effects_group")} ${t("header_materiality_max")}`,
        t("header_max_materiality_degree"),
        t("header_material"),
      ],
      rowBuilder: (item: IDMAOverviewTableItemData) => [
        item.name,
        DmaFormatUtilities.formatMaterialityDegree(item.materialMaterialityDegree.shortTerm),
        DmaFormatUtilities.formatMaterialityDegree(item.materialMaterialityDegree.mediumTerm),
        DmaFormatUtilities.formatMaterialityDegree(item.materialMaterialityDegree.longTerm),
        DmaFormatUtilities.formatMaterialityDegree(item.materialMaterialityDegree.maxValue),
        DmaFormatUtilities.formatMaterialityDegree(item.financialMaterialityDegree.shortTerm),
        DmaFormatUtilities.formatMaterialityDegree(item.financialMaterialityDegree.mediumTerm),
        DmaFormatUtilities.formatMaterialityDegree(item.financialMaterialityDegree.longTerm),
        DmaFormatUtilities.formatMaterialityDegree(item.financialMaterialityDegree.maxValue),
        DmaFormatUtilities.formatMaterialityDegree(item.maxMaterialityDegree),
        t(`materiality_value_${item.isMaterial}`),
      ],
      transformFunction: (items: IDMAOverviewTableItemData[]) => {
        const flattenRecursive = (
          nodes: IDMAOverviewTableItemData[],
        ): IDMAOverviewTableItemData[] => {
          return nodes.reduce((acc, node) => {
            acc.push(node);
            if (node.children && node.children.length > 0) {
              acc.push(...flattenRecursive(node.children));
            }
            return acc;
          }, [] as IDMAOverviewTableItemData[]);
        };
        return flattenRecursive(items);
      },
    }),
    [t],
  );

  const { exportCsv } = useCsvExport(
    data,
    csvOptions,
    getExportFilename,
    EXPORT_DMA_OVERVIEW_TABLE,
  );

  return <ExportButton handleDownload={exportCsv} exportType={ExportTypes.DmaOverviewTable} />;
};
