import { useMemo } from "react";
import { useAuthenticatedUserOrganizationRole } from "../../user/hooks/authenticated-user-org-role.hook";
import { OrganizationRole } from "@netcero/netcero-common";

/**
 * Checks whether the user has at least one of the provided roles. Uses `useAuthenticatedUserOrganizationRole` under the hood.
 * Therefore, the organizationId is taken from the router params.
 * @param roles
 */
export const useDoesUserHaveRole = (roles: OrganizationRole[]) => {
  const authenticatedUserOrgRole = useAuthenticatedUserOrganizationRole();
  return useMemo(
    () => authenticatedUserOrgRole !== null && roles.includes(authenticatedUserOrgRole),
    [authenticatedUserOrgRole, roles],
  );
};
