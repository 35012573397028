export interface IActivityValuesImportEmissionFactorRowData {
  identifier: string;
  emissionFactorId: string;
  unit: string;
}

export type IActivityValuesImportEmissionFactorLookup = Record<
  string,
  IActivityValuesImportEmissionFactorRowData | undefined
>;

export interface IActivityValuesImportSimpleValueRowData {
  emissionFactorIdentifier: string;
  value: number;
}

export interface IActivityValuesImportSimpleValueRowDataWithEmissionFactor {
  value: number;
  emissionFactorId: string;
}

// ERRORS

export enum ActivityValuesImportError {
  /** Used to support generic, unknown Errors */
  UNKNOWN = "UNKNOWN",
  INVALID_IMPORT_FILE_SHEETS = "INVALID_IMPORT_FILE_SHEETS",
  INVALID_EMISSION_FACTOR_ROW_FORMAT = "INVALID_EMISSION_FACTOR_ROW_FORMAT",
  EMISSION_FACTOR_IDENTIFIER_NOT_UNIQUE = "EMISSION_FACTOR_IDENTIFIER_NOT_UNIQUE",
  INVALID_VALUE_ROW_FORMAT = "INVALID_VALUE_ROW_FORMAT",
  UNKNOWN_EMISSION_FACTOR_IDENTIFIER = "UNKNOWN_EMISSION_FACTOR_IDENTIFIER",
}

export interface IActivityValuesImportErrorInformation {
  error: ActivityValuesImportError;
  rowIndex: number | null;
}

export interface IActivityValuesImportResultWithErrors<T> {
  errors: IActivityValuesImportErrorInformation[];
  result: T;
}
