import { useMutation } from "@tanstack/react-query";
import { useInvalidateQueriesByKeys } from "../common/hooks/invalidate-queries-by-keys.hook";
import { getActivityValuesQueryKey } from "./ghg-activity-values.queries";
import { IGhgActivityIdentity, IGhgActivityValueIdentity } from "@netcero/netcero-common";
import { IBaseActivityValueData } from "@netcero/netcero-core-api-client";
import { CoreApiService } from "../core-api/core-api.service";
import { getGhgCategoryActivitiesQueryKey } from "../ghg-activities/ghg-activities.queries";
import { useCallback } from "react";
import { getGhgEvaluationQueryKey } from "../ghg-evaluation/ghg-evaluation.queries";

export const useInvalidateQueriesAffectedByActivityValuesMutations = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useCallback(
    async (_: unknown, variables: IGhgActivityIdentity, __: unknown) =>
      invalidateQueries({
        awaitQueryKeys: [
          getActivityValuesQueryKey(variables),
          getGhgCategoryActivitiesQueryKey(variables),
        ],
        nonAwaitQueryKeys: [getGhgEvaluationQueryKey(variables)],
      }),
    [invalidateQueries],
  );
};

interface IGhgActivityValueCreatePayload extends IGhgActivityIdentity {
  payload: IBaseActivityValueData;
}

export const useCreateGhgActivityValueMutation = () => {
  const invalidateQueries = useInvalidateQueriesAffectedByActivityValuesMutations();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
      payload,
    }: IGhgActivityValueCreatePayload) =>
      CoreApiService.ActivityValuesApi.createActivityValue(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: invalidateQueries,
  });
};

interface IGhgActivityValueUpdatePayload extends IGhgActivityValueIdentity {
  payload: IBaseActivityValueData;
}

export const useUpdateGhgActivityValueMutation = () => {
  const invalidateQueries = useInvalidateQueriesAffectedByActivityValuesMutations();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
      valueId,
      payload,
    }: IGhgActivityValueUpdatePayload) =>
      CoreApiService.ActivityValuesApi.updateActivityValue(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
        valueId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: invalidateQueries,
  });
};

export const useDeleteGhgActivityValueMutation = () => {
  const invalidateQueries = useInvalidateQueriesAffectedByActivityValuesMutations();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
      valueId,
    }: IGhgActivityValueIdentity) =>
      CoreApiService.ActivityValuesApi.deleteActivityValue(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
        valueId,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: invalidateQueries,
  });
};
