import {
  ApiDataCleanupUtilities,
  ContentTranslationUtilities,
  EmissionFactorsUtilities,
  GhgCategoriesUtilities,
  MappingUtilities,
  OptionalPropertiesToNullRecursive,
} from "@netcero/netcero-common";
import {
  IBaseEmissionFactorData,
  IContentLanguage,
  IEmissionFactor,
  IEmissionFactorUnit,
  IGhgCategoriesIdentifier,
  IGhgProtocolScopeEnum,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditDialogWrapper } from "../../common/dialogs/variants/edit-dialog.wrapper";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CountrySelectComponent } from "../../common/components/country-select.component";
import { useGetEmissionFactorQuery } from "../emission-factors.queries";
import { DeleteIcon, PencilIcon } from "../../common/constants/tabler-icon.constants";
import { EmissionFactorLinkToLocationBasedEditDialog } from "./emission-factor-link-to-location-based.dialog";
import { EmissionFactorChip } from "../components/emission-factor-chip.component";
import {
  DEFAULT_EMISSION_FACTOR_TYPE,
  ORDERED_EMISSION_FACTOR_TYPE_FOR_EDIT_DIALOG,
  ORDERED_EMISSION_FACTOR_UNITS_FOR_EDIT_DIALOG,
  ORDERED_MARKET_BASED_EMISSION_FACTOR_TYPES,
} from "../emission-factors.constants";
import { GhgCategoriesChips } from "../../ghg-categories/components/ghg-category-chip.component";
import { EmissionFactorUnitNameShortWithTooltip } from "../components/emission-factor-unit-name.component";
import { HookFormControllerUtilities } from "../../common/input-validation/hook-form-controller.utilities";
import { useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { useRenderGhgCategoryName } from "../../ghg-protocol/ghg-protocol.hooks";

export type IEmissionFactorEditDialogData = Omit<
  OptionalPropertiesToNullRecursive<IBaseEmissionFactorData>,
  "title" | "description" | "database" | "scopeFactors" | "factor" | "supplier"
> & {
  title: string;
  description: string;
  factor: string;
  add_scope_3: boolean;
  scope_3_factor: string | null;
  supplier: string;
};

const getFormDefaultValues = (
  emissionFactor: IEmissionFactor | undefined | null,
): IEmissionFactorEditDialogData => {
  const currentYear = new Date().getFullYear();
  const result: IEmissionFactorEditDialogData = {
    title: emissionFactor?.title.translationsPerLanguage[IContentLanguage.De] ?? "",
    description: emissionFactor?.description?.translationsPerLanguage[IContentLanguage.De] ?? "",
    version: emissionFactor?.version ?? currentYear.toString(),
    ghgCategories: emissionFactor?.ghgCategories ?? [],
    emissionFactorType: emissionFactor?.emissionFactorType ?? DEFAULT_EMISSION_FACTOR_TYPE,
    marketBasedEmissionFactorType: emissionFactor?.marketBasedEmissionFactorType ?? null,
    supplier: emissionFactor?.supplier ?? "",
    factor: emissionFactor?.factor?.toString() ?? "",
    unit: emissionFactor?.unit ?? IEmissionFactorUnit.KWh,
    country: emissionFactor?.country ?? null,
    add_scope_3: emissionFactor?.scopeFactors[IGhgProtocolScopeEnum.Scope3] !== undefined,
    scope_3_factor: emissionFactor?.scopeFactors[IGhgProtocolScopeEnum.Scope3]?.toString() ?? "",
    relatedLocationBasedEmissionFactorId:
      emissionFactor?.relatedLocationBasedEmissionFactorId ?? null,
  };

  return result;
};

const transformFormToApiData = (
  formData: IEmissionFactorEditDialogData,
): IBaseEmissionFactorData => {
  const isMarketBased = EmissionFactorsUtilities.isEmissionFactorTypeMarketBased(
    formData.emissionFactorType,
  );
  const isInScope3 = formData.ghgCategories.some((category) =>
    GhgCategoriesUtilities.isCategoryInScope(category, IGhgProtocolScopeEnum.Scope3),
  );

  return {
    title: ContentTranslationUtilities.createTranslations(() => formData.title.trim()),
    description: MappingUtilities.mapIfNotUndefined(
      ApiDataCleanupUtilities.cleanUpString(formData.description),
      (description) => ContentTranslationUtilities.createTranslations(() => description),
    ),
    version: formData.version,
    ghgCategories: formData.ghgCategories,
    emissionFactorType: formData.emissionFactorType,
    marketBasedEmissionFactorType: isMarketBased
      ? formData.marketBasedEmissionFactorType ?? undefined
      : undefined,
    supplier: ApiDataCleanupUtilities.cleanUpString(formData.supplier),
    factor: +formData.factor,
    scopeFactors: {
      [IGhgProtocolScopeEnum.Scope3]:
        !isInScope3 && formData.add_scope_3
          ? +(formData.scope_3_factor ?? "0") || undefined // Also handle 0 values as no value provided
          : undefined,
    },
    unit: formData.unit,
    country: formData.country ?? undefined,
    relatedLocationBasedEmissionFactorId:
      formData.relatedLocationBasedEmissionFactorId ?? undefined,
  };
};

interface IEmissionFactorEditDialogPropsBase {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IBaseEmissionFactorData | null) => void;
  organizationId: string;
}

interface IEmissionFactorEditDialogPropsCreate extends IEmissionFactorEditDialogPropsBase {
  emissionFactorId?: undefined;
}

interface IEmissionFactorEditDialogPropsEdit extends IEmissionFactorEditDialogPropsBase {
  emissionFactorId: string | null;
}

type IEmissionFactorEditDialogProps =
  | IEmissionFactorEditDialogPropsCreate
  | IEmissionFactorEditDialogPropsEdit;
export const EmissionFactorEditDialog: FC<IEmissionFactorEditDialogProps> = ({
  disabled: parentDisabled,
  onClose,
  error,
  open,
  loading: parentLoading,
  emissionFactorId,
  organizationId,
}) => {
  const mode = emissionFactorId ? "edit" : "create";

  const { t } = useTranslation("emission_factor_dialog");
  const renderGhgCategoryName = useRenderGhgCategoryName();

  const {
    isOpen: showLinkToLocationBasedDialog,
    openDialog: openLinkToLocationBasedDialog,
    closeDialog: closeLinkToLocationBasedDialog,
  } = useDialogStateWithoutData();

  const emissionFactorQueryData = useGetEmissionFactorQuery(
    {
      organizationId,
      emissionFactorId: emissionFactorId ?? "",
    },
    open,
  );

  const loading = parentLoading || emissionFactorQueryData?.isFetching;
  const disabled = parentDisabled || emissionFactorQueryData?.isFetching;

  const emissionFactor = !emissionFactorQueryData.isFetching
    ? emissionFactorQueryData?.data
    : undefined;

  const {
    control,
    handleSubmit,
    formState: { isDirty, submitCount },
    reset,
    watch,
    setValue,
    trigger,
    getValues,
  } = useForm<IEmissionFactorEditDialogData>({
    defaultValues: getFormDefaultValues(emissionFactor),
  });

  const unit = watch("unit");
  const emissionFactorType = watch("emissionFactorType");
  const relatedLocationBasedEmissionFactorId = watch("relatedLocationBasedEmissionFactorId");
  const addScope3 = watch("add_scope_3");
  const ghgCategories = watch("ghgCategories");
  const isScope3InGhgCategories = useMemo(
    () =>
      ghgCategories.some((category) =>
        GhgCategoriesUtilities.isCategoryInScope(category, IGhgProtocolScopeEnum.Scope3),
      ),
    [ghgCategories],
  );

  const isMarketBased =
    EmissionFactorsUtilities.isEmissionFactorTypeMarketBased(emissionFactorType);

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(emissionFactor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emissionFactor, open]);

  const onSubmit = useCallback(
    (formData: IEmissionFactorEditDialogData) => {
      const emissionFactorData = transformFormToApiData(formData);
      onClose(emissionFactorData);
    },
    [onClose],
  );

  return (
    <>
      {/* Link Emission Factor Dialog */}
      <EmissionFactorLinkToLocationBasedEditDialog
        organizationId={organizationId}
        relatedLocationBasedEmissionFactorId={relatedLocationBasedEmissionFactorId}
        forEmissionFactorId={emissionFactorId}
        emissionFactorUnit={unit}
        open={showLinkToLocationBasedDialog}
        onClose={closeLinkToLocationBasedDialog}
        onChange={(emissionFactorId, selected) => {
          if (!selected) {
            setValue("relatedLocationBasedEmissionFactorId", null, {
              shouldDirty: true,
            });
          } else {
            setValue("relatedLocationBasedEmissionFactorId", emissionFactorId, {
              shouldDirty: true,
            });
          }
          // Trigger validation if the user has already tried to submit the form (so errors are cleared/shown)
          if (submitCount > 0) {
            void trigger("relatedLocationBasedEmissionFactorId");
          }
        }}
      />

      {/* Actual Edit Dialog */}
      <EditDialogWrapper
        open={open}
        mode={mode}
        loading={loading}
        hasChanges={isDirty}
        disabled={disabled}
        onCancel={() => onClose(null)}
        onSave={handleSubmit(onSubmit)}
        error={error}
        dialogProps={{
          fullWidth: true,
          maxWidth: "md",
        }}
        title={t(`${mode}_emission_factor`)}
      >
        <Box display="flex" flexDirection="column" gap={4} mt={1}>
          <Box display="flex" flexDirection="column" gap={1.5}>
            {/* Name Input */}
            <Controller
              control={control}
              name="title"
              rules={HookFormControllerUtilities.createRequiredStringRules(
                t("error_field_required", { ns: "common" }),
              )}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t("name")}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                />
              )}
            />
            {/* Country Select and Version Input */}
            <Box display="flex" flexDirection="row" gap={2}>
              <Controller
                control={control}
                name="country"
                render={({ field }) => (
                  <CountrySelectComponent
                    type="nullable"
                    value={field.value}
                    onValueChange={(value) => {
                      field.onChange(value);
                    }}
                    label={t("country")}
                    fullWidth
                    disabled={disabled}
                  />
                )}
              />
              <Controller
                control={control}
                name="version"
                rules={HookFormControllerUtilities.createRequiredStringRules(
                  t("error_field_required", { ns: "common" }),
                )}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t("version")}
                    error={!!error}
                    helperText={error?.message}
                    disabled={disabled}
                    sx={{ width: "50%" }}
                  />
                )}
              />
            </Box>
            <Box display="flex" flexDirection="row" gap={2}>
              {/* Type */}
              <Controller
                control={control}
                name="emissionFactorType"
                rules={{
                  required: t("error_field_required", { ns: "common" }),
                }}
                render={({ field }) => (
                  <FormControl disabled={disabled} sx={{ flex: 1 }}>
                    <InputLabel id="emission-factor-type-label">{t("type")}</InputLabel>
                    <Select
                      {...field}
                      labelId="emission-factor-type-label"
                      label={t("type")}
                      disabled={disabled}
                      renderValue={(selected) => (
                        <Box>{t(selected, { ns: "emission_factor_types" })}</Box>
                      )}
                    >
                      {ORDERED_EMISSION_FACTOR_TYPE_FOR_EDIT_DIALOG.map((type) => (
                        <MenuItem key={type} value={type}>
                          <Box>{t(type, { ns: "emission_factor_types" })}</Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              {/* Market-based Emission Factor Type - only shown if emissionFactorType is MarketBased */}
              {isMarketBased ? (
                <Box display="flex" flexDirection="column" gap={1.5} flex={1}>
                  <Controller
                    control={control}
                    name="marketBasedEmissionFactorType"
                    rules={{
                      required: t("error_field_required", { ns: "common" }),
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} disabled={disabled}>
                        <InputLabel id="market-based-emission-factor-type-label">
                          {t("market_based_emission_factor_type")}
                        </InputLabel>
                        <Select
                          {...field}
                          value={field.value ?? ""}
                          labelId="market-based-emission-factor-type-label"
                          label={t("market_based_emission_factor_type")}
                          disabled={disabled}
                          renderValue={(selected) =>
                            selected && (
                              <Box>{t(selected, { ns: "market_based_emission_factor_types" })}</Box>
                            )
                          }
                        >
                          {ORDERED_MARKET_BASED_EMISSION_FACTOR_TYPES.map((type) => (
                            <MenuItem key={type} value={type}>
                              <Box>{t(type, { ns: "market_based_emission_factor_types" })}</Box>
                            </MenuItem>
                          ))}
                        </Select>
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                  <Box
                    display="flex"
                    sx={{
                      flexDirection: { sm: "column", md: "row" },
                      alignItems: "start",
                      justifyContent: { md: "space-between" },
                      gap: { sm: 0, md: 2 },
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        minWidth: "200px",
                        alignItems: "center",
                      }}
                    >
                      {t("related_location_based_emission_factor")}
                      {/* TODO: NC-1925 add intercom reference widget */}
                      {/* <IntercomReferenceWidget
                          entityType={IIntercomEntity.EmissionFactor}
                          identifier={`dialog_related_emission_factor_help.${emissionFactorType}`}
                          renderIfNoArticleFound={
                            <IntercomReferenceWidget
                              entityType={IIntercomEntity.EmissionFactor}
                              identifier="dialog_related_emission_factor_help.common"
                            />
                          }
                        /> */}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="start"
                      gap={0.5}
                      sx={{ maxWidth: { sm: "100%", md: "50%" } }}
                    >
                      <Controller
                        control={control}
                        name="relatedLocationBasedEmissionFactorId"
                        rules={{
                          required: EmissionFactorsUtilities.isEmissionFactorTypeMarketBased(
                            emissionFactorType,
                          )
                            ? t("error_field_required", { ns: "common" })
                            : undefined,
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl disabled={disabled} error={!!error} sx={{ mt: 0.5 }}>
                            <EmissionFactorChip
                              organizationId={organizationId}
                              emissionFactorId={field.value}
                              onClick={openLinkToLocationBasedDialog}
                              error={!!error}
                              disabled={disabled}
                            />
                            {error && <FormHelperText>{error.message}</FormHelperText>}
                          </FormControl>
                        )}
                      />
                      <Tooltip title={t("link_emission_factor")}>
                        <IconButton size="small" onClick={() => openLinkToLocationBasedDialog()}>
                          <PencilIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              ) : (
                // Spacer to fill half of the row
                <Box flex={1} />
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={1.5}>
            {/* Description */}
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  label={t("description")}
                  multiline
                  minRows={4}
                  maxRows={6}
                  fullWidth
                  disabled={disabled}
                />
              )}
            />

            <Box display="flex" flexDirection="row" gap={2}>
              <Controller
                control={control}
                name="ghgCategories"
                render={({ field }) => (
                  <FormControl fullWidth disabled={disabled}>
                    <InputLabel id="ghg-category-label">{t("ghg_category")}</InputLabel>
                    <Select
                      {...field}
                      multiple
                      labelId="ghg-category-label"
                      label={t("ghg_category")}
                      disabled={disabled}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",

                            flexWrap: "wrap",
                            gap: 1,
                            maxHeight: 100,
                            overflow: "auto",
                          }}
                        >
                          <GhgCategoriesChips ghgCategories={selected} />
                        </Box>
                      )}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                      fullWidth
                    >
                      {Object.values(IGhgCategoriesIdentifier).map((category) => (
                        <MenuItem key={category} value={category}>
                          <Checkbox checked={field.value.includes(category)} />
                          <ListItemText primary={renderGhgCategoryName(category)} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="supplier"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t("supplier")}
                    error={!!error}
                    helperText={error?.message}
                    disabled={disabled}
                    fullWidth
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={1.5}>
            <Box display="flex" flexDirection="row" gap={2}>
              {/* Factor */}
              <Controller
                control={control}
                name="factor"
                rules={{
                  required: t("error_field_required", { ns: "common" }),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("factor")}
                    error={!!error}
                    helperText={error?.message}
                    disabled={disabled}
                    fullWidth
                    sx={{ maxWidth: 320 }}
                  />
                )}
              />
              {/* Unit */}
              <Controller
                control={control}
                name="unit"
                rules={{
                  required: t("error_field_required", { ns: "common" }),
                }}
                render={({ field }) => (
                  <FormControl fullWidth disabled={disabled}>
                    <InputLabel id="unit-label">{t("unit")}</InputLabel>
                    <Select
                      {...field}
                      labelId="unit-label"
                      label={t("unit")}
                      disabled={disabled}
                      renderValue={(selected) => (
                        <EmissionFactorUnitNameShortWithTooltip
                          unit={selected}
                          options={{ withPrefix: true }}
                        />
                      )}
                      fullWidth
                      sx={{ maxWidth: 180 }}
                    >
                      {ORDERED_EMISSION_FACTOR_UNITS_FOR_EDIT_DIALOG.map((type) => (
                        <MenuItem key={type} value={type}>
                          <EmissionFactorUnitNameShortWithTooltip
                            unit={type}
                            options={{ withPrefix: true }}
                            tooltipProps={{ placement: "right" }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
            {!isScope3InGhgCategories &&
              (!addScope3 ? (
                <Controller
                  control={control}
                  name="add_scope_3"
                  render={({ field }) => (
                    <Button
                      onClick={() => field.onChange(!field.value)}
                      disabled={disabled}
                      sx={{ mr: "auto" }}
                    >
                      {t("add_factor_for_indirect_emissions")}
                    </Button>
                  )}
                />
              ) : (
                <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                  <Controller
                    control={control}
                    name="scope_3_factor"
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="number"
                        label={t("scope_3_factor")}
                        error={!!error}
                        helperText={error?.message}
                        disabled={disabled}
                        fullWidth
                        sx={{ maxWidth: 320 }}
                      />
                    )}
                  />
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setValue("add_scope_3", false, { shouldDirty: true });
                        setValue("scope_3_factor", getValues("scope_3_factor") || "", {
                          shouldDirty: true,
                        });
                      }}
                      disabled={disabled}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </EditDialogWrapper>
    </>
  );
};
