import * as React from "react";
import { FC, useCallback, useState } from "react";
import {
  ActivityUtilities,
  ActivityValuesImportError,
  AllowedMimeTypes,
  IActivityValuesImportErrorInformation,
  IActivityValuesImportSimpleValueRowDataWithEmissionFactor,
  IGhgActivityIdentity,
  ObjectUtilities,
} from "@netcero/netcero-common";
import { IActivity } from "@netcero/netcero-core-api-client";
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ActivityDetailsDialogBreadcrumbsComponent } from "../../ghg-activities/components/activity-details-dialog-breadcrumbs.component";
import { FileUtilities } from "../../common/utilities/file.utilities";
import { DialogSecondaryActionButton } from "../../common/dialogs/dialog-button.components";
import { DownloadIcon } from "../../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { FileUploadButton } from "../../common/components/file-upload-button.component";
import { useTranslateGhgActivityValueImportError } from "../../ghg-activity-values/hooks/translate-ghg-activity-value-import-error.hook";
import { GhgActivityValuesImportWebWorkerUtilities } from "../web-workers/ghg-activity-values-import-web-worker.utilities";
import { GhgActivityValuesUploadUtilities } from "../ghg-activity-values-upload.utilities";
import { useCurrentLanguage } from "../../content-translation/hooks/current-language.hook";
import { useQueryClient } from "@tanstack/react-query";

export type IActivityUploadDialogCloseCallback = (
  importedData?: IActivityValuesImportSimpleValueRowDataWithEmissionFactor[],
) => void;

interface IActivityUploadDialogProps {
  activity?: IActivity | null;
  activityIdentity: IGhgActivityIdentity;
  open: boolean;
  onClose: IActivityUploadDialogCloseCallback;
}

export const ActivityUploadDialog: FC<IActivityUploadDialogProps> = ({
  open,
  activity,
  onClose,
  activityIdentity,
}) => {
  const { t: tButtons } = useTranslation("buttons");
  const { t } = useTranslation("ghg_activities", { keyPrefix: "upload" });
  const currentLanguage = useCurrentLanguage();
  const queryClient = useQueryClient();

  const { t: translateTemplateError } = useTranslation("ghg_activities", {
    keyPrefix: "upload.errors_template",
  });
  const [templateState, setTemplateState] = useState<{
    loading: boolean;
    error: ActivityValuesImportError | null;
  }>({ loading: false, error: null });

  const handleDownloadTemplate = useCallback(async () => {
    // Download is only possible for existing activities
    if (!ObjectUtilities.isValuePresent(activity)) {
      return;
    }

    setTemplateState({
      loading: true,
      error: null,
    });
    try {
      const file = await GhgActivityValuesUploadUtilities.generateActivityTemplate(
        activityIdentity,
        activity,
        currentLanguage,
        queryClient,
      );

      FileUtilities.downloadBlobAsFile(
        ActivityUtilities.getActivityTemplateForUploadFileName(activity.title, currentLanguage),
        new Blob([file]),
      );
    } catch (error) {
      setTemplateState({
        loading: false,
        error:
          error instanceof Error &&
          ObjectUtilities.isEnum<ActivityValuesImportError>(
            error.message,
            ActivityValuesImportError,
          )
            ? error.message
            : ActivityValuesImportError.UNKNOWN,
      });
      return;
    }
    // Reset when successful
    setTemplateState({
      loading: false,
      error: null,
    });
  }, [activity, activityIdentity, currentLanguage, queryClient]);

  const translateImportError = useTranslateGhgActivityValueImportError();
  const [importState, setImportState] = useState<{
    loading: boolean;
    errors: IActivityValuesImportErrorInformation[] | null;
  }>({ loading: false, errors: null });

  const handleUploadTemplate = useCallback(
    async (files: FileList) => {
      // Only supports single file
      const file = files[0];
      if (!file) {
        return;
      }
      // Reset errors
      setImportState({ loading: true, errors: null });
      // Run Import
      const data = await file.arrayBuffer();

      const { result, errors } = await GhgActivityValuesImportWebWorkerUtilities.runImport(data);

      if (errors.length > 0) {
        setImportState({ loading: false, errors });
        return;
      }

      setImportState({ loading: false, errors: null });
      onClose(result);
    },
    [onClose],
  );

  // Rendering

  const isLoadingState = templateState.loading || importState.loading;

  return (
    <Dialog
      open={open}
      onClose={!isLoadingState ? () => onClose() : undefined}
      maxWidth="md"
      fullWidth
    >
      {/* Dialog Header */}
      <Box display="flex" alignItems="center" px={2} py={2}>
        <ActivityDetailsDialogBreadcrumbsComponent
          ghgCategoryIdentity={activityIdentity}
          activity={activity}
        />
      </Box>

      {/* Visually separate content */}
      <Divider />

      {/* Loader */}
      {isLoadingState && <LinearProgress />}

      {/* Title */}
      <DialogTitle>{t("dialog_title", { activity })}</DialogTitle>

      {/* Content */}
      <DialogContent>
        {/* Explanation */}
        <FormatTranslation t={t} i18nKey="dialog_explanation" />
        {/* Buttons */}
        <Box display="flex" alignItems="center" justifyContent="center" gap={2} mt={2}>
          <FileUploadButton
            acceptedMimeTypes={[AllowedMimeTypes.XLSX]}
            onChangeFile={handleUploadTemplate}
            alwaysEmitChange
            disabled={isLoadingState}
          >
            {t("button_import_template")}
          </FileUploadButton>
          <DialogSecondaryActionButton
            onClick={handleDownloadTemplate}
            startIcon={<DownloadIcon />}
            disabled={isLoadingState}
          >
            {t("button_download_template")}
          </DialogSecondaryActionButton>
        </Box>
        {/* Error Message */}
        {templateState.error && (
          <Box p={2}>
            <Typography variant="body1" color="error">
              {translateTemplateError(templateState.error)}
            </Typography>
          </Box>
        )}
        {/* Display Import Errors */}
        {importState.errors && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <AlertTitle>{t("error_alert_title")}</AlertTitle>
            <ul>
              {importState.errors.map((importError, index) => (
                <li key={index}>{translateImportError(importError)}</li>
              ))}
            </ul>
          </Alert>
        )}
      </DialogContent>

      {/* Actions */}
      <DialogActions>
        <DialogSecondaryActionButton onClick={() => onClose()} disabled={isLoadingState}>
          {tButtons("close")}
        </DialogSecondaryActionButton>
      </DialogActions>
    </Dialog>
  );
};
