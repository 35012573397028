import { Box, Button, CardContent, styled, Typography } from "@mui/material";
import { IDMAConfiguration, IESRSTopic } from "@netcero/netcero-core-api-client";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { EditIcon } from "../common/constants/tabler-icon.constants";
import { TreeUtilities } from "@netcero/netcero-common";
import { DMADashboardChartsSection } from "./dma-dashboard-charts-section.component";
import { DMADashboardUtilities } from "./dma-dashboard.utilities";
import { DMADashboardBreadcrumbsFilter } from "./dma-matrix/dma-dashboard-breadcrumbs-filter.component";
import { DMAOverviewTable } from "./dma-overview-table/dma-overview-table.component";
import { DmaIroOverviewList } from "./dma-iro-overview-list/dma-iro-overview-list.component";
import { StyledCard } from "../common/components/styled-card.component";
import { useDeoDmaDashboardContext } from "./deo-dma-dashboard.context";
import { AppInternalNavigationUtilities } from "../common/utilities/app-internal-navigation.utilities";
import { FeatureFlag } from "../common/constants/feature-flags.constants";
import { RequireFeatureFlag } from "../common/components/require-feature-flag.component";

const TOPIC_QUERY_PARAM_KEY = "esrsTopicId";
const CATEGORY_QUERY_PARAM_KEY = "dmaCategoryId";

const DmaDashboardCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
  "&:last-child": {
    paddingBottom: theme.spacing(4),
  },
}));

interface IDMADashboardOverviewProps {
  dmaConfiguration: IDMAConfiguration;
  esrsTopics: IESRSTopic[];
}

export const DMADashboardOverview: FC<IDMADashboardOverviewProps> = ({
  dmaConfiguration,
  esrsTopics,
}) => {
  const { t } = useTranslation("dma_dashboard");
  const { organizationId, recordingPeriodId } = useDeoDmaDashboardContext();

  const groupedESRSTopics = useMemo(
    () => DMADashboardUtilities.groupTopicsByArea(esrsTopics),
    [esrsTopics],
  );

  // Filters State Management

  const [searchParams, setSearchParams] = useSearchParams();

  const updateFilters = (esrsTopicId?: string, dmaCategoryId?: string) => {
    const queryESRSTopicId = searchParams.get(TOPIC_QUERY_PARAM_KEY) ?? undefined;
    const queryDMACategoryId = searchParams.get(CATEGORY_QUERY_PARAM_KEY) ?? undefined;

    // Do nothing if the filters are the same
    if (queryESRSTopicId === esrsTopicId && queryDMACategoryId === dmaCategoryId) {
      return;
    }

    setSearchParams((prev) => {
      if (esrsTopicId) {
        prev.set(TOPIC_QUERY_PARAM_KEY, esrsTopicId);
      } else {
        prev.delete(TOPIC_QUERY_PARAM_KEY);
      }

      if (dmaCategoryId) {
        prev.set(CATEGORY_QUERY_PARAM_KEY, dmaCategoryId);
      } else {
        prev.delete(CATEGORY_QUERY_PARAM_KEY);
      }

      return prev;
    });
  };

  const viewedESRSTopic = useMemo(() => {
    const esrsTopicId = searchParams.get(TOPIC_QUERY_PARAM_KEY);
    if (esrsTopicId === null) {
      return null;
    }

    const foundESRSTopic = esrsTopics.find((esrsTopic) => esrsTopic.id === esrsTopicId);

    return foundESRSTopic ?? null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esrsTopics, searchParams]);

  const viewedDMACategory = useMemo(() => {
    const dmaCategoryId = searchParams.get(CATEGORY_QUERY_PARAM_KEY);

    // Only run if we have a viewedESRSTopic and a dmaCategoryId
    if (!viewedESRSTopic?.recordedESRSTopic || dmaCategoryId === undefined) {
      return null;
    }

    // Find the category in the recordedESRSTopic
    for (const category of viewedESRSTopic.recordedESRSTopic.dmaCategories) {
      const dmaCategory = TreeUtilities.getChildInTree(
        category,
        (cat) => cat.children,
        (cat) => cat.id === dmaCategoryId,
      );
      if (dmaCategory) {
        return dmaCategory;
      }
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewedESRSTopic, searchParams]);

  // Cleanup work if ids are provided which cannot be found
  useEffect(() => {
    const esrsTopicId = searchParams.get(TOPIC_QUERY_PARAM_KEY);
    const dmaCategoryId = searchParams.get(CATEGORY_QUERY_PARAM_KEY);

    if (esrsTopicId && !viewedESRSTopic) {
      console.warn(`Could not find ESRSTopic with id ${esrsTopicId} - resetting filter`);
      setSearchParams(
        (prev) => {
          prev.delete(TOPIC_QUERY_PARAM_KEY);
          return prev;
        },
        { replace: true },
      );
    }
    if (dmaCategoryId && !viewedDMACategory) {
      console.warn(`Could not find DMACategory with id ${dmaCategoryId} - resetting filter`);
      setSearchParams(
        (prev) => {
          prev.delete(CATEGORY_QUERY_PARAM_KEY);
          return prev;
        },
        { replace: true },
      );
    }
  }, [searchParams, setSearchParams, viewedESRSTopic, viewedDMACategory]);

  return (
    <Box display="flex" flexDirection="column" gap={2} maxWidth="100%" mx="auto" pb={6}>
      <RequireFeatureFlag negate featureFlag={FeatureFlag.APP_SHELL_V2}>
        <Box mb={2}>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            sx={{ mb: 2, mr: "auto" }}
            component={RouterLink}
            to={AppInternalNavigationUtilities.getDMAPageUrl({ organizationId, recordingPeriodId })}
          >
            {t("button_back_to_dma")}
          </Button>
          <Typography variant="h1">{t("title")}</Typography>
          <DMADashboardBreadcrumbsFilter
            esrsTopics={esrsTopics}
            viewedESRSTopic={viewedESRSTopic}
            viewedDMACategory={viewedDMACategory}
            onChangeFilter={updateFilters}
          />
        </Box>
      </RequireFeatureFlag>

      <Box display="flex" flexDirection="column" gap={4}>
        <StyledCard>
          <DmaDashboardCardContent>
            <DMADashboardChartsSection
              groupedESRSTopics={groupedESRSTopics}
              esrsTopic={viewedESRSTopic}
              dmaCategory={viewedDMACategory}
              onSelectTopic={(topicId) => updateFilters(topicId, undefined)}
              onSelectCategory={(topicId, categoryId) => updateFilters(topicId, categoryId)}
            />
          </DmaDashboardCardContent>
        </StyledCard>

        <StyledCard>
          <DmaDashboardCardContent>
            <DmaIroOverviewList dmaConfiguration={dmaConfiguration} esrsTopics={esrsTopics} />
          </DmaDashboardCardContent>
        </StyledCard>

        <StyledCard>
          <DmaDashboardCardContent>
            <DMAOverviewTable
              groupedESRSTopics={groupedESRSTopics}
              viewedESRSTopic={viewedESRSTopic}
              viewedDMACategory={viewedDMACategory}
              dmaConfiguration={dmaConfiguration}
            />
          </DmaDashboardCardContent>
        </StyledCard>
      </Box>
    </Box>
  );
};
