import { createContext, useContext } from "react";
import {
  IGhgEvaluationCalculationResult,
  IGhgProtocolScopeEnum,
  IInputParameterRecordingStructureGroupGHG,
} from "@netcero/netcero-core-api-client";
import { EvaluationBase } from "./ghg-evaluation.types";

export interface IGhgDashboardContextData {
  viewedDataEntryObjectId: string;
  changeViewedDataEntryObjectId: (viewedDataEntryObjectId: string) => void;
  recordingStructure: IInputParameterRecordingStructureGroupGHG;
  relevantEvaluationResultsForDeoTree: IGhgEvaluationCalculationResult[];
  activeView: EvaluationBase;
  viewedScope: IGhgProtocolScopeEnum;
  changeViewedScope: (newScope: IGhgProtocolScopeEnum) => void;
}

const GhgDashboardContext = createContext<IGhgDashboardContextData | null>(null);

export const GhgDashboardContextProvider = GhgDashboardContext.Provider;

export const useGhgDashboardContext = () => {
  const context = useContext(GhgDashboardContext);
  if (!context) {
    throw new Error("useGhgDashboardContext must be used within a GhgDashboardContextProvider");
  }
  return context;
};
