import { useCallback } from "react";
import { CsvExportUtilities, ICsvExportOptions } from "./csv-export.utilities";
import { IAppSnackbarEnqueueBasicSuccessMessageData } from "../app-snackbar/app-snackbar.interfaces";
import { useAppSnackbar } from "../app-snackbar/app-snackbar.hook";
import { FileUtilities } from "../common/utilities/file.utilities";

/**
 * Custom hook for exporting CSV data with Snackbar integration.
 *
 * @template T - The type of the data to be exported.
 * @param {T} data - The data to be exported.
 * @param {ICsvExportOptions<T, P = T>} csvExportOptions - Options for building the CSV.
 * @param {() => string} getFilename - A function that returns the filename for the CSV.
 * @param {IAppSnackbarEnqueueBasicSuccessMessageData} snackbarMessage - The Snackbar configuration for export success messages.
 *
 * @returns An object with the exportCsv function.
 */
export const useCsvExport = <T, P = T>(
  data: T,
  csvExportOptions: ICsvExportOptions<T, P>,
  getFilename: () => string,
  snackbarMessage: IAppSnackbarEnqueueBasicSuccessMessageData,
) => {
  const appSnackbar = useAppSnackbar();

  const exportCsv = useCallback(() => {
    // Void is fine here because we are not actually doing anything async here
    void appSnackbar.wrapApiPromise(async () => {
      const csvString = CsvExportUtilities.generateCsvString(data, csvExportOptions);
      FileUtilities.downloadCsvStringAsUtf8CsvFile(csvString, getFilename());
    }, snackbarMessage);
  }, [appSnackbar, data, csvExportOptions, getFilename, snackbarMessage]);

  return { exportCsv };
};
