import { useMemo } from "react";
import { useInputParameterRecordingStructureDetailsQuery } from "../../modules/input-parameter-recording-structures/input-parameter-recording-structures.queries";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { GhgCategoriesOverview } from "../../modules/ghg-categories/components/ghg-categories-overview.component";
import { Box, Button, Typography } from "@mui/material";
import { useTranslateContent } from "../../modules/content-translation/hooks/translate-content.hook";
import { useRecordingPeriodGhgCategories } from "../../modules/ghg-categories/ghg-categories.queries";
import { QueriesWrapper } from "../../modules/common/components/queries-wrapper.component";
import { IInputParameterRecordingStructureIdentity, ROLE_ACCESS } from "@netcero/netcero-common";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { DataEntryObjectsContextProvider } from "../../modules/data-entry-objects/data-entry-objects.context";
import { withValidatedRouteParams } from "../../modules/common/components/with-validated-router-parameters.hoc";
import { useTranslation } from "react-i18next";
import { RequireFeatureFlag } from "../../modules/common/components/require-feature-flag.component";
import { FeatureFlag } from "../../modules/common/constants/feature-flags.constants";
import { OnlyShowToUsersWithRole } from "../../modules/authentication/components/only-show-to-users-with-role.component";
import { useGhgProtocolTabItems } from "../../modules/ghg-evaluation/hooks/ghg-protocol-tab-items.hook";
import {
  IIntercomReferencesContextState,
  IntercomReferencesProvider,
} from "../../modules/intercom-references/intercom-references.context";
import { useIntercomReferencesForEntityQuery } from "../../modules/intercom-references/intercom-references.queries";
import { IIntercomEntity } from "@netcero/netcero-core-api-client";
import { CommentsAndAuditLogSidebarStateProvider } from "../../modules/comments-and-audit-log/context/comments-and-audit-log-sidebar-state.context";
import { EvaluationIcon } from "../../modules/common/constants/iconify-icon.constants";

export const GhgProtocolRecordingStructurePage = withValidatedRouteParams(
  ["organizationId", "recordingPeriodId", "recordingStructureId"] as const,
  ({ organizationId, recordingPeriodId, recordingStructureId }) => {
    const identity: IInputParameterRecordingStructureIdentity = useMemo(
      () => ({
        organizationId,
        recordingPeriodId,
        inputParameterRecordingStructureId: recordingStructureId,
      }),
      [organizationId, recordingPeriodId, recordingStructureId],
    );

    const { t } = useTranslation("ghg_protocol");

    const navigate = useNavigate();

    const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
    const sideBarConfiguration = useMemo<ISideBarConfiguration>(
      () => ({
        organizationSelect: {
          organizationId: organizationId,
          onChange: (organizationId) => {
            navigate(`/organizations/${organizationId}`);
          },
        },
        navigationItems: {
          activePath: AppInternalNavigationUtilities.getRecordingStructurePageUrl(
            organizationId,
            recordingPeriodId,
            "ghg",
            recordingStructureId,
          ),
          items: sideBarOrganizationNavItems,
        },
      }),
      [organizationId, identity, sideBarOrganizationNavItems, navigate],
    );
    useCustomSideBarContent(sideBarConfiguration);

    const tabItems = useGhgProtocolTabItems(identity);
    const topBarConfiguration = useMemo<ITopBarConfiguration>(
      () => ({
        organizationSelect: {
          organizationId: organizationId,
          onChange: (organizationId) => {
            navigate(`/organizations/${organizationId}`);
          },
        },
        recordingPeriodId: recordingPeriodId,
        tabNavigation: {
          activeTabKey: "dataEntry",
          items: tabItems,
        },
      }),
      [organizationId, recordingPeriodId, tabItems, navigate],
    );
    useCustomTopBarContent(topBarConfiguration);

    const translateContent = useTranslateContent();

    const recordingStructureDetailsQuery = useInputParameterRecordingStructureDetailsQuery(
      organizationId,
      recordingPeriodId,
      recordingStructureId,
    );
    const recordingPeriodGhgCategoriesQuery = useRecordingPeriodGhgCategories(identity);
    const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
      organizationId,
      recordingPeriodId,
    );

    const ghgCategoryIntercomReferencesQuery = useIntercomReferencesForEntityQuery(
      IIntercomEntity.GhgCategory,
    );
    const intercomReferencesState: IIntercomReferencesContextState = useMemo(
      () => ({
        [IIntercomEntity.GhgCategory]: ghgCategoryIntercomReferencesQuery.data,
      }),
      [ghgCategoryIntercomReferencesQuery.data],
    );

    return (
      <IntercomReferencesProvider state={intercomReferencesState}>
        <QueriesWrapper
          queries={[
            recordingStructureDetailsQuery,
            recordingPeriodGhgCategoriesQuery,
            organizationStructureQuery,
          ]}
          build={([recordingStructureDetails, ghgCategories, organizationStructure]) => (
            <OrganizationStructureDraftWrapper
              organizationId={organizationId}
              recordingPeriodId={recordingPeriodId}
              organizationStructure={organizationStructure}
              build={(rootDataEntryObject) => (
                <Box>
                  <Typography variant="h3" component="h2">
                    {translateContent(recordingStructureDetails.name)}
                  </Typography>
                  <RequireFeatureFlag featureFlag={FeatureFlag.APP_SHELL_V2} negate>
                    <OnlyShowToUsersWithRole
                      roles={ROLE_ACCESS.ALLOWED_TO_ACCESS_INVENTORY_SCREENING}
                    >
                      <Button
                        variant="contained"
                        startIcon={<EvaluationIcon />}
                        sx={{ mt: 1, mr: "auto" }}
                        component={RouterLink}
                        to={AppInternalNavigationUtilities.getGhgProtocolInventoryScreeningPageUrl(
                          identity,
                        )}
                      >
                        {t("button_go_to_inventory_screening")}
                      </Button>
                    </OnlyShowToUsersWithRole>
                    <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_ACCESS_GHG_DASHBOARD}>
                      <Button
                        variant="contained"
                        startIcon={<EvaluationIcon />}
                        sx={{ mt: 1, mr: "auto" }}
                        component={RouterLink}
                        to={AppInternalNavigationUtilities.getGhgProtocolDashboardPageUrl(identity)}
                      >
                        {t("button_go_to_evaluation")}
                      </Button>
                    </OnlyShowToUsersWithRole>
                  </RequireFeatureFlag>
                  <DataEntryObjectsContextProvider rootDataEntryObject={rootDataEntryObject}>
                    <CommentsAndAuditLogSidebarStateProvider>
                      <GhgCategoriesOverview identity={identity} ghgCategories={ghgCategories} />
                    </CommentsAndAuditLogSidebarStateProvider>
                  </DataEntryObjectsContextProvider>
                </Box>
              )}
            />
          )}
        />
      </IntercomReferencesProvider>
    );
  },
);
