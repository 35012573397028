import { FC, useMemo } from "react";
import { FilterBar, getFilterConfigGenerator } from "../../filters/filter-bar.component";
import { EmissionFactorsFilter } from "../../filters/filter.constants";
import { FilterType, IFilterConfig } from "../../filters/filter.type";
import { useTranslation } from "react-i18next";
import { IEmissionFactorUnit } from "@netcero/netcero-core-api-client";
import { useGetEmissionFactorFilters } from "../hooks/get-emission-factor-filter-options.hook";
import { EmissionFactorUnitNameShortWithTooltip } from "./emission-factor-unit-name.component";

interface IEmissionFactorsFiltersProps {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams?: URLSearchParams) => void;
}

export const EmissionFactorsFilters: FC<IEmissionFactorsFiltersProps> = ({
  searchParams,
  setSearchParams,
}) => {
  const { t } = useTranslation("emission_factors_filters");

  const {
    databaseOptions,
    emissionFactorTypeOptions,
    ghgCategoryOptions,
    countryOptions,
    unitOptions,
  } = useGetEmissionFactorFilters();

  const generateFilterConfig = useMemo(() => getFilterConfigGenerator(t), [t]);

  const filters: IFilterConfig[] = useMemo(
    () => [
      generateFilterConfig(EmissionFactorsFilter.name, {
        type: FilterType.SearchOpen,
      }),
      generateFilterConfig(EmissionFactorsFilter.databases, {
        type: FilterType.Select,
        options: databaseOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorsFilter.emissionFactorTypes, {
        type: FilterType.Select,
        options: emissionFactorTypeOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorsFilter.ghgCategories, {
        type: FilterType.SearchMenu,
        options: ghgCategoryOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorsFilter.countries, {
        type: FilterType.SearchMenu,
        options: countryOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorsFilter.units, {
        type: FilterType.Select,
        options: unitOptions,
        multiple: true,
        buildMenuItem: (option) => (
          <EmissionFactorUnitNameShortWithTooltip
            unit={option.value as IEmissionFactorUnit}
            tooltipProps={{ placement: "right" }}
          />
        ),
      }),
    ],
    [
      generateFilterConfig,
      databaseOptions,
      emissionFactorTypeOptions,
      ghgCategoryOptions,
      unitOptions,
      countryOptions,
    ],
  );

  return (
    <FilterBar filters={filters} searchParams={searchParams} setSearchParams={setSearchParams} />
  );
};
