import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Alert, AlertTitle, Box, Button, Collapse, Tooltip, Typography } from "@mui/material";
import {
  IActivity,
  IActivityStatus,
  IGhgEvaluationCalculationResult,
  IGhgEvaluationType,
  IGhgProtocolScopeEnum,
  IPerformEvaluationForRecordingPeriod200Response,
  IRecordingPeriodGhgCategory,
  IRecordingPeriodGhgCategoryStatus,
} from "@netcero/netcero-core-api-client";
import { GhgCategoryName } from "./ghg-category-name.component";
import { useTranslation } from "react-i18next";
import { useGhgCategoriesOverviewContext } from "../ghg-categories-overview.context";
import {
  CopyToClipboardButton,
  CopyToClipboardButtonAnimationProps,
} from "../../common/components/copy-to-clipboard-button.component";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";
import {
  AppUrlUtilities,
  GhgEvaluationUtilities,
  IRecordingPeriodGhgCategoryIdentity,
  ROLE_ACCESS,
} from "@netcero/netcero-common";
import { ExpansionIconButton } from "../../common/components/expansion-icon-button.component";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { ActivityDetailsDialog } from "../../ghg-activities/dialogs/activity-details.dialog";
import { useDialogState } from "../../common/dialogs/dialog-state.hook";
import { useGhgCategoryActivitiesQuery } from "../../ghg-activities/ghg-activities.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { GhgActivitiesTable } from "../../ghg-activities/components/ghg-activities-table.component";
import { useDataEntryObjectsContext } from "../../data-entry-objects/data-entry-objects.context";
import { IntercomReferenceWidget } from "../../intercom-references/intercom-reference.widget";
import { IconSize } from "../../common/constants/tabler-icon.constants";
import { StyledCardWithOnHoverShadow } from "../../common/components/styled-card.component";
import { useDoesUserHaveRole } from "../../authentication/hooks/does-user-have-role.hook";
import { OrganizationUsersComponent } from "../../user/components/organization-users.component";
import { useUserContext } from "../../user/user.context";
import { GhgCategoryStatusIndicatorWithMenu } from "./ghg-category-status-indicator-with-menu.component";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { EXCLUDE_OPACITY } from "../../../theme/theme";
import { AuditLogsButton } from "../../audit-logging/components/audit-logs-button.component";
import { GhgEmissionsSumChip } from "../../ghg-evaluation/components/ghg-emissions-sum-chip.component";
import { useSearchParams } from "react-router-dom";
import { CommentsButtonWithQuery } from "../../comments/components/comments-button.component";
import { useGhgCategoryCommentsAndAuditLogsSidebarContext } from "./ghg-category-comments-and-audit-logs-sidebar.component";
import { GhgCategoryResponsibleUserPicker } from "./ghg-category-responsible-user-picker.component";
import { useGhgCategoryStatusChangeHandling } from "../hooks/ghg-category-status-change-handling.hooks";

const COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME = "ghg-category-copy-to-clipboard-button";

const selectTotalCategoryEmissions = (data: IGhgEvaluationCalculationResult[]) =>
  GhgEvaluationUtilities.calculateTotalEmissionsSumInTCO2Eq(data).marketBasedTotal;

interface IGhgCategoryCardProps {
  scope: IGhgProtocolScopeEnum;
  recordingPeriodGhgCategory: IRecordingPeriodGhgCategory;
}

export const GhgCategoryCard: FC<IGhgCategoryCardProps> = ({
  scope,
  recordingPeriodGhgCategory,
}) => {
  const { t } = useTranslation("ghg_protocol");

  const [searchParams] = useSearchParams();

  const {
    recordingStructureIdentity,
    isCategoryExpanded,
    toggleCategoryExpansion,
    isLoading,
    // Mutations
    handleGhgCategoryResponsiblePersonChange,
    handleGhgCategoryContributingUsersChange,
  } = useGhgCategoriesOverviewContext();
  const { rootDataEntryObject, getConsolidationForDataEntryObject } = useDataEntryObjectsContext();
  const {
    data: commentsViewedForGhgCategory,
    toggleCommentsAndAuditLogSideSection,
    openCommentsAndAuditLogSideSection,
  } = useGhgCategoryCommentsAndAuditLogsSidebarContext();

  const isUserAllowedToChangeContributingUsers = useDoesUserHaveRole(
    ROLE_ACCESS.ALLOWED_TO_MODIFY_GHG_CATEGORY_CONTRIBUTING_USERS,
  );
  const canUserCreateActivities = useDoesUserHaveRole(ROLE_ACCESS.ALLOWED_TO_CREATE_ACTIVITIES);
  const canUserSeeAllActivities = useDoesUserHaveRole(ROLE_ACCESS.ALLOWED_TO_SEE_ALL_ACTIVITIES);

  const { responsibleUserId, contributingUserIds } = recordingPeriodGhgCategory;
  const { user } = useUserContext();
  const userId = user?.userProfile?.id;

  const isResponsiblePerson = responsibleUserId === userId;

  const ghgCategoryIdentity: IRecordingPeriodGhgCategoryIdentity = useMemo(
    () => ({
      organizationId: recordingStructureIdentity.organizationId,
      recordingPeriodId: recordingPeriodGhgCategory.recordingPeriodId,
      ghgCategoryIdentifier: recordingPeriodGhgCategory.ghgCategoryIdentifier,
    }),
    [
      recordingPeriodGhgCategory.ghgCategoryIdentifier,
      recordingPeriodGhgCategory.recordingPeriodId,
      recordingStructureIdentity.organizationId,
    ],
  );

  const activitiesQuery = useGhgCategoryActivitiesQuery(ghgCategoryIdentity);

  const {
    isOpen: isActivityDetailsDialogOpen,
    data: activityDetailsDialogData,
    openDialog: openActivityDetailsDialog,
    closeDialog: closeActivityDetailsDialog,
    updateDialogData: updateActivityDetailsDialogData,
  } = useDialogState<IActivity | undefined>(undefined);

  // Sync Dialog data to activitiesQuery
  useEffect(() => {
    const activity = activitiesQuery.data?.activities.find(
      (activity) => activity.id === activityDetailsDialogData?.id,
    );
    if (activity) {
      updateActivityDetailsDialogData(activity);
    }
    // This is fine since we only want to trigger this on query data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesQuery.data?.activities]);

  // Open Details Dialog when in Url
  useEffect(() => {
    const searchParamsViewedActivityId = searchParams.get(
      AppUrlUtilities.INITIALLY_VIEWED_ACTIVITY_QUERY_PARAM,
    );
    if (searchParamsViewedActivityId) {
      const activity = activitiesQuery.data?.activities.find(
        (activity) => activity.id === searchParamsViewedActivityId,
      );
      if (activity) {
        openActivityDetailsDialog(activity);
      }
    }
  }, [
    // We DON'T want to listen for searchParams changes, since that would cause an finite loop
    // when viewedComment is cleared after first dialog close
    activitiesQuery.data?.activities,
    openActivityDetailsDialog,
  ]);

  const categoryIdentifier = recordingPeriodGhgCategory.ghgCategoryIdentifier;

  const isExpanded = useMemo(
    () => isCategoryExpanded(categoryIdentifier),
    [isCategoryExpanded, categoryIdentifier],
  );

  const selectEmissions = useCallback(
    (data: IPerformEvaluationForRecordingPeriod200Response) =>
      selectTotalCategoryEmissions(
        // be sure to only select emissions that are in the current category
        GhgEvaluationUtilities.filterGhgEvaluationResultsByCategories(data.results, [
          categoryIdentifier,
        ]),
      ),
    [categoryIdentifier],
  );

  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const copyLinkComponent = useMemo(
    () => (
      <Box display="inline-block" className={COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}>
        <Box sx={{ transform: "translateY(-2px)" }}>
          <CopyToClipboardButton
            size="small"
            disableRipple
            value={AppInternalNavigationUtilities.getGhgProtocolPageUrl(
              recordingStructureIdentity,
              {
                viewedScope: scope,
                viewedGhgCategoryId: categoryIdentifier,
              },
            )}
            tooltip={t("tooltip_copy_link", { ns: "buttons" })}
            tooltipSuccess={t("tooltip_copy_link_success", { ns: "buttons" })}
            tooltipPlacement="right"
          />
        </Box>
      </Box>
    ),
    [categoryIdentifier, recordingStructureIdentity, scope, t],
  );

  const openDetailsDialog = useCallback(
    (activity?: IActivity) => {
      updateActivityDetailsDialogData(activity);
      // Use timeout so the dialog open after the value has already been updated
      setTimeout(() => openActivityDetailsDialog(), 0);
    },
    [openActivityDetailsDialog, updateActivityDetailsDialogData],
  );

  // Status Change for GHG Category

  const { statusChangeEvents } = useGhgCategoryStatusChangeHandling({
    ghgCategoryIdentity,
    recordingStructureIdentity,
  });

  const isExcluded = !!recordingPeriodGhgCategory.exclude;
  const isDone = recordingPeriodGhgCategory.status === IRecordingPeriodGhgCategoryStatus.Done;
  const hasIncompleteActivities = useMemo(
    () =>
      activitiesQuery.data?.activities.some(
        (activity) => activity.status !== IActivityStatus.Done,
      ) ?? false,
    [activitiesQuery.data?.activities],
  );

  const sxPropsForExcludedState = { opacity: isExcluded ? EXCLUDE_OPACITY : undefined };

  return (
    <>
      {/* Dialog */}
      <ActivityDetailsDialog
        open={isActivityDetailsDialogOpen}
        isCategoryDone={isDone}
        recordingStructureId={recordingStructureIdentity.inputParameterRecordingStructureId}
        ghgCategoryIdentity={ghgCategoryIdentity}
        rootDataEntryObject={rootDataEntryObject}
        getDataEntryObjectConsolidation={getConsolidationForDataEntryObject}
        activity={activityDetailsDialogData}
        onClose={closeActivityDetailsDialog}
        onUpdateActivity={updateActivityDetailsDialogData}
      />

      {/* Card */}
      <StyledCardWithOnHoverShadow id={categoryIdentifier}>
        <Box>
          {/* GHG Category Title Bar */}
          <Box display="flex" alignItems="center" gap={2} position="sticky" m={-0.5} p={2}>
            {/* Expansion & Title */}
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              flex={1}
              sx={{
                cursor: "pointer",
                ...CopyToClipboardButtonAnimationProps(`.${COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}`),
                ...sxPropsForExcludedState,
              }}
              onClick={() => toggleCategoryExpansion(categoryIdentifier)}
            >
              <Box display="flex" alignItems="center" gap={1}>
                {/* Collapse Button */}
                <ExpansionIconButton
                  expanded={isExpanded}
                  iconButtonProps={{
                    color: "inherit",
                    sx: {
                      fontSize: "48px",
                    },
                  }}
                />
                {/* Title */}
                <LineClampTypographyWithTooltip
                  tooltipOverride={<GhgCategoryName ghgCategory={categoryIdentifier} />}
                  onOverflowChange={setIsTextOverflowing}
                  variant="h6"
                  component="h3"
                  maxLines={2}
                  style={{ cursor: "pointer", overflowWrap: "break-word", wordBreak: "break-word" }}
                >
                  {/* Text */}
                  <GhgCategoryName ghgCategory={categoryIdentifier} />
                  {/* Copy to clipboard button - shown when not overflowing */}
                  {!isTextOverflowing && copyLinkComponent}
                </LineClampTypographyWithTooltip>
                {/* Copy to clipboard button - shown here when overflowing (would be hidden by lineclamp) */}
                {isTextOverflowing && (
                  <Box alignSelf="end" mt={-0.65} ml={-1}>
                    {copyLinkComponent}
                  </Box>
                )}
              </Box>
            </Box>
            {/* Ghg Emissions Chips & Spacing */}
            <Box
              display="flex"
              flex={1}
              gap={1}
              alignItems="center"
              onClick={() => toggleCategoryExpansion(categoryIdentifier)}
              ml={-2} // Close gap
              sx={{ cursor: "pointer" }}
            >
              <GhgEmissionsSumChip
                recordingPeriodIdentity={ghgCategoryIdentity}
                evaluationConfiguration={{
                  evaluationType: IGhgEvaluationType.Category,
                  categoryIdentifier: ghgCategoryIdentity.ghgCategoryIdentifier,
                }}
                shouldComputeScope3Values={false}
                selectSum={selectEmissions}
                fontSize={16}
                typographyProps={{ fontWeight: "bold" }}
              />
            </Box>
            {/* Status Indicator */}
            <GhgCategoryStatusIndicatorWithMenu
              identity={ghgCategoryIdentity}
              inputParameterRecordingStructureId={
                recordingStructureIdentity.inputParameterRecordingStructureId
              }
              category={recordingPeriodGhgCategory}
              hasIncompleteActivities={hasIncompleteActivities}
              disabled={isLoading}
              {...statusChangeEvents}
            />
            {/* Responsible Person */}
            <GhgCategoryResponsibleUserPicker
              organizationIdentity={ghgCategoryIdentity}
              recordingPeriodGhgCategory={recordingPeriodGhgCategory}
              onChange={(newUserId) =>
                handleGhgCategoryResponsiblePersonChange(ghgCategoryIdentity, newUserId)
              }
              disabled={isLoading}
            />
            {/* Intercom Reference*/}
            <IntercomReferenceWidget
              entityType="ghg-category"
              identifier={`ghg_category_help.${categoryIdentifier}`}
              iconSize={IconSize.Medium}
            />
          </Box>
          <Collapse in={isExpanded} unmountOnExit mountOnEnter>
            {/* Main Content */}
            <Box display="flex" flexDirection="column" alignItems="stretch" gap={2.5} p={2} pt={0}>
              {/* Description */}
              <Typography pl={5} sx={sxPropsForExcludedState}>
                <FormatTranslation
                  t={t}
                  i18nKey={categoryIdentifier}
                  ns="ghg_categories_explanations"
                />
              </Typography>

              {/* Buttons & Contributors */}
              <Box display="flex" flexWrap="wrap" gap={1}>
                {/* Comments Button */}
                <CommentsButtonWithQuery
                  organizationId={ghgCategoryIdentity.organizationId}
                  relations={{
                    entity: "recording-period-ghg-category",
                    ghgCategoryRecordingPeriodId: ghgCategoryIdentity.recordingPeriodId,
                    ghgCategoryIdentifier: ghgCategoryIdentity.ghgCategoryIdentifier,
                  }}
                  onClick={() => {
                    if (
                      commentsViewedForGhgCategory === ghgCategoryIdentity.ghgCategoryIdentifier
                    ) {
                      toggleCommentsAndAuditLogSideSection(
                        ghgCategoryIdentity.ghgCategoryIdentifier,
                      );
                    } else {
                      openCommentsAndAuditLogSideSection(ghgCategoryIdentity.ghgCategoryIdentifier);
                    }
                  }}
                />

                {/* Audit Logs Button */}
                <AuditLogsButton organizationId={ghgCategoryIdentity.organizationId} active />

                {/* Vertical Grow Spacer */}
                <Box flex={1} />

                {/* Contributing Users */}
                <OrganizationUsersComponent
                  values={contributingUserIds}
                  organizationId={ghgCategoryIdentity.organizationId}
                  onChange={(newAssignedUserIds) => {
                    handleGhgCategoryContributingUsersChange(
                      ghgCategoryIdentity,
                      newAssignedUserIds,
                    );
                  }}
                  tooltipAddButton={t("add_tooltip", { ns: "add_contributing_user_button" })}
                  emptyMessageAddButton={t("no_more_users_available", {
                    ns: "add_contributing_user_button",
                  })}
                  emptyMessage={t("no_user_selected", { ns: "add_contributing_user_button" })}
                  disabled={isLoading}
                  readOnly={!isUserAllowedToChangeContributingUsers && !isResponsiblePerson}
                />
              </Box>

              {/* Limited Access Banner */}
              {!canUserSeeAllActivities && (
                <Alert severity="warning">
                  <AlertTitle>{t("limited_access_banner.title")}</AlertTitle>
                  {t("limited_access_banner.description")}
                </Alert>
              )}

              {/* Activities List */}
              <QueryWrapper
                query={activitiesQuery}
                build={({ activities }) =>
                  activities.length > 0 && (
                    <Box sx={sxPropsForExcludedState}>
                      <GhgActivitiesTable
                        ghgCategoryIdentity={ghgCategoryIdentity}
                        activities={activities}
                        onClick={openDetailsDialog}
                        disabled={isExcluded}
                      />
                    </Box>
                  )
                }
              />
              {/* Create Activity Button */}
              <Tooltip
                title={
                  !canUserCreateActivities
                    ? // Show limited access info when
                      t("limited_access_banner.description")
                    : isDone
                    ? // Show done info
                      t("notice_ghg_category_done")
                    : null
                }
                placement="right"
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={() => openDetailsDialog(undefined)}
                    disabled={!canUserCreateActivities || isExcluded || isDone}
                  >
                    {t("create_activity")}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Collapse>
        </Box>
      </StyledCardWithOnHoverShadow>
    </>
  );
};
