import { IActivity, IContentLanguage, IEmissionFactor } from "@netcero/netcero-core-api-client";
import GenerateTemplateServiceWorker from "./ghg-activity-values-import-template.web-worker?worker";
import { DeferredPromise } from "@netcero/netcero-common";

interface ITemplateDataBase {
  activity: IActivity;
  emissionFactors: IEmissionFactor[];
  language: IContentLanguage;
}

export interface IGenerateTemplateData extends ITemplateDataBase {
  template: Blob;
}

export interface IGenerateTemplateServiceWorkerConfiguration extends ITemplateDataBase {
  templateUrl: string;
}

export type IGenerateTemplateServiceWorkerResult =
  | { result: Buffer; error?: undefined }
  | {
      result?: undefined;
      error: Error;
    };

export class GhgActivityValuesImportTemplateWebWorkerUtilities {
  static async generateTemplate({
    activity,
    emissionFactors,
    language,
    template,
  }: IGenerateTemplateData): Promise<IGenerateTemplateServiceWorkerResult> {
    const worker = new GenerateTemplateServiceWorker();
    const templateUrl = URL.createObjectURL(template);
    const cleanup = () => {
      worker.terminate();
      URL.revokeObjectURL(templateUrl);
    };
    const deferredPromise = new DeferredPromise<IGenerateTemplateServiceWorkerResult>();
    // Handle messages from the worker
    worker.onmessage = (event) => {
      const data = event.data;
      deferredPromise.resolve(data);
      cleanup();
    };
    // Handle errors from the worker
    worker.onerror = (error) => {
      console.error("Worker error:", error);
      deferredPromise.reject(error);
      cleanup();
    };
    // Send the data to the worker and start the import process
    const workerData: IGenerateTemplateServiceWorkerConfiguration = {
      activity,
      emissionFactors,
      language,
      templateUrl,
    };
    worker.postMessage(workerData);
    return deferredPromise.promise;
  }
}
