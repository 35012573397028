import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { BaseStatusIndicator } from "../../common/components/base-status-indicator.component";
import { GHG_ACTIVITY_STATUS_COLORS } from "../ghg-activities.constants";
import { IActivityStatus } from "@netcero/netcero-core-api-client";

interface IGhgActivityStatusIndicatorProps {
  status: IActivityStatus;
  onClick?: VoidFunction;
  disabled?: boolean;
  isMenuOpen?: boolean;
  shouldRenderChevronIcon?: boolean;
}

export const GhgActivityStatusIndicator = forwardRef<
  HTMLDivElement,
  IGhgActivityStatusIndicatorProps
>(function GhgActivityStatusIndicator(
  { status, onClick, disabled, isMenuOpen, shouldRenderChevronIcon },
  ref,
) {
  const { t } = useTranslation("ghg_activities", { keyPrefix: "status" });

  const statusColor = GHG_ACTIVITY_STATUS_COLORS[status];

  return (
    <BaseStatusIndicator
      ref={ref}
      onClick={onClick}
      color={statusColor}
      label={t(`${status}.name`)}
      isFilled={true} // Until exclude is introduced --> no fill
      disabled={disabled}
      isMenuOpen={isMenuOpen}
      shouldRenderChevronIcon={shouldRenderChevronIcon}
    />
  );
});
