import { FC } from "react";
import { Avatar, Box, Skeleton, SxProps, Theme, Tooltip } from "@mui/material";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { NoUserIcon } from "../../common/constants/tabler-icon.constants";
import { UserUtilities } from "@netcero/netcero-common";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { useGetSingleUserQueryInternal } from "../users.queries";
import {
  CLICKABLE_INDICATOR_WHITE_OVERLAY_PARENT_STYLES,
  ClickableIndicatorWhiteOverlay,
} from "../../common/components/clickable-indicator-white-overlay.component";

type UserAvatarRelevantProps = Pick<IUserRepresentation, "firstName" | "lastName">;

interface IUserAvatarProps {
  user: UserAvatarRelevantProps | null | undefined;
  /** The diameter of the avatar (textSize will be half). DEFAULT: 28 */
  avatarDiameter?: number;
  avatarSx?: SxProps<Theme>;
  innerContainerSx?: SxProps<Theme>;
  withTooltip?: boolean;
  noUserSelectedTooltip?: string;
  disabledOverlay?: boolean;
}

export const UserAvatar: FC<IUserAvatarProps> = ({
  user,
  avatarDiameter = 28,
  avatarSx,
  innerContainerSx,
  withTooltip,
  noUserSelectedTooltip,
  disabledOverlay,
}) => {
  const avatar = (
    <Avatar
      sx={{
        width: avatarDiameter,
        height: avatarDiameter,
        fontSize: avatarDiameter / 2,
        bgcolor: user ? UserUtilities.getColorByUser(user) : undefined,
        ...(disabledOverlay ? {} : CLICKABLE_INDICATOR_WHITE_OVERLAY_PARENT_STYLES),
        ...avatarSx,
      }}
    >
      {user ? (
        <Box
          sx={{
            color: "primary.contrastText",
            WebkitTextFillColor: (theme) => theme.palette.primary.contrastText,
            ...innerContainerSx,
          }}
        >
          {user.firstName?.[0]}
          {user.lastName?.[0]}
          {!disabledOverlay && <ClickableIndicatorWhiteOverlay />}
        </Box>
      ) : (
        <NoUserIcon />
      )}
    </Avatar>
  );

  return withTooltip ? (
    <Tooltip title={user ? `${user.firstName} ${user.lastName}` : noUserSelectedTooltip}>
      {avatar}
    </Tooltip>
  ) : (
    avatar
  );
};

interface IUserAvatarWithQueryProps extends Omit<IUserAvatarProps, "user"> {
  organizationId: string;
  userId: string | undefined | null;
}

export const UserAvatarWithQuery: FC<IUserAvatarWithQueryProps> = ({
  organizationId,
  userId,
  avatarDiameter = 28,
  ...props
}) => {
  const userQuery = useGetSingleUserQueryInternal(organizationId, userId);

  return (
    <QueryWrapper
      query={userQuery}
      loadingOverride={() => (
        <Skeleton variant="circular" width={avatarDiameter} height={avatarDiameter} />
      )}
      build={(user) => <UserAvatar user={user} avatarDiameter={avatarDiameter} {...props} />}
    />
  );
};
