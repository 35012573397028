import { useCurrentLanguage } from "./current-language.hook";
import { useCallback } from "react";
import { ITranslatedProperty } from "@netcero/netcero-core-api-client";

export type IUseTranslateContentResult = ReturnType<typeof useTranslateContent>;
export type IUseTranslateOptionalContentResult = ReturnType<typeof useTranslateOptionalContent>;

export const useTranslateContent = () => {
  const currentLanguage = useCurrentLanguage();

  return useCallback(
    ({ translationsPerLanguage, defaultLanguage }: ITranslatedProperty): string => {
      // Translation for the default language always has to exist!
      return translationsPerLanguage[currentLanguage] ?? translationsPerLanguage[defaultLanguage]!;
    },
    [currentLanguage],
  );
};

export const useTranslateOptionalContent = () => {
  const translate = useTranslateContent();

  return useCallback(
    (
      property: ITranslatedProperty | undefined,
      defaultValue: string | undefined = undefined,
    ): string | undefined => {
      if (property === undefined) {
        return defaultValue;
      }
      return translate(property);
    },
    [translate],
  );
};
