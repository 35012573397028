import React, { FC, useCallback, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { BaseChart, EChartClickHandler } from "../../common/charts/base-chart.component";
import { useTranslation } from "react-i18next";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { GhgEvaluationUtilities, NumberUtilities } from "@netcero/netcero-common";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { ChartLegendColoredIndicator } from "../../common/charts/chart-legend-colored-indicator.component";
import { ClickableTypography } from "../../common/components/clickable-typography.component";
import { GhgEvaluationAppUtilities } from "../ghg-evaluation-app.utilities";
import { useDataEntryObjectsContext } from "../../data-entry-objects/data-entry-objects.context";
import { useGhgDashboardContext } from "../ghg-dashboard.context";
import { GhgChartColorPalette } from "../../../theme/charts-theme";
import { EvaluationBase } from "../ghg-evaluation.types";
import { GhgDashboardResult } from "./ghg-dashboard-result.component";

interface IChartSeriesEntry {
  value: number;
  name: string;
  dataEntryObject: IDataEntryObject;
}

export const GhgDashboardByDataEntryObject: FC = () => {
  const { t } = useTranslation("ghg_protocol", { keyPrefix: "evaluation" });

  const { getDataEntryObject, rootDataEntryObject } = useDataEntryObjectsContext();
  const {
    viewedDataEntryObjectId,
    changeViewedDataEntryObjectId,
    relevantEvaluationResultsForDeoTree,
    activeView,
  } = useGhgDashboardContext();

  const relevantDataEntryObject =
    getDataEntryObject(viewedDataEntryObjectId) ?? rootDataEntryObject;

  const handleDeoClicked: EChartClickHandler = useCallback(
    (param) => {
      const clickedDeo = (param.data as IChartSeriesEntry).dataEntryObject;
      // Only emit event if DEO is different from currently viewed and has children
      // (if it doesn't have children it doesn't make sense for the user to view its details)
      if (relevantDataEntryObject.id !== clickedDeo.id && clickedDeo.children.length > 0) {
        changeViewedDataEntryObjectId(clickedDeo.id);
      }
    },
    [changeViewedDataEntryObjectId, relevantDataEntryObject.id],
  );

  // Get only direct emissions of viewed DEO
  const viewedDataEntryObjectResult = useMemo(
    () => ({
      dataEntryObject: relevantDataEntryObject,
      totalSum: GhgEvaluationUtilities.calculateTotalEmissionsSumInTCO2Eq(
        GhgEvaluationUtilities.filterGhgEvaluationResultsByDataEntryObjects(
          relevantEvaluationResultsForDeoTree,
          [relevantDataEntryObject.id],
        ),
      ),
    }),
    [relevantDataEntryObject, relevantEvaluationResultsForDeoTree],
  );
  // Get all emissions of children DEOs (including all their descendants)
  const childrenResults = useMemo(
    () =>
      relevantDataEntryObject.children.map((dataEntryObject) => ({
        dataEntryObject,
        totalSum: GhgEvaluationUtilities.calculateTotalEmissionsSumInTCO2Eq(
          GhgEvaluationUtilities.filterGhgEvaluationResultsForDataEntryObjectAndChildren(
            relevantEvaluationResultsForDeoTree,
            dataEntryObject,
          ),
        ),
      })),
    [relevantDataEntryObject.children, relevantEvaluationResultsForDeoTree],
  );

  const viewedResults = useMemo(() => {
    const allResults = [viewedDataEntryObjectResult, ...childrenResults].map(
      ({ dataEntryObject, totalSum }) => ({
        dataEntryObject,
        total:
          activeView === EvaluationBase.LocationBased
            ? totalSum.locationBasedTotal
            : totalSum.marketBasedTotal,
      }),
    );
    // Remove currently viewed DEO if result is 0
    return allResults[0].total !== 0 ? allResults : allResults.slice(1);
  }, [activeView, childrenResults, viewedDataEntryObjectResult]);

  const overallTotal = useMemo(
    () => NumberUtilities.sum(viewedResults.map(({ total }) => total)),
    [viewedResults],
  );

  const chartOptions = useMemo(
    () =>
      GhgEvaluationAppUtilities.getEChartPieChartOptions(
        viewedResults.map<IChartSeriesEntry>(({ dataEntryObject, total }) => ({
          value: total,
          name: dataEntryObject.name,
          dataEntryObject,
        })),
        // Get overall total (used for percentages)
        overallTotal,
      ),
    [overallTotal, viewedResults],
  );

  const anyValueShown = overallTotal !== 0;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontSize={20} fontWeight={700}>
        {t("heading_by_data_entry_object")}
      </Typography>
      <Box display="flex" alignItems="center" gap={2}>
        {/* Chart */}
        <Box width={220} height={220} display="flex" justifyContent="center" alignItems="center">
          {anyValueShown ? (
            <BaseChart
              width="100%"
              options={chartOptions}
              onClick={handleDeoClicked}
              themeOverrides={{
                themeName: "ghg-by-data-entry-object",
                colorPaletteOverrides: GhgChartColorPalette,
              }}
            />
          ) : (
            <Typography>{t("no_emissions_notice")}</Typography>
          )}
        </Box>
        {/* Table of Contents*/}
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          gap={1.25}
          height={220}
          py={1}
          sx={{
            overflowY: "auto",
            "> :last-child": { borderBottom: "none" },
          }}
        >
          {viewedResults.map(({ dataEntryObject, total }, index) => (
            <Box
              key={dataEntryObject.id}
              display="flex"
              alignItems="start"
              gap={1}
              pl={1.5}
              pr={0.5}
              pb={1}
              borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
            >
              <ChartLegendColoredIndicator
                index={index}
                colorPaletteOverrides={GhgChartColorPalette}
              />
              <ClickableTypography
                onClick={
                  dataEntryObject.id !== relevantDataEntryObject.id &&
                  dataEntryObject.children.length > 0
                    ? () => changeViewedDataEntryObjectId(dataEntryObject.id)
                    : undefined
                }
                sx={{
                  textDecoration:
                    dataEntryObject.id === relevantDataEntryObject.id ? "underline" : undefined,
                }}
              >
                {dataEntryObject.name}
              </ClickableTypography>
              <Box flex={1} />
              <GhgDashboardResult
                formattedValue={
                  <Typography>{FormatUtilities.formatGhgEvaluationResult(total)}</Typography>
                }
                valueToCopy={total.toString()}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
