import { ICommentLinkMetadata } from "@netcero/netcero-core-api-client";
import { UUID } from "../common";
import { AppUrlUtilities, VIEWED_COMMENT_QUERY_PARAM } from "../app-urls";
import { GhgCategoriesUtilities } from "../ghg-categories";

export class CommentUtilities {
  /**
   * This method generates the link to the given entity for the given base (domain).
   * @param origin The domain for which the link should be generated
   * @param metadata The metadata to use for generation
   */
  public static generateEntityLink(origin: string, metadata: ICommentLinkMetadata): URL {
    switch (metadata.entity) {
      // IROs
      case "material-impact":
      case "financial-effect": {
        return AppUrlUtilities.getUrlForRecordingPeriodDma({
          origin,
          organizationId: metadata.organizationId,
          recordingPeriodId: metadata.recordingPeriodId,
          esrsTopicId: metadata.esrsTopicId,
          dmaCategoryId: metadata.topLevelDmaCategoryId,
          ...(metadata.entity === "material-impact"
            ? {
                editMaterialImpactId: metadata.materialImpactId,
              }
            : {
                editFinancialEffectId: metadata.financialEffectId,
              }),
        });
      }
      // Data Entry Object Input Parameters
      case "data-entry-object-input-parameter": {
        return AppUrlUtilities.getUrlForDataEntryObjectInputParameter({
          origin,
          organizationId: metadata.organizationId,
          recordingPeriodId: metadata.recordingPeriodId,
          dataEntryObjectId: metadata.dataEntryObjectId,
          inputParameterId: metadata.inputParameterId,
          sectionId: metadata.sectionId,
          recordingStructureId: metadata.recordingStructureId,
          recordingStructureType: metadata.recordingStructureType,
        });
      }
      // Recording Period Ghg Category
      case "recording-period-ghg-category":
        return AppUrlUtilities.getGhgProtocolPageUrl({
          origin,
          organizationId: metadata.organizationId,
          recordingPeriodId: metadata.recordingPeriodId,
          recordingStructureId: metadata.recordingStructureId,
          viewedGhgCategoryId: metadata.ghgCategoryIdentifier,
          viewedScope: GhgCategoriesUtilities.getScopeOfGhgCategory(metadata.ghgCategoryIdentifier),
        });
      // Activity
      case "activity":
        return AppUrlUtilities.getUrlForGhgProtocolActivity({
          origin,
          organizationId: metadata.organizationId,
          recordingPeriodId: metadata.recordingPeriodId,
          recordingStructureId: metadata.recordingStructureId,
          viewedGhgCategoryId: metadata.ghgCategoryIdentifier,
          viewedScope: GhgCategoriesUtilities.getScopeOfGhgCategory(metadata.ghgCategoryIdentifier),
          viewedActivityId: metadata.activityId,
        });
    }
  }

  /**
   * This method generates the link to the given comment within the given entity for the given base (domain).
   * @param origin The domain for which the link should be generated
   * @param metadata The metadata to use for generation
   * @param commentId The comment for which the link should be generated
   */
  public static generateEntityLinkForComment(
    origin: string,
    metadata: ICommentLinkMetadata,
    commentId: UUID,
  ): URL {
    const url = CommentUtilities.generateEntityLink(origin, metadata);
    url.searchParams.append(VIEWED_COMMENT_QUERY_PARAM, commentId);
    return url;
  }
}
