import * as Joi from "joi";
import {
  ActivityValuesImportError,
  IActivityValuesImportEmissionFactorRowData,
  IActivityValuesImportSimpleValueRowData,
} from "./activity-values-import.public-types";
import { ACTIVITY_TEMPLATE_CONSTANTS } from "../activities";

const EMISSION_FACTOR_SHEET_COLUMNS = ACTIVITY_TEMPLATE_CONSTANTS.EMISSION_FACTOR_SHEET_COLUMNS;
const SIMPLE_VALUE_SHEET_COLUMNS = ACTIVITY_TEMPLATE_CONSTANTS.SIMPLE_VALUE_SHEET_COLUMNS;

const EmissionFactorRowValidation = Joi.object({
  [EMISSION_FACTOR_SHEET_COLUMNS.NAME_IDENTIFIER]: Joi.string().required(),
  [EMISSION_FACTOR_SHEET_COLUMNS.UUID]: Joi.string().uuid({ version: "uuidv4" }).required(),
  [EMISSION_FACTOR_SHEET_COLUMNS.UNIT]: Joi.string().required(),
}).unknown(true);

const SimpleValueRowValidation = Joi.object({
  [SIMPLE_VALUE_SHEET_COLUMNS.EMISSION_FACTOR_IDENTIFIER]: Joi.string().required(),
  [SIMPLE_VALUE_SHEET_COLUMNS.VALUE]: Joi.number().required(),
}).unknown(true);

export class ActivityValuesImportParseUtilities {
  /**
   * Parses an emission factor row from the import file.
   * @param rawRow
   * @throws Error with ActivityValuesImportError as message if the row is not valid
   */
  public static parseEmissionFactorRow(
    rawRow: Record<string, string>,
  ): IActivityValuesImportEmissionFactorRowData {
    ActivityValuesImportParseUtilities.validateRowWithSchemaOrThrowError(
      EmissionFactorRowValidation,
      rawRow,
      ActivityValuesImportError.INVALID_EMISSION_FACTOR_ROW_FORMAT,
    );
    // Return Result
    return {
      identifier: rawRow[EMISSION_FACTOR_SHEET_COLUMNS.NAME_IDENTIFIER],
      emissionFactorId: rawRow[EMISSION_FACTOR_SHEET_COLUMNS.UUID],
      unit: rawRow[EMISSION_FACTOR_SHEET_COLUMNS.UNIT],
    };
  }

  /**
   * Parses a simple value row from the import file.
   * @param rawRow
   * @throws Error with ActivityValuesImportError as message if the row is not valid
   */
  public static parseSimpleValueRow(
    rawRow: Record<string, string>,
  ): IActivityValuesImportSimpleValueRowData {
    ActivityValuesImportParseUtilities.validateRowWithSchemaOrThrowError(
      SimpleValueRowValidation,
      rawRow,
      ActivityValuesImportError.INVALID_VALUE_ROW_FORMAT,
    );
    // Return Result
    return {
      emissionFactorIdentifier: rawRow[SIMPLE_VALUE_SHEET_COLUMNS.EMISSION_FACTOR_IDENTIFIER],
      value: parseFloat(rawRow[SIMPLE_VALUE_SHEET_COLUMNS.VALUE]), // We know this will work since we validated it with the schema
    };
  }

  private static validateRowWithSchemaOrThrowError(
    schema: Joi.ObjectSchema,
    row: Record<string, string>,
    error: ActivityValuesImportError,
  ) {
    const { error: validationError } = schema.validate(row);
    if (validationError) {
      throw new Error(error);
    }
  }
}
