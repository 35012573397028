import { ChangeEvent, FC, PropsWithChildren } from "react";
import { UploadIcon } from "../constants/tabler-icon.constants";
import { Button, styled } from "@mui/material";
import { ALLOWED_MIME_TYPES_VALUES } from "@netcero/netcero-common";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type IFileUploadButtonProps = {
  onChangeFile: (files: FileList) => void;
  multiple?: boolean;
  /** Whether to always emit changes when selecting files (otherwise only triggers onChangeFile when selection actually changes) */
  alwaysEmitChange?: boolean;
  disabled?: boolean;
} & (
  | {
      acceptAllMimeTypes: true;
      acceptedMimeTypes?: undefined;
    }
  | {
      acceptAllMimeTypes?: false;
      acceptedMimeTypes: string[];
    }
);

export const FileUploadButton: FC<PropsWithChildren<IFileUploadButtonProps>> = ({
  acceptedMimeTypes = ALLOWED_MIME_TYPES_VALUES,
  onChangeFile,
  multiple,
  alwaysEmitChange,
  disabled,
  children,
}) => {
  const handleMultipleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onChangeFile(event.target.files);
      if (alwaysEmitChange) {
        event.target.value = ""; // Reset the input value to allow re-uploading the same file
      }
    }
  };

  return (
    <Button
      component="label"
      variant="contained"
      tabIndex={-1}
      startIcon={<UploadIcon />}
      disabled={disabled}
    >
      {children}
      <VisuallyHiddenInput
        type="file"
        accept={acceptedMimeTypes.join(", ")}
        multiple={multiple}
        onChange={handleMultipleFileChange}
        disabled={disabled}
      />
    </Button>
  );
};
