import { Box, Tooltip, TooltipProps } from "@mui/material";
import { IInputParameter } from "@netcero/netcero-core-api-client";
import {
  VSME_COMPREHENSIVE_DECISION_IP_ID,
  VSME_COMPREHENSIVE_DECISION_ENUM_VALUE,
} from "@netcero/netcero-common";
import { SUPPORT_COLORS } from "../../../theme/theme";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface IVsmeComprehensiveIndicatorProps extends Pick<TooltipProps, "placement"> {
  type: "disclosure-requirement" | "input-parameter";
}

export const VsmeComprehensiveIndicator: FC<IVsmeComprehensiveIndicatorProps> = ({
  type,
  placement,
}) => {
  const { t } = useTranslation("vsme");
  return (
    <Tooltip title={t("comprehensive_tooltip")} placement={placement}>
      <Box
        display="flex"
        flexShrink={0}
        alignItems="center"
        justifyContent="center"
        fontSize={type === "disclosure-requirement" ? 14 : 12}
        fontWeight={type === "disclosure-requirement" ? "bold" : "normal"}
        width={type === "disclosure-requirement" ? 24 : 18}
        height={type === "disclosure-requirement" ? 24 : 18}
        borderRadius={type === "disclosure-requirement" ? 1.5 : 1}
        bgcolor={SUPPORT_COLORS.gray}
      >
        C
      </Box>
    </Tooltip>
  );
};

interface IVsmeComprehensiveIndicatorForInputParameterProps
  extends IVsmeComprehensiveIndicatorProps {
  inputParameter: IInputParameter;
}

export const VsmeComprehensiveIndicatorForInputParameter: FC<
  IVsmeComprehensiveIndicatorForInputParameterProps
> = ({ inputParameter, ...props }) => {
  // Only show indicator if input parameter depends on VSME_COMPREHENSIVE_DECISION_IP_ID
  const showIndicator =
    inputParameter.condition?.checkInputParameterId === VSME_COMPREHENSIVE_DECISION_IP_ID &&
    inputParameter.condition?.check.value === VSME_COMPREHENSIVE_DECISION_ENUM_VALUE;
  return showIndicator && <VsmeComprehensiveIndicator {...props} />;
};
