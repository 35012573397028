import { BeautifulMentionNode } from "lexical-beautiful-mentions";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";

const LIST_NODES = [ListNode, ListItemNode];

const TABLE_NODES = [TableNode, TableCellNode, TableRowNode];

export const COMMON_LEXICAL_NODES = [
  BeautifulMentionNode,
  HeadingNode,
  ...LIST_NODES,
  ...TABLE_NODES,
];
