import { FC, useCallback, useMemo } from "react";
import { MenuItem } from "@mui/material";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { UserItem } from "./user-item.component";
import { UserAvatar } from "./user-avatar.component";
import { SearchableMenu } from "../../common/components/searchable-menu.component";
import { UserUtilities } from "@netcero/netcero-common";

/**
 * Filter a array of IUserRepresentation objects based on a search string.
 * @param search The search string to filter by.
 * @param options The array of IUserRepresentation objects (users) to filter.
 */
const filterUsers = (search: string, options: (IUserRepresentation | null)[]) => {
  if (!search) {
    return options;
  }

  const lowerSearch = search.toLowerCase();
  return options.filter((option) => {
    if (option === null) {
      return false;
    }
    return UserUtilities.formatUserFullName(option).toLowerCase().includes(lowerSearch);
  });
};

interface IPropsCommon {
  show: boolean;
  anchorEl: HTMLElement | null;
  noUsersAvailableText: string;
  onClose: VoidFunction;
  users: IUserRepresentation[] | null | undefined;
  activeUserId?: string | null | undefined;
}

interface IPropsAllowNull extends IPropsCommon {
  noUserOption: true;
  noUserOptionText: string;
  onSelect: (user: IUserRepresentation | null) => void;
}

interface IPropsDisallowNull extends IPropsCommon {
  noUserOption?: false;
  noUserOptionText?: undefined;
  onSelect: (user: IUserRepresentation) => void;
}

type IUserSelectMenuProps = IPropsAllowNull | IPropsDisallowNull;

export const UserSelectMenu: FC<IUserSelectMenuProps> = ({
  show,
  anchorEl,
  noUsersAvailableText,
  users,
  activeUserId,
  onClose,
  noUserOption,
  noUserOptionText,
  onSelect,
}) => {
  // Build the options array. Prepend a null value for "no user" option if enabled.
  const options = useMemo(() => {
    if (!users) {
      return [];
    }
    return noUserOption ? [null, ...users] : users;
  }, [users, noUserOption]);

  const buildMenuItem = useCallback(
    (option: IUserRepresentation | null) => {
      // When the null option is encountered, it should only be displayed if noUserOption is true.
      if (option === null) {
        if (!noUserOption) {
          return null;
        }
        return (
          <MenuItem
            key="no-user-option"
            selected={activeUserId === null}
            onClick={() => onSelect(null)}
            sx={{ display: "flex", gap: 1 }}
          >
            <UserAvatar user={null} />
            {noUserOptionText}
          </MenuItem>
        );
      }

      return (
        <MenuItem
          key={option.id}
          onClick={() => onSelect(option)}
          selected={activeUserId === option.id}
        >
          <UserItem user={option} />
        </MenuItem>
      );
    },
    [noUserOption, noUserOptionText, activeUserId, onSelect],
  );
  return (
    <SearchableMenu
      anchorEl={anchorEl}
      show={show}
      onClose={onClose}
      options={options}
      filterOptions={filterUsers}
      noOptionsPresentText={noUsersAvailableText}
      noOptionsFoundText={noUsersAvailableText}
      buildMenuItem={buildMenuItem}
    />
  );
};
