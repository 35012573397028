import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

export interface IUseExpandedIdsState<T extends string> {
  expandedIds: T[];
  isExpanded: (id: T) => boolean;
  setExpandedIds: Dispatch<SetStateAction<T[]>>;
  toggle: Dispatch<T>;
  expand: Dispatch<T>;
  close: Dispatch<T>;
}

export const useExpandedIdsState = <T extends string>(onlyAllowOne = false) => {
  const [expandedIds, setExpandedIds] = useState<T[]>([]);

  const expand = useCallback((id: T) => {
    setExpandedIds((prev) => (prev.includes(id) ? prev : [...prev, id]));
  }, []);

  const close = useCallback((id: T) => {
    setExpandedIds((prev) => prev.filter((expandedId) => expandedId !== id));
  }, []);

  const toggle = useCallback((id: T) => {
    setExpandedIds((prev) =>
      prev.includes(id) ? prev.filter((expandedId) => expandedId !== id) : [...prev, id],
    );
  }, []);

  // Handle onlyAllowOne flags
  useEffect(() => {
    if (onlyAllowOne) {
      // Only leave last added id in array if length exceeds 1
      if (expandedIds.length > 1) {
        setExpandedIds(expandedIds.slice(expandedIds.length - 1));
      }
    }
  }, [expandedIds, onlyAllowOne]);

  return useMemo<IUseExpandedIdsState<T>>(
    () => ({
      expandedIds,
      isExpanded: (id) => expandedIds.includes(id),
      setExpandedIds,
      toggle,
      expand,
      close,
    }),
    [close, expand, expandedIds, toggle],
  );
};
