import { IActivityStatus } from "@netcero/netcero-core-api-client";
import { IStateMachineTransition } from "../state-machine";

export enum ActivityStatusStateTransitions {
  RequestReview = "request_review",
  ReviewRejected = "review_rejected",
  ReviewApproved = "review_approved",
  MoveToInProgress = "move_to_in_progress",
}

export type ActivityStatusStateTransitionsForNotification =
  ActivityStatusStateTransitions.RequestReview;

export const ActivityStatusStateTransitionDefinitions: Record<
  ActivityStatusStateTransitions,
  IStateMachineTransition<IActivityStatus>
> = {
  [ActivityStatusStateTransitions.RequestReview]: {
    from: IActivityStatus.InProgress,
    to: IActivityStatus.InReview,
  },
  [ActivityStatusStateTransitions.ReviewRejected]: {
    from: IActivityStatus.InReview,
    to: IActivityStatus.InProgress,
  },
  [ActivityStatusStateTransitions.ReviewApproved]: {
    from: IActivityStatus.InReview,
    to: IActivityStatus.Done,
  },
  [ActivityStatusStateTransitions.MoveToInProgress]: {
    from: IActivityStatus.Done,
    to: IActivityStatus.InProgress,
  },
};
