// Interface to define the structure of the combined state returned by the hook
type ICombinedMutationState = {
  isPending: boolean;
} & (
  | {
      isError: false;
      error?: null;
    }
  | {
      isError: true;
      error: Error;
    }
);

/**
 * Custom hook to combine the pending, error, and error message states of multiple mutations
 * @param mutations - An array of mutation results to combine. Note: The size of the mutations array should NOT change.
 */
export const useCombineMutationState = (
  ...mutations: ICombinedMutationState[]
): ICombinedMutationState => {
  // Determine if any of the mutations are pending
  const isPending = mutations.some((mutation) => mutation.isPending);

  // Determine if any of the mutations have encountered an error
  const isError = mutations.some((mutation) => mutation.isError);

  // Get the first error object if any mutation has encountered an error, otherwise null
  const error = isError ? mutations.find((mutation) => mutation.isError)?.error ?? null : null;

  // Return the combined state object
  // as ICombinedMutationState is fine since it's a superset of ICombinedQueryState
  return { isPending, isError, error } as ICombinedMutationState;
};
