import { Dispatch, FC, useCallback } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/material";
import { DateTime } from "luxon";
import { CalendarIcon } from "../constants/tabler-icon.constants";

export interface IDateRangePickerValue {
  startDate: DateTime | null;
  endDate: DateTime | null;
}

interface IDateRangePickerProps {
  value: IDateRangePickerValue;
  onChange: Dispatch<IDateRangePickerValue>;
  disabled?: boolean;
}

export const DateRangePicker: FC<IDateRangePickerProps> = ({ value, onChange, disabled }) => {
  const handleChange = useCallback(
    (field: keyof IDateRangePickerValue) => {
      return (date: DateTime | null) => {
        onChange({ ...value, [field]: date });
      };
    },
    [onChange, value],
  );

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <DatePicker
        maxDate={value.endDate ?? undefined}
        value={value.startDate}
        onChange={handleChange("startDate")}
        slots={{ openPickerIcon: CalendarIcon }}
        slotProps={{ textField: { variant: "standard" } }}
        sx={{ width: 120 }}
        disabled={disabled}
      />
      {/* Minus */}
      -
      <DatePicker
        minDate={value.startDate ?? undefined}
        value={value.endDate}
        onChange={handleChange("endDate")}
        slots={{ openPickerIcon: CalendarIcon }}
        slotProps={{ textField: { variant: "standard" } }}
        sx={{ width: 120 }}
        disabled={disabled}
      />
    </Box>
  );
};
