import { MappingUtilities, UUID } from "../common";
import { ORG_ROLES_ASCENDING, ORG_ROLES_DESCENDING } from "./authentication.constants";
import {
  AppModule,
  IJwtOrganizationInfo,
  IJwtPayload,
  IOrganization,
} from "./authentication.public-types";
import { OrganizationRole } from "./roles.enum";

export class AuthenticationUtilities {
  public static getNetCeroOrganizationIds(jwtPayload: IJwtPayload) {
    return AuthenticationUtilities.getNetCeroOrganizations(jwtPayload).map((org) => org.id);
  }

  public static getNetCeroOrganizations(jwtPayload: IJwtPayload): IOrganization[] {
    return Object.values(jwtPayload.organization_mapping).map(
      AuthenticationUtilities.convertJwtOrganizationInfoToOrganization,
    );
  }

  public static convertJwtOrganizationInfoToOrganization(
    info: IJwtOrganizationInfo,
  ): IOrganization {
    return {
      id: info.attributes.netcero_id?.[0] ?? "undefined",
      name: info.name,
      deo_count_max: MappingUtilities.mapIfNotNull(
        info.attributes.deo_count_max?.[0] ?? null,
        parseInt,
      ),
      full_access: info.attributes.full_access?.[0] === "true",
      esrs_access: info.attributes.esrs_access?.[0] === "true",
      vsme_access: info.attributes.vsme_access?.[0] === "true",
      dma_access: info.attributes.dma_access?.[0] === "true",
      carbon_accounting_access: info.attributes.carbon_accounting_access?.[0] === "true",
      disabled: info.attributes.disabled?.[0] === "true",
    };
  }

  /**
   * Extracts the Keycloak organization ID from the JWT payload for the given NetCero organization ID.
   * @param jwtPayload The JWT payload.
   * @param netCeroOrganizationId The NetCero organization ID.
   * @returns The Keycloak organization ID or null if not found.
   */
  public static findKeycloakOrganizationIdForNetCeroOrganizationId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ): UUID | null {
    const organizationMapping = jwtPayload?.organization_mapping;
    if (!organizationMapping) {
      return null;
    }

    for (const [keycloakOrganizationId, mappingData] of Object.entries(organizationMapping)) {
      if (mappingData.attributes.netcero_id?.includes(netCeroOrganizationId)) {
        return keycloakOrganizationId;
      }
    }

    return null;
  }

  public static getKeycloakOrganizationIdOrThrow(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const keycloakOrganizationId =
      AuthenticationUtilities.findKeycloakOrganizationIdForNetCeroOrganizationId(
        jwtPayload,
        netCeroOrganizationId,
      );

    if (!keycloakOrganizationId) {
      throw new Error("Organization not found in JWT");
    }

    return keycloakOrganizationId;
  }

  /**
   * Extracts the organization data from the JWT payload for the given NetCero organization ID.
   * @param jwtPayload The JWT payload.
   * @param netCeroOrganizationId The NetCero organization ID.
   */
  public static findOrganizationDataForNetCeroOrganizationId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organizationMapping = jwtPayload?.organization_mapping;
    if (!organizationMapping) {
      return null;
    }

    return (
      Object.values(organizationMapping).find((mappingData) =>
        mappingData.attributes.netcero_id?.includes(netCeroOrganizationId),
      ) ?? null
    );
  }

  public static getOrganizationDataForNetCeroId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organizations = Object.values(jwtPayload.organization_mapping);
    return (
      organizations.find((org) => org.attributes.netcero_id?.[0] === netCeroOrganizationId) ?? null
    );
  }

  public static getOrganizationForNetCeroId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    return MappingUtilities.mapIfNotNull(
      AuthenticationUtilities.getOrganizationDataForNetCeroId(jwtPayload, netCeroOrganizationId),
      AuthenticationUtilities.convertJwtOrganizationInfoToOrganization,
    );
  }

  /**
   * Get the module access for a specific organization
   * @param jwtPayload The JWT payload
   * @param netCeroOrganizationId The organization ID (netcero - NOT keycloak)
   */
  public static getOrganizationModuleAccessNetCero(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ): Record<AppModule, boolean> | null {
    const organization = AuthenticationUtilities.getOrganizationDataForNetCeroId(
      jwtPayload,
      netCeroOrganizationId,
    );

    if (!organization) {
      return null;
    }

    // Full Access Check
    if (AuthenticationUtilities.checkModuleAccessJwt(organization, "full_access")) {
      return {
        [AppModule.DMA]: true,
        [AppModule.ESRS]: true,
        [AppModule.VSME]: true,
        [AppModule.CARBON_ACCOUNTING]: true,
      };
    }

    // Check Modules
    return {
      [AppModule.DMA]: AuthenticationUtilities.checkModuleAccessJwt(organization, "dma_access"),
      [AppModule.ESRS]: AuthenticationUtilities.checkModuleAccessJwt(organization, "esrs_access"),
      [AppModule.VSME]: AuthenticationUtilities.checkModuleAccessJwt(organization, "vsme_access"),
      [AppModule.CARBON_ACCOUNTING]: AuthenticationUtilities.checkModuleAccessJwt(
        organization,
        "carbon_accounting_access",
      ),
    };
  }

  /**
   * Get the available modules for a specific organization as an array
   * @param jwtPayload
   * @param netCeroOrganizationId
   */
  public static getOrganizationAvailableModulesNetCero(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organizationAccess = AuthenticationUtilities.getOrganizationModuleAccessNetCero(
      jwtPayload,
      netCeroOrganizationId,
    );
    // Handle organization not in JWT
    if (!organizationAccess) {
      return null;
    }
    // Create Array with available modules
    return (Object.entries(organizationAccess) as [AppModule, boolean][])
      .filter(([, hasAccess]) => hasAccess)
      .map(([key]) => key);
  }

  public static hasOrganizationFullAccessNetCero(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organization = AuthenticationUtilities.getOrganizationDataForNetCeroId(
      jwtPayload,
      netCeroOrganizationId,
    );
    return organization
      ? AuthenticationUtilities.checkModuleAccessJwt(organization, "full_access")
      : null;
  }

  /**
   * Get the (default) max number of DEOs (Data Entry Objects) based on the environment.
   * This method can be used, if no maximum is specified for the organization.
   * @param isProduction - Whether the application is running in production.
   * @returns The default number of DEOs (10 for production, 100 for non-production).
   */
  public static getOrganizationDefaultMaxNumberOfDeos(isProduction: boolean) {
    return isProduction ? 10 : 100;
  }

  /**
   * Get the maximum number of DEOs (Data Entry Objects) for a specific organization.
   * @param jwtPayload - The JWT payload containing organization information.
   * @param netCeroOrganizationId - The NetCero organization ID.
   * @param isProduction - Whether the application is running in production.
   * @returns The maximum number of DEOs for the organization.
   */
  public static getOrganizationMaxNumberOfDeos(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
    isProduction: boolean,
  ): number {
    const organization = AuthenticationUtilities.getOrganizationForNetCeroId(
      jwtPayload,
      netCeroOrganizationId,
    );

    return (
      organization?.deo_count_max ??
      AuthenticationUtilities.getOrganizationDefaultMaxNumberOfDeos(isProduction)
    );
  }

  /**
   * Check if the organization has access to a specific module
   * @param orgInfo The organization info
   * @param key The key to check
   */
  private static checkModuleAccessJwt(
    orgInfo: IJwtOrganizationInfo,
    key: keyof Omit<IJwtOrganizationInfo["attributes"], "netcero_id" | "deo_count_max">,
  ) {
    return orgInfo.attributes[key]?.[0] === "true";
  }

  /**
   * Get the OrgRole of the user
   * @param jwtPayload The JWT payload
   * @param netCeroOrganizationId The organization ID (netcero - NOT keycloak)
   */
  public static getOrgRoleOfUser(
    jwtPayload: IJwtPayload | undefined,
    netCeroOrganizationId: string,
  ): OrganizationRole | null {
    if (!jwtPayload) {
      return null;
    }
    const keycloakOrganizationId =
      AuthenticationUtilities.findKeycloakOrganizationIdForNetCeroOrganizationId(
        jwtPayload,
        netCeroOrganizationId,
      );

    if (!keycloakOrganizationId) {
      return null;
    }

    const organization = jwtPayload.organizations[keycloakOrganizationId];

    if (!organization) {
      return null;
    }

    return AuthenticationUtilities.getHighestOrgRole(organization.roles);
  }

  /**
   * This method check whether a user has at least one role out of a given set of roles
   * @param jwtPayload
   * @param netCeroOrganizationId
   * @param roles
   */
  public static userHasAnyOfRoles(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
    roles: OrganizationRole[],
  ): boolean {
    const roleOfUser = AuthenticationUtilities.getOrgRoleOfUser(jwtPayload, netCeroOrganizationId);
    return roleOfUser !== null && roles.includes(roleOfUser);
  }

  /**
   * Get the available OrgRole options for the user
   * @param executingUserRole The role of the user executing the request
   * @param currentTargetUserRole The role of the user that is the target of the role update request
   */
  public static getOrgRoleUpdateOptions(
    executingUserRole: OrganizationRole | null,
    currentTargetUserRole: OrganizationRole | null,
  ): OrganizationRole[] {
    // No executing user role is handled further down by the fact that the user can
    // only assign roles that are lower or equal to his own role. No role means no options.

    const [executingUserRoleLevel, currentTargetUserRoleLevel] =
      AuthenticationUtilities.mapRolesToAscendingPermissionLevels(
        executingUserRole,
        currentTargetUserRole,
      );

    // Handle executing user has less privileges than the target user (cannot update)
    if (executingUserRoleLevel < currentTargetUserRoleLevel) {
      return [];
    }

    // Get all roles that are lower or equal to the executing user role
    const rolesExecutingUserCanAssign = ORG_ROLES_ASCENDING.slice(0, executingUserRoleLevel + 1);

    // Return no option when: Only single option is available and that option is already selected
    if (
      rolesExecutingUserCanAssign.length === 1 &&
      rolesExecutingUserCanAssign[0] === currentTargetUserRole
    ) {
      return [];
    }

    // Return normal options
    return rolesExecutingUserCanAssign;
  }

  public static isUserPartOfOrganization(jwtPayload: IJwtPayload, organizationId: string) {
    const userOrganizationIds = AuthenticationUtilities.getNetCeroOrganizationIds(jwtPayload);
    return userOrganizationIds.includes(organizationId);
  }

  /**
   * Helper function to get the highest available OrgRole from an array
   * @param roles The roles
   */
  public static getHighestOrgRole(roles: string[]): OrganizationRole | null {
    for (const role of ORG_ROLES_DESCENDING) {
      if (roles.includes(role)) {
        return role;
      }
    }
    return null;
  }

  public static isOrganizationDisabled(jwtPayload: IJwtPayload, organizationId: string) {
    const organization = AuthenticationUtilities.getOrganizationDataForNetCeroId(
      jwtPayload,
      organizationId,
    );

    return !!organization?.attributes.disabled?.includes("true");
  }

  /**
   * Get the permission levels of the given roles in ascending order.
   * Basically their index in the ORG_ROLES_ASCENDING array.
   * @param roles The roles. Null values will be mapped to -1.
   */
  public static mapRolesToAscendingPermissionLevels(...roles: (OrganizationRole | null)[]) {
    return roles.map((role) => (role ? ORG_ROLES_ASCENDING.indexOf(role) : -1));
  }
}
