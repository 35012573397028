import { createContext, Dispatch, Provider, SetStateAction, useContext } from "react";
import {
  IInputParameterRecordingStructureIdentity,
  IRecordingPeriodGhgCategoryIdentity,
} from "@netcero/netcero-common";
import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";

export interface IGHGProtocolCategoriesContext {
  recordingStructureIdentity: IInputParameterRecordingStructureIdentity;
  expandedCategories: IGhgCategoriesIdentifier[];
  isCategoryExpanded: (categoryId: IGhgCategoriesIdentifier) => boolean;
  setExpandedCategories: Dispatch<SetStateAction<IGhgCategoriesIdentifier[]>>;
  toggleCategoryExpansion: Dispatch<IGhgCategoriesIdentifier>;
  expandCategory: Dispatch<IGhgCategoriesIdentifier>;
  closeCategory: Dispatch<IGhgCategoriesIdentifier>;
  isLoading: boolean;
  // Mutations
  handleGhgCategoryResponsiblePersonChange: (
    categoryId: IRecordingPeriodGhgCategoryIdentity,
    responsiblePersonId: string | null,
  ) => void;
  handleGhgCategoryContributingUsersChange: (
    categoryId: IRecordingPeriodGhgCategoryIdentity,
    contributingUserIds: string[],
  ) => void;
}

export const GhgCategoriesOverviewContext = createContext<IGHGProtocolCategoriesContext | null>(
  null,
);

export const GhgCategoriesOverviewContextProvider =
  GhgCategoriesOverviewContext.Provider as Provider<IGHGProtocolCategoriesContext>;

export const useGhgCategoriesOverviewContext = () => {
  const context = useContext(GhgCategoriesOverviewContext);
  if (!context) {
    throw new Error(
      "useGhgCategoriesOverviewContext must be used within a GhgCategoriesOverviewContextProvider",
    );
  }
  return context;
};
