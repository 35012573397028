import { PieSeriesOption } from "echarts/charts";
import { EChartsOption } from "../common/charts/base-chart.component";
import { FormatUtilities } from "../common/utilities/format.utilities";
import { GHG_EVALUATION_RESULT_UNIT_TEXT } from "./ghg-evaluations.constants";

export class GhgEvaluationAppUtilities {
  /**
   * Generates ECharts options for a pie chart.
   * @param data The data to display in the pie chart.
   * @param total The total value of all data. Used to calculate percentages.
   */
  public static getEChartPieChartOptions(
    data: PieSeriesOption["data"],
    total: number,
  ): EChartsOption {
    return {
      tooltip: {
        trigger: "item",
        align: "center",
        valueFormatter: (optionValue) => {
          // We know this is a number
          const value = optionValue as number;
          return `${FormatUtilities.formatGhgEvaluationResult(
            value,
          )} ${GHG_EVALUATION_RESULT_UNIT_TEXT} (${FormatUtilities.formatFractionAsPercentage(
            value / total,
          )})`;
        },
      },
      series: [
        {
          type: "pie",
          radius: ["50%", "90%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data,
        },
      ],
    };
  }
}
