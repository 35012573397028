/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IGhgProtocolScopeEnum = {
  Scope1: "scope_1",
  Scope2: "scope_2",
  Scope3: "scope_3",
} as const;

export type IGhgProtocolScopeEnum =
  (typeof IGhgProtocolScopeEnum)[keyof typeof IGhgProtocolScopeEnum];
