import { FC, useCallback, useEffect, useMemo } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import {
  ICommentLinkMetadataRecordingStructureTypeEnum,
  IInputParameterRecordingStructureBase,
  IInputParameterRecordingStructureGroupESRS,
} from "@netcero/netcero-core-api-client";
import { useSearchParams } from "react-router-dom";
import { GAP_ANALYSIS_TOPIC_QUERY_PARAM_KEY } from "../gap-analysis.constants";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { useInputParameterRecordingStructureDetailsQuery } from "../../input-parameter-recording-structures/input-parameter-recording-structures.queries";
import { GapAnalysisTopicView } from "./gap-analysis-topic-view.component";
import { GapAnalysisBox } from "../gap-analysis-helper-components.constants";
import { useTranslation } from "react-i18next";
import { useDataEntryObjectValuesQuery } from "../../data-entry-object-values/queries/data-entry-object-values.queries";
import { InputParameterRecordingEsrsStructuresUtilities } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import { GapAnalysisProvider, IGapAnalysisContextData } from "../gap-analysis.context";
import { QueriesWrapper } from "../../common/components/queries-wrapper.component";
import { useGapAnalysisApiActions } from "../gap-analysis-api-actions.hook";
import { useObserveSizeState } from "../../common/hooks/use-observe-size-state.hook";
import { GapAnalysisCommentsAndChangelogPaper } from "../../comments-and-audit-log/components/gap-analysis-comments-and-audit-log-paper.component";
import { useDeoCommentsAndAuditLogContext } from "../../comments-and-audit-log/context/deo-comments-and-audit-log.context";

interface IGapAnalysisTopicsOverviewProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  esrsRecordingStructures: IInputParameterRecordingStructureBase[];
}

export const GapAnalysisTopicsOverview: FC<IGapAnalysisTopicsOverviewProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  esrsRecordingStructures,
}) => {
  const { t } = useTranslation("gap_analysis");
  const translateContent = useTranslateContent();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    selectedInputParameterId,
    closeCommentsAndAuditLogSideSection,
    showCommentsAndAuditLogSideSection,
    disclosureRequirementInputParameter,
    setDisclosureRequirementInputParameter,
    sectionId,
    setSectionId,
  } = useDeoCommentsAndAuditLogContext();

  const viewedTopicStructureId = useMemo(
    () => searchParams.get(GAP_ANALYSIS_TOPIC_QUERY_PARAM_KEY),
    [searchParams],
  );
  const setViewedTopicStructureId = useCallback(
    (newViewedTopicStructureId: string) => {
      searchParams.set(GAP_ANALYSIS_TOPIC_QUERY_PARAM_KEY, newViewedTopicStructureId);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const gapAnalysisApiActions = useGapAnalysisApiActions(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    viewedTopicStructureId ?? "",
  );

  // Select first structure if none is selected
  useEffect(() => {
    if (!viewedTopicStructureId && esrsRecordingStructures[0] !== undefined) {
      setViewedTopicStructureId(esrsRecordingStructures[0].id);
    }
  }, [esrsRecordingStructures, setViewedTopicStructureId, viewedTopicStructureId]);

  const viewedRecordingStructureDetailsQuery = useInputParameterRecordingStructureDetailsQuery(
    organizationId,
    recordingPeriodId,
    viewedTopicStructureId,
  );
  const dataEntryObjectInputParametersQuery = useDataEntryObjectValuesQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    viewedTopicStructureId!, // Will always be set when relevant (due to next enabled value)
    !!viewedTopicStructureId,
  );

  const [hydratedAndCompletedStructure /* incompleteDRs, incompleteIPs */] = useMemo(() => {
    if (!dataEntryObjectInputParametersQuery.data || !viewedRecordingStructureDetailsQuery.data) {
      return [null, null, null];
    }
    const dataEntryObjectInputParameters = dataEntryObjectInputParametersQuery.data.inputParameters;
    const structure = viewedRecordingStructureDetailsQuery.data;
    if (structure.structure.type !== "esrs") {
      return [null, null, null];
    }

    const hydratedStructure = InputParameterRecordingEsrsStructuresUtilities.hydrateStructureGroup(
      structure.structure as IInputParameterRecordingStructureGroupESRS,
      dataEntryObjectInputParameters,
    );
    return InputParameterRecordingEsrsStructuresUtilities.cleanUpStructureGroup(hydratedStructure);
  }, [dataEntryObjectInputParametersQuery.data, viewedRecordingStructureDetailsQuery.data]);

  // Get the DR, IP and Section of the selected Input Parameter for the comment section
  useEffect(() => {
    if (!selectedInputParameterId || !hydratedAndCompletedStructure || !setSectionId) {
      return;
    }
    const disclosureRequirement =
      InputParameterRecordingEsrsStructuresUtilities.getDisclosureRequirementOfInputParameterById(
        selectedInputParameterId,
        hydratedAndCompletedStructure,
      );
    const inputParameter = InputParameterRecordingEsrsStructuresUtilities.getInputParameterById(
      selectedInputParameterId,
      hydratedAndCompletedStructure,
    );
    setDisclosureRequirementInputParameter({ disclosureRequirement, inputParameter });
    const selectedSectionId =
      InputParameterRecordingEsrsStructuresUtilities.getSectionIdOfInputParameterById(
        selectedInputParameterId,
        hydratedAndCompletedStructure,
      );
    setSectionId(selectedSectionId);
  }, [
    hydratedAndCompletedStructure,
    selectedInputParameterId,
    setDisclosureRequirementInputParameter,
    setSectionId,
  ]);

  // Gap Analysis Context

  const gapAnalysisContextValue: IGapAnalysisContextData = useMemo(
    () => ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      recordingStructureId: viewedTopicStructureId ?? "",
      ...gapAnalysisApiActions,
    }),
    [
      dataEntryObjectId,
      organizationId,
      recordingPeriodId,
      viewedTopicStructureId,
      gapAnalysisApiActions,
    ],
  );

  const { size: tabsContainerSize, setElementRef: setTabContainerRef } = useObserveSizeState();

  return (
    <GapAnalysisProvider value={gapAnalysisContextValue}>
      <Box flex={1} display="flex" flexDirection="column" gap={2} width="100%" maxWidth="100%">
        {/* Page Title */}
        <Typography variant="h3" component="h1">
          {t("title")}
        </Typography>
        {/* Topic Tabs */}
        <GapAnalysisBox ref={setTabContainerRef} py={1.5} px={2.5} pb={2}>
          <Tabs
            value={viewedTopicStructureId ?? ""}
            onChange={(_, newValue) => setViewedTopicStructureId(newValue)}
            variant={tabsContainerSize.width < 1000 ? "scrollable" : "fullWidth"}
          >
            {esrsRecordingStructures.map((esrsStructure) => (
              <Tab
                key={esrsStructure.id}
                value={esrsStructure.id}
                label={translateContent(esrsStructure.name)}
              />
            ))}
          </Tabs>
          {/* Filters will be added here later */}
        </GapAnalysisBox>

        {/* Recording Structure DRs View */}
        <QueriesWrapper
          queries={[viewedRecordingStructureDetailsQuery, dataEntryObjectInputParametersQuery]}
          centerLoader
          build={([recordingStructureDetails, dataEntryObjectInputParameters]) =>
            recordingStructureDetails.structure.type !== "esrs"
              ? "Invalid ESRS Topic Configuration. Please contact support."
              : hydratedAndCompletedStructure && (
                  <GapAnalysisTopicView
                    structure={recordingStructureDetails}
                    hydratedStructure={hydratedAndCompletedStructure}
                  />
                )
          }
        />
        <GapAnalysisCommentsAndChangelogPaper
          organizationId={organizationId}
          onClose={closeCommentsAndAuditLogSideSection}
          relations={{
            entity: "data-entry-object-input-parameter",
            dataEntryObjectId: dataEntryObjectId,
            inputParameterId: selectedInputParameterId ?? "",
          }}
          linkMetadata={{
            entity: "data-entry-object-input-parameter",
            organizationId: organizationId,
            recordingPeriodId: recordingPeriodId,
            dataEntryObjectId: dataEntryObjectId,
            recordingStructureId: viewedTopicStructureId ?? "",
            sectionId: sectionId ?? "",
            inputParameterId: selectedInputParameterId ?? "",
            recordingStructureType: ICommentLinkMetadataRecordingStructureTypeEnum.Esrs,
          }}
          disclosureRequirementInputParameter={disclosureRequirementInputParameter}
          open={showCommentsAndAuditLogSideSection}
        />
      </Box>
    </GapAnalysisProvider>
  );
};
