export class DmaUtilities {
  public static getIroExportFileName() {
    return `NetCero_IRO-Export_${new Date().toISOString()}.csv`;
  }

  public static getStakeholderFeedbackExportFileName() {
    return `NetCero_Stakeholder-Feedback-Export_${new Date().toISOString()}.csv`;
  }

  public static getDmaOverviewExportFileName() {
    return `NetCero_DMA-Overview-Export_${new Date().toISOString()}.csv`;
  }
}
