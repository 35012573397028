import { Dispatch, FC, useMemo } from "react";
import { Box, Button } from "@mui/material";
import { RequireFeatureFlag } from "../../common/components/require-feature-flag.component";
import { FeatureFlag } from "../../common/constants/feature-flags.constants";
import { EditIcon } from "../../common/constants/tabler-icon.constants";
import { Link as RouterLink } from "react-router-dom";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";
import {
  EvaluationBaseSelector,
  SELECTED_EVALUATION_BASE,
} from "./evaluation-base-selector.component";
import { GhgDashboardTotalEmissions } from "./ghg-dashboard-total-emissions.component";
import {
  IGhgEvaluationCalculationResult,
  IGhgProtocolScopeEnum,
  IInputParameterRecordingStructureGroupGHG,
} from "@netcero/netcero-core-api-client";
import {
  GhgEvaluationUtilities,
  IInputParameterRecordingStructureIdentity,
} from "@netcero/netcero-common";
import { useTranslation } from "react-i18next";
import { GhgDashboardScopeTabs } from "./ghg-dashboard-scope-tabs.component";
import { useDataEntryObjectsContext } from "../../data-entry-objects/data-entry-objects.context";
import { GhgDashboardContextProvider, IGhgDashboardContextData } from "../ghg-dashboard.context";
import { GhgDashboardByEmissionGroupAndDeo } from "./ghg-dashboard-by-emission-group-and-deo.component";
import { OrganizationStructureBreadcrumbs } from "../../organization-structures/navigation/organization-structure-breadcrumbs.component";
import { EvaluationBase } from "../ghg-evaluation.types";
import { useSearchParamEnumValue } from "../../common/hooks/use-search-param-enum-value.hook";

const SCOPE_TAB_QUERY_PARAM_KEY = "scope_tab";

interface IGhgDashboardProps {
  identity: IInputParameterRecordingStructureIdentity;
  recordingStructure: IInputParameterRecordingStructureGroupGHG;
  evaluationResults: IGhgEvaluationCalculationResult[];
  viewedDataEntryObjectId: string;
  onChangeViewedDataEntryObject: Dispatch<string>;
}

export const GhgDashboard: FC<IGhgDashboardProps> = ({
  identity,
  recordingStructure,
  evaluationResults,
  viewedDataEntryObjectId,
  onChangeViewedDataEntryObject,
}) => {
  const { t } = useTranslation("ghg_protocol");
  const { rootDataEntryObject, getDataEntryObject } = useDataEntryObjectsContext();

  // Syncs the enum value with query params
  const { value: selectedEvaluationBase, updateEnumValue: handleEvaluationBaseChange } =
    useSearchParamEnumValue<EvaluationBase>(
      SELECTED_EVALUATION_BASE,
      EvaluationBase,
      EvaluationBase.LocationBased,
    );

  // Syncs the enum value with query params
  const { value: viewedScope, updateEnumValue: handleViewedScopeChange } =
    useSearchParamEnumValue<IGhgProtocolScopeEnum>(
      SCOPE_TAB_QUERY_PARAM_KEY,
      IGhgProtocolScopeEnum,
      IGhgProtocolScopeEnum.Scope1,
    );

  // Results filtered by DEO
  const relevantEvaluationResultsForDeoTree = useMemo(
    () =>
      GhgEvaluationUtilities.filterGhgEvaluationResultsForDataEntryObjectAndChildren(
        evaluationResults,
        getDataEntryObject(viewedDataEntryObjectId) ?? rootDataEntryObject,
      ),
    [evaluationResults, getDataEntryObject, rootDataEntryObject, viewedDataEntryObjectId],
  );

  const ghgDashboardContextValue: IGhgDashboardContextData = useMemo(
    () => ({
      viewedDataEntryObjectId,
      changeViewedDataEntryObjectId: onChangeViewedDataEntryObject,
      relevantEvaluationResultsForDeoTree,
      recordingStructure,
      changeViewedScope: handleViewedScopeChange,
      activeView: selectedEvaluationBase,
      viewedScope,
    }),
    [
      handleViewedScopeChange,
      onChangeViewedDataEntryObject,
      recordingStructure,
      relevantEvaluationResultsForDeoTree,
      selectedEvaluationBase,
      viewedDataEntryObjectId,
      viewedScope,
    ],
  );

  return (
    <GhgDashboardContextProvider value={ghgDashboardContextValue}>
      <Box display="flex" flexDirection="column" gap={3} width="100%" maxWidth="1300px" mx="auto">
        <RequireFeatureFlag featureFlag={FeatureFlag.APP_SHELL_V2} negate>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            sx={{ mb: 2, mr: "auto" }}
            component={RouterLink}
            to={AppInternalNavigationUtilities.getGhgProtocolPageUrl(identity)}
          >
            {t("button_back_to_recording")}
          </Button>
        </RequireFeatureFlag>
        {/* Market vs Location based Toggle */}
        <Box display="flex" alignItems="center">
          {/* TODO: Remove once APP Shell 2 is released */}
          <RequireFeatureFlag featureFlag={FeatureFlag.APP_SHELL_V2} negate>
            <OrganizationStructureBreadcrumbs
              organizationStructure={rootDataEntryObject}
              dataEntryObjectId={viewedDataEntryObjectId}
              onChange={onChangeViewedDataEntryObject}
              isDataEntryObjectSelectable={(dataEntryObject) => dataEntryObject.children.length > 0}
            />
          </RequireFeatureFlag>
          <Box flex={1} />
          <EvaluationBaseSelector
            selectedBase={selectedEvaluationBase}
            onChange={handleEvaluationBaseChange}
          />
        </Box>
        {/* Total Emissions Card */}
        <GhgDashboardTotalEmissions />
        {/* By GHG Category & DEO Card */}
        <GhgDashboardByEmissionGroupAndDeo />
        {/* Scope Tabs */}
        <GhgDashboardScopeTabs />
      </Box>
    </GhgDashboardContextProvider>
  );
};
