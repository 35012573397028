import { stringify } from "csv/sync";
import { Options } from "csv-stringify/lib";

export class CsvUtilities {
  public static readonly DEFAULT_CSV_SERIALIZATION_OPTIONS: Options = {
    delimiter: ";",
    header: true,
    quoted: true,
    quoted_empty: true,
    quoted_string: true,
  };

  /**
   * Stringifies the provided rows into a CSV string.
   * @param columns
   * @param rows The rows to be stringified.
   * @param overrideOptions The options for the CSV serialization.
   */
  public static stringifyRows<T>(columns: string[], rows: T[], overrideOptions?: Options): string {
    return stringify(rows, {
      columns,
      ...CsvUtilities.DEFAULT_CSV_SERIALIZATION_OPTIONS,
      ...overrideOptions,
    });
  }
}
