import { IInternalFormActivityValueData } from "./ghg-activity-values.types";
import { IActivityValue, IBaseActivityValueData } from "@netcero/netcero-core-api-client";
import { isEqual } from "lodash";
import { IActivityValuesImportSimpleValueRowDataWithEmissionFactor } from "@netcero/netcero-common";

export class GhgActivityValuesUtilities {
  public static readonly DEFAULT_VALUES: IInternalFormActivityValueData = {
    id: null,
    value: null,
    emissionFactorId: null,
    dataQuality: null,
    fromDate: null,
    toDate: null,
    note: null,
  };

  public static convertGhgActivityValueToInternalFormValue(
    activityValue: IActivityValue,
  ): IInternalFormActivityValueData {
    return {
      id: activityValue.id,
      value: activityValue.value.toString(),
      emissionFactorId: activityValue.emissionFactorId,
      dataQuality: activityValue.dataQuality ?? null,
      fromDate: activityValue.fromDate ?? null,
      toDate: activityValue.toDate ?? null,
      note: activityValue.note ?? null,
    };
  }

  public static convertGhgActivityInternalFormValueToValue(
    activityValue: IInternalFormActivityValueData,
  ): IBaseActivityValueData {
    return {
      value: +activityValue.value!,
      emissionFactorId: activityValue.emissionFactorId!,
      dataQuality: activityValue.dataQuality ?? undefined,
      fromDate: activityValue.fromDate ?? undefined,
      toDate: activityValue.toDate ?? undefined,
      note: activityValue.note ?? undefined,
    };
  }

  public static areValuesEqual(
    a: IInternalFormActivityValueData,
    b: IInternalFormActivityValueData,
  ) {
    return isEqual(a, b);
  }

  public static createFormValueDataFromImportData(
    importData: IActivityValuesImportSimpleValueRowDataWithEmissionFactor,
  ): IInternalFormActivityValueData {
    return {
      ...GhgActivityValuesUtilities.DEFAULT_VALUES,
      value: importData.value.toString(),
      emissionFactorId: importData.emissionFactorId,
    };
  }
}
