import { FormControl } from "@mui/material";
import { IInputParameterValueMetaDataYear } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { BaseDateComponent } from "./base-date.component";
import { DataEntryObjectInputParameterValueDefinitionForYear } from "@netcero/netcero-common";
import {
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { ICommonInputProps } from "../value-acquisition.types";

type IDateYearInputComponentProps = ICommonInputProps<
  IInputParameterValueMetaDataYear,
  DataEntryObjectInputParameterValueDefinitionForYear
>;

export const DateYearInputComponent: FC<IDateYearInputComponentProps> = ({
  variant,
  value,
  onChange,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  label,
  required,
}) => {
  const stylesSx = useVariantSxStyles(variant);
  const variantProps = useVariantFormProps(variant);

  const valueLimits = useMemo(
    () => ({
      min:
        metaData.valueLimits.min !== undefined
          ? new Date(metaData.valueLimits.min, 1, 1).toISOString()
          : undefined,
      max:
        metaData.valueLimits.max !== undefined
          ? new Date(metaData.valueLimits.max, 1, 1).toISOString()
          : undefined,
    }),
    [metaData.valueLimits],
  );

  return (
    <FormControl
      {...variantProps}
      error={!!error}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined, ...stylesSx }}
    >
      <BaseDateComponent
        variant={variant}
        value={value ? new Date(value, 1, 1).toISOString() : undefined}
        onChange={(value) => onChange(new Date(value).getFullYear())}
        disabled={disabled}
        error={error}
        min={valueLimits.min}
        max={valueLimits.max}
        label={label}
        required={required}
        views={["year"]}
      />
    </FormControl>
  );
};
