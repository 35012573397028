import {
  IEmissionFactorType,
  IEmissionFactorUnit,
  IMarketBasedEmissionFactorType,
} from "@netcero/netcero-core-api-client";

export const EMISSION_UNIT_TEXT = "kgCO₂e";

export const DEFAULT_EMISSION_FACTOR_TYPE = IEmissionFactorType.VolumeBased;

export const ORDERED_EMISSION_FACTOR_UNITS = [
  IEmissionFactorUnit.FZkm,
  IEmissionFactorUnit.Pkm,
  IEmissionFactorUnit.Tkm,
  IEmissionFactorUnit.T,
  IEmissionFactorUnit.L,
  IEmissionFactorUnit.M2,
  IEmissionFactorUnit.KWh,
  IEmissionFactorUnit.MioEuros,
  IEmissionFactorUnit.KgCo2eq,
] as const;

export const ORDERED_EMISSION_FACTOR_UNITS_FOR_EDIT_DIALOG = ORDERED_EMISSION_FACTOR_UNITS.filter(
  (unit) => unit !== IEmissionFactorUnit.KgCo2eq, // --> Not available since only for internal
);

export const ORDERED_EMISSION_FACTOR_TYPE = [
  IEmissionFactorType.VolumeBased,
  IEmissionFactorType.MassBased,
  IEmissionFactorType.DistanceBased,
  IEmissionFactorType.EnergyBased,
  IEmissionFactorType.MarketBasedEnergy,
  IEmissionFactorType.LocationBasedEnergy,
  IEmissionFactorType.SpendingBased,
  IEmissionFactorType.Direct,
  IEmissionFactorType.Other,
] as const;

export const ORDERED_EMISSION_FACTOR_TYPE_FOR_EDIT_DIALOG = ORDERED_EMISSION_FACTOR_TYPE.filter(
  (type) => type !== IEmissionFactorType.Direct, // --> Not available since only for internal
);

export const ORDERED_MARKET_BASED_EMISSION_FACTOR_TYPES = [
  IMarketBasedEmissionFactorType.ProductMix,
  IMarketBasedEmissionFactorType.ResidualMix,
  IMarketBasedEmissionFactorType.SupplierMix,
] as const;
