/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IEmissionFactorDatabase = {
  UbaAt: "uba_at",
  Defra: "defra",
  OrganizationSpecific: "organization_specific",
  Climcalc: "climcalc",
  Dgnb: "dgnb",
  Ecoinvent: "ecoinvent",
  Exiobase: "exiobase",
  Bafa: "bafa",
  Owid: "owid",
  Aib: "aib",
  Unspecified: "unspecified",
} as const;

export type IEmissionFactorDatabase =
  (typeof IEmissionFactorDatabase)[keyof typeof IEmissionFactorDatabase];
