import {
  NUMBER_DEFAULT_PRECISION,
  NUMBER_PRECISION_GHG_EMISSION,
  ObjectUtilities,
  UndefinedOrNull,
} from "@netcero/netcero-common";

export class FormatUtilities {
  public static formatNumber(
    num: UndefinedOrNull<number>,
    options?: Intl.NumberFormatOptions,
  ): string {
    return !ObjectUtilities.isValuePresent(num) || Number.isNaN(num)
      ? "-"
      : num.toLocaleString(navigator.language, options);
  }

  /**
   * Formats a number to a fixed number of decimal places.
   * Returns "-" for NaN.
   * @param num - The number to format.
   * @param decimalPlaces - The number of decimal places to display
   */
  public static formatFixedDecimal(num: UndefinedOrNull<number>, decimalPlaces: number): string {
    return FormatUtilities.formatNumber(num, {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
    });
  }

  /**
   * Formats a number with optional fraction digit control. Wrapper for FormatUtilities.formatNumber
   * @param num - The number to format.
   * @param minFractionDigits - Optional minimum fraction digits.
   * @param maxFractionDigits - Optional maximum fraction digits.
   * @returns The formatted number as a string.
   */
  public static formatDecimal(
    num: UndefinedOrNull<number>,
    minFractionDigits?: number,
    maxFractionDigits?: number,
  ): string {
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
    };
    return FormatUtilities.formatNumber(num, options);
  }

  public static formatEmissionFactorFactor(num: UndefinedOrNull<number>) {
    return FormatUtilities.formatDecimal(num, 0, NUMBER_DEFAULT_PRECISION);
  }

  public static formatGhgEvaluationResult(num: number) {
    return FormatUtilities.formatDecimal(num, 0, NUMBER_PRECISION_GHG_EMISSION);
  }

  public static formatOptionalString(value: UndefinedOrNull<string>, defaultValue = "-"): string {
    return value ?? defaultValue;
  }

  /**
   * Extends the formatPercentage method to format a fraction as a percentage.
   * 0 is 0% and 1 is 100%
   * @param num
   * @param fractionDigits
   * @param minimumIntegerDigits
   * @param minimumFractionDigits
   */
  public static formatFractionAsPercentage(
    num: number,
    fractionDigits = 2,
    minimumIntegerDigits?: number,
    minimumFractionDigits?: number,
  ) {
    return Number.isNaN(num)
      ? "-"
      : num.toLocaleString(navigator.language, {
          maximumFractionDigits: fractionDigits,
          minimumIntegerDigits,
          minimumFractionDigits: minimumFractionDigits ?? fractionDigits,
          style: "percent",
        });
  }

  /**
   *
   * @param num Value as Percent (0 is 0% and 100 is 100%)
   * @param fractionDigits The maximum number of fraction digits to display. (will be rounded)
   * Example: 2 will render 0.123456 as 12.35
   * Default: 2
   * @param minimumIntegerDigits The minimum number of digits (like lpad with 0 until number of digits in front of decimal point is reached) to use for the integer part of the number.
   * Example: 2 will render the number 1 as 01
   * @param minimumFractionDigits If not provided, it will be the same as fractionDigits. If provided, it will be the minimum number of fraction digits.
   * Example: 2 will render 1 as 1.00
   * @returns
   */
  public static formatPercentAsPercentage(
    num: number,
    fractionDigits = 2,
    minimumIntegerDigits?: number,
    minimumFractionDigits?: number,
  ) {
    return FormatUtilities.formatFractionAsPercentage(
      num / 100,
      fractionDigits,
      minimumIntegerDigits,
      minimumFractionDigits,
    );
  }

  public static formatCurrency(num: number, currency: string = "EUR"): string {
    return num.toLocaleString(navigator.language, { style: "currency", currency });
  }

  public static formatDate(date: Date | string): string {
    return new Date(date).toLocaleDateString(navigator.language);
  }

  public static formatDateRange(fromDate: Date | string, toDate: Date | string) {
    return `${FormatUtilities.formatDate(fromDate)} - ${FormatUtilities.formatDate(toDate)}`;
  }

  /**
   * Formats a date time string to a locale string
   * @param date The date to format
   * @param locale The locale to use for formatting. If not provided `navigator.language` is used.
   */
  public static formatDateTime(date: Date | string, locale?: string): string {
    return new Date(date).toLocaleString(locale ?? navigator.language);
  }

  /**
   * Shortens a text to a max length
   * @param text The text to limit in length
   * @param maxLength Max length (including the 3 dots)
   * @returns cut string
   * @deprecated Use LineClampText component instead
   */
  public static cutTextAfterN(text: string, maxLength: number) {
    return text.length > maxLength ? text.substring(0, maxLength - 3) + "..." : text;
  }

  public static formatBoolean(
    materiality: boolean | null | undefined,
    textWhenTrue: string,
    textWhenFalse: string,
    textWhenEmpty = "-",
  ): string {
    return materiality !== null && materiality !== undefined
      ? materiality
        ? textWhenTrue
        : textWhenFalse
      : textWhenEmpty;
  }

  /**
   * A method to create dynamic URLs from provided path segments.
   *
   * @param segments - An array of URL segments that will be concatenated.
   * @returns - The assembled URL.
   */
  public static generateDynamicUrl(segments: string[]): string {
    return `/${segments.join("/")}`;
  }
}
