import { FC, useMemo, useRef } from "react";
import { Menu } from "@mui/material";
import { useUserContext } from "../../user/user.context";
import { IActivity, IActivityStatus } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { GhgActivityStatusIndicator } from "./ghg-activity-status-indicator.component";
import {
  IStatusChangeMenuItem,
  StatusChangeMenuItems,
} from "../../common/components/status-change-menu-items.component";
import { useOpenStateWithForwardClose } from "../../common/hooks/open-state-with-forward-close.hook";

export interface IGhgActivityStatusUpdateEvents {
  onReset: VoidFunction;
  onSubmit: VoidFunction;
  onApprove: VoidFunction;
  onReject: VoidFunction;
}

interface IGhgActivityStatusIndicatorWithMenuProps extends IGhgActivityStatusUpdateEvents {
  activity: IActivity;
  disabled?: boolean;
}

export const GhgActivityStatusIndicatorWithMenu: FC<IGhgActivityStatusIndicatorWithMenuProps> = ({
  activity,
  onReset,
  onSubmit,
  onApprove,
  onReject,
  disabled,
}) => {
  const { t } = useTranslation("ghg_activities");

  const { user } = useUserContext();

  const { status, responsibleUserId } = activity;
  const isResponsibleUser = useMemo(
    () => responsibleUserId && responsibleUserId === user?.userProfile?.id,
    [responsibleUserId, user],
  );

  const statusIndicatorRef = useRef<HTMLDivElement>(null);

  const { showMenu, setShowMenu, forwardWithCloseMenu } = useOpenStateWithForwardClose();

  const statusChangeItems: IStatusChangeMenuItem[] = useMemo(() => {
    switch (status) {
      case IActivityStatus.InProgress:
        return [
          {
            id: "submit",
            onClick: forwardWithCloseMenu(onSubmit),
            disabled: !!disabled,
            title: t(
              responsibleUserId
                ? "transition_actions.submit_with_reviewer"
                : "transition_actions.submit_without_reviewer",
            ),
            newStatusIndicator: (
              <GhgActivityStatusIndicator
                status={responsibleUserId ? IActivityStatus.InReview : IActivityStatus.Done}
              />
            ),
          },
        ];
      case IActivityStatus.InReview: {
        const tooltip = !isResponsibleUser
          ? t("transition_actions.notice_only_responsible_person_can_approve_or_reject")
          : null;
        return [
          /* Approve Option */
          {
            id: "approve",
            onClick: forwardWithCloseMenu(onApprove),
            tooltip,
            disabled: !isResponsibleUser || !!disabled,
            title: t("transition_actions.approve"),
            newStatusIndicator: <GhgActivityStatusIndicator status={IActivityStatus.Done} />,
          },
          /* Reject Option */
          {
            id: "reject",
            onClick: forwardWithCloseMenu(onReject),
            tooltip,
            disabled: !isResponsibleUser || !!disabled,
            title: t("transition_actions.reject"),
            newStatusIndicator: <GhgActivityStatusIndicator status={IActivityStatus.InProgress} />,
          },
        ];
      }
      case IActivityStatus.Done:
        return [
          {
            id: "reset",
            onClick: forwardWithCloseMenu(onReset),
            disabled: !!disabled,
            title: t("transition_actions.reset"),
            newStatusIndicator: <GhgActivityStatusIndicator status={IActivityStatus.InProgress} />,
          },
        ];
      default:
        return [];
    }
  }, [
    disabled,
    forwardWithCloseMenu,
    isResponsibleUser,
    onApprove,
    onReject,
    onReset,
    onSubmit,
    responsibleUserId,
    status,
    t,
  ]);

  return (
    <>
      {/* Menu */}
      <Menu
        open={showMenu}
        anchorEl={statusIndicatorRef.current}
        onClose={() => setShowMenu(false)}
      >
        <StatusChangeMenuItems items={statusChangeItems} />
      </Menu>

      {/* Status */}
      <GhgActivityStatusIndicator
        ref={statusIndicatorRef}
        status={status}
        onClick={() => setShowMenu(true)}
        disabled={disabled}
        isMenuOpen={showMenu}
        shouldRenderChevronIcon
      />
    </>
  );
};
