import { FC } from "react";
import { ICommonInputProps } from "../value-acquisition.types";
import { IInputParameterValueMetaDataTextArea } from "@netcero/netcero-core-api-client";
import { DataEntryObjectInputParameterValueDefinitionForTextArea } from "@netcero/netcero-common";
import { Box, FormHelperText, Typography } from "@mui/material";
import { LexicalToolbarPlugin } from "../../lexical/plugins/toolbar/lexical-toolbar-plugin.component";
import { BoldButton } from "../../lexical/plugins/toolbar/bold-button.component";
import { ItalicButton } from "../../lexical/plugins/toolbar/italic-button.component";
import { UnderlineButton } from "../../lexical/plugins/toolbar/underline-button.component";
import { StrikethroughButton } from "../../lexical/plugins/toolbar/strikethrough-button.component";
import { LexicalRichTextEditor } from "../../lexical/lexical-rich-text-editor.component";
import { useTranslation } from "react-i18next";
import { ValueAndOnChange } from "../../lexical/plugins/value-and-on-change-plugin.component";
import { SaveOnCtrlEnterPlugin } from "../../lexical/plugins/save-on-ctrl-enter-plugin.component";
import { IDataEntryObjectValueInputVariant } from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { ToolbarDivider } from "../../lexical/plugins/toolbar/toolbar-divider.component";
import { BlockTypeDropDown } from "../../lexical/plugins/toolbar/block-type-drop-down.component";
import { LexicalBlockType } from "../../lexical/lexical.types";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { UndoButton } from "../../lexical/plugins/toolbar/undo-button.component";
import { RedoButton } from "../../lexical/plugins/toolbar/redo-button.component";
import { AutoFocusPlugin } from "../../lexical/plugins/auto-focus-plugin.component";
import { AiButton } from "../../lexical/plugins/toolbar/ai-button.component";
import { InsertTableButton } from "../../lexical/plugins/toolbar/insert-table-button.component";

type ITextAreaInputProps = ICommonInputProps<
  IInputParameterValueMetaDataTextArea,
  DataEntryObjectInputParameterValueDefinitionForTextArea
>;

const PLACEHOLDER_PREFIX = "placeholder";

export function getTextAreaPlaceholderTranslationKey(variant: IDataEntryObjectValueInputVariant) {
  switch (variant) {
    case "esrs-dr":
      return `${PLACEHOLDER_PREFIX}.disclosure_requirement`;
    case "esrs-ip":
      return `${PLACEHOLDER_PREFIX}.input_parameter`;
    default:
      return `${PLACEHOLDER_PREFIX}.default`;
  }
}

const SUPPORTED_BLOCK_TYPES: LexicalBlockType[] = [
  "paragraph",
  "h1",
  "h2",
  "h3",
  "h4",
  "bullet",
  "number",
];

export const TextAreaInput: FC<ITextAreaInputProps> = ({
  variant,
  value,
  onChange,
  onSubmit,
  disabled,
  error,
  // metaData, // No data in metaData currently
  label,
  required,
  disableMaxWidth,
  autoFocus,
}) => {
  const { t } = useTranslation("text_area_input");

  const lexicalVariant = variant === "table" ? "table" : "esrs";
  const hasToolbar = variant !== "table";
  const hasLabel = variant !== "table";
  const placeholderKey = getTextAreaPlaceholderTranslationKey(variant);

  return (
    <Box flex={1} maxWidth={!disableMaxWidth ? 320 : undefined}>
      {hasLabel && label && (
        <Typography fontWeight="bold">
          {label}
          {required ? "*" : ""}
        </Typography>
      )}
      <LexicalRichTextEditor
        variant={lexicalVariant}
        initiallyEditable
        error={!!error}
        placeholder={t(placeholderKey)}
        beforeInput={
          hasToolbar && (
            <LexicalToolbarPlugin>
              <UndoButton disabled={disabled} />
              <RedoButton disabled={disabled} />
              <ToolbarDivider />
              <BlockTypeDropDown supportedBlockTypes={SUPPORTED_BLOCK_TYPES} disabled={disabled} />
              <ToolbarDivider />
              <BoldButton disabled={disabled} />
              <ItalicButton disabled={disabled} />
              <UnderlineButton disabled={disabled} />
              <StrikethroughButton disabled={disabled} />
              <InsertTableButton disabled={disabled} />
              <ToolbarDivider />
              <AiButton disabled={disabled} />
            </LexicalToolbarPlugin>
          )
        }
        inInnerEditor={
          <>
            {/* Other Plugins */}
            {autoFocus && <AutoFocusPlugin />}
            {/* Text Plugins */}
            <ListPlugin />
            {/* Events */}
            <ValueAndOnChange value={value} onChange={(value) => onChange(value ?? undefined)} />
            {onSubmit && <SaveOnCtrlEnterPlugin onSave={onSubmit} />}
          </>
        }
        disabled={disabled}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};
