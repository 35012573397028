import { Box, Divider, Link, MenuItem, Popover, SvgIcon, Typography } from "@mui/material";

import { FC, MouseEvent, useMemo, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  getSidebarElementAnimationProperties,
  SIDE_BAR_EXPANDED_WIDTH_V2,
  SIDE_BAR_TRANSITION_DURATION_V2,
} from "../components/side-bar-v2.component";
import { SIDE_BAR_COLORS } from "../../../theme/theme";

// TODO: NC-XYZ Clean up - make name not optional
export interface ISideBarNavigationItem {
  id: string;
  name?: string;
  path: string;
  icon?: FC;
  items?: ISideBarNavigationItem[];
}

// TODO: NC-XYZ Clean up - remove path and Icon props
export interface ISideBarNavigationGroupV2 {
  id: string;
  name?: string;
  items?: ISideBarNavigationItem[];
  path?: string;
  icon?: FC;
}

export interface INavigationItemsSidebarSectionProps {
  activePath: string;
  groups: ISideBarNavigationGroupV2[];
  expanded: boolean;
  subItemsMenuEnabled: boolean;
  setSubItemsMenuEnabled: (subItemsMenuEnabled: boolean) => void;
}

/**
 * This component renders a list of navigation items in the side bar.
 */
export const NavigationItemsSideBarSectionV2: FC<INavigationItemsSidebarSectionProps> = ({
  activePath,
  groups,
  expanded,
  subItemsMenuEnabled,
  setSubItemsMenuEnabled,
}) => {
  const bestMatchItem = useMemo(
    () =>
      groups
        .flatMap((group) => group.items ?? [])
        .filter((item) => activePath.startsWith(item.path))
        .reduce(
          (prev, curr) => (prev === null || curr.path.length > prev.path.length ? curr : prev),
          null as ISideBarNavigationItem | null,
        ),
    [activePath, groups],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [activeItem, setActiveItem] = useState<ISideBarNavigationItem | null>(null);
  const isMouseOverItem = useRef(false);
  const isMouseOverPopover = useRef(false);

  const handleMouseEnter = (event: MouseEvent<HTMLDivElement>, item: ISideBarNavigationItem) => {
    if (item.items && item.items.length > 0) {
      isMouseOverItem.current = true;
      setAnchorEl(event.currentTarget);
      setActiveItem(item);
    }
  };

  const handleMouseLeave = (event: MouseEvent<HTMLDivElement>, item: ISideBarNavigationItem) => {
    if (item.items && item.items.length > 0) {
      isMouseOverItem.current = false;
      leaveItem();
    }
  };

  const handlePopoverMouseEnter = () => {
    isMouseOverPopover.current = true;
    setSubItemsMenuEnabled(true);
  };

  const handlePopoverMouseLeave = (event: MouseEvent<HTMLDivElement>) => {
    isMouseOverPopover.current = false;
    leaveItem();
  };

  const leaveItem = () => {
    setTimeout(() => {
      if (!isMouseOverPopover.current && !isMouseOverItem.current) {
        setAnchorEl(null);
        setActiveItem(null);
      }
    }, 100);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {groups.map(
        (group) =>
          // Check if group should even be displayed
          group.items &&
          group.items.length > 0 && (
            // Render Group
            <Box key={group.id} display="flex" flexDirection="column" gap={0.25}>
              <GroupTitle group={group} expanded={expanded} />
              {group.items?.map((item) => (
                <Link
                  key={item.id}
                  to={item.path}
                  underline="none"
                  color="inherit"
                  component={RouterLink}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    borderRadius={2}
                    py={1}
                    px={1.5}
                    sx={{
                      backgroundColor:
                        bestMatchItem?.id === item.id
                          ? SIDE_BAR_COLORS.buttonBackgroundColor
                          : undefined,
                      color:
                        bestMatchItem?.id === item.id
                          ? SIDE_BAR_COLORS.buttonTextColorSelected
                          : SIDE_BAR_COLORS.buttonTextColor,
                      transitionTimingFunction: "ease-in-out",
                      ":hover": {
                        backgroundColor: SIDE_BAR_COLORS.buttonBackgroundColorHover,
                      },
                    }}
                    onMouseEnter={(e) => {
                      handleMouseEnter(e, item);
                    }}
                    onMouseLeave={(e) => handleMouseLeave(e, item)}
                  >
                    {item.icon && (
                      <SvgIcon
                        sx={{
                          height: "3rem",
                        }}
                      >
                        <item.icon />
                      </SvgIcon>
                    )}
                    <Typography
                      variant="body1"
                      color="inherit"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      width="200px"
                      sx={{
                        opacity: expanded ? 1 : 0,
                        ...getSidebarElementAnimationProperties("width", "opacity"),
                      }}
                      pl={1}
                    >
                      {item.name}
                    </Typography>

                    {item.items && item.items.length > 0 && activeItem?.id === item.id && (
                      <Popover
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: "none",
                          "& .MuiPaper-root": {
                            boxShadow: "8px 0px 12px 0px rgba(0, 0, 0, 0.15)",
                          },
                        }}
                        anchorEl={anchorEl}
                        open={subItemsMenuEnabled && Boolean(anchorEl)}
                        transitionDuration={SIDE_BAR_TRANSITION_DURATION_V2}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: SIDE_BAR_EXPANDED_WIDTH_V2,
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        disableRestoreFocus
                      >
                        <Box
                          sx={{ pointerEvents: "auto" }}
                          onMouseEnter={handlePopoverMouseEnter}
                          onMouseLeave={handlePopoverMouseLeave}
                        >
                          {item.items?.map((subItem) => (
                            <Link
                              key={subItem.id}
                              component={RouterLink}
                              to={subItem.path ?? "/"}
                              underline="none"
                              sx={{ color: "inherit" }}
                            >
                              <MenuItem sx={{ py: 1, px: 2 }}>{subItem.name}</MenuItem>
                            </Link>
                          ))}
                        </Box>
                      </Popover>
                    )}
                  </Box>
                </Link>
              ))}
            </Box>
          ),
      )}
    </Box>
  );
};

interface IGroupTitleProps {
  group: ISideBarNavigationGroupV2;
  expanded: boolean;
}

const GroupTitle: FC<IGroupTitleProps> = ({ group, expanded }) => {
  if (group.name === undefined) {
    return null;
  }
  if (group.name === "") {
    return (
      <Box mb={1} py={1}>
        <Divider />
      </Box>
    );
  }

  return (
    <Box position="relative" sx={{ py: 1.5, mb: 0.5 }}>
      {/* display divider when not expanded */}
      <Divider
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          mt: 1.55,
          // Animation
          opacity: expanded ? 0 : 1,
          ...getSidebarElementAnimationProperties("opacity"),
        }}
      />

      {/* display group name when expanded */}
      <Typography
        px={0}
        py={0.5}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          color: SIDE_BAR_COLORS.groupTitleColor,
          fontSize: "11px !important",
          fontWeight: 400,
          whiteSpace: "nowrap",
          overflow: "hidden",
          // Animation
          opacity: expanded ? 1 : 0,
          ...getSidebarElementAnimationProperties("opacity"),
        }}
      >
        {group.name}
      </Typography>
    </Box>
  );
};
