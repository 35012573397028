import { FC, useCallback, useMemo } from "react";
import { getFilterConfigGenerator } from "../../../filters/filter-bar.component";
import { EmissionFactorDialogFilter } from "../../../filters/filter.constants";
import { FilterType, IFilterConfig } from "../../../filters/filter.type";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { ClearButton } from "../../../common/components/buttons.components";
import { useGetEmissionFactorFilters } from "../../hooks/get-emission-factor-filter-options.hook";
import { MapFilterState, MapStateFilters } from "../../../filters/map-state-filters.component";
import { EmissionFactorUnitNameShortWithTooltip } from "../emission-factor-unit-name.component";
import { IEmissionFactorUnit } from "@netcero/netcero-core-api-client";

interface IEmissionFactorsFiltersProps {
  mutableFilters: MapFilterState;
}

export const EmissionFactorsDialogFilters: FC<IEmissionFactorsFiltersProps> = ({
  mutableFilters,
}) => {
  const { t } = useTranslation("emission_factors_filters");
  const {
    databaseOptions,
    emissionFactorTypeOptions,
    ghgCategoryOptions,
    countryOptions,
    unitOptions,
  } = useGetEmissionFactorFilters();

  const generateFilterConfig = useMemo(() => getFilterConfigGenerator(t), [t]);

  const [searchFilter, ...restFilters]: IFilterConfig[] = useMemo(
    () => [
      generateFilterConfig(EmissionFactorDialogFilter.nameDialog, {
        type: FilterType.SearchOpen,
      }),
      generateFilterConfig(EmissionFactorDialogFilter.databasesDialog, {
        type: FilterType.Select,
        options: databaseOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorDialogFilter.emissionFactorTypesDialog, {
        type: FilterType.Select,
        options: emissionFactorTypeOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorDialogFilter.ghgCategoriesDialog, {
        type: FilterType.SearchMenu,
        options: ghgCategoryOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorDialogFilter.countriesDialog, {
        type: FilterType.SearchMenu,
        options: countryOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorDialogFilter.unitsDialog, {
        type: FilterType.Select,
        options: unitOptions,
        multiple: true,
        buildMenuItem: (option) => (
          <EmissionFactorUnitNameShortWithTooltip
            unit={option.value as IEmissionFactorUnit}
            tooltipProps={{ placement: "right" }}
          />
        ),
      }),
    ],
    [
      generateFilterConfig,
      databaseOptions,
      emissionFactorTypeOptions,
      ghgCategoryOptions,
      unitOptions,
      countryOptions,
    ],
  );

  const handleClear = useCallback(() => {
    mutableFilters.delete(searchFilter.name);
    restFilters.forEach((filter) => mutableFilters.delete(filter.name));
  }, [searchFilter, restFilters, mutableFilters]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 2, mb: 4, gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Search Name Filter */}
        <MapStateFilters filters={[searchFilter]} mutableMapFilterState={mutableFilters} />
        <ClearButton onClick={handleClear} sx={{ ml: 2 }}>
          {t("clear_filter", { ns: "filter_bar" })}
        </ClearButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          columnGap: 1.5,
          rowGap: 0.5,
        }}
      >
        {/* Other Filters */}
        <MapStateFilters filters={restFilters} mutableMapFilterState={mutableFilters} />
      </Box>
    </Box>
  );
};
