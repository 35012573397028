import * as React from "react";
import { Dispatch, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Alert,
  Box,
  DialogActions,
  DialogContent,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IActivity,
  IActivityValue,
  IBaseActivityValueData,
  IWithSources,
} from "@netcero/netcero-core-api-client";
import { EmissionFactorChip } from "../../emission-factors/components/emission-factor-chip.component";
import { IGhgActivityIdentity, IRecordingPeriodGhgCategoryIdentity } from "@netcero/netcero-common";
import { LinkedSourcesDialogInput } from "../../sources/components/linked-sources-dialog-input.component";
import { FormProvider, useForm } from "react-hook-form";
import {
  DialogCancelButton,
  DialogSaveButton,
  DialogUploadButton,
} from "../../common/dialogs/dialog-button.components";
import { useTranslation } from "react-i18next";
import { IActivityDetailsDialogEditTabState } from "../dialogs/activity-details.dialog";
import { useActivityValuesQuery } from "../../ghg-activity-values/ghg-activity-values.queries";
import {
  GhgActivityValuesTableForm,
  IGhgActivityValuesTableFormRef,
  IGhgActivityValuesTableMode,
} from "../../ghg-activity-values/components/ghg-activity-values-table-form.component";
import { GhgActivityValuesUtilities } from "../../ghg-activity-values/ghg-activity-values.utilities";
import { IInternalFormActivityValueData } from "../../ghg-activity-values/ghg-activity-values.types";
import {
  GhgActivityStatusIndicatorWithMenu,
  IGhgActivityStatusUpdateEvents,
} from "./ghg-activity-status-indicator-with-menu.component";
import { CommentsButtonWithQuery } from "../../comments/components/comments-button.component";
import { AuditLogsButton } from "../../audit-logging/components/audit-logs-button.component";
import { UploadIcon } from "../../common/constants/tabler-icon.constants";
import {
  ActivityUploadDialog,
  IActivityUploadDialogCloseCallback,
} from "../../ghg-activity-values-upload/dialogs/activity-upload.dialog";
import { useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";

interface IActivityDetailsDialogSourcesAndValuesFormData extends IWithSources {
  activityValues: IInternalFormActivityValueData[];
}

const getFormData = (
  activity: IActivity | undefined,
  activityValues?: IActivityValue[],
): IActivityDetailsDialogSourcesAndValuesFormData => ({
  sourceIds: activity?.sourceIds ?? [],
  activityValues: (activityValues ?? []).map(
    GhgActivityValuesUtilities.convertGhgActivityValueToInternalFormValue,
  ),
});

export type IActivityDetailsDialogSourcesAndValuesOnSave = (
  newSourceIds: string[] | null,
  newValues: IBaseActivityValueData[],
  updatedValues: [string, IBaseActivityValueData][],
  deletedValues: string[],
) => void;

interface IActivityDetailsDialogSourcesAndValuesProps {
  ghgCategoryIdentity: IRecordingPeriodGhgCategoryIdentity;
  activity: IActivity;
  disabled: boolean;
  onStateChange: Dispatch<IActivityDetailsDialogEditTabState>;
  onClose: VoidFunction;
  onSave: IActivityDetailsDialogSourcesAndValuesOnSave;
  statusChangeEvents: IGhgActivityStatusUpdateEvents;
  onToggleCommentsAndAuditLogSideSection: VoidFunction;
}

export const ActivityDetailsDialogSourcesAndValues: FC<
  IActivityDetailsDialogSourcesAndValuesProps
> = ({
  ghgCategoryIdentity,
  activity,
  disabled: parentDisabled,
  onStateChange,
  onClose,
  onSave,
  statusChangeEvents,
  onToggleCommentsAndAuditLogSideSection,
}) => {
  const { t } = useTranslation("ghg_activities");

  const [tableMode, setTableMode] = useState<IGhgActivityValuesTableMode>(
    IGhgActivityValuesTableMode.Read,
  );
  const onStartEditingTable = useCallback(() => setTableMode(IGhgActivityValuesTableMode.Edit), []);

  const activityIdentity: IGhgActivityIdentity = useMemo(
    () => ({
      ...ghgCategoryIdentity,
      activityId: activity.id,
    }),
    [ghgCategoryIdentity, activity],
  );
  const activityValuesQuery = useActivityValuesQuery(activityIdentity);
  const activityValues = useMemo(
    () => activityValuesQuery.data?.activityValues ?? [],
    [activityValuesQuery.data?.activityValues],
  );
  const disabled = parentDisabled || activityValuesQuery.isFetching;

  const formResult = useForm<IActivityDetailsDialogSourcesAndValuesFormData>({
    defaultValues: getFormData(activity),
  });
  const {
    formState: { isDirty },
    reset,
    handleSubmit,
    getFieldState,
  } = formResult;

  useEffect(() => {
    // This is to ensure that the form is not reset if Tanstack query decides to refetch in the background
    // (for example when switching between tabs etc.)
    if (!isDirty) {
      reset(getFormData(activity, activityValues));
    }
  }, [activity, activityValues, isDirty, reset]);

  useEffect(() => {
    onStateChange({
      hasUnsavedChanges: isDirty,
    });
  }, [isDirty, onStateChange]);

  const handleSave = useCallback(
    (result: IActivityDetailsDialogSourcesAndValuesFormData) => {
      // Check whether sources should be updated
      const wereSourcesUpdated = getFieldState("sourceIds").isDirty;
      const newSources = wereSourcesUpdated ? result.sourceIds : null;

      const wereValuesUpdated =
        // Check if rows were removed
        // (has to be done since isDirty field state does not work for removed rows)
        result.activityValues.length !== activityValues.length ||
        // Check if any values were changed
        getFieldState("activityValues").isDirty;
      let newValues: IBaseActivityValueData[] = [];
      let updatedValues: [string, IBaseActivityValueData][] = [];
      let removedValuesIds: string[] = [];

      // Only update values if they have changes
      if (wereValuesUpdated) {
        // Find newly added values
        newValues = result.activityValues
          .filter((value) => value.id === null)
          .map(GhgActivityValuesUtilities.convertGhgActivityInternalFormValueToValue);
        // Find values that were updated
        updatedValues = result.activityValues
          .filter((value) => {
            if (value.id === null) {
              return false;
            }
            const existingValue = activityValues.find((v) => v.id === value.id);
            return (
              existingValue &&
              !GhgActivityValuesUtilities.areValuesEqual(
                value,
                GhgActivityValuesUtilities.convertGhgActivityValueToInternalFormValue(
                  existingValue,
                ),
              )
            );
          })
          .map((value) => [
            value.id as string,
            GhgActivityValuesUtilities.convertGhgActivityInternalFormValueToValue(value),
          ]);
        // Find removed values
        removedValuesIds = activityValues
          .filter(
            (existingValue) =>
              !result.activityValues.find((newValue) => newValue.id === existingValue.id),
          )
          .map((value) => value.id);
      }

      // Save Changes
      onSave(newSources, newValues, updatedValues, removedValuesIds);
    },
    [activityValues, getFieldState, onSave],
  );

  // Activity Upload

  const {
    isOpen: isActivityUploadDialogOpen,
    openDialog: openActivityUploadDialog,
    closeDialog: closeActivityUploadDialog,
  } = useDialogStateWithoutData();

  const valuesTableFormRef = useRef<IGhgActivityValuesTableFormRef>(null);

  const handleCloseActivityUploadDialog: IActivityUploadDialogCloseCallback = useCallback(
    (importedData) => {
      if (importedData) {
        onStartEditingTable();
        valuesTableFormRef.current?.append(
          importedData.map(GhgActivityValuesUtilities.createFormValueDataFromImportData),
        );
      }
      // Always Close dialog
      closeActivityUploadDialog();
    },
    [closeActivityUploadDialog, onStartEditingTable],
  );

  // Render

  const isUploadAvailable = activity.emissionFactorIds.length > 0;

  return (
    <>
      {/* Activity Upload Dialog */}
      <ActivityUploadDialog
        open={isActivityUploadDialogOpen}
        activity={activity}
        onClose={handleCloseActivityUploadDialog}
        activityIdentity={activityIdentity}
      />

      {/* Form */}
      <FormProvider {...formResult}>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2.5} pt={1}>
            <Box display="flex" flexDirection="column" gap={1} pt={1}>
              {/* Title & Status Row */}
              <Box display="flex" justifyContent="space-between">
                <Typography variant="subtitle1">{activity.title}</Typography>
                <GhgActivityStatusIndicatorWithMenu
                  activity={activity}
                  disabled={disabled || isDirty}
                  {...statusChangeEvents}
                />
              </Box>
              {/* Emission Factors */}
              <Box display="flex" flexWrap="wrap" gap={1}>
                {activity.emissionFactorIds.map((emissionFactorId) => (
                  <EmissionFactorChip
                    key={emissionFactorId}
                    organizationId={ghgCategoryIdentity.organizationId}
                    emissionFactorId={emissionFactorId}
                  />
                ))}
              </Box>
            </Box>
            {/* Buttons */}
            <Box display="flex" gap={2}>
              <CommentsButtonWithQuery
                organizationId={ghgCategoryIdentity.organizationId}
                relations={{
                  entity: "activity",
                  activityId: activity.id,
                }}
                onClick={onToggleCommentsAndAuditLogSideSection}
              />
              <AuditLogsButton organizationId={ghgCategoryIdentity.organizationId} />
            </Box>
            {/* Sources */}
            <Box>
              <LinkedSourcesDialogInput
                organizationId={ghgCategoryIdentity.organizationId}
                disabled={disabled}
              />
            </Box>
            {/* Divider */}
            <Divider />
            {/* Values */}
            <Typography variant="subtitle1">{t("heading_collect_data")}</Typography>
            {activity.emissionFactorIds.length < 1 && (
              <Box py={1}>
                <Alert variant="standard" severity="warning">
                  {t("no_emission_factors_available", { ns: "ghg_activity_values" })}
                </Alert>
              </Box>
            )}
            <GhgActivityValuesTableForm
              ref={valuesTableFormRef}
              mode={tableMode}
              onStartEditing={onStartEditingTable}
              selectableEmissionFactorIds={activity.emissionFactorIds}
              loading={activityValuesQuery.isPending}
              disabled={disabled}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {/*Upload button  */}
          <Tooltip
            placement="right"
            title={
              !isUploadAvailable
                ? t("no_emission_factors_available", { ns: "ghg_activity_values" })
                : null
            }
          >
            <span>
              <DialogUploadButton
                onClick={() => openActivityUploadDialog()}
                startIcon={<UploadIcon />}
                disabled={!isUploadAvailable || disabled}
              >
                {t("buttons.upload_values")}
              </DialogUploadButton>
            </span>
          </Tooltip>
          {/* Spacer to align upload button left */}
          <Box flex={1} />
          <DialogCancelButton onClick={() => onClose()} disabled={disabled}>
            {t("cancel", { ns: "buttons" })}
          </DialogCancelButton>
          <DialogSaveButton onClick={handleSubmit(handleSave)} disabled={disabled || !isDirty}>
            {t("save", { ns: "buttons" })}
          </DialogSaveButton>
        </DialogActions>
      </FormProvider>
    </>
  );
};
