export class NumberUtilities {
  /**
   * Floor a number to a specific precision.
   * For example floorToPrecision(1.005, 2) will return 1.00.
   * @param value
   * @param precision
   */
  public static floorToPrecision(value: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return +(Math.floor(value * factor) / factor).toFixed(precision);
  }

  /**
   * Extract a number from a string with a specific precision. Mostly for extracting numbers from a string. (user input)
   * For example extractNumberFromStringWithPrecision("1.005", 2) will return "1.00".
   * @param value
   * @param precision
   * @returns The extracted part of the string or null if no number was found in the input string.
   */
  public static extractNumberFromStringWithPrecision(value: string, precision = 0) {
    const regex =
      precision === 0
        ? // Normal Regex for only matching numbers (without the decimal point and decimal places)
          /^[+-]?\d*/gm
        : // Regex for matching numbers with a decimal point and a specific number of decimal places
          new RegExp(`^[+-]?\\d*([.,]\\d{0,${precision}})?`, "gm");
    return value.match(regex)?.[0] ?? null;
  }

  /**
   * Get a number within a specific range.
   * @param value The value to check
   * @param min The minimum value
   * @param max The maximum value
   * @returns The value if it is within the range, otherwise the min or max (respectively whether the number is smaller or larger than the permitted range).
   */
  public static getNumberWithingRange(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(max, value));
  }

  /**
   * This method moves the decimal place of a number by the specified count, while also accounting
   * for rounding errors (so that 24/100 does not turn into 0.239999)
   * Example moveDecimalPlaceRounded(24, 2) => 0.24
   * @param value The value to be moved
   * @param moveBy By how many decimal places the result should be moved
   */
  public static moveDecimalPlaceRounded(value: number | string, moveBy: number) {
    return +(+value / Math.pow(10, moveBy)).toFixed(moveBy);
  }

  /**
   * Sum up all the values passed as arguments.
   * @param values
   */
  public static sum(...values: (number | number[])[]): number {
    return values.flat().reduce((acc, val) => acc + val, 0);
  }

  /**
   * Calculates the product of all numbers in an array.
   * @param numbers An array of numbers to multiply together.
   * @returns The product of all numbers. Returns 1 for empty arrays.
   */
  public static product(...numbers: (number | number[])[]): number {
    return numbers.flat().reduce((product, currentValue) => product * currentValue, 1);
  }
}
