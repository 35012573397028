import { Icon } from "@iconify/react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { ICountryCodeAlpha2Enum, IDataEntryObjectData } from "@netcero/netcero-core-api-client";
import { FC, useEffect, useMemo } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CountrySelectComponent } from "../common/components/country-select.component";
import { OrganizationLineIcon } from "../common/constants/iconify-icon.constants";
import { DeleteIcon } from "../common/constants/tabler-icon.constants";
import { ErrorTextComponent } from "../common/components/error-text.component";
import {
  DataEntryObjectsCommonUtilities,
  DataEntryObjectVerification,
  NumberUtilities,
} from "@netcero/netcero-common";
import { DataEntryObjectsAppUtilities } from "./data-entry-objects-app.utilities";
import { DialogCancelButton, DialogSaveButton } from "../common/dialogs/dialog-button.components";
import {
  DEFAULT_DATA_ENTRY_OBJECT_TYPE,
  ORDERED_DATA_ENTRY_OBJECT_TYPES,
} from "./data-entry-objects.constants";

interface IDataEntryObjectEditDialogProps {
  open: boolean;
  loading?: boolean;
  error?: Error;
  disabled?: boolean;
  dataEntryObject?: IDataEntryObjectData;
  onClose: (dataEntryObject: IDataEntryObjectData | null) => void;
  onChangeParent?: () => void;
  onDelete?: () => void;
}

const getDefaultValuesForDialog = (
  dataEntryObject: IDataEntryObjectData | undefined,
): Omit<IDataEntryObjectData, "id"> => {
  return {
    name: dataEntryObject?.name ?? "",
    description: dataEntryObject?.description ?? "",
    objectType: dataEntryObject?.objectType ?? DEFAULT_DATA_ENTRY_OBJECT_TYPE,
    inheritsValuesFrom: dataEntryObject?.inheritsValuesFrom ?? [],
    // make sure that undefined can only be submitted to the API, but not received from the API
    operationalControl: dataEntryObject?.operationalControl ?? true,
    financiallyConsolidated: dataEntryObject?.financiallyConsolidated ?? true,
    partOfValueChain: dataEntryObject?.partOfValueChain ?? false,
    country: dataEntryObject?.country ?? ICountryCodeAlpha2Enum.At,
    shareHeldByParent:
      dataEntryObject?.shareHeldByParent ??
      DataEntryObjectsCommonUtilities.DefaultShareHeldByParent,
  };
};

const cleanValuesBeforeSubmit = (values: IDataEntryObjectData): IDataEntryObjectData => {
  const internalValues = {
    ...values,
    description: values.description?.trim() || undefined,
    shareHeldByParent: DataEntryObjectsCommonUtilities.doesTypeSupportShareHeldByParent(
      values.objectType,
    )
      ? values.shareHeldByParent
      : DataEntryObjectsCommonUtilities.DefaultShareHeldByParent,
  };
  if (!DataEntryObjectVerification.doesObjectTypeAllowBooleanChanges(internalValues.objectType)) {
    return {
      ...internalValues,
      partOfValueChain: undefined,
      operationalControl: undefined,
      financiallyConsolidated: undefined,
    };
  }
  if (!DataEntryObjectVerification.canPartOfValueChainBeSet(internalValues.operationalControl)) {
    return { ...internalValues, partOfValueChain: undefined };
  }
  return internalValues;
};

const useIsDirty = (
  dirtyFields: UseFormReturn<IDataEntryObjectData>["formState"]["dirtyFields"],
  operationalControlInitialValue: boolean = false,
) => {
  return useMemo(() => {
    const clone = { ...dirtyFields };
    if (
      !DataEntryObjectVerification.canPartOfValueChainBeSet(clone.operationalControl) &&
      !DataEntryObjectVerification.canPartOfValueChainBeSet(operationalControlInitialValue)
    ) {
      delete clone.partOfValueChain;
    }
    return Object.keys(clone).length > 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dirtyFields.name,
    dirtyFields.description,
    dirtyFields.objectType,
    dirtyFields.operationalControl,
    dirtyFields.partOfValueChain,
    dirtyFields.financiallyConsolidated,
    dirtyFields.country,
    dirtyFields.shareHeldByParent,
    operationalControlInitialValue,
  ]);
};

/**
 * Dialog for editing and creating data entry objects.
 * UI Elements will be shown based on handlers passed in props.
 */
export const DataEntryObjectEditDialog: FC<IDataEntryObjectEditDialogProps> = ({
  open,
  loading,
  error,
  disabled = false,
  dataEntryObject,
  onClose,
  onChangeParent,
  onDelete,
}) => {
  const { t } = useTranslation("data_entry_object_edit_dialog");

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors, defaultValues },
    watch,
    setValue,
  } = useForm<IDataEntryObjectData>({
    defaultValues: getDefaultValuesForDialog(dataEntryObject),
  });

  const isDirty = useIsDirty(dirtyFields, defaultValues?.operationalControl);

  // Sync form with data entry object
  useEffect(() => {
    if (open) {
      reset(getDefaultValuesForDialog(dataEntryObject));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, dataEntryObject]);

  const handleDialogClose = () => {
    if (!isDirty) {
      onClose(null);
    }
  };

  const handleUpdateDataEntryObject = (values: IDataEntryObjectData) => {
    onClose(cleanValuesBeforeSubmit(values));
  };

  const handleCancel = () => {
    onClose(null);
  };

  const currentObjectType = watch("objectType");
  const currentOperationalControl = watch("operationalControl");

  const supportsShare = useMemo(
    () => DataEntryObjectsCommonUtilities.doesTypeSupportShareHeldByParent(currentObjectType),
    [currentObjectType],
  );

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="md" fullWidth>
      <DialogTitle display="flex" alignItems="center">
        {dataEntryObject !== undefined
          ? t("dialog_title", { dataEntryObjectName: dataEntryObject?.name })
          : t("dialog_title_new")}
        <Box display="flex" alignItems="center" ml="auto">
          {onChangeParent !== undefined && (
            <Tooltip title={t("button_change_parent")}>
              <IconButton size="small" onClick={onChangeParent} disabled={disabled}>
                <OrganizationLineIcon />
              </IconButton>
            </Tooltip>
          )}
          {onDelete !== undefined && (
            <Tooltip title={t("button_delete_tooltip")}>
              <IconButton size="small" onClick={onDelete} disabled={disabled}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent sx={{ pb: 2 }}>
        {error && (
          <Box mb={1}>
            <ErrorTextComponent error={error} />
          </Box>
        )}

        {/* Edit basic properties */}
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={2}
          pt={1}
          onSubmit={handleSubmit(handleUpdateDataEntryObject)}
        >
          {/* Name */}
          <Controller
            control={control}
            name="name"
            rules={{
              required: t("name_required"),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("name_input_label")}
                required
                error={errors?.name !== undefined}
                helperText={errors?.name?.message}
                fullWidth
                disabled={disabled}
              />
            )}
          />
          <Box display="flex" gap={2}>
            {/* Country */}
            <FormControl sx={{ flex: 1 }}>
              <Controller
                control={control}
                name="country"
                render={({ field }) => (
                  <CountrySelectComponent
                    type="not-nullable"
                    value={field.value}
                    onValueChange={(value) => {
                      field.onChange(value);
                    }}
                    label={t("country_select_label")}
                  />
                )}
              />
            </FormControl>

            {/* Type */}
            <FormControl sx={{ flex: 1 }}>
              <InputLabel>{t("object_type_select_label")}</InputLabel>
              <Controller
                control={control}
                name="objectType"
                render={({ field }) => (
                  <Select
                    label={t("object_type_select_label")}
                    {...field}
                    disabled={disabled}
                    renderValue={(selected) => (
                      <Box display="flex" alignItems="center">
                        <Icon icon={DataEntryObjectsAppUtilities.getIconForObjectType(selected)} />
                        <Box ml={2}>{t(`object_type_value_${selected}`)}</Box>
                      </Box>
                    )}
                  >
                    {ORDERED_DATA_ENTRY_OBJECT_TYPES.map((objectType) => (
                      <MenuItem key={objectType} value={objectType}>
                        <Icon
                          icon={DataEntryObjectsAppUtilities.getIconForObjectType(objectType)}
                        />
                        <Box ml={2}>{t(`object_type_value_${objectType}`)}</Box>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            {/* Share */}
            {supportsShare && (
              <Controller
                control={control}
                name="shareHeldByParent"
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    value={field.value === null ? "" : +(field.value * 100).toFixed()}
                    onChange={(evt) => {
                      const textToConsider = NumberUtilities.extractNumberFromStringWithPrecision(
                        evt.currentTarget.value,
                      );
                      const result = textToConsider
                        ? NumberUtilities.moveDecimalPlaceRounded(textToConsider, 2)
                        : null;
                      field.onChange(result);
                    }}
                    onBlur={() => {
                      // Set field to 100% when blurred and empty
                      if (field.value === null) {
                        field.onChange(1);
                        return;
                      }
                      field.onChange(NumberUtilities.getNumberWithingRange(field.value, 0, 1));
                    }}
                    label={t("share_held_by_parent_label")}
                    disabled={disabled}
                    inputProps={{ min: 0, max: 100 }}
                    sx={{ flex: 1 }}
                    InputProps={{
                      endAdornment: <Box mr={1}>%</Box>,
                    }}
                  />
                )}
              />
            )}
          </Box>

          {/* Operational Control & Part of Value Chain Checkboxes*/}
          {DataEntryObjectVerification.doesObjectTypeAllowBooleanChanges(currentObjectType) && (
            <Box display="flex" gap={2}>
              {/* Financial Consolidation */}
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(evt, checked) => {
                          field.onChange(checked);
                        }}
                        disabled={disabled}
                      />
                    }
                    label={t("financially_consolidated_label")}
                    disabled={disabled}
                  />
                )}
                name="financiallyConsolidated"
                control={control}
              />
              {/* Operational Control */}
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(evt, checked) => {
                          field.onChange(checked);
                          if (checked) {
                            // make sure that setting the value causes the field to be marked as dirty!
                            // (isDirty hook expects any fields that have changed from the beginning to be dirty)
                            setValue("partOfValueChain", false, { shouldDirty: true });
                          }
                        }}
                        disabled={disabled}
                      />
                    }
                    label={t("operational_control_label")}
                    disabled={disabled}
                  />
                )}
                name="operationalControl"
                control={control}
              />
              {/* Part of Value Chain */}
              {DataEntryObjectVerification.canPartOfValueChainBeSet(currentOperationalControl) && (
                <Controller
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(evt, checked) => field.onChange(checked)}
                          disabled={disabled}
                        />
                      }
                      label={t("part_of_value_chain_label")}
                      disabled={disabled}
                    />
                  )}
                  name="partOfValueChain"
                  control={control}
                />
              )}
            </Box>
          )}

          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextField
                {...field}
                label={t("description_input_label")}
                fullWidth
                multiline
                minRows={3}
                disabled={disabled}
              />
            )}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <DialogCancelButton onClick={handleCancel} disabled={disabled}>
          {t("button_cancel")}
        </DialogCancelButton>
        <DialogSaveButton
          onClick={handleSubmit(handleUpdateDataEntryObject)}
          disabled={disabled || (!isDirty && dataEntryObject !== undefined)}
        >
          {dataEntryObject !== undefined ? t("button_save") : t("button_create")}
        </DialogSaveButton>
      </DialogActions>
    </Dialog>
  );
};
