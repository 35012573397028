import { IActivityStatus, IContentLanguage } from "@netcero/netcero-core-api-client";
import {
  ActivityStatusStateTransitionDefinitions,
  ActivityStatusStateTransitions,
} from "./activities.public-types";
import { StateMachineUtilities } from "../state-machine";

export class ActivityUtilities {
  private static readonly TEMPLATE_NAMES_PER_LANGUAGE: Record<
    IContentLanguage,
    (title: string) => string
  > = {
    // TODO: this is "reserved" for future use in case the name ever changes
    [IContentLanguage.En]: (title: string) => `NetCero_GHG-${title}_Upload_Template.xlsx`,
    [IContentLanguage.De]: (title: string) => `NetCero_GHG-${title}_Upload_Template.xlsx`,
  };

  public static getStateTransition(
    from: IActivityStatus,
    to: IActivityStatus,
  ): ActivityStatusStateTransitions | null {
    return StateMachineUtilities.getStateTransition(
      from,
      to,
      ActivityStatusStateTransitionDefinitions,
    );
  }

  public static getActivityTemplateForUploadFileName(title: string, language: IContentLanguage) {
    const pathFriendlyName = title.replace(" ", "_");
    return ActivityUtilities.TEMPLATE_NAMES_PER_LANGUAGE[language](pathFriendlyName);
  }
}
