import { Box, Slider, SliderProps } from "@mui/material";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ORDERED_DMA_PROBABILITY_SLIDER_VALUES } from "./dma.constants";
import {
  IDMAConfigurationProbabilityOfOccurrenceSteps,
  IIROProbabilityPredefinedValue,
} from "@netcero/netcero-core-api-client";
import { DMAUtilities } from "@netcero/netcero-dma";
import { FormatUtilities } from "../../common/utilities/format.utilities";

// Converts an enum value to the number equivalent
const convertValueToNumber = (value: IIROProbabilityPredefinedValue) =>
  ORDERED_DMA_PROBABILITY_SLIDER_VALUES.indexOf(value);

// Converts a number to the enum value
const convertNumberToValue = (number: number) => ORDERED_DMA_PROBABILITY_SLIDER_VALUES[number];

// The single marks of the slider
const MARKS = ORDERED_DMA_PROBABILITY_SLIDER_VALUES.map((value) => ({
  value: convertValueToNumber(value),
}));

// Returns the translation key for the value
const getValueTranslationKey = (value: IIROProbabilityPredefinedValue) => `slider_value_${value}`;

interface IDMAProbabilitySliderProps extends Omit<SliderProps, "value" | "onChange"> {
  steps?: IDMAConfigurationProbabilityOfOccurrenceSteps;
  value?: IIROProbabilityPredefinedValue;
  onChange: (newValue: IIROProbabilityPredefinedValue) => void;
}

export const DMAProbabilitySlider: FC<IDMAProbabilitySliderProps> = ({
  steps,
  value,
  onChange,
  ...props
}) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");

  const getValueNameTranslationkey = useCallback(
    (value: number) => {
      const stepLabel = t(getValueTranslationKey(convertNumberToValue(value)));
      const stepLabelValue = steps
        ? DMAUtilities.getProbabilitySliderValue(convertNumberToValue(value), steps)
        : null;

      return `${stepLabel} ${
        stepLabelValue ? `(${FormatUtilities.formatFractionAsPercentage(stepLabelValue, 0)})` : ""
      }`;
    },
    [steps, t],
  );

  return (
    <Box flex={1} display="flex" alignItems="center" px={2}>
      <Slider
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => getValueNameTranslationkey(value)}
        min={convertValueToNumber(ORDERED_DMA_PROBABILITY_SLIDER_VALUES[0])}
        max={convertValueToNumber(
          ORDERED_DMA_PROBABILITY_SLIDER_VALUES[ORDERED_DMA_PROBABILITY_SLIDER_VALUES.length - 1],
        )}
        marks={MARKS}
        step={null}
        value={value === undefined ? undefined : convertValueToNumber(value)}
        onChange={(_, newValue) => onChange(convertNumberToValue(newValue as number))}
        {...props}
      />
    </Box>
  );
};
