import { IESRSTopic } from "@netcero/netcero-core-api-client";
import { Chip, Tooltip, TooltipProps } from "@mui/material";
import { FC } from "react";
import { useRenderESRSTopicName } from "../hooks/render-esrs-topic-name.hook";

interface IEsrsTopicBadgeProps {
  topic?: IESRSTopic;
  tooltipPlacement?: TooltipProps["placement"];
}

export const EsrsTopicBadge: FC<IEsrsTopicBadgeProps> = ({ topic, tooltipPlacement }) => {
  const renderTopicName = useRenderESRSTopicName();

  if (!topic) {
    return null;
  }

  return (
    <Tooltip title={renderTopicName(topic)} placement={tooltipPlacement}>
      <Chip variant="filled" size="small" label={topic.topic} />
    </Tooltip>
  );
};
