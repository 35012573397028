import { IRecordingPeriodIdentity, OrganizationRole } from "@netcero/netcero-common";
import { useMemo } from "react";
import { useAuthenticatedUserOrganizationRole } from "../../user/hooks/authenticated-user-org-role.hook";
import { useHasObjectAdminDataEntryObjectAccess } from "./has-object-admin-data-entry-object-access.hook";
import { useValidatedRouterParameters } from "../../common/hooks/validated-router-parameters.hook";

/**
 * Hook to check if the user has access to a specific data entry object within a recording period.
 * Will consider the user's role to determine access.
 * (Organization Admins always have access, Object Admins only to assigned objects and otherwise always no access.)
 * @param organizationId
 * @param recordingPeriodId
 */
export const useHasUserDataEntryObjectAccess = ({
  organizationId,
  recordingPeriodId,
}: IRecordingPeriodIdentity) => {
  const userRole = useAuthenticatedUserOrganizationRole();

  const checkRawUserDataEntryObjectAccess = useHasObjectAdminDataEntryObjectAccess({
    organizationId,
    recordingPeriodId,
  });

  return useMemo(() => {
    return (...dataEntryObjectIds: string[]) => {
      switch (userRole) {
        case OrganizationRole.ORGANIZATION_ADMIN:
          // Organization admins have access to all data entry objects
          return true;
        case OrganizationRole.OBJECT_ADMIN:
          return dataEntryObjectIds.every((deoId) => checkRawUserDataEntryObjectAccess(deoId));
        default:
          return false;
      }
    };
  }, [checkRawUserDataEntryObjectAccess, userRole]);
};

const REQUIRED_ROUTER_PARAMS = ["organizationId", "recordingPeriodId"] as const;

/**
 * Wrapper for the useHasUserDeoAccess hook that retrieves the organization and recording period ID from the router.
 */
export const useHasUserDeoAccessFromRouter = () => {
  const params = useValidatedRouterParameters(REQUIRED_ROUTER_PARAMS);
  if (!params) {
    throw new Error("Organization and recording period ID are required");
  }
  return useHasUserDataEntryObjectAccess(params);
};
