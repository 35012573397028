import { Box, LinearProgress, Typography } from "@mui/material";
import {
  IDataEntryObject,
  IDataEntryObjectDMAConfiguration,
  IDMACategory,
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IDMAConfiguration,
  IESRSTopic,
  IOptOutOfDmaCategoryRequest,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "../common/dialogs/variants/error.dialog";
import { DeleteImpactOrEffectConfirmDialog } from "./common/delete-impact-or-effect-confirm.dialog";
import { EsrsTopicOptOutDialog } from "./common/esrs-topic-opt-out.dialog";
import { ExecuteDMAConfirmDialog } from "./common/execute-dma-confirm.dialog";
import {
  DMACategoryDialogs,
  ICategoryCompleteActionMetadata,
} from "./dialogs/dma-categories/dma-category-editing-dialogs.component";
import { useDMACategoryEditingDialogsState } from "./dialogs/dma-categories/dma-category-editing-dialogs.hook";
import { DMACategoryFeedbackDialog } from "./dialogs/dma-categories/dma-category-feedback.dialog";
import { DMACategoryOptBackInConfirmDialog } from "./dialogs/dma-categories/dma-category-opt-back-in-confirm.dialog";
import { DMACategoryOptOutDialog } from "./dialogs/dma-categories/dma-category-opt-out.dialog";
import { DMACategoryUtilities } from "./dialogs/dma-categories/dma-category.utilities";
import { DMAConfiguration } from "./dma-configuration.component";
import { ESRSTopicComponent } from "./esrs-topic.component";
import {
  ESRSTopicsStepperFloatingActionBarComponent,
  ESRSTopicsStepperFloatingActionBarComponentSpacer,
} from "./esrs-topics-stepper-floating-action-bar.component";
import { DMAFinancialEffectEditDialog } from "./financial-effects/dma-financial-effect-edit.dialog";
import { DMAMaterialImpactEditDialog } from "./material-impacts/dma-material-impact-edit.dialog";
import { useOptOutDMACategoryMutation } from "./mutations/dma-category.mutations";
import {
  useExecuteDMAMutation,
  useInitDMAForTopicMutation,
  useOptOutOfESRSTopicMutation,
} from "./mutations/dma.mutations";
import {
  DataEntryObjectDmaConfigurationUtilities,
  DMA_CATEGORY_QUERY_PARAM,
  ESRS_TOPIC_QUERY_PARAM,
  FILTER_BY_USER_QUERY_PARAM,
  IRecordingPeriodIdentity,
  ROLE_ACCESS,
} from "@netcero/netcero-common";
import { DMAUtilities } from "./utilities/dma.utilities";
import { useSyncCategoryToLS } from "./hooks/sync-category-to-ls.hook";
import { useRenderESRSTopicName } from "./hooks/render-esrs-topic-name.hook";
import { MoveImpactOrEffectConfirmDialog } from "./dialogs/iros/move-impact-or-effect-confirm.dialog";
import { useSnackbar } from "notistack";
import { DEFAULT_SNACKBAR_CONFIGURATIONS } from "../app-snackbar/app-snackbar.hook";
import { TOP_BAR_HEIGHT } from "../common/components/top-bar.component";
import { CONTENT_Y_PADDING } from "../common/components/app-shell-wrapper.component";
import { FeatureFlag } from "../common/constants/feature-flags.constants";
import { useIsFeatureFlagEnabled } from "../common/hooks/is-feature-flag-enabled.hook";
import { AppInternalNavigationUtilities } from "../common/utilities/app-internal-navigation.utilities";
import { EsrsTopicsStepperToc } from "./esrs-topics-stepper-toc.component";
import { OnlyShowToUsersWithRole } from "../authentication/components/only-show-to-users-with-role.component";
import { useDoesUserHaveRole } from "../authentication/hooks/does-user-have-role.hook";
import { useIroDialogHandlers } from "./hooks/iro-dialog-handlers.hook";

export interface IShowGroupsState {
  showMaterialImpacts: boolean;
  showFinancialEffects: boolean;
}

interface ITopicOptOutDialogState {
  open: boolean;
  esrsTopic: IESRSTopic | null;
}

interface ICategoryOptInOptOutDialogState {
  open: boolean;
  esrsTopic: IESRSTopic | null;
  category: IDMACategoryWithEffectsAndChildren | null;
}

interface IStakeholderFeedbackDialogState {
  open: boolean;
  esrsTopic: IESRSTopic | null;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
}

interface IDMACategoriesStepperComponentProps extends IRecordingPeriodIdentity {
  dmaConfiguration: IDMAConfiguration;
  dmaDeoConfiguration: IDataEntryObjectDMAConfiguration;
  esrsTopics: IESRSTopic[];
  dataEntryObject: IDataEntryObject;
}

export const ESRSTopicsStepperComponent: FC<IDMACategoriesStepperComponentProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObject,
  dmaConfiguration,
  dmaDeoConfiguration,
  esrsTopics,
}) => {
  // TODO: Possibly refactor financial effect and material impact modals into a single component (like with the dma category dialogs)

  const { t } = useTranslation("double_materiality_assessment_stepper_component");
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  // General Preparation

  const dataEntryObjectId = useMemo(() => dataEntryObject.id, [dataEntryObject.id]);

  // ESRS Topic and Category Logic
  const { getStoredCategoryId, saveCategoryId, clearSettingsForDataEntryObject } =
    useSyncCategoryToLS();

  const viewedESRSTopicId = useMemo(
    () => searchParams.get(ESRS_TOPIC_QUERY_PARAM) ?? null,
    [searchParams],
  );

  const viewedDMACategoryId = useMemo(
    () => searchParams.get(DMA_CATEGORY_QUERY_PARAM) ?? null,
    [searchParams],
  );

  const setViewSettings = useCallback(
    (esrsTopicId: string | null, dmaCategoryId: string | null) => {
      // Skip if no changes
      if (esrsTopicId === viewedESRSTopicId && dmaCategoryId === viewedDMACategoryId) {
        return;
      }

      // Update Query Params (and ui state)
      setSearchParams((params) => {
        if (esrsTopicId) {
          params.set(ESRS_TOPIC_QUERY_PARAM, esrsTopicId);
        } else {
          params.delete(ESRS_TOPIC_QUERY_PARAM);
        }

        if (dmaCategoryId) {
          params.set(DMA_CATEGORY_QUERY_PARAM, dmaCategoryId);
        } else {
          params.delete(DMA_CATEGORY_QUERY_PARAM);
        }

        return params;
      });

      if (esrsTopicId !== null) {
        saveCategoryId(dataEntryObjectId, { esrsTopicId, dmaCategoryId });
      }
      // Scroll to tops
      // window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    [dataEntryObjectId, saveCategoryId, setSearchParams, viewedDMACategoryId, viewedESRSTopicId],
  );

  // Initially set the topic if configuration is not available and no topic is being viewed
  // (mainly for non organization admin users)
  const hasAccessToDmaConfiguration = useDoesUserHaveRole(
    ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CONFIGURATION,
  );
  useEffect(() => {
    // Skip if user has access to DMA
    if (hasAccessToDmaConfiguration) {
      return;
    }
    // Skip if already viewing a topic
    if (viewedESRSTopicId !== null) {
      return;
    }
    // Get ESRS Topic to set
    const recordedTopic = esrsTopics[0]?.recordedESRSTopic;
    if (!recordedTopic) {
      return;
    }
    setViewSettings(recordedTopic.esrsTopicId, null);
  }, [esrsTopics, hasAccessToDmaConfiguration, setViewSettings, viewedESRSTopicId]);

  // be sure to return to previously selected category
  useEffect(() => {
    // Only execute initially
    if (viewedESRSTopicId !== null) {
      return;
    }
    // Use Value from Local Storage
    const current = getStoredCategoryId(dataEntryObjectId);
    if (current !== null) {
      setViewSettings(current.esrsTopicId, current.dmaCategoryId);
    }
    // Should only run on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Available UserIds for filters

  const filterableUserIds = useMemo(() => {
    return DMAUtilities.getAllAssignedAndResponsibleUsersIdsFromTopics(esrsTopics);
  }, [esrsTopics]);

  const filterByUserIds = useMemo(
    () => searchParams.getAll(FILTER_BY_USER_QUERY_PARAM),
    [searchParams],
  );
  const setFilterByUserIds = useCallback(
    (userIds: string[]) => {
      if (
        userIds.length !== filterByUserIds.length ||
        userIds.every((id) => filterByUserIds.includes(id))
      ) {
        setSearchParams((params) => {
          // Clear current Value
          params.delete(FILTER_BY_USER_QUERY_PARAM);
          // Add all currently set values
          userIds.forEach((id) => params.append(FILTER_BY_USER_QUERY_PARAM, id));
          // Return
          return params;
        });
      }
    },
    [filterByUserIds, setSearchParams],
  );

  useEffect(() => {
    if (!filterByUserIds.every((id) => filterableUserIds.includes(id))) {
      setFilterByUserIds(filterByUserIds.filter((id) => filterableUserIds.includes(id)));
    }
  }, [filterByUserIds, filterableUserIds, setFilterByUserIds]);

  const shownTopics = useMemo(() => {
    return filterByUserIds.length > 0
      ? DMAUtilities.filterTopicsByUserIds(esrsTopics, filterByUserIds)
      : esrsTopics;
  }, [filterByUserIds, esrsTopics]);

  // Selected ESRS Topics State

  const [showGroups, setShowGroups] = useState<IShowGroupsState>({
    showMaterialImpacts: true,
    showFinancialEffects: true,
  });

  const selectedESRSTopic = useMemo(
    () => (viewedESRSTopicId ? shownTopics.find((t) => t.id === viewedESRSTopicId) ?? null : null),
    [shownTopics, viewedESRSTopicId],
  );

  const selectedDMACategory = useMemo(() => {
    if (selectedESRSTopic && viewedDMACategoryId) {
      return (
        selectedESRSTopic.recordedESRSTopic?.dmaCategories.find(
          (category) => category.id === viewedDMACategoryId,
        ) ?? null
      );
    }

    return null;
  }, [viewedDMACategoryId, selectedESRSTopic]);

  // State Cleanup
  useEffect(() => {
    // Reset filters if unknown topic
    if (selectedESRSTopic === null && viewedESRSTopicId !== null) {
      setViewSettings(shownTopics[0]?.id ?? null, null);
      return;
    }

    // Reset category if unknown category
    if (selectedDMACategory === null && viewedDMACategoryId !== null) {
      setViewSettings(selectedESRSTopic?.id ?? null, null);
    }

    // Set first child if no category is selected, but a topic is and has child categories
    if (
      selectedESRSTopic &&
      !selectedDMACategory &&
      selectedESRSTopic.recordedESRSTopic &&
      selectedESRSTopic.recordedESRSTopic.dmaCategories.length > 0
    ) {
      setViewSettings(
        selectedESRSTopic.id,
        selectedESRSTopic.recordedESRSTopic.dmaCategories[0].id,
      );
    }
  }, [
    selectedESRSTopic,
    viewedESRSTopicId,
    shownTopics,
    setViewSettings,
    selectedDMACategory,
    viewedDMACategoryId,
  ]);

  const selectedStepperIndex = useMemo(() => {
    return shownTopics.findIndex((t) => t.id === selectedESRSTopic?.id) + 1;
  }, [shownTopics, selectedESRSTopic]);

  const handleChangeStepperIndex = useCallback(
    (newIndex: number) => {
      const newTopic = shownTopics[newIndex - 1];
      setViewSettings(
        newTopic?.id ?? null,
        newTopic?.recordedESRSTopic?.dmaCategories[0]?.id ?? null,
      );
    },
    [shownTopics, setViewSettings],
  );

  // IRO Dialog Hook
  const { states, createHandlers, updateHandlers, moveHandlers, deleteHandlers } =
    useIroDialogHandlers(
      esrsTopics,
      selectedESRSTopic,
      organizationId,
      recordingPeriodId,
      dataEntryObject,
    );

  // Topic Opt Out

  const [showOptOutOfTopicDialog, setShowOptOutOfTopicDialog] = useState<ITopicOptOutDialogState>({
    open: false,
    esrsTopic: null,
  });
  const optOutOfESRSTopicMutation = useOptOutOfESRSTopicMutation();

  const handleOptOutOfESRSTopic = (esrsTopic: IESRSTopic) => {
    optOutOfESRSTopicMutation.reset();
    setShowOptOutOfTopicDialog({
      open: true,
      esrsTopic,
    });
  };
  const handleCloseOptOutConfirmDialog = async (data: IOptOutOfDmaCategoryRequest | null) => {
    if (data) {
      await optOutOfESRSTopicMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showOptOutOfTopicDialog.esrsTopic!.id,
        payload: data,
      });
    }
    // Close Dialog
    setShowOptOutOfTopicDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  // Category Opt Out

  const [showCategoryOptOutDialog, setShowCategoryOptOutDialog] =
    useState<ICategoryOptInOptOutDialogState>({
      open: false,
      esrsTopic: null,
      category: null,
    });
  const handleOptOutOfCategory = (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => {
    optOutOfDMACategoryMutation.reset();
    setShowCategoryOptOutDialog({
      open: true,
      esrsTopic,
      category,
    });
  };

  const optOutOfDMACategoryMutation = useOptOutDMACategoryMutation();

  const handleCloseCategoryOptOutDialog = async (data: IOptOutOfDmaCategoryRequest | null) => {
    if (data) {
      await optOutOfDMACategoryMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showCategoryOptOutDialog.esrsTopic!.id,
        dmaCategoryId: showCategoryOptOutDialog.category!.id,
        payload: data,
      });
    }
    // Close Dialog
    setShowCategoryOptOutDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  // Opt back into Category

  const [showCategoryOptBackInDialog, setShowCategoryOptBackInDialog] =
    useState<ICategoryOptInOptOutDialogState>({
      open: false,
      esrsTopic: null,
      category: null,
    });
  const handleOptBackIntoCategory = (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => {
    optOutOfDMACategoryMutation.reset();
    setShowCategoryOptBackInDialog({
      open: true,
      esrsTopic,
      category,
    });
  };

  const handleCloseConfirmOptBackIntoCategoryDialog = async (data: IDMACategory | null) => {
    if (data) {
      await optOutOfDMACategoryMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showCategoryOptBackInDialog.esrsTopic!.id,
        dmaCategoryId: showCategoryOptBackInDialog.category!.id,
        payload: {
          optOut: false,
          optOutReason: undefined,
        },
      });
    }
    setShowCategoryOptBackInDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  // DMA Categories - Start Data Recording

  const startDataRecordingMutation = useInitDMAForTopicMutation();

  const handleStartDataRecording = async (esrsTopic: IESRSTopic) => {
    if (!esrsTopic.currentTemplate) {
      console.error(
        "No current template available for ESRS Topic! handleStartDataRecording should not be called in this state!",
        esrsTopic,
      );
      return;
    }

    await startDataRecordingMutation.mutateAsync({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId: esrsTopic.id,
    });
  };

  // Add DMA Categories

  const {
    dmaCategoryEditingDialogsState,
    openCreateDMACategoryDialog,
    openEditDMACategoryDialog,
    closeDMACategoryDialogs,
  } = useDMACategoryEditingDialogsState();

  const handleCompleteDMACategoryAction = useCallback(
    (metaData: ICategoryCompleteActionMetadata) => {
      if (
        metaData.createdCategory &&
        !metaData.createdCategory.parentId && // Validate is top level category
        metaData.createdCategory.recordedTopicESRSTopicId === selectedESRSTopic?.id
      ) {
        setViewSettings(selectedESRSTopic?.id ?? null, metaData.createdCategory.id);
      }
      // Close all Dialogs
      closeDMACategoryDialogs();
    },
    [selectedESRSTopic, setViewSettings, closeDMACategoryDialogs],
  );

  // Complete DMA

  const esrsTopicsStates = useMemo(() => {
    return shownTopics.map(DMAUtilities.getESRSTopicState);
  }, [shownTopics]);

  const dmaCategoriesStates = useMemo(() => {
    return (
      selectedESRSTopic?.recordedESRSTopic?.dmaCategories.map(
        DMACategoryUtilities.getCategoryTotalState,
      ) ?? []
    );
  }, [selectedESRSTopic]);

  const canCompleteDMA = useMemo(
    () =>
      esrsTopics.length === esrsTopicsStates.length &&
      esrsTopicsStates.every((state) => state === IDMACategoryState.Verified),
    [esrsTopics, esrsTopicsStates],
  );

  const [showExecuteDMADialog, setShowExecuteDMADialog] = useState(false);
  const executeDMAMutation = useExecuteDMAMutation(async (variables) => {
    // Navigate back to Recording Period Dashboard after DMA is completed
    navigate(
      AppInternalNavigationUtilities.getRecordingPeriodDashboardPageUrl(
        variables.organizationId,
        variables.recordingPeriodId,
      ),
    );
  });

  const handleCompleteDMA = () => {
    executeDMAMutation.reset();
    // Button would be disabled if DMA is not complete --> just show the dialog
    setShowExecuteDMADialog(true);
  };
  const handleCloseConfirmExecuteDMADialog = async (confirm: boolean) => {
    if (confirm) {
      // Execute DMA
      await executeDMAMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
      });
      // Show success Toast
      enqueueSnackbar(
        <Box>
          <Typography variant="subtitle1">{t("toast_complete_dma_success_title")}</Typography>
          <Typography variant="body2">{t("toast_complete_dma_success_text")}</Typography>
        </Box>,
        {
          variant: "success",
          ...DEFAULT_SNACKBAR_CONFIGURATIONS,
          autoHideDuration: 6_000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        },
      );
      // Redirect to Recording Period Dashboard
      navigate(
        AppInternalNavigationUtilities.getRecordingPeriodDashboardPageUrl(
          organizationId,
          recordingPeriodId,
        ),
      );
    }
    // Close Dialog
    setShowExecuteDMADialog(false);
  };

  // Feedback Dialog

  const [stakeholderFeedbackDialogState, setStakeholderFeedbackDialogState] =
    useState<IStakeholderFeedbackDialogState>({ open: false, esrsTopic: null, dmaCategory: null });

  const handleOpenStakeholderFeedbackDialog = (
    esrsTopic: IESRSTopic,
    dmaCategory: IDMACategoryWithEffectsAndChildren,
  ) => {
    setStakeholderFeedbackDialogState({
      open: true,
      esrsTopic,
      dmaCategory,
    });
  };

  const handleCloseStakeholderFeedbackDialog = () => {
    setStakeholderFeedbackDialogState((state) => ({ ...state, open: false }));
  };

  // Sync dmaCategory shown in Dialog (on update)
  useEffect(() => {
    if (stakeholderFeedbackDialogState.open) {
      const newTopic = esrsTopics.find(
        (t) => t.id === stakeholderFeedbackDialogState.esrsTopic?.id,
      );
      if (!newTopic) {
        return;
      }

      const newDmaCategory = DMAUtilities.findCategoryInTopic(
        newTopic,
        stakeholderFeedbackDialogState.dmaCategory?.id ?? "",
      );
      if (!newDmaCategory) {
        return;
      }

      setStakeholderFeedbackDialogState((state) => ({
        ...state,
        esrsTopic: newTopic,
        dmaCategory: newDmaCategory,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esrsTopics]);

  // Render Component

  const stepperNavigationConfiguration = {
    esrsTopics: esrsTopics,
    selectedESRSTopic: selectedESRSTopic,
    selectedDMACategory: selectedDMACategory,
    onChangeViewSettings: setViewSettings,
  };

  const isLoading = startDataRecordingMutation.isPending;

  const renderTopicName = useRenderESRSTopicName();

  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);

  return (
    <>
      {/* Material Impacts Modals */}
      <DMAMaterialImpactEditDialog
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        open={states.showCreateMaterialImpactDialogState.open}
        dmaCategory={states.showCreateMaterialImpactDialogState.category}
        dmaConfiguration={dmaConfiguration}
        dataEntryObject={dataEntryObject}
        loading={states.materialImpactCreateDialogLoading}
        disabled={states.materialImpactCreateDialogLoading}
        error={states.materialImpactCreateDialogError}
        onClose={createHandlers.handleCloseCreateMaterialImpactDialog}
        esrsTopic={selectedESRSTopic}
      />
      <DMAMaterialImpactEditDialog
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        open={states.showEditMaterialImpactDialogState.open}
        materialImpact={states.showEditMaterialImpactDialogState.effectOrImpact}
        dmaCategory={states.showEditMaterialImpactDialogState.category}
        dmaConfiguration={dmaConfiguration}
        dataEntryObject={dataEntryObject}
        loading={states.materialImpactEditDialogLoading}
        disabled={states.materialImpactEditDialogLoading}
        error={states.materialImpactEditDialogError}
        onClose={updateHandlers.handleCloseUpdateMaterialImpactDialog}
        onUpdateIROState={updateHandlers.handleUpdateMaterialImpactIROState}
        onDelete={deleteHandlers.handleDeleteMaterialImpact}
        onMoveIRO={moveHandlers.handleMoveMaterialImpact}
        esrsTopic={selectedESRSTopic}
      />
      <DeleteImpactOrEffectConfirmDialog
        open={states.showDeleteMaterialImpactDialogState.open}
        loading={states.materialImpactDeleteDialogLoading}
        disabled={states.materialImpactDeleteDialogLoading}
        error={states.materialImpactDeleteDialogError}
        type="material-impact"
        effectOrImpact={states.showDeleteMaterialImpactDialogState.effectOrImpact}
        onCancel={() => deleteHandlers.handleCloseDeleteMaterialImpactDialog(false)}
        onConfirm={() => deleteHandlers.handleCloseDeleteMaterialImpactDialog(true)}
      />
      <MoveImpactOrEffectConfirmDialog
        open={states.moveMaterialImpactDialogState.open}
        loading={states.moveMaterialImpactDialogState.loading}
        disabled={states.moveMaterialImpactDialogState.loading}
        error={states.moveMaterialImpactDialogState.error}
        type="material_impact"
        effectOrImpact={states.moveMaterialImpactDialogState.effectOrImpact}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObjectId={dataEntryObjectId}
        dmaCategory={states.moveMaterialImpactDialogState.dmaCategory}
        onClose={moveHandlers.handleCloseMoveMaterialImpactDialog}
      />

      {/* Financial Effects Modals */}
      <DMAFinancialEffectEditDialog
        organizationId={organizationId}
        open={states.showCreateFinancialEffectDialogState.open}
        dmaConfiguration={dmaConfiguration}
        dmaCategory={states.showCreateFinancialEffectDialogState.category}
        dataEntryObject={dataEntryObject}
        loading={states.financialEffectCreateDialogLoading}
        disabled={states.financialEffectCreateDialogLoading}
        error={states.financialEffectCreateDialogError}
        onClose={createHandlers.handleCloseCreateFinancialEffectDialog}
        recordingPeriodId={recordingPeriodId}
        esrsTopic={selectedESRSTopic}
      />
      <DMAFinancialEffectEditDialog
        organizationId={organizationId}
        open={states.showEditFinancialEffectDialogState.open}
        financialEffect={states.showEditFinancialEffectDialogState.effectOrImpact}
        dmaConfiguration={dmaConfiguration}
        dmaCategory={states.showEditFinancialEffectDialogState.category}
        dataEntryObject={dataEntryObject}
        loading={states.financialEffectEditDialogLoading}
        disabled={states.financialEffectEditDialogLoading}
        error={states.financialEffectEditDialogError}
        onClose={updateHandlers.handleCloseUpdateFinancialEffectDialog}
        onUpdateIROState={updateHandlers.handleUpdateFinancialEffectIROState}
        onMoveIRO={moveHandlers.handleMoveFinancialEffect}
        onDelete={deleteHandlers.handleDeleteFinancialEffect}
        recordingPeriodId={recordingPeriodId}
        esrsTopic={selectedESRSTopic}
      />
      <DeleteImpactOrEffectConfirmDialog
        open={states.showDeleteFinancialEffectDialogState.open}
        loading={states.financialEffectDeleteDialogLoading}
        disabled={states.financialEffectDeleteDialogLoading}
        error={states.financialEffectDeleteDialogError}
        type="financial-effect"
        effectOrImpact={states.showDeleteFinancialEffectDialogState.effectOrImpact}
        onCancel={() => deleteHandlers.handleCloseDeleteFinancialEffectDialog(false)}
        onConfirm={() => deleteHandlers.handleCloseDeleteFinancialEffectDialog(true)}
      />
      <MoveImpactOrEffectConfirmDialog
        open={states.moveFinancialEffectDialogState.open}
        loading={states.moveFinancialEffectDialogState.loading}
        disabled={states.moveFinancialEffectDialogState.loading}
        error={states.moveFinancialEffectDialogState.error}
        type="financial_effect"
        effectOrImpact={states.moveFinancialEffectDialogState.effectOrImpact}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObjectId={dataEntryObjectId}
        dmaCategory={states.moveFinancialEffectDialogState.dmaCategory}
        onClose={moveHandlers.handleCloseMoveFinancialEffectDialog}
      />

      {/* Topic Opt Out Dialog */}
      <EsrsTopicOptOutDialog
        open={showOptOutOfTopicDialog.open}
        loading={optOutOfESRSTopicMutation.isPending}
        error={optOutOfESRSTopicMutation.isError ? optOutOfESRSTopicMutation.error : null}
        disabled={optOutOfESRSTopicMutation.isPending}
        esrsTopic={showOptOutOfTopicDialog.esrsTopic}
        onClose={handleCloseOptOutConfirmDialog}
      />

      {/* Category Opt Out Dialog */}
      <DMACategoryOptOutDialog
        open={showCategoryOptOutDialog.open}
        category={showCategoryOptOutDialog.category}
        loading={optOutOfDMACategoryMutation.isPending}
        disabled={optOutOfDMACategoryMutation.isPending}
        error={optOutOfDMACategoryMutation.isError ? optOutOfDMACategoryMutation.error : null}
        onClose={handleCloseCategoryOptOutDialog}
      />
      <DMACategoryOptBackInConfirmDialog
        open={showCategoryOptBackInDialog.open}
        category={showCategoryOptBackInDialog.category}
        loading={optOutOfDMACategoryMutation.isPending}
        disabled={optOutOfDMACategoryMutation.isPending}
        error={optOutOfDMACategoryMutation.isError ? optOutOfDMACategoryMutation.error : null}
        onClose={handleCloseConfirmOptBackIntoCategoryDialog}
      />

      {/* DMA Category Dialogs */}
      <DMACategoryDialogs
        organizationId={organizationId}
        dataEntryObjectId={dataEntryObjectId}
        recordingPeriodId={recordingPeriodId}
        dmaCategoryDialogsState={dmaCategoryEditingDialogsState}
        onCompleteAction={handleCompleteDMACategoryAction}
      />

      <DMACategoryFeedbackDialog
        open={stakeholderFeedbackDialogState.open}
        esrsTopic={stakeholderFeedbackDialogState.esrsTopic}
        dmaCategory={stakeholderFeedbackDialogState.dmaCategory}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObjectId={dataEntryObjectId}
        esrsTopicId={stakeholderFeedbackDialogState.esrsTopic?.id ?? ""}
        onClose={handleCloseStakeholderFeedbackDialog}
      />

      {/* Execute DMA Dialogs */}
      <ExecuteDMAConfirmDialog
        open={showExecuteDMADialog}
        loading={executeDMAMutation.isPending}
        disabled={executeDMAMutation.isPending}
        error={executeDMAMutation.isError ? executeDMAMutation.error : null}
        onClose={handleCloseConfirmExecuteDMADialog}
      />

      {/* Error dialog for startDataRecording mutation */}
      <ErrorDialog
        open={startDataRecordingMutation.isError}
        onClose={() => {
          startDataRecordingMutation.reset();
        }}
        error={startDataRecordingMutation.error ?? undefined}
      />

      {/* Content */}
      <Box flex={1} display="flex">
        {isLoading && <LinearProgress />}
        {!isLoading && (
          <Box
            position="relative"
            flex={1}
            display="flex"
            sx={{
              maxHeight: `calc(100vh - ${isFeatureFlagEnabled ? TOP_BAR_HEIGHT + 2 : 0}px)`,
              my: `-${CONTENT_Y_PADDING}px`,
              mr: -4,
            }}
          >
            <Box display="flex" maxHeight="100vh" flex={1}>
              <EsrsTopicsStepperToc
                dataEntryObjectId={dataEntryObjectId}
                viewedESRSTopicId={viewedESRSTopicId}
                viewedDMACategoryId={viewedDMACategoryId}
                selectedStepperIndex={selectedStepperIndex}
                shownTopics={shownTopics}
                esrsTopicsStates={esrsTopicsStates}
                dmaCategoriesStates={dmaCategoriesStates}
                onChangeStepperIndex={handleChangeStepperIndex}
                onChangeViewSettings={setViewSettings}
                onCreateDMACategory={openCreateDMACategoryDialog}
                onClearSettingsForDataEntryObject={clearSettingsForDataEntryObject}
              />
              <Box
                flex={1}
                sx={{
                  overflowY: "auto",
                  overflowX: "clip",
                  py: `${CONTENT_Y_PADDING}px`,
                }}
                px="4vw"
              >
                {/* Title */}
                <Typography variant="h1" mb={3}>
                  {selectedESRSTopic ? (
                    `${selectedESRSTopic.topic} - ${renderTopicName(selectedESRSTopic)}`
                  ) : (
                    <OnlyShowToUsersWithRole
                      roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CONFIGURATION}
                    >
                      {t("title_dma_configuration")}
                    </OnlyShowToUsersWithRole>
                  )}
                </Typography>

                {/* DMA Configuration */}
                <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CONFIGURATION}>
                  {!selectedESRSTopic && (
                    <DMAConfiguration
                      organizationId={organizationId}
                      recordingPeriodId={recordingPeriodId}
                      dataEntryObjectId={dataEntryObjectId}
                      dmaConfiguration={dmaConfiguration}
                      stepperNavigationConfiguration={stepperNavigationConfiguration}
                    />
                  )}
                </OnlyShowToUsersWithRole>

                {/* ESRS Topic Content */}
                {selectedESRSTopic && (
                  <ESRSTopicComponent
                    organizationId={organizationId}
                    recordingPeriodId={recordingPeriodId}
                    dataEntryObjectId={dataEntryObjectId}
                    esrsTopic={selectedESRSTopic}
                    showMaterialImpacts={showGroups.showMaterialImpacts}
                    showFinancialEffects={showGroups.showFinancialEffects}
                    showEmpty={!filterByUserIds.length} // Hide when filtered by user
                    onlyViewCategory={selectedDMACategory}
                    onCreateMaterialImpact={createHandlers.handleCreateMaterialImpact}
                    onCreateFinancialEffect={createHandlers.handleCreateFinancialEffect}
                    onEditMaterialImpact={updateHandlers.handleUpdateMaterialImpact}
                    onEditFinancialEffect={updateHandlers.handleUpdateFinancialEffect}
                    onOptOutOfRecordingESRSTopic={handleOptOutOfESRSTopic}
                    onStartDataRecordingForESRSTopic={handleStartDataRecording}
                    onEditOptOutReasonOfESRSTopic={handleOptOutOfESRSTopic}
                    onEditDMACategory={openEditDMACategoryDialog}
                    onCreateDMACategory={openCreateDMACategoryDialog}
                    onAddChildDMACategory={(esrsTopic, parentCategory) =>
                      openCreateDMACategoryDialog(esrsTopic, parentCategory.id)
                    }
                    onOptOutOfDMACategory={handleOptOutOfCategory}
                    onEditOptOutOfDMACategory={handleOptOutOfCategory}
                    onOptBackIntoDMACategory={handleOptBackIntoCategory}
                    onOpenFeedbackDialog={handleOpenStakeholderFeedbackDialog}
                  />
                )}

                {/* Spacer for Action Bar */}
                <ESRSTopicsStepperFloatingActionBarComponentSpacer />
              </Box>
            </Box>
            {/* Floating Action Bar */}
            <ESRSTopicsStepperFloatingActionBarComponent
              organizationId={organizationId}
              recordingPeriodId={recordingPeriodId}
              showGroups={showGroups}
              setShowGroups={setShowGroups}
              stepperNavigationConfiguration={stepperNavigationConfiguration}
              hasDMABeenCompleted={DataEntryObjectDmaConfigurationUtilities.hasDMABeenCompleted(
                dmaDeoConfiguration,
              )}
              canCompleteDMA={canCompleteDMA}
              onComplete={handleCompleteDMA}
              filterableUserIds={filterableUserIds}
              filterByUserIds={filterByUserIds}
              onChangeFilterByUserIds={setFilterByUserIds}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
