import { FormControl } from "@mui/material";
import { IInputParameterValueMetaDataDate } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { BaseDateComponent } from "./base-date.component";
import { DataEntryObjectInputParameterValueDefinitionForDate } from "@netcero/netcero-common";
import { useVariantFormProps } from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { ICommonInputProps } from "../value-acquisition.types";

type IDateInputComponentProps = ICommonInputProps<
  IInputParameterValueMetaDataDate,
  DataEntryObjectInputParameterValueDefinitionForDate
>;

export const DateInputComponent: FC<IDateInputComponentProps> = ({
  variant,
  value,
  onChange,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  label,
  required,
  autoFocus,
}) => {
  const variantProps = useVariantFormProps(variant);

  return (
    <FormControl
      {...variantProps}
      error={!!error}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined }}
    >
      <BaseDateComponent
        variant={variant}
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
        min={metaData.valueLimits.min}
        max={metaData.valueLimits.max}
        label={label}
        required={required}
        autoFocus={autoFocus}
      />
    </FormControl>
  );
};
