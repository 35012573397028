export enum EsrsFilters {
  ResponsiblePerson = "responsiblePerson",
  ContributingPeople = "contributingPeople",
  Status = "status",
  PhaseIn = "phaseIn",
  Optional = "optional",
}

export const StatusExcludedOption = "excluded";

export enum IrosListFilter {
  EsrsTopic = "esrsTopic",
  IroType = "iroType",
  EffectAreaType = "effectAreaType",
  Materiality = "materiality",
}

export enum EmissionFactorsFilter {
  name = "name",
  databases = "databases",
  emissionFactorTypes = "emissionFactorTypes",
  ghgCategories = "ghgCategories",
  units = "units",
  countries = "countries",
}

export enum EmissionFactorDialogFilter {
  nameDialog = "name",
  databasesDialog = "databases",
  emissionFactorTypesDialog = "emissionFactorTypes",
  ghgCategoriesDialog = "ghgCategories",
  unitsDialog = "units",
  countriesDialog = "countries",
}
