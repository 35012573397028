import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";
import { OrganizationStructurePageWrapperHelper } from "../common/organization-structure-page-wrapper.helper";
import { useLastUsedDataEntryObjectIdFromRouter } from "../../modules/data-entry-objects/use-last-used-data-entry-object.hooks";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";

export const RedirectToRootDataEntryObjectForValueInputPage: FC = () => {
  const { organizationId, recordingPeriodId, recordingStructureId, recordingStructureType } =
    useParams();
  const lastUsedDataEntryObjectId = useLastUsedDataEntryObjectIdFromRouter();

  return (
    <OrganizationStructurePageWrapperHelper
      buildPage={(structure) => (
        <Navigate
          to={AppInternalNavigationUtilities.getDeoPageUrl(
            organizationId!,
            recordingPeriodId!,
            recordingStructureType!,
            recordingStructureId!,
            lastUsedDataEntryObjectId ?? structure.id,
          )}
          replace
        />
      )}
    />
  );
};
