import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box, SvgIcon, TextField, Tooltip } from "@mui/material";
import { InfoIcon } from "../../common/constants/tabler-icon.constants";
import { IInternalEffectBaseData } from "./types/internal-effect-base-data.type";
import { useTranslation } from "react-i18next";
import { IroDialogTopSectionInputs } from "./iro-dialog-top-section-inputs.component";
import {
  CommentsButton,
  CommentsButtonWithQuery,
} from "../../comments/components/comments-button.component";
import { AuditLogsButton } from "../../audit-logging/components/audit-logs-button.component";
import { IroGroupDiscriminator } from "@netcero/netcero-common";

interface IIroCommonInputsProps {
  organizationId: string;
  disabled: boolean;
  readOnly: boolean;
  type: IroGroupDiscriminator;
  existingEntityId: string | undefined | null;
  onClickComments: VoidFunction;
}

export const IroCommonInputs: FC<IIroCommonInputsProps> = ({
  organizationId,
  disabled,
  readOnly,
  type,
  existingEntityId,
  onClickComments,
}) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");
  const { control } = useFormContext<IInternalEffectBaseData>();

  return (
    <>
      {/* Title */}
      <Controller
        control={control}
        name="title"
        rules={{
          required: t("error_title_required"),
          validate: (value) => {
            if (value.trim() === "") {
              return t("error_title_required");
            }

            return true;
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={t("label_title")}
            error={!!error}
            helperText={error?.message}
            fullWidth
            multiline
            maxRows={3}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <Tooltip title={t("description_title")}>
                  <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                    <InfoIcon />
                  </SvgIcon>
                </Tooltip>
              ),
            }}
          />
        )}
      />

      {/* Description */}
      <Controller
        control={control}
        name="description"
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={t("label_description")}
            error={!!error}
            helperText={error?.message}
            multiline
            fullWidth
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <Tooltip title={t("description_explanation")}>
                  <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                    <InfoIcon />
                  </SvgIcon>
                </Tooltip>
              ),
            }}
          />
        )}
      />

      {/* Buttons (only when entity exists) */}
      <Box display="flex" gap={2}>
        {existingEntityId ? (
          <>
            <CommentsButtonWithQuery
              organizationId={organizationId}
              relations={
                type === "material-impact"
                  ? {
                      entity: "material-impact",
                      materialImpactId: existingEntityId,
                    }
                  : {
                      entity: "financial-effect",
                      financialEffectId: existingEntityId,
                    }
              }
              onClick={onClickComments}
              disabled={readOnly}
            />
            <AuditLogsButton organizationId={organizationId} disabled={readOnly} />
          </>
        ) : (
          // Only show comment button disabled, while the entity has not been created
          <Tooltip title={t("comment_button_tooltip")}>
            <span>
              <CommentsButton disabled />
            </span>
          </Tooltip>
        )}
      </Box>

      {/* Common Users and Sources Inputs */}
      <Box pb={2}>
        <IroDialogTopSectionInputs organizationId={organizationId} disabled={disabled} />
      </Box>

      {/* Assessment Explanation */}
      <Controller
        control={control}
        name="assessmentExplanation"
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={t("label_assessmentExplanation")}
            error={!!error}
            helperText={error?.message}
            multiline
            minRows={3}
            fullWidth
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <Tooltip title={t("assessmentExplanation_explanation")}>
                  <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                    <InfoIcon />
                  </SvgIcon>
                </Tooltip>
              ),
            }}
          />
        )}
      />
    </>
  );
};
