import { useCallback } from "react";
import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { GHG_CATEGORIES_PATHS } from "@netcero/netcero-common";

export const useGhgActivityReappropriatedText = () => {
  const { t } = useTranslation("ghg_activities");
  return useCallback(
    (reappropriatedTo: IGhgCategoriesIdentifier) =>
      t("reappropriated_text", { categoryPath: GHG_CATEGORIES_PATHS[reappropriatedTo] }),
    [t],
  );
};
