import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { IActivityValuesImportErrorInformation } from "@netcero/netcero-common";
import { FormatUtilities } from "../../common/utilities/format.utilities";

export const useTranslateGhgActivityValueImportError = () => {
  const { t } = useTranslation("ghg_activities", { keyPrefix: "upload.errors_import" });

  return useCallback(
    ({ error, rowIndex }: IActivityValuesImportErrorInformation) =>
      t(error, {
        row: rowIndex !== null ? FormatUtilities.formatNumber(rowIndex + 1) : undefined,
      }),
    [t],
  );
};
