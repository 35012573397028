import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NoticeComponent } from "../common/components/notice.component";

interface IOrganizationStructureIsDraftNoticeProps {
  organizationId: string;
  recordingPeriodId: string;
}

/**
 * Displays a notice that the organization structure is a draft and links to the configuration page.
 * @param organizationId Organization the structure belongs to
 * @param recordingPeriodId Recording Period the structure belongs to
 * @param noticeText The text that should be displayed
 */
export const OrganizationStructureIsDraftNotice: FC<IOrganizationStructureIsDraftNoticeProps> = ({
  organizationId,
  recordingPeriodId,
}) => {
  const { t } = useTranslation("organization_structure_is_draft_notice");

  return (
    <NoticeComponent
      title={t("title")}
      content={t("description")}
      button={{
        text: t("to_configuration_text"),
        link: `/organizations/${organizationId}/configuration/recording-periods/${recordingPeriodId}/organization-structure`,
      }}
    />
  );
};
