import { IActivity, IContentLanguage } from "@netcero/netcero-core-api-client";
import { CoreApiService } from "../core-api/core-api.service";
import { IOrganizationIdentity } from "@netcero/netcero-common";
import { GhgActivityValuesImportTemplateWebWorkerUtilities } from "./web-workers/ghg-activity-values-import-template-web-worker.utilities";
import { QueryClient } from "@tanstack/react-query";

export class GhgActivityValuesUploadUtilities {
  private static readonly ACTIVITY_TEMPLATE_BASE_PATH = "/templates/ghg";

  /**
   * Do not access this directly, use getTemplatePath instead.
   * @private
   */
  private static readonly ACTIVITY_TEMPLATE_FILE_NAMES_PER_LANGUAGE: Record<
    IContentLanguage,
    string
  > = {
    [IContentLanguage.En]: "NetCero_Upload_Activity_Template_EN.xlsx",
    [IContentLanguage.De]: "NetCero_Upload_Activity_Template_DE.xlsx",
  };

  private static getTemplatePath(language: IContentLanguage) {
    return (
      `${GhgActivityValuesUploadUtilities.ACTIVITY_TEMPLATE_BASE_PATH}/` +
      GhgActivityValuesUploadUtilities.ACTIVITY_TEMPLATE_FILE_NAMES_PER_LANGUAGE[language]
    );
  }

  public static async generateActivityTemplate(
    { organizationId }: IOrganizationIdentity,
    activity: IActivity,
    language: IContentLanguage,
    queryClient: QueryClient,
  ) {
    // Validate Pre Requisites
    if (activity.emissionFactorIds.length < 1) {
      throw new Error("No emission factors found for this activity!");
    }

    // Fetch needed resources
    const [template, emissionFactors] = await Promise.all([
      GhgActivityValuesUploadUtilities.getTemplateFile(queryClient, language),
      Promise.all(
        activity.emissionFactorIds.map((emissionFactorId) =>
          CoreApiService.EmissionFactorApi.getEmissionFactor(organizationId, emissionFactorId)
            .then((req) => req())
            .then((res) => res.data),
        ),
      ),
    ]);

    // Generate the template
    const ret = await GhgActivityValuesImportTemplateWebWorkerUtilities.generateTemplate({
      activity,
      emissionFactors,
      language,
      template,
    });

    if (ret.error) {
      throw ret.error;
    }
    return ret.result;
  }

  private static async getTemplateFile(queryClient: QueryClient, language: IContentLanguage) {
    // pick correct template depending on language
    const filePath = GhgActivityValuesUploadUtilities.getTemplatePath(language);

    return await queryClient.fetchQuery({
      staleTime: 24 * 60 * 60 * 1_000, // Refetch only after 24h (usually this never changes)
      queryKey: ["ghg-activity-template-file", filePath],
      queryFn: async () => {
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error("Failed to fetch template file");
        }
        return await response.blob();
      },
    });
  }
}
