import { useMemo } from "react";
import { useUserContext } from "../user/user.context";
import { AuthenticationUtilities, IOrganization } from "@netcero/netcero-common";

/**
 * Gets all organizations the current user has access to.
 * Returns an empty array if the user is not yet logged in.
 */
export function useOrganizations(): IOrganization[] {
  const { user } = useUserContext(); // Warning for refactor to keycloak JWT directly --> UserContext currently sets a default value for deo_count_max!

  return useMemo(() => {
    if (!user) {
      return [];
    }

    return AuthenticationUtilities.getNetCeroOrganizations(user.jwtPayload);
  }, [user]);
}
