import {
  IContentLanguage,
  IGhgCategoriesIdentifier,
  IGhgProtocolScopeEnum,
  IInputParameterRecordingStructureGroupGHG,
  IRecordingPeriodGhgCategoryStatus,
  ITranslatedProperty,
} from "@netcero/netcero-core-api-client";
import { GHG_VERSIONS } from "./versions/ghg-versions";
import {
  GhgCategoryStatusStateTransitionDefinitions,
  GhgCategoryStatusStateTransitions,
  IGhgCategoriesPerScope,
} from "./ghg-categories.public-types";
import { GHG_CATEGORIES_BY_SCOPE } from "./ghg-categories.constants";
import { GHG_CATEGORY_TRANSLATIONS_PER_LANGUAGE } from "./ghg-categories.translations";
import { GHG_CATEGORIES_PATHS } from "./ghg-categories.paths";
import { GHG_SCOPE_TRANSLATIONS_PER_LANGUAGE } from "./ghg-scopes.translations";
import { ContentTranslationUtilities } from "../content-translation";
import { StateMachineUtilities } from "../state-machine";

export class GhgCategoriesUtilities {
  /**
   * This method retrieves the correct definition for a given GHG structure.
   * @param recordingStructure The recording structure to get the definition for.
   */
  public static getDefinitionForRecordingStructure(
    recordingStructure: IInputParameterRecordingStructureGroupGHG,
  ): IGhgCategoriesPerScope {
    return GHG_VERSIONS[recordingStructure.version];
  }

  /**
   * This method retrieves every present category identifier for a given recording structure.
   * @param recordingStructure The recording structure to get the category identifiers for.
   */
  public static getAllCategoryIdentifiersForRecordingStructure(
    recordingStructure: IInputParameterRecordingStructureGroupGHG,
  ): IGhgCategoriesIdentifier[] {
    return Object.values(
      GhgCategoriesUtilities.getDefinitionForRecordingStructure(recordingStructure),
    ).flatMap((definition) => definition);
  }

  /**
   * This function checks if a given GHG category is included in a specific scope across all GHG versions.
   * @param category - The GHG category identifier to check.
   * @param scope - The scope to check the category against.
   * @returns A boolean value indicating whether the category is present in the scope for any GHG version.
   */
  public static isCategoryInScope(
    category: IGhgCategoriesIdentifier,
    scope: IGhgProtocolScopeEnum,
  ): boolean {
    // check whether category is present in the scope for all ghg versions
    return GHG_CATEGORIES_BY_SCOPE[scope].includes(category);
  }

  /**
   * Builds a lookup object for translations of GHG categories in a specified language.
   * @param language - The language to get translations for.
   * @param property - The property to retrieve (either "name" or "description").
   * @returns A record object where keys are category identifiers and values are the translated property.
   */
  public static buildTranslationLookupForCategories(
    language: IContentLanguage,
    property: "name" | "description",
  ) {
    return Object.entries(GHG_CATEGORY_TRANSLATIONS_PER_LANGUAGE[language]).reduce(
      (acc, [categoryIdentifier, value]) => {
        acc[categoryIdentifier as IGhgCategoriesIdentifier] = value[property];
        return acc;
      },
      {} as Record<IGhgCategoriesIdentifier, string>,
    );
  }

  public static buildTranslationLookupForScopes(
    language: IContentLanguage,
    property: "title" | "subtitle",
  ) {
    return Object.entries(GHG_SCOPE_TRANSLATIONS_PER_LANGUAGE[language]).reduce(
      (acc, [scope, value]) => {
        acc[scope as IGhgProtocolScopeEnum] = value[property];
        return acc;
      },
      {} as Record<IGhgProtocolScopeEnum, string>,
    );
  }

  /**
   * This function retrieves the scope a given GHG category is in.
   * @param category
   */
  public static getScopeOfGhgCategory(category: IGhgCategoriesIdentifier): IGhgProtocolScopeEnum;
  public static getScopeOfGhgCategory(category: string): IGhgProtocolScopeEnum | null {
    return (
      Object.values(IGhgProtocolScopeEnum).find((scope) =>
        (GHG_CATEGORIES_BY_SCOPE[scope] as string[]).includes(category),
      ) ?? null
    );
  }

  /**
   * Retrieves the translated name of a GHG category in a specified language.
   * @param category - The GHG category identifier.
   * @param locale - The language to get the translation for.
   * @param includeCategoryNumber - Whether to include the category number in the translation.
   * @returns The translated name of the GHG category.
   */
  public static getTranslatedNameOfGhgCategory(
    category: IGhgCategoriesIdentifier,
    locale: IContentLanguage,
    includeCategoryNumber = false,
  ) {
    const name = GHG_CATEGORY_TRANSLATIONS_PER_LANGUAGE[locale][category].name;
    return includeCategoryNumber ? `${GHG_CATEGORIES_PATHS[category]} ${name}` : name;
  }

  /**
   * Retrieves the translatable name of a GHG category in all available languages.
   * @param category - The GHG category identifier.
   * @param defaultLanguage - The default language for the translation.
   * @param includeCategoryNumber - Whether to include the category number in the translation.
   * @returns An object containing the default language and translations per language. (ITranslatedProperty)
   */
  public static getTranslatableNameOfGhgCategory(
    category: IGhgCategoriesIdentifier,
    defaultLanguage: IContentLanguage,
    includeCategoryNumber = false,
  ): ITranslatedProperty {
    return ContentTranslationUtilities.createTranslations(
      (language) =>
        GhgCategoriesUtilities.getTranslatedNameOfGhgCategory(
          category,
          language,
          includeCategoryNumber,
        ),
      defaultLanguage,
    );
  }

  /**
   * Retrieves the translated title of a GHG scope in a specified language.
   * @param scope - The GHG protocol scope identifier.
   * @param locale - The language to get the translation for.
   * @param includeSubtitle - Whether to include the subtitle in the translation.
   * @returns The translated title of the GHG scope.
   */
  public static getTranslatedScopeTitle(
    scope: IGhgProtocolScopeEnum,
    locale: IContentLanguage,
    includeSubtitle: boolean = false,
  ): string {
    const entry = GHG_SCOPE_TRANSLATIONS_PER_LANGUAGE[locale][scope];
    return includeSubtitle ? `${entry.title}: ${entry.subtitle}` : entry.title;
  }

  /**
   * Retrieves the translatable title of a GHG scope in all available languages.
   * @param scope - The GHG protocol scope identifier.
   * @param defaultLanguage - The default language for the translation.
   * @param includeSubtitle - Whether to include the subtitle in the translation.
   * @returns An object containing the default language and translations per language. (ITranslatedProperty)
   */
  public static getTranslatableScopeTitle(
    scope: IGhgProtocolScopeEnum,
    defaultLanguage: IContentLanguage,
    includeSubtitle: boolean = false,
  ): ITranslatedProperty {
    return ContentTranslationUtilities.createTranslations(
      (language) =>
        GhgCategoriesUtilities.getTranslatedScopeTitle(scope, language, includeSubtitle),
      defaultLanguage,
    );
  }

  public static getStateTransition(
    from: IRecordingPeriodGhgCategoryStatus,
    to: IRecordingPeriodGhgCategoryStatus,
  ): GhgCategoryStatusStateTransitions | null {
    return StateMachineUtilities.getStateTransition(
      from,
      to,
      GhgCategoryStatusStateTransitionDefinitions,
    );
  }
}
