import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { OrganizationRole, UserUtilities } from "@netcero/netcero-common";
import { useDebounce } from "@uidotdev/usehooks";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSnackbar } from "../../modules/app-snackbar/app-snackbar.hook";
import { BasicSnackbarApiActionType } from "../../modules/app-snackbar/app-snackbar.interfaces";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { EditIcon } from "../../modules/common/constants/tabler-icon.constants";
import { OrganizationRoleSelect } from "../../modules/user/components/organization-role-select.component";
import { useUserContext } from "../../modules/user/user.context";
import { UpdateDeoAccessDialog } from "./update-deo-access-dialog.component";
import { UserTableFilters } from "./user-table-filters.component";
import { useSearchUsersQueryInternal } from "../../modules/user/users.queries";
import { useGetOrganizationRoleOfUser } from "../../modules/user/hooks/get-org-role-of-user.hook";
import { useUpdateOrganizationRoleOfUserMutation } from "../../modules/user/user.mutations";
interface IUsersTableProps {
  organizationId: string;
}

export const UserTable: FC<IUsersTableProps> = ({ organizationId }) => {
  const { t } = useTranslation("users_table");

  const { watch } = useFormContext<UserTableFilters>();
  const searchValue = watch("searchValue");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const usersQuery = useSearchUsersQueryInternal(
    organizationId,
    debouncedSearchValue,
    UserUtilities.sortUsersArrayByFullName,
  );

  const { user } = useUserContext();
  const authenticatedUserId = user?.userProfile.id;
  const getOrganizationRoleOfUser = useGetOrganizationRoleOfUser(organizationId);
  const authenticatedUserRole = getOrganizationRoleOfUser(authenticatedUserId);
  const canUpdateDeoAccess =
    authenticatedUserRole === OrganizationRole.ORGANIZATION_ADMIN ||
    authenticatedUserRole === OrganizationRole.OBJECT_ADMIN;

  const updateOrganizationRoleOfUserMutation =
    useUpdateOrganizationRoleOfUserMutation(organizationId);
  const { wrapApiPromise } = useAppSnackbar();
  const handleRoleChange = async (userId: string, role: OrganizationRole) => {
    await wrapApiPromise(updateOrganizationRoleOfUserMutation.mutateAsync({ userId, role }), {
      type: BasicSnackbarApiActionType.UPDATE_ORG_ROLE,
    });

    if (role === OrganizationRole.OBJECT_ADMIN) {
      setDeoAssignmentDialogState({
        open: true,
        selectedUserId: userId,
      });
    }
  };

  const [deoAssignmentDialogState, setDeoAssignmentDialogState] = useState({
    open: false,
    selectedUserId: "",
  });

  return (
    <QueryWrapper
      query={usersQuery}
      build={(users) =>
        users.length === 0 ? (
          <Box>{t("no_users_found")}</Box>
        ) : (
          <>
            <Table size="small" sx={{ mt: -2 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("first_name")}</StyledTableCell>
                  <StyledTableCell>{t("last_name")}</StyledTableCell>
                  <StyledTableCell>{t("email")}</StyledTableCell>
                  <StyledTableCell>{t("role")}</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {users?.map(({ id, firstName, lastName, email }) => (
                  <TableRow key={id}>
                    <StyledTableCell>{firstName}</StyledTableCell>
                    <StyledTableCell>{lastName}</StyledTableCell>
                    <StyledTableCell>{email}</StyledTableCell>
                    <StyledTableCell>
                      {id ? (
                        <>
                          <OrganizationRoleSelect
                            selectedRole={getOrganizationRoleOfUser(id)}
                            onChange={(newOrgRole) => handleRoleChange(id, newOrgRole)}
                            currentUserRole={authenticatedUserRole}
                            disabled={id === authenticatedUserId}
                          />
                          {getOrganizationRoleOfUser(id) === OrganizationRole.OBJECT_ADMIN &&
                            canUpdateDeoAccess &&
                            id !== authenticatedUserId && (
                              <IconButton
                                onClick={() =>
                                  setDeoAssignmentDialogState({
                                    open: true,
                                    selectedUserId: id,
                                  })
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                        </>
                      ) : null}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <UpdateDeoAccessDialog
              organizationId={organizationId}
              open={deoAssignmentDialogState.open}
              onClose={() => setDeoAssignmentDialogState({ open: false, selectedUserId: "" })}
              targetUserId={deoAssignmentDialogState.selectedUserId}
            />
          </>
        )
      }
    />
  );
};

const StyledTableCell = styled(TableCell)({
  padding: "16px",
});
