import { useParams } from "react-router-dom";
import { useMemo } from "react";

export function useValidatedRouterParameters<T extends string>(expectedParameters: readonly T[]) {
  const routerParameters = useParams();
  return useMemo(() => {
    // Validate the expectedParameters
    const parameterValues = expectedParameters.map((key) => ({
      key,
      value: routerParameters[key],
    }));

    const invalidParameters = parameterValues
      .filter(({ value }) => value === undefined)
      .map((p) => p.key);

    // Handle errors
    if (invalidParameters.length > 0) {
      console.error("Invalid/missing URL expectedParameters", invalidParameters);
      return null;
    }

    return routerParameters as Record<T, string>;
  }, [routerParameters, expectedParameters]);
}
