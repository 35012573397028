import { useCallback, useMemo, useState } from "react";

export const useOpenStateWithForwardClose = () => {
  const [showMenu, setShowMenu] = useState(false);
  const forwardWithCloseMenu = useCallback(
    <T extends CallableFunction>(cb: T) =>
      ((...args: unknown[]) => {
        setShowMenu(false);
        return cb(...args);
      }) as unknown as T,
    [],
  );

  return useMemo(
    () => ({
      showMenu,
      setShowMenu,
      forwardWithCloseMenu,
    }),
    [showMenu, setShowMenu, forwardWithCloseMenu],
  );
};
