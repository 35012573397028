import { ArrayUtilities, TreeUtilities } from "@netcero/netcero-common";
import {
  createContext,
  FC,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Autocomplete, Checkbox } from "@mui/material";
import { LineClampTypographyWithTooltip } from "../../../common/components/line-clamp-typography.component";
import { AutocompleteTextInput, IFlattenedHierarchyEntry } from "./hierarchical-dropdown.component";
import {
  OuterElementContext,
  OuterElementType,
  VirtualizedAutocompleteStyledPopper,
} from "./virtualized-autocomplete.helpers";
import { FixedSizeList, ListChildComponentProps } from "react-window";

interface IGroupedMultipleDropdownProps {
  label: ReactNode;
  flattenedOptions: IFlattenedHierarchyEntry[];
  value: string[];
  onChange: (newValue: string[]) => void;
  disabled: boolean;
}

type ICheckboxState = "checked" | "unchecked" | "indeterminate";

function isOptionParent(option: IFlattenedHierarchyEntry) {
  return option.item.children.length > 0;
}

function getAllLeafValues(option: IFlattenedHierarchyEntry): string[] {
  return option.item.children
    .map((child) =>
      TreeUtilities.flatMap(
        child,
        (o) => o.children,
        (o) => (o.children.length === 0 ? o.value : null),
      ),
    )
    .flat()
    .filter((v) => v !== null) as string[];
}

function getParentOptionState(option: IFlattenedHierarchyEntry, value: string[]): ICheckboxState {
  const allLeafValues = getAllLeafValues(option);
  const selectedChildValuesCount = allLeafValues.filter((v) => value.includes(v)).length;
  return selectedChildValuesCount === allLeafValues.length
    ? "checked"
    : selectedChildValuesCount > 0
    ? "indeterminate"
    : "unchecked";
}

function getLeafOptionState(option: IFlattenedHierarchyEntry, value: string[]): ICheckboxState {
  return value.includes(option.item.value) ? "checked" : "unchecked";
}

type IValueLabelLookup = Record<string, string | undefined>;
type ICheckboxStateLookup = Record<string, ICheckboxState | undefined>;
type IValueClickHandler = (option: IFlattenedHierarchyEntry) => () => void;

interface IHierarchicalDropdownContext {
  checkboxStateLookup: ICheckboxStateLookup;
  valueLabelLookup: IValueLabelLookup;
  handleClickValue: IValueClickHandler;
}

const HierarchicalDropdownContext = createContext<IHierarchicalDropdownContext>({
  checkboxStateLookup: {},
  valueLabelLookup: {},
  handleClickValue: () => () => {},
});

export const HierarchicalDropdownMultiple: FC<IGroupedMultipleDropdownProps> = ({
  label,
  value,
  onChange,
  flattenedOptions,
  disabled,
}) => {
  const selectedOptions = useMemo(
    () => flattenedOptions.filter((option) => value.includes(option.item.value)),
    [flattenedOptions, value],
  );

  const checkboxStateLookup: ICheckboxStateLookup = useMemo(() => {
    const lookup: ICheckboxStateLookup = {};

    flattenedOptions.forEach((flattenedOption) => {
      lookup[flattenedOption.item.value] = isOptionParent(flattenedOption)
        ? getParentOptionState(flattenedOption, value)
        : getLeafOptionState(flattenedOption, value);
    });

    return lookup;
  }, [flattenedOptions, value]);

  const valueLabelLookup: Record<string, string> = useMemo(() => {
    const lookup: Record<string, string> = {};

    flattenedOptions.forEach((flattenedOption) => {
      lookup[flattenedOption.item.value] = flattenedOption.item.label;
    });

    return lookup;
  }, [flattenedOptions]);

  const handleClickValue: IValueClickHandler = useCallback(
    (option: IFlattenedHierarchyEntry): (() => void) => {
      if (isOptionParent(option)) {
        // Handle Parent Value
        const allLeafValues = getAllLeafValues(option);
        const state = checkboxStateLookup[option.item.value];
        if (state === "checked") {
          return () => onChange(value.filter((v) => !allLeafValues.includes(v)));
        } else {
          return () => onChange(Array.from(new Set([...value, ...allLeafValues])));
        }
      } else {
        // Handle Leaf Value
        const state = checkboxStateLookup[option.item.value];
        if (state === "checked") {
          return () => onChange(value.filter((v) => v !== option.item.value));
        } else {
          return () => onChange(Array.from(new Set([...value, option.item.value])));
        }
      }
    },
    [checkboxStateLookup, onChange, value],
  );

  const contextValue = useMemo(
    () => ({ checkboxStateLookup, valueLabelLookup, handleClickValue }),
    [checkboxStateLookup, valueLabelLookup, handleClickValue],
  );

  return (
    <HierarchicalDropdownContext.Provider value={contextValue}>
      <Autocomplete
        multiple
        disableClearable
        disableCloseOnSelect
        ListboxComponent={ListBoxComponent}
        PopperComponent={VirtualizedAutocompleteStyledPopper}
        disableListWrap
        value={selectedOptions}
        onChange={(_, newValue) => {
          onChange(
            newValue
              ? ArrayUtilities.sorted(
                  ArrayUtilities.alwaysArray(newValue).map((value) => value.item.value),
                )
              : [],
          );
        }}
        options={flattenedOptions}
        getOptionLabel={(option) => valueLabelLookup[option.item.value]}
        renderOption={(props, option) => [props, option] as ReactNode}
        renderInput={(params) => <AutocompleteTextInput params={params} {...{ label, disabled }} />}
        fullWidth
      />
    </HierarchicalDropdownContext.Provider>
  );
};

const PADDING = 8;
const ITEM_HEIGHT = 48;

// Adapter for react-window
const ListBoxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  function ListBoxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: ReactElement[] = [];
    (children as ReactElement[]).forEach((item: ReactElement & { children?: ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children ?? []));
    });
    const itemCount = itemData.length;

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            itemData={itemData as unknown as [object, IFlattenedHierarchyEntry][]}
            itemSize={ITEM_HEIGHT}
            itemCount={itemCount}
            height={Math.min(itemCount * ITEM_HEIGHT + 2 * PADDING, window.innerHeight / 2)}
            width="100%"
            outerElementType={OuterElementType}
            innerElementType="ul"
          >
            {RenderRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

function RenderRow(props: ListChildComponentProps<[object, IFlattenedHierarchyEntry][]>) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + PADDING,
  };

  const [itemProps, option] = dataSet;
  const { checkboxStateLookup, valueLabelLookup, handleClickValue } = useContext(
    HierarchicalDropdownContext,
  );

  return (
    <li
      {...itemProps}
      {...props}
      key={option.item.value}
      style={{ ...inlineStyle, borderRadius: 8 }}
      onClick={handleClickValue(option)}
    >
      <Checkbox
        style={{ marginRight: 8 }}
        checked={checkboxStateLookup[option.item.value] === "checked"}
        indeterminate={checkboxStateLookup[option.item.value] === "indeterminate"}
        sx={{ ml: option.depth * 4 }}
      />
      <LineClampTypographyWithTooltip
        fontWeight={option.depth === 0 && option.item.children.length > 0 ? "bold" : "normal"}
      >
        {valueLabelLookup[option.item.value]}
      </LineClampTypographyWithTooltip>
    </li>
  );
}
