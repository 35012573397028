import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { usePostHog } from "posthog-js/react";
import { FC, StrictMode, useEffect } from "react";
import keycloak from "../keycloak";
import { AuthenticationService } from "../modules/authentication/authentication.service";
import { AppShellWrapper } from "../modules/common/components/app-shell-wrapper.component";
import { IntercomWrapper } from "../modules/intercom/intercom-wrapper.component";
import { useCurrentOrganization } from "../modules/organizations/use-current-organization.hook";
import { useSyncAccountToKeycloak } from "../modules/user/hooks/sync-account-to-keycloak.hook";
import { UserContext, useUserContext } from "../modules/user/user.context";
import { useNCTheme } from "../theme/theme";
import { OrganizationsLayout } from "./organizations/organizations.layout";
import { RedirectToKbLayout } from "./redirect-to-kb.layout";

export const RootLayout: FC = () => {
  const defaultTheme = useNCTheme();
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: "login-required" }}
      LoadingComponent={
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        </ThemeProvider>
      }
      onTokens={(tokens) => {
        // TODO: Include error handling here
        AuthenticationService.setBearerTokenOnAxios(tokens.token!);
      }}
    >
      <StrictMode>
        <UserContext
          onTokenUpdate={(token) => AuthenticationService.setBearerTokenOnAxios(token)}
          onLogout={AuthenticationService.clearBearerTokenOnAxios}
        >
          <UserContextComponents />
        </UserContext>
      </StrictMode>
    </ReactKeycloakProvider>
  );
};

const UserContextComponents: FC = () => {
  // Sync the user's local account with keycloak
  useSyncAccountToKeycloak();

  const posthog = usePostHog();
  const currentOrganization = useCurrentOrganization();
  const { user } = useUserContext();

  const showRedirectLayout = user?.jwtPayload.redirectToKb;

  useEffect(() => {
    // Identify user to posthog
    if (user) {
      posthog.identify(user.userProfile.id, {
        email: user.userProfile.email,
      });
      if (currentOrganization) {
        posthog.group("organization", currentOrganization?.id, {
          name: currentOrganization?.name,
        });
      }
    }
  }, [currentOrganization, posthog, user]);

  return (
    <IntercomWrapper>
      {showRedirectLayout ? (
        <RedirectToKbLayout />
      ) : (
        <AppShellWrapper>
          <OrganizationsLayout />
        </AppShellWrapper>
      )}
    </IntercomWrapper>
  );
};
