import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { useRecordingPeriodsQuery } from "../../modules/recording-periods/recording-periods.queries";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useLastUsedRecordingPeriodFromRouter } from "../../modules/recording-periods/use-last-used-recording-period.hooks";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT } from "../../modules/common/components/app-shell-wrapper.component";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";
import { NoticeComponent } from "../../modules/common/components/notice.component";

interface IRecordingPeriodsRedirectPageProps {}

export const RecordingPeriodsRedirectPage: FC<IRecordingPeriodsRedirectPageProps> = () => {
  const { t } = useTranslation("recording_periods_redirect_page");

  const { organizationId } = useParams();
  const navigate = useNavigate();

  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId!);

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(AppInternalNavigationUtilities.getRecordingPeriodPageUrl(orgId));
            },
          }
        : undefined,
      navigationItems: {
        activePath: AppInternalNavigationUtilities.getRecordingPeriodPageUrl(organizationId!),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(AppInternalNavigationUtilities.getRecordingPeriodPageUrl(orgId));
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  const lastUsedRecordingPeriod = useLastUsedRecordingPeriodFromRouter();

  // Redirect to the last used recording period if available
  if (lastUsedRecordingPeriod) {
    return (
      <Navigate
        to={AppInternalNavigationUtilities.getRecordingPeriodDashboardPageUrl(
          organizationId!,
          lastUsedRecordingPeriod.id,
        )}
        replace
      />
    );
  }
  return (
    <QueryWrapper
      query={recordingPeriodsQuery}
      loadingOverride={() => (
        <CenteredCircularProgress minHeight={PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT} />
      )}
      build={(data) => {
        if (data!.recordingPeriods.length > 0) {
          const currentRecordingPeriod =
            data!.recordingPeriods.find(
              (rp) => rp.startDate <= new Date() && rp.endDate >= new Date(),
            ) ?? data!.recordingPeriods[0];
          return (
            <Navigate
              to={AppInternalNavigationUtilities.getRecordingPeriodDashboardPageUrl(
                organizationId!,
                currentRecordingPeriod.id,
              )}
              replace
            />
          );
        }

        return (
          <NoticeComponent
            title={t("title")}
            content={t("description")}
            button={{
              text: t("button_create_recording_period"),
              link: AppInternalNavigationUtilities.getConfigurationRecordingPeriodPageUrl({
                organizationId: organizationId!,
              }),
            }}
          />
        );
      }}
    />
  );
};
