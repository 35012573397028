import { useMutation } from "@tanstack/react-query";
import { IGhgActivityIdentity, IRecordingPeriodGhgCategoryIdentity } from "@netcero/netcero-common";
import {
  IActivityContext,
  IBaseActivityData,
  IWithSources,
} from "@netcero/netcero-core-api-client";
import { CoreApiService } from "../core-api/core-api.service";
import { useInvalidateQueriesByKeys } from "../common/hooks/invalidate-queries-by-keys.hook";
import { getGhgCategoryActivitiesQueryKey } from "./ghg-activities.queries";
import { getRecordingPeriodGhgCategoriesQueryKey } from "../ghg-categories/ghg-categories.queries";
import { getGhgEvaluationQueryKey } from "../ghg-evaluation/ghg-evaluation.queries";

interface IGhgActivityMutationsCommonData extends IRecordingPeriodGhgCategoryIdentity {
  inputParameterRecordingStructureId: string;
}

interface ICreateActivityMutationData extends IGhgActivityMutationsCommonData {
  data: IBaseActivityData;
}

export const useCreateActivityMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      data,
    }: ICreateActivityMutationData) =>
      CoreApiService.ActivitiesApi.createActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        data,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables, context) =>
      invalidateQueries({
        awaitQueryKeys: [
          getRecordingPeriodGhgCategoriesQueryKey(variables),
          getGhgCategoryActivitiesQueryKey(variables),
        ],
      }),
  });
};

interface IUpdateActivityMutationData extends IGhgActivityIdentity {
  data: IBaseActivityData;
}

export const useUpdateActivityMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
      data,
    }: IUpdateActivityMutationData) =>
      CoreApiService.ActivitiesApi.updateActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
        data,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables, context) =>
      invalidateQueries({
        awaitQueryKeys: [getGhgCategoryActivitiesQueryKey(variables)],
        nonAwaitQueryKeys: [getGhgEvaluationQueryKey(variables)],
      }),
  });
};

export const useDeleteActivityMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
    }: IGhgActivityMutationsCommonData & IGhgActivityIdentity) =>
      CoreApiService.ActivitiesApi.deleteActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables, context) =>
      invalidateQueries({
        awaitQueryKeys: [
          getRecordingPeriodGhgCategoriesQueryKey(variables),
          getGhgCategoryActivitiesQueryKey(variables),
        ],
      }),
  });
};

interface IUpdateActivitySourceMutationData extends IGhgActivityIdentity {
  data: IWithSources;
}

export const useUpdateActivitySourcesMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
      data,
    }: IUpdateActivitySourceMutationData) =>
      CoreApiService.ActivitiesApi.updateActivitySources(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
        data,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables, context) =>
      invalidateQueries({
        awaitQueryKeys: [getGhgCategoryActivitiesQueryKey(variables)],
      }),
  });
};

interface IUpdateActivityStateMutationData extends IGhgActivityIdentity {
  context: IActivityContext;
}

export const useSubmitActivityMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
      context,
    }: IUpdateActivityStateMutationData) =>
      CoreApiService.ActivitiesApi.submitActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
        context,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables, context) =>
      invalidateQueries({
        awaitQueryKeys: [getGhgCategoryActivitiesQueryKey(variables)],
      }),
  });
};

export const useApproveActivityMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
    }: IGhgActivityIdentity) =>
      CoreApiService.ActivitiesApi.approveActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables, context) =>
      invalidateQueries({
        awaitQueryKeys: [getGhgCategoryActivitiesQueryKey(variables)],
      }),
  });
};

export const useRejectActivityMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
    }: IGhgActivityIdentity) =>
      CoreApiService.ActivitiesApi.rejectActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables, context) =>
      invalidateQueries({
        awaitQueryKeys: [getGhgCategoryActivitiesQueryKey(variables)],
      }),
  });
};

export const useResetActivityMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
    }: IGhgActivityIdentity) =>
      CoreApiService.ActivitiesApi.resetActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables, context) =>
      invalidateQueries({
        awaitQueryKeys: [getGhgCategoryActivitiesQueryKey(variables)],
      }),
  });
};
