import { GHG_VERSIONS } from "./versions/ghg-versions";
import { IGhgProtocolScopeEnum } from "@netcero/netcero-core-api-client";

export const GHG_CATEGORIES_BY_SCOPE = {
  [IGhgProtocolScopeEnum.Scope1]: Object.values(GHG_VERSIONS).flatMap(
    (ghgVersion) => ghgVersion[IGhgProtocolScopeEnum.Scope1],
  ),
  [IGhgProtocolScopeEnum.Scope2]: Object.values(GHG_VERSIONS).flatMap(
    (ghgVersion) => ghgVersion[IGhgProtocolScopeEnum.Scope2],
  ),
  [IGhgProtocolScopeEnum.Scope3]: Object.values(GHG_VERSIONS).flatMap(
    (ghgVersion) => ghgVersion[IGhgProtocolScopeEnum.Scope3],
  ),
};
