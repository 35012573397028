import { FC, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { GhgDashboardResult } from "./ghg-dashboard-result.component";
import { GhgDashboardCard } from "./ghg-dashboard-card.component";
import { GhgEvaluationUtilities } from "@netcero/netcero-common";
import { useTranslation } from "react-i18next";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { useGhgDashboardContext } from "../ghg-dashboard.context";
import { EvaluationBase } from "../ghg-evaluation.types";

export const GhgDashboardTotalEmissions: FC = () => {
  const { t } = useTranslation("ghg_protocol");

  const { activeView, relevantEvaluationResultsForDeoTree } = useGhgDashboardContext();

  const { locationBasedTotal, marketBasedTotal } = useMemo(() => {
    return GhgEvaluationUtilities.calculateTotalEmissionsSumInTCO2Eq(
      relevantEvaluationResultsForDeoTree,
    );
  }, [relevantEvaluationResultsForDeoTree]);

  const totalEmissions =
    activeView === EvaluationBase.LocationBased ? locationBasedTotal : marketBasedTotal;

  return (
    <GhgDashboardCard>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center" // Center vertically
        pl={1.5}
      >
        {/* Left side: Label */}
        <Typography variant="h4" component="span">
          {t("evaluation.total_emissions")}
        </Typography>

        {/* Right side: Evaluation value with copy button */}
        <Box display="flex" alignItems="center">
          <GhgDashboardResult
            formattedValue={
              <Typography variant="h3" component="span">
                {FormatUtilities.formatGhgEvaluationResult(totalEmissions)}
              </Typography>
            }
            valueToCopy={totalEmissions.toString()}
          />
        </Box>
      </Box>
    </GhgDashboardCard>
  );
};
