import { QueryClient, useQuery } from "@tanstack/react-query";
import { PhaseTwoApiService } from "../phase-two-api/phase-two-api.service";
import { useKeycloakOrganizationId } from "./hooks/keycloak-organization-id.hook";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { useCallback } from "react";
import { OrganizationRole } from "@netcero/netcero-common";
import { CoreApiService } from "../core-api/core-api.service";

const getSearchUsersQueryKey = (keycloakOrganizationId: string, search?: string) => [
  "users",
  keycloakOrganizationId,
  search,
];

export function useSearchUsersQuery<T = IUserRepresentation[]>(
  keycloakOrganizationId: string | null | undefined,
  search?: string,
  select?: (data: IUserRepresentation[]) => T,
) {
  return useQuery({
    queryKey: getSearchUsersQueryKey(keycloakOrganizationId!, search),
    queryFn: async () => {
      // TODO: Add pagination
      return PhaseTwoApiService.OrganizationMembershipsApi.getOrganizationMemberships(
        PhaseTwoApiService.RealmId,
        keycloakOrganizationId!,
        search,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    enabled: !!keycloakOrganizationId,
    select,
  });
}

export function useSearchUsersQueryInternal<T = IUserRepresentation[]>(
  organizationId: string,
  search?: string,
  select?: (data: IUserRepresentation[]) => T,
) {
  const keycloakOrganizationId = useKeycloakOrganizationId(organizationId);
  return useSearchUsersQuery(keycloakOrganizationId, search, select);
}

export function useGetSingleUserQueryInternal(
  organizationId: string,
  userId: string | undefined | null,
) {
  const selectUser = useCallback(
    (users: IUserRepresentation[]) => users.find((u) => u.id === userId) ?? null,
    [userId],
  );
  return useSearchUsersQueryInternal(organizationId, undefined, selectUser);
}

const getUsersByOrganizationRoleQueryKey = (keycloakOrganizationId: string | null) => [
  "get-users-with-role",
  keycloakOrganizationId,
];

export function useGetUsersByOrganizationRoleQuery(organizationId: string, role: OrganizationRole) {
  const keycloakOrganizationId = useKeycloakOrganizationId(organizationId);
  return useQuery({
    queryKey: [...getUsersByOrganizationRoleQueryKey(keycloakOrganizationId), role],
    queryFn: async () => {
      return PhaseTwoApiService.OrganizationRolesApi.getUserOrganizationRoles(
        PhaseTwoApiService.RealmId,
        keycloakOrganizationId!,
        role,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    enabled: !!keycloakOrganizationId,
  });
}

export async function invalidateUserOrganizationRoles(
  queryClient: QueryClient,
  keycloakOrganizationId: string | null,
) {
  await queryClient.invalidateQueries({
    queryKey: getUsersByOrganizationRoleQueryKey(keycloakOrganizationId),
  });
}

function getUserDataEntryObjectsAccessQueryKey(
  organizationId: string,
  userId: string,
  recordingPeriodId: string | undefined,
) {
  return ["data-entry-objects-access", organizationId, userId, recordingPeriodId];
}

export function useGetUserDataEntryObjectsAccessQuery(
  organizationId: string,
  userId: string,
  recordingPeriodId: string,
  { enabled = true } = {},
) {
  return useQuery({
    queryKey: getUserDataEntryObjectsAccessQueryKey(organizationId, userId, recordingPeriodId),
    queryFn: async () => {
      return CoreApiService.UserApi.getUserDataEntryObjectsAccess(
        organizationId,
        userId,
        recordingPeriodId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    enabled,
  });
}

export async function invalidateUserDataEntryObjectsAccess(
  queryClient: QueryClient,
  organizationId: string,
  userId: string,
  recordingPeriodId: string | undefined,
) {
  await queryClient.invalidateQueries({
    queryKey: getUserDataEntryObjectsAccessQueryKey(organizationId, userId, recordingPeriodId),
  });
}
