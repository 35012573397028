import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { IInputParameterValueMetaDataBoolean } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DataEntryObjectInputParameterValueDefinitionForBoolean } from "@netcero/netcero-common";
import {
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { ICommonInputProps } from "../value-acquisition.types";

type IBooleanInputComponentProps = ICommonInputProps<
  IInputParameterValueMetaDataBoolean,
  DataEntryObjectInputParameterValueDefinitionForBoolean
>;

const optionalBooleanToString = (value?: boolean) => {
  if (value === undefined) {
    return "";
  }

  return value ? "true" : "false";
};

const stringToBoolean = (value: string) => {
  return value === "true";
};

export const useTranslateBooleanInputValue = () => {
  const { t } = useTranslation("boolean_input");

  return (value: string | boolean | undefined) => {
    switch (value) {
      case true:
      case "true":
        return t("value_true");
      case false:
      case "false":
        return t("value_false");
      default:
        return undefined;
    }
  };
};

export const BooleanInputComponent: FC<IBooleanInputComponentProps> = ({
  variant,
  value,
  onChange,
  disabled,
  error,
  disableMaxWidth,
  label,
  required,
  autoFocus,
}) => {
  const translateValue = useTranslateBooleanInputValue();
  const variantProps = useVariantFormProps(variant);
  const stylesSx = useVariantSxStyles(variant);

  return (
    <FormControl
      {...variantProps}
      error={!!error}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 120 : undefined, minWidth: 120 }}
      required={required}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        required={required}
        label={label}
        {...variantProps}
        value={optionalBooleanToString(value)}
        disabled={disabled}
        error={!!error}
        onChange={(e) => onChange(stringToBoolean(e.target.value as string))}
        sx={{ textAlign: !disableMaxWidth ? "right" : undefined, ...stylesSx }}
        autoFocus={autoFocus}
      >
        <MenuItem value="true">{translateValue(true)}</MenuItem>
        <MenuItem value="false">{translateValue(false)}</MenuItem>
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
