/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IApproveRecordingPeriodGhgCategory403Response } from "../models";
// @ts-ignore
import { IContributingUsersData } from "../models";
// @ts-ignore
import { ICreateReferenceBudget400Response } from "../models";
// @ts-ignore
import { ICreateReferenceBudget404Response } from "../models";
// @ts-ignore
import { IExcludeRecordingPeriodGhgCategory403Response } from "../models";
// @ts-ignore
import { IExcludeRecordingPeriodGhgCategory409Response } from "../models";
// @ts-ignore
import { IExcludeRecordingPeriodGhgCategoryData } from "../models";
// @ts-ignore
import { IGetRecordingPeriodGhgCategories404Response } from "../models";
// @ts-ignore
import { IGhgCategoriesIdentifier } from "../models";
// @ts-ignore
import { IGhgEvaluationType } from "../models";
// @ts-ignore
import { IPerformEvaluationForRecordingPeriod200Response } from "../models";
// @ts-ignore
import { IRecordingPeriodGhgCategory } from "../models";
// @ts-ignore
import { IRecordingPeriodGhgCategoryContext } from "../models";
// @ts-ignore
import { IRecordingPeriodGhgCategoryMaterialityData } from "../models";
// @ts-ignore
import { IResponsiblePersonData } from "../models";
// @ts-ignore
import { ISubmitRecordingPeriodGhgCategory404Response } from "../models";
// @ts-ignore
import { ISubmitRecordingPeriodGhgCategory409Response } from "../models";
// @ts-ignore
import { IUpdateRecordingPeriodGhgCategoryContributingUsers403Response } from "../models";
// @ts-ignore
import { IUpdateRecordingPeriodGhgCategoryResponsiblePerson403Response } from "../models";
// @ts-ignore
import { IUpdateSource403Response } from "../models";
/**
 * RecordingPeriodGhgCategoriesApi - axios parameter creator
 * @export
 */
export const RecordingPeriodGhgCategoriesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Used to end the review process by moving the GHG category to \"done\"
     * @summary Used to end the review process by moving the GHG category to \"done\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveRecordingPeriodGhgCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("approveRecordingPeriodGhgCategory", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "approveRecordingPeriodGhgCategory",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("approveRecordingPeriodGhgCategory", "ghgCategoryId", ghgCategoryId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/approve`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to exclude a (recording period) ghg category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IExcludeRecordingPeriodGhgCategoryData} iExcludeRecordingPeriodGhgCategoryData Used to exclude a (recording period) ghg category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excludeRecordingPeriodGhgCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iExcludeRecordingPeriodGhgCategoryData: IExcludeRecordingPeriodGhgCategoryData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("excludeRecordingPeriodGhgCategory", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "excludeRecordingPeriodGhgCategory",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("excludeRecordingPeriodGhgCategory", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'iExcludeRecordingPeriodGhgCategoryData' is not null or undefined
      assertParamExists(
        "excludeRecordingPeriodGhgCategory",
        "iExcludeRecordingPeriodGhgCategoryData",
        iExcludeRecordingPeriodGhgCategoryData,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/exclude`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iExcludeRecordingPeriodGhgCategoryData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @summary Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} recordingStructureId The id of the recording structure for which to filter the categories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecordingPeriodGhgCategories: async (
      organizationId: string,
      recordingPeriodId: string,
      recordingStructureId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getRecordingPeriodGhgCategories", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("getRecordingPeriodGhgCategories", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'recordingStructureId' is not null or undefined
      assertParamExists(
        "getRecordingPeriodGhgCategories",
        "recordingStructureId",
        recordingStructureId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (recordingStructureId !== undefined) {
        localVarQueryParameter["recordingStructureId"] = recordingStructureId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to include a (recording period) ghg category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    includeRecordingPeriodGhgCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("includeRecordingPeriodGhgCategory", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "includeRecordingPeriodGhgCategory",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("includeRecordingPeriodGhgCategory", "ghgCategoryId", ghgCategoryId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/include`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Performs the evaluation for the specified recording period.
     * @summary Performs the evaluation for the specified recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgEvaluationType} type Whether the evaluation should be performed for a recording structure or a category. Required.
     * @param {string} [recordingStructureId] The ID of the recording structure for which the evaluation should be performed. Only required if type is \&quot;for-recording-structure\&quot;.
     * @param {IGhgCategoriesIdentifier} [categoryIdentifier] The identifier of the category for which the evaluation should be performed. This only applies to the sourcing of values, not to the output categories. I.e. if category 1.2 is specified and an EF generates emissions in 3.3 or 3.15, those are still returned! Only required if type is \&quot;for-category\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    performEvaluationForRecordingPeriod: async (
      organizationId: string,
      recordingPeriodId: string,
      type: IGhgEvaluationType,
      recordingStructureId?: string,
      categoryIdentifier?: IGhgCategoriesIdentifier,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("performEvaluationForRecordingPeriod", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "performEvaluationForRecordingPeriod",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'type' is not null or undefined
      assertParamExists("performEvaluationForRecordingPeriod", "type", type);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/evaluation`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (recordingStructureId !== undefined) {
        localVarQueryParameter["recordingStructureId"] = recordingStructureId;
      }

      if (categoryIdentifier !== undefined) {
        localVarQueryParameter["categoryIdentifier"] = categoryIdentifier;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Used to end review process by moving the GHG category back to \"in progress\"
     * @summary Used to end review process by moving the GHG category back to \"in progress\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IRecordingPeriodGhgCategoryContext} iRecordingPeriodGhgCategoryContext Used to end review process by moving the GHG category back to \&quot;in progress\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectRecordingPeriodGhgCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iRecordingPeriodGhgCategoryContext: IRecordingPeriodGhgCategoryContext,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("rejectRecordingPeriodGhgCategory", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("rejectRecordingPeriodGhgCategory", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("rejectRecordingPeriodGhgCategory", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'iRecordingPeriodGhgCategoryContext' is not null or undefined
      assertParamExists(
        "rejectRecordingPeriodGhgCategory",
        "iRecordingPeriodGhgCategoryContext",
        iRecordingPeriodGhgCategoryContext,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/reject`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iRecordingPeriodGhgCategoryContext,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Used to reset the status for an GHG category back to in-progress
     * @summary Used to reset the status for an GHG category back to in-progress
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetRecordingPeriodGhgCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("resetRecordingPeriodGhgCategory", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("resetRecordingPeriodGhgCategory", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("resetRecordingPeriodGhgCategory", "ghgCategoryId", ghgCategoryId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/reset`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint submits the specified GHG category for review.
     * @summary This endpoint submits the specified GHG category for review.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IRecordingPeriodGhgCategoryContext} iRecordingPeriodGhgCategoryContext Used to trigger the review process for a GHG category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitRecordingPeriodGhgCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iRecordingPeriodGhgCategoryContext: IRecordingPeriodGhgCategoryContext,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("submitRecordingPeriodGhgCategory", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("submitRecordingPeriodGhgCategory", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("submitRecordingPeriodGhgCategory", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'iRecordingPeriodGhgCategoryContext' is not null or undefined
      assertParamExists(
        "submitRecordingPeriodGhgCategory",
        "iRecordingPeriodGhgCategoryContext",
        iRecordingPeriodGhgCategoryContext,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/submit`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iRecordingPeriodGhgCategoryContext,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the contributing users of a given GHG Category
     * @summary Updates the contributing users of a given GHG Category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IContributingUsersData} iContributingUsersData Used to update the contributing users of a ghg category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRecordingPeriodGhgCategoryContributingUsers: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iContributingUsersData: IContributingUsersData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryContributingUsers",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryContributingUsers",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryContributingUsers",
        "ghgCategoryId",
        ghgCategoryId,
      );
      // verify required parameter 'iContributingUsersData' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryContributingUsers",
        "iContributingUsersData",
        iContributingUsersData,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/contributing-users`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iContributingUsersData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint modifies the materiality of the specified GHG category.
     * @summary This endpoint modifies the materiality of the specified GHG category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IRecordingPeriodGhgCategoryMaterialityData} iRecordingPeriodGhgCategoryMaterialityData Used to update the materiality of the given GHG category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRecordingPeriodGhgCategoryMateriality: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iRecordingPeriodGhgCategoryMaterialityData: IRecordingPeriodGhgCategoryMaterialityData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryMateriality",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryMateriality",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryMateriality",
        "ghgCategoryId",
        ghgCategoryId,
      );
      // verify required parameter 'iRecordingPeriodGhgCategoryMaterialityData' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryMateriality",
        "iRecordingPeriodGhgCategoryMaterialityData",
        iRecordingPeriodGhgCategoryMaterialityData,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/materiality`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iRecordingPeriodGhgCategoryMaterialityData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the responsible person for a given GHG Category
     * @summary Updates the responsible person for a given GHG Category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IResponsiblePersonData} iResponsiblePersonData Used to update the responsible person of a ghg category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRecordingPeriodGhgCategoryResponsiblePerson: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iResponsiblePersonData: IResponsiblePersonData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryResponsiblePerson",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryResponsiblePerson",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryResponsiblePerson",
        "ghgCategoryId",
        ghgCategoryId,
      );
      // verify required parameter 'iResponsiblePersonData' is not null or undefined
      assertParamExists(
        "updateRecordingPeriodGhgCategoryResponsiblePerson",
        "iResponsiblePersonData",
        iResponsiblePersonData,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/responsible-person`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iResponsiblePersonData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RecordingPeriodGhgCategoriesApi - functional programming interface
 * @export
 */
export const RecordingPeriodGhgCategoriesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RecordingPeriodGhgCategoriesApiAxiosParamCreator(configuration);
  return {
    /**
     * Used to end the review process by moving the GHG category to \"done\"
     * @summary Used to end the review process by moving the GHG category to \"done\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to exclude a (recording period) ghg category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IExcludeRecordingPeriodGhgCategoryData} iExcludeRecordingPeriodGhgCategoryData Used to exclude a (recording period) ghg category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async excludeRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iExcludeRecordingPeriodGhgCategoryData: IExcludeRecordingPeriodGhgCategoryData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.excludeRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        iExcludeRecordingPeriodGhgCategoryData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @summary Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} recordingStructureId The id of the recording structure for which to filter the categories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecordingPeriodGhgCategories(
      organizationId: string,
      recordingPeriodId: string,
      recordingStructureId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: Array<IRecordingPeriodGhgCategory> | undefined }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordingPeriodGhgCategories(
        organizationId,
        recordingPeriodId,
        recordingStructureId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to include a (recording period) ghg category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async includeRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.includeRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Performs the evaluation for the specified recording period.
     * @summary Performs the evaluation for the specified recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgEvaluationType} type Whether the evaluation should be performed for a recording structure or a category. Required.
     * @param {string} [recordingStructureId] The ID of the recording structure for which the evaluation should be performed. Only required if type is \&quot;for-recording-structure\&quot;.
     * @param {IGhgCategoriesIdentifier} [categoryIdentifier] The identifier of the category for which the evaluation should be performed. This only applies to the sourcing of values, not to the output categories. I.e. if category 1.2 is specified and an EF generates emissions in 3.3 or 3.15, those are still returned! Only required if type is \&quot;for-category\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async performEvaluationForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      type: IGhgEvaluationType,
      recordingStructureId?: string,
      categoryIdentifier?: IGhgCategoriesIdentifier,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IPerformEvaluationForRecordingPeriod200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.performEvaluationForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        type,
        recordingStructureId,
        categoryIdentifier,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Used to end review process by moving the GHG category back to \"in progress\"
     * @summary Used to end review process by moving the GHG category back to \"in progress\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IRecordingPeriodGhgCategoryContext} iRecordingPeriodGhgCategoryContext Used to end review process by moving the GHG category back to \&quot;in progress\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rejectRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iRecordingPeriodGhgCategoryContext: IRecordingPeriodGhgCategoryContext,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rejectRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        iRecordingPeriodGhgCategoryContext,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Used to reset the status for an GHG category back to in-progress
     * @summary Used to reset the status for an GHG category back to in-progress
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint submits the specified GHG category for review.
     * @summary This endpoint submits the specified GHG category for review.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IRecordingPeriodGhgCategoryContext} iRecordingPeriodGhgCategoryContext Used to trigger the review process for a GHG category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iRecordingPeriodGhgCategoryContext: IRecordingPeriodGhgCategoryContext,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submitRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        iRecordingPeriodGhgCategoryContext,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates the contributing users of a given GHG Category
     * @summary Updates the contributing users of a given GHG Category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IContributingUsersData} iContributingUsersData Used to update the contributing users of a ghg category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRecordingPeriodGhgCategoryContributingUsers(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iContributingUsersData: IContributingUsersData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateRecordingPeriodGhgCategoryContributingUsers(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          iContributingUsersData,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint modifies the materiality of the specified GHG category.
     * @summary This endpoint modifies the materiality of the specified GHG category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IRecordingPeriodGhgCategoryMaterialityData} iRecordingPeriodGhgCategoryMaterialityData Used to update the materiality of the given GHG category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRecordingPeriodGhgCategoryMateriality(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iRecordingPeriodGhgCategoryMaterialityData: IRecordingPeriodGhgCategoryMaterialityData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateRecordingPeriodGhgCategoryMateriality(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          iRecordingPeriodGhgCategoryMaterialityData,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates the responsible person for a given GHG Category
     * @summary Updates the responsible person for a given GHG Category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IResponsiblePersonData} iResponsiblePersonData Used to update the responsible person of a ghg category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRecordingPeriodGhgCategoryResponsiblePerson(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iResponsiblePersonData: IResponsiblePersonData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateRecordingPeriodGhgCategoryResponsiblePerson(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          iResponsiblePersonData,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * RecordingPeriodGhgCategoriesApi - factory interface
 * @export
 */
export const RecordingPeriodGhgCategoriesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RecordingPeriodGhgCategoriesApiFp(configuration);
  return {
    /**
     * Used to end the review process by moving the GHG category to \"done\"
     * @summary Used to end the review process by moving the GHG category to \"done\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .approveRecordingPeriodGhgCategory(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Used to exclude a (recording period) ghg category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IExcludeRecordingPeriodGhgCategoryData} iExcludeRecordingPeriodGhgCategoryData Used to exclude a (recording period) ghg category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excludeRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iExcludeRecordingPeriodGhgCategoryData: IExcludeRecordingPeriodGhgCategoryData,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .excludeRecordingPeriodGhgCategory(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          iExcludeRecordingPeriodGhgCategoryData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @summary Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} recordingStructureId The id of the recording structure for which to filter the categories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecordingPeriodGhgCategories(
      organizationId: string,
      recordingPeriodId: string,
      recordingStructureId: string,
      options?: any,
    ): AxiosPromise<{ [key: string]: Array<IRecordingPeriodGhgCategory> | undefined }> {
      return localVarFp
        .getRecordingPeriodGhgCategories(
          organizationId,
          recordingPeriodId,
          recordingStructureId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Used to include a (recording period) ghg category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    includeRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .includeRecordingPeriodGhgCategory(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Performs the evaluation for the specified recording period.
     * @summary Performs the evaluation for the specified recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgEvaluationType} type Whether the evaluation should be performed for a recording structure or a category. Required.
     * @param {string} [recordingStructureId] The ID of the recording structure for which the evaluation should be performed. Only required if type is \&quot;for-recording-structure\&quot;.
     * @param {IGhgCategoriesIdentifier} [categoryIdentifier] The identifier of the category for which the evaluation should be performed. This only applies to the sourcing of values, not to the output categories. I.e. if category 1.2 is specified and an EF generates emissions in 3.3 or 3.15, those are still returned! Only required if type is \&quot;for-category\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    performEvaluationForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      type: IGhgEvaluationType,
      recordingStructureId?: string,
      categoryIdentifier?: IGhgCategoriesIdentifier,
      options?: any,
    ): AxiosPromise<IPerformEvaluationForRecordingPeriod200Response> {
      return localVarFp
        .performEvaluationForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          type,
          recordingStructureId,
          categoryIdentifier,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Used to end review process by moving the GHG category back to \"in progress\"
     * @summary Used to end review process by moving the GHG category back to \"in progress\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IRecordingPeriodGhgCategoryContext} iRecordingPeriodGhgCategoryContext Used to end review process by moving the GHG category back to \&quot;in progress\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iRecordingPeriodGhgCategoryContext: IRecordingPeriodGhgCategoryContext,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .rejectRecordingPeriodGhgCategory(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          iRecordingPeriodGhgCategoryContext,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Used to reset the status for an GHG category back to in-progress
     * @summary Used to reset the status for an GHG category back to in-progress
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .resetRecordingPeriodGhgCategory(organizationId, recordingPeriodId, ghgCategoryId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint submits the specified GHG category for review.
     * @summary This endpoint submits the specified GHG category for review.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IRecordingPeriodGhgCategoryContext} iRecordingPeriodGhgCategoryContext Used to trigger the review process for a GHG category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iRecordingPeriodGhgCategoryContext: IRecordingPeriodGhgCategoryContext,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .submitRecordingPeriodGhgCategory(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          iRecordingPeriodGhgCategoryContext,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the contributing users of a given GHG Category
     * @summary Updates the contributing users of a given GHG Category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IContributingUsersData} iContributingUsersData Used to update the contributing users of a ghg category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRecordingPeriodGhgCategoryContributingUsers(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iContributingUsersData: IContributingUsersData,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateRecordingPeriodGhgCategoryContributingUsers(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          iContributingUsersData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint modifies the materiality of the specified GHG category.
     * @summary This endpoint modifies the materiality of the specified GHG category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IRecordingPeriodGhgCategoryMaterialityData} iRecordingPeriodGhgCategoryMaterialityData Used to update the materiality of the given GHG category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRecordingPeriodGhgCategoryMateriality(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iRecordingPeriodGhgCategoryMaterialityData: IRecordingPeriodGhgCategoryMaterialityData,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateRecordingPeriodGhgCategoryMateriality(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          iRecordingPeriodGhgCategoryMaterialityData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the responsible person for a given GHG Category
     * @summary Updates the responsible person for a given GHG Category
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IResponsiblePersonData} iResponsiblePersonData Used to update the responsible person of a ghg category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRecordingPeriodGhgCategoryResponsiblePerson(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iResponsiblePersonData: IResponsiblePersonData,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateRecordingPeriodGhgCategoryResponsiblePerson(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          iResponsiblePersonData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RecordingPeriodGhgCategoriesApi - interface
 * @export
 * @interface RecordingPeriodGhgCategoriesApi
 */
export interface RecordingPeriodGhgCategoriesApiInterface {
  /**
   * Used to end the review process by moving the GHG category to \"done\"
   * @summary Used to end the review process by moving the GHG category to \"done\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  approveRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Used to exclude a (recording period) ghg category
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IExcludeRecordingPeriodGhgCategoryData} iExcludeRecordingPeriodGhgCategoryData Used to exclude a (recording period) ghg category
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  excludeRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iExcludeRecordingPeriodGhgCategoryData: IExcludeRecordingPeriodGhgCategoryData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
   * @summary Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} recordingStructureId The id of the recording structure for which to filter the categories.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  getRecordingPeriodGhgCategories(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<{ [key: string]: Array<IRecordingPeriodGhgCategory> | undefined }>;

  /**
   *
   * @summary Used to include a (recording period) ghg category
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  includeRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Performs the evaluation for the specified recording period.
   * @summary Performs the evaluation for the specified recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgEvaluationType} type Whether the evaluation should be performed for a recording structure or a category. Required.
   * @param {string} [recordingStructureId] The ID of the recording structure for which the evaluation should be performed. Only required if type is \&quot;for-recording-structure\&quot;.
   * @param {IGhgCategoriesIdentifier} [categoryIdentifier] The identifier of the category for which the evaluation should be performed. This only applies to the sourcing of values, not to the output categories. I.e. if category 1.2 is specified and an EF generates emissions in 3.3 or 3.15, those are still returned! Only required if type is \&quot;for-category\&quot;.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  performEvaluationForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    type: IGhgEvaluationType,
    recordingStructureId?: string,
    categoryIdentifier?: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IPerformEvaluationForRecordingPeriod200Response>;

  /**
   * Used to end review process by moving the GHG category back to \"in progress\"
   * @summary Used to end review process by moving the GHG category back to \"in progress\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IRecordingPeriodGhgCategoryContext} iRecordingPeriodGhgCategoryContext Used to end review process by moving the GHG category back to \&quot;in progress\&quot;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  rejectRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iRecordingPeriodGhgCategoryContext: IRecordingPeriodGhgCategoryContext,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Used to reset the status for an GHG category back to in-progress
   * @summary Used to reset the status for an GHG category back to in-progress
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  resetRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * This endpoint submits the specified GHG category for review.
   * @summary This endpoint submits the specified GHG category for review.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IRecordingPeriodGhgCategoryContext} iRecordingPeriodGhgCategoryContext Used to trigger the review process for a GHG category.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  submitRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iRecordingPeriodGhgCategoryContext: IRecordingPeriodGhgCategoryContext,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Updates the contributing users of a given GHG Category
   * @summary Updates the contributing users of a given GHG Category
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IContributingUsersData} iContributingUsersData Used to update the contributing users of a ghg category
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  updateRecordingPeriodGhgCategoryContributingUsers(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iContributingUsersData: IContributingUsersData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * This endpoint modifies the materiality of the specified GHG category.
   * @summary This endpoint modifies the materiality of the specified GHG category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IRecordingPeriodGhgCategoryMaterialityData} iRecordingPeriodGhgCategoryMaterialityData Used to update the materiality of the given GHG category.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  updateRecordingPeriodGhgCategoryMateriality(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iRecordingPeriodGhgCategoryMaterialityData: IRecordingPeriodGhgCategoryMaterialityData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Updates the responsible person for a given GHG Category
   * @summary Updates the responsible person for a given GHG Category
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IResponsiblePersonData} iResponsiblePersonData Used to update the responsible person of a ghg category
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  updateRecordingPeriodGhgCategoryResponsiblePerson(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iResponsiblePersonData: IResponsiblePersonData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;
}

/**
 * RecordingPeriodGhgCategoriesApi - object-oriented interface
 * @export
 * @class RecordingPeriodGhgCategoriesApi
 * @extends {BaseAPI}
 */
export class RecordingPeriodGhgCategoriesApi
  extends BaseAPI
  implements RecordingPeriodGhgCategoriesApiInterface
{
  /**
   * Used to end the review process by moving the GHG category to \"done\"
   * @summary Used to end the review process by moving the GHG category to \"done\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public approveRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .approveRecordingPeriodGhgCategory(organizationId, recordingPeriodId, ghgCategoryId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to exclude a (recording period) ghg category
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IExcludeRecordingPeriodGhgCategoryData} iExcludeRecordingPeriodGhgCategoryData Used to exclude a (recording period) ghg category
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public excludeRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iExcludeRecordingPeriodGhgCategoryData: IExcludeRecordingPeriodGhgCategoryData,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .excludeRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        iExcludeRecordingPeriodGhgCategoryData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
   * @summary Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} recordingStructureId The id of the recording structure for which to filter the categories.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public getRecordingPeriodGhgCategories(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureId: string,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .getRecordingPeriodGhgCategories(
        organizationId,
        recordingPeriodId,
        recordingStructureId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to include a (recording period) ghg category
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public includeRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .includeRecordingPeriodGhgCategory(organizationId, recordingPeriodId, ghgCategoryId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Performs the evaluation for the specified recording period.
   * @summary Performs the evaluation for the specified recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgEvaluationType} type Whether the evaluation should be performed for a recording structure or a category. Required.
   * @param {string} [recordingStructureId] The ID of the recording structure for which the evaluation should be performed. Only required if type is \&quot;for-recording-structure\&quot;.
   * @param {IGhgCategoriesIdentifier} [categoryIdentifier] The identifier of the category for which the evaluation should be performed. This only applies to the sourcing of values, not to the output categories. I.e. if category 1.2 is specified and an EF generates emissions in 3.3 or 3.15, those are still returned! Only required if type is \&quot;for-category\&quot;.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public performEvaluationForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    type: IGhgEvaluationType,
    recordingStructureId?: string,
    categoryIdentifier?: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .performEvaluationForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        type,
        recordingStructureId,
        categoryIdentifier,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Used to end review process by moving the GHG category back to \"in progress\"
   * @summary Used to end review process by moving the GHG category back to \"in progress\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IRecordingPeriodGhgCategoryContext} iRecordingPeriodGhgCategoryContext Used to end review process by moving the GHG category back to \&quot;in progress\&quot;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public rejectRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iRecordingPeriodGhgCategoryContext: IRecordingPeriodGhgCategoryContext,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .rejectRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        iRecordingPeriodGhgCategoryContext,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Used to reset the status for an GHG category back to in-progress
   * @summary Used to reset the status for an GHG category back to in-progress
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public resetRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .resetRecordingPeriodGhgCategory(organizationId, recordingPeriodId, ghgCategoryId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint submits the specified GHG category for review.
   * @summary This endpoint submits the specified GHG category for review.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IRecordingPeriodGhgCategoryContext} iRecordingPeriodGhgCategoryContext Used to trigger the review process for a GHG category.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public submitRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iRecordingPeriodGhgCategoryContext: IRecordingPeriodGhgCategoryContext,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .submitRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        iRecordingPeriodGhgCategoryContext,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the contributing users of a given GHG Category
   * @summary Updates the contributing users of a given GHG Category
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IContributingUsersData} iContributingUsersData Used to update the contributing users of a ghg category
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public updateRecordingPeriodGhgCategoryContributingUsers(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iContributingUsersData: IContributingUsersData,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .updateRecordingPeriodGhgCategoryContributingUsers(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        iContributingUsersData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint modifies the materiality of the specified GHG category.
   * @summary This endpoint modifies the materiality of the specified GHG category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IRecordingPeriodGhgCategoryMaterialityData} iRecordingPeriodGhgCategoryMaterialityData Used to update the materiality of the given GHG category.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public updateRecordingPeriodGhgCategoryMateriality(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iRecordingPeriodGhgCategoryMaterialityData: IRecordingPeriodGhgCategoryMaterialityData,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .updateRecordingPeriodGhgCategoryMateriality(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        iRecordingPeriodGhgCategoryMaterialityData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the responsible person for a given GHG Category
   * @summary Updates the responsible person for a given GHG Category
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IResponsiblePersonData} iResponsiblePersonData Used to update the responsible person of a ghg category
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public updateRecordingPeriodGhgCategoryResponsiblePerson(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iResponsiblePersonData: IResponsiblePersonData,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .updateRecordingPeriodGhgCategoryResponsiblePerson(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        iResponsiblePersonData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
