import { FC } from "react";
import { Box, Tooltip, TooltipProps } from "@mui/material";
import {
  IRenderEmissionFactorUnitHookOptions,
  useRenderEmissionFactorUnit,
} from "../emission-factors.hooks";
import { IEmissionFactorUnit } from "@netcero/netcero-core-api-client";
import { IRenderEmissionFactorUnitType } from "@netcero/netcero-common";

interface IEmissionFactorUnitNameProps {
  unit: IEmissionFactorUnit;
  type?: IRenderEmissionFactorUnitType;
  options?: IRenderEmissionFactorUnitHookOptions;
}

export const EmissionFactorUnitName: FC<IEmissionFactorUnitNameProps> = ({
  unit,
  type,
  options,
}) => {
  const renderEmissionFactorUnit = useRenderEmissionFactorUnit();
  return renderEmissionFactorUnit(unit, type, options);
};

type IEmissionFactorUnitNameShortWithTooltipProps = Omit<IEmissionFactorUnitNameProps, "type"> & {
  tooltipProps?: Omit<TooltipProps, "title" | "children">;
};
export const EmissionFactorUnitNameShortWithTooltip: FC<
  IEmissionFactorUnitNameShortWithTooltipProps
> = ({ unit, options, tooltipProps }) => {
  return (
    <Tooltip
      title={<EmissionFactorUnitName unit={unit} type="long" options={options} />}
      {...tooltipProps}
    >
      <Box component="span">
        <EmissionFactorUnitName unit={unit} type="short" options={options} />
      </Box>
    </Tooltip>
  );
};
