import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../../core-api/core-api.service";
import { IGetTargetPathProgressResponse } from "@netcero/netcero-core-api-client";
import { getTargetPathsProgressByRecordingStructureIds } from "../../../pages/target-paths/target-paths-demo-data.constants";

export enum ReferenceRevenueIn {
  Euros = "euros",
  EuroCents = "euro-cents",
}

export const getTargetPathsQueryKey = (organizationId: string) => ["target-paths", organizationId];

export const getTargetPathProgressQueryKey = (
  organizationId: string,
  recordingStructureIds: string[],
) => ["target-paths", organizationId, "progress", ...[...recordingStructureIds].sort()];

/**
 * Fetches all target paths for a given organization
 * @param organizationId
 * @param enabled
 * @param referenceRevenueIn
 */
export const useTargetPathsQuery = (
  organizationId: string,
  enabled = true,
  referenceRevenueIn: ReferenceRevenueIn = ReferenceRevenueIn.EuroCents,
) => {
  return useQuery({
    queryKey: getTargetPathsQueryKey(organizationId),
    queryFn: async () =>
      CoreApiService.TargetPathsApi.getTargetPathsForOrganization(organizationId)
        .then((req) => req())
        .then((res) => res.data),
    select: (data) => {
      if (referenceRevenueIn === ReferenceRevenueIn.EuroCents) {
        return data;
      }
      return {
        targetPaths: data.targetPaths.map((t) => ({
          ...t,
          referenceBudget: {
            ...t.referenceBudget,
            referenceRevenue:
              t.referenceBudget.referenceRevenue !== undefined
                ? t.referenceBudget.referenceRevenue / 100
                : undefined,
          },
        })),
      };
    },
    enabled,
  });
};

export const useTargetPathProgressQuery = (
  organizationId: string,
  recordingStructureIds: string[],
) => {
  return useQuery({
    queryKey: getTargetPathProgressQueryKey(organizationId, recordingStructureIds),
    queryFn: async () => {
      // if (recordingStructureIds.length === 0) {
      //   return { progress: {} } as IGetTargetPathProgressResponse;
      // }
      // return CoreApiService.TargetPathsApi.getTargetPathProgressForRecordingStructures(
      //   organizationId,
      //   recordingStructureIds,
      // )
      //   .then((req) => req())
      //   .then((res) => res.data);

      // NC-2121: Temporary solution to allow for target Paths etc. to still be demoed
      return new Promise<IGetTargetPathProgressResponse>((resolve) => {
        setTimeout(() => {
          resolve(getTargetPathsProgressByRecordingStructureIds(recordingStructureIds));
        }, 250);
      });
    },
  });
};
