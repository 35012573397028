import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { TableCell, Typography } from "@mui/material";

interface IHeadingCellWithOptionalTextProps {
  text: ReactNode;
}

export const HeadingCellWithOptionalText: FC<IHeadingCellWithOptionalTextProps> = ({ text }) => {
  return (
    <TableCell>
      {text}
      <br />
      <OptionalBadge />
    </TableCell>
  );
};

export const OptionalBadge: FC = () => {
  const { t } = useTranslation("common");
  return (
    <Typography variant="inherit" fontWeight="normal" fontSize={11} lineHeight={0.5}>
      {t("optional")}
    </Typography>
  );
};
