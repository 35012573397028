import { FC } from "react";
import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";
import { Alert } from "@mui/material";
import { useGhgActivityReappropriatedText } from "../hooks/ghg-activity-reappropriated-text.hook";

interface IGhgActivityReappropriatedAlertProps {
  reapporpriatedTo: IGhgCategoriesIdentifier;
}

export const GhgActivityReappropriatedAlert: FC<IGhgActivityReappropriatedAlertProps> = ({
  reapporpriatedTo,
}) => {
  const getReappropriatedText = useGhgActivityReappropriatedText();
  return <Alert severity="warning">{getReappropriatedText(reapporpriatedTo)}</Alert>;
};
