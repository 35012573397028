import { IRecordingPeriodIdentity, OrganizationRole } from "@netcero/netcero-common";
import { useUserContext } from "../../user/user.context";
import { useAuthenticatedUserOrganizationRole } from "../../user/hooks/authenticated-user-org-role.hook";
import { useGetUserDataEntryObjectsAccessQuery } from "../../user/users.queries";
import { useCallback } from "react";

/**
 * Hook to check if the authenticated user has access to a specific data entry object within a recording period.
 * Will not consider the user's role, but only whether the user has access to the object.
 * You may rather use the hook `useHasUserDataEntryObjectAccess` which will consider business logic for the user's role. (exa Object Admins have access to all DEOs)
 * @param organizationId
 * @param recordingPeriodId
 */
export function useHasObjectAdminDataEntryObjectAccess({
  organizationId,
  recordingPeriodId,
}: IRecordingPeriodIdentity) {
  const { user } = useUserContext();
  const authenticatedUserId = user?.jwtPayload.sub ?? "";
  const authenticatedUserOrgRole = useAuthenticatedUserOrganizationRole();

  const { data } = useGetUserDataEntryObjectsAccessQuery(
    organizationId,
    authenticatedUserId,
    recordingPeriodId,
    {
      enabled: !!authenticatedUserId && authenticatedUserOrgRole === OrganizationRole.OBJECT_ADMIN,
    },
  );
  return useCallback(
    (dataEntryObjectId: string) => !!data?.dataEntryObjectIds.includes(dataEntryObjectId),
    [data?.dataEntryObjectIds],
  );
}
