import React, { FC } from "react";
import { IGhgEvaluationModeConfiguration, useGhgEvaluationQuery } from "../ghg-evaluation.queries";
import { IRecordingPeriodIdentity } from "@netcero/netcero-common";
import { IPerformEvaluationForRecordingPeriod200Response } from "@netcero/netcero-core-api-client";
import { Box, Skeleton, Typography, TypographyProps } from "@mui/material";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { GhgDashboardResult } from "./ghg-dashboard-result.component";

interface IGhgEmissionsSumChipProps {
  recordingPeriodIdentity: IRecordingPeriodIdentity;
  evaluationConfiguration: IGhgEvaluationModeConfiguration;
  shouldComputeScope3Values: boolean;
  selectSum: (response: IPerformEvaluationForRecordingPeriod200Response) => number;
  fontSize: number;
  typographyProps?: TypographyProps;
}

export const GhgEmissionsSumChip: FC<IGhgEmissionsSumChipProps> = ({
  recordingPeriodIdentity,
  evaluationConfiguration,
  selectSum,
  fontSize,
  typographyProps,
}) => {
  // Sum of Emissions
  const evaluationQuery = useGhgEvaluationQuery(
    recordingPeriodIdentity,
    evaluationConfiguration,
    selectSum,
  );

  return (
    <QueryWrapper
      query={evaluationQuery}
      loadingOverride={() => (
        // Skeleton for loading state - fontSize * 1.5 is to achieve same height as the GhgDashboardResults text
        <Skeleton variant="rounded" height={fontSize * 1.5} width={100} sx={{ borderRadius: 4 }} />
      )}
      errorOverride={() => null}
      build={(totalSum) => (
        <Box display="flex" alignItems="center" gap={0.5}>
          <GhgDashboardResult
            formattedValue={
              <Typography fontSize={fontSize} {...typographyProps}>
                {FormatUtilities.formatGhgEvaluationResult(totalSum)}
              </Typography>
            }
            valueToCopy={totalSum.toString()}
          />
        </Box>
      )}
    />
  );
};
