import { IContentLanguage, IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";

export const GHG_CATEGORIES_TRANSLATIONS_DE: Record<
  IGhgCategoriesIdentifier,
  { name: string; description: string }
> = {
  [IGhgCategoriesIdentifier._11StationaryCombustion]: {
    name: "Stationäre Verbrennung",
    description:
      "Emissionen aus der Verbrennung von Brennstoffen in unternehmenseigenen oder kontrollierten stationären Quellen, wie z. B. Kessel, Öfen, Generatoren und Industrieanlagen.",
  },
  [IGhgCategoriesIdentifier._12MobileCombustion]: {
    name: "Mobile Verbrennung",
    description:
      "Emissionen aus der Verbrennung von Brennstoffen in unternehmenseigenen oder kontrollierten mobilen Quellen, einschließlich Fahrzeuge, Maschinen, Flugzeuge, Schiffe und Züge.",
  },
  [IGhgCategoriesIdentifier._13FugitiveEmissions]: {
    name: "Flüchtige Emissionen",
    description:
      "Emissionen aus unbeabsichtigten Leckagen oder Freisetzungen von Treibhausgasen aus unternehmenseigenen oder kontrollierten Quellen, einschließlich Kälte- und Klimaanlagen, Gasleitungen und Industrieprozessen.",
  },
  [IGhgCategoriesIdentifier._14OtherEmissions]: {
    name: "Sonstige Emissionen",
    description:
      "Sonstige Scope-1-Emissionen, die nicht in anderen Kategorien erfasst sind, wie Emissionen aus chemischen Prozessen.",
  },

  [IGhgCategoriesIdentifier._21PurchasedElectricity]: {
    name: "Eingekaufter Strom",
    description:
      "Emissionen aus der Erzeugung von eingekauften Strom, der von der berichtenden Organisation verbraucht wird.",
  },
  [IGhgCategoriesIdentifier._22PurchasedHeat]: {
    name: "Eingekaufte Wärme",
    description:
      "Emissionen aus der Erzeugung eingekaufter Wärme, die von der berichtenden Organisation genutzt wird.",
  },
  [IGhgCategoriesIdentifier._23PurchasedSteam]: {
    name: "Eingekaufter Dampf",
    description:
      "Emissionen aus der Erzeugung eingekauften Dampfes, der von der berichtenden Organisation genutzt wird",
  },
  [IGhgCategoriesIdentifier._24PurchasedCooling]: {
    name: "Eingekaufte Kälte",
    description:
      "Emissionen aus der Erzeugung eingekaufter Kälte (z. B. gekühltes Wasser), die von der berichtenden Organisation genutzt wird.",
  },

  [IGhgCategoriesIdentifier._31PurchasedGoodsAndServices]: {
    name: "Eingekaufte Waren und Dienstleistungen",
    description:
      "Emissionen im Zusammenhang mit der Herstellung von Waren und Dienstleistungen, die von der berichtenden Organisation eingekauft wurden.",
  },
  [IGhgCategoriesIdentifier._32CapitalGoods]: {
    name: "Kapitalgüter",
    description:
      "Emissionen aus der Herstellung von Anlagegütern wie Gebäuden, Maschinen und Geräten, die von der berichtenden Organisation eingekauft wurden.",
  },
  [IGhgCategoriesIdentifier._33EnergyRelatedActivitiesNotInScope1And2]: {
    name: "Brennstoff- und energiebezogene Aktivitäten",
    description:
      "Erfasst vorgelagerte Emissionen aus der Brennstoff- und Stromlieferkette, die nicht bereits in Scope 1 oder 2 berücksichtigt wurden. Dazu gehören Emissionen aus Förderung, Produktion, Transport, Netzverlusten sowie weiterverkaufter Energie (relevant für Versorger und Energiehändler)." +
      "<br/><br/>" +
      "<i><b>Hinweis:</b> NetCero berechnet Scope-3.3-Emissionen automatisch für Aktivitäten, die in Scope 1 & 2 erfasst wurden – sofern keine benutzerdefinierten Emissionsfaktoren verwendet wurden. " +
      "Diese Emissionen sind in der Dashboard-Ansicht einsehbar.</i>",
  },
  [IGhgCategoriesIdentifier._34UpstreamTransportationAndDistribution]: {
    name: "Vorgelagerter Transport und Distribution",
    description:
      "Emissionen aus dem Transport und der Verteilung von eingekauften Waren, einschließlich Logistikanbietern.",
  },
  [IGhgCategoriesIdentifier._35Waste]: {
    name: "Abfall",
    description:
      "Emissionen aus der Behandlung und Entsorgung von Abfällen, die von der Organisation erzeugt werden, einschließlich Deponierung und Verbrennung.",
  },
  [IGhgCategoriesIdentifier._36BusinessTravel]: {
    name: "Geschäftsreisen",
    description:
      "Emissionen aus Geschäftsreisen von Mitarbeitern, einschließlich Flügen, Mietwagen und Hotelaufenthalten.",
  },
  [IGhgCategoriesIdentifier._37EmployeeCommuting]: {
    name: "Arbeitswege der Mitarbeiter (Pendeln)",
    description:
      "Emissionen aus dem Pendeln von Mitarbeitern zwischen Wohnort und Arbeitsplatz mit privaten Fahrzeugen oder öffentlichen Verkehrsmitteln.",
  },
  [IGhgCategoriesIdentifier._38LeasedOrRentedAssets]: {
    name: "Geleaste Sachanlagen (vorgelagert)",
    description:
      "Emissionen aus Vermögenswerten, die von der berichtenden Organisation geleast oder gemietet werden, wenn eine betriebliche Kontrolle besteht.",
  },
  [IGhgCategoriesIdentifier._39DownstreamTransportationAndDistribution]: {
    name: "Nachgelagerter Transport und Distribution",
    description:
      "Emissionen aus dem Transport und der Verteilung verkaufter Produkte in Fahrzeugen und Einrichtungen, die nicht der Organisation gehören oder von ihr kontrolliert werden.",
  },
  [IGhgCategoriesIdentifier._310ProcessingOfSoldProducts]: {
    name: "Verarbeitung verkaufter Produkte",
    description:
      "Emissionen aus der Verarbeitung verkaufter Produkte durch nachgelagerte Organisationen.",
  },
  [IGhgCategoriesIdentifier._311UseOfSoldProducts]: {
    name: "Nutzung verkaufter Produkte",
    description:
      "Emissionen, die durch die Nutzung der von der Organisation verkauften Produkte entstehen, wie z. B. durch Endverbraucher verbrannte Brennstoffe.",
  },
  [IGhgCategoriesIdentifier._312EndOfLifeTreatmentOfSoldProducts]: {
    name: "End-of-Life Treatment verkaufter Produkte",
    description:
      "Emissionen aus der Abfallbehandlung und -entsorgung der von der Organisation verkauften Produkte.",
  },
  [IGhgCategoriesIdentifier._313LeasedOrRentedAssetsDownstream]: {
    name: "Verleaste Sachanlagen (nachgelagert)",
    description:
      "Emissionen aus nachgelagerten Vermögenswerten, die an andere Einheiten geleast oder vermietet werden, ausgenommen solche unter betrieblicher Kontrolle.",
  },
  [IGhgCategoriesIdentifier._314Franchise]: {
    name: "Franchise",
    description:
      "Emissionen aus den Tätigkeiten von Franchise-Nehmern, die nicht unter der direkten Kontrolle der berichtenden Organisation stehen.",
  },
  [IGhgCategoriesIdentifier._315Investments]: {
    name: "Investitionen",
    description:
      "Emissionen im Zusammenhang mit Investitionen, einschließlich Eigenkapital, Fremdkapital und Projektfinanzierung." +
      "<br/><br/>" +
      "<i><b>Hinweis:</b> NetCero konsolidiert Scope-3.15-Emissionen automatisch für alle Aktivitäten, die Erfassungsobjekten ohne operativer Kontrolle zugewiesen wurden. " +
      "Diese Emissionen sind in der Dashboard-Ansicht einsehbar.</i>",
  },
};

export const GHG_CATEGORIES_TRANSLATIONS_EN: typeof GHG_CATEGORIES_TRANSLATIONS_DE = {
  [IGhgCategoriesIdentifier._11StationaryCombustion]: {
    name: "Stationary Combustion",
    description:
      "Emissions from burning fuels in company-owned or controlled stationary sources, such as boilers, furnaces, generators, and industrial equipment.",
  },
  [IGhgCategoriesIdentifier._12MobileCombustion]: {
    name: "Mobile Combustion",
    description:
      "Emissions from fuel burned in company-owned or controlled mobile sources, including vehicles, machinery, aircraft, ships, and trains.",
  },
  [IGhgCategoriesIdentifier._13FugitiveEmissions]: {
    name: "Fugitive Emissions",
    description:
      "Emissions from unintended leaks or releases of greenhouse gases from company-owned or controlled sources, including refrigeration and air conditioning systems, gas pipelines, and industrial processes.",
  },
  [IGhgCategoriesIdentifier._14OtherEmissions]: {
    name: "Other Emissions",
    description:
      "Miscellaneous Scope 1 emissions not captured in other categories, such as emissions from chemical processes.",
  },
  [IGhgCategoriesIdentifier._21PurchasedElectricity]: {
    name: "Purchased Electricity",
    description:
      "Emissions from the generation of purchased electricity consumed by the reporting organization.",
  },
  [IGhgCategoriesIdentifier._22PurchasedHeat]: {
    name: "Purchased Heat",
    description:
      "Emissions from the generation of purchased heat used by the reporting organization.",
  },
  [IGhgCategoriesIdentifier._23PurchasedSteam]: {
    name: "Purchased Steam",
    description:
      "Emissions from the generation of purchased steam used by the reporting organization.",
  },
  [IGhgCategoriesIdentifier._24PurchasedCooling]: {
    name: "Purchased Cooling",
    description:
      "Emissions from the generation of purchased cooling (e.g., chilled water) consumed by the reporting organization.",
  },
  [IGhgCategoriesIdentifier._31PurchasedGoodsAndServices]: {
    name: "Purchased Goods and Services",
    description:
      "Emissions associated with the production of goods and services purchased by the reporting organization.",
  },
  [IGhgCategoriesIdentifier._32CapitalGoods]: {
    name: "Capital Goods",
    description:
      "Emissions from the production of capital goods, such as buildings, machinery, and equipment purchased by the reporting organization.",
  },
  [IGhgCategoriesIdentifier._33EnergyRelatedActivitiesNotInScope1And2]: {
    name: "Fuel- and Energy-Related Activities",
    description:
      "Covers upstream emissions from fuel and electricity supply chains not already accounted for in Scope 1 or 2. " +
      "This includes emissions from extraction, production, transport, grid losses, and purchased energy resold to end users (relevant for utilities and energy retailers)." +
      "<br/><br/>" +
      "<i><b>Note:</b> NetCero automatically calculates Scope 3.3 emissions for activities reported in Scope 1 & 2—unless custom emission factors are used. These emissions are shown in the Dashboard view.</i>",
  },
  [IGhgCategoriesIdentifier._34UpstreamTransportationAndDistribution]: {
    name: "Upstream Transportation and Distribution",
    description:
      "Emissions from the transportation and distribution of goods purchased by the organization, including logistics providers.",
  },
  [IGhgCategoriesIdentifier._35Waste]: {
    name: "Waste Generated in Operations",
    description:
      "Emissions from the treatment and disposal of waste generated by the organization, including landfilling and incineration.",
  },
  [IGhgCategoriesIdentifier._36BusinessTravel]: {
    name: "Business Travel",
    description:
      "Emissions from employee travel for business purposes, including flights, car rentals, and hotel stays.",
  },
  [IGhgCategoriesIdentifier._37EmployeeCommuting]: {
    name: "Employee Commuting",
    description:
      "Emissions from employees traveling to and from work using private vehicles or public transportation.",
  },
  [IGhgCategoriesIdentifier._38LeasedOrRentedAssets]: {
    name: "Upstream Leased Assets",
    description:
      "Emissions from assets leased or rented by the reporting organization, where operational control exists.",
  },
  [IGhgCategoriesIdentifier._39DownstreamTransportationAndDistribution]: {
    name: "Downstream Transportation and Distribution",
    description:
      "Emissions from the transportation and distribution of sold products in vehicles and facilities not owned or controlled by the organization.",
  },
  [IGhgCategoriesIdentifier._310ProcessingOfSoldProducts]: {
    name: "Processing of Sold Products",
    description: "Emissions from the processing of sold products by downstream organizations.",
  },
  [IGhgCategoriesIdentifier._311UseOfSoldProducts]: {
    name: "Use of Sold Products",
    description:
      "Emissions arising from the use of products sold by the organization, such as fuels combusted by the end user.",
  },
  [IGhgCategoriesIdentifier._312EndOfLifeTreatmentOfSoldProducts]: {
    name: "End-of-Life Treatment of Sold Products",
    description:
      "Emissions from the waste treatment and disposal of products sold by the organization.",
  },
  [IGhgCategoriesIdentifier._313LeasedOrRentedAssetsDownstream]: {
    name: "Downstream Leased Assets",
    description:
      "Emissions from downstream assets leased or rented to other entities, excluding those under operational control.",
  },
  [IGhgCategoriesIdentifier._314Franchise]: {
    name: "Franchises",
    description:
      "Emissions from operations of franchisees not under the direct control of the reporting organization.",
  },
  [IGhgCategoriesIdentifier._315Investments]: {
    name: "Investments",
    description:
      "Emissions associated with investments, including equity, debt, and project financing." +
      "<br/><br/>" +
      "<i><b>Note:</b> NetCero automatically konsolidates Scope 3.15 emissions for activities assigned to entities without operational control. These emissions are shown in the Dashboard view.</i>",
  },
};

export const GHG_CATEGORY_TRANSLATIONS_PER_LANGUAGE: Record<
  IContentLanguage,
  typeof GHG_CATEGORIES_TRANSLATIONS_DE
> = {
  [IContentLanguage.De]: GHG_CATEGORIES_TRANSLATIONS_DE,
  [IContentLanguage.En]: GHG_CATEGORIES_TRANSLATIONS_EN,
};
