import { useMemo } from "react";
import { IInputParameterRecordingStructureIdentity } from "@netcero/netcero-common";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";

interface IEvaluationLinkHookProps {
  organizationId: string;
  recordingPeriodId: string;
  recordingStructureId: string;
}

export const useEvaluationLink = ({
  organizationId,
  recordingPeriodId,
  recordingStructureId,
}: IEvaluationLinkHookProps) => {
  return useMemo(() => {
    const identity: IInputParameterRecordingStructureIdentity = {
      organizationId,
      recordingPeriodId,
      inputParameterRecordingStructureId: recordingStructureId,
    };

    return AppInternalNavigationUtilities.getGhgProtocolDashboardPageUrl(identity);
  }, [organizationId, recordingPeriodId, recordingStructureId]);
};
