import {
  DeferredPromise,
  IActivityValuesImportResultWithErrors,
  IActivityValuesImportSimpleValueRowDataWithEmissionFactor,
} from "@netcero/netcero-common";
import GhgActivityValuesImportWebWorker from "./ghg-activity-values-import.web-worker?worker";

export class GhgActivityValuesImportWebWorkerUtilities {
  static async runImport(
    data: ArrayBuffer,
  ): Promise<
    IActivityValuesImportResultWithErrors<
      IActivityValuesImportSimpleValueRowDataWithEmissionFactor[]
    >
  > {
    const worker = new GhgActivityValuesImportWebWorker();
    const deferredPromise = new DeferredPromise<
      IActivityValuesImportResultWithErrors<
        IActivityValuesImportSimpleValueRowDataWithEmissionFactor[]
      >
    >();
    // Handle messages from the worker
    worker.onmessage = (event) => {
      const { result, errors } = event.data;
      deferredPromise.resolve({ result, errors });
      worker.terminate();
    };
    // Handle errors from the worker
    worker.onerror = (error) => {
      console.error("Worker error:", error);
      deferredPromise.reject(error);
      worker.terminate();
    };
    // Send the data to the worker and start the import process
    worker.postMessage(data);

    return deferredPromise.promise;
  }
}
