import { OrganizationRole } from "@netcero/netcero-common";
import { TFunction } from "i18next";

export class OrganizationRoleUtilities {
  public static translationKeyByOrganizationRole: Record<OrganizationRole, string> = {
    [OrganizationRole.ORGANIZATION_ADMIN]: "organization_admin",
    [OrganizationRole.OBJECT_ADMIN]: "object_admin",
    [OrganizationRole.VIEW_MEMBERS]: "view_members",
  };

  public static descriptionKeyByOrganizationRole: Record<OrganizationRole, string> = {
    [OrganizationRole.ORGANIZATION_ADMIN]: "organization_admin_description",
    [OrganizationRole.OBJECT_ADMIN]: "object_admin_description",
    [OrganizationRole.VIEW_MEMBERS]: "view_members_description",
  };

  public static getOrganizationRoleTranslation(role: OrganizationRole, t: TFunction): string {
    return t(OrganizationRoleUtilities.translationKeyByOrganizationRole[role], { ns: "roles" });
  }

  public static getOrganizationRoleDescription(role: OrganizationRole, t: TFunction): string {
    return t(OrganizationRoleUtilities.descriptionKeyByOrganizationRole[role], { ns: "roles" });
  }
}
