import { useMemo } from "react";
import { useUserContext } from "../../user/user.context";
import { useDoesUserHaveRole } from "../../authentication/hooks/does-user-have-role.hook";
import { ROLE_ACCESS } from "@netcero/netcero-common";
import { IWithUsers } from "@netcero/netcero-core-api-client";

// NC-1944: Remove once actual permission management is implemented
/**
 * Filters PTA entities (Policies, Targets, Actions) based on user access.
 * Org Admin users see all entities; other users only see entities where they are assigned.
 *
 * @param entities - Array of PTA entities that extend IWithUsers.
 * @returns Filtered array of entities.
 */
export const useFilteredPTAsAccess = <T extends IWithUsers>(entities: T[]): T[] => {
  const { user } = useUserContext();
  const isUserAllowedToViewAllPTA = useDoesUserHaveRole(ROLE_ACCESS.ALLOWED_TO_ACCESS_ALL_PTA);
  const userId = user?.jwtPayload.sub;

  return useMemo(() => {
    return entities.filter((entity) => {
      return (
        isUserAllowedToViewAllPTA ||
        (userId &&
          (entity.responsibleUserId === userId || entity.contributingUserIds.includes(userId)))
      );
    });
  }, [entities, isUserAllowedToViewAllPTA, userId]);
};
