import { Box, Menu, SvgIcon } from "@mui/material";
import { FC, useRef, useState } from "react";
import { useRecordingPeriodOrganizationStructureQuery } from "../../../modules/organization-structures/organization-structures.queries";
import { ILocalRecordingPeriod } from "../../../modules/recording-periods/recording-periods.utilities";
import { QueriesWrapper } from "../../../modules/common/components/queries-wrapper.component";
import { TopBarPicker } from "./top-bar-picker.component";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "../constants/tabler-icon.constants";
import { OrganizationOctagonIcon } from "../constants/iconify-icon.constants";
import { DataEntryObjectsAppUtilities } from "../../data-entry-objects/data-entry-objects-app.utilities";
import { OrganizationStructureDraftWrapper } from "../../organization-structures/organization-structure-draft-wrapper.component";
import { TOP_BAR_HEIGHT, TOP_BAR_Y_PADDING } from "../components/top-bar.component";
import { DeoTreePicker } from "../../data-entry-objects/components/deo-tree-picker.component";

type DeoPickerProps = {
  organizationId: string;
  recordingPeriod: ILocalRecordingPeriod;
  selectedDeoId: string;
  selectableDeoIds?: string[];
  onChange: (selectedDeoId: string) => void;
};

export const DeoPickerTopBar: FC<DeoPickerProps> = ({
  organizationId,
  recordingPeriod,
  selectedDeoId,
  selectableDeoIds,
  onChange,
}) => {
  const { t } = useTranslation("top_bar");
  const [open, setOpen] = useState(false);
  const deoMenuAnchorElement = useRef<null | HTMLButtonElement>(null);

  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId,
    recordingPeriod.id,
  );

  const handleChange = (deoId: string) => {
    onChange(deoId);
    setOpen(false);
  };

  return (
    <QueriesWrapper
      queries={[organizationStructureQuery]}
      build={([unknownOrganizationStructure]) => (
        <OrganizationStructureDraftWrapper
          organizationId={organizationId}
          recordingPeriodId={recordingPeriod.id}
          organizationStructure={unknownOrganizationStructure}
          draftNoticeOverride={null}
          build={(rootDeo) => {
            const selectedDeo = DataEntryObjectsAppUtilities.findDataEntryObjectById(
              rootDeo,
              selectedDeoId,
            );

            return (
              <>
                <Box ref={deoMenuAnchorElement}>
                  <TopBarPicker
                    label={t("data_entry_object")}
                    value={selectedDeo?.name ?? ""}
                    startIcon={
                      <SvgIcon
                        color="inherit"
                        sx={{ width: 24, height: 24, padding: 0.25, opacity: 0.54 }}
                      >
                        <OrganizationOctagonIcon />
                      </SvgIcon>
                    }
                    arrowIcon={<ChevronDownIcon size={20} />}
                    onClick={() => setOpen(!open)}
                  />
                </Box>
                <Menu
                  id="deo-menu"
                  anchorEl={deoMenuAnchorElement.current}
                  open={open}
                  onClose={() => setOpen(false)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{
                    mt: `${(TOP_BAR_HEIGHT - 50) / 2 + 2 * TOP_BAR_Y_PADDING}px`,
                    ml: -2,
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    dense: true,
                  }}
                >
                  <DeoTreePicker
                    rootDeo={rootDeo}
                    selectedDeoId={selectedDeoId}
                    selectableDeoIds={selectableDeoIds}
                    onChange={handleChange}
                  />
                </Menu>
              </>
            );
          }}
        />
      )}
    />
  );
};
