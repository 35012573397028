import { isEnum } from "class-validator";

export class ObjectUtilities {
  public static getDeepValue(path: string, value: object) {
    return path.split(".").reduce((acc, curr) => {
      if (acc === undefined || acc === null) {
        return acc;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (acc as any)[curr];
    }, value);
  }

  public static objectToRecord<
    V,
    T extends {
      [key: string]: V | undefined;
    } = { [key: string]: V | undefined },
  >(object: T): Record<string, V> {
    return object as Record<string, V>;
  }

  /**
   * This method determines whether a given value is present and also narrows the type.
   * More specifically, this checks whether a value is:
   * * Not `null` **and**
   * * Not `undefined`
   * @param value The value to test
   */
  public static isValuePresent<T>(value: T): value is Exclude<T, null | undefined> {
    return value !== undefined && value !== null;
  }

  /**
   * This method merges update/change values into a DTO.
   * @param dao The dao to merge values into
   * @param dto The dto to merge into the DTO
   */
  public static mergeDtoValues<T extends object>(dao: T, dto: Partial<T>): T {
    return Object.assign(dao, dto);
  }

  /**
   * This method checks if a given value (may also be null or undefined) is a member of a specified enum.
   * @param value The value to check
   * @param targetEnum The enum to check against
   * @returns True if the value is a member of the enum (and not null or undefined), false otherwise
   */
  public static isEnum<T>(value: unknown | null | undefined, targetEnum: object): value is T {
    return isEnum(value, targetEnum);
  }

  /**
   * This method creates a deep clone of a serializable object.
   * It uses JSON.stringify to convert the object to a JSON string and then
   * JSON.parse to convert it back to an object, effectively cloning it.
   * CAREFUL: This will strip keys that have a value of `undefined`!
   *
   * @param value The object to clone
   * @returns A deep clone of the input object
   */
  public static cloneSerializableObject<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
  }

  /**
   * This method checks if all keys of an object have values that are present (ObjectUtilities.isValuePresent is used for that).
   * It recursively checks nested objects as well.
   *
   * @param value The object to check
   * @returns True if all keys have present values, false otherwise
   */
  public static areAllKeysOfObjectPresent<T>(value: T): boolean {
    if (typeof value !== "object" || value === null) {
      return false;
    }

    for (const key in value) {
      const keyValue = (value as any)[key];

      if (!ObjectUtilities.isValuePresent(keyValue)) {
        return false;
      }

      if (typeof keyValue === "object" && !ObjectUtilities.areAllKeysOfObjectPresent(keyValue)) {
        return false;
      }
    }

    return true;
  }
}
