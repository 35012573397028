import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { ReferenceBudgetsComponent } from "../../modules/reference-budgets/reference-budgets.component";
import { useTranslation } from "react-i18next";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import {
  AppBreadCrumbs,
  IBreadCrumb,
} from "../../modules/common/components/app-bread-crumbs.component";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";
import {
  REFERENCE_BUDGETS_DEMO_DATA,
  TARGET_PATHS_DEMO_RECORDING_PERIODS,
} from "./target-paths-demo-data.constants";
import { Box, Typography } from "@mui/material";

interface IReferenceBudgetPageProps {}

export const ReferenceBudgetPage: FC<IReferenceBudgetPageProps> = () => {
  const { t } = useTranslation("reference_budgets_page");
  const { t: tNavItems } = useTranslation("side_bar_nav_items");

  const { organizationId } = useParams();

  const navigate = useNavigate();

  const breadCrumbItems: IBreadCrumb[] = [
    {
      label: tNavItems("target_paths_reference_budgets"),
      to: AppInternalNavigationUtilities.getReferenceBudgetsPageUrl(organizationId!),
    },
  ];

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(AppInternalNavigationUtilities.getReferenceBudgetsPageUrl(orgId));
            },
          }
        : undefined,
      navigationItems: {
        activePath: AppInternalNavigationUtilities.getReferenceBudgetsPageUrl(organizationId!),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(AppInternalNavigationUtilities.getReferenceBudgetsPageUrl(orgId));
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  // NC-2121: Temporary solution to allow for target Paths etc. to still be demoed
  // const currentOrganization = useCurrentOrganization();
  // const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId!, true);
  // const referenceBudgetsQuery = useReferenceBudgetsQuery(organizationId!);

  // const { isError, error } = useIsError(recordingPeriodsQuery);

  // return (
  //   <Box width="100%" maxWidth="100%" mx="auto">
  //     <AppBreadCrumbs items={breadCrumbItems} />
  //     <Typography variant="h1" mb={2} mt={1}>
  //       {t("title")}
  //     </Typography>
  //     {recordingPeriodsQuery.isLoading ||
  //     referenceBudgetsQuery.isLoading ||
  //     currentOrganization === null ? (
  //       <CircularProgress /> // TODO: Add loading skeleton
  //     ) : isError ? (
  //       <ErrorTextComponent error={error!} />
  //     ) : (
  //       <ReferenceBudgetsComponent
  //         organization={currentOrganization!}
  //         recordingPeriods={recordingPeriodsQuery.data!.recordingPeriods}
  //         referenceBudgets={referenceBudgetsQuery.data!.referenceBudgets}
  //       />
  //     )}
  //   </Box>
  // );

  // NC-2121: Temporary solution to allow for target Paths etc. to still be demoed
  return (
    <Box width="100%" maxWidth="100%" mx="auto">
      <AppBreadCrumbs items={breadCrumbItems} />
      <Typography variant="h1" mb={2} mt={1}>
        {t("title")}
      </Typography>
      <ReferenceBudgetsComponent
        organizationId={organizationId!}
        recordingPeriods={TARGET_PATHS_DEMO_RECORDING_PERIODS.recordingPeriods}
        referenceBudgets={REFERENCE_BUDGETS_DEMO_DATA.referenceBudgets}
      />
    </Box>
  );
};
