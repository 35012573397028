import { useMemo } from "react";
import { IRecordingPeriodIdentity, ROLE_ACCESS } from "@netcero/netcero-common";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";
import { useTranslation } from "react-i18next";
import { useDoesUserHaveRole } from "../../authentication/hooks/does-user-have-role.hook";

export const useDmaTabItems = (identity: IRecordingPeriodIdentity) => {
  const { t } = useTranslation("dma_common");
  const hasAccessToDmaDashboard = useDoesUserHaveRole(ROLE_ACCESS.ALLOWED_TO_ACCESS_DMA_DASHBOARD);

  return useMemo(() => {
    const tabItems = [];
    tabItems.push({
      key: "dataEntry",
      label: t("tabs.data_entry"),
      path: AppInternalNavigationUtilities.getDMAPageUrl(identity),
    });

    if (hasAccessToDmaDashboard) {
      tabItems.push({
        key: "dashboard",
        label: t("tabs.dashboard"),
        path: AppInternalNavigationUtilities.getDMADashboardPageUrl(identity),
      });
    }

    // Only show tabs if the user has access to more than one of them
    return tabItems.length > 1 ? tabItems : [];
  }, [hasAccessToDmaDashboard, t, identity]);
};
