import { Dispatch, useMemo } from "react";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddIcon, IconSize } from "../../common/constants/tabler-icon.constants";
import { HOVER_BACKGROUND_COLOR } from "../../../theme/theme";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { useTranslation } from "react-i18next";
import { UserAvatarWithQuery } from "../../user/components/user-avatar.component";

export interface IMdrEntityData<T> {
  id: string;
  title: string;
  responsibleUserId: string | undefined;
  data: T;
}

interface IMdrEntityTableProps<T> {
  organizationId: string;
  entities: IMdrEntityData<T>[];
  onEdit: Dispatch<IMdrEntityData<T>>;
  noEntitiesPresentNotice: string;
  buttonChooseNoEntitiesForTopicText: string;
  onChooseNoEntitiesForTopic: VoidFunction;
  tooltipAddNew: string;
  onAddNew: VoidFunction;
  disabled: boolean | undefined;
}

export function MdrEntityTable<T>({
  organizationId,
  entities,
  onEdit,
  noEntitiesPresentNotice,
  buttonChooseNoEntitiesForTopicText,
  onChooseNoEntitiesForTopic,
  tooltipAddNew,
  onAddNew,
  disabled,
}: IMdrEntityTableProps<T>) {
  const { t } = useTranslation("mdr_common");

  const hasRows = useMemo(() => entities.length > 0, [entities.length]);

  return (
    <Box display="flex" flexDirection="column" gap={1} width="100%">
      {!hasRows && (
        <Box display="flex" alignItems="center" gap={2}>
          <Typography>{noEntitiesPresentNotice}</Typography>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => onChooseNoEntitiesForTopic()}
            disabled={disabled}
            sx={{ minWidth: 124 }}
          >
            {buttonChooseNoEntitiesForTopicText}
          </Button>
        </Box>
      )}
      {/* Table */}
      {hasRows && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {entities.map((entity) => (
              <TableRow
                key={entity.id}
                onClick={() => onEdit(entity)}
                sx={{
                  cursor: "pointer",
                  ":hover": { bgcolor: HOVER_BACKGROUND_COLOR },
                }}
              >
                <TableCell>
                  <LineClampTypographyWithTooltip
                    variant="inherit"
                    maxLines={3}
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    {entity.title}
                  </LineClampTypographyWithTooltip>
                </TableCell>
                <TableCell align="right">
                  <Box display="flex" justifyContent="end">
                    <UserAvatarWithQuery
                      organizationId={organizationId}
                      userId={entity.responsibleUserId}
                      withTooltip
                      noUserSelectedTooltip={t("no_user_selected", {
                        ns: "add_responsible_user_button",
                      })}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {/* Add Button */}
      <Tooltip title={tooltipAddNew} placement="right">
        <span style={{ width: "min-content" }}>
          <IconButton size="small" onClick={() => onAddNew()} disabled={disabled}>
            <AddIcon size={IconSize.Medium} />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
}
