import { FormControl, FormHelperText, InputAdornment, TextField } from "@mui/material";
import { IInputParameterValueMetaDataNumber } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  DataEntryObjectInputParameterValueDefinitionForNumber,
  NumberUtilities,
} from "@netcero/netcero-common";
import {
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { TranslatedStaticUnitName } from "../../data-entry-object-values/components/translated-static-unit-name.component";
import { ICommonInputProps } from "../value-acquisition.types";

type INumberInputComponentProps = ICommonInputProps<
  IInputParameterValueMetaDataNumber,
  DataEntryObjectInputParameterValueDefinitionForNumber
>;

export const NumberInputComponent: FC<INumberInputComponentProps> = ({
  variant,
  label,
  value,
  onChange,
  onSubmit,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  required,
  autoFocus,
}) => {
  const { t } = useTranslation("number_input");
  const stylesSx = useVariantSxStyles(variant);
  const inputVariantProps = useVariantFormProps(variant);

  const endAdornment = useMemo(() => {
    if (metaData.valueLimits.isPercentage) {
      return <InputAdornment position="end">%</InputAdornment>;
    }

    // Only show static unit if not in table variant (table shows unit in header)
    if (variant !== "table" && metaData.valueLimits.staticUnit) {
      return (
        <InputAdornment position="end">
          <TranslatedStaticUnitName unit={metaData.valueLimits.staticUnit.identifier} />
        </InputAdornment>
      );
    }

    return undefined;
  }, [metaData.valueLimits.isPercentage, metaData.valueLimits.staticUnit, variant]);

  return (
    <FormControl
      {...inputVariantProps}
      error={!!error}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined, minWidth: 250 }}
    >
      <TextField
        required={required}
        label={label}
        {...inputVariantProps}
        type="number"
        placeholder={t("placeholder")}
        value={value ?? ""}
        disabled={disabled}
        autoFocus={autoFocus}
        error={!!error}
        onChange={(e) => onChange(e.target.value === "" ? undefined : e.target.value)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter") {
            onSubmit?.();
          }
        }}
        InputProps={{
          endAdornment,
          inputProps: {
            step: NumberUtilities.moveDecimalPlaceRounded(1, metaData.valueLimits.precision),
            min: metaData.valueLimits.min,
            max: metaData.valueLimits.max,
          },
          sx: stylesSx,
        }}
        sx={{ input: { textAlign: !disableMaxWidth ? "right" : undefined } }}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
