/**
 * Keycloak realm roles used by the phase two extension. Mainly needed for admin actions.
 */
export enum PhaseTwoRealmManagementRole {
  // Taken from https://github.com/p2-inc/keycloak-orgs/blob/main/src/main/java/io/phasetwo/service/resource/OrganizationAdminAuth.java
  // Prefixed with realm-management: to match the keycloak realm-management client
  CREATE_ORGANIZATION = "realm-management:create-organization",
  VIEW_ORGANIZATIONS = "realm-management:view-organizations",
  MANAGE_ORGANIZATIONS = "realm-management:manage-organizations",
}

/**
 * Roles that can be assigned to a user within an organization.
 */
export enum OrganizationRole {
  ORGANIZATION_ADMIN = "organization-admin",
  OBJECT_ADMIN = "object-admin",
  VIEW_MEMBERS = "view-members",
}
