import { FC, useEffect, useMemo, useState } from "react";
import { Box, Fade, Grid } from "@mui/material";
import {
  AppUrlUtilities,
  IGhgCategoriesPerScopeForRecordingPeriod,
  IInputParameterRecordingStructureIdentity,
} from "@netcero/netcero-common";
import { GhgCategoriesOverviewTableOfContents } from "./ghg-categories-overview-table-of-contents.component";
import { useSearchParams } from "react-router-dom";
import { isEnum } from "class-validator";
import { IGhgCategoriesIdentifier, IGhgProtocolScopeEnum } from "@netcero/netcero-core-api-client";
import {
  GhgCategoriesOverviewContextProvider,
  IGHGProtocolCategoriesContext,
} from "../ghg-categories-overview.context";
import { GhgCategoryCard } from "./ghg-category-card.component";
import { useIsFeatureFlagEnabled } from "../../common/hooks/is-feature-flag-enabled.hook";
import { FeatureFlag } from "../../common/constants/feature-flags.constants";
import { TOP_BAR_HEIGHT } from "../../common/components/top-bar.component";
import { useExpandedIdsState } from "../../common/hooks/expanded-ids-state.hook";
import { useScrollPosition } from "../../common/hooks/use-scroll-position.hook";
import { CONTENT_CONTAINER_ID } from "../../common/components/app-shell-wrapper.component";
import { NoGhgCategoriesAccessibleNotice } from "./no-ghg-categories-accessible-notice.component";
import { useGhgCategoryActions } from "../hooks/ghg-category-actions.hook";
import { useCommentsAndAuditLogSidebarStateContext } from "../../comments-and-audit-log/context/comments-and-audit-log-sidebar-state.context";
import { GhgCategoryCommentsAndAuditLogsSidebar } from "./ghg-category-comments-and-audit-logs-sidebar.component";

interface IGhgProtocolVersionCategoriesOverviewProps {
  identity: IInputParameterRecordingStructureIdentity;
  ghgCategories: IGhgCategoriesPerScopeForRecordingPeriod;
}

export const GhgCategoriesOverview: FC<IGhgProtocolVersionCategoriesOverviewProps> = ({
  identity,
  ghgCategories,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    expandedIds: expandedCategories,
    isExpanded: isCategoryExpanded,
    setExpandedIds: setExpandedCategories,
    toggle: toggleCategoryExpansion,
    expand: expandCategory,
    close: closeCategory,
  } = useExpandedIdsState<IGhgCategoriesIdentifier>(true);

  // TODO: NC-XYZ Clean up - only keep styles for UI v2
  const isAppShellReworkEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);

  const viewedGhgScopeIdentifier = useMemo(() => {
    const queryParamValue = searchParams.get(AppUrlUtilities.VIEWED_SCOPE_QUERY_PARAM);
    return isEnum(queryParamValue, IGhgProtocolScopeEnum)
      ? (queryParamValue as IGhgProtocolScopeEnum)
      : null;
  }, [searchParams]);
  const viewedScopeCategories = viewedGhgScopeIdentifier
    ? ghgCategories[viewedGhgScopeIdentifier]
    : null;

  const [viewedGhgCategoryIdentifier, setViewedGhgCategoryIdentifier] =
    useState<IGhgCategoriesIdentifier | null>(null);

  // Scroll Position Tracking (ToC selected Item)
  const handleScrollPositionChange = useMemo(() => {
    const headerIds =
      viewedScopeCategories?.map((category) => category.ghgCategoryIdentifier) ?? [];
    return () => {
      const ghgCategoriesCardElements = headerIds.map((id) => document.getElementById(id));
      const ghgCategoriesCardElementsBoundingRects = ghgCategoriesCardElements
        .map((el) => el?.getBoundingClientRect())
        .reverse();

      const ghgCategoryCardElementIndex = ghgCategoriesCardElementsBoundingRects.findIndex(
        (rect) => rect && rect.top < 56 + TOP_BAR_HEIGHT,
      );
      // -1 to get the previous element (currently scrolling inside of this element)
      const activeGhgCategoryElementIndex =
        ghgCategoryCardElementIndex === -1
          ? 0 // Use first Element if no element is active
          : ghgCategoriesCardElementsBoundingRects.length - 1 - ghgCategoryCardElementIndex;
      // Update the viewed disclosure requirement
      setViewedGhgCategoryIdentifier(headerIds[activeGhgCategoryElementIndex] ?? null);
    };
  }, [viewedScopeCategories]);

  const [container, setContainer] = useState<HTMLElement>();
  useEffect(() => {
    const element = document.querySelector(`#${CONTENT_CONTAINER_ID}`);
    if (element instanceof HTMLDivElement) {
      setContainer(element);
    }
  }, []);

  useScrollPosition(16, handleScrollPositionChange, container);

  const viewedGhgCategoryIdentifierQuery = useMemo(() => {
    const queryParamValue = searchParams.get(AppUrlUtilities.VIEWED_CATEGORY_QUERY_PARAM);
    return isEnum(queryParamValue, IGhgCategoriesIdentifier)
      ? (queryParamValue as IGhgCategoriesIdentifier)
      : null;
  }, [searchParams]);

  // Open Ghg Category that is in query params
  useEffect(() => {
    if (viewedGhgCategoryIdentifierQuery) {
      // Expand Category
      expandCategory(viewedGhgCategoryIdentifierQuery);
      // Run update scroll position to correct viewedCategory
      handleScrollPositionChange();

      // Scroll to the category
      const categoryCardElement = document.getElementById(viewedGhgCategoryIdentifierQuery);
      if (categoryCardElement) {
        // Scroll to element once expansion animations are finished
        setTimeout(() => categoryCardElement.scrollIntoView({ behavior: "smooth" }), 300);
      }
    }
    // This is fine, because otherwise the viewed category resets on every mutation (because we invalidate the whole recording structure)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewedGhgCategoryIdentifierQuery]);

  // Initially navigate to the first scope and category
  useEffect(() => {
    const firstScopeWithCategories = Object.entries(ghgCategories).find(
      ([, categories]) => categories.length > 0,
    );

    if (
      (!viewedGhgScopeIdentifier || !viewedGhgCategoryIdentifierQuery) &&
      firstScopeWithCategories
    ) {
      setSearchParams(
        (params) => {
          params.set(AppUrlUtilities.VIEWED_SCOPE_QUERY_PARAM, firstScopeWithCategories[0]);
          params.set(
            AppUrlUtilities.VIEWED_CATEGORY_QUERY_PARAM,
            firstScopeWithCategories[1][0].ghgCategoryIdentifier,
          );
          return params;
        },
        { replace: true },
      );
    }
  }, [ghgCategories, setSearchParams, viewedGhgCategoryIdentifierQuery, viewedGhgScopeIdentifier]);

  const {
    handleGhgCategoryResponsiblePersonChange,
    handleGhgCategoryContributingUsersChange,
    isLoading,
  } = useGhgCategoryActions(identity);

  const ghgProtocolContextValue: IGHGProtocolCategoriesContext = useMemo(
    () => ({
      recordingStructureIdentity: identity,
      expandedCategories,
      isCategoryExpanded,
      setExpandedCategories,
      toggleCategoryExpansion,
      expandCategory,
      closeCategory,
      isLoading,
      // Mutations
      handleGhgCategoryResponsiblePersonChange,
      handleGhgCategoryContributingUsersChange,
    }),
    [
      isLoading,
      identity,
      expandedCategories,
      isCategoryExpanded,
      setExpandedCategories,
      toggleCategoryExpansion,
      expandCategory,
      closeCategory,
      handleGhgCategoryResponsiblePersonChange,
      handleGhgCategoryContributingUsersChange,
    ],
  );

  const hasAccessToAnyCategories = useMemo(
    () => Object.values(ghgCategories).some((categories) => categories.length > 0),
    [ghgCategories],
  );

  const { mainContentSxProps } = useCommentsAndAuditLogSidebarStateContext();

  return (
    <GhgCategoriesOverviewContextProvider value={ghgProtocolContextValue}>
      {hasAccessToAnyCategories ? (
        <Box>
          {/* Main Content */}
          <Grid container spacing={4} sx={mainContentSxProps}>
            {/* Table of Contents */}
            <Grid item xs={3}>
              <Box
                position="sticky"
                top={isAppShellReworkEnabled ? -40 : 0}
                maxHeight={`calc(100vh - ${isAppShellReworkEnabled ? TOP_BAR_HEIGHT + 2 : 0}px)`}
                style={{ overflowY: "auto" }}
                pt={2}
                pb={4}
              >
                <GhgCategoriesOverviewTableOfContents
                  identity={identity}
                  ghgCategories={ghgCategories}
                  viewedGhgCategory={viewedGhgCategoryIdentifier}
                />
              </Box>
            </Grid>
            {/* Ghg Category (main Content) */}
            {viewedGhgScopeIdentifier && viewedScopeCategories && (
              <Fade key={viewedGhgScopeIdentifier} in appear>
                <Grid item xs={9} pb={10}>
                  <Box display="flex" flexDirection="column" gap={4} pt={3}>
                    {viewedScopeCategories.map((category, index) => (
                      <Box
                        key={category.ghgCategoryIdentifier}
                        sx={{
                          minHeight:
                            index === viewedScopeCategories.length - 1
                              ? `calc(100dvh - ${
                                  128 + (isAppShellReworkEnabled ? TOP_BAR_HEIGHT : 0)
                                }px)`
                              : undefined,
                        }}
                      >
                        <GhgCategoryCard
                          scope={viewedGhgScopeIdentifier}
                          recordingPeriodGhgCategory={category}
                        />
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Fade>
            )}
          </Grid>
          {/* Comments & Audit Logs Sidebar */}
          <GhgCategoryCommentsAndAuditLogsSidebar recordingStructureIdentity={identity} />
        </Box>
      ) : (
        // No GHG Categories Available
        <NoGhgCategoriesAccessibleNotice identity={identity} />
      )}
    </GhgCategoriesOverviewContextProvider>
  );
};
