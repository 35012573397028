import { FC } from "react";
import { IGhgScopeNameHookOptions, useRenderGhgScopeName } from "../ghg-protocol.hooks";
import { IGhgProtocolScopeEnum } from "@netcero/netcero-core-api-client";

interface IGhgScopeNameProps {
  ghgScope: IGhgProtocolScopeEnum | string;
  options?: IGhgScopeNameHookOptions;
}

export const GhgScopeName: FC<IGhgScopeNameProps> = ({ ghgScope, options }) => {
  const renderGhgScopeName = useRenderGhgScopeName();
  return renderGhgScopeName(ghgScope, options);
};
