import { QueryClient, useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import {
  IGhgCategoriesPerScopeForRecordingPeriod,
  IInputParameterRecordingStructureIdentity,
} from "@netcero/netcero-common";

export const getRecordingPeriodGhgCategoriesQueryKey = ({
  organizationId,
  recordingPeriodId,
  inputParameterRecordingStructureId,
}: IInputParameterRecordingStructureIdentity) => [
  "recording-period-ghg-categories",
  organizationId,
  recordingPeriodId,
  inputParameterRecordingStructureId,
];

/**
 * Invalidate the queries related to the recording period GHG categories
 * @param queryClient
 * @param variables
 */
export async function invalidateRecordingPeriodGhgCategoriesQueries(
  queryClient: QueryClient,
  variables: IInputParameterRecordingStructureIdentity,
) {
  await queryClient.invalidateQueries({
    queryKey: getRecordingPeriodGhgCategoriesQueryKey(variables),
  });
}

export const useRecordingPeriodGhgCategories = ({
  recordingPeriodId,
  organizationId,
  inputParameterRecordingStructureId,
}: IInputParameterRecordingStructureIdentity) => {
  return useQuery({
    queryKey: getRecordingPeriodGhgCategoriesQueryKey({
      organizationId,
      recordingPeriodId,
      inputParameterRecordingStructureId,
    }),
    queryFn: () =>
      CoreApiService.RecordingPeriodGhgCategoriesApi.getRecordingPeriodGhgCategories(
        organizationId,
        recordingPeriodId,
        inputParameterRecordingStructureId,
      )
        .then((req) => req())
        .then((res) => res.data as IGhgCategoriesPerScopeForRecordingPeriod),
  });
};
