import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Box, Button, Checkbox, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "../common/constants/tabler-icon.constants";
import { SearchableMenu } from "../common/components/searchable-menu.component";
import { IFilterConfig, IOption } from "./filter.type";
import { NumberBadgeInline } from "../common/components/number-badge-inline.component";

interface IFilterSelectProps {
  filter: IFilterConfig;
  /** URL search parameters for URL-based filtering.
   * Use either searchParams OR selectedValues, not both. */
  searchParams?: URLSearchParams;
  /** Selected values for state-based filtering.
   * Use either selectedValues OR searchParams, not both. */
  selectedValues?: string[];
  onChange: (name: string, value: string | string[]) => void;
}

function filterOptions(search: string, options: IOption[]) {
  return options.filter((option) => option.name.toLowerCase().includes(search.toLowerCase()));
}

export const FilterMenuSearch: FC<IFilterSelectProps> = ({
  filter,
  searchParams,
  selectedValues,
  onChange,
}) => {
  const { t } = useTranslation("filter_bar");
  const menuAnchorElementRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const filterParams = useMemo(() => {
    if (searchParams) {
      return searchParams.getAll(filter.name) || [];
    }
    return selectedValues || [];
  }, [searchParams, selectedValues, filter.name]);

  const defaultMenuItem = useCallback(
    (option: IOption) => {
      const handleClick = () => {
        let newValues: string[];
        if (filter.multiple) {
          if (filterParams.includes(option.value)) {
            newValues = filterParams.filter((value) => value !== option.value);
          } else {
            newValues = [...filterParams, option.value];
          }
        } else {
          newValues = [option.value];
        }
        onChange(filter.name, newValues);
      };
      return (
        <MenuItem key={option.value} value={option.value} onClick={handleClick} sx={{ gap: 1 }}>
          <Checkbox
            size="small"
            checked={filterParams.includes(option.value)}
            sx={{ p: 0, ":hover": { backgroundColor: "transparent" } }}
          />
          <Box display="flex" alignItems="center" gap={1}>
            {option.name}
          </Box>
        </MenuItem>
      );
    },
    [filter, onChange, filterParams],
  );

  return (
    <>
      <Box
        ref={menuAnchorElementRef}
        onClick={() => setOpen(true)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          fontSize: "14px",
          cursor: "pointer",
        }}
      >
        {filter.label}
        <NumberBadgeInline count={filterParams.length} />
        <ChevronDownIcon
          size={20}
          style={{
            paddingTop: 1,
            paddingLeft: 1,
            color: "rgba(0, 0, 0, 0.54)",
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </Box>
      <SearchableMenu
        show={open}
        anchorEl={menuAnchorElementRef.current}
        onClose={() => setOpen(false)}
        options={filter.options ?? []}
        filterOptions={filterOptions}
        noOptionsPresentText={t("no_entries_available")}
        noOptionsFoundText={t("no_entries_found")}
        buildMenuItem={filter.buildMenuItem ?? defaultMenuItem}
        bottomSection={
          filterParams.length > 0 && (
            <Button
              onClick={() => {
                onChange(filter.name, []);
              }}
              sx={{ ml: 2 }}
            >
              {t("clear")}
            </Button>
          )
        }
      />
    </>
  );
};
