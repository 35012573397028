import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  InputProps,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";
import { IInputParameterValueMetaDataCurrency } from "@netcero/netcero-core-api-client";
import Decimal from "decimal.js-light";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaskedInput } from "../../common/components/masked-input.component";
import {
  DataEntryObjectInputParameterValueDefinitionForCurrency,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { ICommonInputProps } from "../value-acquisition.types";

type ICurrencyInputComponentProps = ICommonInputProps<
  IInputParameterValueMetaDataCurrency,
  DataEntryObjectInputParameterValueDefinitionForCurrency
>;

const formattedValue = (
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForCurrency>,
) => {
  if (value === undefined) {
    return "";
  }
  return new Decimal(value).div(100).toFixed(2);
};

export const CurrencyInputComponent: FC<ICurrencyInputComponentProps> = ({
  variant,
  value,
  onChange,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  label,
  required,
  autoFocus,
}) => {
  const { t } = useTranslation("currency_input");
  const variantProps = useVariantFormProps(variant);
  const variantSxStyles = useVariantSxStyles(variant);

  const [inputValue, setInputValue] = useState<string>(formattedValue(value));

  useEffect(() => {
    setInputValue(formattedValue(value));
  }, [value, setInputValue]);

  const inputComponent = useMemo(() => {
    const commonProps: InputProps | OutlinedInputProps = {
      required,
      size: variantProps.size,
      placeholder: t("placeholder"),
      value: inputValue,
      disabled,
      error: !!error,
      onChange: (changeEvent) => {
        const currentValue = changeEvent as unknown as string;

        // don't call onchange if the values are equal (input onchange is sadly also triggered by setInputValue...)
        if (currentValue === value) {
          return;
        }

        setInputValue(currentValue);

        // don't attempt to parse empty input value
        if (currentValue === "") {
          onChange(undefined);
          return;
        }

        // TODO we may relinquish relying on this library in the future
        // but since it works, i wouldn't now...
        const decimal = new Decimal(currentValue);
        const multiplied = decimal.mul(100);

        onChange(multiplied.toFixed());
      },
      inputProps: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ".",
        padFractionalZeros: false,
        radix: ",",
        min: metaData.valueLimits.min,
        max: metaData.valueLimits.max,
        unmask: true,
        eager: true,
        autoFocus: autoFocus,
      },
      endAdornment: <InputAdornment position="end">&euro;</InputAdornment>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputComponent: MaskedInput as any,
      sx: variantSxStyles,
    };

    if (variant === "table") {
      return <Input {...commonProps} />;
    } else {
      return <OutlinedInput {...commonProps} label={label} />;
    }
  }, [
    autoFocus,
    disabled,
    error,
    inputValue,
    label,
    metaData.valueLimits.max,
    metaData.valueLimits.min,
    onChange,
    required,
    t,
    value,
    variant,
    variantProps.size,
    variantSxStyles,
  ]);

  return (
    <FormControl
      {...variantProps}
      error={!!error}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined, minWidth: 220 }}
      required={required}
    >
      {label && <InputLabel>{label}</InputLabel>}
      {inputComponent}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
