import { FC } from "react";
import { Box, Checkbox, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { QueryWrapper } from "../../../common/components/query-wrapper.component";
import { useGetEmissionFactorsQuery } from "../../emission-factors.queries";
import {
  IEmissionFactorDatabase,
  IEmissionFactorType,
  IEmissionFactorUnit,
  IGhgCategoriesIdentifier,
} from "@netcero/netcero-core-api-client";
import { CountryCodeAlpha2Enum } from "@netcero/netcero-common";
import { EmissionFactorDialogFilter } from "../../../filters/filter.constants";
import { PaginationUtilities } from "../../../pagination/pagination.utilities";
import { TablePaginationForState } from "../../../pagination/components/table-pagination-for-state.component";
import { ACTIVE_BACKGROUND_HIGHLIGHT_COLOR, HOVER_BACKGROUND_COLOR } from "../../../../theme/theme";
import { usePaginationState } from "../../../pagination/hooks/pagination-state.hook";
import {
  EmissionFactorTableDatabaseCell,
  EmissionFactorTableFactorCell,
  EmissionFactorTableGhgCategoriesCell,
  EmissionFactorTableTitleCell,
  EmissionFactorTableUnitCell,
  EmissionFactorTableVersionCell,
  StyledTableCell,
} from "../emission-factor-table-common-cell.components";
import { MapFilterState } from "../../../filters/map-state-filters.component";
import { useResetPaginationOnDataChanged } from "../../../pagination/hooks/reset-pagination-on-data-changed.hook";

interface IEmissionFactorsDialogTableProps {
  organizationId: string;
  selectedEmissionFactorIds: string[];
  disabledEmissionFactorIds?: string[];
  onChange: (emissionFactorId: string, selected: boolean) => void;
  filtersMap: MapFilterState;
  disabled?: boolean;
}

export const EmissionFactorsDialogTable: FC<IEmissionFactorsDialogTableProps> = ({
  organizationId,
  disabledEmissionFactorIds,
  selectedEmissionFactorIds,
  onChange,
  filtersMap,
  disabled,
}) => {
  const { t } = useTranslation("emission_factors_table");

  const paginationState = usePaginationState(0, 5);

  const queryData = {
    title: filtersMap.get(EmissionFactorDialogFilter.nameDialog)?.[0] ?? undefined,
    emissionFactorDatabases: filtersMap.get(
      EmissionFactorDialogFilter.databasesDialog,
    ) as IEmissionFactorDatabase[],
    emissionFactorTypes: filtersMap.get(
      EmissionFactorDialogFilter.emissionFactorTypesDialog,
    ) as IEmissionFactorType[],
    ghgCategories: filtersMap.get(
      EmissionFactorDialogFilter.ghgCategoriesDialog,
    ) as IGhgCategoriesIdentifier[],
    countries: filtersMap.get(
      EmissionFactorDialogFilter.countriesDialog,
    ) as CountryCodeAlpha2Enum[],
    units: filtersMap.get(EmissionFactorDialogFilter.unitsDialog) as IEmissionFactorUnit[],
  };

  useResetPaginationOnDataChanged(queryData, paginationState);

  const emissionFactorsQuery = useGetEmissionFactorsQuery({
    organizationId,
    ...PaginationUtilities.getPaginationQueryParams(paginationState.state),
    ...queryData,
  });

  return (
    <QueryWrapper
      query={emissionFactorsQuery}
      build={({ items, total }) =>
        items.length === 0 ? (
          <Box>{t("no_emission_factors_found")}</Box>
        ) : (
          <>
            <Box sx={{ overflowX: "auto" }}>
              <Table size="small" sx={{ mt: -2 }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell />
                    <StyledTableCell>{t("name")}</StyledTableCell>
                    <StyledTableCell>{t("database")}</StyledTableCell>
                    <StyledTableCell>{t("version")}</StyledTableCell>
                    <StyledTableCell width="150px">{t("ghg_category")}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Box component="span" pr="90px">
                        {t("factor")}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>{t("unit")}</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {items.map(
                    ({
                      id,
                      title,
                      database,
                      version,
                      ghgCategories,
                      factor,
                      scopeFactors,
                      unit,
                    }) => {
                      const isSelected = selectedEmissionFactorIds.includes(id);

                      const isEmissionFactorDisabled =
                        disabled || (disabledEmissionFactorIds?.includes(id) ?? false);

                      return (
                        <TableRow
                          key={id}
                          sx={{
                            cursor: isEmissionFactorDisabled ? undefined : "pointer",
                            backgroundColor: isSelected
                              ? ACTIVE_BACKGROUND_HIGHLIGHT_COLOR
                              : undefined,
                            ":hover": isEmissionFactorDisabled
                              ? {}
                              : {
                                  backgroundColor: HOVER_BACKGROUND_COLOR,
                                },
                          }}
                          onClick={
                            !isEmissionFactorDisabled ? () => onChange(id, !isSelected) : undefined
                          }
                        >
                          <StyledTableCell>
                            <Checkbox checked={isSelected} disabled={isEmissionFactorDisabled} />
                          </StyledTableCell>
                          <EmissionFactorTableTitleCell title={title} />
                          <EmissionFactorTableDatabaseCell database={database} />
                          <EmissionFactorTableVersionCell version={version} />
                          <EmissionFactorTableGhgCategoriesCell ghgCategories={ghgCategories} />
                          <EmissionFactorTableFactorCell
                            factor={factor}
                            scopeFactors={scopeFactors}
                          />
                          <EmissionFactorTableUnitCell unit={unit} />
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </Box>
            <TablePaginationForState
              component="div"
              sx={{ mt: 1 }}
              count={total}
              paginationStateResult={paginationState}
              rowsPerPageOptions={[5]}
            />
          </>
        )
      }
    />
  );
};
