import { IUsePaginationStateResult } from "../pagination.types";
import { useEffect, useRef } from "react";
import { isEqual } from "lodash";

export const useResetPaginationOnDataChanged = <T>(
  data: T,
  paginationState: IUsePaginationStateResult,
) => {
  const lastData = useRef<T>(data);
  useEffect(() => {
    if (!isEqual(lastData.current, data)) {
      paginationState.setCurrentPage(0);
      lastData.current = data;
    }
  }, [data, paginationState]);
};
