import { IContentLanguage } from "@netcero/netcero-core-api-client";
import { UUID } from "../common";

export interface IOrganization {
  id: string;
  name: string;
  deo_count_max: number | null;
  full_access: boolean;
  esrs_access: boolean;
  vsme_access: boolean;
  dma_access: boolean;
  carbon_accounting_access: boolean;
  disabled: boolean;
}

export interface IJwtOrganizationInfo {
  name: string;
  attributes: {
    netcero_id?: UUID[];
    deo_count_max?: string[];
    full_access?: string[];
    esrs_access?: string[];
    vsme_access?: string[];
    dma_access?: string[];
    carbon_accounting_access?: string[];
    disabled?: string[];
  };
}

// TODO: The payload format is a Work in Progress
export interface IJwtPayload {
  exp: number;
  iat: number;
  auth_time: number;
  jti: UUID;
  iss: string;
  aud: string;
  sub: UUID;
  typ: string;
  azp: string;
  session_state: UUID;
  acr: string;
  "allowed-origins": string[];
  realm_access: {
    roles: string[];
  };
  resource_access: {
    account: {
      roles: string[];
    };
  };
  scope: string;
  sid: UUID;
  email_verified: boolean;
  organization_mapping: Record<UUID, IJwtOrganizationInfo>;
  redirectToKb?: boolean;
  organizations: {
    [key: UUID]: {
      roles: string[];
      name: string;
    };
  };
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
  locale: IContentLanguage;
}

/**
 * This class provides a thin wrapper over the raw JWT data
 * to rename properties / make them more developer-friendly
 */
export class ParsedUser {
  /**
   * The ID of the user within keycloak.
   */
  public readonly userId: string;

  /**
   * A combination of firstname + lastname which can be used for displaying purposes
   */
  public readonly displayName: string;

  constructor(from: IJwtPayload) {
    this.userId = from.sub;
    this.displayName = from.name;
  }
}

export enum AppModule {
  DMA = "dma",
  ESRS = "esrs",
  VSME = "vsme",
  CARBON_ACCOUNTING = "carbon_accounting",
}
