import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {
  IDataEntryObject,
  IInputParameterRecordingStructureGroupTHGSC,
} from "@netcero/netcero-core-api-client";
import { ArcElement, Chart, Tooltip } from "chart.js";
import { FC, useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ChartColorPalette } from "../../../theme/charts-theme";
import { IconSize, PointFilledIcon } from "../../common/constants/tabler-icon.constants";
import { GroupedCalculationResult, Predicate } from "@netcero/netcero-common";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { EvaluationFormatUtilities } from "../utilities/evaluation-format.utilities";
import { EvaluationUtilities } from "../utilities/evaluation.utilities";
import { EmissionsUtilities } from "./emissions.utilities";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../../content-translation/hooks/translate-content.hook";

Chart.register(ArcElement);
Chart.register([Tooltip]);

interface ITotalEmissionsByStructureGroupPieChartProps {
  generalResult: GroupedCalculationResult;
  currentDataEntryObject: IDataEntryObject;
  structure: IInputParameterRecordingStructureGroupTHGSC;
  includeChildren: boolean;
  onSelectChild: (childIndex: number) => void;
  isChildSelectable?: Predicate<IInputParameterRecordingStructureGroupTHGSC>;
}

export const TotalEmissionsByStructureGroupPieChart: FC<
  ITotalEmissionsByStructureGroupPieChartProps
> = ({
  generalResult,
  currentDataEntryObject,
  structure,
  includeChildren,
  onSelectChild,
  isChildSelectable = () => true,
}) => {
  const { t } = useTranslation("total_emissions_pie_chart_component");
  const theme = useTheme();
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();

  const emissionsByCategory = useMemo(() => {
    return EvaluationUtilities.sumUpCalculationResultsForStructureGroupOverview(
      generalResult,
      currentDataEntryObject,
      structure,
      includeChildren,
    );
  }, [generalResult, currentDataEntryObject, structure, includeChildren]);

  const totalEmissionsPerCategory = useMemo(() => {
    const sumOfRoot = EmissionsUtilities.sumUpEmissionsResults(emissionsByCategory.structure.sums);
    return [
      // Only include root when not 0
      ...(sumOfRoot !== 0
        ? [
            {
              id: null, // used for click event (parent does not do anything)
              name: translateContent(emissionsByCategory.structure.name),
              description: translateOptionalContent(emissionsByCategory.structure.description),
              value: sumOfRoot,
            },
          ]
        : []),
      ...emissionsByCategory.firstLevelChildren.map((firstLevelChild, index) => ({
        id: isChildSelectable(structure.children[index]) ? index : null,
        name: translateContent(firstLevelChild.name),
        description: translateOptionalContent(firstLevelChild.description),
        value: EmissionsUtilities.sumUpEmissionsResults(firstLevelChild.sums),
      })),
    ];
  }, [
    emissionsByCategory.structure.sums,
    emissionsByCategory.structure.name,
    emissionsByCategory.structure.description,
    emissionsByCategory.firstLevelChildren,
    isChildSelectable,
    structure.children,
    translateContent,
    translateOptionalContent,
  ]);

  const totalEmissions = useMemo(() => {
    let sum = 0;

    totalEmissionsPerCategory.forEach((entry) => {
      sum += entry.value;
    });

    return sum;
  }, [totalEmissionsPerCategory]);

  const percentages = useMemo(() => {
    return totalEmissionsPerCategory.map((entry) => entry.value / totalEmissions);
  }, [totalEmissionsPerCategory, totalEmissions]);

  return (
    <Box display="flex" gap={4} justifyContent="center" flex={1} paddingX={4}>
      <Box width={260} height={260} alignSelf="stretch">
        {totalEmissions === 0 ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography>{t("empty")}</Typography>
          </Box>
        ) : (
          <Pie
            data={{
              labels: totalEmissionsPerCategory.map((entry) => entry.name),
              datasets: [
                {
                  label: "",
                  data: totalEmissionsPerCategory.map((entry) => entry.value),
                  borderColor: theme.palette.divider,
                  backgroundColor: ChartColorPalette,
                },
              ],
            }}
            options={{
              onClick: (event, elements, chart) => {
                const elementIndex = elements[0]?.index;
                const childIndex = totalEmissionsPerCategory[elementIndex]?.id ?? null;

                if (childIndex !== null) {
                  onSelectChild(childIndex);
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label(tooltipItem) {
                      return [
                        EvaluationFormatUtilities.formatCO2TonValueUnicode(
                          totalEmissionsPerCategory[tooltipItem.dataIndex].value,
                        ),
                        FormatUtilities.formatFractionAsPercentage(
                          percentages[tooltipItem.dataIndex],
                        ),
                      ];
                    },
                  },
                },
              },
            }}
          />
        )}
      </Box>
      <Box alignSelf="start" flex={1}>
        <Table>
          <TableBody>
            {/* Categories Rows */}
            {totalEmissionsPerCategory.map((entry, index) => (
              <TableRow key={index}>
                <TableCell width="100%">
                  <Box display="flex" gap={1}>
                    <SvgIcon sx={{ color: ChartColorPalette[index % ChartColorPalette.length] }}>
                      <PointFilledIcon size={IconSize.Medium} />
                    </SvgIcon>
                    <Typography
                      onClick={entry.id !== null ? () => onSelectChild(entry.id!) : undefined}
                      sx={{
                        cursor: entry.id !== null ? "pointer" : undefined,
                        ":hover": entry.id !== null ? { textDecoration: "underline" } : undefined,
                      }}
                    >
                      {entry.name}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <Typography whiteSpace="nowrap">
                    {EvaluationFormatUtilities.formatCO2TonValueOnly(entry.value)}
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <Typography whiteSpace="nowrap">
                    ({FormatUtilities.formatFractionAsPercentage(percentages[index], 2)})
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {/* Total Emissions Row */}
            <TableRow>
              <TableCell width="100%">
                <Typography fontWeight="bold">{t("total_emissions")}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold" textAlign="right" whiteSpace="nowrap">
                  {EvaluationFormatUtilities.formatCO2TonValueOnly(totalEmissions)}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </Box>
  );
};
