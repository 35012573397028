import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from "react";
import { IESRSTopic } from "@netcero/netcero-core-api-client";

type TopicLookupFunction = (id: string) => IESRSTopic | undefined;

const ESRSTopicLookupContext = createContext<TopicLookupFunction | null>(null);

interface IESRSTopicLookupContextProviderProps {
  topics: IESRSTopic[];
}

export const ESRSTopicLookupContextProvider: FC<
  PropsWithChildren<IESRSTopicLookupContextProviderProps>
> = ({ topics, children }) => {
  const lookup = useMemo<TopicLookupFunction>(() => {
    const record = topics.reduce<Record<string, IESRSTopic | undefined>>((acc, topic) => {
      acc[topic.id] = topic;
      return acc;
    }, {});
    return (id: string) => record[id];
  }, [topics]);

  return (
    <ESRSTopicLookupContext.Provider value={lookup}>{children}</ESRSTopicLookupContext.Provider>
  );
};

export const useESRSTopicLookup = (): TopicLookupFunction => {
  const context = useContext(ESRSTopicLookupContext);
  if (!context) {
    throw new Error("useESRSTopicLookup must be used within a ESRSTopicLookupContextProvider");
  }
  return context;
};
