import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { BaseStatusIndicator } from "../../common/components/base-status-indicator.component";
import {
  GHG_CATEGORY_MATERIALITY_COLORS,
  GHG_CATEGORY_NO_MATERIALITY_COLOR,
} from "../ghg-categories.constants";
import { IRecordingPeriodGhgCategoryMateriality } from "@netcero/netcero-core-api-client";

interface IGhgCategoryMaterialityIndicatorProps {
  onClick?: VoidFunction;
  disabled?: boolean;
  isMenuOpen?: boolean;
  shouldRenderChevronIcon?: boolean;
  materiality: IRecordingPeriodGhgCategoryMateriality | null;
}

export const GhgCategoryMaterialityIndicator = forwardRef<
  HTMLDivElement,
  IGhgCategoryMaterialityIndicatorProps
>(function GhgCategoryStatusIndicator(
  { materiality, onClick, disabled, isMenuOpen, shouldRenderChevronIcon },
  ref,
) {
  const { t } = useTranslation("ghg_protocol", { keyPrefix: "materiality" });

  const materialityColor = materiality
    ? GHG_CATEGORY_MATERIALITY_COLORS[materiality]
    : GHG_CATEGORY_NO_MATERIALITY_COLOR;

  return (
    <BaseStatusIndicator
      ref={ref}
      onClick={onClick}
      color={materialityColor}
      label={t(`${materiality ?? "not_specified"}.name`)}
      disabled={disabled}
      isMenuOpen={isMenuOpen}
      shouldRenderChevronIcon={shouldRenderChevronIcon}
    />
  );
});
