import { FC, ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";
import { HOVER_BACKGROUND_COLOR } from "../../../theme/theme";

interface IClickToEditTextFieldProps {
  isEditing: boolean;
  onEdit: VoidFunction;
  value: ReactNode;
  inputComponent: ReactNode;
  disabled?: boolean;
  boxProps?: BoxProps;
  // If the component does not support or have readonly mode, the inputComponent gets rendered directly
  hasReadonlyMode?: boolean;
}

export const ClickToEditInput: FC<IClickToEditTextFieldProps> = ({
  isEditing,
  onEdit,
  value,
  inputComponent,
  disabled,
  boxProps,
  hasReadonlyMode,
}) => {
  return isEditing || !hasReadonlyMode ? (
    inputComponent
  ) : (
    <Box
      onClick={disabled ? undefined : onEdit}
      {...boxProps}
      sx={{
        borderRadius: 1,
        whiteSpace: "pre-line",
        ":hover": { background: HOVER_BACKGROUND_COLOR },
        cursor: !disabled ? "pointer" : undefined,
        ...boxProps?.sx,
      }}
    >
      {value}
    </Box>
  );
};
