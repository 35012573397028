import { Box, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useTranslation } from "react-i18next";
import { TargetPathsConfigurationComponent } from "../../modules/target-paths/components/target-paths-configuration.component";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import {
  AppBreadCrumbs,
  IBreadCrumb,
} from "../../modules/common/components/app-bread-crumbs.component";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";
import {
  REFERENCE_BUDGETS_DEMO_DATA,
  TARGET_PATHS_DEMO_RECORDING_PERIODS,
  TARGET_PATHS_DEMO_TARGET_PATHS,
} from "./target-paths-demo-data.constants";

interface ITargetPathOverviewPageProps {}

export const TargetPathOverviewPage: FC<ITargetPathOverviewPageProps> = () => {
  const { t } = useTranslation("target_paths_configuration_page");
  const { t: tNavItems } = useTranslation("side_bar_nav_items");

  const { organizationId } = useParams();

  const navigate = useNavigate();

  const breadCrumbItems: IBreadCrumb[] = [
    {
      label: tNavItems("target_paths"),
      to: AppInternalNavigationUtilities.getTargetPageUrl(organizationId!),
    },
  ];

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(AppInternalNavigationUtilities.getTargetPageUrl(orgId));
            },
          }
        : undefined,
      navigationItems: {
        activePath: AppInternalNavigationUtilities.getTargetPageUrl(organizationId!),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(AppInternalNavigationUtilities.getTargetPageUrl(orgId));
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  // const currentOrganization = useCurrentOrganization();
  // const referenceBudgetsQuery = useReferenceBudgetsQuery(organizationId!);
  // const targetPathsQuery = useTargetPathsQuery(
  //   organizationId!,
  //   undefined,
  //   ReferenceRevenueIn.Euros,
  // );
  // const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId!, true);
  //
  // const { isError, error } = useIsError(targetPathsQuery, referenceBudgetsQuery);
  //
  // return (
  //   <Box width="100%" maxWidth="100%" mx="auto">
  //     <AppBreadCrumbs items={breadCrumbItems} />
  //     <Typography variant="h1" mb={2} mt={1}>
  //       {t("title")}
  //     </Typography>
  //     {targetPathsQuery.isLoading ||
  //     referenceBudgetsQuery.isLoading ||
  //     recordingPeriodsQuery.isLoading ||
  //     currentOrganization === null ? (
  //       <CircularProgress /> // TODO: Add loading skeleton
  //     ) : isError ? (
  //       <ErrorTextComponent error={error!} />
  //     ) : (
  //       <TargetPathsConfigurationComponent
  //         organization={currentOrganization!}
  //         targetPaths={targetPathsQuery.data!.targetPaths}
  //         referenceBudgets={referenceBudgetsQuery.data!.referenceBudgets}
  //         recordingPeriods={recordingPeriodsQuery.data!.recordingPeriods}
  //       />
  //     )}
  //   </Box>
  // );

  // NC-2121: Temporary solution to allow for target Paths etc. to still be demoed
  return (
    <Box width="100%" maxWidth="100%" mx="auto">
      <AppBreadCrumbs items={breadCrumbItems} />
      <Typography variant="h1" mb={2} mt={1}>
        {t("title")}
      </Typography>
      <TargetPathsConfigurationComponent
        organizationId={organizationId!}
        targetPaths={TARGET_PATHS_DEMO_TARGET_PATHS.targetPaths}
        referenceBudgets={REFERENCE_BUDGETS_DEMO_DATA.referenceBudgets}
        recordingPeriods={TARGET_PATHS_DEMO_RECORDING_PERIODS.recordingPeriods}
      />
    </Box>
  );
};
