import { useSearchParams } from "react-router-dom";
import { useCallback, useMemo, useRef } from "react";
import {
  IDMACategoryWithEffectsAndChildren,
  IDMAFinancialEffect,
  IDMAMaterialImpact,
  IESRSTopic,
} from "@netcero/netcero-core-api-client";

import { IShowEditOrDeleteDialogState } from "./iro-dialog-handlers.hook";

export const useIroEditDialogQueryParamState = <T extends IDMAFinancialEffect | IDMAMaterialImpact>(
  queryParamKey: string,
  selectedEsrsTopic: IESRSTopic | null,
  dmaCategoriesForIROIdsLookup: Record<string, IDMACategoryWithEffectsAndChildren | undefined>,
  iroTypeLookup: Record<string, T | undefined>,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleUpdateEditState = useCallback(
    (iro: T | null) => {
      setSearchParams(
        (params) => {
          if (iro) {
            params.set(queryParamKey, iro.id);
          } else {
            params.delete(queryParamKey);
          }
          return params;
        },
        { replace: true },
      ); // Replace to avoid history entries
    },
    [queryParamKey, setSearchParams],
  );

  const showEditIroDialogStateLastRef = useRef<IShowEditOrDeleteDialogState<T>>();
  const showEditIroDialogState: IShowEditOrDeleteDialogState<T> = useMemo(() => {
    const iroId = searchParams.get(queryParamKey);
    const closedState = {
      open: false,
      category: showEditIroDialogStateLastRef.current?.category ?? null,
      esrsTopic: showEditIroDialogStateLastRef.current?.esrsTopic ?? null,
      effectOrImpact: showEditIroDialogStateLastRef.current?.effectOrImpact ?? null,
    };
    // Return empty state if no IRO is selected
    if (!iroId) {
      return closedState;
    }
    // Find category and topic
    const dmaCategoryForIRO = dmaCategoriesForIROIdsLookup[iroId];
    const iro = iroTypeLookup[iroId];
    if (!dmaCategoryForIRO || !iro) {
      return closedState;
    }

    const result = {
      open: true,
      esrsTopic: selectedEsrsTopic,
      category: dmaCategoryForIRO ?? null,
      effectOrImpact: iro ?? null,
    };
    showEditIroDialogStateLastRef.current = result;
    return result;
  }, [searchParams, queryParamKey, dmaCategoriesForIROIdsLookup, iroTypeLookup, selectedEsrsTopic]);

  return {
    showEditIroDialogState,
    handleUpdateEditState,
  };
};
