import { Dispatch, FC, useCallback, useRef } from "react";
import { GhgCategoryMaterialityIndicator } from "./ghg-category-materiality-indicator.component";
import { IRecordingPeriodGhgCategoryMateriality } from "@netcero/netcero-core-api-client";
import { useOpenStateWithForwardClose } from "../../common/hooks/open-state-with-forward-close.hook";
import { Menu, MenuItem } from "@mui/material";
import { ORDERED_GHG_CATEGORY_MATERIALITY } from "../ghg-categories.constants";

type ValueType = IRecordingPeriodGhgCategoryMateriality | null;

interface IGhgCategoryMaterialityIndicatorWithMenuProps {
  materiality: ValueType;
  onChangeMateriality: Dispatch<ValueType>;
  disabled?: boolean;
}

export const GhgCategoryMaterialityIndicatorWithMenu: FC<
  IGhgCategoryMaterialityIndicatorWithMenuProps
> = ({ materiality, onChangeMateriality, disabled }) => {
  const indicatorRef = useRef<HTMLDivElement>(null);
  const { showMenu, setShowMenu, forwardWithCloseMenu } = useOpenStateWithForwardClose();

  const handlePickOption = useCallback(
    (newMateriality: ValueType) => () => {
      if (materiality !== newMateriality) {
        onChangeMateriality(newMateriality);
      }
    },
    [materiality, onChangeMateriality],
  );

  return (
    <>
      {/* Menu */}
      <Menu open={showMenu} anchorEl={indicatorRef.current} onClose={() => setShowMenu(false)}>
        <MenuItem onClick={forwardWithCloseMenu(handlePickOption(null))}>
          <GhgCategoryMaterialityIndicator materiality={null} />
        </MenuItem>
        {ORDERED_GHG_CATEGORY_MATERIALITY.map((option) => (
          <MenuItem key={option} onClick={forwardWithCloseMenu(handlePickOption(option))}>
            <GhgCategoryMaterialityIndicator materiality={option} />
          </MenuItem>
        ))}
      </Menu>

      {/* Status Indicator */}
      <GhgCategoryMaterialityIndicator
        ref={indicatorRef}
        materiality={materiality}
        isMenuOpen={showMenu}
        onClick={() => setShowMenu(true)}
        shouldRenderChevronIcon
        disabled={disabled}
      />
    </>
  );
};
