import { Box, MenuItem, Select, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AuthenticationUtilities, OrganizationRole } from "@netcero/netcero-common";
import { OrganizationRoleUtilities } from "../org-role.utilities";
import { TFunction } from "i18next";
import { useIsFeatureFlagEnabled } from "../../common/hooks/is-feature-flag-enabled.hook";
import { FeatureFlag } from "../../common/constants/feature-flags.constants";

interface IOrganizationRoleSelectProps {
  selectedRole: OrganizationRole | null;
  onChange: (value: OrganizationRole) => void;
  currentUserRole: OrganizationRole | null;
  disabled?: boolean;
}

export const OrganizationRoleSelect: FC<IOrganizationRoleSelectProps> = ({
  selectedRole,
  onChange,
  currentUserRole,
  disabled,
}) => {
  const { t } = useTranslation("roles");

  const orgRoleOptions = AuthenticationUtilities.getOrgRoleUpdateOptions(
    currentUserRole,
    selectedRole,
  );

  const isObjectAdminRoleAvailable = useIsFeatureFlagEnabled(FeatureFlag.OBJECT_ADMIN_ROLE);
  const availableRoles = useMemo(
    () =>
      isObjectAdminRoleAvailable
        ? orgRoleOptions
        : // Remove Object Admin Role if not enabled
          orgRoleOptions.filter((role) => role !== OrganizationRole.OBJECT_ADMIN),
    [orgRoleOptions, isObjectAdminRoleAvailable],
  );

  return (
    <Select
      value={selectedRole}
      onChange={(e) => onChange(e.target.value as OrganizationRole)}
      renderValue={(value) =>
        value ? OrganizationRoleUtilities.getOrganizationRoleTranslation(value, t) : ""
      }
      variant="standard"
      disabled={disabled || orgRoleOptions.length === 0}
    >
      {availableRoles.map((role) => renderOrgRoleMenuItem(role, t))}
    </Select>
  );
};

function renderOrgRoleMenuItem(role: OrganizationRole, t: TFunction) {
  return (
    <MenuItem key={role} value={role}>
      <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "360px", minWidth: 0 }}>
        <Typography variant="body1" fontWeight="bold">
          {OrganizationRoleUtilities.getOrganizationRoleTranslation(role, t)}
        </Typography>
        <Typography variant="body2" color="text.secondary" whiteSpace="break-spaces">
          {OrganizationRoleUtilities.getOrganizationRoleDescription(role, t)}
        </Typography>
      </Box>
    </MenuItem>
  );
}
