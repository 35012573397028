import { CssBaseline, styled, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { PostHogProvider } from "posthog-js/react";
import { FC } from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./app/app";
import "./i18n/i18n";
import { EnvironmentUtilities } from "./modules/common/utilities/environment.utilities";
import { CoreApiService } from "./modules/core-api/core-api.service";
import { useNCTheme } from "./theme/theme";
import TimeAgo from "javascript-time-ago";
import de from "javascript-time-ago/locale/de.json";
import en from "javascript-time-ago/locale/en.json";
import "./theme/theme.css";

// Fonts
// TODO: Add additional font sources if other font weights are needed
import "@fontsource/dm-sans/300.css";
import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  "&.notistack-MuiContent-success": {
    borderRadius: theme.spacing(2),
  },
  "&.notistack-MuiContent-error": {
    borderRadius: theme.spacing(2),
  },
}));

// Initialize Sentry: https://sentry.io/organizations/netcero/projects/netcero-app
Sentry.init({
  dsn: "https://a0df83ffdf6e261a4cc2d042f161c018@o4507662004518912.ingest.de.sentry.io/4507662044561488",
  enabled: !EnvironmentUtilities.IS_LOCAL,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Enable distributed tracing for all requests to the API in every environment (everything on a subdomain within netcero.eu)
  tracePropagationTargets: [/^https:\/\/.*\.netcero\.eu\/api/],

  // Session Replay
  replaysSessionSampleRate: 0, // Disable session replay
  replaysOnErrorSampleRate: 1.0, // Capture 100% of the errors

  // Environment tag
  environment: EnvironmentUtilities.ENVIRONMENT,
});

// Time Ago init

TimeAgo.addLocale(de);
TimeAgo.addLocale(en);

// Register global Axios Error Handler/Transformer
CoreApiService.registerAxiosApiErrorTransformerInterceptor(axios);

const queryClient = new QueryClient();

const RootComponent: FC = () => {
  const defaultTheme = useNCTheme();
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        adapterLocale={navigator.language || "de-DE"}
      >
        <SnackbarProvider
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
          }}
        >
          <BrowserRouter>
            {/* PostHog Setup */}
            <PostHogProvider
              options={{
                api_host: EnvironmentUtilities.POSTHOG_URL,
                // Disable autocapture in local environment
                autocapture: !EnvironmentUtilities.IS_LOCAL,
                loaded: (ph) => {
                  // Disable session recording in local environment
                  if (EnvironmentUtilities.IS_LOCAL) {
                    ph.opt_out_capturing();
                    ph.set_config({
                      disable_session_recording: true,
                    });
                  }
                },
              }}
              apiKey={EnvironmentUtilities.POSTHOG_API_KEY}
            >
              <App />
            </PostHogProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <RootComponent />
  </QueryClientProvider>,
);
