import { FC, useEffect } from "react";
import { useCommentsAndAuditLogSidebarStateContext } from "../../comments-and-audit-log/context/comments-and-audit-log-sidebar-state.context";
import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";
import { SidebarCommentsAndAuditLogPaper } from "../../comments-and-audit-log/components/sidebar-comments-and-audit-log-paper.component";
import { GhgCategoryName } from "./ghg-category-name.component";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  AppUrlUtilities,
  IInputParameterRecordingStructureIdentity,
  ObjectUtilities,
} from "@netcero/netcero-common";

export const useGhgCategoryCommentsAndAuditLogsSidebarContext =
  useCommentsAndAuditLogSidebarStateContext<IGhgCategoriesIdentifier>;

interface IGhgCategoryCommentsAndAuditLogsSidebarProps {
  recordingStructureIdentity: IInputParameterRecordingStructureIdentity;
}

export const GhgCategoryCommentsAndAuditLogsSidebar: FC<
  IGhgCategoryCommentsAndAuditLogsSidebarProps
> = ({ recordingStructureIdentity }) => {
  const { organizationId, recordingPeriodId, inputParameterRecordingStructureId } =
    recordingStructureIdentity;
  const {
    showCommentsAndAuditLogSideSection,
    isViewedCommentSet,
    data: ghgCategory,
    openCommentsAndAuditLogSideSection,
    closeCommentsAndAuditLogSideSection,
  } = useGhgCategoryCommentsAndAuditLogsSidebarContext();

  // Automatically open the sidebar if a comment is viewed
  // but only if no activity is being viewed (initially)
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (
      !isViewedCommentSet ||
      // Ensure that no activity comment is being viewed
      searchParams.get(AppUrlUtilities.INITIALLY_VIEWED_ACTIVITY_QUERY_PARAM) !== null
    ) {
      return;
    }

    const ghgCategoryFromQueryParam = searchParams.get(AppUrlUtilities.VIEWED_CATEGORY_QUERY_PARAM);
    if (
      ObjectUtilities.isEnum<IGhgCategoriesIdentifier>(
        ghgCategoryFromQueryParam,
        IGhgCategoriesIdentifier,
      )
    ) {
      openCommentsAndAuditLogSideSection(ghgCategoryFromQueryParam);
    }
  }, []);

  return (
    ghgCategory && (
      <SidebarCommentsAndAuditLogPaper
        open={showCommentsAndAuditLogSideSection}
        organizationId={organizationId}
        relations={{
          entity: "recording-period-ghg-category",
          ghgCategoryRecordingPeriodId: recordingPeriodId,
          ghgCategoryIdentifier: ghgCategory,
        }}
        linkMetadata={{
          entity: "recording-period-ghg-category",
          organizationId,
          recordingPeriodId,
          recordingStructureId: inputParameterRecordingStructureId,
          recordingStructureType: "ghg",
          ghgCategoryIdentifier: ghgCategory,
        }}
        topBarContent={
          <Box sx={{ m: 1.5 }}>
            <GhgCategoryName ghgCategory={ghgCategory} />
          </Box>
        }
        onClose={() => closeCommentsAndAuditLogSideSection()}
      />
    )
  );
};
