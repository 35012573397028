import { useKeycloakUserApiClient } from "./hooks/keycloak-user-api.hook";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateLocale } from "@netcero/keycloak-common";
import { useInvalidateQueriesByKeys } from "../common/hooks/invalidate-queries-by-keys.hook";
import { getUserAccountQueryKey } from "./user.queries";
import { useMemo } from "react";
import { AVAILABLE_LANGUAGE_KEY, OrganizationRole } from "@netcero/netcero-common";
import type { UseMutationOptions } from "@tanstack/react-query/src/types";
import { CoreApiService } from "../core-api/core-api.service";
import {
  invalidateUserDataEntryObjectsAccess,
  invalidateUserOrganizationRoles,
} from "./users.queries";
import { useKeycloakOrganizationId } from "./hooks/keycloak-organization-id.hook";

interface IUpdateUserLocaleMutationData {
  locale: AVAILABLE_LANGUAGE_KEY;
}

export const useUpdateUserLocaleMutation = (
  mutationOptions?: Pick<UseMutationOptions, "retry">,
) => {
  const keycloakClient = useKeycloakUserApiClient();
  const invalidateQueries = useInvalidateQueriesByKeys();
  const updateUserLocaleMutation = useMutation({
    mutationFn: ({ locale }: IUpdateUserLocaleMutationData) =>
      keycloakClient
        ? updateLocale(keycloakClient, locale)
        : Promise.reject("Keycloak client is not ready"),
    onSuccess: () => invalidateQueries({ awaitQueryKeys: [getUserAccountQueryKey()] }),
    ...mutationOptions,
  });

  return useMemo(
    () => ({
      isReady: !!keycloakClient,
      updateUserLocaleMutation: {
        isPending: updateUserLocaleMutation.isPending,
        mutateAsync: updateUserLocaleMutation.mutateAsync,
        mutate: updateUserLocaleMutation.mutate,
      },
    }),
    [
      keycloakClient,
      updateUserLocaleMutation.isPending,
      updateUserLocaleMutation.mutateAsync,
      updateUserLocaleMutation.mutate,
    ],
  );
};

export function useUpdateOrganizationRoleOfUserMutation(organizationId: string) {
  const keycloakOrganizationId = useKeycloakOrganizationId(organizationId);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, role }: { userId: string; role: OrganizationRole }) => {
      return CoreApiService.UserApi.updateUserRole(organizationId, userId, { role })
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async () => {
      // OrganizationRoles are cached => invalidate them to trigger a refetch and update the UI.
      await invalidateUserOrganizationRoles(queryClient, keycloakOrganizationId);
    },
  });
}

export function useUpdateUserDataEntryObjectsAccessMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organizationId,
      userId,
      recordingPeriodId,
      dataEntryObjectIds,
    }: {
      organizationId: string;
      userId: string;
      recordingPeriodId: string;
      dataEntryObjectIds: string[];
    }) => {
      return CoreApiService.UserApi.updateUserDataEntryObjectsAccess(
        organizationId,
        userId,
        recordingPeriodId,
        { dataEntryObjectIds },
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (_, { organizationId, userId, recordingPeriodId }) => {
      // UserDataEntryObjectsAccess might be cached => invalidate
      await invalidateUserDataEntryObjectsAccess(
        queryClient,
        organizationId,
        userId,
        recordingPeriodId,
      );
    },
  });
}
