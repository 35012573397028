import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { useCallback, useMemo } from "react";
import {
  useUpdateGhgCategoryContributingUsersMutation,
  useUpdateGhgCategoryResponsiblePersonMutation,
} from "../ghg-categories.mutations";
import {
  UPDATE_GHG_CATEGORY_CONTRIBUTING_USERS,
  UPDATE_GHG_CATEGORY_RESPONSIBLE_PERSON,
} from "../../data-entry-object-values/esrs/esrs-api-actions.constants";
import {
  IInputParameterRecordingStructureIdentity,
  IRecordingPeriodGhgCategoryIdentity,
} from "@netcero/netcero-common";

export const useGhgCategoryActions = (identity: IInputParameterRecordingStructureIdentity) => {
  // Snackbar for API calls (success, error handling)
  const { wrapApiPromise } = useAppSnackbar();

  // Category Responsible Person and Contributors
  const updateResponsiblePersonMutation = useUpdateGhgCategoryResponsiblePersonMutation();
  const handleGhgCategoryResponsiblePersonChange = useCallback(
    async (
      ghgCategoryId: IRecordingPeriodGhgCategoryIdentity,
      responsiblePersonId: string | null,
    ) => {
      return wrapApiPromise(
        updateResponsiblePersonMutation.mutateAsync({
          ...identity,
          ghgCategoryIdentifier: ghgCategoryId.ghgCategoryIdentifier,
          responsiblePersonId: responsiblePersonId ?? undefined,
        }),
        UPDATE_GHG_CATEGORY_RESPONSIBLE_PERSON,
      );
    },
    [wrapApiPromise, updateResponsiblePersonMutation, identity],
  );

  const updateContributingUsersMutation = useUpdateGhgCategoryContributingUsersMutation();
  const handleGhgCategoryContributingUsersChange = useCallback(
    async (ghgCategoryId: IRecordingPeriodGhgCategoryIdentity, contributingUserIds: string[]) => {
      return wrapApiPromise(
        updateContributingUsersMutation.mutateAsync({
          ...identity,
          ghgCategoryIdentifier: ghgCategoryId.ghgCategoryIdentifier,
          contributingUserIds: contributingUserIds,
        }),
        UPDATE_GHG_CATEGORY_CONTRIBUTING_USERS,
      );
    },
    [wrapApiPromise, updateContributingUsersMutation, identity],
  );

  // Combined states (loading, error) for all mutations

  const isLoading = useMemo(
    () => updateResponsiblePersonMutation.isPending || updateContributingUsersMutation.isPending,
    [updateResponsiblePersonMutation, updateContributingUsersMutation],
  );

  return useMemo(
    () => ({
      // Category Responsible Person and Contributors
      handleGhgCategoryResponsiblePersonChange,
      handleGhgCategoryContributingUsersChange,
      // Combined states
      isLoading,
    }),
    [handleGhgCategoryContributingUsersChange, handleGhgCategoryResponsiblePersonChange, isLoading],
  );
};
