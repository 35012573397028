import { FC, useCallback } from "react";
import { UserUtilities } from "@netcero/netcero-common";
import { FileUtilities } from "../../common/utilities/file.utilities";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import {
  BasicSnackbarApiActionType,
  IAppSnackbarEnqueueBasicSuccessMessageData,
} from "../../app-snackbar/app-snackbar.interfaces";
import { ExportButton } from "../../export/export-button.component";
import { ExportTypes } from "../../export/export.constants";
import { useExportUsersMutation } from "../../export/export.mutations";

interface IUserExportButtonProps {
  organizationId: string;
}

const EXPORT_USERS_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.EXPORT_USERS,
};

export const UserExportButton: FC<IUserExportButtonProps> = ({ organizationId }) => {
  const appSnackbar = useAppSnackbar();

  const { mutateAsync, isPending } = useExportUsersMutation(organizationId);

  const handleDownload = useCallback(async () => {
    await appSnackbar.wrapApiPromise(async () => {
      const data = await mutateAsync();

      FileUtilities.downloadCsvStringAsUtf8CsvFile(data, UserUtilities.getUserExportFileName());
    }, EXPORT_USERS_ACTION);
  }, [appSnackbar, mutateAsync]);

  return (
    <ExportButton
      handleDownload={handleDownload}
      exportType={ExportTypes.Users}
      isLoading={isPending}
    />
  );
};
