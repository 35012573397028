import { FC } from "react";
import { GhgDashboardCard } from "./ghg-dashboard-card.component";
import { Box, Grid } from "@mui/material";
import { GhgDashboardByDataEntryObject } from "./ghg-dashboard-by-data-entry-object.component";
import { GhgDashboardByEmissionGroup } from "./ghg-dashboard-by-emission-group.component";

export const GhgDashboardByEmissionGroupAndDeo: FC = () => {
  return (
    <GhgDashboardCard>
      <Box p={1.5}>
        <Grid container spacing={2}>
          {/* By Emission Groups */}
          <Grid item xs={12} xl={6}>
            <GhgDashboardByEmissionGroup />
          </Grid>
          <Grid item xs={12} xl={6}>
            <GhgDashboardByDataEntryObject />
          </Grid>
        </Grid>
      </Box>
    </GhgDashboardCard>
  );
};
