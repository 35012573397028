import { FC, PropsWithChildren, ReactNode, useMemo } from "react";
import { DialogCancelButton, DialogSaveButton } from "../dialog-button.components";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "@tanstack/react-query";
import { BaseAsyncActionDialog } from "./base-async-action.dialog";
import { IDialogWithError, OverrideDialogProps } from "../types/common.types";
import { ArrayUtilities } from "@netcero/netcero-common";
import { Box } from "@mui/material";

export type IEditDialogWrapperMode = "create" | "edit";

interface IEditDialogWrapperProps extends IDialogWithError {
  open: boolean;
  title: ReactNode;
  mode: IEditDialogWrapperMode;
  loading: boolean;
  hasChanges: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  additionalStartActions?: ReactNode;
  cancelButtonContentOverride?: ReactNode;
  onCancel: () => void;
  saveButtonContentOverride?: ReactNode;
  onSave: () => void;
  /**
   * Beware these properties will not only add new ones
   * but also override the default ones set in the wrapper!
   * In most cases you do not want to do this!
   **/
  dialogProps?: OverrideDialogProps;
  allowSaveWithoutChanges?: boolean;
}

/**
 * Wrapper component for Edit Dialog with default behaviour
 * for onClose and included cancel and save/cancel buttons.
 */
export const EditDialogWrapper: FC<PropsWithChildren<IEditDialogWrapperProps>> = ({
  open,
  title,
  mode,
  loading,
  error,
  hasChanges,
  disabled,
  readOnly,
  additionalStartActions,
  cancelButtonContentOverride,
  onCancel,
  saveButtonContentOverride,
  onSave,
  // Children,
  children,
  dialogProps,
  allowSaveWithoutChanges,
}) => {
  const { t } = useTranslation("buttons");
  const disableCloseOnBackdrop = loading || hasChanges || disabled;

  return (
    <BaseAsyncActionDialog
      open={open}
      onClose={disableCloseOnBackdrop ? undefined : onCancel}
      dialogProps={dialogProps}
      title={title}
      loading={loading}
      error={error}
      content={children}
      actions={
        <>
          {additionalStartActions && (
            <>
              {additionalStartActions}
              {/* Spacer */}
              <Box flex={1} />
            </>
          )}
          <DialogCancelButton onClick={onCancel} disabled={loading || disabled}>
            {cancelButtonContentOverride || t(readOnly ? "close" : "cancel")}
          </DialogCancelButton>
          {!readOnly && (
            <DialogSaveButton
              onClick={onSave}
              disabled={loading || disabled || (!hasChanges && !allowSaveWithoutChanges)}
            >
              {saveButtonContentOverride || t(mode === "edit" ? "save" : "create")}
            </DialogSaveButton>
          )}
        </>
      }
    />
  );
};

interface IEditDialogWrapperForMutationProps
  extends Omit<IEditDialogWrapperProps, "loading" | "error"> {
  mutations: UseMutationResult | UseMutationResult[];
}

/**
 * Wrapper to use with mutation(s)
 * @param mutation The mutation(s) to set the state for.
 * @param disabled When any mutation is Pending (loading state in Dialog) disabled state is already set for the base `EditDialogWrapper` component.
 *                 This property can be used as an additional disabled override.
 * @param props All other props are the same as for the base `EditDialogWrapper` component.
 */
export const EditDialogWrapperForMutation: FC<IEditDialogWrapperForMutationProps> = ({
  mutations,
  disabled,
  ...props
}) => {
  const mutationsArray = useMemo(() => ArrayUtilities.alwaysArray(mutations), [mutations]);

  const loading = useMemo(() => mutationsArray.some((m) => m.isPending), [mutationsArray]);
  const error = useMemo(
    () => mutationsArray.find((m) => m.isError)?.error ?? null,
    [mutationsArray],
  );

  return (
    <EditDialogWrapper loading={loading} error={error} disabled={loading || disabled} {...props} />
  );
};
