import { IContentLanguage, IGhgProtocolScopeEnum } from "@netcero/netcero-core-api-client";

export const GHG_SCOPES_TRANSLATIONS_DE: Record<
  IGhgProtocolScopeEnum,
  { title: string; subtitle: string }
> = {
  [IGhgProtocolScopeEnum.Scope1]: {
    title: "Scope 1",
    subtitle: "Direkte Emissionen",
  },
  [IGhgProtocolScopeEnum.Scope2]: {
    title: "Scope 2",
    subtitle: "Energiezukauf (indirekte Emissionen)",
  },
  [IGhgProtocolScopeEnum.Scope3]: {
    title: "Scope 3",
    subtitle: "Wertschöpfungskette (indirekte Emissionen)",
  },
};

export const GHG_SCOPES_TRANSLATIONS_EN: typeof GHG_SCOPES_TRANSLATIONS_DE = {
  [IGhgProtocolScopeEnum.Scope1]: {
    title: "Scope 1",
    subtitle: "Direct Emissions",
  },
  [IGhgProtocolScopeEnum.Scope2]: {
    title: "Scope 2",
    subtitle: "Energy purchases (indirect Emissions)",
  },
  [IGhgProtocolScopeEnum.Scope3]: {
    title: "Scope 3",
    subtitle: "Value Chain (indirect Emissions)",
  },
};

export const GHG_SCOPE_TRANSLATIONS_PER_LANGUAGE: Record<
  IContentLanguage,
  typeof GHG_SCOPES_TRANSLATIONS_DE
> = {
  [IContentLanguage.De]: GHG_SCOPES_TRANSLATIONS_DE,
  [IContentLanguage.En]: GHG_SCOPES_TRANSLATIONS_EN,
};
