import { FC } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { TextEditorToggleButton } from "../../components/styled.components";
import { TableIcon } from "../../../common/constants/tabler-icon.constants";
import { INSERT_TABLE_COMMAND } from "@lexical/table";

interface IInsertTableButtonProps {
  disabled?: boolean;
}

export const InsertTableButton: FC<IInsertTableButtonProps> = ({ disabled }) => {
  const [editor] = useLexicalComposerContext();

  return (
    <TextEditorToggleButton
      size="small"
      value="table"
      onClick={() =>
        editor.dispatchCommand(INSERT_TABLE_COMMAND, {
          rows: String(3),
          columns: String(3),
          includeHeaders: false,
        })
      }
      disabled={disabled}
    >
      <TableIcon />
    </TextEditorToggleButton>
  );
};
