import { Box, SvgIcon, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { IDataEntryObjectData } from "@netcero/netcero-core-api-client";
import { BarElement, CategoryScale, Chart, LinearScale, Tooltip } from "chart.js";
import { FC, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ChartColorPalette } from "../../../theme/charts-theme";
import { PointFilledIcon } from "../../common/constants/tabler-icon.constants";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { EvaluationFormatUtilities } from "../utilities/evaluation-format.utilities";
import { IEmissionsByCategoryDEOBarsListCalculationResult } from "./emissions-by-category-deo-bars-list.component";
import { EmissionsUtilities } from "./emissions.utilities";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";

Chart.register(BarElement, CategoryScale, LinearScale);
Chart.register([Tooltip]);

interface IDEOEmissionsByCategoryBarChartProps {
  deoCategoryEvaluationResult: IEmissionsByCategoryDEOBarsListCalculationResult;
  showStructureRootGroup?: boolean;
  onClickChildGroup: (childGroupIndex: number) => void;
  onClickDataEntryObject?: (dataEntryObject: IDataEntryObjectData) => void;
}

export const DEOEmissionsByCategoryBarChart: FC<IDEOEmissionsByCategoryBarChartProps> = ({
  deoCategoryEvaluationResult,
  showStructureRootGroup,
  onClickChildGroup,
  onClickDataEntryObject,
}) => {
  const { t } = useTranslation("deo_emissions_by_category_bar_chart");
  const translateContent = useTranslateContent();

  const shownResults = useMemo(
    () => [
      ...(showStructureRootGroup ? [deoCategoryEvaluationResult.results.structure] : []),
      ...deoCategoryEvaluationResult.results.firstLevelChildren,
    ],
    [deoCategoryEvaluationResult, showStructureRootGroup],
  );

  const categorySums = useMemo(
    () => shownResults.map((result) => EmissionsUtilities.sumUpEmissionsResults(result.sums)),
    [shownResults],
  );

  const categoryPercentages = useMemo(
    () =>
      categorySums.map((sum) =>
        deoCategoryEvaluationResult.totalSum !== 0
          ? (sum / deoCategoryEvaluationResult.totalSum) * 100
          : 0,
      ),
    [categorySums, deoCategoryEvaluationResult.totalSum],
  );

  const childSelectIndexLookup = useMemo(
    () =>
      shownResults
        .map((_, index) => {
          // Root structure group is never selectable
          if (index === 0 && showStructureRootGroup) {
            return null;
          }

          return showStructureRootGroup ? index - 1 : index;
        })
        .reverse(),
    [shownResults, showStructureRootGroup],
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
      {/* Bar Chart */}
      <Box
        minHeight={250}
        maxWidth={200}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {deoCategoryEvaluationResult.totalSum !== 0 ? (
          <Bar
            options={{
              onClick: (event, elements, chart) => {
                const elementIndex = elements[0]?.datasetIndex ?? -1;
                const childIndex = childSelectIndexLookup[elementIndex];

                if (childIndex !== null) {
                  onClickChildGroup(childIndex);
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label(tooltipItem) {
                      const entry = shownResults.slice().reverse()[tooltipItem.datasetIndex];
                      const value = categorySums.slice().reverse()[tooltipItem.datasetIndex];
                      const percentage = categoryPercentages.slice().reverse()[
                        tooltipItem.datasetIndex
                      ];

                      return [
                        ` ${entry.name}`,
                        ` ${EvaluationFormatUtilities.formatCO2TonValueUnicode(value)}`,
                        ` ${FormatUtilities.formatPercentAsPercentage(percentage)}`,
                      ];
                    },
                  },
                },
              },
              scales: {
                x: {
                  stacked: true,
                  grid: {
                    display: false,
                  },
                  border: {
                    display: false,
                  },
                  ticks: {
                    display: false,
                  },
                },
                y: {
                  stacked: true,
                  ticks: {
                    display: false,
                  },
                  border: {
                    display: false,
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            }}
            data={{
              labels: [""],
              datasets: categoryPercentages
                .map((percentage, index) => ({
                  label: translateContent(shownResults[index].name),
                  data: [percentage],
                  backgroundColor: ChartColorPalette[index % ChartColorPalette.length],
                }))
                .reverse(),
            }}
          />
        ) : (
          <Typography>{t("empty")}</Typography>
        )}
      </Box>

      <Typography
        textAlign="center"
        fontWeight="bold"
        my="auto"
        sx={{
          ":hover": {
            textDecoration: onClickDataEntryObject ? "underline" : undefined,
          },
          cursor: onClickDataEntryObject ? "pointer" : undefined,
        }}
        onClick={() => onClickDataEntryObject?.(deoCategoryEvaluationResult.dataEntryObject)}
      >
        {deoCategoryEvaluationResult.dataEntryObject.name}
      </Typography>

      <Table size="small" sx={{ width: "unset" }}>
        <TableBody>
          {shownResults.map((result, index) => (
            <TableRow key={`${result.name}-${index}`}>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                <Box display="flex" alignItems="center">
                  <SvgIcon
                    sx={{ color: ChartColorPalette[index % ChartColorPalette.length], mr: 1 }}
                  >
                    <PointFilledIcon />
                  </SvgIcon>
                  <Box ml="auto">
                    {EvaluationFormatUtilities.formatCO2TonValueOnly(categorySums[index])}
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                ({FormatUtilities.formatPercentAsPercentage(categoryPercentages[index])})
              </TableCell>
            </TableRow>
          ))}
          {/* Total */}
          <TableRow>
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              <Box fontWeight="bold">
                {EvaluationFormatUtilities.formatCO2TonValueOnly(
                  deoCategoryEvaluationResult.totalSum,
                )}
              </Box>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
