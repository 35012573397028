import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";
import { GHG_CATEGORIES_PATHS } from "@netcero/netcero-common";

export type IGhgScopeNameHookOptions = {
  style: "long" | "short";
};

export const useRenderGhgScopeName = () => {
  const { t } = useTranslation("ghg_protocol");

  return useCallback(
    (ghgScope: IGhgCategoriesIdentifier | string, options?: IGhgScopeNameHookOptions) => {
      const title = t(`scope_titles.${ghgScope}`);
      return options?.style === "long" ? `${title}: ${t(`scope_subtitles.${ghgScope}`)}` : title;
    },
    [t],
  );
};

export type IGhgCategoryNameHookOptions = {
  // Defaults to true
  withPath?: boolean;
};

export const useRenderGhgCategoryName = () => {
  const { t } = useTranslation("ghg_categories");

  return useCallback(
    (ghgCategory: IGhgCategoriesIdentifier, options?: IGhgCategoryNameHookOptions) => {
      const categoryName = t(ghgCategory);

      return options?.withPath ?? true
        ? `${GHG_CATEGORIES_PATHS[ghgCategory]} ${categoryName}`
        : categoryName;
    },
    [t],
  );
};
