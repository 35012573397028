import { FC } from "react";
import { Box } from "@mui/material";
import { CommentTextEditorWithUsersQuery } from "./comment-text-editor.component";
import { SerializedEditorState } from "lexical";
import { ILocalComment } from "../comments.types";

interface ICreateNewCommentProps {
  organizationId: string;
  comment: ILocalComment;
  onSave: (content: SerializedEditorState) => Promise<void>;
  onCancel?: () => void;
  disabled?: boolean;
}

export const EditCommentEditorComponent: FC<ICreateNewCommentProps> = ({
  organizationId,
  comment,
  onSave,
  onCancel,
  disabled,
}) => {
  return (
    <Box display="flex">
      {/* Comment Input */}
      <Box flex={1}>
        <CommentTextEditorWithUsersQuery
          organizationId={organizationId}
          onSave={onSave}
          onCancel={onCancel}
          disabled={disabled}
          content={comment.content}
          initiallyEditable
          autoFocus
        />
      </Box>
    </Box>
  );
};
