import { FC, useCallback, useRef } from "react";
import { LexicalRichTextEditor } from "../../lexical/lexical-rich-text-editor.component";
import { LexicalToolbarPlugin } from "../../lexical/plugins/toolbar/lexical-toolbar-plugin.component";
import { useTranslation } from "react-i18next";
import { BoldButton } from "../../lexical/plugins/toolbar/bold-button.component";
import { ItalicButton } from "../../lexical/plugins/toolbar/italic-button.component";
import { UnderlineButton } from "../../lexical/plugins/toolbar/underline-button.component";
import { StrikethroughButton } from "../../lexical/plugins/toolbar/strikethrough-button.component";
import { SaveAndCancelButtons } from "../../lexical/plugins/save-and-cancel-buttons.component";
import { Box } from "@mui/material";
import { SerializedEditorState } from "lexical";
import { SubscribeToContent } from "../../lexical/plugins/subscribe-to-content.component";
import { SaveOnCtrlEnterPlugin } from "../../lexical/plugins/save-on-ctrl-enter-plugin.component";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { UsersMentionsPlugin } from "../../lexical/plugins/users-mentions-plugin.component";
import { AiButton } from "../../lexical/plugins/toolbar/ai-button.component";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { useSearchUsersQueryInternal } from "../../user/users.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";

interface ICommentTextEditorCommonProps {
  onSave: (content: SerializedEditorState) => Promise<void>;
  onCancel?: () => void;
  content?: SerializedEditorState;
  disabled?: boolean;
  initiallyEditable?: boolean;
  autoFocus?: boolean;
}

interface ICommentTextEditorProps extends ICommentTextEditorCommonProps {
  users: IUserRepresentation[];
}

export const CommentTextEditor: FC<ICommentTextEditorProps> = ({
  onSave,
  onCancel,
  users,
  content,
  disabled,
  initiallyEditable,
  autoFocus,
}) => {
  const { t } = useTranslation("comments");

  const resetRef = useRef<VoidFunction>(() => {});

  const handleSave = useCallback(
    async (tree: SerializedEditorState) => {
      await onSave(tree);
      resetRef.current();
    },
    [onSave],
  );

  const handleCancel = useCallback(() => {
    onCancel?.();
    resetRef.current();
  }, [onCancel]);

  return (
    <Box>
      <LexicalRichTextEditor
        variant="comment"
        initiallyEditable={initiallyEditable}
        placeholder={t("text_editor.placeholder")}
        beforeInput={
          <LexicalToolbarPlugin>
            <BoldButton />
            <ItalicButton />
            <UnderlineButton />
            <StrikethroughButton />
            <Box style={{ marginLeft: "auto" }}>
              <AiButton variant="comment" />
            </Box>
          </LexicalToolbarPlugin>
        }
        inInnerEditor={
          <>
            <SubscribeToContent content={content} resetRef={resetRef} />
            <UsersMentionsPlugin users={users} />
            {autoFocus && <AutoFocusPlugin />}
          </>
        }
        afterEditor={
          <>
            <SaveAndCancelButtons onSave={handleSave} onCancel={handleCancel} disabled={disabled} />
            <SaveOnCtrlEnterPlugin onSave={handleSave} />
          </>
        }
        disabled={disabled}
      />
    </Box>
  );
};

interface ICommentTextEditorWithUsersQueryProps extends ICommentTextEditorCommonProps {
  organizationId: string;
}

export const CommentTextEditorWithUsersQuery: FC<ICommentTextEditorWithUsersQueryProps> = ({
  organizationId,
  ...props
}) => {
  const organizationUsers = useSearchUsersQueryInternal(organizationId);

  return (
    <QueryWrapper
      query={organizationUsers}
      build={(users) => <CommentTextEditor users={users} {...props} />}
    />
  );
};
