import { useDoesCurrentUserIdMatchId } from "../../authentication/hooks/does-current-user-id-match-id.hook";
import { useDoesUserHaveRole } from "../../authentication/hooks/does-user-have-role.hook";
import { ObjectUtilities, ROLE_ACCESS } from "@netcero/netcero-common";
import { useCallback } from "react";
import { ISource, ISourceVisibility } from "@netcero/netcero-core-api-client";

export const useCanCurrentUserModifySource = (alwaysAllowAccessToPublicSources: boolean = true) => {
  const updateAllowedBecauseOfRole = useDoesUserHaveRole(
    ROLE_ACCESS.ALLOWED_TO_MODIFY_PRIVATE_SOURCES,
  );

  const doesIdMatch = useDoesCurrentUserIdMatchId();

  return useCallback(
    (source: Pick<ISource, "createdByUserId" | "visibility"> | undefined | null) => {
      // no value present --> always allowed since mode is probably "create"
      if (!ObjectUtilities.isValuePresent(source)) {
        return true;
      }

      // always allowed if the current user is created or the update is allowed because of the role
      if (doesIdMatch(source.createdByUserId) || updateAllowedBecauseOfRole) {
        return true;
      }

      // source is public + access to public sources is always allowed --> allowed as well; else not allowed
      return source.visibility === ISourceVisibility.Public && alwaysAllowAccessToPublicSources;
    },
    [doesIdMatch, updateAllowedBecauseOfRole, alwaysAllowAccessToPublicSources],
  );
};
