import { styled, TableRow } from "@mui/material";
import { HOVER_BACKGROUND_COLOR } from "../../../theme/theme";

export const ClickableTableRow = styled(TableRow)(({ onClick }) =>
  onClick
    ? {
        cursor: "pointer",
        ":hover": {
          backgroundColor: HOVER_BACKGROUND_COLOR,
        },
      }
    : undefined,
);
