import { Alert, Box, CardContent, Divider, styled, Typography } from "@mui/material";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { StyledCard } from "../../common/components/styled-card.component";
import { RecordingStructureGroupsBreadcrumbs } from "../../recording-structures/navigation/recording-structure-groups-breadcrumbs.component";
import {
  IInputParameterRecordingStructureGroupWithInternalIds,
  RecordingStructuresUtilities,
} from "../../recording-structures/recording-structures.utilities";
import { EmissionsByCategoryDEOBarsList } from "./emissions-by-category-deo-bars-list.component";
import { EmissionsByScopeBarsList } from "./emissions-by-scope-bars-list.component";
import { EmissionsDirectAndUpstreamComponent } from "./emissions-direct-and-upstream.component";
import { TotalEmissionsByStructureGroupPieChart } from "./total-emissions-by-structure-group-pie-chart.component";
import { useSearchParamStateBasic } from "../../common/hooks/use-search-param-state.hook";
import {
  GroupedCalculationResult,
  LookUp,
  LookupUtilities,
  RecursiveUtilities,
} from "@netcero/netcero-common";
import { TotalEmissionsByChildDeoPieChart } from "./total-emissions-by-child-deo-pie-chart.component";
import { ILocalInputParameterRecordingStructureTHG } from "../../input-parameter-recording-structures/local-input-parameter-recording-structure.interfaces";
import { useIsFeatureFlagEnabled } from "../../common/hooks/is-feature-flag-enabled.hook";
import { FeatureFlag } from "../../common/constants/feature-flags.constants";
import { OrganizationStructureBreadcrumbs } from "../../organization-structures/navigation/organization-structure-breadcrumbs.component";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";

const RECORDING_GROUP_ID_QUERY_PARAM_KEY = "recordingGroupId";

const EvaluationCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
  "&:last-child": {
    paddingBottom: theme.spacing(4),
  },
}));

interface IEmissionsEvaluationOverviewComponentProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  organizationStructure: IDataEntryObject;
  recordingStructure: ILocalInputParameterRecordingStructureTHG;
  evaluationResult: GroupedCalculationResult;
}

export const EmissionsEvaluationOverviewComponent: FC<
  IEmissionsEvaluationOverviewComponentProps
> = ({
  organizationId,
  recordingPeriodId,
  organizationStructure,
  recordingStructure,
  evaluationResult,
  dataEntryObjectId,
}) => {
  const { t } = useTranslation("emissions_overview");
  const navigate = useNavigate();

  // TODO: NC-XYZ Remove feature flag check
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);

  const internalRootRecordingStructureGroup = useMemo(() => {
    return RecordingStructuresUtilities.hydrateStructureWithInternalIds(
      recordingStructure.structure.rootSustainabilityCategory,
    );
  }, [recordingStructure]);

  const [searchParams] = useSearchParams();
  const [recordingGroupId, setRecordingGroupId] = useSearchParamStateBasic(
    RECORDING_GROUP_ID_QUERY_PARAM_KEY,
    internalRootRecordingStructureGroup.id,
  );
  const handleFilterByRecordingGroup = (
    group: IInputParameterRecordingStructureGroupWithInternalIds,
  ) => {
    setRecordingGroupId(group.id);
  };

  const selectedRecordingGroup = useMemo(() => {
    if (!recordingGroupId) {
      return internalRootRecordingStructureGroup;
    }

    return (
      RecordingStructuresUtilities.findGroupById(
        internalRootRecordingStructureGroup,
        recordingGroupId,
      ) ?? internalRootRecordingStructureGroup
    );
  }, [recordingGroupId, internalRootRecordingStructureGroup]);

  const handleChangeDataEntryObject = (newDataEntryObjectId: string) => {
    navigate({
      pathname: AppInternalNavigationUtilities.getEmissionsEvaluationPageUrl(
        organizationId,
        recordingPeriodId,
        recordingStructure.id,
        newDataEntryObjectId,
      ),
      search: searchParams.toString(),
    });
  };

  const handleSelectChildGroup = (childIndex: number) => {
    const childGroup = selectedRecordingGroup.children[childIndex];
    handleFilterByRecordingGroup(childGroup);
  };

  const dataEntryObjectsLookUp: LookUp<IDataEntryObject> = useMemo(
    () =>
      LookupUtilities.generateEntityLookUp(
        RecursiveUtilities.flattenRecursiveStructureDown(organizationStructure),
      ),
    [organizationStructure],
  );

  const currentDataEntryObject = useMemo(
    () => dataEntryObjectsLookUp[dataEntryObjectId],
    [dataEntryObjectId, dataEntryObjectsLookUp],
  );

  return (
    <Box display="flex" flexDirection="column" gap={4} pt={2}>
      {/* Navigation Top Section */}
      <Box display="flex" gap={4} flexDirection={{ xs: "column", md: "row" }}>
        <Box flex={1}>
          {!isFeatureFlagEnabled && (
            <>
              <Typography variant="h6">{t("title_organization_unit")}</Typography>
              <OrganizationStructureBreadcrumbs
                organizationStructure={organizationStructure}
                dataEntryObjectId={dataEntryObjectId}
                onChange={handleChangeDataEntryObject}
                isDataEntryObjectSelectable={(dataEntryObject) =>
                  dataEntryObject.children.length > 0
                }
              />
            </>
          )}
          <Typography variant="h6" mt={!isFeatureFlagEnabled ? 2 : 0}>
            {t("title_emission_group")}
          </Typography>
          <RecordingStructureGroupsBreadcrumbs
            recordingStructureRootGroup={internalRootRecordingStructureGroup}
            selectedGroup={selectedRecordingGroup}
            onSelectGroup={handleFilterByRecordingGroup}
            isRecordingGroupSelectable={(group) => group.children.length > 0}
          />
        </Box>
        {/* Emissions Side Content */}
        <Box display="flex" justifyContent="start">
          <StyledCard>
            <EvaluationCardContent>
              <EmissionsDirectAndUpstreamComponent
                generalResult={evaluationResult}
                currentDataEntryObject={currentDataEntryObject}
                structureGroup={selectedRecordingGroup}
              />
            </EvaluationCardContent>
          </StyledCard>
        </Box>
      </Box>
      {/* Unit Notice */}
      <Box display="flex" mx="auto">
        <Alert severity="info">
          <FormatTranslation i18nKey="notice_unit" t={t} />
        </Alert>
      </Box>
      {/* Total Emissions Card */}
      <StyledCard>
        <EvaluationCardContent>
          <Typography variant="h4" component="h2">
            {t("title_total_emissions")}
          </Typography>
          <Box display="flex" gap={6} flexWrap="wrap">
            <Box display="flex" flexDirection="column" flex={1}>
              <Typography variant="h6" component="h3" mb={2}>
                {t("title_total_emissions_by_recording_group")}
              </Typography>
              <TotalEmissionsByStructureGroupPieChart
                structure={selectedRecordingGroup}
                generalResult={evaluationResult}
                currentDataEntryObject={currentDataEntryObject}
                onSelectChild={handleSelectChildGroup}
                includeChildren
                isChildSelectable={(childGroup) => childGroup.children.length > 0}
              />
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
              <Typography variant="h6" component="h3" mb={2}>
                {t("title_total_emissions_by_child")}
              </Typography>
              <TotalEmissionsByChildDeoPieChart
                structure={selectedRecordingGroup}
                generalResult={evaluationResult}
                currentDataEntryObject={currentDataEntryObject}
                onSelectChild={handleChangeDataEntryObject}
                isChildSelectable={(deo) => deo.children.length > 0}
              />
            </Box>
          </Box>
        </EvaluationCardContent>
      </StyledCard>
      {/* DEO Bar Charts */}
      <StyledCard sx={{ overflow: "unset" /* Support Sticky Header inside this card */ }}>
        <EvaluationCardContent>
          <Typography variant="h4" component="h2" mb={2}>
            {t("title_child_data_entry_objects")}
          </Typography>
          <Divider />
          <EmissionsByCategoryDEOBarsList
            generalResult={evaluationResult}
            currentDataEntryObject={currentDataEntryObject}
            structure={selectedRecordingGroup}
            onSelectChildGroup={handleSelectChildGroup}
            isChildCategorySelectable={(childGroup) => childGroup.children.length > 0}
            onSelectChildDataEntryObject={(deo) => handleChangeDataEntryObject(deo.id)}
            isChildDataEntryObjectSelectable={(result) => result.hasChildren}
          />
        </EvaluationCardContent>
      </StyledCard>
      {/* Scope Bar Charts */}
      <StyledCard>
        <EvaluationCardContent>
          <Typography variant="h4" component="h2" mb={2}>
            {t("title_scopes")}
          </Typography>
          <Divider />
          <EmissionsByScopeBarsList
            generalResult={evaluationResult}
            currentDataEntryObject={currentDataEntryObject}
            structure={selectedRecordingGroup}
            onSelectChildDataEntryObject={(deo) => handleChangeDataEntryObject(deo.id)}
            // TODO: maybe also check whether there are results for the children
            // TODO: also goes for others...
            isChildDataEntryObjectSelectable={(deo) => deo.children.length > 0}
          />
        </EvaluationCardContent>
      </StyledCard>
    </Box>
  );
};
