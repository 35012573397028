import { FC, useMemo } from "react";
import {
  Box,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslateOptionalContent } from "../../content-translation/hooks/translate-content.hook";
import { GapAnalysisBox } from "../gap-analysis-helper-components.constants";
import { IHydratedCompleteInputParameterRecordingESRSStructure } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { IInputParameterRecordingStructureBase } from "@netcero/netcero-core-api-client";
import { GapAnalysisDisclosureRequirementTableRow } from "./gap-analysis-disclosure-requirement-table-row.component";
import { useTranslation } from "react-i18next";
import { useGapAnalysisContext } from "../gap-analysis.context";
import { useTopicMateriality } from "../../data-entry-object-values/hooks/topic-materiality.hook";
import { MaterialityStatusChip } from "../../double-materiality-assessment/common/materiality-status-chip.component";
import { IconSize, InfoIcon } from "../../common/constants/tabler-icon.constants";

const GapAnalysisHTableHeadCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

interface IGapAnalysisTopicViewProps {
  structure: IInputParameterRecordingStructureBase;
  hydratedStructure: IHydratedCompleteInputParameterRecordingESRSStructure;
}

export const GapAnalysisTopicView: FC<IGapAnalysisTopicViewProps> = ({
  structure,
  hydratedStructure,
}) => {
  const { t } = useTranslation("gap_analysis");
  const translateOptionalContent = useTranslateOptionalContent();
  const disclosureRequirements = useMemo(
    () =>
      hydratedStructure.sections.flatMap((section) =>
        section.disclosureRequirements.map((disclosureRequirement) => ({
          disclosureRequirement,
          section,
        })),
      ),
    [hydratedStructure],
  );

  const { organizationId, recordingPeriodId, dataEntryObjectId } = useGapAnalysisContext();
  const topicMateriality = useTopicMateriality({
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    categoryIdentifier: structure.categoryIdentifier,
    allowIncomplete: false,
  });

  return (
    <GapAnalysisBox p={2.5}>
      {/* Topic Title */}
      <Stack direction="row" alignItems="center" gap={2}>
        {/* Title */}
        <Typography variant="h4" component="h2">
          {translateOptionalContent(structure.name)} -{" "}
          {translateOptionalContent(structure.description)}
        </Typography>
        {/* Materiality Chip */}
        {topicMateriality !== null && <MaterialityStatusChip isMateriality={topicMateriality} />}
      </Stack>

      {/* DRs Table */}
      <Table sx={{ mt: 1 }}>
        <TableHead>
          <TableRow>
            <GapAnalysisHTableHeadCell>{t("table.header_name")}</GapAnalysisHTableHeadCell>
            <GapAnalysisHTableHeadCell>
              {t("table.header_data_collection")}
            </GapAnalysisHTableHeadCell>
            <GapAnalysisHTableHeadCell>{t("table.header_availability")}</GapAnalysisHTableHeadCell>
            <GapAnalysisHTableHeadCell>{t("table.header_sources")}</GapAnalysisHTableHeadCell>
            <GapAnalysisHTableHeadCell>{t("table.header_status")}</GapAnalysisHTableHeadCell>
            <GapAnalysisHTableHeadCell align="center">
              <Box display="flex" alignItems="center" justifyContent="center" gap={0.5}>
                {t("table.header_responsible_person")}
                <Tooltip title={t("table.header_responsible_person_info")}>
                  <InfoIcon size={IconSize.Small} style={{ minWidth: IconSize.Small }} />
                </Tooltip>
              </Box>
            </GapAnalysisHTableHeadCell>
            <GapAnalysisHTableHeadCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {disclosureRequirements.map(({ section, disclosureRequirement }) => (
            <GapAnalysisDisclosureRequirementTableRow
              key={disclosureRequirement.id}
              section={section}
              disclosureRequirement={disclosureRequirement}
            />
          ))}
        </TableBody>
      </Table>
    </GapAnalysisBox>
  );
};
