import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Menu } from "@mui/material";
import { DataEntryObjectInputParameterStatusIndicator } from "./data-entry-object-input-parameter-status-indicator.component";
import { IDataEntryObjectInputParameterStatus } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../user/user.context";
import { DataEntryObjectInputParameterExclusionDialogWithMutation } from "../dialogs/data-entry-object-input-parameter-exclusion.dialog";
import { ILocalDataEntryObjectInputParameter } from "../../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import { DataEntryObjectInputParameterEsrsDiscriminator } from "../data-entry-object-input-parameter.types";
import {
  IStatusChangeMenuItem,
  StatusChangeMenuItems,
} from "../../common/components/status-change-menu-items.component";
import { useOpenStateWithForwardClose } from "../../common/hooks/open-state-with-forward-close.hook";

interface IDataEntryObjectInputParameterStatusIndicatorWithMenuProps {
  type: DataEntryObjectInputParameterEsrsDiscriminator;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  recordingStructureId: string;
  dataEntryObjectInputParameter: ILocalDataEntryObjectInputParameter;
  onReset: VoidFunction;
  onSubmit: VoidFunction;
  onApprove: VoidFunction;
  onReject: VoidFunction;
  disabled?: boolean;
  parentIsExcluded?: boolean;
}

export const DataEntryObjectInputParameterStatusIndicatorWithMenu: FC<
  IDataEntryObjectInputParameterStatusIndicatorWithMenuProps
> = ({
  type,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  recordingStructureId,
  dataEntryObjectInputParameter,
  onReset,
  onSubmit,
  onApprove,
  onReject,
  disabled,
  parentIsExcluded,
}) => {
  const { user } = useUserContext();
  const excluded = !!dataEntryObjectInputParameter.exclude;
  const { status, responsibleUserId } = dataEntryObjectInputParameter;
  const isResponsibleUser = useMemo(
    () => responsibleUserId && responsibleUserId === user?.userProfile?.id,
    [responsibleUserId, user],
  );

  const { t } = useTranslation("data_entry_object_input_parameter_common");

  const statusIndicatorRef = useRef<HTMLDivElement>(null);

  const [showExclusionDialog, setShowExclusionDialog] = useState(false);
  const handleClickExclude = useCallback(() => setShowExclusionDialog(true), []);

  const { showMenu, setShowMenu, forwardWithCloseMenu } = useOpenStateWithForwardClose();

  const statusChangeItems: IStatusChangeMenuItem[] = useMemo(() => {
    switch (status) {
      case IDataEntryObjectInputParameterStatus.InProgress:
        return [
          {
            id: "submit",
            onClick: forwardWithCloseMenu(onSubmit),
            disabled: !!disabled,
            title: t(
              responsibleUserId
                ? "transition_actions.submit_with_reviewer"
                : "transition_actions.submit_without_reviewer",
            ),
            newStatusIndicator: (
              <DataEntryObjectInputParameterStatusIndicator
                status={
                  responsibleUserId
                    ? IDataEntryObjectInputParameterStatus.InReview
                    : IDataEntryObjectInputParameterStatus.Done
                }
              />
            ),
          },
        ];
      case IDataEntryObjectInputParameterStatus.InReview: {
        const tooltip = !isResponsibleUser
          ? t("transition_actions.notice_only_responsible_person_can_approve_or_reject")
          : null;
        return [
          /* Approve Option */
          {
            id: "approve",
            onClick: forwardWithCloseMenu(onApprove),
            tooltip,
            disabled: !isResponsibleUser || !!disabled,
            title: t("transition_actions.approve"),
            newStatusIndicator: (
              <DataEntryObjectInputParameterStatusIndicator
                status={IDataEntryObjectInputParameterStatus.Done}
              />
            ),
          },
          /* Reject Option */
          {
            id: "reject",
            onClick: forwardWithCloseMenu(onReject),
            tooltip,
            disabled: !isResponsibleUser || !!disabled,
            title: t("transition_actions.reject"),
            newStatusIndicator: (
              <DataEntryObjectInputParameterStatusIndicator
                status={IDataEntryObjectInputParameterStatus.InProgress}
              />
            ),
          },
        ];
      }
      case IDataEntryObjectInputParameterStatus.Done:
        return [
          {
            id: "reset",
            onClick: forwardWithCloseMenu(onReset),
            disabled: !!disabled,
            title: t("transition_actions.reset"),
            newStatusIndicator: (
              <DataEntryObjectInputParameterStatusIndicator
                status={IDataEntryObjectInputParameterStatus.InProgress}
              />
            ),
          },
        ];
      default:
        return [];
    }
  }, [
    disabled,
    forwardWithCloseMenu,
    isResponsibleUser,
    onApprove,
    onReject,
    onReset,
    onSubmit,
    responsibleUserId,
    status,
    t,
  ]);

  const canExclude = useMemo(
    () => !dataEntryObjectInputParameter.responsibleUserId || isResponsibleUser,
    [dataEntryObjectInputParameter.responsibleUserId, isResponsibleUser],
  );

  const isExcludeAvailable = useMemo(
    () => status !== IDataEntryObjectInputParameterStatus.Done,
    [status],
  );

  const availableItems = useMemo(
    () => [
      // Normal Status Change Items (when not excluded)
      ...(!excluded ? statusChangeItems : []),
      // Add Exclusion Option
      ...(isExcludeAvailable
        ? [
            {
              id: "exclude",
              onClick: forwardWithCloseMenu(handleClickExclude),
              disabled: !canExclude,
              tooltip: canExclude ? null : t("notice_exclusion_only_possible_as_responsible"),
              title: t("exclude_transition_name"),
              newStatusIndicator: <DataEntryObjectInputParameterStatusIndicator excluded={true} />,
            },
          ]
        : []),
    ],
    [
      canExclude,
      excluded,
      forwardWithCloseMenu,
      handleClickExclude,
      isExcludeAvailable,
      statusChangeItems,
      t,
    ],
  );

  return (
    <>
      {/* Exclusion Dialog */}
      <DataEntryObjectInputParameterExclusionDialogWithMutation
        open={showExclusionDialog}
        mode={type}
        onClose={() => setShowExclusionDialog(false)}
        dataEntryObjectInputParameter={dataEntryObjectInputParameter}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObjectId={dataEntryObjectId}
        recordingStructureId={recordingStructureId}
      />

      {/* Menu */}
      <Menu
        open={showMenu}
        anchorEl={statusIndicatorRef.current}
        onClose={() => setShowMenu(false)}
      >
        <StatusChangeMenuItems items={availableItems} />
      </Menu>

      {/* Status */}
      <DataEntryObjectInputParameterStatusIndicator
        ref={statusIndicatorRef}
        status={status}
        excluded={excluded || parentIsExcluded}
        onClick={() => (excluded ? setShowExclusionDialog(true) : setShowMenu(true))}
        disabled={disabled}
        isMenuOpen={showMenu}
        shouldRenderChevronIcon={true}
      />
    </>
  );
};
