import { FC } from "react";
import { Box, Chip } from "@mui/material";

interface IChipProps {
  chipContents: string[];
  size: "small" | "medium";
}

export const MultipleChip: FC<IChipProps> = ({ chipContents, size }) => {
  return (
    chipContents.length >= 1 && (
      <Box display="flex" flexWrap="wrap" gap={1}>
        {chipContents.map((content) => (
          <Chip label={content} size={size} key={content} />
        ))}
      </Box>
    )
  );
};
