import { Box, useTheme } from "@mui/material";
import {
  BarChart,
  BarSeriesOption,
  LineChart,
  LineSeriesOption,
  PieChart,
  PieSeriesOption,
  ScatterChart,
  ScatterSeriesOption,
} from "echarts/charts";
import {
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkLineComponent,
  MarkLineComponentOption,
  MarkPointComponent,
  MarkPointComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from "echarts/components";
import * as echarts from "echarts/core";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { SVGRenderer } from "echarts/renderers";
import { FC, useEffect, useRef } from "react";
import { useEChartTheme } from "../../../theme/charts-theme";
import { useObserveSize } from "../hooks/use-observe-size.hook";

export type EChartsOption = echarts.ComposeOption<
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | MarkLineComponentOption
  | MarkPointComponentOption
  | BarSeriesOption
  | LineSeriesOption
  | PieSeriesOption
  | ScatterSeriesOption
>;

export type EChartClickHandler = (param: echarts.ECElementEvent) => void;

interface ILegendToggleEvent {
  type: "legendselectchanged";
  // change legend name
  name: string;
  // table of all legend selecting states
  selected: {
    [name: string]: boolean;
  };
}

export interface IEChartLegendLabelTogglePayload {
  seriesName: string;
  newState: boolean;
}

export type EChartLegendLabelToggleHandler = (payload: IEChartLegendLabelTogglePayload) => void;

export interface IBaseChartProps {
  options: EChartsOption;
  onClick?: EChartClickHandler;
  onLabelToggle?: EChartLegendLabelToggleHandler;
  width?: string;
  height?: string;
  themeOverrides?: {
    themeName: string;
    colorPaletteOverrides?: readonly string[];
  };
}

export const BaseChart: FC<IBaseChartProps> = ({
  options,
  onClick,
  onLabelToggle,
  width,
  height,
  themeOverrides,
}) => {
  const theme = useTheme();
  const themeName = themeOverrides?.themeName ?? "netcero-light";

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<echarts.EChartsType | null>(null);
  const eChartTheme = useEChartTheme(themeOverrides?.colorPaletteOverrides);

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    BarChart,
    LineChart,
    PieChart,
    ScatterChart,
    SVGRenderer,
    LabelLayout,
    UniversalTransition,
  ]);
  echarts.registerTheme(themeName, eChartTheme);

  const chartClickHandlerRef = useRef<EChartClickHandler | null>(null);
  useEffect(() => {
    chartClickHandlerRef.current = onClick ?? null;
  }, [onClick]);

  const chartLegendLabelToggleHandlerRef = useRef<EChartLegendLabelToggleHandler | null>(null);
  useEffect(() => {
    chartLegendLabelToggleHandlerRef.current = onLabelToggle ?? null;
  }, [onLabelToggle]);

  useEffect(() => {
    chartRef.current = echarts.init(chartContainerRef.current, themeName);

    // Setup Click Handler
    chartRef.current.on("click", (params) => chartClickHandlerRef.current?.(params));
    chartRef.current.on("legendselectchanged", (params) => {
      const event = params as ILegendToggleEvent;
      chartLegendLabelToggleHandlerRef.current?.({
        seriesName: event.name,
        newState: event.selected[event.name],
      });
    });

    // Destroy
    return () => {
      chartRef.current?.dispose();
      chartRef.current = null;
    };
  }, [themeName]);

  useEffect(() => {
    chartRef.current?.setOption({
      textStyle: { fontFamily: theme.typography.fontFamily },
      ...options,
    });
  }, [options, theme.typography.fontFamily]);

  // Setup Resize Observer
  useObserveSize(chartContainerRef.current, () => {
    chartRef.current?.resize({
      silent: true,
    });
  });

  return (
    <Box
      ref={chartContainerRef}
      style={{
        width: width ?? "100%",
        height: height ?? "100%",
      }}
    />
  );
};
