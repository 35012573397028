import {
  IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevantReasonEnum,
  IExcludeRecordingPeriodGhgCategoryDataReasonOtherReasonEnum,
  IRecordingPeriodGhgCategoryMateriality,
  IRecordingPeriodGhgCategoryStatus,
} from "@netcero/netcero-core-api-client";
import { SUPPORT_COLORS } from "../../theme/theme";
import { IRecordingPeriodGhgCategoryExcludeReason } from "@netcero/netcero-common";

export const GHG_CATEGORY_EXCLUDED_COLOR = SUPPORT_COLORS.darkGray;
export const GHG_CATEGORY_STATUS_COLORS: Record<IRecordingPeriodGhgCategoryStatus, string> = {
  [IRecordingPeriodGhgCategoryStatus.Open]: SUPPORT_COLORS.gray,
  [IRecordingPeriodGhgCategoryStatus.InProgress]: SUPPORT_COLORS.yellow,
  [IRecordingPeriodGhgCategoryStatus.InReview]: SUPPORT_COLORS.blue,
  [IRecordingPeriodGhgCategoryStatus.Done]: SUPPORT_COLORS.green,
};

export const GHG_CATEGORY_MATERIALITY_COLORS: Record<
  IRecordingPeriodGhgCategoryMateriality,
  string
> = {
  [IRecordingPeriodGhgCategoryMateriality.Material]: SUPPORT_COLORS.green,
  [IRecordingPeriodGhgCategoryMateriality.NotMaterial]: SUPPORT_COLORS.red,
  [IRecordingPeriodGhgCategoryMateriality.Unclear]: SUPPORT_COLORS.darkGray,
};
export const GHG_CATEGORY_NO_MATERIALITY_COLOR = SUPPORT_COLORS.darkGray;
export const ORDERED_GHG_CATEGORY_MATERIALITY = [
  IRecordingPeriodGhgCategoryMateriality.Material,
  IRecordingPeriodGhgCategoryMateriality.NotMaterial,
  IRecordingPeriodGhgCategoryMateriality.Unclear,
] as const;

export const ORDERED_GHG_CATEGORY_EXCLUSION_REASONS: IRecordingPeriodGhgCategoryExcludeReason[] = [
  IExcludeRecordingPeriodGhgCategoryDataReasonNotRelevantReasonEnum.NotRelevant,
  IExcludeRecordingPeriodGhgCategoryDataReasonOtherReasonEnum.Other,
] as const;
