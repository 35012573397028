import { Box, CircularProgress } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorTextComponent } from "../../modules/common/components/error-text.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { DataEntryObjectValuesOverviewThgComponent } from "../../modules/data-entry-object-values/thg/data-entry-object-values-overview-thg.component";
import { useDataEntryObjectValuesQuery } from "../../modules/data-entry-object-values/queries/data-entry-object-values.queries";
import { useListValuesQuery } from "../../modules/data-entry-object-values/queries/list-values.queries";
import { useInputParameterRecordingStructureDetailsQuery } from "../../modules/input-parameter-recording-structures/input-parameter-recording-structures.queries";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { useCurrentRecordingPeriod } from "../../modules/recording-periods/use-current-recording-period.hook";
import { useDEOLookup } from "../../modules/data-entry-object-values/hooks/deo-lookup.hook";
import { DeoEsrsValuesOverviewComponent } from "../../modules/data-entry-object-values/esrs/deo-esrs-values-overview.component";
import { InputParameterRecordingStructuresUtilities } from "../../modules/input-parameter-recording-structures/input-parameter-recording-structures.utilities";
import { useIntercomReferencesForEntityQuery } from "../../modules/intercom-references/intercom-references.queries";
import { IDataEntryObject, IIntercomEntity } from "@netcero/netcero-core-api-client";
import {
  IIntercomReferencesContextState,
  IntercomReferencesProvider,
} from "../../modules/intercom-references/intercom-references.context";
import { useCombineQueryState } from "../../modules/common/hooks/combine-query-state.hook";
import { EsrsVsmePaywall } from "../../modules/data-entry-object-values/esrs/esrs-vsme-paywall.component";
import { ILocalRecordingPeriod } from "../../modules/recording-periods/recording-periods.utilities";
import { ILocalInputParameterRecordingStructureESRSVSME } from "../../modules/input-parameter-recording-structures/local-input-parameter-recording-structure.interfaces";
import { ILocalDataEntryObjectInputParameter } from "../../modules/data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import { useEsrsPhaseInQuery } from "../../modules/esrs-phase-in/esrs-phase-in.queries";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { DeoCommentsAndAuditLogProvider } from "../../modules/comments-and-audit-log/context/deo-comments-and-audit-log.context";
import { useUpdateLastUsedDataEntryObjectIdLocalStorageFromRouter } from "../../modules/data-entry-objects/use-last-used-data-entry-object.hooks";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";
import { useGetAccessibleDataObjectEntryIds } from "../../modules/user/hooks/get-accessible-data-entry-object-ids.hook";
import { IOrganization } from "@netcero/netcero-common";

export const DataEntryObjectValueInputPage: FC = () => {
  useUpdateLastUsedDataEntryObjectIdLocalStorageFromRouter();
  const navigate = useNavigate();

  const {
    organizationId,
    recordingPeriodId,
    recordingStructureId,
    recordingStructureType,
    dataEntryObjectId,
  } = useParams();

  const currentOrganization = useCurrentOrganization();
  const currentRecordingPeriod = useCurrentRecordingPeriod();

  const accessibleDataEntryObjectIds = useGetAccessibleDataObjectEntryIds({
    organizationId: organizationId!,
    recordingPeriodId: recordingPeriodId!,
    recordingStructureId,
  });

  if (
    accessibleDataEntryObjectIds &&
    accessibleDataEntryObjectIds.length > 0 &&
    !accessibleDataEntryObjectIds.includes(dataEntryObjectId!)
  ) {
    navigate(
      AppInternalNavigationUtilities.getDeoPageUrl(
        organizationId!,
        recordingPeriodId!,
        recordingStructureType!,
        recordingStructureId!,
        accessibleDataEntryObjectIds[0],
      ),
    );
  }

  // Setup Header & Sidebar

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: currentOrganization
        ? {
            organizationId: currentOrganization.id,
            onChange: (organizationId) => {
              navigate(`/organizations/${organizationId}`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: AppInternalNavigationUtilities.getRecordingStructurePageUrl(
          currentOrganization?.id ?? "",
          recordingPeriodId ?? "",
          recordingStructureType ?? "",
          recordingStructureId ?? "",
        ),
        items: sideBarOrganizationNavItems,
      },
    }),
    [
      sideBarOrganizationNavItems,
      currentOrganization,
      recordingPeriodId,
      recordingStructureType,
      navigate,
    ],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: currentOrganization
        ? {
            organizationId: currentOrganization.id,
            onChange: (organizationId) => {
              navigate(AppInternalNavigationUtilities.getOrganizationPageUrl(organizationId));
            },
          }
        : undefined,
      recordingPeriodId: currentRecordingPeriod?.id,
      dataEntryObjectSelect: {
        dataEntryObjectId: dataEntryObjectId,
        selectableDataEntryObjectIds: accessibleDataEntryObjectIds ?? undefined,
        onChange: (dataEntryObjectId) => {
          const newPath = AppInternalNavigationUtilities.getDeoPageUrl(
            currentOrganization?.id ?? "",
            currentRecordingPeriod?.id ?? "",
            recordingStructureType ?? "",
            recordingStructureId ?? "",
            dataEntryObjectId,
          );
          navigate({
            pathname: newPath,
            search: window.location.search,
          });
        },
      },
    }),
    [
      currentOrganization,
      currentRecordingPeriod?.id,
      recordingStructureId,
      dataEntryObjectId,
      accessibleDataEntryObjectIds,
      navigate,
      recordingStructureType,
    ],
  );
  useCustomTopBarContent(topBarConfiguration);

  // Queries

  // Fetch structure + create lookup to easily reference DEOs by id
  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  const lookup = useDEOLookup(organizationStructureQuery.data);

  const recordingStructureQuery = useInputParameterRecordingStructureDetailsQuery(
    organizationId!,
    recordingPeriodId!,
    recordingStructureId!,
  );

  const dataEntryObjectValuesQuery = useDataEntryObjectValuesQuery(
    organizationId!,
    recordingPeriodId!,
    dataEntryObjectId!,
    recordingStructureId!,
  );

  const listValuesQuery = useListValuesQuery(organizationId!);

  // Handlers
  const handleChangeDataEntryObject = (newDataEntryObjectId: string) => {
    const newPath = AppInternalNavigationUtilities.getDeoPageUrl(
      organizationId!,
      recordingPeriodId!,
      recordingStructureType!,
      recordingStructureId!,
      newDataEntryObjectId,
    );
    navigate({
      pathname: newPath,
      search: window.location.search,
    });
  };

  // Intercom queries
  const intercomPoliciesQuery = useIntercomReferencesForEntityQuery(IIntercomEntity.Policy);
  const intercomActionsQuery = useIntercomReferencesForEntityQuery(IIntercomEntity.Action);
  const intercomTargetQuery = useIntercomReferencesForEntityQuery(IIntercomEntity.Target);
  const intercomInputParameterQuery = useIntercomReferencesForEntityQuery(
    IIntercomEntity.InputParameter,
  );

  const {
    isLoading,
    isError,
    error: queriesError,
  } = useCombineQueryState(
    organizationStructureQuery,
    recordingStructureQuery,
    dataEntryObjectValuesQuery,
    listValuesQuery,
    intercomPoliciesQuery,
    intercomActionsQuery,
    intercomTargetQuery,
    intercomInputParameterQuery,
  );

  // Intercom state
  const intercomState: IIntercomReferencesContextState = useMemo(() => {
    if (isLoading) {
      return {};
    }
    return {
      [IIntercomEntity.Policy]: intercomPoliciesQuery.data!,
      [IIntercomEntity.Action]: intercomActionsQuery.data!,
      [IIntercomEntity.Target]: intercomTargetQuery.data!,
      [IIntercomEntity.InputParameter]: intercomInputParameterQuery.data!,
    };
  }, [
    intercomActionsQuery.data,
    intercomInputParameterQuery.data,
    intercomPoliciesQuery.data,
    intercomTargetQuery.data,
    isLoading,
  ]);

  return isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: 1, height: "100vh" }}
    >
      <CircularProgress />
    </Box>
  ) : isError ? (
    <ErrorTextComponent error={queriesError!} />
  ) : (
    <OrganizationStructureDraftWrapper
      organizationId={organizationId!}
      recordingPeriodId={recordingPeriodId!}
      organizationStructure={organizationStructureQuery.data!}
      build={(organizationStructure) => (
        <Box>
          {InputParameterRecordingStructuresUtilities.isTHGRecordingStructure(
            recordingStructureQuery.data!,
          ) && (
            <DataEntryObjectValuesOverviewThgComponent
              organization={currentOrganization!}
              recordingPeriod={currentRecordingPeriod!}
              recordingStructure={recordingStructureQuery.data!}
              organizationStructure={organizationStructure}
              dataEntryObject={lookup[dataEntryObjectId!]}
              dataEntryObjectInputParameters={dataEntryObjectValuesQuery.data!.inputParameters}
              onChangeDataEntryObject={handleChangeDataEntryObject}
            />
          )}
          <IntercomReferencesProvider state={intercomState}>
            {(InputParameterRecordingStructuresUtilities.isESRSRecordingStructure(
              recordingStructureQuery.data!,
            ) ||
              InputParameterRecordingStructuresUtilities.isVSMERecordingStructure(
                recordingStructureQuery.data!,
              )) && (
              <EsrsVsmePaywall
                organizationId={organizationId}
                // ESRS or VSME
                type={recordingStructureQuery.data!.structure.type}
              >
                <DeoEsrsValues
                  organization={currentOrganization!}
                  recordingPeriod={currentRecordingPeriod!}
                  recordingStructure={recordingStructureQuery.data!}
                  organizationStructure={organizationStructure}
                  dataEntryObject={lookup[dataEntryObjectId!]}
                  dataEntryObjectInputParameters={dataEntryObjectValuesQuery.data!.inputParameters}
                  onChangeDataEntryObject={handleChangeDataEntryObject}
                />
              </EsrsVsmePaywall>
            )}
          </IntercomReferencesProvider>
        </Box>
      )}
    />
  );
};

interface IDeoEsrsValuesProps {
  organization: IOrganization;
  recordingPeriod: ILocalRecordingPeriod;
  recordingStructure: ILocalInputParameterRecordingStructureESRSVSME;
  organizationStructure: IDataEntryObject;
  dataEntryObject: IDataEntryObject;
  dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[];
  onChangeDataEntryObject: (dataEntryObjectId: string) => void;
}

const DeoEsrsValues: FC<IDeoEsrsValuesProps> = ({
  organization,
  recordingPeriod,
  recordingStructure,
  organizationStructure,
  dataEntryObject,
  dataEntryObjectInputParameters,
  onChangeDataEntryObject,
}) => {
  const hasPhaseIn =
    recordingStructure.structure.type === "esrs" && !!recordingStructure.structure.phaseIn;

  const phaseInQuery = useEsrsPhaseInQuery({
    organizationId: organization.id,
    recordingPeriodId: recordingPeriod.id,
    dataEntryObjectId: dataEntryObject.id,
    enabled: hasPhaseIn,
  });

  return (
    <DeoCommentsAndAuditLogProvider>
      {hasPhaseIn ? (
        <QueryWrapper
          query={phaseInQuery}
          build={(phaseInResponse) => (
            <DeoEsrsValuesOverviewComponent
              organization={organization}
              recordingPeriod={recordingPeriod!}
              recordingStructure={recordingStructure}
              organizationStructure={organizationStructure}
              dataEntryObject={dataEntryObject}
              phaseInDecisions={phaseInResponse.decisions}
              dataEntryObjectInputParameters={dataEntryObjectInputParameters}
              onChangeDataEntryObject={onChangeDataEntryObject}
            />
          )}
        />
      ) : (
        <DeoEsrsValuesOverviewComponent
          organization={organization}
          recordingPeriod={recordingPeriod!}
          recordingStructure={recordingStructure}
          organizationStructure={organizationStructure}
          dataEntryObject={dataEntryObject}
          phaseInDecisions={null} // No Phase In
          dataEntryObjectInputParameters={dataEntryObjectInputParameters}
          onChangeDataEntryObject={onChangeDataEntryObject}
        />
      )}
    </DeoCommentsAndAuditLogProvider>
  );
};
