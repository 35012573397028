import {
  IGhgCategoryMutationCommonProps,
  useApproveGhgCategoryMutation,
  useRejectGhgCategoryMutation,
  useResetGhgCategoryMutation,
  useSubmitGhgCategoryMutation,
} from "../ghg-categories.mutations";
import { IGhgActivityStatusUpdateEvents } from "../../ghg-activities/components/ghg-activity-status-indicator-with-menu.component";
import { useMemo } from "react";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";
import {
  IInputParameterRecordingStructureIdentity,
  IRecordingPeriodGhgCategoryIdentity,
} from "@netcero/netcero-common";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { useCombineMutationState } from "../../common/hooks/combined-mutation-state.hook";
import { IRecordingPeriodGhgCategoryContext } from "@netcero/netcero-core-api-client";

interface IGhgCategoryStatusChangeHandlingData {
  ghgCategoryIdentity: IRecordingPeriodGhgCategoryIdentity;
  recordingStructureIdentity: IInputParameterRecordingStructureIdentity;
}

export const useGhgCategoryStatusChangeHandling = ({
  ghgCategoryIdentity,
  recordingStructureIdentity,
}: IGhgCategoryStatusChangeHandlingData) => {
  const { wrapApiPromise } = useAppSnackbar();

  const submitGhgCategoryMutation = useSubmitGhgCategoryMutation();
  const approveGhgCategoryMutation = useApproveGhgCategoryMutation();
  const rejectGhgCategoryMutation = useRejectGhgCategoryMutation();
  const resetGhgCategoryMutation = useResetGhgCategoryMutation();

  const statusChangeEvents: IGhgActivityStatusUpdateEvents = useMemo(() => {
    const mutationsIdentity: IGhgCategoryMutationCommonProps = {
      ...ghgCategoryIdentity,
      inputParameterRecordingStructureId:
        recordingStructureIdentity.inputParameterRecordingStructureId,
    };
    const context: IRecordingPeriodGhgCategoryContext = {
      recordingStructureId: recordingStructureIdentity.inputParameterRecordingStructureId,
    };

    return {
      onSubmit: () =>
        wrapApiPromise(
          submitGhgCategoryMutation.mutateAsync({
            ...mutationsIdentity,
            context,
          }),
          { type: BasicSnackbarApiActionType.SUBMIT_GHG_CATEGORY },
        ),
      onApprove: () =>
        wrapApiPromise(approveGhgCategoryMutation.mutateAsync(mutationsIdentity), {
          type: BasicSnackbarApiActionType.APPROVE_GHG_CATEGORY,
        }),
      onReject: () =>
        wrapApiPromise(
          rejectGhgCategoryMutation.mutateAsync({
            ...mutationsIdentity,
            context,
          }),
          { type: BasicSnackbarApiActionType.REJECT_GHG_CATEGORY },
        ),
      onReset: () =>
        wrapApiPromise(
          resetGhgCategoryMutation.mutateAsync({
            ...mutationsIdentity,
            context,
          }),
          { type: BasicSnackbarApiActionType.RESET_GHG_CATEGORY },
        ),
    };
  }, [
    ghgCategoryIdentity,
    recordingStructureIdentity.inputParameterRecordingStructureId,
    wrapApiPromise,
    submitGhgCategoryMutation.mutateAsync,
    approveGhgCategoryMutation.mutateAsync,
    rejectGhgCategoryMutation.mutateAsync,
    resetGhgCategoryMutation.mutateAsync,
  ]);

  const mutationsState = useCombineMutationState(
    submitGhgCategoryMutation,
    approveGhgCategoryMutation,
    rejectGhgCategoryMutation,
    resetGhgCategoryMutation,
  );

  return {
    statusChangeEvents,
    mutationsState,
  };
};
