import { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";
import { IRecordingPeriodIdentity } from "@netcero/netcero-common";

interface INoGhgCategoriesAccessibleNoticeProps {
  identity: IRecordingPeriodIdentity;
}

export const NoGhgCategoriesAccessibleNotice: FC<INoGhgCategoriesAccessibleNoticeProps> = ({
  identity,
}) => {
  const { t } = useTranslation("ghg_protocol", {
    keyPrefix: "notice_no_ghg_categories_accessible",
  });

  return (
    <Box display="flex" flexDirection="column" alignItems="start" gap={1} p={2}>
      <Typography variant="subtitle1">{t("title")}</Typography>
      <Typography>{t("explanation")}</Typography>
      <Button
        variant="contained"
        component={RouterLink}
        to={AppInternalNavigationUtilities.getRecordingPeriodDashboardPageUrl(
          identity.organizationId,
          identity.recordingPeriodId,
        )}
      >
        {t("button")}
      </Button>
    </Box>
  );
};
