import { useSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { ObjectUtilities } from "@netcero/netcero-common";

/**
 *
 * @param searchParamKey Query key to bind to
 * @param enumType The enum
 * @param defaultValue The default value for the enum
 * @returns The current `value` (or the default value in case the current value is invalid), `updateEnumValue` to modify the parameter. Beware when using the `updateEnumValue` as it may override other parameter changes that have already occurred during the same rendering cycle!
 */
export function useSearchParamEnumValue<T extends string>(
  searchParamKey: string,
  enumType: object,
  defaultValue: T,
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = useMemo(() => {
    const queryParamValue = searchParams.get(searchParamKey);
    return ObjectUtilities.isEnum<T>(queryParamValue, enumType) ? queryParamValue : defaultValue;
  }, [defaultValue, enumType, searchParamKey, searchParams]);

  const updateEnumValue = useCallback(
    (newValue: T) => {
      // Update the query params
      setSearchParams((params) => {
        params.set(searchParamKey, newValue);
        return params;
      });
    },
    [setSearchParams, searchParamKey],
  );

  return useMemo(() => ({ value, updateEnumValue }), [value, updateEnumValue]);
}
