import { useMemo } from "react";
import { useDeoEsrsInputContext } from "../esrs/deo-esrs-input.context";
import {
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IWithUsers,
} from "@netcero/netcero-core-api-client";
import { IWithTopicAssociation } from "../../minimum-disclosure-requirements-common/mdr.types";
import { MdrUtilities } from "../../minimum-disclosure-requirements-common/mdr.utilities";

/**
 * This interface represents the properties required for filtering PTA entities.
 */
export type IUseFilteredPTAsRelevantProperties = IWithUsers & IWithTopicAssociation;

/**
 * Hook that filters PTA (Policies, Targets, Actions) entities based on topic and user filters.
 *
 * @param entities - The list of entities to filter.
 * @param topicIdentifier - The topic identifier to filter by.
 * @returns A filtered array of entities.
 */
export const useFilteredPTAs = <T extends IUseFilteredPTAsRelevantProperties>(
  entities: T[],
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
): T[] => {
  const { filters } = useDeoEsrsInputContext();

  return useMemo(() => {
    return MdrUtilities.getAllWithAssociatedTopic(entities, topicIdentifier).filter((entity) => {
      // Responsible filter: if no filter is selected, always pass.
      const responsibleMatches =
        filters.responsiblePerson.length === 0 ||
        filters.responsiblePerson.includes(entity.responsibleUserId ?? "");

      // Contributing filter: if no filter is selected, always pass.
      const contributingMatches =
        filters.contributingPeople.length === 0 ||
        filters.contributingPeople.some((contributor) =>
          entity.contributingUserIds.includes(contributor),
        );

      return responsibleMatches && contributingMatches;
    });
  }, [entities, topicIdentifier, filters]);
};
