import { useMutation } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { useInvalidateQueriesAffectedByActivityValuesMutations } from "./ghg-activity-values.mutations";
import { IGhgActivityIdentity } from "@netcero/netcero-common";
import { ICreateMultipleActivityValuesRequest } from "@netcero/netcero-core-api-client";

type IGhgActivityValueCollectionCreatePayload = IGhgActivityIdentity &
  ICreateMultipleActivityValuesRequest;

export const useCreateMultipleGhgActivityValuesMutation = () => {
  const invalidateQueries = useInvalidateQueriesAffectedByActivityValuesMutations();
  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      ghgCategoryIdentifier,
      activityId,
      activityValues,
    }: IGhgActivityValueCollectionCreatePayload) =>
      CoreApiService.ActivityValuesApi.createMultipleActivityValues(
        organizationId,
        recordingPeriodId,
        ghgCategoryIdentifier,
        activityId,
        { activityValues },
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: invalidateQueries,
  });
};
