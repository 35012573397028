import React, { FC, useMemo } from "react";
import { GhgDashboardCard } from "./ghg-dashboard-card.component";
import {
  FilterUtilities,
  GhgCategoriesUtilities,
  GhgEvaluationUtilities,
  IGhgEvaluationSums,
} from "@netcero/netcero-common";
import { Box, Fade, styled } from "@mui/material";
import { GhgScopeName } from "../../ghg-protocol/components/ghg-scope-name.component";
import { useTranslation } from "react-i18next";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { GhgCategoryName } from "../../ghg-categories/components/ghg-category-name.component";
import { useGhgDashboardContext } from "../ghg-dashboard.context";
import { EvaluationBase } from "../ghg-evaluation.types";
import { GhgDashboardResult } from "./ghg-dashboard-result.component";
import { GhgScopeTabs } from "../../ghg-protocol/components/ghg-scope-tabs.component";

const TabsRowContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(0.5),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const TabsRowSpacer = styled(Box)({ flex: 1 });

export const GhgDashboardScopeTabs: FC = () => {
  const { t } = useTranslation("ghg_protocol");

  const {
    recordingStructure,
    relevantEvaluationResultsForDeoTree,
    activeView,
    viewedScope,
    changeViewedScope,
  } = useGhgDashboardContext();

  const sumsPerCategory = useMemo(
    () =>
      GhgEvaluationUtilities.calculateEmissionSumsPerCategoryInTCO2Eq(
        relevantEvaluationResultsForDeoTree,
      ),
    [relevantEvaluationResultsForDeoTree],
  );

  const viewedGhgCategories = useMemo(
    () =>
      GhgCategoriesUtilities.getDefinitionForRecordingStructure(recordingStructure)[viewedScope],
    [recordingStructure, viewedScope],
  );

  const scopeTotals: IGhgEvaluationSums = useMemo(
    () =>
      GhgEvaluationUtilities.sumUpGhgEvaluationsSums(
        FilterUtilities.filterNotUndefined(
          viewedGhgCategories.map((categoryIdentifier) => sumsPerCategory[categoryIdentifier]),
        ),
      ),
    [sumsPerCategory, viewedGhgCategories],
  );

  const viewedScopeTotal =
    activeView === EvaluationBase.LocationBased
      ? scopeTotals.locationBasedTotal
      : scopeTotals.marketBasedTotal;

  return (
    <GhgDashboardCard>
      {/* Tabs */}
      <GhgScopeTabs value={viewedScope} onChange={changeViewedScope} />
      {/* Content */}
      <Fade key={viewedScope} in appear>
        <Box pt={2} sx={{ "> :last-child": { borderBottom: "none" } }}>
          {/* Scope Total Result */}
          <TabsRowContainer mb={2}>
            <Box component="span" fontWeight="bold" fontSize={20}>
              <GhgScopeName ghgScope={viewedScope} /> {t("evaluation.total_emissions")}
            </Box>
            <TabsRowSpacer />
            <GhgDashboardResult
              formattedValue={
                <Box component="span" fontWeight="bold" fontSize={28}>
                  {FormatUtilities.formatGhgEvaluationResult(viewedScopeTotal)}
                </Box>
              }
              valueToCopy={viewedScopeTotal.toString()}
            />
          </TabsRowContainer>
          {/* Categories Results */}
          {viewedGhgCategories.map((categoryIdentifier) => {
            const categoryResults = sumsPerCategory[categoryIdentifier] ?? {
              locationBasedTotal: 0,
              marketBasedTotal: 0,
            };
            const categoryResult =
              activeView === EvaluationBase.LocationBased
                ? categoryResults.locationBasedTotal
                : categoryResults.marketBasedTotal;

            return (
              <TabsRowContainer key={categoryIdentifier}>
                <Box component="span" fontSize={14}>
                  <GhgCategoryName ghgCategory={categoryIdentifier} options={{ withPath: false }} />
                </Box>
                <TabsRowSpacer />
                <GhgDashboardResult
                  formattedValue={
                    <Box component="span" fontWeight="bold" fontSize={20}>
                      {FormatUtilities.formatGhgEvaluationResult(categoryResult)}
                    </Box>
                  }
                  valueToCopy={categoryResult.toString()}
                />
              </TabsRowContainer>
            );
          })}
        </Box>
      </Fade>
    </GhgDashboardCard>
  );
};
