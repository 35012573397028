// ghg-protocol-tab-items.hook.ts
import { useMemo } from "react";
import { IInputParameterRecordingStructureIdentity, ROLE_ACCESS } from "@netcero/netcero-common";
import { AppInternalNavigationUtilities } from "../../common/utilities/app-internal-navigation.utilities";
import { useTranslation } from "react-i18next";
import { useDoesUserHaveRole } from "../../authentication/hooks/does-user-have-role.hook";

export const useGhgProtocolTabItems = (identity: IInputParameterRecordingStructureIdentity) => {
  const { t } = useTranslation("ghg_protocol");
  const hasAccessToGhgDashboard = useDoesUserHaveRole(ROLE_ACCESS.ALLOWED_TO_ACCESS_GHG_DASHBOARD);
  const hasAccessToGhgInventoryScreening = useDoesUserHaveRole(
    ROLE_ACCESS.ALLOWED_TO_ACCESS_INVENTORY_SCREENING,
  );

  return useMemo(() => {
    const tabItems = [];
    if (hasAccessToGhgInventoryScreening) {
      tabItems.push({
        key: "inventoryScreening",
        label: t("tabs.inventory_screening"),
        path: AppInternalNavigationUtilities.getGhgProtocolInventoryScreeningPageUrl(identity),
      });
    }

    tabItems.push({
      key: "dataEntry",
      label: t("tabs.data_entry"),
      path: AppInternalNavigationUtilities.getGhgProtocolPageUrl(identity),
    });

    if (hasAccessToGhgDashboard) {
      tabItems.push({
        key: "dashboard",
        label: t("tabs.dashboard"),
        path: AppInternalNavigationUtilities.getGhgProtocolDashboardPageUrl(identity),
      });
    }

    // Only show tabs if the user has access to more than one of them
    return tabItems.length > 1 ? tabItems : [];
  }, [hasAccessToGhgInventoryScreening, t, identity, hasAccessToGhgDashboard]);
};
