import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { BaseStatusIndicator } from "../../common/components/base-status-indicator.component";
import {
  GHG_CATEGORY_STATUS_COLORS,
  GHG_CATEGORY_EXCLUDED_COLOR,
} from "../ghg-categories.constants";
import { IRecordingPeriodGhgCategoryStatus } from "@netcero/netcero-core-api-client";

type IGhgCategoryStatusIndicatorProps = {
  onClick?: VoidFunction;
  disabled?: boolean;
  isMenuOpen?: boolean;
  shouldRenderChevronIcon?: boolean;
} & (
  | {
      status: IRecordingPeriodGhgCategoryStatus;
      excluded?: false;
    }
  | {
      status?: IRecordingPeriodGhgCategoryStatus;
      excluded: true;
    }
);

export const GhgCategoryStatusIndicator = forwardRef<
  HTMLDivElement,
  IGhgCategoryStatusIndicatorProps
>(function GhgCategoryStatusIndicator(
  { excluded, status, onClick, disabled, isMenuOpen, shouldRenderChevronIcon },
  ref,
) {
  const { t } = useTranslation("ghg_protocol", { keyPrefix: "status" });

  const statusColor = excluded ? GHG_CATEGORY_EXCLUDED_COLOR : GHG_CATEGORY_STATUS_COLORS[status];

  return (
    <BaseStatusIndicator
      ref={ref}
      onClick={onClick}
      color={statusColor}
      label={t(excluded ? "excluded.name" : `${status}.name`)}
      isFilled={!excluded}
      disabled={disabled}
      isMenuOpen={isMenuOpen}
      shouldRenderChevronIcon={shouldRenderChevronIcon}
    />
  );
});
