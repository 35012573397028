import { FC, useMemo } from "react";
import { IDMACategoryWithEffectsAndChildren } from "@netcero/netcero-core-api-client";
import { IROType } from "../../../policies/policies.types";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useRenderDMACategoryName } from "../../../double-materiality-assessment/hooks/render-dma-category-name.hook";
import { useTranslation } from "react-i18next";
import { useESRSTopicLookup } from "../../../double-materiality-assessment/esrs-topic-lookup.context";
import { EsrsTopicBadge } from "../../../double-materiality-assessment/components/esrs-topic-badge.component";

interface IPoliciesIROTabComponentProps {
  categories: IDMACategoryWithEffectsAndChildren[];
  iroType: IROType;
  checkedIds: string[];
  doAnyOfTypeExist: boolean;
  onChange: (checkedIds: string[]) => void;
  disabled?: boolean;
}

export const AssociatedIROsTabComponent: FC<IPoliciesIROTabComponentProps> = ({
  iroType,
  checkedIds,
  categories,
  doAnyOfTypeExist,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation("associated_iros_tab_component");
  const translateDMACategory = useRenderDMACategoryName();
  const esrsTopicLookup = useESRSTopicLookup();

  const filteredCategories = useMemo(() => {
    // only include categories that are still relevant
    return categories.filter((c) => c[iroType].length > 0);
  }, [categories, iroType]);

  const handleChange = (id: string) => {
    const newIds = checkedIds.includes(id)
      ? checkedIds.filter((c) => c !== id)
      : [...checkedIds, id];
    newIds.sort();
    onChange(newIds);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {/* Show message in case no IROs could be found */}
      {filteredCategories.length === 0 && (
        <Typography>
          {t(doAnyOfTypeExist ? `no_results.${iroType}` : `no_entries.${iroType}`)}
        </Typography>
      )}

      {/* Render all categories with IROs*/}
      {filteredCategories.map((c) => {
        return (
          <Box key={c.id}>
            {/* Category title */}
            <Typography variant="subtitle1" flex={1}>
              <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                {translateDMACategory(c)}
                {c.recordedTopicESRSTopicId && (
                  <EsrsTopicBadge
                    topic={esrsTopicLookup(c.recordedTopicESRSTopicId)}
                    tooltipPlacement="right"
                  />
                )}
              </Box>
            </Typography>
            {/* All IROs of the category */}
            <Box display="flex" flexDirection="column">
              {c[iroType].map((iro) => (
                <FormControlLabel
                  key={iro.id}
                  control={
                    <Checkbox
                      size="medium"
                      checked={checkedIds.includes(iro.id)}
                      onChange={() => handleChange(iro.id)}
                    />
                  }
                  label={iro.title}
                  disabled={disabled}
                />
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
