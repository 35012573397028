import { Dispatch, FC, useCallback, useEffect, useMemo } from "react";
import { EditDialogWrapper } from "../common/dialogs/variants/edit-dialog.wrapper";
import {
  IBaseTargetData,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IIntercomEntity,
  ITarget,
  ITargetDataGeneral,
} from "@netcero/netcero-core-api-client";
import { Box, Divider, IconButton, Skeleton, Tooltip } from "@mui/material";
import { MdrTopicBreadcrumb } from "../minimum-disclosure-requirements-common/components/mdr-topic-breadcrumb.component";
import { FormatTranslation } from "../common/components/format-translation.component";
import { DeleteIcon } from "../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";
import { ITranslatableError } from "../common/hooks/translate-error.hook";
import { FormProvider, useForm } from "react-hook-form";
import { MdrUtilities } from "../minimum-disclosure-requirements-common/mdr.utilities";
import { KeysForTarget, TargetsUtilities } from "@netcero/netcero-common";
import { ALL_SECTIONS_CONFIG, ALL_SECTIONS_ORDER, GENERAL } from "./target-inputs.constants";
import { QueriesWrapper } from "../common/components/queries-wrapper.component";
import { usePoliciesQuery } from "../policies/policies.queries";
import { useDMACategoriesQuery } from "../double-materiality-assessment/dma.queries";
import { useActionsQuery } from "../actions/actions.queries";
import { TargetTopicComponent } from "./components/target-topic.component";
import {
  ITargetEditDialogData,
  TargetEDataOnly,
  TargetsAppUtilities,
  TargetSDataOnly,
} from "./targets-app.utilities";
import { MdrAssociatedUsersAndSourcesInputs } from "../minimum-disclosure-requirements-common/components/mdr-associated-users-and-sources-inputs.component";
import { IntercomReferenceWidget } from "../intercom-references/intercom-reference.widget";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { ESRSTopicLookupContextProvider } from "../double-materiality-assessment/esrs-topic-lookup.context";

const getFormDefaultValues = (
  target: ITarget | undefined | null,
  identifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
): ITargetEditDialogData => {
  const identifierKey = TargetsAppUtilities.getIdentifierKeyForTopic(identifier);

  const result: ITargetEditDialogData = {
    ...(target ? TargetsUtilities.convertTargetToTargetData(target) : {}),
    associatedTopics: target?.associatedTopics ?? [identifier],
    materialImpactIds: target?.materialImpactIds ?? [],
    financialEffectIds: target?.financialEffectIds ?? [],
    policyIds: target?.policyIds ?? [],
    actionIds: target?.actionIds ?? [],
    general: MdrUtilities.getDefaultValuesForConfiguration<ITargetDataGeneral>(
      GENERAL,
      target?.general,
    ),
    responsibleUserId: target?.responsibleUserId ?? null,
    contributingUserIds: target?.contributingUserIds ?? [],
    sourceIds: target?.sourceIds ?? [],
  };

  if (
    // S Sections
    TargetsAppUtilities.isSTopic(identifierKey)
  ) {
    // S Sections general
    result.s = MdrUtilities.getDefaultValuesForConfiguration<TargetSDataOnly>(
      ALL_SECTIONS_CONFIG["s"],
      target?.s,
    );
    // S Sections specifics
    result.s[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
      ALL_SECTIONS_CONFIG[identifierKey],
      target?.s?.[identifierKey],
    ) as never;
  } else if (
    // E Sections
    TargetsAppUtilities.isETopic(identifierKey)
  ) {
    // E Sections general
    result.e = MdrUtilities.getDefaultValuesForConfiguration<TargetEDataOnly>(
      ALL_SECTIONS_CONFIG["e"],
      target?.e,
    );
    // E Sections specifics
    result.e[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
      ALL_SECTIONS_CONFIG[identifierKey],
      target?.e?.[identifierKey],
    ) as never;
    // E2 to E5
    if (TargetsAppUtilities.isE2ToE5(identifierKey)) {
      result.e.e2ToE5 = MdrUtilities.getDefaultValuesForConfiguration(
        ALL_SECTIONS_CONFIG.e2ToE5,
        target?.e?.e2ToE5,
      );
    }
  }

  return result;
};

interface ITargetEditDialogPropsBase {
  organizationId: string;
  recordingPeriodId: string;
  rootDataEntryObjectId: string;
  open: boolean;
  onClose: Dispatch<IBaseTargetData | null>;
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  loading: boolean;
  error: ITranslatableError | null | undefined;
  disabled?: boolean;
}

interface ITargetEditDialogPropsCreate extends ITargetEditDialogPropsBase {
  target?: undefined;
  onDelete?: undefined;
}

interface ITargetEditDialogPropsEdit extends ITargetEditDialogPropsBase {
  target: ITarget | undefined | null;
  onDelete: Dispatch<ITarget>;
}

type ITargetEditDialogProps = ITargetEditDialogPropsCreate | ITargetEditDialogPropsEdit;

export const TargetEditDialog: FC<ITargetEditDialogProps> = ({
  organizationId,
  recordingPeriodId,
  rootDataEntryObjectId,
  open,
  onClose,
  onDelete,
  topicIdentifier,
  target,
  loading,
  error,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_target_component");
  const mode = useMemo(() => (target ? "edit" : "create"), [target]);
  const identifierKey = useMemo(
    () => TargetsAppUtilities.getIdentifierKeyForTopic(topicIdentifier),
    [topicIdentifier],
  );

  const policiesQuery = usePoliciesQuery(organizationId, recordingPeriodId);
  const actionsQuery = useActionsQuery(organizationId, recordingPeriodId);
  const dmaCategoriesQuery = useDMACategoriesQuery(
    organizationId,
    recordingPeriodId,
    rootDataEntryObjectId,
  );

  const useFormResult = useForm<ITargetEditDialogData>({
    defaultValues: getFormDefaultValues(target, topicIdentifier),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
    trigger,
  } = useFormResult;
  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(target, topicIdentifier));
    }
    // This is fine since reset should only be triggered on open
    // eslint-disable-next-line
  }, [open]);

  const validateDates = useCallback(
    (_: unknown, formData: ITargetEditDialogData) => {
      if (
        formData.general.periodToWhichTargetAppliesStartYear !== null &&
        formData.general.periodToWhichTargetAppliesStartYear !== undefined &&
        formData.general.periodToWhichTargetAppliesEndYear !== null &&
        formData.general.periodToWhichTargetAppliesEndYear !== undefined &&
        formData.general.periodToWhichTargetAppliesStartYear >
          formData.general.periodToWhichTargetAppliesEndYear
      ) {
        return t("input_errors.start_year_after_end_year");
      }
      return undefined;
    },
    [t],
  );

  const rules: Partial<Record<KeysForTarget, UseControllerProps<ITargetEditDialogData>["rules"]>> =
    useMemo(
      () => ({
        "general.periodToWhichTargetAppliesStartYear": {
          validate: validateDates,
        },
        "general.periodToWhichTargetAppliesEndYear": {
          validate: validateDates,
        },
      }),
      [validateDates],
    );

  // Revalidate Dates on Changes
  const periodToWhichTargetAppliesStartYear = watch("general.periodToWhichTargetAppliesStartYear");
  const periodToWhichTargetAppliesEndYear = watch("general.periodToWhichTargetAppliesEndYear");
  useEffect(() => {
    void trigger([
      "general.periodToWhichTargetAppliesStartYear",
      "general.periodToWhichTargetAppliesEndYear",
    ]);
  }, [periodToWhichTargetAppliesStartYear, periodToWhichTargetAppliesEndYear, trigger]);

  const handleEmitData = useCallback(
    (data: ITargetEditDialogData) => {
      const result = {
        ...data,
        responsibleUserId: data.responsibleUserId ?? undefined,
        general: MdrUtilities.convertToApiPayload(data.general, ALL_SECTIONS_CONFIG["general"]),
      };

      if (TargetsAppUtilities.isSTopic(identifierKey)) {
        result.s = {
          ...data.s!,
          ...MdrUtilities.convertToApiPayload<TargetSDataOnly>(data.s!, ALL_SECTIONS_CONFIG["s"]),
          [identifierKey]: MdrUtilities.convertToApiPayload(
            data.s![identifierKey],
            ALL_SECTIONS_CONFIG[identifierKey],
          ),
        };
      } else if (TargetsAppUtilities.isETopic(identifierKey)) {
        result.e = {
          ...data.e!,
          ...MdrUtilities.convertToApiPayload<TargetEDataOnly>(data.e!, ALL_SECTIONS_CONFIG["e"]),
          [identifierKey]: MdrUtilities.convertToApiPayload(
            data.e![identifierKey],
            ALL_SECTIONS_CONFIG[identifierKey],
          ),
        };
        // E2 to E5
        if (TargetsAppUtilities.isE2ToE5(identifierKey)) {
          result.e.e2ToE5 = MdrUtilities.convertToApiPayload(
            data.e?.e2ToE5,
            ALL_SECTIONS_CONFIG.e2ToE5,
          );
        }
      } else {
        // Do nothing here as those topics have no topic-specific data, i.e. they don't require further processing
      }
      // as IBaseTargetData is fine since all relevant data is converted before to only be undefined instead of null.
      onClose(result as IBaseTargetData);
    },
    [identifierKey, onClose],
  );

  return (
    <EditDialogWrapper
      open={open}
      mode={mode}
      hasChanges={isDirty}
      onCancel={() => onClose(null)}
      onSave={handleSubmit(handleEmitData)}
      loading={loading}
      error={error}
      dialogProps={{
        fullWidth: true,
        maxWidth: "xl",
      }}
      title={
        <Box display="flex" alignItems="center" gap={2}>
          <Box flex={1}>
            {/* Breadcrumbs */}
            <MdrTopicBreadcrumb topicIdentifier={topicIdentifier} />
            {/* Actual title*/}
            <span>
              <FormatTranslation
                i18nKey={mode === "create" ? "create_target" : "edit_target"}
                t={t}
                values={{ title: target?.general.name }}
              />
              {mode === "create" && (
                <IntercomReferenceWidget
                  entityType={IIntercomEntity.Target}
                  identifier={`dialog_heading_create_help.${topicIdentifier}`}
                  renderIfNoArticleFound={
                    <IntercomReferenceWidget
                      entityType={IIntercomEntity.Target}
                      identifier="dialog_heading_create_help.common"
                    />
                  }
                />
              )}
            </span>
          </Box>
          {/* Delete button */}
          {onDelete && target && (
            <>
              <Divider orientation="vertical" flexItem />
              <Tooltip title={t("delete_target_tooltip")}>
                <span>
                  <IconButton onClick={() => onDelete(target)} disabled={loading}>
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
        </Box>
      }
    >
      {/* General section (includes associated entities) */}
      <FormProvider {...useFormResult}>
        {/* Linked Users & Sources */}
        <MdrAssociatedUsersAndSourcesInputs organizationId={organizationId} disabled={disabled} />
        {/* General Inputs */}
        <QueriesWrapper
          queries={[policiesQuery, actionsQuery, dmaCategoriesQuery]}
          loadingOverride={() => <Skeleton variant="rounded" height={56} sx={{ my: 2 }} />}
          build={([policies, actions, { dmaCategories, esrsTopics }]) => (
            <ESRSTopicLookupContextProvider topics={esrsTopics}>
              <TargetTopicComponent
                topicPrefix="general"
                control={control}
                inputs={ALL_SECTIONS_ORDER["general"]}
                inputsMetaData={ALL_SECTIONS_CONFIG["general"]}
                watch={watch}
                inputsRules={rules}
                disabled={!!disabled}
                dmaCategories={dmaCategories}
                policies={policies}
                actions={actions}
              />
            </ESRSTopicLookupContextProvider>
          )}
        />
        {TargetsAppUtilities.isETopic(identifierKey) && (
          <>
            {/* E Common */}
            <Divider sx={{ my: 3 }} />
            <TargetTopicComponent
              sectionTitle={t("section_titles.esrs_e")}
              topicPrefix="e"
              control={control}
              inputs={ALL_SECTIONS_ORDER["e"]}
              inputsMetaData={ALL_SECTIONS_CONFIG["e"]}
              watch={watch}
              inputsRules={rules}
              disabled={!!disabled}
            />
            {/* E2 to E5 common */}
            {TargetsAppUtilities.isE2ToE5(identifierKey) && (
              <>
                <Divider sx={{ my: 3 }} />
                <TargetTopicComponent
                  sectionTitle={t(`section_titles.esrs_e2_to_e5`)}
                  topicPrefix="e.e2ToE5"
                  control={control}
                  inputs={ALL_SECTIONS_ORDER.e2ToE5}
                  inputsMetaData={ALL_SECTIONS_CONFIG.e2ToE5}
                  watch={watch}
                  inputsRules={rules}
                  disabled={!!disabled}
                />
              </>
            )}
            {/* Specific E Section inputs */}
            <Divider sx={{ my: 3 }} />
            <TargetTopicComponent
              sectionTitle={t(`section_titles.esrs_${identifierKey}`)}
              topicPrefix={`e.${identifierKey}`}
              control={control}
              inputs={ALL_SECTIONS_ORDER[identifierKey]}
              inputsMetaData={ALL_SECTIONS_CONFIG[identifierKey]}
              watch={watch}
              inputsRules={rules}
              disabled={!!disabled}
            />
          </>
        )}
        {/* S Topics */}
        {TargetsAppUtilities.isSTopic(identifierKey) && (
          <>
            <Divider sx={{ my: 3 }} />
            <TargetTopicComponent
              sectionTitle={t("section_titles.esrs_s")}
              topicPrefix="s"
              control={control}
              inputs={ALL_SECTIONS_ORDER["s"]}
              inputsMetaData={ALL_SECTIONS_CONFIG["s"]}
              watch={watch}
              inputsRules={rules}
              disabled={!!disabled}
            />
            <Divider sx={{ my: 3 }} />
            <TargetTopicComponent
              sectionTitle={t(`section_titles.esrs_${identifierKey}`)}
              topicPrefix={`s.${identifierKey}`}
              control={control}
              inputs={ALL_SECTIONS_ORDER[identifierKey]}
              inputsMetaData={ALL_SECTIONS_CONFIG[identifierKey]}
              watch={watch}
              inputsRules={rules}
              disabled={!!disabled}
            />
          </>
        )}
      </FormProvider>
    </EditDialogWrapper>
  );
};
