import React, { FC } from "react";
import { ChartColorPalette } from "../../../theme/charts-theme";
import { IconSize, PointFilledIcon } from "../constants/tabler-icon.constants";
import { SvgIcon } from "@mui/material";

interface IChartLegendColoredIndicatorProps {
  /** Index of the legend item - used to get correct color */
  index: number;
  colorPaletteOverrides?: readonly string[];
}

export const ChartLegendColoredIndicator: FC<IChartLegendColoredIndicatorProps> = ({
  index,
  colorPaletteOverrides,
}) => {
  const chartToConsider = colorPaletteOverrides ?? ChartColorPalette;
  return (
    <SvgIcon sx={{ color: chartToConsider[index % chartToConsider.length] }}>
      <PointFilledIcon size={IconSize.Medium} />
    </SvgIcon>
  );
};
