import { FC } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EvaluationBase } from "../ghg-evaluation.types";

export const SELECTED_EVALUATION_BASE = "selectedEvaluationBase";

interface IEvaluationBaseSelectorProps {
  onChange: (newValue: EvaluationBase) => void;
  selectedBase?: EvaluationBase;
}

export const EvaluationBaseSelector: FC<IEvaluationBaseSelectorProps> = ({
  onChange,
  selectedBase,
}) => {
  const { t } = useTranslation("ghg_protocol");

  return (
    <ToggleButtonGroup
      value={selectedBase}
      exclusive
      onChange={(_event, newValue) => newValue && onChange(newValue)}
    >
      <ToggleButton value={EvaluationBase.LocationBased}>
        {t("evaluation.location_based")}
      </ToggleButton>
      <ToggleButton value={EvaluationBase.MarketBased}>{t("evaluation.market_based")}</ToggleButton>
    </ToggleButtonGroup>
  );
};
