/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IActivity } from "../models";
// @ts-ignore
import { IActivityContext } from "../models";
// @ts-ignore
import { IApproveActivity403Response } from "../models";
// @ts-ignore
import { IBaseActivityData } from "../models";
// @ts-ignore
import { ICreateActivity404Response } from "../models";
// @ts-ignore
import { ICreateActivity409Response } from "../models";
// @ts-ignore
import { ICreateReferenceBudget404Response } from "../models";
// @ts-ignore
import { IDeleteActivity404Response } from "../models";
// @ts-ignore
import { IDeleteActivity409Response } from "../models";
// @ts-ignore
import { IGetActivitiesForCategoryResponse } from "../models";
// @ts-ignore
import { IGetActivityValuesForActivity404Response } from "../models";
// @ts-ignore
import { IGhgCategoriesIdentifier } from "../models";
// @ts-ignore
import { ISubmitActivity409Response } from "../models";
// @ts-ignore
import { IUpdateActivity404Response } from "../models";
// @ts-ignore
import { IUpdateSource403Response } from "../models";
// @ts-ignore
import { IWithSources } from "../models";
/**
 * ActivitiesApi - axios parameter creator
 * @export
 */
export const ActivitiesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Used to end the review process by moving the activity to \"done\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveActivity: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("approveActivity", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("approveActivity", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("approveActivity", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("approveActivity", "activityId", activityId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}/approve`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint creates a new activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IBaseActivityData} [iBaseActivityData] Create Activity Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createActivity: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iBaseActivityData?: IBaseActivityData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("createActivity", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("createActivity", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("createActivity", "ghgCategoryId", ghgCategoryId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseActivityData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint deletes a given activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteActivity: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("deleteActivity", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("deleteActivity", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("deleteActivity", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("deleteActivity", "activityId", activityId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint retrieves all activities for a given organization, recording period, and GHG category. It also filters by the current user role.
     * @summary This endpoint retrieves all activities for a given organization, recording period, and GHG category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivitiesForRecordingPeriodGhgCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "getActivitiesForRecordingPeriodGhgCategory",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "getActivitiesForRecordingPeriodGhgCategory",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists(
        "getActivitiesForRecordingPeriodGhgCategory",
        "ghgCategoryId",
        ghgCategoryId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Used to end review process by moving the activity back to \"in progress\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectActivity: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("rejectActivity", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("rejectActivity", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("rejectActivity", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("rejectActivity", "activityId", activityId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}/reject`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Used to reset the status for an activity back to in-progress
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetActivity: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("resetActivity", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("resetActivity", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("resetActivity", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("resetActivity", "activityId", activityId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}/reset`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint submits the specified activity for review.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IActivityContext} iActivityContext Used to trigger the review process for an activity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitActivity: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iActivityContext: IActivityContext,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("submitActivity", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("submitActivity", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("submitActivity", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("submitActivity", "activityId", activityId);
      // verify required parameter 'iActivityContext' is not null or undefined
      assertParamExists("submitActivity", "iActivityContext", iActivityContext);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}/submit`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iActivityContext,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint updates the activity with the given ID.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IBaseActivityData} [iBaseActivityData] Update Activity Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivity: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iBaseActivityData?: IBaseActivityData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateActivity", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("updateActivity", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("updateActivity", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("updateActivity", "activityId", activityId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseActivityData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint updates the sources of an activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IWithSources} [iWithSources] Update Activity Sources Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivitySources: async (
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iWithSources?: IWithSources,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateActivitySources", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("updateActivitySources", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'ghgCategoryId' is not null or undefined
      assertParamExists("updateActivitySources", "ghgCategoryId", ghgCategoryId);
      // verify required parameter 'activityId' is not null or undefined
      assertParamExists("updateActivitySources", "activityId", activityId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/{ghgCategoryId}/activities/{activityId}/sources`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"ghgCategoryId"}}`, encodeURIComponent(String(ghgCategoryId)))
          .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iWithSources,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ActivitiesApi - functional programming interface
 * @export
 */
export const ActivitiesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ActivitiesApiAxiosParamCreator(configuration);
  return {
    /**
     * Used to end the review process by moving the activity to \"done\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint creates a new activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IBaseActivityData} [iBaseActivityData] Create Activity Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iBaseActivityData?: IBaseActivityData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IActivity>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        iBaseActivityData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint deletes a given activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint retrieves all activities for a given organization, recording period, and GHG category. It also filters by the current user role.
     * @summary This endpoint retrieves all activities for a given organization, recording period, and GHG category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActivitiesForRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetActivitiesForCategoryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getActivitiesForRecordingPeriodGhgCategory(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Used to end review process by moving the activity back to \"in progress\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rejectActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rejectActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Used to reset the status for an activity back to in-progress
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint submits the specified activity for review.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IActivityContext} iActivityContext Used to trigger the review process for an activity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iActivityContext: IActivityContext,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submitActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        iActivityContext,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint updates the activity with the given ID.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IBaseActivityData} [iBaseActivityData] Update Activity Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iBaseActivityData?: IBaseActivityData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IActivity>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        iBaseActivityData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint updates the sources of an activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IWithSources} [iWithSources] Update Activity Sources Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateActivitySources(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iWithSources?: IWithSources,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IActivity>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivitySources(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        iWithSources,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ActivitiesApi - factory interface
 * @export
 */
export const ActivitiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ActivitiesApiFp(configuration);
  return {
    /**
     * Used to end the review process by moving the activity to \"done\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .approveActivity(organizationId, recordingPeriodId, ghgCategoryId, activityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint creates a new activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {IBaseActivityData} [iBaseActivityData] Create Activity Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      iBaseActivityData?: IBaseActivityData,
      options?: any,
    ): AxiosPromise<IActivity> {
      return localVarFp
        .createActivity(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          iBaseActivityData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint deletes a given activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteActivity(organizationId, recordingPeriodId, ghgCategoryId, activityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint retrieves all activities for a given organization, recording period, and GHG category. It also filters by the current user role.
     * @summary This endpoint retrieves all activities for a given organization, recording period, and GHG category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivitiesForRecordingPeriodGhgCategory(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      options?: any,
    ): AxiosPromise<IGetActivitiesForCategoryResponse> {
      return localVarFp
        .getActivitiesForRecordingPeriodGhgCategory(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Used to end review process by moving the activity back to \"in progress\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .rejectActivity(organizationId, recordingPeriodId, ghgCategoryId, activityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Used to reset the status for an activity back to in-progress
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .resetActivity(organizationId, recordingPeriodId, ghgCategoryId, activityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint submits the specified activity for review.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IActivityContext} iActivityContext Used to trigger the review process for an activity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iActivityContext: IActivityContext,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .submitActivity(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          activityId,
          iActivityContext,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint updates the activity with the given ID.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IBaseActivityData} [iBaseActivityData] Update Activity Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivity(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iBaseActivityData?: IBaseActivityData,
      options?: any,
    ): AxiosPromise<IActivity> {
      return localVarFp
        .updateActivity(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          activityId,
          iBaseActivityData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint updates the sources of an activity.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
     * @param {string} activityId The id of the activity to use.
     * @param {IWithSources} [iWithSources] Update Activity Sources Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivitySources(
      organizationId: string,
      recordingPeriodId: string,
      ghgCategoryId: IGhgCategoriesIdentifier,
      activityId: string,
      iWithSources?: IWithSources,
      options?: any,
    ): AxiosPromise<IActivity> {
      return localVarFp
        .updateActivitySources(
          organizationId,
          recordingPeriodId,
          ghgCategoryId,
          activityId,
          iWithSources,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ActivitiesApi - interface
 * @export
 * @interface ActivitiesApi
 */
export interface ActivitiesApiInterface {
  /**
   * Used to end the review process by moving the activity to \"done\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApiInterface
   */
  approveActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * This endpoint creates a new activity.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IBaseActivityData} [iBaseActivityData] Create Activity Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApiInterface
   */
  createActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iBaseActivityData?: IBaseActivityData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IActivity>;

  /**
   * This endpoint deletes a given activity.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApiInterface
   */
  deleteActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * This endpoint retrieves all activities for a given organization, recording period, and GHG category. It also filters by the current user role.
   * @summary This endpoint retrieves all activities for a given organization, recording period, and GHG category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApiInterface
   */
  getActivitiesForRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetActivitiesForCategoryResponse>;

  /**
   * Used to end review process by moving the activity back to \"in progress\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApiInterface
   */
  rejectActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Used to reset the status for an activity back to in-progress
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApiInterface
   */
  resetActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * This endpoint submits the specified activity for review.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {IActivityContext} iActivityContext Used to trigger the review process for an activity.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApiInterface
   */
  submitActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    iActivityContext: IActivityContext,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * This endpoint updates the activity with the given ID.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {IBaseActivityData} [iBaseActivityData] Update Activity Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApiInterface
   */
  updateActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    iBaseActivityData?: IBaseActivityData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IActivity>;

  /**
   * This endpoint updates the sources of an activity.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {IWithSources} [iWithSources] Update Activity Sources Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApiInterface
   */
  updateActivitySources(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    iWithSources?: IWithSources,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IActivity>;
}

/**
 * ActivitiesApi - object-oriented interface
 * @export
 * @class ActivitiesApi
 * @extends {BaseAPI}
 */
export class ActivitiesApi extends BaseAPI implements ActivitiesApiInterface {
  /**
   * Used to end the review process by moving the activity to \"done\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApi
   */
  public approveActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    options?: AxiosRequestConfig,
  ) {
    return ActivitiesApiFp(this.configuration)
      .approveActivity(organizationId, recordingPeriodId, ghgCategoryId, activityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint creates a new activity.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {IBaseActivityData} [iBaseActivityData] Create Activity Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApi
   */
  public createActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    iBaseActivityData?: IBaseActivityData,
    options?: AxiosRequestConfig,
  ) {
    return ActivitiesApiFp(this.configuration)
      .createActivity(organizationId, recordingPeriodId, ghgCategoryId, iBaseActivityData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint deletes a given activity.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApi
   */
  public deleteActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    options?: AxiosRequestConfig,
  ) {
    return ActivitiesApiFp(this.configuration)
      .deleteActivity(organizationId, recordingPeriodId, ghgCategoryId, activityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint retrieves all activities for a given organization, recording period, and GHG category. It also filters by the current user role.
   * @summary This endpoint retrieves all activities for a given organization, recording period, and GHG category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApi
   */
  public getActivitiesForRecordingPeriodGhgCategory(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ) {
    return ActivitiesApiFp(this.configuration)
      .getActivitiesForRecordingPeriodGhgCategory(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Used to end review process by moving the activity back to \"in progress\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApi
   */
  public rejectActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    options?: AxiosRequestConfig,
  ) {
    return ActivitiesApiFp(this.configuration)
      .rejectActivity(organizationId, recordingPeriodId, ghgCategoryId, activityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Used to reset the status for an activity back to in-progress
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApi
   */
  public resetActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    options?: AxiosRequestConfig,
  ) {
    return ActivitiesApiFp(this.configuration)
      .resetActivity(organizationId, recordingPeriodId, ghgCategoryId, activityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint submits the specified activity for review.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {IActivityContext} iActivityContext Used to trigger the review process for an activity.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApi
   */
  public submitActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    iActivityContext: IActivityContext,
    options?: AxiosRequestConfig,
  ) {
    return ActivitiesApiFp(this.configuration)
      .submitActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        iActivityContext,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint updates the activity with the given ID.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {IBaseActivityData} [iBaseActivityData] Update Activity Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApi
   */
  public updateActivity(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    iBaseActivityData?: IBaseActivityData,
    options?: AxiosRequestConfig,
  ) {
    return ActivitiesApiFp(this.configuration)
      .updateActivity(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        iBaseActivityData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint updates the sources of an activity.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IGhgCategoriesIdentifier} ghgCategoryId The identifier of the GHG category to use.
   * @param {string} activityId The id of the activity to use.
   * @param {IWithSources} [iWithSources] Update Activity Sources Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitiesApi
   */
  public updateActivitySources(
    organizationId: string,
    recordingPeriodId: string,
    ghgCategoryId: IGhgCategoriesIdentifier,
    activityId: string,
    iWithSources?: IWithSources,
    options?: AxiosRequestConfig,
  ) {
    return ActivitiesApiFp(this.configuration)
      .updateActivitySources(
        organizationId,
        recordingPeriodId,
        ghgCategoryId,
        activityId,
        iWithSources,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
