import { styled, Typography } from "@mui/material";

export const ClickableTypography = styled(Typography)(({ onClick }) =>
  onClick
    ? {
        cursor: "pointer",
        ":hover": { textDecoration: "underline" },
      }
    : undefined,
);
