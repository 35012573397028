import { ThemeOption } from "echarts/types/src/util/types";
import { useNCTheme } from "./theme";
import { useMemo } from "react";

/** Ordered [Scope1, Scope2, Scope3] */
export const GhgScopesChartColorPalette = ["#FFB74D", "#4DB6AC", "#9575CD"] as const;

export const GhgChartColorPalette = [
  "#757575",
  "#DCDCDC",
  "#9ED3AA",
  "#93C7E9",
  "#F9E593",
  "#FF7979",
  "#3A6078",
  "#FFB74D",
  "#4DB6AC",
  "#9575CD",
] as const;

export const ChartColorPalette = [
  "#31D181",
  "#009BFF",
  "#FF5F51",
  "#E18805",
  "#F9E282",
  "#9DB0A3",
  "#FF5F51",
  "#3A4A40",
  "#B72C64",
  "#F27138",
  "#000C57",
  "#FF5296",
  "#660000",
] as const;

// ECharts

export const useEChartTheme = (paletteOverride?: readonly string[]) => {
  const ncTheme = useNCTheme();
  return useMemo((): ThemeOption => {
    return {
      seriesCnt: "10",
      backgroundColor: "rgba(0, 0, 0, 0)",
      titleColor: "#464646",
      subtitleColor: "#6E7079",
      textColorShow: false,
      textColor: "#333",
      markTextColor: "#eee",
      color: paletteOverride ?? ChartColorPalette,
      borderColor: "#ccc",
      borderWidth: 0,
      visualMapColor: ["#bf444c", "#d88273", "#f6efa6"],
      legendTextColor: "#333",
      kColor: ncTheme.palette.error.main,
      kColor0: ncTheme.palette.success.main,
      kBorderColor: ncTheme.palette.error.main,
      kBorderColor0: ncTheme.palette.success.main,
      kBorderWidth: 1,
      lineWidth: 2,
      symbolSize: 4,
      symbol: "circle",
      symbolBorderWidth: 1,
      lineSmooth: false,
      graphLineWidth: 1,
      graphLineColor: "#aaa",
      mapLabelColor: "#000",
      mapLabelColorE: "rgb(100,0,0)",
      mapBorderColor: "#444",
      mapBorderColorE: "#444",
      mapBorderWidth: 0.5,
      mapBorderWidthE: 1,
      mapAreaColor: "#eee",
      mapAreaColorE: "rgba(255,215,0,0.8)",
      axes: [
        {
          type: "all",
          name: "All",
          axisLineShow: true,
          axisLineColor: "#6E7079",
          axisTickShow: true,
          axisTickColor: "#6E7079",
          axisLabelShow: true,
          axisLabelColor: "#6E7079",
          splitLineShow: true,
          splitLineColor: ["#E0E6F1"],
          splitAreaShow: false,
          splitAreaColor: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
        },
        {
          type: "category",
          name: "Category",
          axisLineShow: true,
          axisLineColor: "#6E7079",
          axisTickShow: true,
          axisTickColor: "#6E7079",
          axisLabelShow: true,
          axisLabelColor: "#6E7079",
          splitLineShow: false,
          splitLineColor: ["#E0E6F1"],
          splitAreaShow: false,
          splitAreaColor: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
        },
        {
          type: "value",
          name: "Value",
          axisLineShow: false,
          axisLineColor: "#6E7079",
          axisTickShow: false,
          axisTickColor: "#6E7079",
          axisLabelShow: true,
          axisLabelColor: "#6E7079",
          splitLineShow: true,
          splitLineColor: ["#E0E6F1"],
          splitAreaShow: false,
          splitAreaColor: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
        },
        {
          type: "log",
          name: "Log",
          axisLineShow: false,
          axisLineColor: "#6E7079",
          axisTickShow: false,
          axisTickColor: "#6E7079",
          axisLabelShow: true,
          axisLabelColor: "#6E7079",
          splitLineShow: true,
          splitLineColor: ["#E0E6F1"],
          splitAreaShow: false,
          splitAreaColor: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
        },
        {
          type: "time",
          name: "Time",
          axisLineShow: true,
          axisLineColor: "#6E7079",
          axisTickShow: true,
          axisTickColor: "#6E7079",
          axisLabelShow: true,
          axisLabelColor: "#6E7079",
          splitLineShow: false,
          splitLineColor: ["#E0E6F1"],
          splitAreaShow: false,
          splitAreaColor: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
        },
      ],
      axisSeperateSetting: true,
      toolboxColor: "#999",
      toolboxEmphasisColor: "#666",
      tooltipAxisColor: "#ccc",
      tooltipAxisWidth: 1,
      timelineLineColor: "#DAE1F5",
      timelineLineWidth: 2,
      timelineItemColor: "#A4B1D7",
      timelineItemColorE: "#FFF",
      timelineCheckColor: "#316bf3",
      timelineCheckBorderColor: "fff",
      timelineItemBorderWidth: 1,
      timelineControlColor: "#A4B1D7",
      timelineControlBorderColor: "#A4B1D7",
      timelineControlBorderWidth: 1,
      timelineLabelColor: "#A4B1D7",
    };
  }, [ncTheme.palette.error.main, ncTheme.palette.success.main]);
};
