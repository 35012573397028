import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { TCO2eqBadge } from "./tco2eq-badge.component";
import {
  CopyToClipboardButton,
  CopyToClipboardButtonAnimationProps,
} from "../../common/components/copy-to-clipboard-button.component";
import { useTranslation } from "react-i18next";

const COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME = "ghg-total-value-copy-to-clipboard-button";

interface IGhgDashboardResultProps {
  formattedValue: ReactNode;
  valueToCopy: string;
}

export const GhgDashboardResult: FC<IGhgDashboardResultProps> = ({
  formattedValue,
  valueToCopy,
}) => {
  const { t } = useTranslation("ghg_protocol");

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        ...CopyToClipboardButtonAnimationProps(`.${COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}`),
      }}
    >
      {formattedValue}
      <Box width={8} />
      <TCO2eqBadge />
      <Box className={COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}>
        <CopyToClipboardButton
          size="small"
          disableRipple
          value={valueToCopy}
          tooltip={t("tooltip_copy_value", { ns: "buttons" })}
          tooltipSuccess={t("tooltip_copy_value_success", { ns: "buttons" })}
          tooltipPlacement="right"
        />
      </Box>
    </Box>
  );
};
