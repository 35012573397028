import React, { Dispatch, FC } from "react";
import { Tab, Tabs } from "@mui/material";
import { ORDERED_GHG_PROTOCOL_SCOPES } from "../ghg-protocol.constants";
import { GhgScopeName } from "./ghg-scope-name.component";
import { IGhgProtocolScopeEnum } from "@netcero/netcero-core-api-client";

interface IGhgScopeTabsProps {
  value: IGhgProtocolScopeEnum;
  onChange: Dispatch<IGhgProtocolScopeEnum>;
  /* Optional scopes to display (ordered) */
  scopes?: IGhgProtocolScopeEnum[];
}

export const GhgScopeTabs: FC<IGhgScopeTabsProps> = ({ value, onChange, scopes }) => {
  return (
    <Tabs value={value} onChange={(_, newScope) => onChange(newScope)} variant="fullWidth">
      {(scopes ?? ORDERED_GHG_PROTOCOL_SCOPES).map((scope) => (
        <Tab
          key={scope}
          value={scope}
          label={<GhgScopeName ghgScope={scope} options={{ style: "long" }} />}
        />
      ))}
    </Tabs>
  );
};
