import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GhgActivityValuesTableRow } from "./ghg-activity-values-table-row.component";
import { IInternalFormActivityValueData } from "../ghg-activity-values.types";
import { GhgActivityValuesUtilities } from "../ghg-activity-values.utilities";
import { AddIcon } from "../../common/constants/tabler-icon.constants";
import { HeadingCellWithOptionalText } from "../../common/components/heading-cell-with-optional-text.component";
import { UseFieldArrayAppend } from "react-hook-form/dist/types/fieldArray";

export interface IGhgActivityValuesTableFormRef {
  append: UseFieldArrayAppend<IActivityValuesFormData, "activityValues">;
}

export interface IActivityValuesFormData {
  activityValues: IInternalFormActivityValueData[];
}

export enum IGhgActivityValuesTableMode {
  Read = "read",
  Edit = "edit",
}

interface IGhgActivityValuesTableFormProps {
  mode: IGhgActivityValuesTableMode;
  onStartEditing: VoidFunction;
  selectableEmissionFactorIds: string[];
  loading: boolean;
  disabled: boolean;
}

export const GhgActivityValuesTableForm = forwardRef<
  IGhgActivityValuesTableFormRef,
  IGhgActivityValuesTableFormProps
>(function GhgActivityValuesTableForm(
  { mode, onStartEditing, selectableEmissionFactorIds, loading, disabled },
  ref,
) {
  const { t } = useTranslation("ghg_activity_values", { keyPrefix: "table" });

  const { control } = useFormContext<IActivityValuesFormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "activityValues",
  });

  const [lastAddedIndex, setLastAddedIndex] = useState<number | null>(null);

  // Imperative Handle
  useImperativeHandle(ref, () => ({
    append,
  }));

  const handleAddRow = useCallback(() => {
    const newValue = {
      ...GhgActivityValuesUtilities.DEFAULT_VALUES,
    };
    if (selectableEmissionFactorIds.length === 1) {
      newValue.emissionFactorId = selectableEmissionFactorIds[0];
    }
    append(newValue);
    setLastAddedIndex(fields.length);
    onStartEditing();
  }, [selectableEmissionFactorIds, append, fields.length, onStartEditing]);

  // Reset last added index on next render,
  // because it was used to set the focus on the newly added row,
  // and we don't want/need to keep it
  useEffect(() => {
    if (lastAddedIndex !== null) {
      setLastAddedIndex(null);
    }
  }, [lastAddedIndex]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("heading_factor")}</TableCell>
            <TableCell>{t("heading_value")}</TableCell>
            <TableCell>{t("heading_unit")}</TableCell>
            <HeadingCellWithOptionalText text={t("heading_data_quality")} />
            <HeadingCellWithOptionalText text={t("heading_timespan")} />
            <HeadingCellWithOptionalText text={t("heading_note")} />
            {/* Actions Row */}
            <TableCell />
          </TableRow>
        </TableHead>
        {!loading && (
          <TableBody>
            {fields.map(({ id }, rowIndex) => (
              <GhgActivityValuesTableRow
                key={id}
                mode={mode}
                index={rowIndex}
                selectableEmissionFactorIds={selectableEmissionFactorIds}
                onStartEditing={onStartEditing}
                onDelete={() => remove(rowIndex)}
                disabled={disabled}
                isNewlyAdded={lastAddedIndex === rowIndex}
              />
            ))}
          </TableBody>
        )}
      </Table>
      {/* Empty State */}
      {!loading && fields.length === 0 && (
        <Typography pt={2} textAlign="center">
          {t("no_values_yet")}
        </Typography>
      )}
      {/* Loading State */}
      {loading && (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      )}
      {!loading && selectableEmissionFactorIds.length > 0 && (
        <IconButton onClick={handleAddRow} sx={{ my: 1 }} disabled={disabled}>
          <AddIcon />
        </IconButton>
      )}
    </TableContainer>
  );
});
