import React, { FC, useCallback, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { BaseChart, EChartClickHandler } from "../../common/charts/base-chart.component";
import { ChartLegendColoredIndicator } from "../../common/charts/chart-legend-colored-indicator.component";
import { ClickableTypography } from "../../common/components/clickable-typography.component";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import {
  FilterUtilities,
  GhgCategoriesUtilities,
  GhgEvaluationUtilities,
  NumberUtilities,
} from "@netcero/netcero-common";
import { useTranslation } from "react-i18next";
import { GhgEvaluationAppUtilities } from "../ghg-evaluation-app.utilities";
import { useRenderGhgScopeName } from "../../ghg-protocol/ghg-protocol.hooks";
import { GhgScopeName } from "../../ghg-protocol/components/ghg-scope-name.component";
import { useGhgDashboardContext } from "../ghg-dashboard.context";
import { GhgScopesChartColorPalette } from "../../../theme/charts-theme";
import { IGhgProtocolScopeEnum } from "@netcero/netcero-core-api-client";
import { EvaluationBase } from "../ghg-evaluation.types";
import { GhgDashboardResult } from "./ghg-dashboard-result.component";
import { ORDERED_GHG_PROTOCOL_SCOPES } from "../../ghg-protocol/ghg-protocol.constants";

interface IChartDataElement {
  name: string;
  value: number;
  scope: IGhgProtocolScopeEnum;
}

export const GhgDashboardByEmissionGroup: FC = () => {
  const { t } = useTranslation("ghg_protocol", { keyPrefix: "evaluation" });
  const renderGhgScopeName = useRenderGhgScopeName();

  const {
    recordingStructure,
    viewedScope,
    changeViewedScope,
    activeView,
    relevantEvaluationResultsForDeoTree,
  } = useGhgDashboardContext();

  const handleScopeClicked: EChartClickHandler = useCallback(
    (params) => {
      const scope = (params.data as IChartDataElement).scope;
      if (viewedScope !== scope) {
        changeViewedScope(scope);
      }
    },
    [changeViewedScope, viewedScope],
  );

  const recordingStructureScopes = useMemo(
    () => GhgCategoriesUtilities.getDefinitionForRecordingStructure(recordingStructure),
    [recordingStructure],
  );

  const resultsByGhgCategory = useMemo(
    () =>
      GhgEvaluationUtilities.calculateEmissionSumsPerCategoryInTCO2Eq(
        relevantEvaluationResultsForDeoTree,
      ),
    [relevantEvaluationResultsForDeoTree],
  );

  const scopeTotals = useMemo(
    () =>
      ORDERED_GHG_PROTOCOL_SCOPES.map((scope) => ({
        scope,
        sums: GhgEvaluationUtilities.sumUpGhgEvaluationsSums(
          FilterUtilities.filterNotUndefined(
            recordingStructureScopes[scope].map((category) => resultsByGhgCategory[category]),
          ),
        ),
      })),
    [recordingStructureScopes, resultsByGhgCategory],
  );

  const shownScopeTotals = useMemo(
    () =>
      scopeTotals.map(({ scope, sums }) => ({
        scope,
        total:
          activeView === EvaluationBase.LocationBased
            ? sums.locationBasedTotal
            : sums.marketBasedTotal,
      })),
    [activeView, scopeTotals],
  );

  const overallTotal = useMemo(
    () => NumberUtilities.sum(shownScopeTotals.map(({ total }) => total)),
    [shownScopeTotals],
  );

  const chartOptions = useMemo(
    () =>
      GhgEvaluationAppUtilities.getEChartPieChartOptions(
        shownScopeTotals.map(({ scope, total }) => ({
          name: renderGhgScopeName(scope, { style: "long" }),
          value: total,
          scope,
        })),
        overallTotal,
      ),
    [renderGhgScopeName, shownScopeTotals, overallTotal],
  );

  const anyValueShown = overallTotal !== 0;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontSize={20} fontWeight={700}>
        {t("heading_by_emission_group")}
      </Typography>
      <Box display="flex" alignItems="center" gap={2}>
        {/* Chart */}
        <Box width={220} height={220} display="flex" justifyContent="center" alignItems="center">
          {anyValueShown ? (
            <BaseChart
              width="100%"
              options={chartOptions}
              onClick={handleScopeClicked}
              themeOverrides={{
                themeName: "ghg-by-scope",
                colorPaletteOverrides: GhgScopesChartColorPalette,
              }}
            />
          ) : (
            <Typography>{t("no_emissions_notice")}</Typography>
          )}
        </Box>
        {/* Table of Contents*/}
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          gap={1.25}
          py={1}
          sx={{ "> :last-child": { borderBottom: "none" } }}
        >
          {shownScopeTotals.map(({ scope, total }, index) => (
            <Box
              key={scope}
              display="flex"
              alignItems="start"
              gap={1}
              pl={1.5}
              pr={0.5}
              pb={1}
              borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
            >
              <ChartLegendColoredIndicator
                index={index}
                colorPaletteOverrides={GhgScopesChartColorPalette}
              />
              <ClickableTypography
                onClick={scope !== viewedScope ? () => changeViewedScope(scope) : undefined}
                sx={{
                  textDecoration: scope === viewedScope ? "underline" : undefined,
                }}
              >
                <GhgScopeName ghgScope={scope} options={{ style: "long" }} />
              </ClickableTypography>
              <Box flex={1} />
              <GhgDashboardResult
                formattedValue={
                  <Typography>{FormatUtilities.formatGhgEvaluationResult(total)}</Typography>
                }
                valueToCopy={total.toString()}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
