import { FC } from "react";
import { Tooltip } from "@mui/material";
import { SourcesVerification } from "@netcero/netcero-common";
import { ISource } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { AppWarningIcon } from "../../common/components/app-warning-icon.component";

interface ISourceCannotBeReferencedWarningProps {
  source: ISource;
}

export const SourceCannotBeReferencedWarning: FC<ISourceCannotBeReferencedWarningProps> = ({
  source,
}) => {
  return (
    !SourcesVerification.canSourceBeReferenced(source) && <SourceCannotBeReferencedWarningIcon />
  );
};

export const SourceCannotBeReferencedWarningIcon: FC = () => {
  const { t } = useTranslation("sources");

  return (
    <Tooltip title={t("warnings.cannot_be_linked")} placement="bottom">
      <AppWarningIcon fontSize="small" />
    </Tooltip>
  );
};
