import { IContentLanguage, IEmissionFactorUnit } from "@netcero/netcero-core-api-client";

export type IRenderEmissionFactorUnitType = "short" | "long";

const EMISSION_FACTOR_UNIT_TRANSLATIONS_DE: Record<
  IRenderEmissionFactorUnitType,
  Record<IEmissionFactorUnit, string>
> = {
  short: {
    [IEmissionFactorUnit.FZkm]: "FZkm",
    [IEmissionFactorUnit.Pkm]: "Pkm",
    [IEmissionFactorUnit.Tkm]: "tkm",
    [IEmissionFactorUnit.T]: "t",
    [IEmissionFactorUnit.L]: "l",
    [IEmissionFactorUnit.M2]: "m²",
    [IEmissionFactorUnit.KWh]: "kWh",
    [IEmissionFactorUnit.MioEuros]: "Mio. €",
    [IEmissionFactorUnit.KgCo2eq]: "kg CO₂e",
  },
  long: {
    [IEmissionFactorUnit.FZkm]: "Fahrzeugkilometer",
    [IEmissionFactorUnit.Pkm]: "Personenkilometer",
    [IEmissionFactorUnit.Tkm]: "Tonnenkilometer",
    [IEmissionFactorUnit.T]: "Tonne",
    [IEmissionFactorUnit.L]: "Liter",
    [IEmissionFactorUnit.M2]: "Quadratmeter",
    [IEmissionFactorUnit.KWh]: "Kilowattstunde",
    [IEmissionFactorUnit.MioEuros]: "Millionen Euro",
    [IEmissionFactorUnit.KgCo2eq]: "Kilogramm CO₂e",
  },
};

const EMISSION_FACTOR_UNIT_TRANSLATIONS_EN: Record<
  IRenderEmissionFactorUnitType,
  Record<IEmissionFactorUnit, string>
> = {
  short: {
    [IEmissionFactorUnit.FZkm]: "vkm",
    [IEmissionFactorUnit.Pkm]: "pkm",
    [IEmissionFactorUnit.Tkm]: "tkm",
    [IEmissionFactorUnit.T]: "t",
    [IEmissionFactorUnit.L]: "l",
    [IEmissionFactorUnit.M2]: "m²",
    [IEmissionFactorUnit.KWh]: "kWh",
    [IEmissionFactorUnit.MioEuros]: "€ mio",
    [IEmissionFactorUnit.KgCo2eq]: "kg CO₂e",
  },
  long: {
    [IEmissionFactorUnit.FZkm]: "Vehicle kilometer",
    [IEmissionFactorUnit.Pkm]: "Passenger kilometer",
    [IEmissionFactorUnit.Tkm]: "Tonne kilometer",
    [IEmissionFactorUnit.T]: "Tonne",
    [IEmissionFactorUnit.L]: "Liter",
    [IEmissionFactorUnit.M2]: "Square meter",
    [IEmissionFactorUnit.KWh]: "Kilowatt hour",
    [IEmissionFactorUnit.MioEuros]: "Million euros",
    [IEmissionFactorUnit.KgCo2eq]: "Kilogram CO₂e",
  },
};

export const EMISSION_FACTOR_UNIT_TRANSLATIONS_PER_LANGUAGE: Record<
  IContentLanguage,
  typeof EMISSION_FACTOR_UNIT_TRANSLATIONS_DE
> = {
  [IContentLanguage.De]: EMISSION_FACTOR_UNIT_TRANSLATIONS_DE,
  [IContentLanguage.En]: EMISSION_FACTOR_UNIT_TRANSLATIONS_EN,
};
