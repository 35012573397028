import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { useCallback } from "react";
import { IAllESRSTopicsResponse, IESRSTopic } from "@netcero/netcero-core-api-client";

/**
 * Gets the query key for the DMA Configuration
 * @param organizationId The organization id the recording period belongs to
 * @param recordingPeriodId The recording period id to get the dma configuration for
 * @returns The Query Key for the DMA Configuration
 */
export const getDMAConfigurationQueryKey = (organizationId: string, recordingPeriodId: string) => [
  "dma-configuration",
  organizationId,
  recordingPeriodId,
];

/**
 * Gets the DMA configuration for a given recording period
 * @param organizationId The organization id the recording period belongs to
 * @param recordingPeriodId The recording period id to get the dma configuration for
 * @returns The fetched DMA configuration
 */
export const useDMAConfigurationQuery = (organizationId: string, recordingPeriodId: string) => {
  return useQuery({
    queryKey: getDMAConfigurationQueryKey(organizationId, recordingPeriodId),
    queryFn: () =>
      CoreApiService.DMAApi.getDmaConfiguration(organizationId, recordingPeriodId)
        .then((req) => req())
        .then((res) => res.data),
  });
};

/**
 * This is used to remove cached ESRS Topics for *all* DEOs of a given RP.
 */
export const getESRSTopicsQueryKeyWithoutDEO = (
  organizationId: string,
  recordingPeriodId: string,
) => ["esrs-topics", organizationId, recordingPeriodId];

/**
 * Gets the query key for all double materiality categories for a given data entry object
 * @param organizationId The organization id
 * @param recordingPeriodId The recording period id
 * @param dataEntryObjectId The data entry object id to get dma categories and values for
 * @returns Query key
 */
export const getESRSTopicsQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId?: string,
) => [...getESRSTopicsQueryKeyWithoutDEO(organizationId, recordingPeriodId), dataEntryObjectId];

/**
 * Gets all ESRS topics for a given data entry object.
 * @param organizationId The organization id
 * @param recordingPeriodId The recording period id
 * @param dataEntryObjectId The data entry object id to get the ESRS topics for
 * @param select The selector function to extract the data from the response
 * @returns Query result
 */
export function useESRSTopicsQuery<T = IAllESRSTopicsResponse>(
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId?: string,
  select?: (data: IAllESRSTopicsResponse) => T,
) {
  return useQuery({
    queryKey: getESRSTopicsQueryKey(organizationId, recordingPeriodId, dataEntryObjectId!),
    queryFn: () =>
      CoreApiService.ESRSTopicsApi.getAllEsrsTopics(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId!,
      )
        .then((req) => req())
        .then((res) => res.data),
    enabled: !!dataEntryObjectId,
    select,
  });
}

/**
 * Gets all DMA Categories (inside ESRS Topics) for a given data entry object.
 * @param organizationId The organization id
 * @param recordingPeriodId The recording period id
 * @param dataEntryObjectId The data entry object id to get the ESRS topics for
 * @returns Query result
 */
export const useDMACategoriesQuery = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId?: string,
) => {
  const extractTopicsAndRootDMACategories = useCallback(
    ({ esrsTopics }: IAllESRSTopicsResponse) => ({
      esrsTopics,
      dmaCategories: esrsTopics.flatMap(
        (esrsTopic: IESRSTopic) => esrsTopic.recordedESRSTopic?.dmaCategories ?? [],
      ),
    }),
    [],
  );

  return useESRSTopicsQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    extractTopicsAndRootDMACategories,
  );
};

export const getAllManualFeedbacksForDmaCategoryQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
  esrsTopicId: string,
  dmaCategoryId: string,
) => [
  "manual-stakeholder-feedbacks",
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  esrsTopicId,
  dmaCategoryId,
];

/**
 * Gets all manual feedbacks for a given DMA category
 * @param organizationId The organization id
 * @param recordingPeriodId The recording period id
 * @param dataEntryObjectId The data entry object id
 * @param esrsTopicId The ESRS topic id
 * @param dmaCategoryId The DMA category id
 * @returns Query result
 * */
export const useAllManualFeedbacksForDmaCategoryQuery = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
  esrsTopicId: string | null | undefined,
  dmaCategoryId: string | null | undefined,
) =>
  useQuery({
    enabled: !!esrsTopicId && !!dmaCategoryId,
    queryKey: getAllManualFeedbacksForDmaCategoryQueryKey(
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId!,
      dmaCategoryId!,
    ),
    queryFn: () =>
      CoreApiService.DMACategoryManualFeedbackApi.getAllManualFeedbacks(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId!,
        dmaCategoryId!,
      )
        .then((req) => req())
        .then((res) => res.data),
  });

export const getAllStakeholderFeedbackForCategoryQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
  esrsTopicId: string,
  dmaCategoryId: string,
) => [
  "stakeholder-feedbacks",
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  esrsTopicId,
  dmaCategoryId,
];

/**
 * Gets all stakeholder feedbacks for a given DMA category
 * @param organizationId The organization id
 * @param recordingPeriodId The recording period id
 * @param dataEntryObjectId The data entry object id
 * @param esrsTopicId The ESRS topic id
 * @param dmaCategoryId The DMA category id
 * @returns Query result
 * */
export const useAllStakeholderFeedbacksForCategoryQuery = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
  esrsTopicId: string | null | undefined,
  dmaCategoryId: string | null | undefined,
) =>
  useQuery({
    enabled: !!esrsTopicId && !!dmaCategoryId,
    queryKey: getAllStakeholderFeedbackForCategoryQueryKey(
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId!,
      dmaCategoryId!,
    ),
    queryFn: () =>
      CoreApiService.DMACategoryStakeholderFeedbackApi.getAllStakeholderFeedbacks(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId!,
        dmaCategoryId!,
      )
        .then((req) => req())
        .then((res) => res.data),
  });

export const getDmaDataEntryObjectConfigurationQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
) => ["dma-deo-configuration", organizationId, recordingPeriodId, dataEntryObjectId];

/**
 * Gets the DMA configuration for a given DEO
 * @param organizationId
 * @param recordingPeriodId
 * @param dataEntryObjectId
 */
export const useDmaDataEntryObjectConfigurationQuery = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
) => {
  return useQuery({
    queryKey: getDmaDataEntryObjectConfigurationQueryKey(
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
    ),
    queryFn: () =>
      CoreApiService.DMAApi.getDmaConfigurationForDataEntryObject(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
      )
        .then((req) => req())
        .then((res) => res.data),
  });
};
