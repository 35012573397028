import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { useRecordingPeriodsQuery } from "../../modules/recording-periods/recording-periods.queries";
import { useNavigate, useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { RecordingPeriodsEditListComponent } from "../../modules/recording-periods/recording-periods-edit-list.component";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useTranslation } from "react-i18next";
import {
  AppBreadCrumbs,
  IBreadCrumb,
} from "../../modules/common/components/app-bread-crumbs.component";
import { PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT } from "../../modules/common/components/app-shell-wrapper.component";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";

export const RecordingPeriodsConfigurationPage: FC = () => {
  const { organizationId } = useParams();
  const { t } = useTranslation("side_bar_nav_items");

  const breadCrumbItems: IBreadCrumb[] = [
    {
      label: t("configuration"),
      to: AppInternalNavigationUtilities.getConfigurationPageUrl(organizationId!),
    },
    { label: t("configuration_recording_periods") },
  ];

  const navigate = useNavigate();

  const currentOrganization = useCurrentOrganization();
  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId);

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(AppInternalNavigationUtilities.getRecordingPeriodPageUrl(orgId));
            },
          }
        : undefined,
      navigationItems: {
        activePath: AppInternalNavigationUtilities.getConfigurationRecordingPeriodPageUrl({
          organizationId: organizationId!,
        }),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(AppInternalNavigationUtilities.getRecordingPeriodPageUrl(orgId));
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  return (
    <Box>
      <AppBreadCrumbs items={breadCrumbItems} />
      {currentOrganization && (
        <QueryWrapper
          query={recordingPeriodsQuery}
          loadingOverride={() => (
            <CenteredCircularProgress minHeight={PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT} />
          )}
          build={(data) => (
            <RecordingPeriodsEditListComponent
              organization={currentOrganization}
              recordingPeriods={data.recordingPeriods}
            />
          )}
        />
      )}
    </Box>
  );
};
