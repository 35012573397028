import { FC, useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { AppInternalNavigationUtilities } from "../../modules/common/utilities/app-internal-navigation.utilities";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { useGhgEvaluationQuery } from "../../modules/ghg-evaluation/ghg-evaluation.queries";
import { IInputParameterRecordingStructureIdentity } from "@netcero/netcero-common";
import { useGhgProtocolTabItems } from "../../modules/ghg-evaluation/hooks/ghg-protocol-tab-items.hook";
import {
  IDataEntryObject,
  IGhgEvaluationCalculationResult,
  IGhgEvaluationType,
  IInputParameterRecordingStructureGroupGHG,
} from "@netcero/netcero-core-api-client";
import { withValidatedRouteParams } from "../../modules/common/components/with-validated-router-parameters.hoc";
import { GhgDashboard } from "../../modules/ghg-evaluation/components/ghg-dashboard.component";
import { useInputParameterRecordingStructureDetailsQuery } from "../../modules/input-parameter-recording-structures/input-parameter-recording-structures.queries";
import { QueriesWrapper } from "../../modules/common/components/queries-wrapper.component";
import { Typography } from "@mui/material";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { DataEntryObjectsContextProvider } from "../../modules/data-entry-objects/data-entry-objects.context";

const GHG_PROTOCOL_VIEWED_DEO_QUERY_PARAM_KEY = "viewedDataEntryObjectId";

export const GhgProtocolDashboardPage: FC = withValidatedRouteParams(
  ["organizationId", "recordingPeriodId", "recordingStructureId"] as const,
  ({ organizationId, recordingPeriodId, recordingStructureId }) => {
    const navigate = useNavigate();

    // Identity for the input parameter recording structure
    const identity: IInputParameterRecordingStructureIdentity = useMemo(
      () => ({
        organizationId,
        recordingPeriodId,
        inputParameterRecordingStructureId: recordingStructureId,
      }),
      [organizationId, recordingPeriodId, recordingStructureId],
    );

    const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
    const sideBarConfiguration = useMemo<ISideBarConfiguration>(
      () => ({
        organizationSelect: {
          organizationId: organizationId,
          onChange: (organizationId) => {
            navigate(`/organizations/${organizationId}`);
          },
        },
        navigationItems: {
          activePath: AppInternalNavigationUtilities.getGhgProtocolDashboardPageUrl(identity),
          items: sideBarOrganizationNavItems,
        },
      }),
      [organizationId, identity, sideBarOrganizationNavItems, navigate],
    );
    useCustomSideBarContent(sideBarConfiguration);

    const ghgStructureQuery = useInputParameterRecordingStructureDetailsQuery(
      organizationId,
      recordingPeriodId,
      recordingStructureId,
    );
    const evaluationQuery = useGhgEvaluationQuery(
      { organizationId, recordingPeriodId },
      {
        evaluationType: IGhgEvaluationType.RecordingStructure,
        recordingStructureId,
      },
    );
    const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
      organizationId,
      recordingPeriodId,
    );

    return (
      <QueriesWrapper
        queries={[ghgStructureQuery, evaluationQuery, organizationStructureQuery]}
        build={([ghgStructure, evaluationResult, organizationStructure]) => (
          <OrganizationStructureDraftWrapper
            organizationId={organizationId}
            recordingPeriodId={recordingPeriodId}
            organizationStructure={organizationStructure}
            build={(rootDataEntryObject) =>
              ghgStructure.structure.type === "ghg" ? (
                <InternalGhgProtocolDashboardPage
                  identity={identity}
                  rootDataEntryObject={rootDataEntryObject}
                  recordingStructure={ghgStructure.structure}
                  results={evaluationResult.results}
                />
              ) : (
                <Typography color="error">
                  ERROR: Not a GHG Structure. Please contact support!
                </Typography>
              )
            }
          />
        )}
      />
    );
  },
);

interface IInternalGhgProtocolDashboardPageProps {
  identity: IInputParameterRecordingStructureIdentity;
  rootDataEntryObject: IDataEntryObject;
  recordingStructure: IInputParameterRecordingStructureGroupGHG;
  results: IGhgEvaluationCalculationResult[];
}

const InternalGhgProtocolDashboardPage: FC<IInternalGhgProtocolDashboardPageProps> = ({
  identity,
  rootDataEntryObject,
  recordingStructure,
  results,
}) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const viewedDataEntryObjectId = useMemo(
    () => searchParams.get(GHG_PROTOCOL_VIEWED_DEO_QUERY_PARAM_KEY) ?? rootDataEntryObject.id,
    [searchParams, rootDataEntryObject.id],
  );
  const handleChangeDataEntryObjectId = useCallback(
    (dataEntryObjectId: string) => {
      setSearchParams((params) => {
        params.set(GHG_PROTOCOL_VIEWED_DEO_QUERY_PARAM_KEY, dataEntryObjectId);
        return params;
      });
    },
    [setSearchParams],
  );

  const tabItems = useGhgProtocolTabItems(identity);
  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: {
        organizationId: identity.organizationId,
        onChange: (organizationId) => {
          navigate(`/organizations/${organizationId}`);
        },
      },
      dataEntryObjectSelect: {
        dataEntryObjectId: viewedDataEntryObjectId,
        onChange: handleChangeDataEntryObjectId,
      },
      recordingPeriodId: identity.recordingPeriodId,
      tabNavigation: {
        activeTabKey: "dashboard",
        items: tabItems,
      },
    }),
    [
      identity.organizationId,
      identity.recordingPeriodId,
      viewedDataEntryObjectId,
      handleChangeDataEntryObjectId,
      tabItems,
      navigate,
    ],
  );
  useCustomTopBarContent(topBarConfiguration);

  return (
    <DataEntryObjectsContextProvider rootDataEntryObject={rootDataEntryObject}>
      <GhgDashboard
        identity={identity}
        recordingStructure={recordingStructure}
        evaluationResults={results}
        viewedDataEntryObjectId={viewedDataEntryObjectId}
        onChangeViewedDataEntryObject={handleChangeDataEntryObjectId}
      />
    </DataEntryObjectsContextProvider>
  );
};
