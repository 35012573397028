import { FC, useMemo } from "react";
import { IInputParameterValueMetaData } from "@netcero/netcero-core-api-client";
import {
  ArrayUtilities,
  DataEntryObjectInputParameterValueDefinitionForBoolean,
  DataEntryObjectInputParameterValueDefinitionForDate,
  DataEntryObjectInputParameterValueDefinitionForNestedOptions,
  DataEntryObjectInputParameterValueDefinitionForOptions,
  DataEntryObjectInputParameterValueDefinitionForReferencedTarget,
  DataEntryObjectInputParameterValueDefinitionForText,
  DataEntryObjectInputParameterValueDefinitionForTextArea,
  DataEntryObjectInputParameterValueDefinitionForValueWithUnit,
  OptionalDataEntryObjectInputParameterValueDefinition,
} from "@netcero/netcero-common";
import { useTranslateBooleanInputValue } from "../../../value-acquisition/input-components/boolean-input.component";
import { FormatUtilities } from "../../../common/utilities/format.utilities";
import { useGenerateOptionsLookup } from "../../../value-acquisition/hooks/options-lookup.hook";
import { useGenerateNestedOptionsLookup } from "../../../value-acquisition/hooks/nested-options-lookup.hook";
import {
  ILineClampTypographyWithTooltipProps,
  LineClampTypographyWithTooltip,
} from "../../../common/components/line-clamp-typography.component";
import { LexicalRichTextEditor } from "../../../lexical/lexical-rich-text-editor.component";
import { SubscribeToContent } from "../../../lexical/plugins/subscribe-to-content.component";
import { QueryWrapper } from "../../../common/components/query-wrapper.component";
import {
  IDataEntryObjectValueInputComponentAssociationData,
  IDataEntryObjectValueInputVariant,
} from "../data-entry-object-value-input.component";
import { useTargetsQuery } from "../../../targets/targets.queries";
import { getTextAreaPlaceholderTranslationKey } from "../../../value-acquisition/input-components/text-area-input.component";
import { useTranslation } from "react-i18next";

const NO_VALUE = "-";

export interface IRenderTableValueReadonlyProps {
  valueMetaData: IInputParameterValueMetaData;
  value: OptionalDataEntryObjectInputParameterValueDefinition;
  associationData: IDataEntryObjectValueInputComponentAssociationData;
  variant: IDataEntryObjectValueInputVariant;
  typographyProps?: ILineClampTypographyWithTooltipProps;
}

const resolveAndRenderOptionValues = (values: string[], lookup: Record<string, string>): string => {
  const resolved = values
    .map((v) => lookup[v])
    // v should never be undefined in the first place, but just to be safe
    // and, in order to not lose this wisdom: "better safe than unprotected"
    .filter((v) => v !== undefined);

  return resolved.join(", ");
};

export const READONLY_SUPPORTED_TYPES: IInputParameterValueMetaData["type"][] = [
  "text",
  "text-area",
  "number",
  "boolean",
  "value-with-unit",
  "options",
  "nested-options",
  "currency",
  "date",
  "referenced-target",
];

export const RenderValueReadonly: FC<IRenderTableValueReadonlyProps> = ({
  valueMetaData,
  value,
  associationData,
  variant,
  typographyProps,
}) => {
  const { t } = useTranslation("esrs_input_placeholders");
  const translateBooleanInputValue = useTranslateBooleanInputValue();
  const generateOptionsLookup = useGenerateOptionsLookup();
  const generateNestedOptionsLookup = useGenerateNestedOptionsLookup();

  const renderedValue = useMemo(() => {
    if (value === undefined) {
      // If the value gets rendered in a table, just return undefined
      if (variant === "table") {
        return undefined;
      }

      // Otherwise return the placeholder for the specific type
      if (valueMetaData.type === "text-area") {
        return t(getTextAreaPlaceholderTranslationKey(variant), { ns: "text_area_input" });
      }
      return t(valueMetaData.type);
    }

    switch (valueMetaData.type) {
      case "text":
        return value as DataEntryObjectInputParameterValueDefinitionForText;
      case "text-area":
        return (
          <LexicalRichTextEditor
            variant="read-only"
            inInnerEditor={
              <SubscribeToContent
                content={value as DataEntryObjectInputParameterValueDefinitionForTextArea}
              />
            }
            disabled
          />
        );
      case "number":
        if (valueMetaData.valueLimits.isPercentage) {
          return FormatUtilities.formatPercentAsPercentage(
            +value,
            valueMetaData.valueLimits.precision,
            1,
            0,
          );
        } else {
          return FormatUtilities.formatDecimal(+value, 0, valueMetaData.valueLimits.precision);
        }
      case "boolean":
        return translateBooleanInputValue(
          value as DataEntryObjectInputParameterValueDefinitionForBoolean,
        );
      case "value-with-unit": {
        const { value: num, unit } =
          value as DataEntryObjectInputParameterValueDefinitionForValueWithUnit;
        return `${FormatUtilities.formatNumber(num)} ${unit}`;
      }
      case "options": {
        // generate lookup for nested values
        const lookup = generateOptionsLookup(valueMetaData);
        // read current values
        const typedValue = ArrayUtilities.alwaysArray(
          value as DataEntryObjectInputParameterValueDefinitionForOptions,
        );
        // render selected values
        return resolveAndRenderOptionValues(typedValue, lookup);
      }
      case "nested-options": {
        // generate lookup for nested values
        const lookup = generateNestedOptionsLookup(valueMetaData);
        // read current values
        const typedValue = ArrayUtilities.alwaysArray(
          value as DataEntryObjectInputParameterValueDefinitionForNestedOptions,
        );
        // render selected values
        return resolveAndRenderOptionValues(typedValue, lookup);
      }
      case "currency":
        return FormatUtilities.formatCurrency(+value / 100, "EUR");
      // Future type have to be added once they will be in use
      case "date":
        return FormatUtilities.formatDate(
          new Date(value as DataEntryObjectInputParameterValueDefinitionForDate),
        );
      case "referenced-target":
        return (
          <ReadOnlyTargetsComponent
            targetIds={value as DataEntryObjectInputParameterValueDefinitionForReferencedTarget}
            associationData={associationData}
          />
        );
      // When adding a new type, please also add it to the READONLY_SUPPORTED_TYPES array
      default:
        console.warn(`${valueMetaData.type} is not supported by readonly mode`);
        return "Unsupported";
    }
  }, [
    associationData,
    generateNestedOptionsLookup,
    generateOptionsLookup,
    t,
    translateBooleanInputValue,
    value,
    valueMetaData,
    variant,
  ]);

  return (
    <LineClampTypographyWithTooltip variant="body2" maxLines={2} {...typographyProps}>
      {renderedValue ?? NO_VALUE}
    </LineClampTypographyWithTooltip>
  );
};

interface IReadOnlyTargetsComponentProps {
  targetIds: string[];
  associationData: IDataEntryObjectValueInputComponentAssociationData;
}

const ReadOnlyTargetsComponent: FC<IReadOnlyTargetsComponentProps> = ({
  targetIds,
  associationData,
}) => {
  const targetsQuery = useTargetsQuery(associationData);

  return (
    <QueryWrapper
      query={targetsQuery}
      build={(targets) =>
        targets
          .filter((t) => targetIds.includes(t.id))
          .map((target) => target.general.name)
          .join(", ")
      }
    />
  );
};
