import { IContentLanguage, ITranslatedProperty } from "@netcero/netcero-core-api-client";

export type ResourceTranslateType = string | ITranslatedProperty;

export class ContentTranslationUtilities {
  public static readonly DEFAULT_TRANSLATION_LANGUAGE = IContentLanguage.De;

  public static translate(
    { translationsPerLanguage, defaultLanguage }: ITranslatedProperty,
    currentLanguage: IContentLanguage,
  ) {
    return translationsPerLanguage[currentLanguage] ?? translationsPerLanguage[defaultLanguage]!;
  }

  public static translateResourceTranslate(
    resource: ResourceTranslateType,
    currentLanguage: IContentLanguage,
  ) {
    return typeof resource === "string"
      ? resource
      : ContentTranslationUtilities.translate(resource, currentLanguage);
  }

  public static translateResourceTranslateParameters(
    language: IContentLanguage,
    parameters?: Record<string, ResourceTranslateType>,
  ): Record<string, string> {
    if (parameters === undefined) {
      return {};
    }
    return Object.fromEntries(
      Object.entries(parameters).map(([key, value]) => [
        key,
        ContentTranslationUtilities.translateResourceTranslate(value, language),
      ]),
    );
  }

  public static createTranslations(
    getTranslation: (language: IContentLanguage) => string | undefined,
    defaultLanguage: IContentLanguage = ContentTranslationUtilities.DEFAULT_TRANSLATION_LANGUAGE,
  ) {
    const translationsPerLanguage: Record<string, string | undefined> = {};
    Object.values(IContentLanguage).forEach((lang) => {
      translationsPerLanguage[lang] = getTranslation(lang);
    });
    return {
      defaultLanguage,
      translationsPerLanguage,
    };
  }

  public static createSingleEntry(
    translation: string,
    language: IContentLanguage = IContentLanguage.De,
  ): ITranslatedProperty {
    if (translation === "") {
      throw new Error("Encountered invalid translation");
    }

    return {
      defaultLanguage: language,
      translationsPerLanguage: {
        [language]: translation,
      },
    };
  }

  public static createTranslationFactory(defaultLanguage: IContentLanguage = IContentLanguage.En) {
    return (translationsPerLanguage: ITranslatedProperty["translationsPerLanguage"]) =>
      ContentTranslationUtilities.createTranslation(translationsPerLanguage, defaultLanguage);
  }

  public static createTranslation(
    translationsPerLanguage: ITranslatedProperty["translationsPerLanguage"],
    defaultLanguage: IContentLanguage = IContentLanguage.En,
  ): ITranslatedProperty {
    return {
      defaultLanguage,
      translationsPerLanguage,
    };
  }

  public static createSingleEntryFromNullable(
    translation: string | null,
    language: IContentLanguage = IContentLanguage.De,
  ): ITranslatedProperty | null {
    if (translation === null) {
      return null;
    }
    return this.createSingleEntry(translation, language);
  }

  public static createPropertyFromNullableOptions(
    ...options: { language: IContentLanguage; value: string | null }[]
  ) {
    const filtered = options.filter((v) => v.value !== null);

    if (filtered.length === 0) {
      return null;
    }

    const translatedProperty: ITranslatedProperty = {
      defaultLanguage: filtered[0].language,
      translationsPerLanguage: {
        [filtered[0].language]: filtered[0].value!,
      },
    };

    for (const option of filtered.slice(1)) {
      translatedProperty.translationsPerLanguage[option.language] = option.value!;
      translatedProperty.defaultLanguage = option.language;
    }

    return translatedProperty;
  }
}
