import {
  IContentLanguage,
  IEmissionFactor,
  IEmissionFactorDatabase,
  IEmissionFactorType,
} from "@netcero/netcero-core-api-client";
import { EMISSION_FACTOR_DATABASE_TRANSLATIONS_PER_LANGUAGE } from "./emission-factor-database.translations";
import { ContentTranslationUtilities } from "../content-translation";

export class EmissionFactorsUtilities {
  public static readonly MarketBasedEmissionFactorTypes: IEmissionFactorType[] = [
    IEmissionFactorType.MarketBasedEnergy,
  ];

  public static readonly LocationBasedEmissionFactorTypes: IEmissionFactorType[] = [
    IEmissionFactorType.VolumeBased,
    IEmissionFactorType.MassBased,
    IEmissionFactorType.DistanceBased,
    IEmissionFactorType.EnergyBased,
    IEmissionFactorType.LocationBasedEnergy,
    IEmissionFactorType.SpendingBased,
    IEmissionFactorType.Direct,
    IEmissionFactorType.Other,
  ];

  public static isEmissionFactorTypeMarketBased(type: IEmissionFactorType): boolean {
    return EmissionFactorsUtilities.MarketBasedEmissionFactorTypes.includes(type);
  }

  public static isEmissionFactorTypeLocationBased(type: IEmissionFactorType): boolean {
    return EmissionFactorsUtilities.LocationBasedEmissionFactorTypes.includes(type);
  }

  public static areEFTemplateNamesUnique(
    language: IContentLanguage,
    emissionFactors: Pick<IEmissionFactor, "title" | "version" | "database">[],
  ) {
    const names = emissionFactors.map((ef) =>
      EmissionFactorsUtilities.renderNameForTemplate(ef, language),
    );

    return new Set(names).size === names.length;
  }

  public static renderNameForTemplate(
    emissionFactor: Pick<IEmissionFactor, "title" | "version" | "database">,
    language: IContentLanguage,
  ) {
    return `${ContentTranslationUtilities.translate(emissionFactor.title, language)} (${
      emissionFactor.version
    }), ${EmissionFactorsUtilities.getDatabaseNameForLanguage(language, emissionFactor.database)}`;
  }

  public static getDatabaseNameForLanguage(
    language: IContentLanguage,
    database: IEmissionFactorDatabase,
  ) {
    return EMISSION_FACTOR_DATABASE_TRANSLATIONS_PER_LANGUAGE[language][database];
  }
}
