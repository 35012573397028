import { FC, useCallback, useMemo } from "react";
import { ISourceVisibility } from "@netcero/netcero-core-api-client";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SourceVisibilityIcon } from "./source-visibility-icon.component";

interface ISourceVisibilityButtonProps {
  value: ISourceVisibility;
  onValueChange: (newValue: ISourceVisibility) => void;
  disabled?: boolean;
}

export const SourceVisibilityButton: FC<ISourceVisibilityButtonProps> = ({
  onValueChange,
  value,
  disabled,
}) => {
  const { t } = useTranslation("sources", { keyPrefix: "buttons" });

  const nextState = useMemo(
    () =>
      value === ISourceVisibility.Public ? ISourceVisibility.Private : ISourceVisibility.Public,
    [value],
  );

  const handleClick = useCallback(() => onValueChange(nextState), [nextState, onValueChange]);

  return (
    <Tooltip title={t(`tooltips.${nextState}`)} placement="left">
      <span>
        <Button
          disabled={disabled}
          variant="text"
          startIcon={<SourceVisibilityIcon state={nextState} disableTooltip />}
          onClick={handleClick}
        >
          {t(`text.${nextState}`)}
        </Button>
      </span>
    </Tooltip>
  );
};
