import { FC } from "react";
import { ISourceVisibility } from "@netcero/netcero-core-api-client";
import {
  VisibilityPrivateIcon,
  VisibilityPublicIcon,
} from "../../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

interface ISourceVisibilityIconProps {
  state: ISourceVisibility;
  disableTooltip?: boolean;
}

export const SourceVisibilityIcon: FC<ISourceVisibilityIconProps> = ({ state, disableTooltip }) => {
  const { t } = useTranslation("sources", { keyPrefix: "buttons" });

  const icon =
    state === ISourceVisibility.Private ? <VisibilityPrivateIcon /> : <VisibilityPublicIcon />;

  return <Tooltip title={disableTooltip ? null : t(`tooltips.${state}`)}>{icon}</Tooltip>;
};
