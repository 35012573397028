import { FC, useMemo } from "react";
import {
  IDataEntryObjectInputParameterValueValueForKey,
  IInputParameter,
  IInputParameterUIConfigurationRow,
} from "@netcero/netcero-core-api-client";
import { ILocalDataEntryObjectInputParameterValueData } from "../../interfaces/local-data-entry-object-values.interfaces";
import { Box } from "@mui/material";
import {
  DataEntryObjectInputParameterValueValuesPerKey,
  InputParametersUtilities,
  InputParameterValuesPerKey,
  ObjectUtilities,
} from "@netcero/netcero-common";
import {
  DataEntryObjectValueInputComponent,
  IDataEntryObjectValueInputVariant,
} from "../data-entry-object-value-input.component";
import { useTranslateOptionalContent } from "../../../content-translation/hooks/translate-content.hook";
import { DataEntryObjectValueESRSPhaseInInputComponent } from "../data-entry-object-value-esrs-phase-in-input.component";

export type DataEntryObjectValuesInputFieldsErrorsPerKey = Partial<Record<string, string>>;

export interface IDataEntryObjectValueInputFieldsComponentProps {
  /** By default "thg" will be used */
  variant?: IDataEntryObjectValueInputVariant;
  inputParameter: IInputParameter;
  currentValue: ILocalDataEntryObjectInputParameterValueData;
  onInputValueChanged: (key: string, value: IDataEntryObjectInputParameterValueValueForKey) => void;
  disabled?: boolean;
  errors: DataEntryObjectValuesInputFieldsErrorsPerKey;
  organizationId: string;
  recordingPeriodId: string;
  rootDataEntryObjectId: string;
  dataEntryObjectId: string;
}

export const DataEntryObjectValueInputFieldsDirectComponent: FC<
  IDataEntryObjectValueInputFieldsComponentProps
> = ({
  variant = "thg",
  currentValue,
  inputParameter,
  onInputValueChanged,
  disabled,
  errors,
  organizationId,
  recordingPeriodId,
  rootDataEntryObjectId,
  dataEntryObjectId,
}) => {
  const valueConfigurationsPerKey: InputParameterValuesPerKey = useMemo(
    () =>
      InputParametersUtilities.getInputParameterValuesConfigurationLookup(inputParameter.values),
    [inputParameter.values],
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {inputParameter.uiConfiguration.rows.map((r, index) => (
        <DataEntryObjectValueInputFieldsRowComponent
          variant={variant}
          row={r}
          key={index}
          onValueChange={onInputValueChanged}
          values={ObjectUtilities.objectToRecord(currentValue.valuesPerKey)}
          valueConfigurationsPerKey={valueConfigurationsPerKey}
          disabled={disabled}
          errors={errors}
          organizationId={organizationId}
          recordingPeriodId={recordingPeriodId}
          rootDataEntryObjectId={rootDataEntryObjectId}
          dataEntryObjectId={dataEntryObjectId}
        />
      ))}
    </Box>
  );
};

interface IDataEntryObjectValueInputFieldsRowComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  row: IInputParameterUIConfigurationRow;
  valueConfigurationsPerKey: InputParameterValuesPerKey;
  values: DataEntryObjectInputParameterValueValuesPerKey;
  onValueChange: (key: string, value: IDataEntryObjectInputParameterValueValueForKey) => void;
  disabled?: boolean;
  errors: DataEntryObjectValuesInputFieldsErrorsPerKey;
  organizationId: string;
  recordingPeriodId: string;
  rootDataEntryObjectId: string;
  dataEntryObjectId: string;
}

const DataEntryObjectValueInputFieldsRowComponent: FC<
  IDataEntryObjectValueInputFieldsRowComponentProps
> = ({
  variant,
  row,
  valueConfigurationsPerKey,
  values,
  onValueChange,
  disabled,
  errors,
  organizationId,
  recordingPeriodId,
  rootDataEntryObjectId,
  dataEntryObjectId,
}) => {
  const isSingleInput = useMemo(() => row.columns.length <= 1, [row.columns]);
  const translateContent = useTranslateOptionalContent();

  const associationData = useMemo(
    () => ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      rootDataEntryObjectId,
    }),
    [organizationId, recordingPeriodId, dataEntryObjectId, rootDataEntryObjectId],
  );

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2} flex={1}>
      {row.columns.map((c) => {
        const config = valueConfigurationsPerKey[c.valueKey];
        const value = values[c.valueKey];

        // don't display anything if the value configuration /the actual value can't be found
        if (config === undefined || value === undefined) {
          return null;
        }

        return config.valueConfiguration.type === "esrs-topic-phase-in" ? (
          <DataEntryObjectValueESRSPhaseInInputComponent
            key={c.valueKey}
            organizationId={organizationId}
            recordingPeriodId={recordingPeriodId}
            rootDataEntryObjectId={rootDataEntryObjectId}
            dataEntryObjectId={dataEntryObjectId}
          />
        ) : (
          <Box width="100%" key={c.valueKey}>
            <DataEntryObjectValueInputComponent
              key={c.valueKey}
              variant={variant}
              valueMetaData={config.valueConfiguration.configuration}
              value={value.value}
              onChange={(value) => {
                onValueChange(c.valueKey, { type: "simple", value });
              }}
              label={translateContent(c.label)}
              required={config.required}
              disabled={disabled}
              error={errors[c.valueKey]}
              disableMaxWidth={isSingleInput}
              associationData={associationData}
            />
          </Box>
        );
      })}
    </Box>
  );
};
