import {
  IGhgCategoriesIdentifier,
  IGhgProtocolScopeEnum,
  IGhgProtocolVersion,
  IRecordingPeriodGhgCategory,
  IRecordingPeriodGhgCategoryStatus,
} from "@netcero/netcero-core-api-client";
import { IStateMachineTransition } from "../state-machine";

/**
 * This represents the categories for every scope of a GHG protocol version.
 */
export type IGhgCategoriesPerVersion = Record<IGhgProtocolVersion, IGhgCategoriesPerScope>;

/**
 * This represents the categories for each scope.
 */
export type IGhgCategoriesPerScope = Record<IGhgProtocolScopeEnum, IGhgCategoriesIdentifier[]>;

export type IGhgCategoriesPerScopeForRecordingPeriod = Partial<
  Record<IGhgProtocolScopeEnum, IRecordingPeriodGhgCategory[]>
>;

export enum GhgCategoryStatusStateTransitions {
  UndoWork = "undo_work",
  StartWork = "start_work",
  RequestReview = "request_review",
  ReviewRejected = "review_rejected",
  ReviewApproved = "review_approved",
  MoveToInProgress = "move_to_in_progress",
}

export type GhgCategoryStatusStateTransitionsForNotification =
  | GhgCategoryStatusStateTransitions.RequestReview
  | GhgCategoryStatusStateTransitions.ReviewRejected;

export const GhgCategoryStatusStateTransitionDefinitions: Record<
  GhgCategoryStatusStateTransitions,
  IStateMachineTransition<IRecordingPeriodGhgCategoryStatus>
> = {
  [GhgCategoryStatusStateTransitions.UndoWork]: {
    from: IRecordingPeriodGhgCategoryStatus.InProgress,
    to: IRecordingPeriodGhgCategoryStatus.Open,
  },
  [GhgCategoryStatusStateTransitions.StartWork]: {
    from: IRecordingPeriodGhgCategoryStatus.Open,
    to: IRecordingPeriodGhgCategoryStatus.InProgress,
  },
  [GhgCategoryStatusStateTransitions.RequestReview]: {
    from: IRecordingPeriodGhgCategoryStatus.InProgress,
    to: IRecordingPeriodGhgCategoryStatus.InReview,
  },
  [GhgCategoryStatusStateTransitions.ReviewRejected]: {
    from: IRecordingPeriodGhgCategoryStatus.InReview,
    to: IRecordingPeriodGhgCategoryStatus.InProgress,
  },
  [GhgCategoryStatusStateTransitions.ReviewApproved]: {
    from: IRecordingPeriodGhgCategoryStatus.InReview,
    to: IRecordingPeriodGhgCategoryStatus.Done,
  },
  [GhgCategoryStatusStateTransitions.MoveToInProgress]: {
    from: IRecordingPeriodGhgCategoryStatus.Done,
    to: IRecordingPeriodGhgCategoryStatus.InProgress,
  },
};
