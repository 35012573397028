import { FC, ReactNode } from "react";
import { Box, Button, CardActions, CardContent, CardHeader } from "@mui/material";
import { Link } from "react-router-dom";
import { StyledCardWithOnHoverShadow } from "./styled-card.component";

interface IBaseProps {
  title: ReactNode;
  content?: ReactNode;
}

interface INoticeWithButtonProps extends IBaseProps {
  button: {
    text: string;
    link: string;
  };
  actions?: never;
}

interface INoticeWithActionsProps extends IBaseProps {
  actions: ReactNode;
  button?: never;
}

type INoticeComponentProps = INoticeWithButtonProps | INoticeWithActionsProps;

export const NoticeComponent: FC<INoticeComponentProps> = ({ title, content, ...props }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt="15vh">
      <StyledCardWithOnHoverShadow sx={{ maxWidth: 600 }}>
        <CardHeader title={title} />
        {content && <CardContent>{content}</CardContent>}
        <CardActions>
          {props.button ? (
            <Button component={Link} to={props.button.link} variant="outlined" sx={{ mx: "auto" }}>
              {props.button.text}
            </Button>
          ) : (
            props.actions
          )}
        </CardActions>
      </StyledCardWithOnHoverShadow>
    </Box>
  );
};
