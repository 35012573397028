import {
  AppUrlUtilities,
  IInputParameterRecordingStructureIdentity,
  IOrganizationIdentity,
  IRecordingPeriodIdentity,
} from "@netcero/netcero-common";
import { IGhgCategoriesIdentifier, IGhgProtocolScopeEnum } from "@netcero/netcero-core-api-client";

export class AppInternalNavigationUtilities {
  // Helpers

  private static stringifyUrl(url: URL) {
    return url.pathname + url.search;
  }

  // Actual Paths

  public static getOrganizationPageUrl(organizationId: string) {
    return `/organizations/${organizationId}`;
  }

  public static getRecordingPeriodPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/recording-periods`;
  }

  public static getRecordingPeriodDashboardPageUrl(
    organizationId: string,
    recordingPeriodId: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/all`;
  }

  public static getFilteredRecordingPeriodDashboardPageUrl(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureType?: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/${
      recordingStructureType ?? "all"
    }`;
  }

  public static getRecordingStructurePageUrl(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureType: string,
    recordingStructureId: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/${recordingStructureType}/recording-structures/${recordingStructureId}`;
  }

  public static getDeoPageUrl(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureType: string,
    recordingStructureId: string,
    dataEntryObjectId: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/${recordingStructureType}/recording-structures/${recordingStructureId}/data-entry-objects/${dataEntryObjectId}/`;
  }

  public static getEvaluationSelectDataEntryObjectPageUrl(
    organizationId: string,
    recordingPeriodId: string,
    evaluationKey: string,
    recordingStructureId: string,
    dataEntryObjectId: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/evaluations/${evaluationKey}/recording-structures/${recordingStructureId}/data-entry-objects/${dataEntryObjectId}`;
  }

  public static getEmissionsEvaluationPageUrl(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureId: string,
    dataEntryObjectId: string,
  ) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/evaluations/emissions/recording-structures/${recordingStructureId}/data-entry-objects/${dataEntryObjectId}`;
  }

  public static getDMAPageUrl({ organizationId, recordingPeriodId }: IRecordingPeriodIdentity) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/double-materiality-assessment`;
  }

  public static getDMADashboardPageUrl({
    organizationId,
    recordingPeriodId,
  }: IRecordingPeriodIdentity) {
    return `/organizations/${organizationId}/recording-periods/${recordingPeriodId}/double-materiality-assessment/dashboard`;
  }

  public static getTargetPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/target-paths`;
  }

  public static getReferenceBudgetsPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/target-paths/reference-budgets`;
  }

  public static getConfigurationPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration`;
  }

  public static getAuditLoggingConfigurationPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/audit-logging`;
  }

  public static getConfigurationRecordingPeriodPageUrl({ organizationId }: IOrganizationIdentity) {
    return `/organizations/${organizationId}/configuration/recording-periods`;
  }

  public static getConfigurationRecordingPeriodOrganizationStructurePageUrl(
    identity: IOrganizationIdentity,
  ) {
    return AppInternalNavigationUtilities.getConfigurationRecordingPeriodPageUrl(identity);
  }

  public static getConfigurationStakeholderPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/stakeholder`;
  }

  public static getConfigurationSourcesPageUrl(organizationId: string, sourceId?: string) {
    const url = new URL(
      `/organizations/${organizationId}/configuration/sources`,
      window.location.origin,
    );
    if (sourceId) {
      url.hash = sourceId;
    }
    return AppInternalNavigationUtilities.stringifyUrl(url);
  }

  public static getConfigurationUsersPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/users`;
  }

  public static getConfigurationEmissionFactorsPageUrl(organizationId: string) {
    return `/organizations/${organizationId}/configuration/emission-factors`;
  }

  public static getGhgProtocolPageUrl(
    {
      organizationId,
      recordingPeriodId,
      inputParameterRecordingStructureId,
    }: IInputParameterRecordingStructureIdentity,
    queryParams?: {
      viewedScope?: IGhgProtocolScopeEnum | string;
      viewedGhgCategoryId?: IGhgCategoriesIdentifier | string;
    },
  ) {
    const url = new URL(
      AppInternalNavigationUtilities.getRecordingStructurePageUrl(
        organizationId,
        recordingPeriodId,
        "ghg",
        inputParameterRecordingStructureId,
      ),
      window.location.origin,
    );
    if (queryParams) {
      if (queryParams.viewedScope) {
        url.searchParams.append(AppUrlUtilities.VIEWED_SCOPE_QUERY_PARAM, queryParams.viewedScope);
      }
      if (queryParams.viewedGhgCategoryId) {
        url.searchParams.append(
          AppUrlUtilities.VIEWED_CATEGORY_QUERY_PARAM,
          queryParams.viewedGhgCategoryId,
        );
      }
    }
    return AppInternalNavigationUtilities.stringifyUrl(url);
  }

  public static getGhgProtocolInventoryScreeningPageUrl(
    recordingStructureIdentity: IInputParameterRecordingStructureIdentity,
    viewedScopeId?: IGhgProtocolScopeEnum,
  ) {
    const url = new URL(
      `${AppInternalNavigationUtilities.getGhgProtocolPageUrl(
        recordingStructureIdentity,
      )}/inventory-screening`,
      window.location.origin,
    );
    if (viewedScopeId) {
      url.searchParams.set(AppUrlUtilities.VIEWED_SCOPE_QUERY_PARAM, viewedScopeId);
    }

    return AppInternalNavigationUtilities.stringifyUrl(url);
  }

  public static getGhgProtocolDashboardPageUrl(
    identity: IInputParameterRecordingStructureIdentity,
    queryParams?: {
      selectedBase?: string;
    },
  ) {
    const url = new URL(
      this.getGhgProtocolPageUrl(identity) + "/dashboard",
      window.location.origin,
    );

    if (queryParams) {
      if (queryParams.selectedBase) {
        url.searchParams.append(AppUrlUtilities.VIEWED_SCOPE_QUERY_PARAM, queryParams.selectedBase);
      }
    }

    return AppInternalNavigationUtilities.stringifyUrl(url);
  }
}
